import React from "react";
import { ProgressMesaage } from "../../../../constants/constants";
import "./demo.less";

export const Demo = () => {
    return(
        <div className="demo-container">
        {ProgressMesaage}
        </div>
    )
}