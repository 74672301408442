/* eslint-disable eqeqeq */
import React from "react";
import { Empty, Select } from "antd";
import "./dropDown.less";

const DropDown = (props: any) => {
  const { Option } = Select;
  const {
    className,
    bordered,
    defaultValue,
    id,
    optionValue,
    onChange,
    value,
    childwidth,
    optionClass,
    disabled,
    icon,
    placeholder,
    showSearch,
    mode,
    showSort,
    sortIcon,
    onSortClick,
  } = props;

  let selectedVal = "";
  if (!defaultValue) {
    selectedVal = optionValue && optionValue.length ? optionValue[0].text : "";
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedVal = defaultValue;
  }

  const RenderIcon = () => {
    return <img src={icon} alt =""/>
}
const tagRender = (props: any) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event:any) => {
    event.preventDefault();
    event.stopPropagation();
  };
  let style: React.CSSProperties ={
    backgroundColor: "rgba(90, 237, 149, 0.7)",
    borderRadius: "100px",
    color:" #11853F",
    fontWeight: 600,
    fontSize:"12px",
    lineHeight: "14px",
    paddingLeft: "5px",
  }
  return (
    <span style={style}>
      {label}
      {closable ? (
        <button  style={{border: "none", background:"transparent"}}type="button" onMouseDown={onPreventMouseDown} onClick={onClose}>
          x
        </button>
      ) : null}
    </span>
  );
};

  return (
    <>
      <Select
        className={className}
        placeholder={placeholder}
        bordered={bordered}
        value={value}
        notFoundContent={<Empty description="No results found."/>}
        defaultValue={defaultValue}
        id={id}
        suffixIcon={RenderIcon}
        style={{ width: "100%"}}
        onChange={onChange}
        dropdownMatchSelectWidth={childwidth? childwidth: true}
        dropdownClassName="dropdown"
        showSearch={showSearch}
        filterOption={(input, option: any) =>{
         return ( 
          option?.children ?
           option.children[1]?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
           :
           false
           )
        }
        }
        getPopupContainer={(trigger) => trigger.parentNode}
        virtual={true}
        disabled={disabled?disabled:false}
        mode={mode? mode: "single"}
        showArrow={true}
        tagRender={tagRender}
        maxTagCount="responsive"
        maxTagTextLength={10}
      >
        {optionValue.map((option: any, index: any) => {
          return (
            <Option className={optionClass?'optionClass': 'options'} key={option.value} value={option.value}>
              {showSort && value == option.value ?
              <img src={sortIcon} style={{height:"15px", paddingRight:"5px"}}alt="" onClick={onSortClick}/> 
              :null}
              <span onClick={onSortClick}>{option.text}</span>
              {option.description?  <><br/><span className="option-desc">{option.description}</span></>
                : null
              }
            </Option>
          );
        })}
      </Select>
    </>
  );
};
export default DropDown;