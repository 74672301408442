import { message } from "antd";
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { showError } from "../../../utility/appUtil";
import ContentCorner from "../../models/contentCorner/contentCorner";
import FeaturedList from "../../models/featuredList/category";
import NewsLetterDetail from "../../models/featuredList/newsLetter";
import Group from "../../models/group/group";
import { AppStateService } from "../storage/appStateService";

export class GroupService extends BaseService {
    constructor() {
        super(getAPIBaseUrl(PortalModule.GROUPS));
      }

      showErrorForApi = (data: any, ) =>{
        if(data?.Message !== "" && data?.Message !== undefined){
          var authText = "Authorization has been denied for this request."
          message.error({
            content:(data?.Message?.includes("denied") || (data?.Message.toUpperCase() === authText.toUpperCase())?"Session Expired. Please log in again.":data?.Message),
            duration: 4,
          })
          if(data?.Message?.includes("denied") || (data?.Message.toUpperCase() === authText.toUpperCase())){
            return true
          }else{
            return false
          }
        }else if(data?.ErrorMessage !== "" && data?.ErrorMessage !== undefined){
          message.error({
            content:data?.ErrorMessage,
            duration: 4,
          })
         return false
        }else{
          return undefined
        }
        }

    async fetchGroups (params: any): Promise<Group[]| any> {
      var libId = params.libId
      var countryCode = params.countryCode
      var userCatalog = AppStateService.getState("userCatalog")
      var limit = params.limit
      var showOnlyUnshown = params.showOnlyUnshown
        const response = await this.get(`/featureGroups?lib_id=${libId}&country_code=${countryCode}&catalog_id=${userCatalog}&limit=${limit}&showOnly_Unshown=${showOnlyUnshown}`);
        // eslint-disable-next-line eqeqeq
        if(response.data.success == 1){
            const groupData = this.setGroupsData(response.data.data)
            return groupData;
        }
        else {
          this.showErrorForApi(response?.data?.data)
          return null
        }
      }

      async fetchGroupByType (params: any): Promise<Group[]| any> {
        var isGroupType = params.isGroupType
          const response = await this.get(`/featureGroupByType?isGroup_Type=${isGroupType}`);
          // eslint-disable-next-line eqeqeq
          if(response.data.success == 1){
              const groupData = this.setGroupsData(response.data.data)
              return groupData;
          }
          else {
            showError(response?.data?.data)
            return null
          }
        }

      async fetchCheckedGroupIds (params: any): Promise<Group[]| any> {
          const response = await this.get(`/userFeatureListData`);
          // eslint-disable-next-line eqeqeq
          if(response.data.success == 1){
              const checkedGroups = response.data.data
              return checkedGroups;
          }
          else {
            showError(response?.data?.data)
            return null
          }
        }

        async selectGroups (payload: any): Promise<Group[]| any> {
          const response = await this.post(`/selectGroup`, payload);
          if(response.data.success === 1){
            const groupData = response.data.data
            return groupData;
          }
          else {
            showError(response?.data?.data)
            return false
          }
        }

        async unSelectGroups (params: any): Promise<Group[]| any> {
          var groupId = params.groupId
            const response = await this.delete(`/unSelectGroup?group_Id=${groupId}`);
            // eslint-disable-next-line eqeqeq
            if(response.data.success == 1){
                const groupData = response.data.data
                return groupData;
            }
            else {
              showError(response?.data?.data)
              return null
            }
          }

          async fetchContentCorner (country: any): Promise<Group[]| any> {
            var countryCode = country
            const response = await this.get(`/featureContentCorner?country_code=${countryCode}`);
            // eslint-disable-next-line eqeqeq
            if(response.data.success == 1){
                const resp = response.data.data
                return this.setContentData(resp);
            }
            else {
              // showError(response?.data?.data)
              return null
            }
          }



    private setGroupsData = (data: any[]) =>{
      var groups:Group[] = data.map((group: Group)=>{
        return(
          {
          ChangeDateTime: group?.ChangeDateTime,
          FeaturedListGroupId: group?.FeaturedListGroupId,
          FeaturedListGroupName: group?.FeaturedListGroupName,
          FeaturedListGroupDescription: group?.FeaturedListGroupDescription,
          SortOrder: group?.SortOrder,
          IsActive : group?.IsActive,
          IsShown : group?.IsShown,
          CreateUserId : group?.ChangeUserId,
          ChangeUserId : group?.ChangeUserId,
          CatalogID : group?.CatalogID,
          CountryCode : group?.CountryCode,
          IsNew : group?.IsNew,
          ItemCount: group?.ItemCount,
          Items: group?.Items?.map((category: FeaturedList)=>{
            return(
            {
              Archived: category?.Archived,
              FeaturedListId: category?.FeaturedListId,
              FeaturedListName: category?.FeaturedListName,
              FeaturedList: category?.FeaturedList
            }
            )
          }),
        })
      })
      return groups
    }

    private setContentData(item: any) {
      const content: ContentCorner =  {
        Archived:item?.Archived,
        Author: item?.Author,
        AuthorDetails: item?.AuthorDetails,
        CatalogId: item?.CatalogId,
        CountryCode: item?.CountryCode,
        CreatedDateTime: item?.CreatedDateTime,
        EndDate : item?.EndDate,
        IsActive : item?.IsActive,
        ModifiedDateTime : item?.ModifiedDateTime,
        NewsLetterId : item?.NewsLetterId,
        NewsLetterTitle : item?.NewsLetterTitle,
        StartDate : item?.StartDate,
        NewsLetterDetails:item?.NewsLetterDetails,
        PublicationDate:item?.NewsLetterDetails,
        PublicationDateString:item?.PublicationDateString,
        NewsLetterDetail: item?.NewsLetterDetail?.map((category: NewsLetterDetail)=>{
          return(
          {
            CatalogId:  category?.CatalogId,
            CountryCode:  category?.CountryCode,
            CreatedDateTime:  category?.CreatedDateTime,
            FeaturedListDscr:  category?.FeaturedListDscr,
            FeaturedListId:  category?.FeaturedListId,
            FeaturedListName:  category?.FeaturedListName,
            ModifiedDateTime:  category?.ModifiedDateTime,
            NewsLetterDescription:  category?.NewsLetterDescription,
            NewsLetterDetailId:  category?.NewsLetterDetailId,
            NewsLetterId:  category?.NewsLetterId,
            SortOrder:  category?.SortOrder,
          }
          )
        }),
    }
      return content;
  }
}