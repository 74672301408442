import { Popover, Divider } from "antd"
import { infoIcon } from "../../../../images";
import { BookStateSelector } from "../../../../../redux/reducers/books/bookListReducer";
import { useSelector } from "react-redux";
import React from "react";




export const CartPopup = (props: any) => {
    const { cartId, CatalogItemId } = props
    const { carts } = useSelector(BookStateSelector);

    const InfoCarts = (
        <div>
            {carts ? (
                carts[CatalogItemId] && carts[CatalogItemId].length ? (
                    carts[CatalogItemId]?.map((bookCart: any, index: number) => {
                        return (
                            <div key={`cart${index}`}>
                                <p className="book-cart">
                                    {bookCart.CartName}
                                </p>
                                <span className="copies-text">{bookCart.NumberOfCopies} Copies</span>
                                {index < carts[CatalogItemId]?.length - 1 && (
                                    <Divider className="carts-divider" />
                                )}
                            </div>
                        );
                    })
                ) : (
                    <div className="book-cart">Loading...</div>
                )
            ) : null}
        </div>
    );
    return (
        <>
            {cartId}
            <Popover
                trigger={["click","hover"]}
                placement="bottomLeft"
                content={InfoCarts}
                // getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover>
        </>
    )
}