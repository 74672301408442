/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Switch, Drawer, Tabs, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "../carts.less";
import { active, inactive } from "../../../../images";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import {
  CartStateSelector,
  clearSaved,
  setEbookScrollScroll,
  setAudioBookScroll,
} from "../../../../../redux/reducers/carts/cartsReducer";
import { AutoCartSlider } from "./sliderTabPane";
import DataCart from "./dummyCart";
import Button from "../../../stateless/common/button";
import { Demo } from "../../../stateless/common/demo";
import { fetchUsers } from "../../../../../redux/actions/user/userAction";
import User from "../../../../models/user/user";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import {
  fetchAutoCarts,
  saveAutoCart,
  resetAutoCarts,
  addNewRuleAutoCart,
  recentTab,
} from "../../../../../redux/actions/carts/cartAction";
import { Messages } from "../../../../constants/messages";
import { AutoCartType } from "../../../../constants/enums";
import { AutoCartCommon } from "./autoCartCommonSetting";
import { CompWrapper } from "../../../stateless/common/compWrapper";
import moment from "moment-timezone";
import { AutoHistory } from "./history/autoHistory";
import { WarningOutlined } from "@ant-design/icons";

export const AutoCartList = (props: any) => {
  const { carts } = props
  const { TabPane } = Tabs;
  const { appUser } = useSelector(userStateSelector);
  const {
    autoCartsData,
    formState,
    ruleSaved,
    saveAutoCartFormState,
    resetformState,
    fetchAutoCartFormState,
    defaultTab,
    autoByRuleError
  } = useSelector(CartStateSelector);
  const { users } = useSelector(userStateSelector);
  const cartList = DataCart();
  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [editByClick, setEditByClick] = useState<boolean>(false);
  const [selectedCart, setSelectedCart] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [commonSelectedCart, setCommonSelectedCart] = useState<any>();
  const [updatedEbook, setUpdatedEbook] = useState<any>([]);
  const [updatedAbook, setUpdatedAbook] = useState<any>([]);

  const [addNewRule, setAddNewRule] = useState<any>([]);

  const [bookCartNo, setBookCartNo] = useState<any>(1);
  const [bookCartNoWithActive, setookCartNoWithActive] =
    useState<boolean>(true);
  const [updatedCommonEbook, setUpdatedCommonEbook] = useState<any>([]);

  const [updatedCommonAbook, setUpdatedCommonAbook] = useState<any>([]);

  const [saveRule, setSaveRule] = useState<boolean>(false);
  const [saveCommon, setSaveCommon] = useState<boolean>(false);

  const [editByUpdated, setEditByUpdated] = useState<boolean>(false);

  const [ebookActiveStatus, setEbookActiveStatus] = useState<boolean>(true);
  const [audioActiveStatus, setAudioActiveStatus] = useState<boolean>(true);

  const [commonUpdatedEbook, setCommonUpdatedBook] = useState<any>([]);

  const [getCommonSpendLimit, setGetCommonSpendLimit] = useState<String>();
  const [getscrollheight, setGetScrollHeight] = useState<number>();
  const [errorSpendLimit, setErrorSpendLimit] = useState<boolean>(false);
  const [ebookHold, setEBokHold] = useState<boolean>(false);
  const [ebookContent, setEbookContent] = useState<boolean>(false);
  const [ebookSuggest, setEbookSuggest] = useState<boolean>(false);
  const [audioHold, setAudioHold] = useState<boolean>(false);
  const [audioContent, setAudioContent] = useState<boolean>(false);
  const [commonFormEror, setCommonFormEror] = useState<boolean>(false);
  const [commonAudioFormEror, setCommonAudioFormEror] = useState<boolean>(false);

  const [audioSuggestion, setAudioSuggestion] = useState<boolean>(false);
  const [formloading, setFormLoading] = useState<boolean>();
  const [ruleArePresentEbook, setRuleArePresentEbook] = useState<any>([])
  const [ruleArePresentAbook, setRuleArePresentAbook] = useState<any>([])
  const [presentBook, setPresentBook] = useState<any>([])
  const [purchaseCapReachDate, setPurchaseCapReachDate] = useState<any>();
  const [purchaseCapPeriod, setPurchaseCapPeriod] = useState<any>();
  const [periodRunningTotal, setPeriodRunningTotal] = useState<any>();

  const [holdSpent, setHoldSpent] = useState<boolean>(false);
  const [contentExpSpent, setContentExpSpent] = useState<boolean>(false);
  const [suggestionSpent, setSuggestionSpent] = useState<boolean>(false);
  const [amountReachRule,setAmountReachRule] = useState<any>("")

  var currency = appUser && appUser.libraryData ? appUser.libraryData[0]?.CurrencySymbol : ""
  var ebookRules: any
  var audioBookRules: any
  var userHasPermision =
    appUser && appUser.libraryData ? appUser.libraryData[0].FeaturePermissions : "";
  var dateFormat = appUser && appUser.libraryData ? appUser?.libraryData[0]?.DateFormat.toUpperCase() : ""
  const [audioBookCartLimit, setAudioBookCartLimit] = useState<boolean>(false);
  const [ebookCartLimit, setEbookCartLimit] = useState<boolean>(false);

  useEffect(() => {
    const userLength = users && users?.length ? users?.length : 0
    if(userLength <= 0) {
      if(appUser?.isLibraryAdmin || appUser?.IsPatronCommunicator){
        dispatch(
          fetchUsers({
            libId: lib_Id,
            activeOnly: 1
          })
        );
      }
    }
    setCommonSelectedCart({ CartName: "Common" });
  }, []);
  useEffect(() => {
    if (ruleSaved) {
      if(appUser?.isLibraryBuyer){
      dispatch(fetchAutoCarts(lib_Id));
      }
      dispatch(clearSaved());
      message.success({
        content: Messages.RULES_UPDATED,
        key: "notificationMessage",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleSaved]);

  useEffect(() => {
    if(autoByRuleError) {
      message.error({
        content:autoByRuleError,
        key: "notificationMessage",
      });
    }
  }, [autoByRuleError])
  

useEffect(()=>{
  setBookCartNo(defaultTab)
},[defaultTab])

  useEffect(() => {
    setAudioBooks(
      autoCartsData?.filter((data: any) => {
        return data.MediaTypeId === 2;
      })
    );
    setEbooks(
      autoCartsData?.filter((data: any) => {
        return data.MediaTypeId === 1;
      })
    );
  }, [autoCartsData]);

  const [DataAudioBooks, setAudioBooks] = useState(
    autoCartsData?.filter((data: any) => {
      return data.MediaTypeId === 2;
    })
  );
  const [audioBookCart, setAudiobookCart] = useState(
    DataAudioBooks[0] !== undefined
      ? JSON.parse(JSON.stringify(DataAudioBooks[0]))
      : []
  );
  const [DataEbooks, setEbooks] = useState(
    autoCartsData?.filter((data: any) => {
      return data.MediaTypeId === 1;
    })
  );

  const [eBookCart, setEbookCart] = useState(
    DataEbooks[0] !== undefined ? JSON.parse(JSON.stringify(DataEbooks[0])) : []
  );
  useEffect(() => {
    setEbookActiveStatus(DataEbooks[0]?.Active);
    setAudioActiveStatus(DataAudioBooks[0]?.Active);

    if (parseInt(bookCartNo) === 1) {
      setookCartNoWithActive(DataEbooks[0]?.Active);
    } else {
      setookCartNoWithActive(DataAudioBooks[0]?.Active);
    }

    DataAudioBooks[0] !== undefined &&
      setAudiobookCart(JSON.parse(JSON.stringify(DataAudioBooks[0])));

    const getRuleName = (name: any) => {
      if (name?.toUpperCase() === "Hold Ratio".toUpperCase()) {
        return "Holds";
      } else if (name?.toUpperCase() === "Suggestions".toUpperCase()) {
        return "Suggestions";
      } else if (name?.toUpperCase() === "Content Expiration".toUpperCase()) {
        return "Content Expiration";
      } else {
        return undefined;
      }
    };

    DataEbooks[0] !== undefined &&
      setEbookCart(JSON.parse(JSON.stringify(DataEbooks[0])));
    DataAudioBooks[0] !== undefined &&
      setRuleArePresentEbook(
        JSON.parse(
          JSON.stringify(
            DataEbooks[0]?.Rules?.map((item: any) =>
              getRuleName(item?.RuleName)
            )
          )
        )
      );
    DataEbooks[0] !== undefined &&
      setRuleArePresentAbook(
        JSON.parse(
          JSON.stringify(
            DataAudioBooks[0]?.Rules?.map((item: any) =>
              getRuleName(item?.RuleName)
            )
          )
        )
      );
  }, [DataAudioBooks, DataEbooks, bookCartNo]);

  const getUserOptions = (type: number) => {
    if (type === 1) {
      var temp = users?.filter((data: User) => {
        return data.isActive && data.isLibraryBuyer;
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      var temp = users?.filter((data: User) => {
        return data.isActive && (data.isLibraryBuyer || data.isLibraryUser);
      });
    }
    return temp?.map((user: User) => {
      return { text: `${user.firstName} ${user.lastName}`, value: user.userId };
    });
  };

  const sorterFun = (data:any,type:number) => {
    if(type === 0) {
      var sortedList = data?.sort((a:any, b:any) => 
      {
        const nameA = a.text.toLowerCase()
        const nameB = b.text.toLowerCase()
        if ( nameA < nameB ){
          return -1;
        }
        if ( nameA > nameB ){
          return 1;
        }
        return 0;
      }
      )
    }
    else {
      sortedList = data?.sort((a:any, b:any) => 
      {
        const nameA = a.text.toLowerCase()
        const nameB = b.text.toLowerCase()
        if ( nameA < nameB ){
          return -1;
        }
        if ( nameA > nameB ){
          return 1;
        }
        return 0;
      }
      )
    }
    return sortedList
  }

  const getActiveStatus = (cart: any) => {
    let ebookStatus;
    let audioBookStatus;
    if (cart?.CartName === "Holds" && parseInt(bookCartNo) === 1) {
      for (var i = 0; i < eBookCart.Rules?.length; i++) {
        if (eBookCart.Rules[i].AutoBuyRuleType === 2) {
          ebookStatus = eBookCart.Rules[i].Active;
        }
      }
      return ebookStatus;
    } else if (cart.CartName === "Holds" && parseInt(bookCartNo) === 2) {
      for (var j = 0; j < audioBookCart.Rules?.length; j++) {
        if (audioBookCart.Rules[j].AutoBuyRuleType === 2) {
          audioBookStatus = audioBookCart.Rules[j].Active;
        }
      }
      return audioBookStatus;
    } else if (cart?.CartName === "Suggestions" && parseInt(bookCartNo) === 1) {
      for (i = 0; i < eBookCart.Rules?.length; i++) {
        if (eBookCart.Rules[i].AutoBuyRuleType === 3) {
          ebookStatus = eBookCart.Rules[i].Active;
        }
      }
      return ebookStatus;
    } else if (cart?.CartName === "Suggestions" && parseInt(bookCartNo) === 2) {
      for (j = 0; j < audioBookCart.Rules?.length; j++) {
        if (audioBookCart.Rules[j].AutoBuyRuleType === 3) {
          audioBookStatus = audioBookCart.Rules[j].Active;
        }
      }
      return audioBookStatus;
    } else if (
      cart?.CartName === "Content Expiration" &&
      parseInt(bookCartNo) === 1
    ) {
      for (i = 0; i < eBookCart.Rules?.length; i++) {
        if (eBookCart.Rules[i].AutoBuyRuleType === 1) {
          ebookStatus = eBookCart.Rules[i].Active;
        }
      }
      return ebookStatus;
    } else if (
      cart?.CartName === "Content Expiration" &&
      parseInt(bookCartNo) === 2
    ) {
      for (j = 0; j < audioBookCart.Rules?.length; j++) {
        if (audioBookCart.Rules[j].AutoBuyRuleType === 1) {
          audioBookStatus = audioBookCart.Rules[j].Active;
        }
      }
      return audioBookStatus;
    }
  };
  const handleActiveChange = (checked: any, cart: any) => {
    if (cart?.CartName === "Holds" && parseInt(bookCartNo) === 1) {
      for (var i = 0; i < eBookCart.Rules?.length; i++) {
        if (eBookCart.Rules[i].AutoBuyRuleType === 2) {
          eBookCart.Rules[i].Active = checked;
        }
      }
    } else if (cart?.CartName === "Holds" && parseInt(bookCartNo) === 2) {
      for (var j = 0; j < audioBookCart.Rules?.length; j++) {
        if (audioBookCart.Rules[j].AutoBuyRuleType === 2) {
          audioBookCart.Rules[j].Active = checked;
        }
      }
    } else if (cart?.CartName === "Suggestions" && parseInt(bookCartNo) === 1) {
      for (i = 0; i < eBookCart.Rules?.length; i++) {
        if (eBookCart.Rules[i].AutoBuyRuleType === 3) {
          eBookCart.Rules[i].Active = checked;
        }
      }
    } else if (cart?.CartName === "Suggestions" && parseInt(bookCartNo) === 2) {
      for (j = 0; j < audioBookCart.Rules?.length; j++) {
        if (audioBookCart.Rules[j].AutoBuyRuleType === 3) {
          audioBookCart.Rules[j].Active = checked;
        }
      }
    } else if (
      cart?.CartName === "Content Expiration" &&
      parseInt(bookCartNo) === 1
    ) {
      for (i = 0; i < eBookCart.Rules?.length; i++) {
        if (eBookCart.Rules[i].AutoBuyRuleType === 1) {
          eBookCart.Rules[i].Active = checked;
        }
      }
    } else if (
      cart?.CartName === "Content Expiration" &&
      parseInt(bookCartNo) === 2
    ) {
      for (j = 0; j < audioBookCart.Rules?.length; j++) {
        if (audioBookCart.Rules[j].AutoBuyRuleType === 1) {
          audioBookCart.Rules[j].Active = checked;
        }
      }
    }
    if (parseInt(bookCartNo) === 1) {
      ebookRules = eBookCart;
      dispatch(saveAutoCart({...ebookRules,Active:true}));
    } else {
      audioBookRules = audioBookCart;
      dispatch(saveAutoCart({...audioBookRules,Active:true}));
    }
  };

  const handleCartStatusActiveChange = (checked: any, cart: any) => {
    if (cart?.CartName === "Holds" && parseInt(bookCartNo) === 1) {
      for (var i = 0; i < eBookCart.Rules?.length; i++) {
        if (eBookCart.Rules[i].AutoBuyRuleType === 2) {
          eBookCart.Rules[i].Active = checked;
        }else{
          if(eBookCart.Rules[i].Active !== undefined){
            eBookCart.Rules[i].Active = false;
          }
        }
      }
    } else if (cart?.CartName === "Holds" && parseInt(bookCartNo) === 2) {
      for (var j = 0; j < audioBookCart.Rules?.length; j++) {
        if (audioBookCart.Rules[j].AutoBuyRuleType === 2) {
          audioBookCart.Rules[j].Active = checked;
        }else{
          if(audioBookCart.Rules[j].Active !== undefined){
            audioBookCart.Rules[j].Active = false;
          }
        }
      }
    } else if (cart?.CartName === "Suggestions" && parseInt(bookCartNo) === 1) {
      for (i = 0; i < eBookCart.Rules?.length; i++) {
        if (eBookCart.Rules[i].AutoBuyRuleType === 3) {
          eBookCart.Rules[i].Active = checked;
        }else{
          if(eBookCart.Rules[i].Active !== undefined){
            eBookCart.Rules[i].Active = false;
          }
        }
      }
    } else if (cart?.CartName === "Suggestions" && parseInt(bookCartNo) === 2) {
      for (j = 0; j < audioBookCart.Rules?.length; j++) {
        if (audioBookCart.Rules[j].AutoBuyRuleType === 3) {
          audioBookCart.Rules[j].Active = checked;
        }else{
          if(audioBookCart.Rules[j].Active !== undefined){
            audioBookCart.Rules[j].Active = false;
          }
        }
      }
    } else if (
      cart?.CartName === "Content Expiration" &&
      parseInt(bookCartNo) === 1
    ) {
      for (i = 0; i < eBookCart.Rules?.length; i++) {
        if (eBookCart.Rules[i].AutoBuyRuleType === 1) {
          eBookCart.Rules[i].Active = checked;
        }else{
          if(eBookCart.Rules[i].Active !== undefined){
            eBookCart.Rules[i].Active = false;
          } 
        }
      }
    } else if (
      cart?.CartName === "Content Expiration" &&
      parseInt(bookCartNo) === 2
    ) {
      for (j = 0; j < audioBookCart.Rules?.length; j++) {
        if (audioBookCart.Rules[j].AutoBuyRuleType === 1) {
          audioBookCart.Rules[j].Active = checked;
        }else{
          if(audioBookCart.Rules[j].Active !== undefined){
            audioBookCart.Rules[j].Active = false;
          }  
        }
      }
    }
    if (parseInt(bookCartNo) === 1) {
      ebookRules = eBookCart;
      dispatch(saveAutoCart({...ebookRules,Active:true}));
    } else {
      audioBookRules = audioBookCart;
      dispatch(saveAutoCart({...audioBookRules,Active:true}));
    }
  };

  const getDataUpdate = (data: boolean) => {
    if (data) {
      return editByClick;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (getDataUpdate(true)) {
      setVisible(true);
    }
  }, [DataEbooks]);

  useEffect(()=>{
  if(visible){
    setEditByUpdated(true)
  }else{
    setEditByUpdated(false)
  }
  },[visible])



  const handleAddNewRule = (
    checked: boolean,
    rule: string,
    active: boolean
  ) => {
    if (rule === "Holds" && checked === true) {
      var data = {
        AutoBuyRuleId: null,
        AutoBuyLibrarySettingsId: null,
        Active: active,
        AutoBuyRuleType: 2,
        AutoBuy: false,
        CartManagerUserId:appUser?.userId,
        NotificationUserIds: null,
        MaxStockQuantity: null,
        ExternalRefId: null,
        PurchaseCapForPeriod: null,
        RuleName: "Hold Ratio",
        RuleDescription:
          "When a book exceeds the designated hold ratio number, CAT will add to the Auto Cart as many copies of the book as needed to drop the hold ratio to the specified number.",
        ExecutionOrder: 1,
        Properties: [
          {
            Name: "HoldRatio",
            Value: 7,
          },
        ],
      };
      setAddNewRule(data);
    } else if (rule === "Content Expiration" && checked === true) {
      var dataContent = {
        AutoBuyRuleId: null,
        AutoBuyLibrarySettingsId: null,
        Active: active,
        AutoBuyRuleType: 1,
        AutoBuy: false,
        CartManagerUserId:appUser?.userId,
        NotificationUserIds: null,
        MaxStockQuantity: null,
        ExternalRefId: null,
        PurchaseCapForPeriod: null,
        RuleName: "Content Expiration",
        RuleDescription:
          "Add an additional copy when stock count becomes zero for that title and the title meets the specified minimum # of checkouts.",
        ExecutionOrder: 2,
        Properties: [
          {
            Name: "CheckoutLimit",
            Value: 10,
          },
          {
            Name: "Period",
            Value: "month",
          },
          {
            Name: "PeriodCount",
            Value: 12,
          },
        ],
      };
      setAddNewRule(dataContent);
    } else {
      var dataSugestion = {
        AutoBuyRuleId: null,
        AutoBuyLibrarySettingsId: null,
        Active: active,
        AutoBuyRuleType: 3,
        AutoBuy: false,
        CartManagerUserId:appUser?.userId,
        NotificationUserIds: null,
        MaxStockQuantity: null,
        ExternalRefId: null,
        PurchaseCapForPeriod: null,
        RuleName: "Suggestions",
        RuleDescription:
          "Add to Auto Cart a copy of a book your patrons have suggested.",
        ExecutionOrder: 3,
        Properties: [
          {
            Name: "Suggestions",
            Value: 1,
          },
          {
            Name: "Duration",
            Value: "90",
          },
          {
            Name: "IncludePresale",
            Value: true,
          },
        ],
      };
      setAddNewRule(dataSugestion);
    }
  };

  const makeNewRuleData = (addedRules: any, id: number) => {
    var newObj = [];
    var updatedAuto = autoCartsData?.filter((item: any) => {
      return item?.MediaTypeId === id;
    });
    newObj = updatedAuto[0];
    if (newObj?.Active !== undefined) {
      var newRules: any = [...newObj?.Rules, addedRules];
      newObj = [{ ...newObj, Rules: newRules }];
    }
    var newAutocartData = newObj;
    var res = autoCartsData?.map(
      (obj: { MediaTypeId: any }) =>
        newAutocartData?.find(
          (o: { MediaTypeId: any }) => o.MediaTypeId === obj.MediaTypeId
        ) || obj
    );
    return res;
  };

  useEffect(() => {
    if (addNewRule?.Active !== undefined) {
      if (parseInt(bookCartNo) === 1) {
        var dataE = updatedCommonEbook;
        dataE?.Rules?.push(addNewRule);
        if(editByUpdated){
          dispatch(addNewRuleAutoCart(makeNewRuleData(addNewRule, 1)));
        }else{
         dispatch(saveAutoCart({...dataE,Active:true}));
        }
      } else {
        var dataA = updatedCommonAbook;
        dataA?.Rules?.push(addNewRule);
        if(editByUpdated){
          dispatch(addNewRuleAutoCart(makeNewRuleData(addNewRule, 2)));
        }else{
         dispatch(saveAutoCart({...dataA,Active:true}));
        }
      }
      setAddNewRule([]);
    } else {
      return undefined;
    }
  }, [addNewRule, updatedCommonEbook, updatedCommonAbook, bookCartNo,editByUpdated]);

  useEffect(() => {
    if (saveRule) {
      if (
        updatedAbook?.AutoBuyLibrarySettingsId !==
        updatedEbook?.AutoBuyLibrarySettingsId
      ) {
        if (updatedAbook?.AutoBuyLibrarySettingsId !== undefined) {
          dispatch(saveAutoCart({...updatedAbook,Active:true}));
        } else {
          return undefined;
        }
        if (updatedEbook?.AutoBuyLibrarySettingsId !== undefined) {
          dispatch(saveAutoCart({...updatedEbook,Active:true}));
        } else {
          return undefined;
        }
      } else {
        dispatch(saveAutoCart({...updatedEbook,Active:true}));
      }
      if (saveRule) {
        setSaveRule(false);
      }
    } else {
      return undefined;
    }
  }, [updatedAbook, updatedEbook, saveRule]);

  const ebookStatus = () => {
    cartList.map((data: any) => {
      let Active;
      let final;
      let toboolean;
      const cartName = data.CartName;
      if (cartName === AutoCartType.HOLDS) {
        Active = DataEbooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 2
        ).map((data: any) => data.Active);
        final = Active?.toString();
        toboolean = final === "true" ? true : false;
        return setEBokHold(toboolean);
      } else if (cartName === AutoCartType.CONTENT_EXP) {
        Active = DataEbooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 1
        ).map((data: any) => data.Active);
        final = Active?.toString();
        toboolean = final === "true" ? true : false;
        return setEbookContent(toboolean);
      } else if (cartName === AutoCartType.SUGGESTIONS) {
        Active = DataEbooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 3
        ).map((data: any) => data.Active);
        final = Active?.toString();
        toboolean = final === "true" ? true : false;
        return setEbookSuggest(toboolean);
      }
      return Active || final;
    });
  };

  const audioBookStatus = () => {
    cartList.map((data: any) => {
      let Active;
      let final;
      let toboolean;
      const cartName = data.CartName;
      if (cartName === AutoCartType.HOLDS) {
        Active = DataAudioBooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 2
        ).map((data: any) => data.Active);
        final = Active?.toString();
        toboolean = final === "true" ? true : false;
        return setAudioHold(toboolean);
      } else if (cartName === AutoCartType.CONTENT_EXP) {
        Active = DataAudioBooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 1
        ).map((data: any) => data.Active);
        final = Active?.toString();
        toboolean = final === "true" ? true : false;
        return setAudioContent(toboolean);
      } else if (cartName === AutoCartType.SUGGESTIONS) {
        Active = DataAudioBooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 3
        ).map((data: any) => data.Active);
        final = Active?.toString();
        toboolean = final === "true" ? true : false;
        return setAudioSuggestion(toboolean);
      }
      return Active;
    });
  };

  const savecommonSettings = () => {
    return setSaveCommon(true);
  };

  const getTabCSNumber = (val: any) => {
    setBookCartNo(val);
    setEbookCartLimit(false)
    setAudioBookCartLimit(false)
    setAmountReachRule("")
    dispatch(recentTab(val))
  };

  useEffect(() => {
    if (saveCommon !== false) {
      if (getCommonSpendLimit !== "null") {
        if (
          getCommonSpendLimit !== "")
         {
          setSaveCommon(false);
          setErrorSpendLimit(false);
          if (updatedCommonEbook.Active !== undefined) {
            if(!commonFormEror){
              dispatch(saveAutoCart({...updatedCommonEbook,Active:true}));
            }  
          }
          if (updatedCommonAbook.Active !== undefined) {
            if(!commonAudioFormEror){
              dispatch(saveAutoCart({...updatedCommonAbook,Active:true}));
            }
          }
        } else {
          setSaveCommon(false);
          setErrorSpendLimit(true);
        }
      } else {
        setSaveCommon(false);
        setErrorSpendLimit(true);
      }
    } else {
      setSaveCommon(false);
      return undefined;
    }
  }, [
    saveCommon,
    updatedCommonAbook,
    updatedCommonEbook,
    getCommonSpendLimit,
    errorSpendLimit,
  ]);

  useEffect(() => {
    if (parseInt(bookCartNo) === 1) {
      setUpdatedCommonEbook(commonUpdatedEbook);
    } else if (parseInt(bookCartNo) === 2) {
      setUpdatedCommonAbook(commonUpdatedEbook);
    } else {
      return undefined;
    }
  }, [bookCartNo, commonUpdatedEbook]);

  useEffect(() => {
    ebookStatus();
    audioBookStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartList]);

  const purchaseCap = (name: any) => {
    var puchasecaps;
    name === AutoCartType.HOLDS
      ? (puchasecaps = DataEbooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 2
        ).map((data: any) => data.AutoBuy))
      : name === AutoCartType.CONTENT_EXP
      ? (puchasecaps = DataEbooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 1
        ).map((data: any) => data.AutoBuy))
      : (puchasecaps = DataEbooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 3
        ).map((data: any) => data.AutoBuy));
    return puchasecaps;
  };

  const AudiopurchaseCap = (name: any) => {
    var Apuchasecaps;
    name === AutoCartType.HOLDS
      ? (Apuchasecaps = DataAudioBooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 2
        ).map((data: any) => data.AutoBuy))
      : name === AutoCartType.CONTENT_EXP
      ? (Apuchasecaps = DataAudioBooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 1
        ).map((data: any) => data.AutoBuy))
      : (Apuchasecaps = DataAudioBooks[0]?.Rules.filter(
          (data: any) => data.AutoBuyRuleType === 3
        ).map((data: any) => data.AutoBuy));
    return Apuchasecaps;
  };

  useEffect(() => {
    if (visible === false) {
      var apiData = autoCartsData;
      dispatch(resetAutoCarts(apiData));
    }
  }, [visible]);

  useEffect(() => {
    if (
      formState?.loading ||
      saveAutoCartFormState?.loading ||
      resetformState?.loading ||
      fetchAutoCartFormState?.loading
    ) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
  }, [saveAutoCartFormState, formState, resetformState, fetchAutoCartFormState]);

  useEffect(() => {
    if (parseInt(bookCartNo) === 1) {
      setPresentBook(ruleArePresentEbook);
    } else {
      setPresentBook(ruleArePresentAbook);
    }
  }, [ruleArePresentAbook, ruleArePresentEbook, bookCartNo]);

  const dateModify = (date:any) => {
    const myStr = date !== null || undefined ? date.substr(0, 10) : "";
    const currentDate =  moment(myStr).tz('America/Los_Angeles').format(dateFormat)
    return currentDate === "Invalid date" ? "" : currentDate
  }

  useEffect(() => {
    if(DataEbooks[0] !== undefined && (parseInt(bookCartNo) === 1 )){
      var ebookCartData = carts.filter((cart :any) => cart.CartTypeCode === "AutoBuyCart")
      var ebookSpendingLimit = DataEbooks[0]?.PurchaseCapForPeriod
      if(ebookCartData[0]?.TotalCartAmount > ebookSpendingLimit ) {
        setEbookCartLimit(true)
        setPurchaseCapPeriod(DataEbooks[0]?.PurchaseCapForPeriod)
        setPurchaseCapReachDate(DataEbooks[0]?.DatePurchaseCapReached)
        setPeriodRunningTotal(DataEbooks[0]?.AutoBuyPeriodRunningTotal)
      } else {
      for (var i = 0; i < DataEbooks[0]?.Rules.length; i++) {
        if (DataEbooks[0]?.Rules[i]?.AutoBuyRuleType === 1) {
          var ebookContentExpirationCart = carts.filter((data: any) => data.CartTypeCode?.includes("AutoBuyCart") && data.CartName?.includes("ContentExpiration"));
          var ebookContentExpirationSpend = DataEbooks[0]?.Rules[i].PurchaseCapForPeriod
          if(ebookContentExpirationCart[0]?.TotalCartAmount > ebookContentExpirationSpend) {
            setEbookCartLimit(true)
            setPurchaseCapPeriod(null)
            setPurchaseCapReachDate(DataEbooks[0]?.Rules[i].DatePurchaseCapReached)
            setPeriodRunningTotal(null)
            setAmountReachRule("Content Expiration")
          } else {
            setEbookCartLimit(false)
          }
        }
      }

      for (var i = 0; i < DataEbooks[0].Rules.length; i++) {
        if (DataEbooks[0].Rules[i].AutoBuyRuleType === 2) {
          var ebookHoldCart = carts.filter((data: any) => data.CartTypeCode?.includes("AutoBuyCart") && data.CartName?.includes("HoldRatio"));
          var ebookHoldSpend = DataEbooks[0]?.Rules[i].PurchaseCapForPeriod
          if(ebookHoldCart[0]?.TotalCartAmount > ebookHoldSpend) {
            setEbookCartLimit(true)
            setPurchaseCapPeriod(null)
            setPurchaseCapReachDate(DataEbooks[0]?.Rules[i].DatePurchaseCapReached)
            setPeriodRunningTotal(null)
            setAmountReachRule('Holds')
          } else {
            setEbookCartLimit(false)
          }
        }
      }

      for (var i = 0; i < DataEbooks[0]?.Rules.length; i++) {
        if (DataEbooks[0]?.Rules[i].AutoBuyRuleType === 3) {
          var ebookSuggestionsCart = carts.filter((data: any) => data.CartTypeCode?.includes("AutoBuyCart") && data.CartName?.includes("Suggestions"));
          var ebookSuggestionsSpend = DataEbooks[0]?.Rules[i].PurchaseCapForPeriod
          if(ebookSuggestionsCart[0]?.TotalCartAmount > ebookSuggestionsSpend) {
            setEbookCartLimit(true)
            setPurchaseCapPeriod(null)
            setPurchaseCapReachDate(DataEbooks[0].Rules[i].DatePurchaseCapReached)
            setPeriodRunningTotal(null)
            setAmountReachRule('Suggestions')
          } else {
            setEbookCartLimit(false)
          }
        }
      }
    }
    }

    if(DataAudioBooks[0] !== undefined && ( parseInt(bookCartNo) === 2)) {
      var audioCartData = carts.filter((cart :any) => cart.CartTypeCode === "AutoBuyAudioBookCart")
      var audioSpendingLimit = DataAudioBooks[0]?.PurchaseCapForPeriod
      if(audioCartData[0]?.TotalCartAmount > audioSpendingLimit) {
        setAudioBookCartLimit(true)
        setPurchaseCapPeriod(DataAudioBooks[0]?.PurchaseCapForPeriod)
        setPurchaseCapReachDate(DataAudioBooks[0]?.DatePurchaseCapReached)
        setPeriodRunningTotal(DataAudioBooks[0]?.AutoBuyPeriodRunningTotal)
      } else {
      for (var i = 0; i < DataAudioBooks[0].Rules.length; i++) {
          if (DataAudioBooks[0].Rules[i].AutoBuyRuleType === 1) {
            var audioBookContentExpirationCart = carts.filter((data: any) => data.CartTypeCode?.includes("Audio") && data.CartName?.includes("ContentExpiration"));
            var audioBookContentExpirationSpend = DataAudioBooks[0]?.Rules[i].PurchaseCapForPeriod
            if(audioBookContentExpirationCart[0]?.TotalCartAmount > audioBookContentExpirationSpend) {
              setAudioBookCartLimit(true)
              setPurchaseCapPeriod(null)
              setPurchaseCapReachDate(DataAudioBooks[0].Rules[i].DatePurchaseCapReached)
              setPeriodRunningTotal(null)
              setAmountReachRule('Content Expiration')
            } else {
              setAudioBookCartLimit(false)
            }
          }
        }

        for (var i = 0; i < DataAudioBooks[0].Rules.length; i++) {
          if (DataAudioBooks[0]?.Rules[i].AutoBuyRuleType === 2) {
            var audioHoldCart = carts.filter((data: any) => data.CartTypeCode?.includes("Audio") && data.CartName?.includes("HoldRatio"));
            var audioHoldSpend = DataAudioBooks[0]?.Rules[i].PurchaseCapForPeriod
            if(audioHoldCart[0]?.TotalCartAmount > audioHoldSpend) {
              setAudioBookCartLimit(true)
              setPurchaseCapPeriod(null)
              setPurchaseCapReachDate(DataAudioBooks[0].Rules[i].DatePurchaseCapReached)
              setPeriodRunningTotal(null)
              setAmountReachRule('Holds')
            } else {
              setAudioBookCartLimit(false)
            }
          }
        }

        for (var i = 0; i < DataAudioBooks[0].Rules.length; i++) {
          if (DataAudioBooks[0]?.Rules[i].AutoBuyRuleType === 3) {
            var audioSuggestionsCart = carts.filter((data: any) => data.CartTypeCode?.includes("Audio") && data.CartName?.includes("Suggestions"));
            var audioSuggestionsSpend = DataAudioBooks[0].Rules[i].PurchaseCapForPeriod
            if(audioSuggestionsCart[0]?.TotalCartAmount > audioSuggestionsSpend) {
              setAudioBookCartLimit(true)
              setPurchaseCapPeriod(DataAudioBooks[0]?.PurchaseCapForPeriod)
              setPurchaseCapReachDate(DataAudioBooks[0]?.DatePurchaseCapReached)
              setPeriodRunningTotal(DataAudioBooks[0]?.AutoBuyPeriodRunningTotal)
              setAmountReachRule('Suggestions')
            } else {
              setAudioBookCartLimit(false)
            }
          }
        }
      }
     }
  }, [carts,bookCartNo,DataEbooks,DataAudioBooks])

  useEffect(() => {
    var ebokHoldSpent
    var holdRead
    var ebokContentSpent
    var contentRead
    var ebokSuggestionSpent
    var suggestRead

    if (parseInt(bookCartNo) === 1){
       ebokHoldSpent = DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2)
       holdRead = ebokHoldSpent !== undefined ? ebokHoldSpent[0]?.AutoBuy : false
      setHoldSpent(holdRead)
       ebokContentSpent = DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1)
       contentRead = ebokContentSpent !== undefined ? ebokContentSpent[0]?.AutoBuy : false
      setContentExpSpent(contentRead)
       ebokSuggestionSpent = DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3)
       suggestRead = ebokSuggestionSpent !== undefined ? ebokSuggestionSpent[0]?.AutoBuy : false
       setSuggestionSpent(suggestRead)
    }
    if (parseInt(bookCartNo) === 2){
       ebokHoldSpent = DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2)
       holdRead = ebokHoldSpent !== undefined ? ebokHoldSpent[0]?.AutoBuy : false
       setHoldSpent(holdRead)
       ebokContentSpent = DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1)
       contentRead = ebokContentSpent !== undefined ? ebokContentSpent[0]?.AutoBuy : false
       setContentExpSpent(contentRead)
       ebokSuggestionSpent = DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3)
       suggestRead = ebokSuggestionSpent !== undefined ? ebokSuggestionSpent[0]?.AutoBuy : false
       setSuggestionSpent(suggestRead)
    }
  }, [bookCartNo,DataAudioBooks,DataEbooks])

  const ruleAutoPurchase = (name:any) => {
    if(name === AutoCartType.HOLDS ) {
        return holdSpent
    } else if (name === AutoCartType.CONTENT_EXP) {
        return contentExpSpent
    } else {
        return suggestionSpent
    }
  }

  const showNotification = (type: any) => {
    if (type === "error") {
        message.error({
          content: "Access Denied",
          key: "error",
        });
    }
  };

  const alertForEbooks:any = (ebookCartLimit && (purchaseCapReachDate !== null) ?
    <div className="alertBorder">
      <div className="messHed">
      <WarningOutlined className="alertWarningIcon" />Alert: Limit reached
      </div>
      <div className="messLine pb-10">
        The Auto Cart process determined on {dateModify(purchaseCapReachDate)} that the Monthly Auto Cart Spending Limit had been reached. No additional books will be purchased by the Auto Cart process this month. Ebooks that would have been purchased have been placed into the Auto Cart.
      </div>
      <div className="messLine">
        Auto Cart purchasing will resume at the beginning of the next month. To resume purchasing this month, increase the Monthly Auto Cart Spending Limit which will take effect the following day.
      </div>
      <div className="purLimBox pt-10">
        {(purchaseCapPeriod !== null) ? 
        <div className="purLimRow"><span className="purLimRowHed">Current Purchase Limit: </span>{currency}{Number.parseFloat(purchaseCapPeriod).toFixed(2)}</div>
        : null
        }
        {
          (periodRunningTotal !== null) ?
        <div className="purLimRow"><span className="purLimRowHed">Amount Spent: </span>{currency}{Number.parseFloat(periodRunningTotal).toFixed(2)}</div>
        : null
        }
        <div className="purLimRow"><span className="purLimRowHed">Date Limit Reached: </span> {dateModify(purchaseCapReachDate)}</div>
      </div>
    </div>
    : null
  )

  const alertForAbooks:any = (audioBookCartLimit && (purchaseCapReachDate !== null) ?
  <div className="alertBorder">
    <div className="messHed">
    <WarningOutlined className="alertWarningIcon" />Alert: Limit reached
    </div>
    <div className="messLine pb-10">
      The Auto Cart process determined on {dateModify(purchaseCapReachDate)} that the Monthly Auto Cart Spending Limit had been reached. No additional books will be purchased by the Auto Cart process this month. AudioBooks that would have been purchased have been placed into the Auto Cart.
    </div>
    <div className="messLine">
      Auto Cart purchasing will resume at the beginning of the next month. To resume purchasing this month, increase the Monthly Auto Cart Spending Limit which will take effect the following day.
    </div>
    <div className="purLimBox pt-10">
      {
        (purchaseCapPeriod !== null) ?
      <div className="purLimRow"><span className="purLimRowHed">Current Purchase Limit: </span>{currency}{Number.parseFloat(purchaseCapPeriod).toFixed(2)}</div>
      : null
      }
      {
        (periodRunningTotal !== null) ?
      <div className="purLimRow"><span className="purLimRowHed">Amount Spent: </span>{currency}{Number.parseFloat(periodRunningTotal).toFixed(2)}</div>
        : null
      }
      <div className="purLimRow"><span className="purLimRowHed">Date Limit Reached: </span>{dateModify(purchaseCapReachDate)}</div>
    </div>
  </div>
   :
  null
)

  return (
    <SpinnerLoader loading={formloading}>
     
        <Row className="autoCarts-note">
          <Col span={24}>
            <p className="newLine">{Messages.AUTO_CART_MESSAGE}</p>
          </Col>
        </Row>
        {bookCartNo === "3"? null :
        <Col className="commonsettingSave" span={24}>
          {appUser?.isLibraryBuyer === true ? (
            <Button type="primary confirm-button" onClick={savecommonSettings}>
              Save
            </Button>
          ) : (
            <></>
          )}
        </Col>}
        <Tabs onChange={getTabCSNumber} activeKey={`${bookCartNo}`} className="cart-tabs-settings">
          <TabPane tab="ebooks" key="1" >
            <CompWrapper otherClass = "scroll" name= "scroll" loading={formloading} getscrollHeight={setGetScrollHeight} >
            {alertForEbooks}
            <AutoCartCommon
              title="eBooks"
              libId={lib_Id}
              cart={selectedCart}
              rulesData={DataEbooks[0]}
              managerOptions={sorterFun(getUserOptions(1),1)}
              notifyOptions={sorterFun(getUserOptions(0),0)}
              formState={formState}
              getBookData={setCommonUpdatedBook}
              getSpendLimit={setGetCommonSpendLimit}
              errorForm={errorSpendLimit}
              formError={setCommonFormEror}
            />
 <div className="allRulesCard">
         {cartList?.map((data: any, index: number) => {
          return (
            <div key={index}>
              {/* {presentBook?.includes(data?.CartName) !== true ? <></> : */}
                <Row className="cart-item" key={index}>
                  <Col span={24}>
                    <Row>
                      <Col xs={12} sm={12} md={14} lg={16} xl={16}>
                        <span className="auto-cart-title" onClick={() => { }}>
                          {data.CartName}
                        </span>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={10}
                        lg={8}
                        xl={8}
                        className="cart-edit-value"
                      >
                        {/* Updated on {data.LastUpdate} */}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="discriptionStyle">
                        {data.CartName === AutoCartType.HOLDS ? (
                          <>
                            When a book exceeds the designated hold ratio
                            number, CAT will add to the Auto Cart as many copies
                            of the book as needed to drop the hold ratio to the
                            specified number.
                          </>
                        ) : data.CartName === AutoCartType.CONTENT_EXP ? (
                          <>
                            Add an additional copy when stock count becomes zero
                            for that title and the title meets the specified
                            minimum # of checkouts.
                          </>
                        ) : data.CartName === AutoCartType.SUGGESTIONS ? (
                          <>
                            Add to Auto Cart a copy of a book your patrons have
                            suggested.
                          </>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="cart-details">
                      {parseInt(bookCartNo) === 1 ?
                        <Col xs={10} sm={4} md={3} lg={3} xl={4}>
                          {data.CartName === AutoCartType.HOLDS ? (
                            ebookHold ? (
                              ebookActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : data.CartName === AutoCartType.CONTENT_EXP ? (
                            ebookContent ? (
                              ebookActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : data.CartName === AutoCartType.SUGGESTIONS ? (
                            ebookSuggest ? (
                              ebookActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : null}
                          <span className="cart-amount">ebooks</span>
                        </Col>
                        :
                        <Col xs={10} sm={4} md={3} lg={3} xl={4}>
                          {data.CartName === AutoCartType.HOLDS ? (
                            audioHold ? (
                              audioActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : data.CartName === AutoCartType.CONTENT_EXP ? (
                            audioContent ? (
                              audioActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : data.CartName === AutoCartType.SUGGESTIONS ? (
                            audioSuggestion ? (
                              audioActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : null}
                          <span className="cart-amount">AudioBooks</span>
                        </Col>
                      }
                      {parseInt(bookCartNo) === 1 ?
                        <Col xs={10} sm={4} md={3} lg={3} xl={3}>
                          {
                            DataEbooks[0]?.AutocartProcessingStrategyId === "LibraryLevel"  ?
                              autoCartsData[0]?.AutoBuy === true ?
                                <span className="cart-edit-value">
                                  Auto Purchase
                                </span>
                                :
                                <span className="cart-edit-value">
                                  Manual Purchase
                                </span>
                              :
                              (data.CartName === AutoCartType.HOLDS)
                                ? ebookHold
                                  ? purchaseCap(data?.CartName)[0] ?
                                    <span className="cart-edit-value">
                                      Auto Purchase
                                    </span>
                                    :
                                    <span className="cart-edit-value">
                                      Manual Purchase
                                    </span>
                                  : ""
                                : (data.CartName === AutoCartType.CONTENT_EXP)
                                  ? ebookContent
                                    ? purchaseCap(data?.CartName)[0] ?
                                      <span className="cart-edit-value">
                                        Auto Purchase
                                      </span>
                                      :
                                      <span className="cart-edit-value">
                                        Manual Purchase
                                      </span>
                                    : ""
                                  : (data.CartName === AutoCartType.SUGGESTIONS)
                                    ? ebookSuggest
                                      ? purchaseCap(data?.CartName)[0] ?
                                        <span className="cart-edit-value">
                                          Auto Purchase
                                        </span>
                                        :
                                        <span className="cart-edit-value">
                                          Manual Purchase
                                        </span>
                                      : ""
                                    : null
                          }
                        </Col>
                        :
                        <Col xs={10} sm={4} md={3} lg={3} xl={3}>
                          {
                            DataAudioBooks[0]?.AutocartProcessingStrategyId === "LibraryLevel"  ?
                              autoCartsData[1].AutoBuy === true ?
                                <span className="cart-edit-value">
                                  Auto Purchase
                                </span>
                                :
                                <span className="cart-edit-value">
                                  Manual Purchase
                                </span>
                              :
                              (data.CartName === AutoCartType.HOLDS)
                                ? audioHold
                                  ? AudiopurchaseCap(data?.CartName)[0] ?
                                    <span className="cart-edit-value">
                                      Auto Purchase
                                    </span>
                                    :
                                    <span className="cart-edit-value">
                                      Manual Purchase
                                    </span>
                                  : ""
                                : (data.CartName === AutoCartType.CONTENT_EXP)
                                  ? audioContent
                                    ? AudiopurchaseCap(data?.CartName)[0] ?
                                      <span className="cart-edit-value">
                                        Auto Purchase
                                      </span>
                                      :
                                      <span className="cart-edit-value">
                                        Manual Purchase
                                      </span>
                                    : ""
                                  : (data.CartName === AutoCartType.SUGGESTIONS)
                                    ? audioSuggestion
                                      ? AudiopurchaseCap(data?.CartName)[0] ?
                                        <span className="cart-edit-value">
                                          Auto Purchase
                                        </span>
                                        :
                                        <span className="cart-edit-value">
                                          Manual Purchase
                                        </span>
                                      : ""
                                    : null
                          }
                        </Col>
                      }

                      <Col xs={10} sm={4} md={4} lg={4} xl={4}>
                        {parseInt(bookCartNo) === 1 ?
                          DataEbooks[0]?.AutocartProcessingStrategyId === "LibraryLevel" ?
                            <></>
                            :
                            <>
                              {ruleAutoPurchase(data.CartName) ? 
                              <div className={`${amountReachRule === data.CartName ? "spendLimitText cart-text capReached" : "cart-text spendLimitText"}`}>Spending Limit</div>
                              : null }
                              {ruleAutoPurchase(data.CartName) ? 
                              <div className={`${amountReachRule === data.CartName ? "spendLimitText capReached" : "spendLimitText"}`}>
                                { (data.CartName === AutoCartType.HOLDS) ?
                                  ebookHold ?
                                    (DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2).map((data:any) => data.PurchaseCapForPeriod)[0]) === null ?
                                      currency + 0
                                      : currency + DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2).map((data:any) => data.PurchaseCapForPeriod)[0]
                                    : ''
                                  : (data.CartName === AutoCartType.CONTENT_EXP) ?
                                    ebookContent ?
                                      (DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1).map((data:any) => data.PurchaseCapForPeriod)[0] === null) ?
                                        currency + 0
                                        : currency + DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1).map((data:any) => data.PurchaseCapForPeriod)[0]
                                      : ''
                                    : (data.CartName === AutoCartType.SUGGESTIONS) ?
                                      ebookSuggest ?
                                        (DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3).map((data:any) => data.PurchaseCapForPeriod)[0] === null) ?
                                          currency + 0
                                          : currency + DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3).map((data:any) => data.PurchaseCapForPeriod)[0]
                                        : ''
                                      : null
                                }
                              </div>
                               : null }
                            </>

                          :
                          DataAudioBooks[0]?.AutocartProcessingStrategyId === "LibraryLevel" ?
                            <></>
                            :
                            <>
                              <div className="cart-text spendLimitText">Spending Limit</div>
                              <div className="spendLimitText">
                                { (data.CartName === AutoCartType.HOLDS) ?
                                  audioHold ?
                                    (DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2).map((data:any) => data.PurchaseCapForPeriod)[0]) === null ?
                                      currency + 0
                                      : currency + DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2).map((data:any) => data.PurchaseCapForPeriod)[0]
                                    : ''
                                  : (data.CartName === AutoCartType.CONTENT_EXP)?
                                    audioContent ?
                                      (DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1).map((data:any) => data.PurchaseCapForPeriod)[0]) === null ?
                                        currency + 0
                                        : currency + DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1).map((data:any) => data.PurchaseCapForPeriod)[0]
                                      : ''
                                    : (data.CartName === AutoCartType.SUGGESTIONS) ?
                                      audioSuggestion ?
                                        (DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3).map((data:any) => data.PurchaseCapForPeriod)[0] === null ) ?
                                          currency + 0
                                          : currency + DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3).map((data:any) => data.PurchaseCapForPeriod)[0]
                                        : ''
                                      : null
                                }
                              </div>
                            </>
                        }
                      </Col>
                      <Col xs={10} sm={6} md={5} lg={5} xl={4}>
                        {/* <span className="cart-amount">{currency}{data.TotalCartAmount}</span> */}
                        {
                          DataEbooks[0]?.AutocartProcessingStrategyId === "LibraryLevel" ?
                          null
                          :
                          (data.CartName === AutoCartType.HOLDS) ?
                          holdSpent ?
                          <div className="cart-text amountSpent">
                          Amount spent this month 
                          </div>
                          :null
                          : (data.CartName === AutoCartType.CONTENT_EXP) ?
                            contentExpSpent ?
                            <div className="cart-text amountSpent">
                              Amount spent this month
                            </div>
                            :null
                          : (data.CartName === AutoCartType.SUGGESTIONS) ?
                          suggestionSpent ?
                          <div className="cart-text amountSpent">
                          Amount spent this month
                        </div>
                        : null
                        :null
                        }
                        {/* {
                          (holdSpent || contentExpSpent || suggestionSpent) ?
                          <div className="cart-text amountSpent">
                            Amount spent this month
                          </div>
                        :
                        null
                        } */}
                        { parseInt(bookCartNo) === 1 ?
                          <div className="amountSpent">
                            {
                              (data.CartName === AutoCartType.HOLDS) ?
                                ebookHold && holdSpent ?
                                  (DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2).map((data:any) => data.AutoBuyPeriodRunningTotal)[0]) === null ?
                                    currency + 0
                                    : currency + DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2).map((data:any) => data.AutoBuyPeriodRunningTotal)[0]
                                  : ''
                                : (data.CartName === AutoCartType.CONTENT_EXP) ?
                                  ebookContent && contentExpSpent ?
                                    (DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1).map((data:any) => data.AutoBuyPeriodRunningTotal)[0] === null) ?
                                      currency + 0
                                      : currency + DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1).map((data:any) => data.AutoBuyPeriodRunningTotal)[0]
                                    : ''
                                  : (data.CartName === AutoCartType.SUGGESTIONS) ?
                                    ebookSuggest && suggestionSpent ?
                                      (DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3).map((data:any) => data.AutoBuyPeriodRunningTotal)[0] === null) ?
                                        currency + 0
                                        : currency + DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3).map((data:any) => data.AutoBuyPeriodRunningTotal)[0]
                                      : ''
                                    : null
                            }
                          </div>
                          :
                          <div className="amountSpent">
                            {
                              (data.CartName === AutoCartType.HOLDS) ?
                                audioHold ?
                                  (DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2).map((data:any) => data.AutoBuyPeriodRunningTotal)[0]) === null ?
                                    currency + 0
                                    : currency + DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2).map((data:any) => data.AutoBuyPeriodRunningTotal)[0]
                                  : ''
                                : (data.CartName === AutoCartType.CONTENT_EXP)?
                                  audioContent ?
                                    (DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1).map((data:any) => data.AutoBuyPeriodRunningTotal)[0]) === null ?
                                      currency + 0
                                      : currency + DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1).map((data:any) => data.AutoBuyPeriodRunningTotal)[0]
                                    : ''
                                  : (data.CartName === AutoCartType.SUGGESTIONS) ?
                                    audioSuggestion ?
                                      (DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3).map((data:any) => data.AutoBuyPeriodRunningTotal)[0] === null ) ?
                                        currency + 0
                                        : currency + DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3).map((data:any) => data.AutoBuyPeriodRunningTotal)[0]
                                      : ''
                                    : null
                            }
                          </div>
                        }
                      </Col>
                      <Col xs={14} sm={12} md={4} lg={4} xl={5}>
                        {/* ExternalRefId */}
                        {parseInt(bookCartNo) === 1 ?
                          DataEbooks[0]?.AutocartProcessingStrategyId === "LibraryLevel" ?
                            <></>
                            :
                            <>
                               {ruleAutoPurchase(data.CartName) ? 
                              <div className="cart-text spendLimitText">PO Reference#</div>
                              : null }
                              <div className="spendLimitText">
                                {(data.CartName === AutoCartType.HOLDS) ?
                                  ebookHold && holdSpent ?
                                    DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2).map((data:any) => data.ExternalRefId)[0]
                                    : ''
                                  : (data.CartName === AutoCartType.CONTENT_EXP) ?
                                    ebookContent && contentExpSpent ?
                                      DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1).map((data:any) => data.ExternalRefId)[0]
                                      : ''
                                    : (data.CartName === AutoCartType.SUGGESTIONS) ?
                                      ebookSuggest && suggestionSpent ?
                                        DataEbooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3).map((data:any) => data.ExternalRefId)[0]
                                        : ''
                                      : null}
                              </div>
                            </>
                          :
                          DataAudioBooks[0]?.AutocartProcessingStrategyId === "LibraryLevel" ?
                            <></>
                            :
                            <>
                              <div className="cart-text spendLimitText">PO Reference#</div>
                              <div className="spendLimitText">
                                {
                                  (data.CartName === AutoCartType.HOLDS) ?
                                    audioHold ?
                                      DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 2).map((data:any) => data.ExternalRefId)[0]
                                      : ''
                                    : (data.CartName === AutoCartType.CONTENT_EXP)?
                                      audioContent ?
                                        DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 1).map((data:any) => data.ExternalRefId)[0]
                                        : ''
                                      : (data.CartName === AutoCartType.SUGGESTIONS) ?
                                        audioSuggestion ?
                                          DataAudioBooks[0]?.Rules.filter((data: any) => data.AutoBuyRuleType === 3).map((data:any) => data.ExternalRefId)[0]
                                          : ''
                                        : null
                                }
                              </div>
                            </>
                        }
                      </Col>
                      {appUser?.isLibraryBuyer === true ?
                        <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                          {presentBook?.includes(data?.CartName) !== true ? (   
                            bookCartNoWithActive?                      
                                <>
                                  <span className="edit-rules deletedRules">
                                    <b>
                                      <u
                                        onClick={() => {
                                          if( autoByRuleError?.length === 0) {
                                            dispatch(setEbookScrollScroll(getscrollheight))
                                            setEditByClick(true);
                                            setEditByUpdated(true)
                                            setSelectedCart(data);
                                            handleAddNewRule(
                                              true,
                                              data?.CartName,
                                              false
                                            );
                                          } else {
                                            showNotification("error");
                                          }
                                        }}
                                      >
                                        Edit Rules
                                      </u>
                                    </b>
                                  </span>
                                  <Switch
                                    onChange={(checked: any) =>
                                     {
                                      dispatch(setEbookScrollScroll(getscrollheight))
                                        setEditByUpdated(false)
                                        handleAddNewRule(
                                        checked,
                                        data?.CartName,
                                        true
                                      )}
                                    }
                                  />
                                </>     
                                :
                                <>
                                <span className="edit-rules deletedRules activeFalse">
                                  <b>
                                    <u
                                      onClick={() => {
                                        if( autoByRuleError?.length === 0) {
                                          setEditByClick(true);
                                          setEditByUpdated(true)
                                          setSelectedCart(data);
                                          handleAddNewRule(
                                            true,
                                            data?.CartName,
                                            false
                                          );
                                          dispatch(setEbookScrollScroll(getscrollheight))
                                        } else {
                                          showNotification("error");
                                        }
                                      }}
                                    >
                                      Edit Rules
                                    </u>
                                  </b>
                                </span>
                                <Switch
                                  onChange={(checked: any) =>
                                   {
                                      setEditByUpdated(false)
                                      handleAddNewRule(
                                      checked,
                                      data?.CartName,
                                      true
                                    )
                                    dispatch(setEbookScrollScroll(getscrollheight))
                                  }
                                  }
                                />
                              </>                    
                          ) : bookCartNoWithActive ? (
                            <>
                              <span
                                className="edit-rules normalRules"
                                onClick={() => {
                                  if( autoByRuleError?.length === 0) {
                                    setVisible(true);
                                    setSelectedCart(data);
                                    dispatch(setEbookScrollScroll(getscrollheight))
                                  } else {
                                    showNotification("error");
                                  }
                                }}
                              >
                                <b>
                                  <u>Edit Rules</u>
                                </b>
                              </span>
                              <Switch
                                checked={getActiveStatus(data)}
                                onChange={(checked: any) =>{
                                  handleActiveChange(checked, data)
                                  dispatch(setEbookScrollScroll(getscrollheight))}
                                }
                              />
                            </>
                          ) : (
                            <>
                              <span
                                onClick={() => {
                                  if( autoByRuleError?.length === 0) {
                                    setVisible(true);
                                    setSelectedCart(data);
                                    dispatch(setEbookScrollScroll(getscrollheight))
                                  } else {
                                    showNotification("error");
                                  }
                                }}
                               className="edit-rules normalRules activeTrue">
                                <b>
                                  <u>Edit Rules</u>
                                </b>
                              </span>
                              <Switch onChange={(checked: any) =>{
                                  handleCartStatusActiveChange(checked, data)
                                  dispatch(setEbookScrollScroll(getscrollheight))}
                                }/>
                            </>
                          )}
                        </Col>:
                        <Col xs={24} sm={8} md={4} lg={4} xl={4}><></></Col>}
                    </Row>
                  </Col>
                </Row>
         {/* } */}

            </div>
          );
        })}
         </div>
         </CompWrapper>
          </TabPane>
          { userHasPermision.includes("AudioBooks") ?
          <TabPane tab="AudioBooks" key="2" >
            <CompWrapper otherClass = "scroll2" name= "scroll2" loading={formloading} getscrollHeight={setGetScrollHeight}>
            {alertForAbooks}
            <AutoCartCommon
              title="AudioBooks"
              libId={lib_Id}
              cart={selectedCart}
              rulesData={DataAudioBooks[0]}
              managerOptions={sorterFun(getUserOptions(1),1)}
              notifyOptions={sorterFun(getUserOptions(0),0)}
              formState={formState}
              getBookData={setCommonUpdatedBook}
              getSpendLimit={setGetCommonSpendLimit}
              errorForm={errorSpendLimit}
              formError={setCommonAudioFormEror}
            />
             <div className="allRulesCard">
         {cartList.map((data: any, index: number) => {
          return (
             <div key={index}>
                {/* {presentBook?.includes(data?.CartName) !== true ? <></> : */}
                <Row className="cart-item" key={index}>
                  <Col span={24}>
                    <Row>
                      <Col xs={12} sm={12} md={14} lg={16} xl={16}>
                        <span className="auto-cart-title" onClick={() => {}}>
                          {data.CartName}
                        </span>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={10}
                        lg={8}
                        xl={8}
                        className="cart-edit-value"
                      >
                        {/* Updated on {data.LastUpdate} */}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="discriptionStyle">
                        {data.CartName === AutoCartType.HOLDS ? (
                          <>
                            When a book exceeds the designated hold ratio
                            number, CAT will add to the Auto Cart as many copies
                            of the book as needed to drop the hold ratio to the
                            specified number.
                          </>
                        ) : data.CartName === AutoCartType.CONTENT_EXP ? (
                          <>
                            Add an additional copy when stock count becomes zero
                            for that title and the title meets the specified
                            minimum # of checkouts.
                          </>
                        ) : data.CartName === AutoCartType.SUGGESTIONS ? (
                          <>
                            Add to Auto Cart a copy of a book your patrons have
                            suggested.
                          </>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="cart-details">
                      {parseInt(bookCartNo) === 1 ? (
                        <Col xs={10} sm={4} md={3} lg={3} xl={4}>
                          {data.CartName === AutoCartType.HOLDS ? (
                            ebookHold ? (
                              ebookActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : data.CartName === AutoCartType.CONTENT_EXP ? (
                            ebookContent ? (
                              ebookActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : data.CartName === AutoCartType.SUGGESTIONS ? (
                            ebookSuggest ? (
                              ebookActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : null}
                          <span className="cart-amount">ebooks</span>
                        </Col>
                      ) : (
                        <Col xs={10} sm={4} md={3} lg={3} xl={4}>
                          {data.CartName === AutoCartType.HOLDS ? (
                            audioHold ? (
                              audioActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : data.CartName === AutoCartType.CONTENT_EXP ? (
                            audioContent ? (
                              audioActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : data.CartName === AutoCartType.SUGGESTIONS ? (
                            audioSuggestion ? (
                              audioActiveStatus ? (
                                <img
                                  src={active}
                                  className="cart-amount"
                                  alt="status"
                                />
                              ) : (
                                <img
                                  src={inactive}
                                  className="cart-amount"
                                  alt="status"
                                />
                              )
                            ) : (
                              <img
                                src={inactive}
                                className="cart-amount"
                                alt="status"
                              />
                            )
                          ) : null}
                          <span className="cart-amount">AudioBooks</span>
                        </Col>
                      )}
                      {parseInt(bookCartNo) === 1 ? (
                        <Col xs={10} sm={4} md={3} lg={3} xl={3}>
                          {DataEbooks[0]?.AutocartProcessingStrategyId ===
                          "LibraryLevel" ? (
                            autoCartsData[0]?.AutoBuy === true ? ( 
                              <span className="cart-edit-value">
                                Auto Purchase
                              </span>
                            ) : (
                              <span className="cart-edit-value">
                                Manual Purchase
                              </span>
                            )
                          ) : data.CartName === AutoCartType.HOLDS ? (
                            ebookHold ? (
                              purchaseCap(data?.CartName)[0] ? (
                                <span className="cart-edit-value">
                                  Auto Purchase
                                </span>
                              ) : (
                                <span className="cart-edit-value">
                                  Manual Purchase
                                </span>
                              )
                            ) : (
                              ""
                            )
                          ) : data.CartName === AutoCartType.CONTENT_EXP ? (
                            ebookContent ? (
                              purchaseCap(data?.CartName)[0] ? (
                                <span className="cart-edit-value">
                                  Auto Purchase
                                </span>
                              ) : (
                                <span className="cart-edit-value">
                                  Manual Purchase
                                </span>
                              )
                            ) : (
                              ""
                            )
                          ) : data.CartName === AutoCartType.SUGGESTIONS ? (
                            ebookSuggest ? (
                              purchaseCap(data?.CartName)[0] ? (
                                <span className="cart-edit-value">
                                  Auto Purchase
                                </span>
                              ) : (
                                <span className="cart-edit-value">
                                  Manual Purchase
                                </span>
                              )
                            ) : (
                              ""
                            )
                          ) : null}
                        </Col>
                      ) : (
                        <Col xs={10} sm={4} md={3} lg={3} xl={3}>
                          {DataAudioBooks[0]?.AutocartProcessingStrategyId ===
                          "LibraryLevel" ? (
                            autoCartsData[1].AutoBuy === true ? (
                              <span className="cart-edit-value">
                                Auto Purchase
                              </span>
                            ) : (
                              <span className="cart-edit-value">
                                Manual Purchase
                              </span>
                            )
                          ) : data.CartName === AutoCartType.HOLDS ? (
                            audioHold ? (
                              AudiopurchaseCap(data?.CartName)[0] ? (
                                <span className="cart-edit-value">
                                  Auto Purchase
                                </span>
                              ) : (
                                <span className="cart-edit-value">
                                  Manual Purchase
                                </span>
                              )
                            ) : (
                              ""
                            )
                          ) : data.CartName === AutoCartType.CONTENT_EXP ? (
                            audioContent ? (
                              AudiopurchaseCap(data?.CartName)[0] ? (
                                <span className="cart-edit-value">
                                  Auto Purchase
                                </span>
                              ) : (
                                <span className="cart-edit-value">
                                  Manual Purchase
                                </span>
                              )
                            ) : (
                              ""
                            )
                          ) : data.CartName === AutoCartType.SUGGESTIONS ? (
                            audioSuggestion ? (
                              AudiopurchaseCap(data?.CartName)[0] ? (
                                <span className="cart-edit-value">
                                  Auto Purchase
                                </span>
                              ) : (
                                <span className="cart-edit-value">
                                  Manual Purchase
                                </span>
                              )
                            ) : (
                              ""
                            )
                          ) : null}
                        </Col>
                      )}

                      <Col xs={10} sm={4} md={4} lg={4} xl={4}>
                        {parseInt(bookCartNo) === 1 ? (
                          DataEbooks[0]?.AutocartProcessingStrategyId ===
                          "LibraryLevel" ? (
                            <></>
                          ) : (
                            <>
                              <div className="cart-text spendLimitText">
                                Spending Limit
                              </div>
                              <div className="spendLimitText">
                                {data.CartName === AutoCartType.HOLDS
                                  ? ebookHold
                                    ? ebookActiveStatus
                                      ? DataEbooks[0]?.Rules.filter(
                                          (data: any) =>
                                            data.AutoBuyRuleType === 2
                                        ).map(
                                          (data: any) =>
                                            data.PurchaseCapForPeriod
                                        )[0] === null
                                        ? currency + 0
                                        : currency +
                                          DataEbooks[0]?.Rules.filter(
                                            (data: any) =>
                                              data.AutoBuyRuleType === 2
                                          ).map(
                                            (data: any) =>
                                              data.PurchaseCapForPeriod
                                          )[0]
                                      : ""
                                    : ""
                                  : data.CartName === AutoCartType.CONTENT_EXP
                                  ? ebookContent
                                    ? ebookActiveStatus
                                      ? DataEbooks[0]?.Rules.filter(
                                          (data: any) =>
                                            data.AutoBuyRuleType === 1
                                        ).map(
                                          (data: any) =>
                                            data.PurchaseCapForPeriod
                                        )[0] === null
                                        ? currency + 0
                                        : currency +
                                          DataEbooks[0]?.Rules.filter(
                                            (data: any) =>
                                              data.AutoBuyRuleType === 1
                                          ).map(
                                            (data: any) =>
                                              data.PurchaseCapForPeriod
                                          )[0]
                                      : ""
                                    : ""
                                  : data.CartName === AutoCartType.SUGGESTIONS
                                  ? ebookSuggest
                                    ? ebookActiveStatus
                                      ? DataEbooks[0]?.Rules.filter(
                                          (data: any) =>
                                            data.AutoBuyRuleType === 3
                                        ).map(
                                          (data: any) =>
                                            data.PurchaseCapForPeriod
                                        )[0] === null
                                        ? currency + 0
                                        : currency +
                                          DataEbooks[0]?.Rules.filter(
                                            (data: any) =>
                                              data.AutoBuyRuleType === 3
                                          ).map(
                                            (data: any) =>
                                              data.PurchaseCapForPeriod
                                          )[0]
                                      : ""
                                    : ""
                                  : null}
                              </div>
                            </>
                          )
                        ) : DataAudioBooks[0]?.AutocartProcessingStrategyId ===
                          "LibraryLevel" ? (
                          <></>
                        ) : (
                          <>
                                {ruleAutoPurchase(data.CartName) ? 
                            <div className={`${amountReachRule === data.CartName ? "spendLimitText cart-text capReached" : "cart-text spendLimitText"}`}>
                              Spending Limit
                            </div>
                                : null
                                }
                                {ruleAutoPurchase(data.CartName) ? 
                            <div className={`${amountReachRule === data.CartName ? "spendLimitText capReached" : "spendLimitText"}`}>
                              {data.CartName === AutoCartType.HOLDS
                                ? audioHold
                                  ? audioActiveStatus
                                    ? DataAudioBooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 2
                                      ).map(
                                        (data: any) => data.PurchaseCapForPeriod
                                      )[0] === null
                                      ? currency + 0
                                      : currency +
                                        DataAudioBooks[0]?.Rules.filter(
                                          (data: any) =>
                                            data.AutoBuyRuleType === 2
                                        ).map(
                                          (data: any) =>
                                            data.PurchaseCapForPeriod
                                        )[0]
                                    : ""
                                  : ""
                                : data.CartName === AutoCartType.CONTENT_EXP
                                ? audioContent
                                  ? audioActiveStatus
                                    ? DataAudioBooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 1
                                      ).map(
                                        (data: any) => data.PurchaseCapForPeriod
                                      )[0] === null
                                      ? currency + 0
                                      : currency +
                                        DataAudioBooks[0]?.Rules.filter(
                                          (data: any) =>
                                            data.AutoBuyRuleType === 1
                                        ).map(
                                          (data: any) =>
                                            data.PurchaseCapForPeriod
                                        )[0]
                                    : ""
                                  : ""
                                : data.CartName === AutoCartType.SUGGESTIONS
                                ? audioSuggestion
                                  ? audioActiveStatus
                                    ? DataAudioBooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 3
                                      ).map(
                                        (data: any) => data.PurchaseCapForPeriod
                                      )[0] === null
                                      ? currency + 0
                                      : currency +
                                        DataAudioBooks[0]?.Rules.filter(
                                          (data: any) =>
                                            data.AutoBuyRuleType === 3
                                        ).map(
                                          (data: any) =>
                                            data.PurchaseCapForPeriod
                                        )[0]
                                    : ""
                                  : ""
                                : null}
                            </div>
                            : null }
                          </>
                        )}
                      </Col>
                      <Col xs={10} sm={6} md={5} lg={5} xl={4}>
                        {/* <span className="cart-amount">{currency}{data.TotalCartAmount}</span> */}
                        {
                          (data.CartName === AutoCartType.HOLDS) ?
                          holdSpent ?
                          <div className="cart-text amountSpent">
                          Amount spent this month
                          </div>
                          :null
                          : (data.CartName === AutoCartType.CONTENT_EXP) ?
                            contentExpSpent ?
                            <div className="cart-text amountSpent">
                              Amount spent this month
                            </div>
                            :null
                          : (data.CartName === AutoCartType.SUGGESTIONS) ?
                          suggestionSpent ?
                          <div className="cart-text amountSpent">
                          Amount spent this month
                        </div>
                        : null
                        :null
                        }
                        {/* <div className="cart-text amountSpent">
                          Amount spent this month
                        </div> */}
                        {parseInt(bookCartNo) === 1 ? (
                          <div className="amountSpent">
                            {data.CartName === AutoCartType.HOLDS
                              ? ebookHold
                                ? ebookActiveStatus
                                  ? DataEbooks[0]?.Rules.filter(
                                      (data: any) => data.AutoBuyRuleType === 2
                                    ).map(
                                      (data: any) =>
                                        data.AutoBuyPeriodRunningTotal
                                    )[0] === null
                                    ? currency + 0
                                    : currency +
                                      DataEbooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 2
                                      ).map(
                                        (data: any) =>
                                          data.AutoBuyPeriodRunningTotal
                                      )[0]
                                  : ""
                                : ""
                              : data.CartName === AutoCartType.CONTENT_EXP
                              ? ebookContent
                                ? ebookActiveStatus
                                  ? DataEbooks[0]?.Rules.filter(
                                      (data: any) => data.AutoBuyRuleType === 1
                                    ).map(
                                      (data: any) =>
                                        data.AutoBuyPeriodRunningTotal
                                    )[0] === null
                                    ? currency + 0
                                    : currency +
                                      DataEbooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 1
                                      ).map(
                                        (data: any) =>
                                          data.AutoBuyPeriodRunningTotal
                                      )[0]
                                  : ""
                                : ""
                              : data.CartName === AutoCartType.SUGGESTIONS
                              ? ebookSuggest
                                ? ebookActiveStatus
                                  ? DataEbooks[0]?.Rules.filter(
                                      (data: any) => data.AutoBuyRuleType === 3
                                    ).map(
                                      (data: any) =>
                                        data.AutoBuyPeriodRunningTotal
                                    )[0] === null
                                    ? currency + 0
                                    : currency +
                                      DataEbooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 3
                                      ).map(
                                        (data: any) =>
                                          data.AutoBuyPeriodRunningTotal
                                      )[0]
                                  : ""
                                : ""
                              : null}
                          </div>
                        ) : (
                          <div className="amountSpent">
                            {data.CartName === AutoCartType.HOLDS
                              ? audioHold
                                ? audioActiveStatus && holdSpent
                                  ? DataAudioBooks[0]?.Rules.filter(
                                      (data: any) => data.AutoBuyRuleType === 2
                                    ).map(
                                      (data: any) =>  
                                        data.AutoBuyPeriodRunningTotal
                                    )[0] === null
                                    ? currency + 0
                                    : currency +
                                      DataAudioBooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 2
                                      ).map(
                                        (data: any) =>
                                          data.AutoBuyPeriodRunningTotal
                                      )[0]
                                  : ""
                                : ""
                              : data.CartName === AutoCartType.CONTENT_EXP
                              ? audioContent
                                ? audioActiveStatus && contentExpSpent
                                  ? DataAudioBooks[0]?.Rules.filter(
                                      (data: any) => data.AutoBuyRuleType === 1
                                    ).map(
                                      (data: any) =>
                                        data.AutoBuyPeriodRunningTotal
                                    )[0] === null
                                    ? currency + 0
                                    : currency +
                                      DataAudioBooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 1
                                      ).map(
                                        (data: any) =>
                                          data.AutoBuyPeriodRunningTotal
                                      )[0]
                                  : ""
                                : ""
                              : data.CartName === AutoCartType.SUGGESTIONS
                              ? audioSuggestion
                                ? audioActiveStatus && suggestionSpent
                                  ? DataAudioBooks[0]?.Rules.filter(
                                      (data: any) => data.AutoBuyRuleType === 3
                                    ).map(
                                      (data: any) =>
                                        data.AutoBuyPeriodRunningTotal
                                    )[0] === null
                                    ? currency + 0
                                    : currency +
                                      DataAudioBooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 3
                                      ).map(
                                        (data: any) =>
                                          data.AutoBuyPeriodRunningTotal
                                      )[0]
                                  : ""
                                : ""
                              : null}
                          </div>
                        )}
                      </Col>
                      <Col xs={14} sm={12} md={4} lg={4} xl={5}>
                        {/* ExternalRefId */}
                        {parseInt(bookCartNo) === 1 ? (
                          DataEbooks[0]?.AutocartProcessingStrategyId ===
                          "LibraryLevel" ? (
                            <></>
                          ) : (
                            <>
                              <div className="cart-text spendLimitText">
                                PO Reference#
                              </div>
                              <div className="spendLimitText">
                                {data.CartName === AutoCartType.HOLDS
                                  ? ebookHold
                                    ? ebookActiveStatus
                                      ? DataEbooks[0]?.Rules.filter(
                                          (data: any) =>
                                            data.AutoBuyRuleType === 2
                                        ).map(
                                          (data: any) => data.ExternalRefId
                                        )[0]
                                      : ""
                                    : ""
                                  : data.CartName === AutoCartType.CONTENT_EXP
                                  ? ebookActiveStatus
                                    ? ebookContent
                                      ? DataEbooks[0]?.Rules.filter(
                                          (data: any) =>
                                            data.AutoBuyRuleType === 1
                                        ).map(
                                          (data: any) => data.ExternalRefId
                                        )[0]
                                      : ""
                                    : ""
                                  : data.CartName === AutoCartType.SUGGESTIONS
                                  ? ebookSuggest
                                    ? ebookActiveStatus
                                      ? DataEbooks[0]?.Rules.filter(
                                          (data: any) =>
                                            data.AutoBuyRuleType === 3
                                        ).map(
                                          (data: any) => data.ExternalRefId
                                        )[0]
                                      : ""
                                    : ""
                                  : null}
                              </div>
                            </>
                          )
                        ) : DataAudioBooks[0]?.AutocartProcessingStrategyId ===
                          "LibraryLevel" ? (
                          <></>
                        ) : (
                          <>
                            {ruleAutoPurchase(data.CartName) ? 
                            <div className="cart-text spendLimitText">
                              PO Reference#
                            </div>
                            : null }
                            <div className="spendLimitText">
                              {data.CartName === AutoCartType.HOLDS
                                ? audioHold && holdSpent
                                  ? audioActiveStatus
                                    ? DataAudioBooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 2
                                      ).map(
                                        (data: any) => data.ExternalRefId
                                      )[0]
                                    : ""
                                  : ""
                                : data.CartName === AutoCartType.CONTENT_EXP
                                ? audioContent && contentExpSpent
                                  ? audioActiveStatus
                                    ? DataAudioBooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 1
                                      ).map(
                                        (data: any) => data.ExternalRefId
                                      )[0]
                                    : ""
                                  : ""
                                : data.CartName === AutoCartType.SUGGESTIONS
                                ? audioSuggestion && suggestionSpent
                                  ? audioActiveStatus
                                    ? DataAudioBooks[0]?.Rules.filter(
                                        (data: any) =>
                                          data.AutoBuyRuleType === 3
                                      ).map(
                                        (data: any) => data.ExternalRefId
                                      )[0]
                                    : ""
                                  : ""
                                : null}
                            </div>
                          </>
                        )}
                      </Col>
                      {appUser?.isLibraryBuyer === true ? (
                        <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                          {presentBook?.includes(data?.CartName) !== true ? (   
                            bookCartNoWithActive?                      
                                <>
                                  <span className="edit-rules deletedRules">
                                    <b>
                                      <u
                                        onClick={() => {
                                          if( autoByRuleError?.length === 0) {
                                            setEditByClick(true);
                                            setEditByUpdated(true)
                                            setSelectedCart(data);
                                            handleAddNewRule(
                                              true,
                                              data?.CartName,
                                              false
                                            );
                                            dispatch(setAudioBookScroll(getscrollheight))
                                          } else {
                                            showNotification("error")
                                          }
                                        }}
                                      >
                                        Edit Rules
                                      </u>
                                    </b>
                                  </span>
                                  <Switch
                                    onChange={(checked: any) =>
                                     {
                                      dispatch(setAudioBookScroll(getscrollheight))
                                        setEditByUpdated(false)
                                        handleAddNewRule(
                                        checked,
                                        data?.CartName,
                                        true
                                      )}
                                    }
                                  />
                                </>     
                                :
                                <>
                                <span className="edit-rules deletedRules activeFalse">
                                  <b>
                                    <u
                                      onClick={() => {
                                        if( autoByRuleError?.length === 0) {
                                          dispatch(setAudioBookScroll(getscrollheight))
                                          setEditByClick(true);
                                          setEditByUpdated(true)
                                          setSelectedCart(data);
                                          handleAddNewRule(
                                            true,
                                            data?.CartName,
                                            false
                                          );
                                        } else {
                                          showNotification("error");
                                        }
                                      }}
                                    >
                                      Edit Rules
                                    </u>
                                  </b>
                                </span>
                                <Switch
                                  onChange={(checked: any) =>
                                   {
                                    dispatch(setAudioBookScroll(getscrollheight))
                                      setEditByUpdated(false)
                                      handleAddNewRule(
                                      checked,
                                      data?.CartName,
                                      true
                                    )}
                                  }
                                />
                              </>                    
                          ) : bookCartNoWithActive ? (
                            <>
                              <span
                                className="edit-rules normalRules"
                                onClick={() => {
                                  if( autoByRuleError?.length === 0) {
                                    dispatch(setAudioBookScroll(getscrollheight))
                                    setVisible(true);
                                    setSelectedCart(data);
                                  } else {
                                    showNotification("error");
                                  }
                                }}
                              >
                                <b>
                                  <u>Edit Rules</u>
                                </b>
                              </span>
                              <Switch
                                checked={getActiveStatus(data)}
                                onChange={(checked: any) =>{
                                  dispatch(setAudioBookScroll(getscrollheight))
                                  handleActiveChange(checked, data)}
                                }
                              />
                            </>
                          ) : (
                            <>
                              <span
                                onClick={() => {
                                  if( autoByRuleError?.length === 0) {
                                    dispatch(setAudioBookScroll(getscrollheight))
                                    setVisible(true);
                                    setSelectedCart(data);
                                  } else {
                                    showNotification("error");
                                  }
                                }}
                               className="edit-rules normalRules activeTrue">
                                <b>
                                  <u>Edit Rules</u>
                                </b>
                              </span>
                              <Switch onChange={(checked: any) =>{
                                 dispatch(setAudioBookScroll(getscrollheight))
                                 handleCartStatusActiveChange(checked, data)
                              }  
                                }/>
                            </>
                          )}
                        </Col>
                      ) : (
                        <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                          <></>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
                {/* } */}
            </div>
          );
        })}
         </div>
         </CompWrapper>
          </TabPane>
          :
          null
          }
          <TabPane tab="History" key="3" >
            <AutoHistory libId={lib_Id} currentTab={'3'} />
          </TabPane>
        </Tabs>
        <Drawer
          placement="right"
          width="428"
          onClose={() => {
            setVisible(false);
            setEditByClick(false);
          }}
          visible={visible}
        >
          <Tabs
            className="cart-tabs parentAutocartTab"
            activeKey={`${bookCartNo}`}
          >
            <TabPane tab="ebooks" key="1">
              <div className="sideBarAutocartContent">
                <AutoCartSlider
                  title="eBooks"
                  libId={lib_Id}
                  cart={selectedCart}
                  rulesData={DataEbooks[0]}
                  managerOptions={sorterFun(getUserOptions(1),1)}
                  notifyOptions={sorterFun(getUserOptions(0),0)}
                  setVisible={setVisible}
                  setEditByClick={setEditByClick}
                  formState={formState}
                  Abook={false}
                  getSaveRules={setSaveRule}
                  getEbookData={setUpdatedEbook}
                  getAbookData={setUpdatedAbook}
                  allData={cartList}
                  presentBooks={presentBook}
                />
              </div>
            </TabPane>
            <TabPane tab="AudioBooks" key="2">
              <div className="sideBarAutocartContent">
                <AutoCartSlider
                  title="AudioBooks"
                  libId={lib_Id}
                  cart={selectedCart}
                  rulesData={DataAudioBooks[0]}
                  managerOptions={sorterFun(getUserOptions(1),1)}
                  notifyOptions={sorterFun(getUserOptions(0),0)}
                  setVisible={setVisible}
                  setEditByClick={setEditByClick}
                  getSaveRules={setSaveRule}
                  formState={formState}
                  Abook={true}
                  getAbookData={setUpdatedAbook}
                  getEbookData={setUpdatedEbook}
                  presentBooks={presentBook}
                />
              </div>
            </TabPane>
            <TabPane tab="Videos" key="3">
              <Demo />
            </TabPane>
          </Tabs>
        </Drawer>
    </SpinnerLoader>
  );
};
