import moment from "moment";
import { useSelector } from "react-redux";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import CustomData from "./customData";
import { AutoCartType } from "../../../../constants/enums";

export const DataCart=()=>{
    const {appUser} = useSelector(userStateSelector)
    var dateFormat = appUser && appUser.libraryData? appUser.libraryData[0].DateFormat.toUpperCase(): "DD-MM-YYYY"
    const outputDataHold=CustomData()
    const cartList = [
        {
        CartId: 1,
        CartName: AutoCartType.HOLDS,
        CartOwner: "Community Cart",
        CartTypeCode: "auto",
        TotalCartAmount: outputDataHold.holdData['amount'],
        NumberOfTitles: outputDataHold.holdData['titles'],
        CartOwnerFirstName: "xyz",
        CartOwnerLastName: "xyz",
        IsCommunityCart: false,
        AssociateShelfId: "xyz",
        CartDescription: "xyz",
        CartDetails:[],
        NumberofCopies: 0,
        NumberOfPrepubCopies: 0,
        NumberOfPrepubTitles: 0,
        LastUpdate: outputDataHold.holdData['update_date']? moment(outputDataHold.holdData['update_date']).format(`${dateFormat}  h:mmA`): "",
    },
    {
        CartId: 2,
        CartName: AutoCartType.SUGGESTIONS,
        CartOwner: "Community Cart",
        CartTypeCode: "auto",
        TotalCartAmount: outputDataHold.suggestionData['amount'],
        NumberOfTitles: outputDataHold.suggestionData['titles'],
        CartOwnerFirstName: "xyz",
        CartOwnerLastName: "xyz",
        IsCommunityCart: false,
        AssociateShelfId: "xyz",
        CartDescription: "xyz",
        CartDetails:[],
        NumberofCopies: 0,
        NumberOfPrepubCopies: 0,
        NumberOfPrepubTitles: 0,
        LastUpdate:outputDataHold.suggestionData['update_date']? moment(outputDataHold.suggestionData['update_date']).format(`${dateFormat}  h:mmA`):"",
    },
    {
        CartId: 3,
        CartName: AutoCartType.CONTENT_EXP,
        CartOwner: "Community Cart",
        CartTypeCode: "auto",
        TotalCartAmount: outputDataHold.renewData['amount'],
        NumberOfTitles: outputDataHold.renewData['titles'],
        CartOwnerFirstName: "xyz",
        CartOwnerLastName: "xyz",
        IsCommunityCart: false,
        AssociateShelfId: "xyz",
        CartDescription: "xyz",
        CartDetails:[],
        NumberofCopies: 0,
        NumberOfPrepubCopies: 0,
        NumberOfPrepubTitles: 0,
        LastUpdate:outputDataHold.renewData['update_date']? moment(outputDataHold.renewData['update_date']).format(`${dateFormat}  h:mmA`): "",
    },
    ]
    
    return cartList
}

export default DataCart