/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";


export const ParagraphBox = (props: any) => {
  const { data, className, boxType } = props;

  return (
    <div className={className}>
      {boxType === 0 ? (
        <div className="headerPart">
          <span className="vertical-line"></span> &nbsp;
          <span className="spotlightHead">
            {/* {data?.CreatedDateTime ? dateModify(data?.CreatedDateTime, 0) : ""} */}
            {data?.PublicationDateString}
          </span>
          <span className="spotlightSubHead">{data?.NewsLetterTitle}</span>
          <p className="spotlightPara">{data?.NewsLetterDetails}</p>
        </div>
      ) : null}
      {boxType === 1 ? (
        <div className="headerPart">
          <span className="spotlightSubHead">{data?.Author}&nbsp;</span>
          {/* <span className="spotlightHead">
            {`${
              data?.CreatedDateTime ? dateModify(data?.CreatedDateTime, 1) : ""
            } - ${data?.EndDateTime ? dateModify(data?.EndDateTime, 1) : ""}`}
          </span> */}
          <p className="spotlightPara">{data?.AuthorDetails}</p>
        </div>
      ) : null}
    </div>
  );
};
