import { createAsyncThunk } from "@reduxjs/toolkit";
import { MarcService } from "../../../web/services/marc/marcService";

const marcSrv = new MarcService();

export const fetchMarcByDateRange = createAsyncThunk(
  "api/fetchMarcByDateRange",
  async (_args: any, thunkAPI) => {
    try {
      const data = await marcSrv.fetchMarcByDateRange(_args);
        return data;
     
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchMarcByIsbn = createAsyncThunk(
  "api/fetchMarcByIsbn",
  async (_args: any, thunkAPI) => {
    try {
      const data = await marcSrv.fetchMarcByIsbn(_args);
      if (data.status === "SUCCESS") {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchLatestStatusOfTask = createAsyncThunk(
  "api/fetchLatestStatusOfTask",
  async (_args: any, thunkAPI) => {
    try {
      const data = await marcSrv.fetchLatestStatusOfTask(_args);
      if (data.data.success===1) {
        return data;
      }
      else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
)

export const fetchStatusOfMarcTask = createAsyncThunk(
  "api/getStatusOfMarcTask",
  async (_args: any, thunkAPI) => {
    try {
      const data = await marcSrv.fetchStatusOfMarcTask(_args);
      if (data.success) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  })

export const fetchMarcDownload=createAsyncThunk(
  "api/fetchMarcDownload",
  async(_args:any,thunkAPI)=>{
    try{
      const data =await marcSrv.fetchMarcDownload(_args);
      if (data?.status == "SUCCESS") {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    }catch(e:any){
      return thunkAPI.rejectWithValue(e.data);
    }
  }
)
