import React, { useEffect, useRef, useState } from "react";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { Row, Col, Drawer, Input, message } from "antd";
import "./libraryStyles.less";
import DropDown from "../../../stateless/common/dropDown";
import { Upload } from "antd";
import Button from "../../../stateless/common/button";
import { dropdownIcon, notFound, Fill } from "../../../../images";
import {
  removeLibraryLogo,
  updateLibrarySettings,
} from "../../../../../redux/actions/settings/settings";
import { useDispatch, useSelector } from "react-redux";
import { SettingStateSelector } from "../../../../../redux/reducers/settings/settingsReducer";
import { libraryInputs } from "../settingUtility";
import { getAPIBaseUrl } from "../../../../../environment/api";
import { PortalModule } from "../../../../../environment/apiEnvironment";

const Library = (props: any) => {
  const { libId } = props;
  const dispatch = useDispatch();
  const { libSettingsData, libSettingsFormState, libLanguage, getLibraryFormState, updateLibraryFormState } =
    useSelector(SettingStateSelector);
  const fileUpload = useRef<any>(null);
  const [infoEdit, setInfoEdit] = useState<boolean>(false);
  const [imageRemoved, setImageRemoved] = useState<boolean>(false);
  const [imagepath, setImagePath] = useState<any>("");
  const [settingLabel, setSettingLabel] = useState<any>([]);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [selectedEdit, setSelectedEdit] = useState<any>([]);
  const [formLoading, setFormLoading] = useState(false)
  const [updateSettings, setUpdateSettings] = useState<any>({
    Twitter: "",
    URLName: "",
    SupportEmail: "",
    LibraryWebsite: "",
    SupportWebsite: "",
    AllowGlobalCatalogSearch: "",
    SupportLoanHistory: "",
    IsTrendingShelfEnabled: "",
    IsEbookTrendingShelfEnabled: "",
    IsAudioBookTrendingShelfEnabled: "",
    MaxPatronWishes: "",
    MaxLoanedDocuments: "",
    MaxHeldDocuments: "",
    MaxLoanTimeDuration: "",
    MaxHoldTimeDuration: "",
    LanguageForApps: "",
    LibraryImage: "",
  });

  const [imageUpload, setImageUpload] = useState<boolean>(false);
  const [languageCode, setLanguageCode] = useState<any>();

  const emailField = ["LibraryWebsite", "SupportEmail","SupportWebsite"];

  useEffect(() => {
    return () => {
      setImagePath("");
    };
  }, []);

  const logoImg = `${getAPIBaseUrl(
    PortalModule.CARTS
  )}/getLibraryLogo?library_Id=${libId}`;

  useEffect(() => {
    setImagePath(logoImg);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libId]);

  const handleBeforeUpload = async (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "gif";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 0.8;
    if (!isLt2M) {
      message.error("Image must smaller than 800K!");
      return Upload.LIST_IGNORE;
    }
    const isValid = await checkImageWH(file, 520, 72);
    if (isValid && isJpgOrPng) {
      const img = {
        preview: URL.createObjectURL(file),
        data: file,
      };
      setImage(img);
      setImagePath(URL.createObjectURL(file));
    }
    return isValid ? true : Upload.LIST_IGNORE;
  };

  const checkImageWH = (file: any, width: number, height: number) => {
    return new Promise<boolean>(function (resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = (e) => {
        let src = e?.target?.result;
        const image: any = new Image();
        image.onload = function () {
          if (
            (this.width && this.width > width) ||
            (this.height && this.height > height)
          ) {
            message.error(
              "Please upload picture of size less than or equal to " +
                width +
                " * " +
                height
            );
            return false;
          } else {
            resolve(true);
          }
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  };

  const handleFileChange = (e: any) => {
    handleBeforeUpload(e.target.files[0]);
    setImageRemoved(false);
  };

  const handleUpload = () => {
    console.log(fileUpload.current.click(), "fileUpload");
  };


  const uploadImage: any = (
    <div>
      <img
        src={imagepath}
        alt=""
        className="libraryUploadImage"
        onError={(e: any) => {
          e.target.src = notFound;
        }}
      />
    </div>
  );

  const uploadButton: any = (
    <div>
      <img
        className="img-preview libraryUploadImage"
        src={imageRemoved ? imagepath : logoImg}
        alt=""
        onError={(e: any) => {
          e.target.src = notFound;
        }}
      />
    </div>
  );

  const updateLibSettings =()=>{
    setUpdateSettings({
      ...updateSettings,
      Twitter: libSettingsData?.Twitter,
      URLName: libSettingsData?.URLName,
      SupportEmail: libSettingsData?.SupportEmail,
      LibraryWebsite: libSettingsData?.LibraryWebsite,
      SupportWebsite: libSettingsData?.SupportWebsite,
      AllowGlobalCatalogSearch: libSettingsData?.AllowGlobalCatalogSearch,
      SupportLoanHistory: libSettingsData?.SupportLoanHistory,
      IsTrendingShelfEnabled: libSettingsData?.IsTrendingShelfEnabled,
      IsEbookTrendingShelfEnabled: libSettingsData?.IsEbookTrendingShelfEnabled,
      IsAudioBookTrendingShelfEnabled:
        libSettingsData?.IsAudioBookTrendingShelfEnabled,
      MaxPatronWishes:
        JSON.stringify(libSettingsData?.MaxPatronWishes) !== "null"
          ? libSettingsData?.MaxPatronWishes
          : "0",
      MaxLoanedDocuments: libSettingsData?.MaxLoanedDocuments,
      MaxHeldDocuments: libSettingsData?.MaxHeldDocuments,
      MaxLoanTimeDuration: libSettingsData?.MaxLoanTimeDuration,
      MaxHoldTimeDuration: libSettingsData?.MaxHoldTimeDuration,
      LanguageForApps: libSettingsData?.LanguageForApps,
      LibraryImage: libSettingsData?.LibraryImage,
    });
  }

  useEffect(() => {
    setSettingLabel(libraryInputs(libSettingsData));
    updateLibSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libSettingsData]);

  const onHandleInput = (e: any) => {
    setSelectedEdit({ ...selectedEdit, value: e?.target?.value });
    setUpdateSettings({
      ...updateSettings,
      [`${e?.target?.name}`]: e?.target?.value,
    });
  };

  const handleEditSave = () => {
    let formData = new FormData();

    formData.append(
      "Twitter",
      JSON.stringify(updateSettings?.Twitter) !== "null"
        ? updateSettings?.Twitter
        : ""
    );
    formData.append(
      "URLName",
      JSON.stringify(updateSettings?.URLName) !== "null"
        ? updateSettings?.URLName
        : ""
    );
    formData.append(
      "SupportEmail",
      JSON.stringify(updateSettings?.SupportEmail) !== "null"
        ? updateSettings?.SupportEmail
        : ""
    );
    formData.append(
      "LibraryWebsite",
      JSON.stringify(updateSettings?.LibraryWebsite) !== "null"
        ? updateSettings?.LibraryWebsite
        : ""
    );
    formData.append(
      "SupportWebsite",
      JSON.stringify(updateSettings?.SupportWebsite) !== "null"
        ? updateSettings?.SupportWebsite
        : ""
    );
    if (updateSettings?.AllowGlobalCatalogSearch === 1) {
      formData.append("AllowGlobalCatalogSearch", "on");
    }else{
      formData.append("AllowGlobalCatalogSearch", "off");
    } 

    if (updateSettings?.SupportLoanHistory === 1) {
      formData.append("SupportLoanHistory", "on");
    }else{
      formData.append("SupportLoanHistory", "off");
    }

    if (updateSettings?.IsTrendingShelfEnabled === true) {
      formData.append("IsTrendingShelfEnabled", "on");
    }else{
      formData.append("IsTrendingShelfEnabled", "off");
    }

    if (updateSettings?.IsEbookTrendingShelfEnabled === true) {
      formData.append("IsEbookTrendingShelfEnabled", "on");
    }else{
      formData.append("IsEbookTrendingShelfEnabled", "off");
    }

    if (updateSettings?.IsAudioBookTrendingShelfEnabled === true) {
      formData.append("IsAudioBookTrendingShelfEnabled", "on");
    }else{
      formData.append("IsAudioBookTrendingShelfEnabled", "off");
    }

    if (updateSettings?.PatronPIN === 1) {
      formData.append("PatronPIN", "on");
    }else{
      formData.append("PatronPIN", "off");
    }
    
    formData.append(
      "MaxPatronWishes",
      updateSettings?.MaxPatronWishes !== ""
        ? updateSettings?.MaxPatronWishes
        : "0"
    );
    formData.append(
      "MaxLoanedDocuments",
      JSON.stringify(updateSettings?.MaxLoanedDocuments) !== "null"
        ? updateSettings?.MaxLoanedDocuments
        : ""
    );
    formData.append(
      "MaxHeldDocuments",
      JSON.stringify(updateSettings?.MaxHeldDocuments) !== "null"
        ? updateSettings?.MaxHeldDocuments
        : ""
    );
    formData.append(
      "MaxLoanTimeDuration",
      JSON.stringify(updateSettings?.MaxLoanTimeDuration) !== "null"
        ? updateSettings?.MaxLoanTimeDuration
        : ""
    );
    formData.append(
      "MaxHoldTimeDuration",
      JSON.stringify(updateSettings?.MaxHoldTimeDuration) !== "null"
        ? updateSettings?.MaxHoldTimeDuration
        : ""
    );
    formData.append(
      "LanguageForApps",
      JSON.stringify(updateSettings?.LanguageForApps) !== "null"
        ? updateSettings?.LanguageForApps
        : ""
    );
    formData.append("LibraryImage", image?.data);

    dispatch(
      updateLibrarySettings({
        libraryId: libId,
        reqBody: formData,
      })
    );
    if (imagepath !== "") {
      setImageUpload(true);
    }
    setInfoEdit(false);
  };

  const closeDrawer = () => {
    setInfoEdit(false);
    setImageUpload(false);
    setImagePath("");
    updateLibSettings()
  };

  const language = (data: any) => {
    const langcode = data;
    const displayLanguage = languageCode?.map((data: any) => {
      if (langcode === data?.value) {
        return data?.text;
      } else {
        return [];
      }
    });
    return displayLanguage;
  };

  useEffect(() => {
    const langCode = libLanguage?.map((data: any) => {
      return {
        text: data?.Displayname,
        value: data?.LanguageCode,
      };
    });
    setLanguageCode(langCode);
  }, [libLanguage]);

  const handleDropdown = (value: any) => {
    setUpdateSettings({ ...updateSettings, LanguageForApps: value });
    // setSelectedEdit({ ...selectedEdit, value: value });
  };

  const imageRemover = () => {
    setImagePath("");
    dispatch(removeLibraryLogo(libId));
    setImageRemoved(true);
  };

  function validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function validateURL(url: string) {
    var re =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
      if(url !== "" && JSON.stringify(url) !== "null"){
        return re.test(url);
      }else{
        return true;
      }
  }

  useEffect(() => {
    if(updateLibraryFormState?.isSuccess) {
      setFormLoading(true)
    }
  }, [updateLibraryFormState])

  useEffect(() => {
      if(getLibraryFormState?.loading) {
        setFormLoading(false)
      } else if ((!getLibraryFormState?.loading) && (!updateLibraryFormState?.loading)) {
        setFormLoading(false)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLibraryFormState])

  return (
    <SpinnerLoader loading={libSettingsFormState.loading || formLoading}>
      <Row className="scrollContent scrollStyle libraryScroll">
        {settingLabel?.map((item: any) => {
          if (item?.type === "text") {
            return (
              <Col key={item.key} span={18}>
                <h4 className="library-labeling">{item?.label}</h4>
                <div className="subRowWrapper">
                  <span>
                    {item?.label === "Default Language"
                      ? language(item?.value)
                      : item?.value}
                  </span>
                  <span className="libraryBtn">
                    <Button
                      onClick={() => {
                        setSelectedEdit(item);
                        setInfoEdit(true);
                      }}
                      type="primary mark-ready-button"
                    >
                      Edit
                    </Button>
                  </span>
                </div>
                <hr className="col-wrapper" />
              </Col>
            );
          } else if (item?.type === "img") {
            return (
              <Col key={item.key} span={18}>
                <h4 className="library-labeling">{item?.label}</h4>
                <div className="subRowWrapper">
                  {imagepath !== "" && imageUpload ? uploadImage : uploadButton}
                  <span className="libraryBtn">
                    {/* {imagepath !== "" ? ( */}
                    <span className="libraryRemoveBtn">
                      <Button
                        onClick={imageRemover}
                        type="primary mark-ready-button"
                      >
                        Remove
                      </Button>
                    </span>
                    {/* ) : null} */}
                    <Button
                      onClick={() => {
                        setSelectedEdit(item);
                        setInfoEdit(true);
                      }}
                      type="primary mark-ready-button"
                    >
                      Edit
                    </Button>
                  </span>
                </div>
              </Col>
            );
          } else {
            return null;
          }
        })}
      </Row>
      <Drawer
        placement="right"
        width="428"
        onClose={closeDrawer}
        visible={infoEdit}
      >
        <div className="drawer-wrapper">
          <div className="sub-drawer">
            <div className="drawer-padding-adjusted">
              <h4 className="drawer-title-bolding">{selectedEdit?.label}</h4>
              {selectedEdit?.type !== "img" ? (
                selectedEdit?.key !== "defaultLanguage" ? (
                  <>
                    <Input
                      name={selectedEdit?.name}
                      placeholder={selectedEdit?.label}
                      className="card-input"
                      bordered={false}
                      onChange={onHandleInput}
                      value={selectedEdit?.value}
                    />
                    {selectedEdit?.name === "SupportEmail" ? (
                      !validateEmail(selectedEdit?.value) ? (
                        <p className="errorValidation">
                           Valid e-mail address required
                        </p>
                      ) : null
                    ) : null}
                    {selectedEdit?.name === "LibraryWebsite" || selectedEdit?.name === "SupportWebsite" ? (
                      !validateURL(selectedEdit?.value) ? (
                        <p className="errorValidation">
                          Valid URL required: http//www.yoursite.com/page
                        </p>
                      ) : null
                    ) : null}
                  </>
                ) : (
                  <>
                    <DropDown
                      placeholder="Buyer"
                      className="card-dropdown with-search pt-3 libDrop"
                      bordered={false}
                      optionValue={languageCode}
                      value={
                        updateSettings?.LanguageForApps !== undefined
                          ? updateSettings?.LanguageForApps
                          : ""
                      }
                      onChange={handleDropdown}
                      icon={dropdownIcon}
                    />
                  </>
                )
              ) : (
                <>
                  <Row className="image-row">
                    <Col span={12} className="libDrawerImg">
                      {imagepath !== "" ? uploadImage : uploadButton}
                    </Col>
                    <Col span={12}>
                      <div className="upload">
                        <Col span={18}>
                          <div className="upload-text">
                            Upload a 520 x 72 pixel PNG or JPG.
                          </div>
                        </Col>
                        {/* <Upload
                          maxCount={1}
                          beforeUpload={handleBeforeUpload}
                          onChange={handleChange}
                          showUploadList={false}
                          accept="image/jpeg,image/png,image/jpg,gif"
                        >
                          <Col span={18}>
                            <Button type="primary confirm-button">
                              <img src={Fill} alt="" className="libBtnIcon" />
                              Upload
                            </Button>
                          </Col>
                        </Upload> */}
                        <div className="inputTypeBtnCss">
                          <input
                            style={{ opacity: "0" }}
                            ref={fileUpload}
                            accept="image/png, image/jpeg"
                            type="file"
                            name="file"
                            onChange={handleFileChange}
                          ></input>
                          <button
                            onClick={() => handleUpload()}
                            className="libBtnIcon"
                          >
                            <img src={Fill} alt="" className="libBtnIconImg" />
                            Upload
                          </button>
                        </div>
                      </div>
                      {/* {imagepath !== "" ? 
                      <Button onClick={handleClose}>delete</Button>
                      : null } */}
                    </Col>
                  </Row>
                </>
              )}
            </div>

            <div className="library-save">
              {emailField?.includes(selectedEdit?.name) ? (
                <>
                  {selectedEdit?.name === "SupportEmail" ? (
                    validateEmail(selectedEdit?.value) ? (
                      <Button
                        type="primary confirm-button"
                        onClick={handleEditSave}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button type="primary confirm-button" disabled={true}>
                        Save
                      </Button>
                    )
                  ) : null}
                   {selectedEdit?.name === "SupportWebsite" ? (
                    validateURL(selectedEdit?.value) ? (
                      <Button
                        type="primary confirm-button"
                        onClick={handleEditSave}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button type="primary confirm-button" disabled={true}>
                        Save
                      </Button>
                    )
                  ) : null}
                  {selectedEdit?.name === "LibraryWebsite" ? (
                    validateURL(selectedEdit?.value) ? (
                      <Button
                        type="primary confirm-button"
                        onClick={handleEditSave}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button type="primary confirm-button" disabled={true}>
                        Save
                      </Button>
                    )
                  ) : null}
                </>
              ) : (
                <>
                  <Button
                    type="primary confirm-button"
                    onClick={handleEditSave}
                  >
                    {" "}
                    Save
                  </Button>
                </>
              )}
              <Button type="secondary cancel-button" onClick={closeDrawer}>
                {" "}
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </SpinnerLoader>
  );
};

export default Library;
