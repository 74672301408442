/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Drawer,
  Tabs,
  Radio,
  Input,
  DatePicker,
} from "antd";
import Button from "../../stateless/common/button";
import { useDispatch, useSelector } from "react-redux";
import { PurchaseTable } from "./purchaseTable";
import {
  PurchaseStateSelector,
} from "../../../../redux/reducers/purchases/purchases";
import {
  getPurchase,
  getPurchaseComplete,
  getPurchasePending,
  getPurchaseUser,
  resizePurchaseCols,
  resizeCompletedCols,
  resizePendingCols,
  getPrePub,
  recentTab,
  purchasesFilterRemember,
} from "../../../../redux/actions/purchases/purchasesActions";
import { allPurchaseCulm, completedCulm, pendingCulm } from "./tableColumns";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { useHistory } from "react-router-dom";
import DropDown from "../../stateless/common/dropDown";
import { dropdownIcon } from "../../../images";
import { filterOptions, Page } from "../../../constants/constants";
import moment from "moment";
import { PurchaseHeader } from "./PurchaseHeader";
import { PreSale } from "./preSale";
import IsUnsaveDataModel from "./stayModel/leaveWithoutSaveModal";
import { AppRoutes } from "../../../router/appRoutes";
import PaginationTag from "../../stateless/common/pagination";
import { SideBarStateSelector, retainPageSize } from "../../../../redux/reducers/app/sideBarReducer";
import SpinnerLoader from "../../stateless/common/spinnerLoader";
import formState from "../../../../core/states/formState";

export const PurchasesMain = (props: any) => {
  const { TabPane } = Tabs;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    purchaseUserData,
    purchaseOrderData,
    purchaseUserCompleteData,
    purchaseUserPendingData,
    PurchaseformState,
    PurchaseCompleteState,
    PurchasePending,
    allPurchaseColState,
    completedPurchaseColState,
    pendingPurchaseColState,
    preSaleItems,
    defaultTab,
    isFromPrchseDetil,
    purchasesFilter
  } = useSelector(PurchaseStateSelector);
  const { appUser } = useSelector(userStateSelector);
  const { retainedPageSize } = useSelector(SideBarStateSelector);
  const [cameFromPurchaseDetail, setCameFromPurchaseDetail] = useState<any>(isFromPrchseDetil?.cameFromPurchaseDetail !== undefined?isFromPrchseDetil?.cameFromPurchaseDetail:false);
  const [openTab, setOpenTab] = useState<any>(isFromPrchseDetil?.openTab !== undefined?isFromPrchseDetil?.openTab:"1");
  const [allPurchases, setAllPurchases] = useState<any>([]);
  const [purchasePending, setPurchasePending] = useState<any>([]);
  const [completePurchase, setCompletePurchase] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [purchaseCompleted, setPurchaseCompleted] = useState<any>([]);
  const [libId, setLibId] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [retainPosition, setRetainPosition] = useState<any>([]);
  const [tabVal, setTabVal] = useState<string>("1");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [duration, setDuration] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allBuyer, setAllBuyer] = useState<string>("");
  const [urlpath, setUrlpath] = useState<string>("");
  const [storeTabVal, setStoreTabVal] = useState<string>("");
  const [tabValByStat, setTabValByStat] = useState<string>("1");
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [unSaveData, setUnSaveData] = useState<boolean>(false);
  const [showStayModel, setShowStayModel] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<any>(0);
  const [totalCountC, setTotalCountC] = useState<any>(0);
  const [totalCountP, setTotalCountP] = useState<any>(0);
  const [allBuyers, setAllBuyers] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewBook, setViewBook] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startDate, setStartDate] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [endDate, setEndDate] = useState<string>("");
  const [sideDrawerDate, setSideDrawerDate] = useState<any>("");
  const [sideDrawerDateTo, setSideDrawerDateTo] = useState<any>("");
  const [radioValue, setRadioValue] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(
    retainedPageSize ?? 100
  );
  
  useEffect(()=>{
      setPageSize(retainedPageSize);    
  },[tabVal]);
  
  const [currentPage, setCurrentPage] = useState(1);
  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  const [searchHFilter, setSearchHFilter] = useState<any>({
    lib_Id: `${lib_Id}`,
    ExternalRef_id: "",
    buyer_id: "",
    days_Old: "30",
    end_Date: "",
    status:0,
    record_Count: "100",
    record_Start: "1",
    sort_Direction: "DESC",
    sort_Field: "PurchaseOrderDate",
    start_Date: "",
  });
 const [searchSideFilter, setSearchSideFilter] = useState<any>({
    lib_Id: `${lib_Id}`,
    ExternalRef_id: "",
    buyer_id: (purchasesFilter.buyer !== undefined) ? purchasesFilter.buyer : "",
    days_Old: (purchasesFilter.daysOld !== undefined) ? purchasesFilter.daysOld : "30",
    end_Date: "",
    status:0,
    record_Count: "100",
    record_Start: "1",
    sort_Direction: "DESC",
    sort_Field: "PurchaseOrderDate",
    start_Date: "",
  });
  const dateFormat = "MM/DD/YYYY";
  const defaultStartDate = moment().format(dateFormat);
  const defaultToDate = moment().add(1, "months").format(dateFormat);
  const [allPurchaseResize, setAllPurchaseResize] = useState<any>([]);
  const [completedPurchaseResize, setCompletedPurchaseResize] = useState<any>(
    []
  );
  const [start, setStart] = useState<number>(Page.DEFAULT_START);
  const [lastCount, setLastCount] = useState(start + pageSize - 1);
  const [pendingPurchaseResize, setpendingPurchaseResize] = useState<any>([]);
  var currency = appUser &&  appUser.libraryData? appUser.libraryData[0]?.CurrencySymbol : "";
  const defautlOpt = [
    {
      text: `All buyers`,
      value: `Buyers`,
    },
  ];

  useEffect(()=>{
    setCameFromPurchaseDetail(isFromPrchseDetil?.cameFromPurchaseDetail !== undefined?isFromPrchseDetil?.cameFromPurchaseDetail:false)
    setOpenTab(isFromPrchseDetil?.openTab !== undefined?isFromPrchseDetil?.openTab:"1")
  },[isFromPrchseDetil])

  useEffect(() => {
    setRetainPosition({
      fromDetail: cameFromPurchaseDetail,
      openTab: openTab,
    });
  }, [cameFromPurchaseDetail, openTab]);

  // useEffect(() => {
  //   setTabVal(openTab);
  // }, []);

  useEffect(() => {
    if (!cameFromPurchaseDetail) {
      dispatch(
        getPurchaseUser({
          lib_Id: `${lib_Id}`,
          ExternalRef_id: "",
          record_Count: pageSize,
          sort_Direction: "DESC",
          sort_Field: "PurchaseOrderDate",
        })
      );
    }

    setRetainPosition({
      fromDetail: false,
      openTab: "1",
    });
  }, []);


  useEffect(()=>{
    if (tabVal === "1"){
      dispatch(
        getPurchase({
          searchHFilter
        })
      )

    }else if (tabVal === "2"){
      dispatch(
        getPurchaseComplete({
          searchHFilter
        })
      )
    }else if(tabVal === "3"){
      dispatch(
        getPurchasePending({
          searchHFilter
        })
      )
    }
  },[searchHFilter])

  useEffect(()=>{
    const purchaseFilterApply ={
      buyer : `${searchSideFilter?.buyer_id}`,
      daysOld : `${searchSideFilter?.days_Old}`
    }
    dispatch(purchasesFilterRemember(purchaseFilterApply))

  },[searchSideFilter?.buyer_id,searchSideFilter?.days_Old])

  useEffect(() => {
    setSearchHFilter({
      ...searchHFilter,
      lib_Id: lib_Id,
      start_Date: startDate,
      end_Date: endDate,
    });
    setSearchSideFilter({
      ...searchSideFilter,
      lib_Id: lib_Id,
      start_Date: defaultStartDate,
      end_Date: defaultToDate,
    });
    setLibId(lib_Id);
  }, [lib_Id, startDate, endDate, defaultToDate, defaultStartDate]);

  const changePageSize = (size: number) => {
    setPageSize(size);
    setCurrentPage(1);
    setStart(1)
    dispatch(retainPageSize(size))
    if (tabVal === "1"){
      dispatch(
        getPurchase({
          ...searchHFilter,
          lib_Id: `${lib_Id}`,
          buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
          days_Old: searchSideFilter?.days_Old,
          status:0,
          end_Date: "",
          start_Date: "",
          ExternalRef_id: "",
          record_Count: size,
          record_Start: "1",
          sort_Direction: "DESC",
          sort_Field: "PurchaseOrderDate",
        })
      )

    }else if (tabVal === "2"){
      dispatch(
        getPurchaseComplete({
          ...searchHFilter,
          lib_Id: `${lib_Id}`,
          buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
          days_Old: searchSideFilter?.days_Old,
          status:2,
          end_Date: "",
          start_Date: "",
          ExternalRef_id: "",
          record_Count: size,
          record_Start: "1",
          sort_Direction: "DESC",
          sort_Field: "PurchaseOrderDate",
        })
      )
    }else if(tabVal === "3"){
      dispatch(
        getPurchasePending({
          ...searchHFilter,
          lib_Id: `${lib_Id}`,
          buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
          days_Old: searchSideFilter?.days_Old,
          status:1,
          end_Date: "",
          start_Date: "",
          ExternalRef_id: "",
          record_Count: size,
          record_Start: "1",
          sort_Direction: "DESC",
          sort_Field: "PurchaseOrderDate",
        })
      )
    }else{
      return null
    }
   
  };


  const onPageChange = (page:any) => {
    const pageStart = (page - 1) * pageSize + 1;
    setStart(pageStart);
    setCurrentPage(page);
    if (tabVal === "1"){
      dispatch(
        getPurchase({
          ...searchHFilter,
        lib_Id: `${libId}`,
        record_Count:pageSize,
        record_Start: pageStart,
        sort_Direction: "DESC",
        sort_Field: "PurchaseOrderDate",
        buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
        days_Old: searchSideFilter?.days_Old,
        status:0,
        end_Date: "",
        start_Date: "",
        ExternalRef_id: "",
        })
      )

    }else if (tabVal === "2"){
      dispatch(
        getPurchaseComplete({
          ...searchHFilter,
        lib_Id: `${libId}`,
        record_Count:pageSize,
        record_Start: pageStart,
        sort_Direction: "DESC",
        sort_Field: "PurchaseOrderDate",
        buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
        days_Old: searchSideFilter?.days_Old,
        status:2,
        end_Date: "",
        start_Date: "",
        ExternalRef_id: "",
        })
      )
    }else if(tabVal === "3"){
      dispatch(
        getPurchasePending({
          ...searchHFilter,
        lib_Id: `${libId}`,
        record_Count:pageSize,
        record_Start: pageStart,
        sort_Direction: "DESC",
        sort_Field: "PurchaseOrderDate",
        buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
        days_Old: searchSideFilter?.days_Old,
        status:1,
        end_Date: "",
        start_Date: "",
        ExternalRef_id: "",
        })
      )
    }else{
      return null;
    }
  };

  useEffect(() => {
    let end = start + pageSize - 1;
    if(tabVal === "1"){
      if (totalCount < end) {
        setLastCount(totalCount);
      } else {
        setLastCount(end);
      }
    }else if(tabVal === "2"){
      if (totalCountC < end) {
            setLastCount(totalCountC);
          } else {
            setLastCount(end);
          }
        }
          else if(tabVal === "3"){
            if (totalCountP < end) {
                  setLastCount(totalCountP);
                } else {
                  setLastCount(end);
                }
          }
   
  }, [start,totalCount,pageSize,totalCountC,totalCountP,tabVal]);

  useEffect(() => {
    if (tabVal === "1") {
      //get purchase data recent by default
      if (purchaseOrderData.length < 1) {
        dispatch(
          getPurchase({
            ...searchHFilter,
            lib_Id: `${lib_Id}`,
            buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
            days_Old: searchSideFilter?.days_Old,  
            status:0,
            end_Date: "",
            start_Date: "",
            ExternalRef_id: "",
            record_Count: pageSize,
            record_Start: "1",
          })
        );
      }
    } else if (tabVal === "2") {
      //get completes purchase data recent by default
      if (purchaseUserCompleteData?.length < 1) {
        dispatch(
          getPurchaseComplete({
            ...searchHFilter,
            lib_Id: `${lib_Id}`,
            buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
            days_Old: searchSideFilter?.days_Old,  
            status:2,
            end_Date: "",
            start_Date: "",
            ExternalRef_id: "",
            record_Count: pageSize,
            record_Start: "1",
          })
        );
      }
    } else if (tabVal === "3") {
      //get pending purchase data recent by default
      if (purchaseUserPendingData?.length < 1) {
        dispatch(
          getPurchasePending({
            ...searchHFilter,
            lib_Id: `${lib_Id}`,
            buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
            days_Old: searchSideFilter?.days_Old,  
            status:1,
            end_Date: "",
            start_Date: "",
            ExternalRef_id: "",
            record_Count: pageSize,
            record_Start: "1",
          })
        );
      }
    } else {
      if (preSaleItems?.length < 1) {
        //get pre sale data recent by default
        dispatch(
          getPrePub({
            lib_Id: `${libId}`,
            record_Count: pageSize,
            record_Start: "1",
            sort_Direction: "ASC",
            sort_Field: "",
          })
        );
      }
    }
  }, [lib_Id]);

  useEffect(() => {  
    if (tabVal === "4") {  
      if (preSaleItems?.length < 1) {
        //get pre sale data recent by default
        dispatch(
          getPrePub({
            lib_Id: `${libId}`,
            record_Count: retainedPageSize,
            record_Start: "1",
            sort_Direction: "ASC",
            sort_Field: "",
          })
        );     
    }
  }
  }, [tabVal]);

  useEffect(() => {
    if(purchaseUserData?.length > 0){
    setAllBuyers(
      purchaseUserData?.map((item: any) => {
        return {
          text: `${item?.ScreenName} (${item?.UserID})`,
          value: `${item?.UserID}`,
        };
      })
    );
    }
    setAllPurchases(     
      [purchaseOrderData]?.map((item: any) => {
        const AllPurchasesDate=item?.Results?.slice().sort((a:any,b:any) => b.PurchaseOrderDate.localeCompare(a.PurchaseOrderDate))
        return AllPurchasesDate;
      })[0]
    );
    setTotalCount(
      [purchaseOrderData]?.map((item: any) => {
        return item?.TotalCount;
      })[0]
    );
    setPurchasePending(
      [purchaseUserPendingData]?.map((item: any) => {
        const pendingDate=item?.Results?.slice().sort((a:any,b:any) => b.PurchaseOrderDate.localeCompare(a.PurchaseOrderDate))
        return pendingDate;
      })[0]
    );
    setTotalCountP(
      [purchaseUserPendingData]?.map((item: any) => {
        return item?.TotalCount;
      })[0]
    );
    setCompletePurchase(
      [purchaseUserCompleteData]?.map((item: any) => {
        const completedDate=item?.Results?.slice().sort((a:any,b:any) => b.PurchaseOrderDate.localeCompare(a.PurchaseOrderDate))
        return completedDate;
      })[0]
    );
    setTotalCountC( [purchaseUserCompleteData]?.map((item: any) => {
      return item?.TotalCount;
    })[0])  }, [
    purchaseOrderData,
    purchaseUserPendingData,
    purchaseUserData,
    purchaseUserCompleteData,
  ]);

  // useEffect(() => {
  //   setPurchaseCompleted(
  //     allPurchases?.filter((item: any) => {
  //       return item?.IsComplete === true;
  //     })
  //   );
  // }, [allPurchases, purchaseUserCompleteData]);

  const handleSelect = (e: string) => {
    setStart(Page.DEFAULT_START)
    setCurrentPage(1)
    if (e === "1") {
            dispatch(
              getPurchase({
                ...searchHFilter,
                lib_Id: `${lib_Id}`,
                buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
                days_Old: searchSideFilter?.days_Old, 
                status:0,
                end_Date: "",
                start_Date: "",
                ExternalRef_id: "",
                record_Count: retainedPageSize,
                record_Start: "1",
              })            
            );
          } else if (e === "2") {
            dispatch(
              getPurchaseComplete({
                ...searchHFilter,
                lib_Id: `${lib_Id}`,
                buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
                days_Old: searchSideFilter?.days_Old,  
                status:2,
                end_Date: "",
                start_Date: "",
                ExternalRef_id: "",
                record_Count: retainedPageSize,
                record_Start: "1",
              })           
            );
          } else if (e ==='3') {
            dispatch(
              getPurchasePending({
                ...searchHFilter,
                lib_Id: `${lib_Id}`,
                buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
                days_Old: searchSideFilter?.days_Old,
                status:1,
                end_Date: "",
                start_Date: "",
                ExternalRef_id: "",
                record_Count: retainedPageSize,
                record_Start: "1",
              })           
            );
          }
      
    if (unSaveData) {
      setShowStayModel(true);
      setStoreTabVal(e)
    } else {
      setTabVal(e);
      dispatch(recentTab(e));
    }
  };

  const onSearchHeader = (val: boolean) => {
    setSideDrawerDate("");
    setSideDrawerDateTo("");
    setShowDrawer(val);
    setSearchSideFilter({
      ...searchSideFilter,
      start_Date: "",
      end_Date: "",
    });
  };

  const dateFrom = (date: any, dateString: any) => {
    setSideDrawerDate(moment(JSON.stringify(dateString), "MM/DD/YYYY"));
    setSearchSideFilter({
      ...searchSideFilter,
      start_Date: `${moment(dateString).format("MM/DD/YYYY")}`,
    });
  };

  const dateTo = (date: any, dateString: any) => {
    setSideDrawerDateTo(moment(JSON.stringify(dateString), "MM/DD/YYYY"));
    setSearchSideFilter({
      ...searchSideFilter,
      end_Date: `${moment(dateString).format("MM/DD/YYYY")}`,
    });
  };

  const handleRecent = (e: any) => {
    setSearchSideFilter({ ...searchSideFilter, days_Old: JSON.stringify(e) });
  };

  const handleStatus = (e: any) => {
    if (e.target.value === 1) {
      setRadioValue(1);
      setTabValByStat("1");
      setSearchSideFilter({ ...searchSideFilter, status:0 });
    } else if (e.target.value === 2) {
      setRadioValue(2);
      setTabValByStat("2");
      setSearchSideFilter({ ...searchSideFilter, status:2 });
    } else {
      setRadioValue(3);
      setTabValByStat("3");
      setSearchSideFilter({ ...searchSideFilter, status:1 });
    }
  };

  const handleBuyer = (e: any) => {
    setSearchSideFilter({
      ...searchSideFilter,
      buyer_id: e !== "Buyers" ? e : "",
    });
  };

  const handlePoref = (e: any) => {
    setSearchSideFilter({
      ...searchSideFilter,
      ExternalRef_id: e.target.value,
    });
  };

  const submitSearch = () => {
    setTabVal(tabValByStat);
    if (tabValByStat === "1") {
      dispatch(getPurchase({ ...searchSideFilter, status:0 }));
    } else if (tabValByStat === "2") {
      dispatch(
        getPurchaseComplete({ ...searchSideFilter, status:2 })
      );
    } else if (tabValByStat === "3") {
      dispatch(
        getPurchasePending({ ...searchSideFilter,status:1 })
      );
    }
    setShowDrawer(false);
  };
  useEffect(() => {
    if (allPurchaseResize.length > 0) {
      dispatch(resizePurchaseCols(allPurchaseResize));
    }
  }, [allPurchaseResize]);
  useEffect(() => {
    if (completedPurchaseResize.length > 0) {
      dispatch(resizeCompletedCols(completedPurchaseResize));
    }
  }, [completedPurchaseResize]);
  useEffect(() => {
    if (pendingPurchaseResize.length > 0) {
      dispatch(resizePendingCols(pendingPurchaseResize));
    }
  }, [pendingPurchaseResize]);

  useEffect(() => {
    if (!showDrawer) {
    }
  }, [showDrawer]);

  useEffect(() => {
    setRadioValue(parseInt(tabVal));
  }, [tabVal]);

  const handleStayYes = () => {
    if (unSaveData) {
      setUnSaveData(false);
      if(storeTabVal !== ""){
        setTabVal(storeTabVal)
        setStoreTabVal("")
      }
      if(urlpath !== ""){
        setTimeout(()=>{
          history.push(urlpath)
        },50)
        setUrlpath("")
      }
    }
  };

  useEffect(() => {
    setTabVal(defaultTab);
  }, [defaultTab]);

  const leavingWithoutSave = (row: any) => {
    const unblock = history.block(({ pathname }) => {
      setShowStayModel(true);
      if (!unSaveData) {
        unblock();
        history.push(pathname, {
          purchaseDetail: row,
          cameFromPurchaseDetail: false,
          activeTab: tabVal,
        });
      }
      return false;
    });
  };

  const purchaseDetailsList = (row: any) => {
    leavingWithoutSave(row);
    history.push(AppRoutes.PURCHASEDETAILS,{
      purchaseDetail: row,
      cameFromPurchaseDetail: false,
      activeTab: tabVal,
    });
  };

  return (
    <div>
      {/* <SpinnerLoader loading={formState.loading}> */}
      <Row className="head-container">
        <Col xs={12} sm={14} md={18} lg={20} xl={20}>
          <p className="page-title">Purchases</p>
        </Col>
        <Col xs={12} sm={10} md={6} lg={4} xl={4}></Col>
      </Row>
      <Tabs
        className="cart-tabs"
        defaultActiveKey={tabVal}
        activeKey={tabVal}
        onChange={handleSelect}
      >
        <TabPane tab="All Purchases" key="1">
          <div className="tableStyle">
            <PurchaseHeader
              preSale={false}
              totalCount={totalCount}
              dateOption={filterOptions.purchaseDate}
              buyerOption={[...defautlOpt, ...allBuyers]}
              handleDuration={setDuration}
              handelBuyer={setAllBuyer}
              libId={libId}
              currentTab={tabVal}
              serachHeader={onSearchHeader}
              setSearchSideFilter={setSearchSideFilter}
              searchSideFilter={searchSideFilter}
              handlePageSize={changePageSize}
              pageSizeVal={pageSize}
              lastCount={lastCount}
              startCount={start}
              data={allPurchases}
              
            />
            <PurchaseTable
              showPagination={false}
              needRowData={false}
              data={allPurchases}
              classN="purchaseTable left-sorter"
              editAble={false}
              columns={allPurchaseCulm(purchaseDetailsList,currency)}
              formState={PurchaseformState}
              setColumnResize={setAllPurchaseResize}
              resizeCol={allPurchaseColState}
              rowKey={"PurchaseOrderId"}
            />           
          </div>
          <div className="purchaseFooter">
            {totalCount > lastCount - start + 1 &&
            allPurchases &&
            allPurchases.length >0 ?(
              <>
              {PurchaseformState.isSuccess ?
              <div className="PurchaseTablePagination">
                <PaginationTag
                  total={totalCount}
                  current={currentPage}
                  defaultPageSize={pageSize}
                  onChange={onPageChange}
                /> </div>
                :
               null
               }              
                <Col>
                  <span className="searchTitleStyle purchaseTitleStyle">
                    {start}-{lastCount} of {totalCount} Purchase
                  </span>
                </Col>
              </>
            ) : null
              }
          </div>
        </TabPane>
        <TabPane tab="Completed" key="2">
          <div className="tableStyle">
            <PurchaseHeader
              preSale={false}
              totalCount={totalCountC}
              dateOption={filterOptions.purchaseDate}
              buyerOption={[...defautlOpt, ...allBuyers]}
              handleDuration={setDuration}
              handelBuyer={setAllBuyer}
              libId={libId}
              currentTab={tabVal}
              serachHeader={onSearchHeader}
              setSearchSideFilter={setSearchSideFilter}
              searchSideFilter={searchSideFilter}
              handlePageSize={changePageSize}
              pageSizeVal={pageSize}
              lastCount={lastCount}
              startCount={start}
              data={allPurchases}
            />
            <PurchaseTable
             showPagination={false}
             needRowData={false}
              data={completePurchase}
              classN="purchaseTable left-sorter"
              editAble={false}
              columns={completedCulm(purchaseDetailsList,currency)}
              formState={PurchaseCompleteState}
              setColumnResize={setCompletedPurchaseResize}
              resizeCol={completedPurchaseColState}
              rowKey={"PurchaseOrderId"}
            />           
          </div>
          <div className="purchaseFooter">
          {totalCountC > lastCount - start + 1 && completePurchase &&
            completePurchase.length > 0 ?(
              <>
              {PurchaseCompleteState.isSuccess ?
              <div className="PurchaseTablePagination">
                <PaginationTag
                  total={totalCountC}
                  current={currentPage}
                  defaultPageSize={pageSize}
                  onChange={onPageChange}
                /> </div>
                :
               null
               }             
                <Col>
                  <span className="searchTitleStyle purchaseTitleStyle">
                    {start}-{lastCount} of {totalCountC} Purchase
                  </span>
                </Col>
              </>
            ) : null
              }
          </div>
        </TabPane>
        <TabPane tab="Pending" key="3">
          <div className="tableStyle">
            <PurchaseHeader
              preSale={false}
              totalCount={totalCountP}
              dateOption={filterOptions.purchaseDate}
              buyerOption={[...defautlOpt, ...allBuyers]}
              handleDuration={setDuration}
              handelBuyer={setAllBuyer}
              libId={libId}
              currentTab={tabVal}
              serachHeader={onSearchHeader}
              setSearchSideFilter={setSearchSideFilter}
              searchSideFilter={searchSideFilter}
              handlePageSize={changePageSize}
              pageSizeVal={pageSize}
              lastCount={lastCount}
              startCount={start}
              data={allPurchases}
            />
            <PurchaseTable
              showPagination={false}
              needRowData={false}
              data={purchasePending}
              classN="purchaseTable left-sorter"
              editAble={false}
              columns={pendingCulm(purchaseDetailsList,currency)}
              formState={PurchasePending}
              setColumnResize={setpendingPurchaseResize}
              resizeCol={pendingPurchaseColState}
              rowKey={"PurchaseOrderId"}
            />          
          </div>
          <div className="purchaseFooter">
            {totalCountP > lastCount - start + 1 &&
            allPurchases &&
            allPurchases.length > 0 ?(
              <>
              {PurchasePending.isSuccess ?
              <div className="PurchaseTablePagination">                          
                <PaginationTag
                  total={totalCountP}
                  current={currentPage}
                  defaultPageSize={pageSize}
                  onChange={onPageChange}
                /> </div>
                :
               null
               }             
                <Col>
                  <span className="searchTitleStyle purchaseTitleStyle">
                    {start}-{lastCount} of {totalCountP} Purchase
                  </span>
                </Col>
              </>
            ) : null
              }
          </div>
        </TabPane>
        <TabPane tab="Pre-Sale" key="4">
          <PreSale
            libId={libId}
            currentTab={tabVal}
            isLeavingWithoutSave={setUnSaveData}
            unSaveData={unSaveData}
            getIsLeaving={setShowStayModel}
            screenUrl={setUrlpath}
          />
        </TabPane>
      </Tabs>
      <IsUnsaveDataModel
        IsUnsaveDataShow={showStayModel}
        setDeclineUnsaveData={setShowStayModel}
        handleStayYes={handleStayYes}
        message={"Are you sure you want to leave without saving?"}
      />
      <Drawer
        placement="right"
        width="428"
        onClose={() => {
          setShowDrawer(false);
        }}
        visible={showDrawer}
      >
        <div className="searchPurchasesSideBar">
          <Row className="head-container">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <p className="searchPurSidebarHed">Search Purchases</p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="firstBox">
                <p className="searchPurSidebarSubHed">Purchase Date</p>
                <p className="searchPurSidebarSubDetail">Range</p>
                <Row className="pb-12">
                  <Col span={12}>
                    <DatePicker
                      className="card-datepicker"
                      format={dateFormat}
                      value={sideDrawerDate}
                      suffixIcon={<img className="iconImg" src={dropdownIcon} alt="" />}
                      placeholder="From"
                      onChange={dateFrom}
                    />
                  </Col>
                  <Col span={12} className="pl-6">
                    <DatePicker
                      className="card-datepicker"
                      value={sideDrawerDateTo}
                      format={dateFormat}
                      suffixIcon={<img className="iconImg" src={dropdownIcon} alt="" />}
                      placeholder="To"
                      onChange={dateTo}
                    />
                  </Col>
                </Row>
                <p className="searchPurSidebarSubDetail mb-7">Recent</p>
                <Col span={24}>
                  <DropDown
                    bordered={false}
                    className="dropDownBox pl-9"
                    value={parseInt(searchSideFilter?.days_Old)}
                    optionValue={filterOptions.purchaseDate}
                    icon={dropdownIcon}
                    onChange={handleRecent}
                  />
                </Col>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="firstBox">
                <p className="searchPurSidebarSubHed">Status</p>
                <Col span={24}>
                  <Radio.Group
                    onChange={handleStatus}
                    value={radioValue}
                    name="AutoBuy"
                  >
                    <Row>
                      <Col span={24} className="pb-8">
                        <Radio name="status" value={2}>
                          <span className="radio-span">Completed</span>
                        </Radio>
                      </Col>
                      <Col span={24} className="pb-8">
                        <Radio name="status" value={3}>
                          <span className="radio-span">Pending</span>
                        </Radio>
                      </Col>
                      <Col span={24}>
                        <Radio name="status" value={1}>
                          <span className="radio-span">All</span>
                        </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Col>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="firstBox">
                <p className="searchPurSidebarSubHed">Other</p>
                <Row>
                  <Col span={24} className="pb-12">
                    <DropDown
                      bordered={false}
                      defaultValue={(purchasesFilter.buyer !== undefined) ? purchasesFilter.buyer : ""}
                      value={
                        searchSideFilter?.buyer_id !== ""
                          ? searchSideFilter?.buyer_id
                          : "Buyers"
                      }
                      className="dropDownBox pl-9"
                      optionValue={[...defautlOpt, ...allBuyers]}
                      icon={dropdownIcon}
                      onChange={handleBuyer}
                    />
                  </Col>
                  <Col span={24}>
                    <Input
                      name="autoCartPurchase"
                      placeholder="PO Reference"
                      className="dropDownBox search searchIcon"
                      bordered={false}
                      onChange={handlePoref}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24} className="slider-footer">
              <Button onClick={submitSearch} type="primary confirm-button">
                Search
              </Button>
            </Col>
          </Row>
        </div>
      </Drawer>
      {/* </SpinnerLoader> */}
    </div>
  );
};
