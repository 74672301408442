import { createAsyncThunk } from "@reduxjs/toolkit";
import { ShelfService } from "../../../web/services/shelves/shelfService";
import { fetchCarts } from "../carts/cartAction";
import { showError } from "../../../utility/appUtil";

const ShelfSrv = new ShelfService();

export const fetchShelves = createAsyncThunk(
  "api/fetchShelves",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.fetchShelvesForLibrary(_args);
      if (data && data.length) {
        return data;
      } else {
        showError(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchShelveItems = createAsyncThunk(
  "api/fetchShelveItems",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.fetchShelveItems(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const setShelfItemOrder = createAsyncThunk(
  "api/setShelfItemOrder",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.setShelfItemOrder(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const resizeCompletedCols = createAsyncThunk(
  "api/resizeCompletedCols",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const deleteShelve = createAsyncThunk(
  "api/deleteShelve",
  async (payload: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.deleteShelv(payload);
      if (data === true) {
        thunkAPI.dispatch(fetchShelves(payload?.libId));
        thunkAPI.dispatch(fetchCarts(payload));
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const preDeleteShelv = createAsyncThunk(
  "api/preDeleteShelv",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.preDeleteShelv(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchShelfDetails = createAsyncThunk(
  "api/fetchShelfDetails",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.fetchShelveDetails(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchShelvByShelvId = createAsyncThunk(
  "api/fetchShelvByShelvId",
  async (payload: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.fetchShelvByShelvId(payload);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const updateCartShelv = createAsyncThunk(
  "api/updateCartShelv",
  async (payload: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.updateCartShelv(payload);
      if (data.ShelfId) {
        return setTimeout(() => {
          thunkAPI.dispatch(fetchShelvByShelvId(payload?.getShelv));
          thunkAPI.dispatch(fetchShelfDetails(payload?.getShelv));
        }, 100);
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const updateCartShelvName = createAsyncThunk(
  "api/updateCartShelvName",
  async (payload: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.updateCartShelv(payload);
      if (data.ShelfId) {
        return setTimeout(() => {
          thunkAPI.dispatch(fetchShelves(payload?.getShelv?.libraryId));
        }, 100);
      } else {
        return data;
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const createNewShelve = createAsyncThunk(
  "api/createNewShelve",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.createNewShelve(_args);
      if (data.ShelfId) {
        return thunkAPI.dispatch(fetchShelves(_args?.LibraryId));
      } else {
        return data;
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        error: e.data,
        ShelfName: _args.ShelfName,
      });
    }
  }
);

export const createNewAutoShelve = createAsyncThunk(
  "api/createNewAutoShelve",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.createNewShelve(_args);
      if (data.ShelfId) {
        thunkAPI.dispatch(fetchShelves(_args?.LibraryId));
        return data;
      } else {
        return data;
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue({
        error: e.data,
        ShelfName: _args.ShelfName,
      });
    }
  }
);

export const editAutoShelv = createAsyncThunk(
  "api/editAutoShelv",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.updateCartShelv(_args);
      if (data.ShelfId) {
        thunkAPI.dispatch(fetchShelves(_args?.getShelv?.libraryId));
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const removeShelvItems = createAsyncThunk(
  "api/removeShelvItems",
  async (payload: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.removeShelvItems(payload?.removeItem);
      if (data === true) {
        return setTimeout(() => {
          thunkAPI.dispatch(fetchShelfDetails(payload?.fetchSelv));
          thunkAPI.dispatch(fetchShelvByShelvId(payload?.fetchSelv));
          thunkAPI.dispatch(fetchShelves(payload?.fetchSelv?.libraryId));
        }, 100);
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const onSelectShelve = createAsyncThunk(
  "api/onSelectShelve",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const addItemtoShelve = createAsyncThunk(
  "api/addItemtoShelve",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.addShelvItems(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const addItemtoIndividualShelve = createAsyncThunk(
  "api/addItemtoIndividualShelve",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.addShelvItems(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const refreshShelv = createAsyncThunk(
  "api/refreshShelv",
  async (payload: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.fetchRefershShelvs(payload?.catShelfId);
      if (data?.data === true) {
        thunkAPI.dispatch(fetchShelveItems(payload?.fetchSelvItem));
        return data?.shelv
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchShelvesPatron = createAsyncThunk(
  "api/fetchShelvesPatron",
  async (_args: any, thunkAPI) => {
    try {
      const data = await ShelfSrv.fetchShelvesForLibrary(_args);
      if (data && data.length) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const savedShelfColumns = createAsyncThunk(
  "api/savedShelfColumns",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const retainShelvState = createAsyncThunk(
  "api/retainShelvState",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const retainShelvPostion = createAsyncThunk(
  "api/retainShelvPostion",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const updateShelvAfterSync = createAsyncThunk(
  "api/updateShelvAfterSync",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const newlyCreatedShelv = createAsyncThunk(
  "api/newlyCreatedShelv",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
