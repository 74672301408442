/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Dropdown,
  Menu,
  message,
  Spin
} from "antd";
import DropDown from "../../../stateless/common/dropDown";
import {
  dropdownIcon,
  editIcon,
  downIcon,
  list,
  asteriskIcon,
  downloadIcon,
  adduser,
} from "../../../../images";
import { filterOptions, Page } from "../../../../constants/constants";
import "./libraryStyles.less";
import Button from "../../../stateless/common/button";
import { PurchaseTable } from "../../purchases/purchaseTable";
import { useDispatch, useSelector } from "react-redux";
import { libraryStuffCol } from "../../purchases/tableColumns";
import PaginationTag from "../../../stateless/common/pagination";
import { addNewUser, fetchLibraryUsers, updateUser } from "../../../../../redux/actions/settings/settings";
import { SettingStateSelector,clearUserStatus, setUserFilter,setRoleFilter, clearUserFormState } from "../../../../../redux/reducers/settings/settingsReducer";
import LibraryDrawer from "./libraryDrawer";
import { CSVLink } from "react-csv";
import {LoadingOutlined} from "@ant-design/icons"
import { fetchUsers } from "../../../../../redux/actions/user/userAction";
import { SideBarStateSelector, retainPageSize } from "../../../../../redux/reducers/app/sideBarReducer";

const LibraryStuff = (props: any) => {
  const { libId,appUser } = props;
  const dispatch = useDispatch();
  const {
    libUsersData,
    libUsersFormState,
    addUser,
    addMsg,
    editUser,
    editmsg,
    userFilter,
    roleFilter
  } = useSelector(SettingStateSelector);
  const { retainedPageSize } = useSelector(SideBarStateSelector);
  const [users, setUsers] = useState("1");
  const [TotalCount, setTotalCount] = useState(0);
  const [roles, setRoles] = useState("ScreenName");
  const [sortDirection] = useState("asc");
  const [libUserData, setLibUserData] = useState<any>([]);
  const [getRowData, setGetRowData] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [libraryStuffResize, setLibraryStuffResize] = useState<any>([]);
  const optionList = [10, 20, 50, 100, 250, 500];
  const [pageSize, setPageSize] = useState<number>(retainedPageSize ?? 100);
  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState<number>(Page.DEFAULT_START);
  const [lastCount, setLastCount] = useState(start + pageSize - 1);
  const [drawerOpen,setDrawerOpen] = useState<boolean>(false);
  const [editdrawer,setEditdrawer] = useState<boolean>(false);

  const handleSortFilter = (value: any) => {
    setUsers(value);
    dispatch(setUserFilter(value))
    setStart(1)
    setCurrentPage(1)
  };

  const handleRoleFilter = (value: any) => {
    setRoles(value);
    dispatch(setRoleFilter(value))
    setStart(1)
    setCurrentPage(1)
  };

  const changePageSize = (size: number) => {
    setPageSize(size);
    dispatch(retainPageSize(size))
    setCurrentPage(1);
    setStart(1);
  };

  useEffect(()=>{
    if(users === "0"){   //all users
      setTotalCount(libUsersData?.TotalCount)
    }
    if(users === "2"){  //inactive users
      setTotalCount(libUsersData?.TotalCount)
    }
    if(users === "1"){  //active users
      setTotalCount(libUsersData?.TotalCount)
    }
  },[libUsersData?.TotalCount,users])

  useEffect(()=>{
    if(getRowData.length !== 0){
      setEditdrawer(true)
    }
  },[getRowData])
  
  const pageOptions = (
    <Menu className="header-dropdown">
      <Menu.Item key="page-size">
        <Row>
          <Col style={{ width: "20px" }}>
            <img style={{ width: "14px", height: "14px" }} src={list} alt=" " />
          </Col>
          <Col flex="auto" style={{ paddingLeft: "5px" }}>
            Page Size
          </Col>
        </Row>
      </Menu.Item>
      {optionList.map((data: number, index: number) => {
        return (
          <Menu.Item key={index} onClick={() => changePageSize(data)}>
            <Row>
              <Col style={{ width: "20px" }}>
                {pageSize === data ? (
                  <img
                    style={{ width: "14px", height: "14px" }}
                    src={asteriskIcon}
                    alt="*"
                  />
                ) : (
                  " "
                )}
              </Col>
              <Col style={{ paddingLeft: "5px" }}>{data}</Col>
            </Row>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const onPageChange = (page: any) => {
    const pageStart = (page - 1) * pageSize + 1;
    setStart(pageStart);
    setCurrentPage(page);
  };

  useEffect(() => {
      let end = start + pageSize - 1;
      if (libUsersData.TotalCount < end) {
        setLastCount(libUsersData.TotalCount);
      } else {
        setLastCount(end);
      }
  }, [start, pageSize, libUsersData.TotalCount,users]);


  useEffect(()=>{
    let dataCopied = libUsersData?.Users
    let nullRemoved = dataCopied?.map((data:any) => {
      if(data.LastName === null) {
        return {...data,LastName: ""}
      }else {
        return data
      }
    })
    var filterResult = nullRemoved
    if(roleFilter !== "ScreenName"){
      var filterResult = nullRemoved?.filter((data: any) => {
        if(roleFilter === "IsLibraryBuyer"){
          return data.IsLibraryBuyer === true;
        }else if(roleFilter === "IsLibraryAdmin"){
          return data.IsLibraryAdmin === true;
        }else if(roleFilter === "IsPatronCommunicator"){
          return data.IsPatronCommunicator === true;
        }else if(roleFilter === "IsPatronManager"){
          return data.IsPatronManager === true;
        }else if(roleFilter === "IsShelfAdmin"){
          return data.IsShelfAdmin === true;
        }else if(roleFilter === "IsLibraryUser"){
          return data.IsLibraryUser === true;
        }else{
          return data
        }
      })
    }
   
      setLibUserData(filterResult)
  },[libUsersData,users,roles]) 

  useEffect(() => {
    dispatch(
      fetchLibraryUsers({
        libraryId: libId,
        activeOnly: userFilter,
        recordCount: pageSize,
        recordStart: start,
        sortDirection: sortDirection,
        sortField: roleFilter,
      })
    );
  }, [libId,pageSize,sortDirection,roles,users,start,userFilter,roleFilter]);

const hadleSave =(data:any)=>{  
  var addUserPayload = {
    reqBody:data?.user,
    getPayload:{
      libraryId: libId,
      activeOnly: userFilter,
      recordCount: 100,
      recordStart: 1,
      sortDirection: "ASC",
      sortField: roleFilter,
    }
  }
  if(data?.update !== true){
    dispatch(
      addNewUser(addUserPayload)
    );
  }else{
    dispatch(
      updateUser(addUserPayload)
    );
  }
}

useEffect(() => {
  if(addUser?.isSuccess || editUser?.isSuccess){
    dispatch(
      fetchUsers({
        libId,
        activeOnly: 1
      })
    );
    dispatch(clearUserFormState())
  }
}, [addUser,editUser])

useEffect(() => {
  if(!editdrawer) {
    setGetRowData([])
  }
}, [editdrawer])

const headers = [
  { label: "FirstName", key: "FirstName" },
  { label: "LastName", key: "LastName" },
  { label: "Email", key: "EmailAddress" },
  { label: "Status", key: "status" },
];

const csvExportList = {
  filename: `LibraryStaffList.csv`,
  headers: headers,
  data: libUserData,
};

const showNotification = (content: any, type: any) => {
  if (type === "success") {
    message
      .success({
        content: content,
        key: "notificationMessage",
        duration: 4,
      })
      .then(() => {
        dispatch(clearUserStatus());
      });
  } else {
    message.error({
      content: content,
      key: "error",
    }).then(() => {
      dispatch(clearUserStatus());
    });
      }
};


useEffect(()=>{
  if(editmsg.data){
   showNotification('User updated successfully', "success");
  }
  if(editmsg.ErrorMessage) {
    showNotification(editmsg.ErrorMessage, "error");
  }
  if(addMsg.data){
    showNotification('User added successfully', "success");
    setDrawerOpen(false)
  }
  if(addMsg.ErrorMessage){
    if(addMsg.ErrorMessage === "Data validation failure. User Id already exists,User Id already exists"){
      showNotification('User Id already exists', "error");
    }else{
      showNotification(addMsg.ErrorMessage, "error");
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
},[editmsg,addMsg])
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Spin spinning={libUsersFormState.loading || editUser.loading || addUser.loading} tip="Loading..." indicator={antIcon}>
      <Row className="libraryStuffHeader">
        <Col span={12}>
          <Row>
            <Col sm={5} md={6} lg={7} xl={8} className="libraryCol">
              <DropDown
                value={userFilter}
                bordered={false}
                className="filters"
                optionValue={filterOptions.libraryUsers}
                icon={dropdownIcon}
                onChange={handleSortFilter}
              />
            </Col>
            <Col sm={5} md={6} lg={7} xl={8} className="libraryCol">
              <DropDown
                value={roleFilter}
                bordered={false}
                className="filters"
                optionValue={filterOptions.libraryRoles}
                icon={dropdownIcon}
                onChange={handleRoleFilter}
              />
            </Col>
            <Col sm={12} md={10} lg={8} xl={6} className="mt-5">
            {start}-{lastCount} of {TotalCount} Users
            </Col>
          </Row>
        </Col>
        <Col span={12} className="libraryStuffCol">
          <Col  md={12} lg={9} xl={7} className="downloaddiv">
            {libUserData !== undefined ?
            <CSVLink {...csvExportList} className="downloadLink">
               <Button type="downloadBtn cancel-button">
              <img src={downloadIcon} className="edit-icon" alt="" />
              Download List
              </Button>
            </CSVLink>
            : null}
          </Col>
          <Col  md={9} lg={7} xl={6} className="lib-btn-wrapper">
            <Button type="secondary cancel-button" onClick={()=> setDrawerOpen(true)}>
              <img src={adduser} className="edit-icon" alt="" />
              Add User
            </Button>
          </Col>
          <Col md={8} lg={8} xl={6}>
            <Dropdown overlay={pageOptions} trigger={["click"]}>
              <button className="button-type editor">
                <img src={editIcon} className="edit-icon" alt="" /> Editor
                &nbsp; &nbsp;
                <img src={downIcon} className="down-icon" alt="" />
              </button>
            </Dropdown>
          </Col>
        </Col>
      </Row>
      <PurchaseTable
        showPagination={false}
        data={libUserData}
        classN="purchaseTable left-sorter"
        editAble={false}
        needRowData={true}
        columns={libraryStuffCol}
        setclickedRowData={setGetRowData}
        setColumnResize={setLibraryStuffResize}
        rowKey={"key"}
      />
      {
        !libUsersFormState.loading ? <div className="LibraryStuffPagination"><PaginationTag
        total={TotalCount}
        current={currentPage}
        defaultPageSize={pageSize}
        onChange={onPageChange}
      /></div>
      : null
      }
      <Col>
        <span className="searchTitleStyle">
          {start}-{lastCount} of {TotalCount} Users
        </span>
      </Col>
      <LibraryDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} editable={false} editData={[]} hadleSave={hadleSave} appUser={appUser} title="Add New User" edituser={editUser?.loading} adduser={addUser?.loading} />
      <LibraryDrawer drawerOpen={editdrawer} setDrawerOpen={setEditdrawer} editable={true} editData={getRowData} hadleSave={hadleSave} appUser={appUser} title="Edit User" edituser={editUser?.loading} adduser={addUser?.loading} />
      </Spin>
  );
};

export default LibraryStuff;
