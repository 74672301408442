import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import { expirationRules } from "../../actions/expirationRules/expirationAction";
import { ExpirationState } from "../../states/expiration/expirationState";
import { RootState } from "../../store/rootReducer";

const initialState: ExpirationState = {
  expiration: [],
  formState: resetState(),
};

export const ExpirationSlice = createSlice({
  name: "expiration",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      return state;
    },
  },
  extraReducers: {
    [expirationRules.fulfilled.toString()]: (state, { payload }) => {
      state.expiration = payload;
      if (payload?.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
      }
      return state;
    },
    [expirationRules.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [expirationRules.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
  },
});

export const { clearState } = ExpirationSlice.actions;
export const ExpirationstateSelector = (state: RootState) => state.Expiration;
export default ExpirationSlice.reducer;
