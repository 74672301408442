import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import { RootState } from "../../store/rootReducer";
import { fetchLatestStatusOfTask, fetchMarcByDateRange, fetchMarcByIsbn, fetchMarcDownload, fetchStatusOfMarcTask } from "../../actions/marc/marcActions";
import { MarcState } from "../../states/marc/marcState";


const initialState: MarcState = {
  formState: resetState(),
  formStateDownload: resetState(),
  formStateDateRange: resetState(),
  formStateLatestStatus: resetState(),
  formStateMarcStatus: resetState(),
  latestStatusData: [],
  statusResult: [],
  marcError: [],
  dateRangeData: [],
  marcDownloadData: [],
  tabVal: "1",
  status: "",
  contentRadios:"EBOOK",
  timeFrameData:"7Days",
  format:"MRC",
   preSales:false,
   currentCollection:"INCLUDE_ALL",
   notIncludeCollection:false

};

export const MarcSlice = createSlice({
  name: "expiration",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      state.formStateDateRange=resetState();
      state.formStateLatestStatus=resetState();
      state.formStateMarcStatus=resetState();
      state.formStateDownload=resetState();
      state.latestStatusData=[]
      state.statusResult=[]
      state.dateRangeData=[]
      return state;
    },
    setContent:(state,action)=>{
      state.contentRadios=action.payload;
      return state;
    },
    setTimeFrameData:(state,action)=>{
      state.timeFrameData=action.payload;
      return state
;
    },
    setFormat:(state,action)=>{
      state.format=action.payload;
      return state
;
    },
    setPreSale:(state,action)=>{
      state.preSales=action.payload;
      return state;
    },
    setCurrentCollection:(state,action)=>{
      state.currentCollection=action.payload;
      return state;
    },
    setNotIncludeCollection:(state,action)=>{
      state.notIncludeCollection=action.payload;
      return state;
    },
    resetFilters: (state) => {
      state.contentRadios = "EBOOK";  
      state.timeFrameData="7Days";
      state.format="MRC";
      state.preSales=false;
      state.currentCollection="INCLUDE_ALL";
      state.notIncludeCollection=false;
      return state;
    },
    },
  
  extraReducers: {
    [fetchMarcByDateRange.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formStateDateRange = errorState(payload.errorMsg);
      } else {
        state.formStateDateRange = fulfilledState();
        state.dateRangeData=payload
      }
      return state;
    },
    [fetchMarcByDateRange.pending.toString()]: (state) => {
      state.formStateDateRange = pendingState();
      return state;
    },
    [fetchMarcByDateRange.rejected.toString()]: (state, { payload }) => {

      state.marcError = payload
      state.formStateDateRange = rejectedState(payload);

      return state;
    },

    [fetchMarcByIsbn.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
      }
      return state;
    },
    [fetchMarcByIsbn.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchMarcByIsbn.rejected.toString()]: (state, { payload }) => {
      state.marcError = payload
      state.formState = rejectedState(payload);
      return state;
    },

    [fetchLatestStatusOfTask.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formStateLatestStatus = errorState(payload.errorMsg);
      } else {
        state.latestStatusData=payload
        state.formStateLatestStatus = fulfilledState();
      }
      return state;
    },

    [fetchLatestStatusOfTask.pending.toString()]: (state) => {
      state.formStateLatestStatus = pendingState();
      return state;
    },
    [fetchLatestStatusOfTask.rejected.toString()]: (state, { payload }) => {
      state.latestStatusData = payload
      state.formStateLatestStatus = rejectedState(payload);

      return state;
    }, [fetchStatusOfMarcTask.fulfilled.toString()]:(state,{payload})=>{
      if(payload.errorMsg){
        state.formStateMarcStatus=errorState(payload.errorMsg);
      }else{
        state.formStateMarcStatus=fulfilledState();
        state.statusResult=payload

        // return state;

      }
      return state;
    },
    [fetchStatusOfMarcTask.pending.toString()]: (state) => {
      state.formStateMarcStatus = pendingState();

      return state;
    },
    [fetchStatusOfMarcTask.rejected.toString()]: (state, { payload }) => {
      state.statusResult = payload
      state.formStateMarcStatus = rejectedState(payload);
      return state;
    },
   
    [fetchMarcDownload.rejected.toString()]:(state,{payload})=>{
      state.marcDownloadData=payload
      state.formStateDownload=rejectedState(payload);
      return state;
    },
    [fetchMarcDownload.pending.toString()]:(state,{payload})=>{
      state.formStateDownload=pendingState();
      return state;
    },
    [fetchMarcDownload.fulfilled.toString()]:(state,{payload})=>{
      if(payload.errorMessage){
        state.formStateDownload=errorState(payload.errorMsg);
    }else{
      state.marcDownloadData=payload
      state.formStateDownload=fulfilledState();
      // return state;
    }
    return state;
    }
  },
});

export const { clearState,resetFilters,setContent,setFormat,setTimeFrameData,setCurrentCollection,setPreSale ,setNotIncludeCollection} = MarcSlice.actions;
export const MarcstateSelector = (state: RootState) => state.Marc;
export default MarcSlice.reducer;
