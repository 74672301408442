/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Switch, InputNumber, Radio, Input, Tabs } from "antd";
import DropDown from "../../../stateless/common/dropDown";
import Button from "../../../stateless/common/button";
import { downIcon, dropdownIcon, searchIcon } from "../../../../images";
import "./slider.less";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAutoCarts,
  saveAutoCart,
} from "../../../../../redux/actions/carts/cartAction";
import { filterOptions } from "../../../../constants/constants";
import { AutoCartType } from "../../../../constants/enums";
import DeactiveCartModel from "./deactiveCartModel/deactiveCartModel";
import { CartStateSelector } from "../../../../../redux/reducers/carts/cartsReducer";
import { PurchasingForm } from "./purchasing";
import { clearGroups } from "../../../../../redux/reducers/groups/groupListReducer";
import FormItem from "antd/lib/form/FormItem";
export const AutoCartSlider = (props: any) => {
  const {
    title,
    cart,
    rulesData,
    managerOptions,
    notifyOptions,
    setVisible,
    formState,
    Abook,
    getEbookData,
    getAbookData,
    setEditByClick,
    getSaveRules,
    allData,
    libId,
    presentBooks,
  } = props;
  const newData = useRef(JSON.parse(JSON.stringify(rulesData)));
  const oldData = useRef(JSON.parse(JSON.stringify(rulesData)));
  const { autoCartsData } = useSelector(CartStateSelector);
  const [manager, setManager] = useState<any>();
  const [notifyUsers, setNotifyUsers] = useState<any>([]);
  const [newRuleData, setNewRuleData] = useState<any>([]);
  const [switchActive, setSwitchActive] = useState<boolean>();
  const [autoCartStatus, setAutoCartStatus] = useState<boolean>(
    newData?.current?.Active
  );
  const [spendLimit, setSpendLimit] = useState<any>("");
  const [holdRatioLimit, setHoldRatioLimit] = useState<any>("");
  const [errorHOld, setErrorHold] = useState<boolean>(false);
  const [noOfSuggestion, setNoOfSuggestion] = useState<any>("");
  const [errorSuggestion, setErrorSuggestion] = useState<boolean>(false);
  const [poRefNo, setPoRefNo] = useState<any>("");
  const [errorSpend, setErrorSpend] = useState<boolean>(false);
  const [deactiveCart, setDeactiveCart] = useState(false);
  const [showRadio, setShowRadio] = useState<boolean>(false);
  const [prevState, setprevState] = useState<boolean>();
  const [subActiveTab, setSubActiveTab] = useState<string>();

  const [errorChild, setErrorChild] = useState<any>(false);
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  var auto: boolean = false;
  var manual: boolean = false;
  var selectedCartType = useRef<number>(0);

  const onSave = (updatedData: any) => {
    if (spendLimit !== "null") {
      if (spendLimit !== "") {
        if (
          JSON.stringify(newData?.current) !== JSON.stringify(oldData?.current)
        ) {
          getSaveRules(true);
          setVisible(false);
          setEditByClick(false);
          if (!Abook) {
            getEbookData(updatedData);
          } else {
            getAbookData(updatedData);
          }
        } else if (oldData?.current?.Rules[0] !== switchActive) {
          if (!Abook) {
            getEbookData({ ...newData.current.Rules[0], Active: switchActive });
          } else {
            getAbookData({ ...newData.current.Rules[0], Active: switchActive });
          }
        } else {
          return undefined;
        }
      } else {
        return setErrorSpend(true);
      }
    } else {
      return setErrorSpend(true);
    }
  };
  useEffect(() => {
    getEbookData(newData?.current);
    getAbookData(newData?.current);
    setAutoCartStatus(newData.current?.Active);
  }, [newData?.current, oldData?.current, switchActive]);

  useEffect(() => {
    getEbookData([]);
    getAbookData([]);
  }, []);
  useEffect(() => {
    if (cart?.CartName === AutoCartType.HOLDS) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 2) {
          setSpendLimit(newData?.current?.Rules[i]?.PurchaseCapForPeriod);
          setPoRefNo(newData?.current?.Rules[i]?.ExternalRefId);
          setHoldRatioLimit(newData.current.Rules[i].Properties[0].Value);
          setNoOfSuggestion(newData.current.Rules[i].Properties[0].Value);
        }
      }
    } else if (cart?.CartName === AutoCartType.CONTENT_EXP) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 1) {
          setSpendLimit(newData?.current?.Rules[i]?.PurchaseCapForPeriod);
          setPoRefNo(newData?.current?.Rules[i]?.ExternalRefId);
          setHoldRatioLimit(newData.current.Rules[i].Properties[0].Value);
          setNoOfSuggestion(newData.current.Rules[i].Properties[0].Value);
        }
      }
    } else if (cart?.CartName === AutoCartType.SUGGESTIONS) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 3) {
          setSpendLimit(newData?.current?.Rules[i]?.PurchaseCapForPeriod);
          setPoRefNo(newData?.current?.Rules[i]?.ExternalRefId);
          setHoldRatioLimit(newData.current.Rules[i].Properties[0].Value);
          setNoOfSuggestion(newData.current.Rules[i].Properties[0].Value);
        }
      }
    }
  }, [cart?.CartName, newData?.current]);

  useEffect(() => {
    if (subActiveTab === "1") {
      selectedCartType.current = 2;
    } else if (subActiveTab === "3") {
      selectedCartType.current = 3;
    } else {
      selectedCartType.current = 1;
    }
    getNotifyValue();
    getManagerValue();
  }, [subActiveTab]);
  const getRulesValue = (id: number) => {
    var temp = newData.current?.Rules.filter((rule: any) => {
      return rule?.AutoBuyRuleType === id;
    });
    if (id === 3) {
      return {
        value: temp[0]?.Properties[0]?.Value,
        duration: temp[0]?.Properties[1]?.Value,
        preSale: temp[0]?.Properties[2]?.Value !== "false" ? true : false,
      };
    } else {
      return temp[0]?.Properties[0]?.Value;
    }
  };
  const getRulesState = (id: number) => {
    var temp = newData.current?.Rules.filter((rule: any) => {
      return rule?.AutoBuyRuleType === id;
    });
    return temp[0]?.Active;
  };
  const handleChange = (e: any) => {
    var value = e.target.value;
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        if (value === auto) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            AutoBuy: true,
            DoNotBuy: false,
          };
          setShowRadio(true);
        } else if (value === manual) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            AutoBuy: false,
            DoNotBuy: true,
          };
          setShowRadio(false);
        }
      }
    }
  };
  const getNotifyValue = () => {
    var tempNotify = null;
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        tempNotify = newData.current?.Rules[i]?.NotificationUserIds?.split(",");
      }
    }
    setNotifyUsers(tempNotify);
    return tempNotify;
  };

  const getManagerValue = () => {
    var tempMang = null;
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        tempMang = newData.current?.Rules[i]?.CartManagerUserId;
      }
    }
    setManager(tempMang);
    return tempMang;
  };
  const handleDurationChange = (value: any) => {
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        newData.current.Rules[i].Properties[1] = {
          ...newData.current.Rules[i].Properties[1],
          Value: value,
        };
      }
    }
  };

  const handleHoldsChange = (value: any) => {
    if (holdRatioLimit === "" && value === "") {
      setHoldRatioLimit("");
      setErrorHold(true);
    } else if (holdRatioLimit < 1 && value < 1) {
      setHoldRatioLimit("");
      setErrorHold(true);
    } else if (value < 1) {
      setHoldRatioLimit("");
      setErrorHold(true);
    } else if (JSON.stringify(value) === "") {
      setHoldRatioLimit("");
      setErrorHold(true);
    } else {
      setHoldRatioLimit(value);
      setErrorHold(false);
      for (var i = 0; i < newData.current.Rules.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 2) {
          newData.current.Rules[i].Properties[0].Value = value;
        }
      }
    }
  };

  const handleSuggestionsChange = (value: any) => {
    if (noOfSuggestion === "" && value === "") {
      setNoOfSuggestion("");
      setErrorSuggestion(true);
    } else if (noOfSuggestion < 1 && value < 1) {
      setNoOfSuggestion("");
      setErrorSuggestion(true);
    } else if (value < 1) {
      setNoOfSuggestion("");
      setErrorSuggestion(true);
    } else if (JSON.stringify(value) === "") {
      setNoOfSuggestion("");
      setErrorSuggestion(true);
    } else {
      setNoOfSuggestion(value);
      setErrorSuggestion(false);
      for (var i = 0; i < newData.current.Rules.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 3) {
          for (var j = 0; j < newData.current.Rules[i].Properties.length; j++) {
            if (newData.current.Rules[i].Properties[j].Name === "Suggestions") {
              newData.current.Rules[i].Properties[j].Value = value;
            }
          }
        }
      }
    }
  };
  const handleRenewalsChange = (value: any) => {
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (newData.current.Rules[i].AutoBuyRuleType === 1) {
        for (var j = 0; j < newData.current.Rules[i].Properties.length; j++) {
          if (newData.current.Rules[i].Properties[j].Name === "CheckoutLimit") {
            newData.current.Rules[i].Properties[j].Value = value;
          }
        }
      }
    }
  };
  const onRuleValueUpdate = (checked: any) => {
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (newData.current.Rules[i].AutoBuyRuleType === 3) {
        newData.current.Rules[i].Properties[2].Value = JSON.stringify(checked);
      }
    }
  };
  const updateSwitchData = (checked: any, ruleId: number) => {
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (newData.current.Rules[i].AutoBuyRuleType === ruleId) {
        newData.current.Rules[i].Active = checked;
      }
    }
  };
  const getPurchaseType = () => {
    if (subActiveTab === "1") {
      selectedCartType.current = 2;
    } else if (subActiveTab === "3") {
      selectedCartType.current = 3;
    } else {
      selectedCartType.current = 1;
    }
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        if (
          newData.current.Rules[i].AutoBuy &&
          !newData.current.Rules[i].DoNotBuy
        ) {
          auto = true;
          manual = false;
        } else {
          auto = false;
          manual = true;
        }
      }
    }
  };

  useEffect(() => {
    setShowRadio(auto);
  }, [auto]);

  const getRulesContent = () => {
    if (subActiveTab === "1") {
      return (
        <>
          <Row justify="space-between" className="rules-row">
            <Col span={10}>
              {/* <span className="cart-title">Hold Ratio</span> */}
            </Col>
            <Col span={3}>
              {/* <Switch
                size="small"
                className="autoCart-switch"
                defaultChecked={getRulesState(2)}
                onChange={(checked: any) => updateSwitchData(checked, 2)}
              /> */}
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={20}>
              <span className="cart-title">Hold Ratio Limit</span>
            </Col>
            <Col span={4}>
              <InputNumber
                min={0}
                key="holds"
                className="autoCart-input"
                size="middle"
                defaultValue={getRulesValue(2)}
                onChange={handleHoldsChange}
              />
            </Col>
            <div style={{ paddingTop: "10px" }}>
              {errorHOld === false ? (
                <></>
              ) : (
                <p style={{ color: "red", fontSize: "12px" }}>
                  Hold Ratio Limit is required, and must be at least 1
                </p>
              )}
            </div>
          </Row>
        </>
      );
    } else if (subActiveTab === "3") {
      return (
        <>
          <Row justify="space-between" className="rules-row">
            <Col span={10}>
              {/* <span className="cart-title">Suggestions</span> */}
            </Col>
            <Col span={3}>
              {/* <Switch
                size="small"
                className="autoCart-switch"
                defaultChecked={getRulesState(3)}
                onChange={(checked: any) => updateSwitchData(checked, 3)}
              /> */}
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={20}>
              <span className="cart-title">Number of suggestions</span>
            </Col>
            <Col span={4}>
              <InputNumber
                min={0}
                key="suggestion"
                className="autoCart-input"
                size="middle"
                defaultValue={getRulesValue(3)?.value}
                onChange={handleSuggestionsChange}
              />
            </Col>
            <div style={{ paddingTop: "10px" }}>
              {errorSuggestion === false ? (
                <></>
              ) : (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    alignItems: "center",
                  }}
                >
                  Number of suggestions is required, and must be at least 1
                </p>
              )}
            </div>
          </Row>
          <Row style={{ paddingTop: "12px" }}>
            <Col span={24}>
              <div className="card-dropdown-withLabel">
                <label>Duration</label>
                <DropDown
                  placeholder="Duration"
                  className="card-dropdown with-search"
                  defaultValue={getRulesValue(3)?.duration}
                  onChange={handleDurationChange}
                  bordered={false}
                  optionValue={filterOptions.duration}
                  icon={downIcon}
                />
              </div>
            </Col>
          </Row>
          <Row justify="space-between" className="rules-row">
            <Col span={12}>
              <span className="cart-title">Include pre-sale titles</span>
            </Col>
            <Col span={3}>
              <Switch
                size="small"
                className="autoCart-switch"
                defaultChecked={getRulesValue(3)?.preSale}
                onChange={onRuleValueUpdate}
              />
            </Col>
          </Row>
        </>
      );
    } else if (subActiveTab === "2") {
      return (
        <>
          <Row justify="space-between" className="rules-row">
            <Col span={10}>
              {/* <span className="cart-title">Content Expiration</span> */}
            </Col>
            <Col span={3}>
              {/* <Switch
                size="small"
                className="autoCart-switch"
                defaultChecked={getRulesState(1)}
                onChange={(checked: any) => updateSwitchData(checked, 1)}
              /> */}
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={20}>
              <span className="cart-title">Minimum # of Checkouts</span>
            </Col>
            <Col span={4}>
              <InputNumber
                min={0}
                key="renewals"
                className="autoCart-input"
                size="middle"
                defaultValue={getRulesValue(1)}
                onChange={handleRenewalsChange}
              />
            </Col>
          </Row>
        </>
      );
    }
  };

  const onSwitch = (checked: any) => {
    if (checked === false) {
      if (prevState !== checked) {
        setDeactiveCart(true);
        newData.current = {
          ...newData.current,
          Active: true,
        };
      } else {
        if (subActiveTab === "1") {
          for (var i = 0; i < newData.current.Rules?.length; i++) {
            if (newData.current.Rules[i].AutoBuyRuleType === 2) {
              newData.current = {
                ...newData.current,
                Active: true,
              };
              newData.current.Rules[i] = {
                ...newData.current.Rules[i],
                Active: checked,
              };
            }
          }
        } else if (subActiveTab === "2") {
          for (var i = 0; i < newData.current.Rules?.length; i++) {
            if (newData.current.Rules[i].AutoBuyRuleType === 1) {
              newData.current = {
                ...newData.current,
                Active: true,
              };
              newData.current.Rules[i] = {
                ...newData.current.Rules[i],
                Active: checked,
              };
            }
          }
        } else if (subActiveTab === "3") {
          for (var i = 0; i < newData.current.Rules?.length; i++) {
            if (newData.current.Rules[i].AutoBuyRuleType === 3) {
              newData.current = {
                ...newData.current,
                Active: true,
              };
              newData.current.Rules[i] = {
                ...newData.current.Rules[i],
                Active: checked,
              };
            }
          }
        }
        setSwitchActive(false);
      }
    } else {
      if (subActiveTab === "1") {
        for (var i = 0; i < newData.current.Rules?.length; i++) {
          if (newData.current.Rules[i].AutoBuyRuleType === 2) {
            newData.current = {
              ...newData.current,
              Active: true,
            };
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              Active: checked,
            };
          }
        }
      } else if (subActiveTab === "2") {
        for (var i = 0; i < newData.current.Rules?.length; i++) {
          if (newData.current.Rules[i].AutoBuyRuleType === 1) {
            newData.current = {
              ...newData.current,
              Active: true,
            };
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              Active: checked,
            };
          }
        }
      } else if (subActiveTab === "3") {
        for (var i = 0; i < newData.current.Rules?.length; i++) {
          newData.current = {
            ...newData.current,
            Active: true,
          };
          if (newData.current.Rules[i].AutoBuyRuleType === 3) {
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              Active: checked,
            };
          }
        }
      }
      setSwitchActive(true);
    }
  };

  const onActiveCartSwitch = (checked: any) => {
    if (checked === false) {
      if (prevState !== checked) {
        setDeactiveCart(true);
        newData.current = {
          ...newData.current,
          Active: true,
        };
      } else {
        if (subActiveTab === "1") {
          for (var i = 0; i < newData.current.Rules?.length; i++) {
            if (newData.current.Rules[i].AutoBuyRuleType === 2) {
              newData.current = {
                ...newData.current,
                Active: true,
              };
              newData.current.Rules[i] = {
                ...newData.current.Rules[i],
                Active: checked,
              };
            } else {
              newData.current.Rules[i] = {
                ...newData.current.Rules[i],
                Active: false,
              };
            }
          }
        } else if (subActiveTab === "2") {
          for (var i = 0; i < newData.current.Rules?.length; i++) {
            if (newData.current.Rules[i].AutoBuyRuleType === 1) {
              newData.current = {
                ...newData.current,
                Active: true,
              };
              newData.current.Rules[i] = {
                ...newData.current.Rules[i],
                Active: checked,
              };
            } else {
              newData.current.Rules[i] = {
                ...newData.current.Rules[i],
                Active: false,
              };
            }
          }
        } else if (subActiveTab === "3") {
          for (var i = 0; i < newData.current.Rules?.length; i++) {
            if (newData.current.Rules[i].AutoBuyRuleType === 3) {
              newData.current = {
                ...newData.current,
                Active: true,
              };
              newData.current.Rules[i] = {
                ...newData.current.Rules[i],
                Active: checked,
              };
            } else {
              newData.current.Rules[i] = {
                ...newData.current.Rules[i],
                Active: false,
              };
            }
          }
        }
        setSwitchActive(false);
      }
    } else {
      if (subActiveTab === "1") {
        for (var i = 0; i < newData.current.Rules?.length; i++) {
          if (newData.current.Rules[i].AutoBuyRuleType === 2) {
            newData.current = {
              ...newData.current,
              Active: true,
            };
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              Active: checked,
            };
          } else {
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              Active: false,
            };
          }
        }
      } else if (subActiveTab === "2") {
        for (var i = 0; i < newData.current.Rules?.length; i++) {
          if (newData.current.Rules[i].AutoBuyRuleType === 1) {
            newData.current = {
              ...newData.current,
              Active: true,
            };
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              Active: checked,
            };
          } else {
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              Active: false,
            };
          }
        }
      } else if (subActiveTab === "3") {
        for (var i = 0; i < newData.current.Rules?.length; i++) {
          if (newData.current.Rules[i].AutoBuyRuleType === 3) {
            newData.current = {
              ...newData.current,
              Active: true,
            };
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              Active: checked,
            };
          } else {
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              Active: false,
            };
          }
        }
      }
      setSwitchActive(true);
    }
  };

  const forwardCheck = (value: boolean) => {
    if (subActiveTab === "1") {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 2) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            Active: value,
          };
        }
      }
    } else if (subActiveTab === "2") {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 1) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            Active: value,
          };
        }
      }
    } else if (subActiveTab === "3") {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 3) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            Active: value,
          };
        }
      }
    }
    setSwitchActive(value);
  };

  useEffect(() => {
    if (subActiveTab === "1") {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 2) {
          setSwitchActive(newData.current?.Rules[i]?.Active);
          setprevState(newData.current?.Rules[i]?.Active);
        }
      }
    } else if (subActiveTab === "2") {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 1) {
          setSwitchActive(newData.current?.Rules[i]?.Active);
          setprevState(newData.current?.Rules[i]?.Active);
        }
      }
    } else if (subActiveTab === "3") {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 3) {
          setSwitchActive(newData.current?.Rules[i]?.Active);
          setprevState(newData.current?.Rules[i]?.Active);
        }
      }
    }
  }, [cart?.CartName, subActiveTab]);

  const onCancel = () => {
    var apiData = autoCartsData;
    dispatch(resetAutoCarts(apiData));
    setVisible(false);
    setEditByClick(false);
  };

  useEffect(() => {
    if (cart?.CartName === AutoCartType.HOLDS) {
      setSubActiveTab("1");
    } else if (cart?.CartName === AutoCartType.CONTENT_EXP) {
      setSubActiveTab("2");
    } else {
      setSubActiveTab("3");
    }
  }, []);

  const activeTab = (key: any) => {
    setSubActiveTab(key);
  };

  useEffect(() => {
    if (newRuleData?.RuleName === AutoCartType.HOLDS) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 2) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            PurchaseCapForPeriod: newRuleData?.PurchaseCapForPeriod,
            ExternalRefId: newRuleData?.ExternalRefId,
            CartManagerUserId: newRuleData?.CartManagerUserId,
            NotificationUserIds: newRuleData?.NotificationUserIds,
            AutoBuy: newRuleData?.AutoBuy,
            DoNotBuy: newRuleData?.DoNotBuy,
          };
        }
      }
    } else if (newRuleData?.RuleName === AutoCartType.CONTENT_EXP) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 1) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            PurchaseCapForPeriod: newRuleData?.PurchaseCapForPeriod,
            ExternalRefId: newRuleData?.ExternalRefId,
            CartManagerUserId: newRuleData?.CartManagerUserId,
            NotificationUserIds: newRuleData?.NotificationUserIds,
            AutoBuy: newRuleData?.AutoBuy,
            DoNotBuy: newRuleData?.DoNotBuy,
          };
        }
      }
    } else if (newRuleData?.RuleName === AutoCartType.SUGGESTIONS) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 3) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            PurchaseCapForPeriod: newRuleData?.PurchaseCapForPeriod,
            ExternalRefId: newRuleData?.ExternalRefId,
            CartManagerUserId: newRuleData?.CartManagerUserId,
            NotificationUserIds: newRuleData?.NotificationUserIds,
            AutoBuy: newRuleData?.AutoBuy,
            DoNotBuy: newRuleData?.DoNotBuy,
          };
        }
      }
    } else {
      return undefined;
    }
  }, [newRuleData]);

  return (
    <>
      <Tabs activeKey={subActiveTab} onTabClick={activeTab}>
        <TabPane
          disabled={presentBooks?.includes("Holds") ? false : true}
          tab="Hold"
          key="1"
        >
          <div className="scrollContent scrollStyle auto-rules">
            {getPurchaseType()}
            <Row justify="space-between">
              <Col span={18}>
                <p className="cart-title">{title} Auto Cart</p>
              </Col>
              <Col span={2}>
                {autoCartStatus !== false ? (
                  <Switch
                    size="small"
                    className="autoCart-switch"
                    defaultChecked={switchActive}
                    checked={switchActive}
                    onChange={onSwitch}
                  />
                ) : (
                  <Switch
                    size="small"
                    className="autoCart-switch hol"
                    defaultChecked={false}
                    onChange={onActiveCartSwitch}
                  />
                )}
              </Col>
            </Row>
            {autoCartStatus !== false ? (
              <PurchasingForm
                title={title}
                libId={libId}
                cart={{...cart, CartName: "Holds"}}
                rulesData={rulesData}
                managerOptions={managerOptions}
                notifyOptions={notifyOptions}
                setVisible={setVisible}
                getSaveRules={getSaveRules}
                formState={formState}
                Abook={Abook}
                getAbookData={getAbookData}
                getEbookData={getEbookData}
                getNewRuleData={setNewRuleData}
                setErrorChild={setErrorChild}
              />
            ) : null}

            <p className="cart-title">Rules</p>
            <Row className="details-row">
              <Col span={24}>{getRulesContent()}</Col>
            </Row>
            <Row className="slider-footer" gutter={[10, 10]}>
              <Col span={12}>
                <Button type="secondary cancel-button" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  disabled={errorChild || errorHOld}
                  type="primary confirm-button"
                  onClick={() => onSave(newData.current)}
                >
                  Save
                </Button>
              </Col>
            </Row>
            <DeactiveCartModel
              deactiveCart={deactiveCart}
              setDeactiveCart={setDeactiveCart}
              forwardCheck={forwardCheck}
            />
          </div>
        </TabPane>
        <TabPane
          disabled={presentBooks?.includes("Suggestions") ? false : true}
          tab="Suggestions"
          key="3"
        >
          <div className="scrollContent scrollStyle auto-rules">
            {getPurchaseType()}
            <Row justify="space-between">
              <Col span={18}>
                <p className="cart-title">{title} Auto Cart</p>
              </Col>
              <Col span={2}>
                {autoCartStatus !== false ? (
                  <Switch
                    size="small"
                    className="autoCart-switch"
                    defaultChecked={switchActive}
                    checked={switchActive}
                    onChange={onSwitch}
                  />
                ) : (
                  <Switch
                    size="small"
                    className="autoCart-switch suges"
                    defaultChecked={false}
                    onChange={onActiveCartSwitch}
                  />
                )}
              </Col>
            </Row>
            {autoCartStatus !== false ? (
              <PurchasingForm
                title={title}
                libId={libId}
                cart={{ ...cart, CartName: "Suggestions" }}
                rulesData={rulesData}
                managerOptions={managerOptions}
                notifyOptions={notifyOptions}
                setVisible={setVisible}
                getSaveRules={getSaveRules}
                formState={formState}
                Abook={Abook}
                getAbookData={getAbookData}
                getEbookData={getEbookData}
                getNewRuleData={setNewRuleData}
                setErrorChild={setErrorChild}
              />
            ) : null}

            <p className="cart-title">Rules</p>
            <Row className="details-row">
              <Col span={24}>{getRulesContent()}</Col>
            </Row>
            <Row className="slider-footer" gutter={[10, 10]}>
              <Col span={12}>
                <Button type="secondary cancel-button" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  disabled={errorChild || errorSuggestion}
                  type="primary confirm-button"
                  onClick={() => onSave(newData.current)}
                >
                  Save
                </Button>
              </Col>
            </Row>
            <DeactiveCartModel
              deactiveCart={deactiveCart}
              setDeactiveCart={setDeactiveCart}
              forwardCheck={forwardCheck}
            />
          </div>
        </TabPane>
        <TabPane
          disabled={presentBooks?.includes("Content Expiration") ? false : true}
          tab="Content Expiration"
          key="2"
        >
          <div className="scrollContent scrollStyle auto-rules">
            {getPurchaseType()}
            <Row justify="space-between">
              <Col span={18}>
                <p className="cart-title">{title} Auto Cart</p>
              </Col>
              <Col span={2}>
                {autoCartStatus !== false ? (
                  <Switch
                    size="small"
                    className="autoCart-switch"
                    defaultChecked={switchActive}
                    checked={switchActive}
                    onChange={onSwitch}
                  />
                ) : (
                  <Switch
                    size="small"
                    className="autoCart-switch contExp"
                    defaultChecked={false}
                    onChange={onActiveCartSwitch}
                  />
                )}
              </Col>
            </Row>
            {autoCartStatus !== false ? (
              <PurchasingForm
                title={title}
                libId={libId}
                cart={{ ...cart, CartName: "Content Expiration" }}
                rulesData={rulesData}
                managerOptions={managerOptions}
                notifyOptions={notifyOptions}
                setVisible={setVisible}
                getSaveRules={getSaveRules}
                formState={formState}
                Abook={Abook}
                getAbookData={getAbookData}
                getEbookData={getEbookData}
                getNewRuleData={setNewRuleData}
                setErrorChild={setErrorChild}
              />
            ) : null}

            <p className="cart-title">Rules</p>
            <Row className="details-row">
              <Col span={24}>{getRulesContent()}</Col>
            </Row>
            <Row className="slider-footer" gutter={[10, 10]}>
              <Col span={12}>
                <Button type="secondary cancel-button" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  disabled={errorChild}
                  type="primary confirm-button"
                  onClick={() => onSave(newData.current)}
                >
                  Save
                </Button>
              </Col>
            </Row>
            <DeactiveCartModel
              deactiveCart={deactiveCart}
              setDeactiveCart={setDeactiveCart}
              forwardCheck={forwardCheck}
            />
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};
