import React, { useEffect, useMemo, useState } from "react";
import { TreeSelect, Checkbox } from "antd";
import "./multiselect.less";

const MultiSelect = (props: any) => {
  const {
    placeholder,
    onChange,
    maxTagCount,
    className,
    option,
    defaultValue,
    maxSelection,
    id,
    isContentProvider,
    headingText,
  } = props;

  const { TreeNode } = TreeSelect;

  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [allIds, setAllIds] = useState<any>([]);

  useEffect(() => {
    setOptions(option);
  }, [option]);

  useEffect(() => {
    setAllIds(options?.map(({ value }: any) => value));
  }, [options]);

  const handelSelect = (value: any, defaultValue: any) => {
    const limitedValues: any = value?.slice(0, maxSelection);
    const onlyVal: any = limitedValues?.map((item: any) => item?.value);
    setSelectedValues(onlyVal);

    const disabledOptions = allIds?.filter(
      (id: any) =>
        limitedValues?.findIndex((item: any) => item?.value === id) === -1
    );

    onChange(onlyVal, defaultValue, disabledOptions);
  };

  const handelSelectAll = () => {
    const limitedValues = allIds.slice(0, maxSelection);
    setSelectedValues(limitedValues);

    onChange(limitedValues, [], []);
  };

  const handelShowOnlyChecked = (e: any) => {
    if (e?.target?.checked) {
      const onlyChecked = option?.filter((item: any) => {
        return selectedValues?.includes(item?.value);
      });
      setOptions(onlyChecked);
    } else {
      setOptions(option);
    }
  };
  
  /**
  * Memoizes the disabling of language options based on selection constraints.
  *
  * Disables checkboxes and options when the selection limit (`maxSelection`) is reached, 
  * but only for options not already selected. This logic is applied only when `id` is "languages". 
  * If `id` is different, the original `options` array is returned unmodified.
  *
  * Dependencies: `options`, `selectedValues`, `maxSelection`, `id`.
  *
  * @returns {Array} Modified options with `disableCheckbox` and `disabled` set to true where applicable.
  */
  const disableLanguageOptions = useMemo(() => {
    if(id === "languages"){
      return options.map((option: any) => ({
        ...option,
        disableCheckbox: selectedValues.length >= maxSelection && selectedValues.indexOf(option.value) === -1,
        disabled : selectedValues.length >= maxSelection && selectedValues.indexOf(option.value) === -1,
      }));
    } else return options;
  }, [options, selectedValues, maxSelection, id]);

  const handelUnSelectAll = () => {
    setSelectedValues([]);
    onChange([], [], allIds);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedValues(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (isContentProvider && selectedValues.length <= 0) {
      setOptions(option);
    }
  }, [selectedValues, isContentProvider]);

  const handleSelect = (value: any, defaultValue: any) => {
    const limitedValues = value.slice(0, maxSelection);
    const onlyVal = limitedValues.map((item: any) => item.value);
    setSelectedValues(onlyVal);

    const disabledOptions = allIds.filter(
      (id: any) =>
        limitedValues.findIndex((item: any) => item.value === id) === -1
    );

    onChange(onlyVal, defaultValue, disabledOptions);
  };

  const customDropdown = () => (
    <div className="customFIxedHeader">
      {isContentProvider ? (
        selectedValues?.length > 0 ? (
          <>
            <span
              onClick={handelUnSelectAll}
              className="multiTitleFont"
              style={{
                display: "inline-block",
                color: "black",
                cursor: "pointer",
              }}
            >
              Unselect All
            </span>
            <span
              className="showOnlyCheckedBtn multiTitleFont"
              style={{
                display: "inline-block",
                color: "black",
                cursor: "pointer",
              }}
            >
              <Checkbox
                className="multiTitleFont"
                onChange={handelShowOnlyChecked}
              >
                Show only selected providers
              </Checkbox>
            </span>
          </>
        ) : (
          <>
            <span
              // onClick={handelSelectAll}
              className="multiTitleFont"
              style={{
                display: "inline-block",
                color: "black",
                cursor: "default",
              }}
            >
              {maxSelection ? (
                `Select up to ${maxSelection} languages`
              ) : isContentProvider ? (
                <span className="contentText multiTitleFont">
                  {headingText}
                </span>
              ) : (
                "Select All"
              )}
            </span>
          </>
        )
      ) : selectedValues?.length > 0 ? (
        <span
          onClick={handelUnSelectAll}
          className="multiTitleFont"
          style={{
            display: "inline-block",
            color: "black",
            cursor: "pointer",
          }}
        >
          Unselect All
        </span>
      ) : (
        <span
          // onClick={handelSelectAll}
          className="multiTitleFont"
          style={{
            display: "inline-block",
            color: "black",
            cursor: "default",
          }}
        >
          {maxSelection ? (
            `Select up to ${maxSelection} languages`
          ) : isContentProvider ? (
            <span className="contentText multiTitleFont">{headingText}</span>
          ) : (
            "Select All"
          )}
        </span>
      )}
    </div>
  );

  const renderTreeNode = (data: any) => {
    return data?.map((item: any) => (
      <TreeNode
        key={item?.value}
        title={item?.title}
        value={item?.value}
        disabled={item?.disabled}
      >
        {item?.children && renderTreeNode(item?.children)}
      </TreeNode>
    ));
  };

  return (
    <>
      <TreeSelect
        className={`${className} multiSelectDropDown`}
        allowClear={true}
        placeholder={placeholder}
        treeCheckable={true}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        treeCheckStrictly={true} // Allow checking any number of items
        style={{ width: "100%" }}
        dropdownStyle={{ maxHeight: "300px", minWidth: "400px" }}
        onChange={handelSelect}
        value={selectedValues}
        defaultValue={defaultValue}
        maxTagCount={maxTagCount}
        maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length} ...`}
        treeData={disableLanguageOptions}
        filterTreeNode={(search: any, item: any) => {
          return item.titleB.toLowerCase().indexOf(search.toLowerCase()) >= 0;
        }}
        dropdownRender={(originNode) => (
          <div>
            {customDropdown()}
            <div style={{ maxHeight: 300, overflow: "auto" }}>{originNode}</div>
          </div>
        )}
      />
    </>
  );
};

export default MultiSelect;
