import { useEffect } from "react"
import { Row, Modal } from "antd";
import { confirmIcon } from "../../../../../images";
import Button from "../../../../stateless/common/button";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../../../router/appRoutes";
import { useDispatch, useSelector } from "react-redux";
import { fetchCarts } from "../../../../../../redux/actions/carts/cartAction";
import { CartStateSelector, clearDeleted } from "../../../../../../redux/reducers/carts/cartsReducer";
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import SpinnerLoader from "../../../../stateless/common/spinnerLoader";

export const PurchaseSuccessModal = (props: any) => {
  const { purchased, libId, formState,titles,setPurchased } = props
  const dispatch = useDispatch()
  const history = useHistory();
  const { cartDeleted } = useSelector(CartStateSelector)
  const { appUser } = useSelector(userStateSelector);
  useEffect(() => {
    if (cartDeleted) {
      dispatch(fetchCarts({
        libId,
        appuser: appUser?.email
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartDeleted])

  const handleContinue =()=>{
    if(titles < 1 || cartDeleted){
      dispatch(clearDeleted())
      history.push(AppRoutes.CART);
    }else{
      return setPurchased(false)
    }
  }

  return (
    <Modal
      wrapClassName="purchase-modal"
      visible={purchased}
      closable={false}
      footer={
        <div className="footer">
          {/* <Row justify="center">
            <Button
              type="primary confirm-button"
            >
              Manage Shelves
            </Button>
            </Row> */}           
          <Row justify="center">
              <Button type="done-button" onClick={handleContinue}>
                Continue
              </Button>
          </Row>
        </div>
      }
    >
      <SpinnerLoader loading={formState}>
      <Row justify="center" style={{ paddingBottom: "20px" }}>
        <img src={confirmIcon} alt="" />
      </Row>
      <p className="purchase-title">Purchase Submitted</p>
      </SpinnerLoader>
    </Modal>
  )
}
