/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Row,
  Input,
  Col,
  Tooltip,
  Form,
  Dropdown,
  Menu,
} from "antd";
import Button from "../../stateless/common/button";
import { useDispatch, useSelector } from "react-redux";
import { PurchaseTable } from "./purchaseTable";
import {
  PurchaseStateSelector,
} from "../../../../redux/reducers/purchases/purchases";
import {
  getPrePub,
  updatePrePub,
  deletePrePub,
  searchFilters,
  onApplyingFilters,
  resizePreSaleCols,
} from "../../../../redux/actions/purchases/purchasesActions";
import "../carts/cartDetails/cartDetails.less";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { useHistory, useLocation } from "react-router-dom";
import {
  notFound,ebookLabel, audiobookLabel, videobookLabel
} from "../../../images";
import { AppRoutes } from "../../../router/appRoutes";
import {
  getSearchString,
  onBackFillForm,
} from "../../../../redux/actions/search/searchActions";
import { searchPayloadForAuthor } from "../../../../utility/searchUtil";
import { CartStateSelector } from "../../../../redux/reducers/carts/cartsReducer";
import { PurchaseHeader } from "./PurchaseHeader";
import PublicationDate from "../carts/cartDetails/publicationDate";
import PaginationTag from "../../stateless/common/pagination";
import { Page } from "../../../constants/constants";
import RemoveConfirmModel from "../carts/cartDetails/remove/removeModal";
import EditPoReferenceModel from "./stayModel/editPOReferenceModal";
import AddToShelfModel from "./stayModel/addToShelfModal";
import { fetchShelves } from "../../../../redux/actions/shelves/shelfAction";
import { SideBarStateSelector, retainPageSize } from "../../../../redux/reducers/app/sideBarReducer";

export const PreSale = (props: any) => {
  const {
    libId,
    currentTab,
    isLeavingWithoutSave,
    unSaveData,
    getIsLeaving,
    screenUrl,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    preSaleFormState,
    preSaleTotalCount,
    preSaleItems,
    selectedFilters,
    completedPreSaleColState,
    filters,
    prePubFormState
  } = useSelector(PurchaseStateSelector);
  const { retainedPageSize } = useSelector(SideBarStateSelector);
  const { buyerImgUrl } = useSelector(CartStateSelector);
  const { appUser } = useSelector(userStateSelector);
  const [form] = Form.useForm();
  const [data, setData] = useState(preSaleItems);
  const [dataCount, setDataCount] = useState(preSaleTotalCount);
  const [editCellRows, setEditCellRows] = useState<any>([]);
  const [completedPurchaseResize, setCompletedPurchaseResize] = useState<any>([]);
  const [editPoRef, setEditPoRef] = useState<any>([]);
  const [totalQuantiy, setTotalQuantiy] = useState<any>([]);
  const [totalRef, setTotalRef] = useState<any>([]);
  const [showPagination, setShowPagination] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [showRemove, setShowRemove] = useState(false);
  const [editPOReference, setEditPOReference] = useState(false);
  const [addToShelfModel, setAddToShelfModel] = useState(false);
  const [storeUrl, setStoreUrl] = useState<string>("");
  const [clearSelectedRows, setClearSelectedRows] = useState<any>();
  const [saveQuantItems, setSaveQuantItems] = useState<any>([]);
  const [savePoItems, setSavePoItems] = useState<any>([]);
  const [saveData, setSaveData] = useState<boolean>(false);
  const [resetData, setResetData] = useState<boolean>(false);
  const [showQuantitybtn, setShowQuantitybtn] = useState<boolean>(false);
  const [showRefbtn, setShowRefbtn] = useState<boolean>(false);
  const [showDeletebtn, setShowDeletebtn] = useState<boolean>(false);
  const [searchByAuthor, setSearchByAuthor] = useState<any>([]);
  const [multiplePoRef, setMultiplePoRef] = useState<any>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [pageSize, setPageSize] = useState<number>(
    retainedPageSize ?? 100
  );
  const [screenSize, getDimension] = useState<any>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState<number>(Page.DEFAULT_START);
  const [lastCount, setLastCount] = useState(start + pageSize - 1);
  const [preSaleLoading, setPreSaleLoading] = useState<any>(preSaleFormState)
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const[pOReferenceChange,setPOReferenceChange]= useState('');
  var currency = appUser &&  appUser.libraryData? appUser.libraryData[0]?.CurrencySymbol : "";

  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;

  useEffect(() => {
    setData(preSaleItems);
    setDataCount(preSaleTotalCount);
  }, [preSaleTotalCount, preSaleItems]);

  const handleKeyWord = (book: any) => {
    searchPayloadForAuthor(book?.Authors, appUser).then((data: any) => {
      dispatch(getSearchString(data.strinParam));
      dispatch(onBackFillForm(data.searchParam));
      setSearchByAuthor(data.state);
      const unblock = history.block(({ pathname }) => {
        getIsLeaving(true);
        if (!unSaveData) {
          unblock();
          history.push(pathname, data.state);
        }
        return false;
      });
    });
  };

  useEffect(() => {
    if (searchByAuthor?.searchDetails !== undefined) {
      history.push(AppRoutes.SEARCHRESULTS);
    }
  }, [searchByAuthor]);

  const leavingWithoutSave = (row: any) => {
    const unblock = history.block(({ pathname }) => {
      getIsLeaving(true);
      if (!unSaveData) {
        unblock();
        screenUrl(pathname);
        history.push(pathname, {
          book: row,
          searchDetails: row,
        });
      }
      return false;
    });
  };

  const onSelecttitle = (row: any) => {
    leavingWithoutSave(row);
    history.push(AppRoutes.ITEMDETAILS, {
      book: row,
    });
  };

  const getBookLabel = (format: any) => {
    switch (format) {
      case "EPUB": {
        return ebookLabel;
      }
      case "PDF": {
        return ebookLabel;
      }
      case "MP3": {
        return audiobookLabel;
      }
      case "VideoBook": {
        return videobookLabel;
      }
    }
  };

  const columns = [
    {
      title: "Title",
      width: 360,
      className: "preSaleTitle",
      dataIndex: ["ImageId", "Title", "Imprint", "FileFormatName", "ItemState"],
      ellipsis: true,
      sorter: (a: any, b: any) => a?.Title?.localeCompare(b?.Title),
      render: (text: any, row: any) => {
        return (
          <div className="title_colm">
            <span className="CartCoverColm">
              <img
                className="item-cover"
                src={`https://${buyerImgUrl}?type=DOCUMENTIMAGE&documentID=${row["ImageId"]}&size=MEDIUM&token=nobody`}
                alt=""
                onError={(e: any) => {
                  e.target.src = notFound;
                }}
              />
              <img
              src={getBookLabel(row['FileFormatName'])}
              alt=""
              className="cart-book-label"
            />
            </span>
            <span className="cover_conten">
              <Tooltip title={row["Title"]}>
              <p
                onClick={() => onSelecttitle(row)}
                className="book-name-link bold-titles textUnder"
              >
                {row["Title"]}
              </p>
              </Tooltip>
              <p className="place">{row["Imprint"]}</p>
              <p className="fileformat">{row["FileFormatName"]}</p>
              <p className="ItemState">
                {row["ItemState"] === "PRE_SALE" ? (
                  <span className="book-state-carts">PRE SALE</span>
                ) : row["ItemState"] === "NOT_FOR_SALE" ? (
                  <span className="notFor_sale">NOT FOR SALE</span>
                ) : (
                  row["ItemState"]
                )}
              </p>
            </span>
          </div>
        );
      },
    },
    {
      title: "Author",
      dataIndex: "Authors",
      className: "preSaleTitle",
      width: 150,
      ellipsis: true,
      sorter: (a: any, b: any) => a?.Authors?.localeCompare(b?.Authors),
      render: (text: any, row: any) => {
        return (
          <div className="title_colm">
          <Tooltip title={row["Authors"]}>
            <p
              onClick={() => handleKeyWord(row)}
              className="book-name-link textUnder m-0"
            >
              {row["Authors"]}
            </p>
          </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Publication Date",
      dataIndex: "PublicationDate",
      className: "preSaleTitle",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) =>
        a?.PublicationDate?.localeCompare(b?.PublicationDate),
      render: (Date: string) => {
        return <div className="column m-0"><PublicationDate date={Date} /></div>;
      },
    },
    {
      title: "Street Date",
      dataIndex: "StreetDate",
      className: "preSaleTitle",
      ellipsis: true,
      width: 150,
      sorter: (a: any, b: any) => a?.StreetDate?.localeCompare(b?.StreetDate),
      render: (Date: string) => {
        return <div className="column m-0"><PublicationDate date={Date} /></div>;
      },
    },
    {
      title: "eISBN",
      dataIndex: "EISBN",
      className: "preSaleTitle",
      width: 150,
      ellipsis: true,
      sorter: (a: any, b: any) => a?.EISBN?.localeCompare(b?.EISBN),
      render: (EISBN: any) => {
        return (
          <Tooltip title={EISBN}>
            <p className="column m-0">{EISBN}</p>
          </Tooltip>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "RetailPrice",
      className: "preSaleTitle",
      width: 150,
      ellipsis: true,
      sorter: (a: any, b: any) => a.RetailPrice - b.RetailPrice,
      render: (RetailPrice: any) => {
        return (
          <Tooltip title={ `${currency}${Number.parseFloat(RetailPrice).toFixed(2)}` }>
            <p className="column m-0">{currency}{Number.parseFloat(RetailPrice).toFixed(2)}</p>
          </Tooltip>
        );
      },
    },
    {
      title: "Buyer",
      dataIndex: "BuyerId",
      className: "preSaleTitle",
      width: 150,
      ellipsis: true,
      sorter: (a: any, b: any) => a?.BuyerId?.localeCompare(b?.BuyerId),
      render: (BuyerId: string) => {
        return (
          <Tooltip title={BuyerId}>
            <p className="column m-0">{BuyerId}</p>
          </Tooltip>
        );
      },
    },
    {
      title: "PO Reference",
      dataIndex: "ExternalRefId",
      className: "preSaleTitle",
      ellipsis: true,
      editable: true,
      width: 150,
      sorter: (a: any, b: any) => a?.ExternalRefId?.localeCompare(b?.ExternalRefId),
      render: (ExternalRefId: number) => {
        return (
          <Tooltip title={ExternalRefId}>
            <p className="column m-0">{ExternalRefId}</p>
          </Tooltip>
        );
      },
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      className: "preSaleTitle",
      ellipsis: true,
      editable: true,
      width: 150,
      sorter: (a: any, b: any) => a.Quantity - b.Quantity,
    },
  ];

  useEffect(() => {
    const unblock = history.block(({ pathname }) => {
      screenUrl(pathname);
      setStoreUrl(pathname);
      getIsLeaving(true);
      if (!unSaveData) {
        unblock();
        history.push(pathname);
      } else return false;
    });
  }, [unSaveData, history]);

  useEffect(() => {
    const filtersApply = {
      pageSize,
    };
    dispatch(onApplyingFilters(filtersApply));
  }, [pageSize]);

  const changePageSize = (size: number) => {
    setPageSize(size);
    dispatch(retainPageSize(size))
    setCurrentPage(1);
    setStart(1)
    dispatch(
      getPrePub({
        lib_Id: `${libId}`,
        record_Count: size,
        record_Start: "1",
        sort_Direction: "ASC",
        sort_Field: "",
      })
    );
  };

  useEffect(() => {
    if (completedPurchaseResize.length > 0) {
      dispatch(resizePreSaleCols(completedPurchaseResize));
    }
  }, [completedPurchaseResize]);

  useEffect(() => {
    const filtersApply = {
      pageSize,
    };
    dispatch(searchFilters(filtersApply));
  }, [pageSize]);

  useEffect(() => {
    let foundItem = totalQuantiy?.find(
      (item: { key: any }) => item.key === editCellRows.key
    );
    if (foundItem) {
      var res = totalQuantiy.map(
        (obj: { key: number }) =>
          [editCellRows].find((o: { key: number }) => o.key === obj.key) || obj
      );
      setTotalQuantiy(res);
    } else {
      setTotalQuantiy([...totalQuantiy, editCellRows]);
    }
  }, [editCellRows]);
  useEffect(() => {
    if (totalQuantiy?.length > 0) {
      var quantityItems = totalQuantiy
        ?.map((item: any) => {
          if (item?.PrepubPendingPurchaseId !== undefined) {
            return {
              PrepubPendingPurchaseId: item?.PrepubPendingPurchaseId,
              Quantity: item?.Quantity,
            };
          } else {
            return undefined;
          }
        })
        .filter((item: any) => {
          return item !== undefined;
        });
      setSaveQuantItems(quantityItems);
    }
    if (quantityItems?.length > 0) {
      setShowQuantitybtn(true);
    }
  }, [totalQuantiy]);
  useEffect(() => {
    let foundItem = totalRef?.find(
      (item: { key: any }) => item.key === editPoRef.key
    );
    if (foundItem) {
      var res = totalRef.map(
        (obj: { key: number }) =>
          [editCellRows].find((o: { key: number }) => o.key === obj.key) || obj
      );
      setTotalRef(res);
    } else {
      setTotalRef([...totalRef, editPoRef]);
    }
  }, [editPoRef]);

  useEffect(() => {
    if (totalRef?.length > 0) {
      var refItems = totalRef
        ?.map((item: any) => {
          if (item?.PrepubPendingPurchaseId !== undefined) {
            return {
              PrepubPendingPurchaseId: item?.PrepubPendingPurchaseId,
              ExternalRefId: item?.ExternalRefId,
            };
          } else {
            return undefined;
          }
        })
        .filter((item: any) => {
          return item !== undefined;
        });
      setSavePoItems(refItems);
    }
    if (refItems?.length > 0) {
      setShowRefbtn(true);
    }
  }, [totalRef]);

  const handleQuantitySave = () => {
    if (saveQuantItems?.length > 0) {
      dispatch(
        updatePrePub({
          lib_id: libId,
          body: {
            Action: "update quantity",
            Items: saveQuantItems,
            LibraryId: libId,
            record_Count: pageSize,
            record_Start: start,
            sortDirection: "ASC",
            sortField: "",
          },
        })
      );
      onQuantyCancel();
      onRefCancel();
    }
  };

  const onQuantyCancel = () => {
    setShowQuantitybtn(false);
    setSaveQuantItems([]);
    setTotalQuantiy([]);
    setEditCellRows([]);
  };

  const handlePorefSave = () => {
    if (savePoItems?.length > 0) {
      dispatch(
        updatePrePub({
          lib_id: libId,
          body: {
            Action: "update poref",
            Items: savePoItems,
            LibraryId: libId,
            record_Count: pageSize,
            record_Start: start,
            sortDirection: "ASC",
            sortField: "",
          },
        })
      );
      onRefCancel();
      onQuantyCancel();
    }
  };
  const onRefCancel = () => {
    setShowRefbtn(false);
    setSavePoItems([]);
    setTotalRef([]);
    setEditPoRef([]);
  };

  const handleDelete = () => {
    if (selectedRows?.selectedRowsKey?.length > 0) {
      setShowDeletebtn(false);
      onRefCancel()
      onQuantyCancel()
      dispatch(
        deletePrePub({
          Id: libId,
          Ids: selectedRows?.selectedRowsKey?.toString(),
          lib_id: libId,
          record_Count: pageSize,
          record_Start: start,
          sort_Direction: "ASC",
          sort_Field: "",
        })
      );
    }
  };
  var valueArr = selectedRows?.selectedRows?.map(function(item:any){ return item.ExternalRefId });
  const uniqueElements = new Set(valueArr);
  let showmsg = uniqueElements?.size === 1 ? false : true;
  const handleEditPOReference = () => {
    if (selectedRows?.selectedRowsKey?.length > 0) {
      var poIds = multiplePoRef?.map((item:any)=>{
        return{
          PrepubPendingPurchaseId: item,
          ExternalRefId:pOReferenceChange,
        };
      })
      setShowDeletebtn(false);
      onRefCancel()
      onQuantyCancel()
      dispatch(
        updatePrePub({
          lib_id: libId,
          body: {
            Action: "update poref",
            Items: poIds,
            LibraryId: libId,
            record_Count: pageSize,
            record_Start: start,
            sortDirection: "ASC",
            sortField: "",
          },
        })
      );
    }
  };
   const editPoReferenceButton = selectedRows?.selectedRows?.filter(function(item:any)
   {
     return item.ItemState === "NOT_FOR_SALE"
   }); 
   const shelvesData = ["dummyy1","dummmy2"]
   const shelvesList = (
    <Menu className="header-dropdown">
      {shelvesData.map((data: any, index: number) => {
        return (
          <Menu.Item key={index}
          >
            <Tooltip title={data} placement="bottom">
            <p className="dropDown-title dropdown-shelves-title  shelves-list-ellipse"><span>{data}</span> <span className="status-span">live</span></p>
            <span className="dropDown-content">
              2 titles
            </span>
            </Tooltip>
          </Menu.Item>
        );
      })}
    </Menu>
  );


  useEffect(() => {
    if (selectedRows?.selectedRowsKey?.length > 0) {
      setShowDeletebtn(true);
      setAllSelected(true); 
      setMultiplePoRef(selectedRows?.selectedRowsKey)
    } else {
      setShowDeletebtn(false);
      setAllSelected(false);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (
      showQuantitybtn === true ||
      showRefbtn === true ||
      showDeletebtn === true
    ) {
      setSaveData(false);
      isLeavingWithoutSave(true);
    } else {
      setSaveData(true);
      isLeavingWithoutSave(false);
    }
  }, [showQuantitybtn, showRefbtn, showDeletebtn]);

  useEffect(() => {
    if (unSaveData === false) {
      onRefCancel();
      onQuantyCancel();
      setResetData(true);
    } else {
      setResetData(false);
    }
  }, [unSaveData]);

  useEffect(()=>{
    if(resetData){
      setShowPagination(false)
      setTimeout(()=>{
        setShowPagination(true)
      },100)
    }
  },[resetData])

  const onPageChange = (page:any) => {
    const pageStart = (page - 1) * pageSize + 1;
    setStart(pageStart);
    setCurrentPage(page);
    dispatch(
      getPrePub({
        lib_Id: `${libId}`,
        record_Count: pageSize,
        record_Start: pageStart,
        sort_Direction: "ASC",
        sort_Field: "",
      })
    );
  };

  useEffect(() => {
    let end = start + pageSize - 1;
    if (preSaleTotalCount < end) {
      setLastCount(preSaleTotalCount);
    } else {
      setLastCount(end);
    }
  }, [start, pageSize, preSaleTotalCount]);

  useEffect(() => {
    var loading
    if(prePubFormState.loading || preSaleFormState.loading ) {
      loading = {...preSaleLoading,loading : true}
      setPreSaleLoading(loading)
    } else {
      setTimeout(()=> {
        loading = {...preSaleLoading,loading : false}
        setPreSaleLoading(loading)
        setAllSelected(false); 
      }, 2000)
    }
  }, [prePubFormState,preSaleFormState])

  const addtoShelveBtn = () => {
    dispatch(fetchShelves(lib_Id))
    setAddToShelfModel(true)
  }

  const addedSucess = () => {
    dispatch(
      getPrePub({
        lib_Id: `${libId}`,
        record_Count: pageSize,
        record_Start: Page.DEFAULT_START,
        sort_Direction: "ASC",
        sort_Field: "",
      })
    );
  }

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  return (
    <>
      <div id="scrollableDiv">
        <PurchaseHeader
          preSale={true}
          totalCount={dataCount}
          dateOption={[]}
          buyerOption={[]}
          handleDuration={""}
          handelBuyer={""}
          libId={libId}
          currentTab={currentTab}
          serachHeader={[]}
          setSearchSideFilter={[]}
          searchSideFilter={false}
          selectedFilters={selectedFilters}
          handlePageSize={changePageSize}
          allSelected={allSelected}
          pageSizeVal={pageSize}
          data={data}
          lastCount={lastCount}
          startCount={start}
        />
         <div
          style={
            screenSize?.dynamicHeight
              ? { height: screenSize?.dynamicHeight - 210 }
              : {}
          }
          className="purchaseBody scrollStyle scrollOnBooksScreen checkboxClickableAreaWiden purchaseFixedScroll"
        >
          <PurchaseTable
            showPagination={true}
            data={data}
            editAble={true}
            needRowData={false}
            columns={columns}
            classN="purchaseTable-preSales left-sorter"
            formState={preSaleLoading}
            setColumnResize={setCompletedPurchaseResize}
            resizeCol={completedPreSaleColState}
            getQuantity={setEditCellRows}
            getSelectedRows={setSelectedRows}
            getpoRef={setEditPoRef}
            clearSelsectedRows={clearSelectedRows}
            resetData={resetData}
            rowKey={"PrepubPendingPurchaseId"}
          />
          <div className="purchaseFooter mb-50">
            {preSaleTotalCount > lastCount - start + 1 &&
            data &&
            data.length ? (
              <div className={`${showPagination? "" : "dispalayBtnConsole"}  ${preSaleLoading?.loading? "dispalayBtnConsole" : ""}`}>
              {prePubFormState.isSuccess ?
                <PaginationTag
                  total={preSaleTotalCount}
                  current={currentPage}
                  defaultPageSize={pageSize}
                  onChange={onPageChange}
                />
                :
                null
              }
                <Col>
                  <span className="searchTitleStyle">
                    {start}-{lastCount} of {preSaleTotalCount} Titles
                  </span>
                </Col>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={`detail-footer preSale-footer ${saveData ? "dispalayBtnConsole" : ""}`}>
        <Row>
          {showDeletebtn ? null : (
            <>
              {showQuantitybtn ? (
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="update_quantity"
                >
                  <span>
                    <Button
                      onClick={handleQuantitySave}
                      type="primary mark-ready-button"
                    >
                      Update Quantity
                    </Button>
                  </span>
                  <span>
                    <Button
                      onClick={onQuantyCancel}
                      type="smallRed mark-ready-button"
                    >
                      Cancel
                    </Button>
                  </span>
                </Col>
              ) : null}
              {showRefbtn ? (
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="update_poRef"
                >
                  <span>
                    <Button
                      onClick={handlePorefSave}
                      type="primary mark-ready-button"
                    >
                      Change PO Reference
                    </Button>
                  </span>
                  <span>
                    <Button
                      onClick={onRefCancel}
                      type="smallRed mark-ready-button"
                    >
                      Cancel
                    </Button>
                  </span>
                </Col>
              ) : null}
            </>
          )}
          {showDeletebtn ? (
          <>
            <Col
              xs={12}
              sm={8}
              md={6}
              lg={5}
              xl={4}
              xxl={3}
              className="update_quantity"
            >
              <span className="removeBtnLink">
                <Button
                  onClick={() => setShowRemove(true)}
                  type="primary mark-ready-button"
                >
                  Remove selected
                </Button>
              </span>
              </Col>
              {editPoReferenceButton?.length===0?
              <Col 
              xs={12}
              sm={8}
              md={6}
              lg={5}
              xl={4}
              xxl={3}
              className="update_quantity"
              >
              <span className="removeBtnLink POReference">
                <Button
                  onClick={() => setEditPOReference(true)}
                  type="primary mark-ready-button"
                > 
                  Edit PO Reference
                </Button>
              </span>
              </Col>
              : null }
              {editPoReferenceButton?.length===0?
              <Col 
              xs={12}
              sm={8}
              md={6}
              lg={5}
              xl={4}
              xxl={3}
              className="update_quantity"
              >
              <span className="removeBtnLink POReference">
                <Button
                  onClick={addtoShelveBtn}
                  type="primary mark-ready-button"
                > 
                  Add to shelf
                </Button>
              </span>
              </Col>
              : null }
           </>
           ) : null}
        </Row>
      </div>
      <RemoveConfirmModel
        showRemove={showRemove}
        setShowRemove={setShowRemove}
        removeConfirm={handleDelete}
        heading={"Remove Confirmation"}
        message={
          "Are you sure you want to remove the selected book(s) from the pre sale list?"
        }
      />
       <EditPoReferenceModel
        editPOReference={editPOReference}
        setEditPOReference={setEditPOReference}
        editConfirm={handleEditPOReference}
        setPOReferenceChange={setPOReferenceChange}
        showmsg={showmsg}
        heading={"Change PO Reference Number"}
        message={
          "Note: The selected items currently have different PO Reference Numbers." 
        }
        message2={
          "The selected  items will be assigned the following PO Reference Number. When the items are purchased, the Purchase Order(s) will have this reference number"
        }
        label={"PO Reference Number"}
        inputbox={
          <Input 
           placeholder="PO Reference Number" 
           className='PO-reference-input'
           value={pOReferenceChange}
           onChange={(e:any)=>setPOReferenceChange(e.target.value)}
         />
        }
      />

      <AddToShelfModel
        addToShelfModel={addToShelfModel}
        setAddToShelfModel={setAddToShelfModel}
        heading={"Add to shelf"}
        messageforModel={
          "The selected items will be assigned to the selected shelf."  
        }    
        label={"Destination Shelf"}
        selectedRows = {selectedRows?.selectedRows}
        addedSucess={addedSucess}
        setShowDeletebtn={setShowDeletebtn}
        />
    </>
  );
};
