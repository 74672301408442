// Base Imports
import React from "react";

// Package Imports
import { Spin } from "antd";
import {LoadingOutlined} from "@ant-design/icons"

// Other Imports

const SpinnerLoader = (props: any) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  if (props.loading) {
    return <Spin className={props.className} tip="Loading..." indicator={antIcon}>{props.children}</Spin>;
  }

  return <>{props.children}</>;
};

export default SpinnerLoader;
