import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import {fetchPatronHold, recentPatronTab, recentSettingTab, resizePendingCols, searchPatron, updateHoldPosition, updatePatron } from "../../actions/patron/patronAction";
import { PatronState } from "../../states/patron/patronState";
import { RootState } from "../../store/rootReducer";

const initialState: PatronState = {
  formState: resetState(),
  defaultSettingTab: "2",
  defaultPatronTab:"1",
  patronData:[],
  eBookData:[],
  aBookData:[],
  patronHoldFormState: resetState(),
  patronReplaceFormState: resetState(),
  patronHoldData:[],
  patronUpdated:false,
  postionUpdated:false,
  errMsg:[],
  holdMgtResizeState:[],
  errEbokMsg:[],
  errAbokMsg:[],
  ebokUpdated:false,
  abokUpdated:false,
  ppuEbookState: resetState(),
  ppuAbookState: resetState(),
  ppuEbookUpdateState: resetState(),
  ppuAbookUpdateState: resetState(),
  selectedTab:1,
};

export const PatronSlice = createSlice({
  name: "patron",
  initialState: initialState,
  reducers: {
    clearSettingsState: (state) => {
      state.formState = resetState()
      state.patronHoldFormState = resetState()
      state.patronReplaceFormState = resetState()
      state.defaultSettingTab = "2"
      state.defaultPatronTab = "1"
      state.patronData = []
      state.patronHoldData = []
      state.eBookData = []
      state.aBookData = []
      state.errMsg = []
      state.errEbokMsg = []
      state.errAbokMsg = []
      state.errAbokMsg = []
      state.ebokUpdated = false
      state.abokUpdated = false
      state.ppuEbookState = resetState()
      state.ppuAbookState = resetState()
      state.ppuEbookUpdateState = resetState()
      state.ppuAbookUpdateState = resetState()
      return state;
    },
    clearPatronUpdate: (state) => {
      state.patronUpdated = false
      return state;
    },
    clearErrMsg: (state) => {
      state.errMsg = []
      return state;
    },
    setPatronTab: (state,{ payload}) => {
      state.selectedTab = payload;
      return state;
    },
    clearEMsg: (state) => {
      state.errEbokMsg = []
      return state;
    },
    clearAMsg: (state) => {
      state.errAbokMsg = []
      return state;
    },
    clearPatronData: (state) => {
      state.patronData = []
      return state;
    }
  },
  extraReducers: {
    [recentSettingTab.fulfilled.toString()]: (state, { payload }) => {
      state.defaultSettingTab = payload;
      state.formState = fulfilledState();
      return state;
    },
    [recentSettingTab.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [recentSettingTab.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },

    [recentPatronTab.fulfilled.toString()]: (state, { payload }) => {
      state.defaultPatronTab = payload;
      state.formState = fulfilledState();
      return state;
    },
    [recentPatronTab.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [recentPatronTab.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },

    [searchPatron.fulfilled.toString()]: (state, { payload }) => {

      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
        state.patronData = payload;
      }
      return state;
    },
    [searchPatron.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [searchPatron.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },

    [updatePatron.fulfilled.toString()]: (state, { payload }) => {
      if (payload?.ErrorMessage !== undefined && payload?.ErrorMessage !== "") {
        state.patronReplaceFormState = errorState(payload.ErrorMessage);
        state.errMsg = payload?.ErrorDetails;
      } else {
        state.patronReplaceFormState = fulfilledState();
        state.patronUpdated = true;
        state.errMsg = []
      }
      return state;
    },
    [updatePatron.pending.toString()]: (state) => {
      state.patronReplaceFormState = pendingState();
      return state;
    },
    [updatePatron.rejected.toString()]: (state, { payload }) => {
      state.patronReplaceFormState = rejectedState();
      return state;
    },

    [fetchPatronHold.fulfilled.toString()]: (state, { payload }) => {

      if (payload.errorMsg) {
        state.patronHoldFormState = errorState(payload.errorMsg);
      } else {
        state.patronHoldFormState = fulfilledState();
        state.patronHoldData = payload;
      }
      return state;
    },
    [fetchPatronHold.pending.toString()]: (state) => {
      state.patronHoldFormState = pendingState();
      return state;
    },
    [fetchPatronHold.rejected.toString()]: (state, { payload }) => {
      state.patronHoldFormState = rejectedState();
      return state;
    },

    [updateHoldPosition.fulfilled.toString()]: (state, { payload }) => {
      if (payload?.ErrorMessage !== undefined && payload?.ErrorMessage !== "") {
        state.formState = errorState(payload.ErrorMessage);
        state.errMsg = payload?.ErrorDetails;
      } else {
        state.formState = fulfilledState();
        state.postionUpdated = true;
        state.errMsg = []
      }
      return state;
    },
    [updateHoldPosition.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [updateHoldPosition.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },

    [resizePendingCols.fulfilled.toString()]: (state, { payload }) => {
      state.holdMgtResizeState = payload
    },
    [resizePendingCols.pending.toString()]: (state) => {
      return state;
    },
    [resizePendingCols.rejected.toString()]: (state, { payload }) => {
      return state;
    },
  },
});

export const { clearSettingsState,clearPatronUpdate,clearErrMsg,clearEMsg,clearAMsg,setPatronTab, clearPatronData} = PatronSlice.actions;
export const PatronStateSelector = (state: RootState) => state.Patron;
export default PatronSlice.reducer;
