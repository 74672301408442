
export const getConfigJson =()=> {
    const envFetch = fetch("../config.json")
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    const printAddress = async () => {
      const a = await envFetch;
      localStorage.setItem("environment", a?.serviceUrl);
      localStorage.setItem("appVersion", a?.version);
      localStorage.setItem("reportsUrl", a?.reportsUrl);
      return a?.runningENV
    };
    return printAddress()
      };