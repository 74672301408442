import React from 'react';
import { Row, Modal, } from "antd";
import Button from "../../../../stateless/common/button";
import { useSelector,useDispatch } from "react-redux";
import { CartStateSelector } from "../../../../../../redux/reducers/carts/cartsReducer";
import SpinnerLoader from "../../../../stateless/common/spinnerLoader";
import {
  ShelfstateSelector,clearSelectedShelve
} from "../../../../../../redux/reducers/shelves/shelfReducer";

const RemoveConfirmModel = ( props: any) => {
    const {showRemove, setShowRemove, removeConfirm,message,message2 ,setButtonClicked,setSelectedRow,deleteRowItem} = props;
    const {formState} = useSelector(CartStateSelector);
    const { selectedShelve } = useSelector(ShelfstateSelector);
    const dispatch = useDispatch();

    const handleYesSubmit =() =>{
      if( setButtonClicked !== undefined) {
        deleteRowItem?.ShelfId === selectedShelve?.ShelfId && dispatch(clearSelectedShelve())
        removeConfirm()
        setShowRemove(false)
        setButtonClicked(false)
        setSelectedRow([])
      } else {
        deleteRowItem?.ShelfId === selectedShelve?.ShelfId && dispatch(clearSelectedShelve())
        removeConfirm()
        setShowRemove(false)
      }
    }

    const onCancelModel=()=>{
      if( setButtonClicked !== undefined) {
      setShowRemove(false)
      setButtonClicked(false)
      setSelectedRow([])
      } else {
        setShowRemove(false)
      }
    }

    const handleNoSubmit = () => {
      if( setButtonClicked !== undefined) {
      setShowRemove(false);
      setButtonClicked(false)
      setSelectedRow([])
      } else {
        setShowRemove(false)
      }
    }

  return (
    <Modal
    wrapClassName="purchase-modal"
    visible={showRemove}
    onCancel={onCancelModel}
    footer={
          <Row justify="space-around">
          <Button
            type="primary confirm-button"
            onClick={handleYesSubmit}
          >
            Yes
          </Button>
          <Button
            type="smallRed confirm-button"
            onClick={handleNoSubmit}
          >
            No
          </Button>
          </Row>
      }
    >
        <SpinnerLoader loading={formState.loading}>
        <p className="purchase-title">{props.heading}</p>
        <div className="remove-content">
        <p className='purchaseNote'>
          {message} <br /> {message2}
        </p>
        </div>
        </SpinnerLoader>

    </Modal>
  )
};

export default RemoveConfirmModel;
