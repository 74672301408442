/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Switch, InputNumber, Radio, Input } from "antd";
import DropDown from "../../../stateless/common/dropDown";
import Button from "../../../stateless/common/button";
import { downIcon, dropdownIcon, searchIcon } from "../../../../images";
import "./slider.less";
import { useDispatch,useSelector } from "react-redux";
import { saveAutoCart } from "../../../../../redux/actions/carts/cartAction";
import { filterOptions } from "../../../../constants/constants";
import { AutoCartType } from "../../../../constants/enums";
import DeactiveCartModel from "./deactiveCartModel/deactiveCartModel";
import FormItem from "antd/lib/form/FormItem";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";

export const AutoCartCommon = (props: any) => {
  const {
    title,
    cart,
    rulesData,
    managerOptions,
    notifyOptions,
    formState,
    getBookData,
    getSpendLimit,
    errorForm,
    formError
  } = props;
  const [form] = Form.useForm();
  const { appUser } = useSelector(userStateSelector);
  const [newData, setNewData] = useState<any>(
    JSON?.parse(JSON?.stringify({ current: rulesData }))
  );
  const [oldData, setOldData] = useState<any>(
    JSON?.parse(JSON?.stringify({ current: rulesData }))
  );
  var currency = appUser && appUser.libraryData ? appUser.libraryData[0]?.CurrencySymbol : ""
  const [manager, setManager] = useState<any>(newData?.current?.Active !== true?appUser?.email:newData?.current?.CartManagerUserId);

  const [comonMaxCopy, setComonMaxCopy] = useState<number>(newData?.current?.Active !== true?20:newData?.current?.MaxStockQuantity);

  const [notifyUsers, setNotifyUsers] = useState<any>([]);
  const [getNotifyUsers, setGetNotifyUsers] = useState<any>([]);
  const [notifyUsersId, setNotifyUsersId] = useState<any>([]);
  const [spendLimit, setSpendLimit] = useState<any>('');

  const [poRefNo, setPoRefNo] = useState<any>('');
  const [radioValue, setRadioValue] = React.useState(1);
  const [errorSpend, setErrorSpend] = useState<boolean>(false);
  const [showMaxCopMsg, setShowMaxCopMsg] = useState<boolean>(false);
  const [level, setLevel] = useState<string>(
    newData?.current?.AutocartProcessingStrategyId
  );
  const [levelActivate, setlLevelActivate] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setNewData(JSON?.parse(JSON?.stringify({ current: rulesData })));
    setOldData(JSON?.parse(JSON?.stringify({ current: rulesData })));
  }, [rulesData, cart]);

  useEffect(() => {
    getBookData(newData?.current);
    setRadioButton(newData?.current);
    onSave(newData?.current);
    setSpendLimit(newData?.current?.PurchaseCapForPeriod)
    getSpendLimit(newData?.current?.PurchaseCapForPeriod)
    setPoRefNo(newData?.current?.ExternalRefId)
    setNotifyUsers(newData?.current?.NotificationUserIds?.split(","));
    if(newData?.current?.Active !== true){
      setLevel("LibraryLevel");
      setComonMaxCopy(20)
      setManager(appUser?.email);
    }else{
      setLevel(newData?.current?.AutocartProcessingStrategyId);
      setComonMaxCopy(newData?.current?.MaxStockQuantity)
      setManager(newData?.current?.CartManagerUserId);
    }
  }, [newData?.current]);

  const setRadioButton = (data: any) => {
    if (data?.AutoBuy !== false) {
      setRadioValue(1);
    } else {
      setRadioValue(2);
    }
  };

  useEffect(()=>{
    setErrorSpend(errorForm)
  },[errorForm])

  useEffect(() => {
    var A: any[] = [];
    notifyUsers?.map((item: any) => {
      var data = { UserId: item, ScreenName: "" };
      return A.push(data);
    });
    setNotifyUsersId(A);
  }, [notifyUsers]);


  const onSave = (updatedData: any) => {
    if (JSON.stringify(spendLimit) !== "") {
      if (JSON.stringify(newData?.current) !== JSON.stringify(oldData?.current)) {
        getBookData(updatedData);
      } else {
        return undefined;
      }
    }else{
      setErrorSpend(true)
    }
  };

  const handleLavels = (val: any) => {
    setLevel(val);
    setlLevelActivate(true);
    if (val) {
      oldData.current = {
        ...oldData.current,
        AutocartProcessingStrategyId: val,
        NotifyUserIds: notifyUsersId,
      };
    }
  };

  useEffect(() => {
    if (levelActivate) {
      dispatch(saveAutoCart({ ...oldData?.current }));
      setlLevelActivate(false);
    } else {
      return undefined;
    }
  }, [level, levelActivate, oldData?.current]);

  useEffect(() => {
    getBookData([]);
  }, []);

  const handleChange = (e: any) => {
    var value = e.target.value;
    if (value === 1) {
      setRadioValue(1);
      newData.current = {
        ...newData.current,
        AutoBuy: true,
        DoNotBuy: false,
      };
      return newData.current;
    } else if (value === 2) {
      setRadioValue(2);
      newData.current = {
        ...newData.current,
        AutoBuy: false,
        DoNotBuy: true,
      };
      return newData.current;
    }
  };

  const handleManagerSelect = (value: any) => {
    setManager(value);
    newData.current = {
      ...newData.current,
      Active:true,
      CartManagerUserId: value,
    };
    return newData.current;
  };
  const handleNotifySelect = (value: any) => {
    newData.current = {
      ...newData.current,
      NotificationUserIds: value.join(","),
      NotifyUserIds: value.join(",").split(",")?.filter((item:any)=>{return item !== ""}),
    };
    setNotifyUsers(value);
  };

  const handlePoRefernce = (e: any) => {
    setPoRefNo(e.target.value)
    newData.current = {
      ...newData.current,
      ExternalRefId: e.target.value,
    };
  };

  const handleSpendlimit = (e: any) => {
    if (spendLimit === "" && e.target.value === "") {
      setSpendLimit("")
      getSpendLimit("")
      setErrorSpend(true)
      newData.current = {
        ...newData.current,
        PurchaseCapForPeriod: e.target.value,
      };
    } else if (spendLimit < 1 && e.target.value < 1) {
      setSpendLimit("")
      getSpendLimit("")
      setErrorSpend(true)
      newData.current = {
        ...newData.current,
        PurchaseCapForPeriod: e.target.value,
      };
    }
    else if (e.target.value < 1) {
      setSpendLimit("")
      getSpendLimit("")
      setErrorSpend(true)
      newData.current = {
        ...newData.current,
        PurchaseCapForPeriod: e.target.value,
      };
    }
    else if (JSON.stringify(e.target.value) === "") {
      setSpendLimit("")
      getSpendLimit("")
      setErrorSpend(true)
      newData.current = {
        ...newData.current,
        PurchaseCapForPeriod: e.target.value,
      };
    }else{
      setSpendLimit(e.target.value)
      getSpendLimit(e.target.value)
      setErrorSpend(false)
      newData.current = {
        ...newData.current,
        PurchaseCapForPeriod: e.target.value,
      };
    }
  };

  const errMsg =(value:any)=>{
    var reg = /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/gm;
    if(reg.test(value)){
      formError(false)
      return setShowMaxCopMsg(false)
    }else{
      formError(true)
      return setShowMaxCopMsg(true)
    }
  }

  const handleInputValue =(e:any)=>{
    errMsg(e.target.value)
    setComonMaxCopy(e.target.value)
    newData.current = {
      ...newData.current,
      MaxStockQuantity: e.target.value,
    };
  }

  useEffect(()=> {
    if(radioValue === 2) {
      newData.current = {
        ...newData.current,
        ExternalRefId: oldData.current.ExternalRefId
      };

      newData.current = {
        ...newData.current,
        PurchaseCapForPeriod: oldData.current.PurchaseCapForPeriod
      };
      setErrorSpend(false)

    }
  },[radioValue])

  useEffect(()=>{
    var data = notifyUsers?.filter((item:any)=>{
      return item !== ""
    })
    setGetNotifyUsers(data)
  },[notifyUsers])

  return (
    <>
      <div className="commonSettings">
        <Form layout="horizontal" form={form}>
          <Row className="commonRowStyle">
            <Col span={12}>
              <Row>
                <Col className="label" span={2}></Col>
                <Col span={18}>
                  <div className="card-dropdown-withLabel">
                    <label>Cart structure</label>
                    <DropDown
                      placeholder="AutoCart Manager"
                      className="card-dropdown with-search"
                      bordered={false}
                      optionValue={filterOptions.cartStructure}
                      value={level}
                      icon={dropdownIcon}
                      onChange={handleLavels}
                    />
                  </div>
                </Col>
              </Row>
              {level !== "RuleLevel" ? (
                <>
                  <Row>
                    <Col span={2}></Col>
                    <Col span={18}>
                      <div className="card-dropdown-withLabel">
                        <FormItem
                          rules={[
                            {
                              required: true,
                              message: "it is required field !",
                            },

                          ]}
                          initialValue={manager}
                        >

                          <label>Autocart Manager</label>
                          <DropDown
                            placeholder="AutoCart Manager"
                            className="card-dropdown with-search autoManagerList"
                            bordered={false}
                            optionValue={managerOptions}
                            value={manager}
                            onChange={handleManagerSelect}
                            icon={dropdownIcon}
                          />
                        </FormItem>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={2}></Col>
                    <Col span={18}>
                      <div className="card-dropdown-withLabel">
                        <label>Email Notification</label>
                        <DropDown
                          showSearch
                          placeholder="Notification"
                          className="card-dropdown with-search"
                          bordered={false}
                          value={getNotifyUsers?.length > 0?getNotifyUsers:undefined}
                          optionValue={notifyOptions}
                          onChange={handleNotifySelect}
                          icon={searchIcon}
                          mode="multiple"
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Col>
            {/* ROW 2 */}
            <Col span={12}>
              <Row>
                <Col className="label" span={11}>
                  Maximum copies owned
                </Col>
                <Col span={13}>
                  <div className="spenDlimitInput">
                    {/* <FormItem
                          name="HoldRatio"
                          rules={[
                            {
                              required: true,
                              message: "Maximum copies owned is required, and must be at least 1",
                            },
                            {
                              pattern: /^([1-9]+\.?[0-9]*|\.[0-9]+)$/,
                              message: "Maximum copies owned is required, and must be at least 1",
                            },
                          ]}
                          initialValue={comonMaxCopy}
                    >

                    <Input
                      type="number"
                       min={0}
                       className="autoCart-maxcopies-input"
                       value={comonMaxCopy}
                       onChange={handleInputValue}
                    />
                    </FormItem> */}
                    <Input
                      type="number"
                      min={0}
                      className="autoCart-maxcopies-input"
                      value={comonMaxCopy}
                      onChange={handleInputValue}
                    />
                    {
                      showMaxCopMsg !== false?
                        <p style={{color:"red"}}>Maximum copies owned is required, and must be at least 1</p>
                        :
                        null
                    }
                  </div>
                </Col>
              </Row>
              {level !== "RuleLevel" ? (
                <>
                  {radioValue !== 2 ? (
                    <>
                      <Row>
                        <Col className="label" span={11}>
                          Monthly Auto cart spending limits
                        </Col>
                        <Col span={13}>
                          <div className="card-dropdown-withLabel">
                            <Input
                              name="CartSpending"
                              placeholder="1000"
                              className="card-input"
                              bordered={false}
                              value={spendLimit}
                              onChange={handleSpendlimit}
                            />
                            {errorSpend === false ? <></> :
                              <p style={{ color: "red" ,fontSize:"12px"}}>Monthly Auto Cart Spending Limit is required, and must be at least 1</p>}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="label" span={11}>
                          Auto Cart PO Reference #
                        </Col>
                        <Col span={13}>
                          <div className="card-dropdown-withLabel">
                            <Input
                              name="autoCartPurchase"
                              placeholder="Auto cart purchase"
                              className="card-input"
                              bordered={false}
                              value={poRefNo}
                              onChange={handlePoRefernce}
                            />
                          </div>
                        </Col>
                        <Col className="label singleAmount" span={11}>
                        Amount spent this month
                        </Col>
                        <Col span={13} className="singleAmount">
                         {currency} {newData?.current?.AutoBuyPeriodRunningTotal}
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <Row>
                    <Col className="label" span={11}>
                      Purchase Structure
                    </Col>
                    <Col span={13}>
                      <Radio.Group
                        name="AutoBuy"
                        value={radioValue}
                        onChange={handleChange}
                      >
                        <Row>
                          <Col span={12}>
                            <Radio value={1}>
                              <span className="radio-span">Auto Purchase</span>
                            </Radio>
                          </Col>
                          <Col span={12}>
                            <Radio value={2}>
                              <span className="radio-span">
                                Manual Purchase
                              </span>
                            </Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
