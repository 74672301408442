/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-redeclare */

import React, { useState, useEffect, useRef } from "react";
import { AppRoutes } from "../../../../router/appRoutes";
import { SearchRenderer } from "./searchRenderer";
import "./search.less";
import { useDispatch, useSelector } from "react-redux";
import { onBackFillForm, getSearchString,fetchQuickSearchNew, fetchIsbnSearcNew, fetchQuickSearchIsbnNew } from "../../../../../redux/actions/search/searchActions";
import { clearQuickSearchResults, SearchstateSelector, clearSearchScroll, clearQuicSearchNew, setQuickSearchValue, clearQuickSearchValue } from "../../../../../redux/reducers/search/searchReducer";
import { useHistory } from "react-router-dom";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { Page } from "../../../../constants/constants";
import Axios from "axios";
import { setGlobalCopy, setSelectAllChkBox, setSelectedTitles } from "../../../../../redux/actions/books/bookActions";
import { BookStateSelector, clearBooklistCurrentPage, clearSelectedChkBox } from "../../../../../redux/reducers/books/bookListReducer";
import { checkPayloadAndReform } from "../../../../../utility/searchUtil";

export const SearchBar = React.memo((props: any) => {
  const {libId, setSpan,searchSpan,setClearText,setHasValue} = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const searchRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const {quickSearchValue} = useSelector(SearchstateSelector)
  const [searchValue, setSearchValue] = useState(quickSearchValue);
  const [resetBtn, setResetBtn] = useState<boolean>(false);
  const [qSBySapceBtn, setQSBySapceBtn] = useState<any>([])
  const [enterHit, setEnter] = useState(false)
  const dispatch = useDispatch();
  const history = useHistory()
  const { searchResults } = useSelector(SearchstateSelector)
  const {appUser} = useSelector(userStateSelector)
  var userCatalog = appUser && appUser.libraryData? appUser.libraryData[0].NatureId: 0
  const [isbnStatus,setIsbnStatus] = useState<boolean>(false);
  const {booklistCurrentPage} = useSelector(BookStateSelector);

  useEffect(() => {
    let handler = (event: any) => {
      if (showDropdown) {
        if (!searchRef.current?.contains(event.target)) {
          setShowDropdown(false);
          setSpan(6)
        }
      } else if (inputRef.current?.contains(event.target)) {
        setShowDropdown(true);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

useEffect(()=>{
  setSearchValue(quickSearchValue)
},[quickSearchValue])

  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
    if(e.target.value){
     setShowDropdown(true)
     setHasValue(true)
    }else{
      setHasValue(false)
    }
  };
  const selectItem = (item: any) => {
    setSearchValue(item);
  };
  const handleSearch = (e: any) => {
    setQSBySapceBtn(e)
    if(searchValue !== '' && searchValue.length >= 2){
      let state :any ;
      let searchBody = {
        AddedToCatalogDateFrom: "",
        AddedToCatalogDateTo: "",
        AddedToCatalogDaysOld: 0,
        AuthorSearch: [],
        ContentProviderSearch: [],
        CategorySearch: [],
        combinedSearch: "0",
        EISBN: "",
        Series: "",
        Narrator: "",
        FromPublisherDate: "",
        FromPurchaseDate: "",
        HoldRatio: "",
        ItemFormatId: "",
        ItemFormatIds: {},
        Language: "",
        LibraryId: props.libId,
        MinimumPriceAmount: "",
        MaximumPriceAmount: "",
        NotInCollection: false,
        ExcludeSelfPublished: false,
        PublisherSearch: [],
        QuickSearch:[searchValue],
        SubCategorySearch: [],
        TitleDaysOld: 0,
        PreSaleDays: 0,
        TargetAudienceIds: [],
        TitleSearch: [],
        ToPublisherDate: "",
        ToPurchaseDate: "",
        POReference: "",
        PPUCriteria: "1",
        AllowedSharingStatusCodes: ["P"],
        ItemFormatIdSet: ["1","2","3"],
        MediaTypeIdSet: [],
        recordStart:Page.DEFAULT_START,
        recordCount:Page.DEFAULT_COUNT,
        sortField: "rank",
        sortDirection: "ASC",
        CatalogId: userCatalog
    }
      state = {
        searchDetails: searchBody,
        gaString: {
          byFrom: "byQuickSearch",
          gaData: searchValue,
          currentPage: booklistCurrentPage,
        },
      };
      if(e.code=== "Enter" && !isbnStatus){
        dispatch(setSelectAllChkBox(true))
        dispatch(clearSelectedChkBox());
        dispatch(setGlobalCopy(1))
        dispatch(setSelectedTitles([]))
        dispatch(clearSearchScroll());

        setEnter(true)
        setSpan(6)
        setShowDropdown(false);
        dispatch(clearSearchScroll());
        dispatch(onBackFillForm({...searchBody,EISBN:"",QuickSearch:[]}))
        dispatch(clearBooklistCurrentPage());
        history.push(AppRoutes.SEARCHRESULTS, state)
      } else if (e.code=== "Space" && !isbnStatus){
        let onlyCharacters = searchValue.replaceAll(" ", "");
        if(onlyCharacters.length){
        setEnter(false)
      }
    } else if (isbnStatus && e.code === "Enter"){
      setShowDropdown(false);
      let onlyCharacters = searchValue.replaceAll(" ", "");
      state = {
        searchDetails: {...searchBody,EISBN:onlyCharacters},
        isbnQuickSearch: { list: [{EISBN: onlyCharacters }], libId,
          sortField: null,
          sortDirection: "asc",
          catalogID: userCatalog,
          recordCount: Page.DEFAULT_COUNT,
          recordStart: Page.DEFAULT_START,
        },
        gaString:{
          byFrom:'byQuickSearch',
          type:'isbnQuickSearch',
          gaData:searchValue,
          currentPage : booklistCurrentPage
        }
      }
      dispatch(clearSearchScroll());
      dispatch(onBackFillForm({...searchBody,EISBN:"",QuickSearch:[]}))
      dispatch(clearBooklistCurrentPage());
      history.push(AppRoutes.SEARCHRESULTS, state)
    }

    }else{
      return null
    }
    dispatch(setQuickSearchValue(searchValue));
  }

  const validateEnterString =(str:string)=>{
      var isNumber = /^\d+$/.test(str)
      var isNoLength = str?.length >= 13
      if(isNumber && isNoLength){
        return 0
      }else{
        return 1
      }
  }

  useEffect(()=>{
    let source = Axios.CancelToken.source();
      let searchBody = {
        AddedToCatalogDateFrom: null,
        AddedToCatalogDateTo: null,
        AddedToCatalogDaysOld: "",
        AuthorSearch: [],
        ContentProviderSearch: [],
        CategorySearch: [],
        combinedSearch : "0",
        EISBN: "",
        Series: "",
        Narrator: "",
        FromPublisherDate: null,
        FromPurchaseDate: null,
        HoldRatio: "",
        InCollection: null,
        ItemFormatId: "",
        ItemFormatIds: {},
        Language: "",
        LibraryId: props.libId,
        MinimumPriceAmount: "",
        MaximumPriceAmount: "",
        NotInCollection: true,
        ExcludeSelfPublished: false,
        PublisherSearch: [],
        QuickSearch:[searchValue],
        SubCategorySearch: [],
        AllowedSharingStatusCodes: ["P"],
        TitleDaysOld: "",
        PreSaleDays: 0,
        TargetAudienceIds: [],
        TitleSearch: [],
        ToPublisherDate: null,
        ToPurchaseDate: null,
        POReference: "",
        PPUCriteria: "1",
        ItemFormatIdSet: ["1","2","3"],
        MediaTypeIdSet: [],
        recordStart:Page.DEFAULT_START,
        recordCount:4,
        sortField: "rank",
        sortDirection: "ASC",
        CatalogId: userCatalog
    }
      // dispatch(fetchQuickSearch(searchBody))
      if(searchValue !== "" && searchValue.length >= 3){
        dispatch(clearQuicSearchNew())
        setTimeout(()=>{
          if(validateEnterString(searchValue) === 0){
            dispatch(fetchQuickSearchIsbnNew({payload:{ list: [{EISBN:searchValue.replaceAll(" ", "") }], libId,
              sortField: null,
              sortDirection: "asc",
              catalogID: userCatalog,
              recordCount: Page.DEFAULT_COUNT,
              recordStart: Page.DEFAULT_START,
            },signal:source}));
          }
          if(validateEnterString(searchValue) === 1){
            dispatch(fetchQuickSearchNew({payload:checkPayloadAndReform(searchBody),signal:source}))
          }
        },100)
      }

    return () => {
      source.cancel();
    };

     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchValue])

  const checkforisbn = (str:string) => {
    var isNumber = /^\d+$/.test(str)
    var isNoLength = str?.length >= 13
    if(isNumber && isNoLength){
      return true
    }else{
      return false
    }
  }

 // Reset Input Field
 const resetInputField = () => {
  setSearchValue("");
  dispatch(clearQuickSearchResults())
  setClearText(true)
 };

 useEffect(()=>{
if(searchValue !== ""){
  setResetBtn(true)
  setIsbnStatus(checkforisbn(searchValue))
}else{
  setResetBtn(false)
  dispatch(clearQuickSearchResults())
}
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[searchValue])

 useEffect(()=>{
  if(searchSpan === 12){
    setShowDropdown(true)
  }else{
    setShowDropdown(false)
  }
 },[searchSpan])
  
  return (
    <div className="search-renderer">
        <input
        ref={inputRef}
        className="search searchPlaceholder"
        placeholder="Quick Search by Title, Author or ISBN"
        value={searchValue}
        onChange={handleChange}
        onKeyUp={handleSearch}
      />{
        resetBtn?
        <button className="resetBtn" onClick={resetInputField}><span>X</span></button>
        :
      null
      }
      {showDropdown ? (
        <div
          ref={searchRef}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <SearchRenderer
            options={searchResults}
            setDisplay={setShowDropdown}
            setValue={setSearchValue}
            selectItem={selectItem}
            enterHit={enterHit}
          />
          <div className="advance-search">
            <span className="link" 
              onClick={()=>{
                dispatch(clearQuickSearchValue());
                history.push(AppRoutes.SEARCH);
                setShowDropdown(false);
                setSpan(6);
            }}>
              Advanced Search</span>
          </div>
        </div>
      ) : null}
    </div>
  );
});
