import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import { fetchShelfDetails, fetchShelveItems, fetchShelves, resizeCompletedCols, preDeleteShelv, fetchShelvByShelvId, updateCartShelv, createNewShelve, removeShelvItems, refreshShelv, addItemtoShelve, onSelectShelve, createNewAutoShelve, editAutoShelv, fetchShelvesPatron, updateCartShelvName,savedShelfColumns, deleteShelve, retainShelvState, retainShelvPostion, addItemtoIndividualShelve, updateShelvAfterSync, newlyCreatedShelv } from "../../actions/shelves/shelfAction";
import { ShelfState } from "../../states/shelves/shelfState";
import { RootState } from "../../store/rootReducer";

const initialState: ShelfState = {
  shelves: [],
  shelvSyncData:[],
  shelveItems: [],
  shelvDetails: [],
  updateShelvError:[],
  removeError: [],
  selectedTab:"1",
  shelvDetailById: [],
  shelvDetailsColState:[],
  refreshShelvFormState: resetState(),
  shelvDetailFormState: resetState(),
  shelvIdFormState: resetState(),
  updateShlvFormState: resetState(),
  removeformState: resetState(),
  newAutoShelveFormState:resetState(),
  formState: resetState(),
  itemsFormState: resetState(),
  preShelf: [],
  preDeleteFormState: resetState(),
  shelvesFilter:1,
  newShelve: [],
  newShelveFormState: resetState(),
  selectedShelve:[],
  addItemShelf: resetState(),
  addItemShelfDone:false,
  addSingleItemToShelf:false,
  previousLocation:[],
  savedShelfCols:[],
  savedColFormState: resetState(),
  deleteFormState: resetState(),
  addItemShelfAlone: resetState(),
  shelvFormState: resetState(),
  shelvListState:[1,2,3,4],
  shelvListPostion:0,
  newlyCrtedShelv:[]
};

export const ShelfSlice = createSlice({
  name: "shelves",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      state.updateShlvFormState = resetState();
      state.itemsFormState = resetState();
      state.removeformState = resetState();
      state.shelvDetailFormState = resetState();
      state.addItemShelfAlone = resetState();
      state.shelveItems =[];
      state.shelvSyncData =[];
      state.removeError = [];
      state.shelvDetailById =[];
      state.shelvDetailsColState =[];
      state.addSingleItemToShelf = false;
      state.addItemShelfDone = false;
      state.shelves =[];
      state.preShelf = [];
      state.preDeleteFormState = resetState();
      state.newShelve = [];
      // state.newlyCrtedShelv = [];
      state.newShelveFormState = resetState();
      state.newAutoShelveFormState = resetState();
      state.addItemShelf = resetState();
      state.shelvFormState = resetState();
      return state;
    },
    clearShelvItems: (state) => {
      state.shelveItems =[];
      state.itemsFormState = resetState();
      return state;
    },
    clearRefeshShelv: (state) => {
      state.shelvSyncData =[];
      return state;
    },
    clearShelvDetails: (state) => {
      state.shelvDetails =[];
      state.shelvDetailById =[];
      state.removeformState = resetState();
      return state;
    },
    setTab: (state,{ payload}) => {
      state.selectedTab = payload;
      return state;
    },
    setShelvesFilter: (state,{ payload}) => {
      state.shelvesFilter = payload;
      return state;
    },
    clearNewShelv: (state) => {
      state.newShelve = [];
      state.newShelveFormState = resetState();
      state.newAutoShelveFormState = resetState();
    },
    clearAaddSingleShelv: (state) => {
      state.addSingleItemToShelf = false;
      state.addItemShelfAlone = resetState();
    },
    clearRemoveError: (state) => {
      state.removeError = [];
      state.removeformState = resetState();
    },
    clearShelvListState: (state) => {
      state.shelvListState = [1,2,3,4];
      state.shelvListPostion = 0;
      return state;
    },
    clearUpdateError: (state) => {
      state.updateShelvError = []
    },
    clearNewlyCrtedShelv: (state) => {
      state.newlyCrtedShelv = []
    },
    clearAddItemtoShelf : (state) => {
      state.addItemShelf = resetState();
      state.addItemShelfDone = false;
      return state
    },
    clearShelvFormState : (state) => {
      state.shelvFormState = resetState();
      return state
    },
    getPreviousLocaton: (state,{ payload}) => {
      state.previousLocation = payload;
      return state;
    },
    clearSelectedShelve : (state) => {
      state.selectedShelve = []
      return state
    },
    clearShelfColomns: (state) => {
      state.savedShelfCols = []
      return state
    },
    clearupdateShlvFormState: (state) => {
      state.updateShlvFormState = resetState();
    },
    cleardeleteFormState : (state) => {
      state.deleteFormState = resetState();
    }
  },
  extraReducers: {
    [fetchShelves.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
        state.shelvFormState = errorState(payload.errorMsg);
      } else {
        state.shelves = payload;
        state.formState = fulfilledState();
        state.shelvFormState = fulfilledState();
      }
      return state;
    },
    [fetchShelves.pending.toString()]: (state) => {
      state.formState = pendingState();
      state.shelvFormState = pendingState();
      return state;
    },
    [fetchShelves.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      state.shelvFormState = rejectedState();
      return state;
    },

    [fetchShelveItems.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.itemsFormState = errorState(payload.errorMsg);
      } else {
        state.itemsFormState = fulfilledState();
        state.shelveItems = payload;
      }
      return state;
    },
    [fetchShelveItems.pending.toString()]: (state) => {
      state.itemsFormState = pendingState();
      return state;
    },
    [fetchShelveItems.rejected.toString()]: (state, { payload }) => {
      state.itemsFormState = rejectedState();
      return state;
    },

    [resizeCompletedCols.fulfilled.toString()]: (state, { payload }) => {
      state.shelvDetailsColState = payload
    },
    [resizeCompletedCols.pending.toString()]: (state) => {
      return state;
    },
    [resizeCompletedCols.rejected.toString()]: (state, { payload }) => {
      return state;
    },
    [preDeleteShelv.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.preDeleteFormState = errorState(payload.errorMsg);
      } else {
        state.preShelf = payload;
        state.preDeleteFormState = fulfilledState();
      }
      return state;
    },
    [preDeleteShelv.pending.toString()]: (state) => {
      state.preDeleteFormState = pendingState();
      return state;
    },
    [preDeleteShelv.rejected.toString()]: (state, { payload }) => {
      state.preDeleteFormState = rejectedState();
      return state;
    },

    [fetchShelfDetails.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.shelvDetailFormState = errorState(payload.errorMsg);
      } else {
        state.shelvDetailFormState = fulfilledState();
        state.shelvDetails = payload;
      }
      return state;
    },
    [fetchShelfDetails.pending.toString()]: (state) => {
      state.shelvDetailFormState = pendingState();
      return state;
    },
    [fetchShelfDetails.rejected.toString()]: (state, { payload }) => {
      state.shelvDetailFormState = rejectedState();
      return state;
    },

    [fetchShelvByShelvId.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.shelvIdFormState = errorState(payload.errorMsg);
      } else {
        state.shelvIdFormState = fulfilledState();
        state.shelvDetailById = payload;
      }
      return state;
    },
    [fetchShelvByShelvId.pending.toString()]: (state) => {
      state.shelvIdFormState = pendingState();
      return state;
    },
    [fetchShelvByShelvId.rejected.toString()]: (state, { payload }) => {
      state.shelvIdFormState = rejectedState();
      return state;
    },

    [updateCartShelv.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.updateShlvFormState = errorState(payload.errorMsg);
      } else {
        state.updateShlvFormState = fulfilledState();
      }
      return state;
    },
    [updateCartShelv.pending.toString()]: (state) => {
      state.updateShlvFormState = pendingState();
      return state;
    },
    [updateCartShelv.rejected.toString()]: (state, { payload }) => {
      state.updateShelvError = payload
      state.updateShlvFormState = rejectedState();
      return state;
    },

    [updateCartShelvName.fulfilled.toString()]: (state, { payload }) => {
      if (payload[0]?.ErrorMessage) {
        state.updateShlvFormState = errorState(payload[0]?.ErrorDetails[1]);
      } else {
        state.updateShlvFormState = fulfilledState();
      }
      return state;
    },
    [updateCartShelvName.pending.toString()]: (state) => {
      state.updateShlvFormState = pendingState();
      return state;
    },
    [updateCartShelvName.rejected.toString()]: (state, { payload }) => {
      state.updateShelvError = payload
      state.updateShlvFormState = rejectedState();
      return state;
    },

    [createNewShelve.fulfilled.toString()]: (state, { payload }) => {
      if (payload.ErrorMessage) {
        state.newShelveFormState = errorState(payload.ErrorMessage);
      } else {
        state.newShelveFormState = fulfilledState();
      }
      return state;
    },
    [createNewShelve.pending.toString()]: (state) => {
      state.newShelveFormState = pendingState();
      return state;
    },
    [createNewShelve.rejected.toString()]: (state, { payload }) => {
      state.newShelveFormState = rejectedState();
      return state;
    },

    [createNewAutoShelve.fulfilled.toString()]: (state, { payload }) => {
      if (payload.ErrorMessage) {
        state.newAutoShelveFormState = errorState(payload.ErrorMessage);
      } else {
        state.newShelve = payload;
        state.newAutoShelveFormState = fulfilledState();
      }
      return state;
    },
    [createNewAutoShelve.pending.toString()]: (state) => {
      state.newAutoShelveFormState = pendingState();
      return state;
    },
    [createNewAutoShelve.rejected.toString()]: (state, { payload }) => {
      state.updateShelvError = payload
      state.newAutoShelveFormState = rejectedState();
      return state;
    },

    [editAutoShelv.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.updateShlvFormState = errorState(payload.errorMsg);
      } else {
        state.newShelve = payload;
        state.updateShlvFormState = fulfilledState();
      }
      return state;
    },
    [editAutoShelv.pending.toString()]: (state) => {
      state.updateShlvFormState = pendingState();
      return state;
    },
    [editAutoShelv.rejected.toString()]: (state, { payload }) => {
      state.updateShelvError = payload
      state.updateShlvFormState = rejectedState();
      return state;
    },

    [removeShelvItems.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.removeformState = errorState(payload.errorMsg);
      } else {
        state.removeformState = fulfilledState();
      }
      return state;
    },
    [removeShelvItems.pending.toString()]: (state) => {
      state.removeformState = pendingState();
      return state;
    },
    [removeShelvItems.rejected.toString()]: (state, { payload }) => {
      state.removeError = payload;
      state.removeformState = rejectedState();
      return state;
    },

    [refreshShelv.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.refreshShelvFormState = errorState(payload.errorMsg);
      } else {
        state.refreshShelvFormState = fulfilledState();
        state.shelvSyncData = payload;
      }
      return state;
    },
    [refreshShelv.pending.toString()]: (state) => {
      state.refreshShelvFormState = pendingState();
      return state;
    },
    [refreshShelv.rejected.toString()]: (state, { payload }) => {
      state.updateShelvError = payload
      state.refreshShelvFormState = rejectedState();
      return state;
    },

    [onSelectShelve.fulfilled.toString()]: (state, { payload }) => {
      state.selectedShelve = payload
      state.formState = fulfilledState();
      return state;
    },
    [onSelectShelve.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [onSelectShelve.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },

    [addItemtoShelve.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.addItemShelf = errorState(payload.errorMsg);
      } else {
        state.addItemShelf = fulfilledState();
        state.addItemShelfDone = true;
      }
      return state;
    },
    [addItemtoShelve.pending.toString()]: (state) => {
      state.addItemShelf = pendingState();
      return state;
    },
    [addItemtoShelve.rejected.toString()]: (state, { payload }) => {
      state.addItemShelf = rejectedState();
      return state;
    },

    [addItemtoIndividualShelve.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.addItemShelfAlone = errorState(payload.errorMsg);
      } else {
        state.addItemShelfAlone = fulfilledState();
        state.addSingleItemToShelf = true;
      }
      return state;
    },
    [addItemtoIndividualShelve.pending.toString()]: (state) => {
      state.addItemShelfAlone = pendingState();
      return state;
    },
    [addItemtoIndividualShelve.rejected.toString()]: (state, { payload }) => {
      state.addItemShelfAlone = rejectedState();
      return state;
    },
    [fetchShelvesPatron.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.shelves = payload;
      }
      return state;
    },
    [savedShelfColumns.fulfilled.toString()]: (state, { payload }) => {
      state.savedShelfCols = payload
      state.savedColFormState = fulfilledState();
    },
    [savedShelfColumns.pending.toString()]: (state) => {
      state.savedColFormState = pendingState();
      return state;
    },
    [savedShelfColumns.rejected.toString()]: (state, { payload }) => {
      state.savedColFormState = rejectedState();
      return state;
    },
    [deleteShelve.fulfilled.toString()]: (state, { payload }) => {
      state.deleteFormState = fulfilledState();
    },
    [deleteShelve.pending.toString()]: (state) => {
      state.deleteFormState = pendingState();
      return state;
    },
    [deleteShelve.rejected.toString()]: (state, { payload }) => {
      state.deleteFormState = rejectedState();
      return state;
    },
    [retainShelvState.fulfilled.toString()]: (state, { payload }) => {
      state.shelvListState = payload
      return state;
    },
    [retainShelvPostion.fulfilled.toString()]: (state, { payload }) => {
      state.shelvListPostion = payload
      return state;
    },
    [updateShelvAfterSync.fulfilled.toString()]: (state, { payload }) => {
      state.shelves = payload
      return state;
    },
    [newlyCreatedShelv.fulfilled.toString()]: (state, { payload }) => {
      state.newlyCrtedShelv = payload
      return state;
    },
  },
});

export const { clearState,clearRefeshShelv,clearAaddSingleShelv,clearShelvItems,clearShelvDetails,setTab,setShelvesFilter, clearNewShelv,clearRemoveError,clearUpdateError,clearAddItemtoShelf,getPreviousLocaton,clearSelectedShelve,clearShelfColomns, clearupdateShlvFormState, cleardeleteFormState,clearShelvListState,clearShelvFormState,clearNewlyCrtedShelv } = ShelfSlice.actions;
export const ShelfstateSelector = (state: RootState) => state.Shelves;
export default ShelfSlice.reducer;
