export const settingFlags = {
  LibraryId: 0,
  Activated: "",
  AllowDocumentSale: 0,
  AllowGlobalCatalogSearch: 0,
  AllowPhysicalDocumentSearch: 0,
  Created: "",
  DiscountPercentage: null,
  InactiveSince: "",
  MaxHeldDocuments: 0,
  MaxHoldTimeDuration: 0,
  MaxHoldTimeDurationUnit: 0,
  MaxLoanTimeDuration: 0,
  MaxLoanTimeDurationUnit: 0,
  MaxLoanedDocuments: 0,
  MaxReservationTimeDuration: 0,
  MaxReservationTimeDurationUnit: 0,
  MaxPatronWishes: 0,
  Modified: "",
  Name: "",
  PatronPIN: 0,
  Twitter: "",
  URLName: "",
  AddressId: "",
  BillingContactId: "",
  ImageId: "",
  NatureId: "",
  SalesContactId: "",
  TechnicalContactId: "",
  PhysicalSearchConfigured: "",
  BillingId: "",
  SupportEmail: "",
  ConsortiumId: "",
  TestLibrary: "",
  SupportLoanHistory: 0,
  AffiliateId: "",
  CreateDateTime: "",
  DeidentifiedLibraryId: "",
  AllowPrivateCollection: 0,
  CollectionSharingPerm: "0",
  LocaleForCAT: "",
  Currency: "",
  CurrencySymbol: "",
  CountryCode: "",
  LibraryWebsite: "",
  SupportWebsite: "",
  LibraryImage: "",
  CatalogName: "",
  AllowBuyNow: "",
  LanguageForApps: "enus",
  IsTrendingShelfEnabled: false,
  IsEbookTrendingShelfEnabled: false,
  IsAudioBookTrendingShelfEnabled: false,
  IsEbookPPUPatronLimitEnabled: false,
  PPUEbookPatronMaxLimit: 0,
  IsAudioBookPPUPatronLimitEnabled: false,
  PPUAudioBookPatronMaxLimit: 0,
};

export const userDataKeys ={
  EmailAddress: "",
  FirstName: "",
  IsActive: true,
  IsDownloadStationUser: false,
  IsFeaturedContentManager: false,
  IsLibraryAdmin: false,
  IsLibraryBuyer: false,
  IsLibraryUser: false,
  IsPatronCommunicator: false,
  IsShelfAdmin: false,
  LastName: "",
  Library: [],
  Password: "",
  ScreenName: "",
  UserId: "",
  UserLanguage: "en-US",
}

export const libraryInputs = (libSettingsData: any) => {
  return [
    {
      label: "Support Email",
      type: "text",
      key: "SupportEmail",
      name: "SupportEmail",
      value:
        libSettingsData?.SupportEmail !== undefined
          ? libSettingsData?.SupportEmail
          : "",
    },
    {
      label: "Library Website",
      name: "LibraryWebsite",
      key: "LibraryWebsite",
      type: "text",
      value:
        libSettingsData?.LibraryWebsite !== undefined
          ? libSettingsData?.LibraryWebsite
          : "",
    },
    {
      label: "Support Website",
      name: "SupportWebsite",
      key: "SupportWebsite",
      type: "text",
      value:
        libSettingsData?.SupportWebsite !== undefined
          ? libSettingsData?.SupportWebsite
          : "",
    },
    {
      label: "Library Name (identifier in URL)",
      type: "text",
      name: "URLName",
      key: "URLName",
      value:
        libSettingsData?.URLName !== undefined ? libSettingsData?.URLName : "",
    },
    // {
    //   label: "Twitter Account Name",
    //   type: "text",
    //   name: "Twitter",
    //   key: "Twitter",
    //   value:
    //     libSettingsData?.Twitter !== undefined ? libSettingsData?.Twitter : "",
    // },
    {
      label: "Default Language",
      type: "text",
      name: "defaultLanguage",
      key: "defaultLanguage",
      value:
        libSettingsData?.LanguageForApps !== undefined
          ? libSettingsData?.LanguageForApps
          : "",
    },
    {
      label: "Library Logo",
      type: "img",
      name: "Library_Logo",
      key: "Library_Logo",
      value:
        libSettingsData?.SupportEmail !== undefined
          ? libSettingsData?.SupportEmail
          : "",
    },
  ];
};

export const patronCheckboxes = (libSettingsData: any) => {
  return [
    {
      label: "Allow patrons to add content to suggestions.",
      name: "AllowGlobalCatalogSearch",
      value:
        libSettingsData?.AllowGlobalCatalogSearch !== undefined
          ? libSettingsData?.AllowGlobalCatalogSearch
          : 0,
    },
    {
      label: "Enable reading history for all patrons.",
      name: "SupportLoanHistory",
      value:
        libSettingsData?.SupportLoanHistory !== undefined
          ? libSettingsData?.SupportLoanHistory
          : 0,
    },
    {
      label: "Enable trending shelf for patrons?",
      name: "IsTrendingShelfEnabled",
      value:
        libSettingsData?.IsTrendingShelfEnabled !== undefined
          ? libSettingsData?.IsTrendingShelfEnabled
          : false,
    },
    {
      label: "Ebook",
      name: "IsEbookTrendingShelfEnabled",
      value:
        libSettingsData?.IsEbookTrendingShelfEnabled !== undefined
          ? libSettingsData?.IsEbookTrendingShelfEnabled
          : false,
    },
    {
      label: "Audio Book",
      name: "IsAudioBookTrendingShelfEnabled",
      value:
        libSettingsData?.IsAudioBookTrendingShelfEnabled !== undefined
          ? libSettingsData?.IsAudioBookTrendingShelfEnabled
          : false,
    },
  ];
};

export const patronInputs = (libSettingsData: any) => {
  return [
    {
      label: "Checkouts per Patron",
      name: "MaxLoanedDocuments",
      type:'Number',
      value:
        libSettingsData?.MaxLoanedDocuments !== undefined
          ? libSettingsData?.MaxLoanedDocuments
          : "0",
    },
    {
      label: "Holds per Patron",
      name: "MaxHeldDocuments",
      type:'Number',
      value:
        libSettingsData?.MaxHeldDocuments !== undefined
          ? libSettingsData?.MaxHeldDocuments
          : "0",
    },
    {
      label: "Monthly Suggestions per Patron",
      name: "MaxPatronWishes",
      type:'Number',
      value:
        libSettingsData?.MaxPatronWishes !== undefined
          ? libSettingsData?.MaxPatronWishes
          : "0",
    },
    {
      label: "Hold Queue Reservations, in Days",
      name: "MaxHoldTimeDuration",
      type:'Number',
      value:
        libSettingsData?.MaxHoldTimeDuration !== undefined
          ? libSettingsData?.MaxHoldTimeDuration
          : "0",
    },
    {
      label: "Default Loan Period",
      name: "MaxLoanTimeDuration",
      type:'Number',
      value:
        libSettingsData?.MaxLoanTimeDuration !== undefined
          ? libSettingsData?.MaxLoanTimeDuration
          : "0",
    },
  ];
};


export const userRoolesChkBox = (userData: any) => {
  return [
    {
      label: "Library Manager",
      name: "IsLibraryAdmin",
      contentIniIcon:"User is able to manage library users and settings.",
      value:
        userData?.IsLibraryAdmin !== undefined
          ? userData?.IsLibraryAdmin
          : false,
    },
    {
      label: "Buyer",
      name: "IsLibraryBuyer",
      contentIniIcon:"User is able to both shop for and buy books. NOTE: Any user with the 'Buyer' role is automatically assigned the 'Shelf Manager' and 'Shopper' roles.",
      value:
        userData?.IsLibraryBuyer !== undefined
          ? userData?.IsLibraryBuyer
          : false,
    },
    {
      label: "Patron Communicator",
      name: "IsPatronCommunicator",
      contentIniIcon:"User is able to manage patron communications.",
      value:
        userData?.IsPatronCommunicator !== undefined
          ? userData?.IsPatronCommunicator
          : false,
    },
    {
      label: "Patron Manager",
      name: "IsPatronManager",
      contentIniIcon:"User is able to manage patron accounts.",
      value:
        userData?.IsPatronManager !== undefined
          ? userData?.IsPatronManager
          : false,
    },
    {
      label: "Shelves Manager",
      name: "IsShelfAdmin",
      contentIniIcon:"User is able to create and organize shelves.",
      value:
        userData?.IsShelfAdmin !== undefined ? userData?.IsShelfAdmin : false,
    },
    {
      label: "Shopper",
      name: "IsLibraryUser",
      contentIniIcon:"User is able to shop for books and add books to the shopping cart, but user cannot buy books.",
      value:
        userData?.IsLibraryUser !== undefined
          ? userData?.IsLibraryUser
          : false,
    },
  ]
};