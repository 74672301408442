import { searchBody, searchString } from "../web/constants/searchConstant";

export const searchPayloadForSeries = async (series: string, appUser: any) => {
  let searchParam = {
    ...searchBody,
    series: series,
    LibraryId:
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0,
    CatalogId:
      appUser && appUser.libraryData ? appUser.libraryData[0].NatureId : 0,
  };

  let strinParam = {
    ...searchString,
    series: series,
  };

  let state: any = {
    searchDetails: searchParam,
  };

  return { searchParam, strinParam, state };
};

export const searchPayloadForAuthor = async (author: string, appUser: any) => {
  const regexForStringReplace = /,/gi;
  const temp: any = author?.replace(regexForStringReplace, ",").split(";");
  let searchParam = {
    ...searchBody,
    AuthorSearch: temp,
    LibraryId:
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0,
    CatalogId:
      appUser && appUser.libraryData ? appUser.libraryData[0].NatureId : 0,
  };

  let strinParam = {
    ...searchString,
    author: author,
  };

  let state: any = {
    searchDetails: searchParam,
  };

  return { searchParam, strinParam, state };
};

export const searchPayloadForNarrator = async (
  narrator: string,
  appUser: any
) => {
  const temp: any = narrator;

  let searchParam = {
    ...searchBody,
    Narrator: temp,
    LibraryId:
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0,
    CatalogId:
      appUser && appUser.libraryData ? appUser.libraryData[0].NatureId : 0,
  };

  let strinParam = {
    ...searchString,
    narrator: narrator,
  };

  let state: any = {
    searchDetails: searchParam,
  };

  return { searchParam, strinParam, state };
};


const getMediaType = (arr: any) => {
  var x = arr;
  if (x.includes("1") && x.includes("2") && x.includes("3")) {
    return [1, 2];
  } else if (x.includes("1") && x.includes("2") && !x.includes("3")) {
    return [1];
  } else if (x.includes("1") && !x.includes("2") && !x.includes("3")) {
    return [];
  } else if (!x.includes("1") && x.includes("2") && !x.includes("3")) {
    return [];
  } else if (!x.includes("1") && !x.includes("2") && x.includes("3")) {
    return [2];
  } else if (!x.includes("1") && x.includes("2") && x.includes("3")) {
    return [2];
  } else if (x.includes("1") && !x.includes("2") && x.includes("3")) {
    return [2];
  } else if (!x.includes("1") && !x.includes("2") && !x.includes("3")) {
    return [];
  } else {
    return [];
  }
};

//payload standards for advance search
export const checkPayloadAndReform = (item: any) => {
  let ItemFormatIdSets: any = [];
  const getFormat = (item: any) => {
    var itemFormatIdSet: any = item;
    const formatMap: any = {
      "1": "EPUB",
      "2": "PDF",
      "3": "MP3",
    };
    const itemFormatIds: any = {
      Ebooks: [],
      Audiobooks: [],
    };
    itemFormatIdSet?.forEach((id: any) => {
      if (formatMap[id]) {
        if (id === "1" || id === "2") {
          itemFormatIds?.Ebooks?.push(formatMap[id]);
          if (id === "1") {
            ItemFormatIdSets?.push(1);
          } else if (id === "2") {
            ItemFormatIdSets?.push(2);
          }
        } else if (id === "3") {
          itemFormatIds?.Audiobooks?.push(formatMap[id]);
        }
      }
    });
    if (itemFormatIds.Ebooks.length === 0) {
      delete itemFormatIds?.Ebooks;
    }
    if (itemFormatIds.Audiobooks.length === 0) {
      delete itemFormatIds?.Audiobooks;
    }
    return itemFormatIds;
  };
  const ItemFormatSet = getFormat(
    item?.ItemFormatIdSet ? item?.ItemFormatIdSet : []
  );

  return {
    AddedToCatalogDateFrom: item?.AddedToCatalogDateFrom
      ? item?.AddedToCatalogDateFrom
      : null,
    AddedToCatalogDateTo: item?.AddedToCatalogDateTo
      ? item?.AddedToCatalogDateTo
      : null,
    AddedToCatalogDaysOld: item?.AddedToCatalogDaysOld
      ? item?.AddedToCatalogDaysOld
      : "",
    AuthorSearch: item?.AuthorSearch ? item?.AuthorSearch : [""],
    ContentProviderSearch: item?.ContentProviderSearch
      ? item?.ContentProviderSearch
      : [],
    CategorySearch: item?.CategorySearch ? item?.CategorySearch : [],
    EISBN: item?.EISBN ? item?.EISBN : "",
    SeriesSearch: item?.series ? item?.series : [],
    Narrator: item?.Narrator ? item?.Narrator : "",
    FromPublisherDate: item?.FromPublisherDate ? item?.FromPublisherDate : null,
    FromPurchaseDate: item?.FromPurchaseDate ? item?.FromPurchaseDate : null,
    HoldRatio: item?.HoldRatio ? item?.HoldRatio : "",
    InCollection: item?.InCollection,
    ItemFormatId: "",
    ItemFormatIds:
      Object?.keys(ItemFormatSet)?.length !== 0 ? ItemFormatSet : null,
      ItemFormatIdSet: ItemFormatIdSets
      ? ItemFormatIdSets.length !== 2
        ? ItemFormatIdSets
        : []
      : [],
    MediaTypeIdSet: getMediaType(item?.ItemFormatIdSet?item?.ItemFormatIdSet:[]),
    Language: item?.Language ? item?.Language : [],
    LibraryId: item?.LibraryId ? item?.LibraryId : "",
    MinimumPriceAmount: item?.MinimumPriceAmount
      ? item?.MinimumPriceAmount
      : "",
    MaximumPriceAmount: item?.MaximumPriceAmount
      ? item?.MaximumPriceAmount
      : "",
    NotInCollection: item?.NotInCollection ? item?.NotInCollection : true,
    ExcludeSelfPublished: item?.ExcludeSelfPublished
      ? item?.ExcludeSelfPublished
      : false,
    PublisherSearch: item?.PublisherSearch ? item?.PublisherSearch : [],
    QuickSearch: item?.QuickSearch ? item?.QuickSearch : [""],
    SubCategorySearch: item?.SubCategorySearch ? item?.SubCategorySearch : [],
    TitleDaysOld: item?.TitleDaysOld ? item?.TitleDaysOld : "",
    PreSaleDays: item?.PreSaleDays ? item?.PreSaleDays : 0,
    PPUCriteria: item?.PPUCriteria ? item?.PPUCriteria : 1,
    TargetAudienceIds: item?.TargetAudienceIds ? item?.TargetAudienceIds : [],
    TitleSearch: item?.TitleSearch ? item?.TitleSearch : [],
    ToPublisherDate: item?.ToPublisherDate ? item?.ToPublisherDate : null,
    ToPurchaseDate: item?.ToPurchaseDate ? item?.ToPurchaseDate : null,
    POReference: item?.POReference ? item?.POReference : "",
    recordStart: item?.recordStart ? item?.recordStart : 1,
    recordCount: item?.recordCount ? item?.recordCount : 100,
    sortField: item?.sortField ? item?.sortField : "",
    sortDirection: item?.sortDirection ? item?.sortDirection : "ASC",
    CatalogId: item?.CatalogId ? item?.CatalogId : 2,
  };
};

export const checkCollectPayloadNReForm = (item: any) => {
  let MediaTypeIdSet: any = [];
  let ItemFormatIdSets: any = [];
  const getFormat = (item: any) => {
    var itemFormatIdSet: any = item;
    const formatMap: any = {
      "1": "EPUB",
      "2": "PDF",
      "3": "MP3",
    };
    const itemFormatIds: any = {
      Ebooks: [],
      Audiobooks: [],
    };
    itemFormatIdSet?.forEach((id: any) => {
      if (formatMap[id]) {
        if (id === "1" || id === "2") {
          itemFormatIds?.Ebooks?.push(formatMap[id]);
          if (id === "1") {
            ItemFormatIdSets?.push(1);
          } else if (id === "2") {
            ItemFormatIdSets?.push(2);
          }
        } else if (id === "3") {
          itemFormatIds?.Audiobooks?.push(formatMap[id]);
        }
      }
    });
    if (itemFormatIds.Ebooks.length === 0) {
      delete itemFormatIds?.Ebooks;
    }
    if (itemFormatIds.Audiobooks.length === 0) {
      delete itemFormatIds?.Audiobooks;
    }
    return itemFormatIds;
  };
  const ItemFormatSet = getFormat(
    item?.ItemFormatIdSet ? item?.ItemFormatIdSet : []
  );
  return {
    AddedToCatalogDateFrom: item?.AddedToCatalogDateFrom
      ? item?.AddedToCatalogDateFrom
      : null,
    AddedToCatalogDateTo: item?.AddedToCatalogDateTo
      ? item?.AddedToCatalogDateTo
      : null,
    AddedToCatalogDaysOld: item?.AddedToCatalogDaysOld
      ? item?.AddedToCatalogDaysOld
      : "",
    AuthorSearch: item?.AuthorSearch ? item?.AuthorSearch : [""],
    ContentProviderSearch: item?.ContentProviderSearch
      ? item?.ContentProviderSearch
      : [],
    CategorySearch: item?.CategorySearch ? item?.CategorySearch : [],
    EISBN: item?.EISBN ? item?.EISBN : "",
    SeriesSearch: item?.series ? item?.series : [],
    Narrator: item?.Narrator ? item?.Narrator : "",
    FromPublisherDate: item?.FromPublisherDate ? item?.FromPublisherDate : null,
    FromPurchaseDate: item?.FromPurchaseDate ? item?.FromPurchaseDate : null,
    HoldRatio: item?.HoldRatio ? item?.HoldRatio : "",
    InCollection: true,
    NotInCollection: false,
    ItemFormatId: "",
    ItemFormatIds:
      Object?.keys(ItemFormatSet)?.length !== 0 ? ItemFormatSet : null,
    ItemFormatIdSet: ItemFormatIdSets
      ? ItemFormatIdSets.length !== 2
        ? ItemFormatIdSets
        : []
      : [],
    MediaTypeIdSet: getMediaType(item?.ItemFormatIdSet),
    Language: item?.Language ? item?.Language : [],
    LibraryId: item?.LibraryId ? item?.LibraryId : "",
    MinimumPriceAmount: item?.MinimumPriceAmount
      ? item?.MinimumPriceAmount
      : "",
    MaximumPriceAmount: item?.MaximumPriceAmount
      ? item?.MaximumPriceAmount
      : "",
    ExcludeSelfPublished: item?.ExcludeSelfPublished
      ? item?.ExcludeSelfPublished
      : false,
    PublisherSearch: item?.PublisherSearch ? item?.PublisherSearch : [],
    QuickSearch: item?.QuickSearch ? item?.QuickSearch : [""],
    SubCategorySearch: item?.SubCategorySearch ? item?.SubCategorySearch : [],
    TitleDaysOld: item?.TitleDaysOld ? item?.TitleDaysOld : "",
    PreSaleDays: item?.PreSaleDays ? item?.PreSaleDays : 0,
    PPUCriteria: item?.PPUCriteria ? item?.PPUCriteria : 1,
    TargetAudienceIds: item?.TargetAudienceIds ? item?.TargetAudienceIds : [],
    TitleSearch: item?.TitleSearch ? item?.TitleSearch : [],
    ToPublisherDate: item?.ToPublisherDate ? item?.ToPublisherDate : null,
    ToPurchaseDate: item?.ToPurchaseDate ? item?.ToPurchaseDate : null,
    POReference: item?.POReference ? item?.POReference : "",
    recordStart: item?.recordStart ? item?.recordStart : 1,
    recordCount: item?.recordCount ? item?.recordCount : 100,
    sortField: item?.sortField ? item?.sortField : "",
    sortDirection: item?.sortDirection ? item?.sortDirection : "ASC",
    CatalogId: item?.CatalogId ? item?.CatalogId : 2,
  };
};

export function removeQuotes(str:any) {
  return str.replace(/\\?"|"/g, '');
}