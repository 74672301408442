export enum AutoCartType {
  HOLDS = "Holds",
  SUGGESTIONS = "Suggestions",
  CONTENT_EXP = "Content Expiration",
  COMMON = "Common"
}

export enum GroupArchived {
 TRUE = "D",
 FALSE = "N"
}

export enum UserRole {
  NONE = "@@userRole/NONE",
  PLATFORM_ADMIN = "Bibliotheca Admin",
  PROJECT_MANAGER = "Bibliotheca Project Manager",
  SUPPORT = "Bibliotheca Support User",
  SITE_ENGINEER = "Bibliotheca Site Engineer",
  LOCATION_ADMIN = "Location Admin",

  ORGANISATION_ADMIN = "Organisation Admin",
  LIBRARY_BACK_OFFICE = "Back Office",
  LIBRARY_FRONT_DESK = "Front Office",
  LIBRARY_DIRECTOR_MANAGER = "Library Manager",
  BIBLIOTHECA_USER = "Bibliotheca User"
}

export enum DisplayViewType {
  CARD_VIEW = "0",
  LIST_VIEW = "1",
}

export enum APIResponseError {
  NOT_FOUND = "NotFoundError",
  USER_FORBIDDEN = "ForbiddenError",
  AUTH_FAILURE = "AuthFailureError",
  ALREADY_EXISTS = "AlreadyExistsError",
}

export enum Permissions {
USERS = "Users",
ORGANISATION = "Organisation Details",
ROLE = "Roles",
DEVICE = "Devices", 
SUBSCRIPTION = "Subscriptions",
LOCATIONS = "Locations",
MASTER_CONFIG = "Master Configuration",
PRODUCT_CONFIG = "Product Configuration",
DEVICE_CONFIG = "Device Configuration",
ALERTS = "Alerts",
}
