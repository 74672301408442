import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { showError } from "../../../utility/appUtil";
import Group from "../../models/group/group";
import Report from "../../models/reports/reports";

const reportBaseUrl = localStorage.getItem("reportsUrl")

export class ReportsService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.GROUPS));
  }
  async fetchRepotsToken(payload: any): Promise<Group[] | any> {
    var new_tab:any=window?.open()
    const response = await this.post(`/getReportsToken?email_Id=${payload?.email_Id}`);
    // eslint-disable-next-line eqeqeq
    if (response.data.success == 1) {
      const reportData = this.setReportData(response.data.data);
        var url = `${reportBaseUrl}/Account/LogOn?SSOToken=${reportData?.UserTokenId}`
        if(reportData?.UserTokenId !== undefined){
          new_tab.location.href=url;
        }
      return reportData;
    } else{
      showError(response?.data?.data)
      return response.data.data}
  }

  private setReportData(data: any) {
    const reports: Report = {
      ClaimSet: data?.ClaimSet,
      CreateDateTime: data?.CreateDateTime,
      ExpirationDateTime: data?.ExpirationDateTime,
      Signature: data?.Signature,
      UserId: data?.UserId,
      UserTokenId: data?.UserTokenId,
      ValidationURL: data?.ValidationURL,
    };
    return reports;
  }

  private setError(data: any) {
    const error: Error[] = data.map((err: any) => {
      return {
        ErrorCode : err?.ErrorCode !== undefined?err?.ErrorCode:"",
        ErrorDetails: err?.ErrorDetails!== undefined?err?.ErrorDetails:"",
        ErrorMessage:err?.ErrorMessage!== undefined?err?.ErrorMessage:"",
        Message:err?.Message !== undefined?err?.Message:""
      };
    });
    return error;
  }
}
