/* eslint-disable eqeqeq */
import { useSelector } from "react-redux";
import { CartStateSelector } from "../../../../../redux/reducers/carts/cartsReducer";

const CustomData=()=>{
    const {carts,autoCartsData } = useSelector(CartStateSelector)
    let customObject:any
    var holdRatioTitles:number=0
    var holdRatioAmount:number=0
    var holdRatioData:any=[]
    var suggestionTitles:number=0
    var suggestionAmount:number=0
    var suggestionData:any=[]
    var renewalTitles:number=0
    var renewalAmount:number=0
    var renewalData:any=[]

    for (var key in carts) {
        if((carts[key].CartName=='Auto Audio HoldRatio' || carts[key].CartName=='Auto eBook HoldRatio') && carts[key].CartOwner=='Auto'){            
            holdRatioTitles +=Number(carts[key].NumberOfTitles)
            holdRatioAmount +=Number(carts[key].TotalCartAmount)
            holdRatioData['titles']=holdRatioTitles
            holdRatioData['amount']=holdRatioAmount
        }
        if((carts[key].CartName=='Auto Audio Suggestions' || carts[key].CartName=='Auto eBook Suggestions') && carts[key].CartOwner=='Auto'){            
            suggestionTitles +=Number(carts[key].NumberOfTitles)
            suggestionAmount +=Number(carts[key].TotalCartAmount)
            suggestionData['titles']=suggestionTitles
            suggestionData['amount']=suggestionAmount
        }
        if((carts[key].CartName=='Auto Audio ContentExpiration' || carts[key].CartName=='Auto eBook ContentExpiration') && carts[key].CartOwner=='Auto'){            
            renewalTitles +=Number(carts[key].NumberOfTitles)
            renewalAmount +=Number(carts[key].TotalCartAmount)
            renewalData['titles']=renewalTitles
            renewalData['amount']=renewalAmount
        }
    }

        for (var i = 0; i < autoCartsData[0]?.Rules?.length; i++) {
          if (autoCartsData[0]?.Rules[i]?.AutoBuyRuleType === 1) {
            renewalData['update_date'] = autoCartsData[0]?.Rules[i]?.DateLastRun;
          }
          if (autoCartsData[0]?.Rules[i]?.AutoBuyRuleType === 2) {
            holdRatioData['update_date'] = autoCartsData[0]?.Rules[i]?.DateLastRun;
          }
          if (autoCartsData[0]?.Rules[i]?.AutoBuyRuleType === 3) {
            suggestionData['update_date'] = autoCartsData[0]?.Rules[i]?.DateLastRun;
          }
        }
    
    customObject={holdData:holdRatioData,suggestionData:suggestionData,renewData:renewalData}
    return customObject

}
export default CustomData

