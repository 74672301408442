import { createAsyncThunk } from "@reduxjs/toolkit";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import thunk from "redux-thunk";
import { showError } from "../../../utility/appUtil";
import { SettingsService } from "../../../web/services/settings/settings";

const settingsSrv = new SettingsService();

export const recentSettingTab = createAsyncThunk(
  "api/recentSettingTab",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const recentPatronTab = createAsyncThunk(
  "api/recentSettingTab",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const searchPatron = createAsyncThunk(
  "api/searchPatron",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.fetchPatronById(payload);
      if (data && data?.Catalog) {
        if (data?.IsActive) {
          thunkAPI.dispatch(fetchPatronHold(payload));
          return data;
        } else {
          return data;
        }
      } else {
        showError(data)
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchPatronHold = createAsyncThunk(
  "api/fetchPatronHold",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.fetchPatronHoldById(payload);
      if (data) {
        return data;
      } else {
        showError(data)
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const updatePatron = createAsyncThunk(
  "api/updatePatron",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.putNewPatronById(payload);
      if (data) {
        if (data?.ErrorMessage !== undefined && data?.ErrorMessage !== "") {
          return data;
        } else {
          thunkAPI.dispatch(
            searchPatron({
              libraryId: payload?.libraryId,
              PatronId: payload?.newName,
            })
          );
        }
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const updateHoldPosition = createAsyncThunk(
  "api/updateHoldPosition",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.putHoldPosition(payload);
      if (data) {
        if (data?.ErrorMessage !== undefined && data?.ErrorMessage !== "") {
          return data;
        } else {
          thunkAPI.dispatch(
            fetchPatronHold({
              libraryId: payload?.libraryId,
              PatronId: payload?.PatronId,
            })
          );
        }
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const resizePendingCols = createAsyncThunk(
  "api/resizePendingCols",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
