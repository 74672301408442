
export const getallSearchStringRowA=(searchStrings:any)=> {
  const strinDetails = searchStrings
    var inputSearchStringA: JSX.Element = (
      strinDetails !== null?
        !strinDetails?.isbn?
        <div>
          {strinDetails?.title !== "" ? <p className="pr-5"><span>Title:</span> <span className="labelTerm">{strinDetails?.title?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.author !== "" ? <p className="pr-5"><span>Author:</span> <span className="labelTerm">{strinDetails?.author?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.narrator !== "" ? <p className="pr-5"><span>Narrator:</span> <span className="labelTerm">{strinDetails?.narrator?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.category !== "" ? <p className="pr-5"><span>Category:</span> <span className="labelTerm">{strinDetails?.category?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.subCategory !== "" ? <p className="pr-5"><span>SubCategory:</span> <span className="labelTerm">{strinDetails?.subCategory?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.audience !== "" ? <p className="pr-5"><span>Audience:</span> <span className="labelTerm">{strinDetails?.audience?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.language !== "" ? <p className="pr-5"><span>Language:</span> <span className="labelTerm">{strinDetails?.language?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.series !== "" ? <p className="pr-5"><span>Series:</span> <span className="labelTerm">{strinDetails?.series?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.MinimumPriceAmount || strinDetails?.MaximumPriceAmount !== "" ? <p className="pr-5"><span>Pricing:</span> <span> Min: </span><span className="labelTerm">{strinDetails?.MinimumPriceAmount?.replace(/,/g, ', ')}</span> <span> Max: </span><span className="labelTerm">{strinDetails?.MaximumPriceAmount?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.persaleTitle !== "" ? <p className="pr-5"><span>Presale Titles:</span> <span className="labelTerm">{strinDetails?.persaleTitle?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.HoldRatio !== "" ? <p className="pr-5"><span>Hold Ratio:</span> <span className="labelTerm">{strinDetails?.HoldRatio?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.publisher !== "" ? <p className="pr-5"><span>Publishers:</span> <span className="labelTerm">{strinDetails?.publisher?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.provider !== "" ? <p className="pr-5"><span>Providers:</span> <span className="labelTerm">{strinDetails?.provider?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.publisheWithin !== "" ? <p className="pr-5"><span>Published within:</span> <span className="labelTerm">{strinDetails?.publisheWithin?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.publishDays !== "" && strinDetails?.publishDays !== "0 days" ? <p className="pr-5"><span>Published within:</span> <span className="labelTerm">{strinDetails?.publishDays?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.dateAdedToCl !== "" && strinDetails?.dateAdedToCl !== "0 days" ? <p className="pr-5"><span>Date added to cloud library:</span> <span className="labelTerm">{strinDetails?.dateAdedToCl?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.PPUCriteria !== "" && strinDetails?.PPUCriteria !== undefined ? <p className="pr-5"><span>PPU Titles:</span> <span className="labelTerm">{strinDetails?.PPUCriteria?.replace(/,/g, ', ')}</span> | </p>: ""}             
          {strinDetails?.POReference !== "" && strinDetails?.POReference !== undefined ? <p className="pr-5"><span>PO Refrence:</span> <span className="labelTerm">{strinDetails?.POReference?.replace(/,/g, ', ')}</span> | </p>: ""}
          {strinDetails?.customDateRange !== "" && strinDetails?.customDateRange !== undefined ? <p className="pr-5"><span>Purchased Date:</span> <span className="labelTerm">{strinDetails?.customDateRange?.replace(/,/g, ', ')}</span> | </p>: ""}
        </div>
        :
        <div>
        {strinDetails?.isbn !== "" ? <p className="pr-5"><span>ISBN:</span> <span className="labelTerm">{strinDetails?.isbn.replace(/,/g, ', ')}</span> </p>: ""}
      </div>
        :
        <></>
      );

      return inputSearchStringA

}

export const getallSearchStringRowB=(searchStrings:any)=> {
  const strinDetails = searchStrings
  const inputSearchStringB: JSX.Element = (
    strinDetails !== null?
    !strinDetails?.isbn?
    <div>
    {strinDetails?.formats !== "" ? <p className="pr-5"><span>Formats:</span> <span className="labelTerm">{strinDetails?.formats}</span> | </p>: ""}
    {strinDetails?.filters !== "" ? <p className="pr-5"><span>Filters:</span> <span className="labelTerm">{strinDetails?.filters}</span> | </p>: ""}
  </div>
  :
  <></>
  :
  <></>
  )

      return inputSearchStringB
}