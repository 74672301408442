import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import { AppStateService } from "../../../web/services/storage/appStateService";
import { checkEmail, checkLibrarianEmail, forgotPassword, libraryInitialPassword, logOut, resetPassword, SingleSignOn } from "../../actions/user/authAction";
import { fetchConfigData, fetchUsers } from "../../actions/user/userAction";
import { getNewAppData, getRoutPathName } from "../../actions/app/appActions";
import { AuthState } from "../../states/user/authState";
import { RootState } from "../../store/rootReducer";
const initialState: AuthState = {
  appUser: null,
  showSpotLight: false,
  libLogUser: false,
  authInput: null,
  routPathName:"",
  users: [],
  appVersion: '',
  formState: resetState(),
  accountSettingsFormState: resetState(),
  forgotPwdformState:resetState(),
  resetPwdformState:resetState(),
  libInitialPaswrdformState:resetState(),
  forgotPwdError:'',
  resetPwdError:'',
  libInitialPaswrdError:'',
  libInitialPaswrd:false,
  authErrMsg: '',
  forgotPwd:'',
  resetPwd:false,
  configData:[]
};

export const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      state.forgotPwdformState = resetState();
      state.resetPwdformState = resetState();
      state.libInitialPaswrdformState = resetState();
      state.forgotPwdError = [];
      state.resetPwdError = [];
      state.libInitialPaswrdError = '';
      state.forgotPwd = '';
      state.resetPwd = false;
      state.libInitialPaswrd = false;
      state.configData = []
      return state;
    },
    clearErrorMsg: (state) => {
      state.authErrMsg = '';
      state.forgotPwdError = '';
      state.resetPwdError = '';
      state.libInitialPaswrdError = '';
      state.formState = resetState();
      return state;
    },
    clearUser: (state) => {
      state.appUser = null;
      state.showSpotLight = false;
      return state;
    },
    clearResetFormstate: (state) => {
      state.resetPwdformState = resetState();
      state.libInitialPaswrdformState = resetState();
      return state;
    },
    clearAuthInput: (state) => {
      state.authInput = null;
      return state;
    },
    clearForgotPwdRequest: (state) => {
      state.forgotPwdRequest = null;
      return state;
    },
    clearUserActions: (state) => {
      state.isUserBlocked = false;
      state.isUserRemoved = false;
      return state;
    },
    resetAccountSettingsFormState: (state) => {
      return {
        ...state,
        accountSettingsFormState: resetState()
      };
    },
    clearRoutPath: (state) => {
      state.routPathName = "";
      return state;
    },
  },
  extraReducers: {
    [checkEmail.fulfilled.toString()]: (state, { payload }) => {
      state.appUser = payload;
      state.libLogUser = false
      state.showSpotLight = payload?.libraryData?.length > 0?payload?.libraryData[0]?.DefaultToSpotlight:false
      state.authErrMsg = payload?.error !== undefined ? payload : [];
      AppStateService.storeState("userCatalog", payload?.libraryData[0]?.NatureId)
      AppStateService.storeState("CountryCode", payload?.libraryData[0]?.CountryCode)
      if (state.appUser) {
        state.formState = fulfilledState();
      } else {
        state.formState = errorState(payload.errorMsg);
      }
      return state;
    }
    ,
    [checkEmail.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [checkEmail.rejected.toString()]: (state) => {
      state.formState = rejectedState();
    },
    [checkLibrarianEmail.fulfilled.toString()]: (state, { payload }) => {
      state.appUser = payload;
      state.showSpotLight = payload?.libraryData?.length > 0?payload?.libraryData[0]?.DefaultToSpotlight:false
      state.libLogUser = payload?.libLogin
      state.authErrMsg = payload?.error !== undefined ? payload : [];
      AppStateService?.storeState("userCatalog", payload?.error !== undefined ? '' : payload?.libraryData[0]?.NatureId)
      AppStateService.storeState("CountryCode", payload?.error !== undefined ? '' :payload?.libraryData[0]?.CountryCode)
      if (state.appUser) {
        state.formState = fulfilledState();
      } else {
        state.formState = errorState(payload.errorMsg);
      }
      return state;
    }
    ,
    [checkLibrarianEmail.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [checkLibrarianEmail.rejected.toString()]: (state) => {
      state.formState = rejectedState();
    },
    [SingleSignOn.fulfilled.toString()]: (state, { payload }) => {
      state.appUser = payload;
      state.showSpotLight = payload?.libraryData?.length > 0?payload?.libraryData[0]?.DefaultToSpotlight:false
      state.libLogUser = false
      AppStateService.storeState("userCatalog", payload?.libraryData[0]?.NatureId)
      AppStateService.storeState("CountryCode", payload?.libraryData[0]?.CountryCode)
      if (state.appUser) {
        state.formState = fulfilledState();
      } else {
        state.formState = errorState(payload.errorMsg);
      }
      return state;
    }
    ,
    [SingleSignOn.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [SingleSignOn.rejected.toString()]: (state) => {
      state.formState = rejectedState();
    },
    [logOut.fulfilled.toString()]: (state, { payload }) => {
      if (payload) {
        state.appUser = null;
        state.showSpotLight = false
        state.formState = fulfilledState();
      }
      return state;
    },
    [logOut.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [fetchUsers.fulfilled.toString()]: (state, { payload }) => {
      state.users = payload;
      if (state.users) {
        state.formState = fulfilledState();
      } else {
        state.formState = errorState(payload.errorMsg);
      }
      return state;
    }
    ,
    [fetchUsers.pending.toString()]: (state) => {
      state.formState = pendingState();
    },
    [fetchUsers.rejected.toString()]: (state) => {
      state.formState = rejectedState();
    },
    [getNewAppData.fulfilled.toString()]: (state, { payload }) => {
      state.appVersion = payload;
      return state;
    },

    [forgotPassword.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.forgotPwd = payload
        state.formState = fulfilledState();
      }
      return state;
    },
    [forgotPassword.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [forgotPassword.rejected.toString()]: (state, { payload }) => {
      state.forgotPwdError = payload;
      state.formState = rejectedState();
      return state;
    },

    [resetPassword.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.resetPwdformState = errorState(payload.errorMsg);
      } else {
        state.resetPwd = payload
        state.resetPwdformState = fulfilledState();
      }
      return state;
    },
    [resetPassword.pending.toString()]: (state) => {
      state.resetPwdformState = pendingState();
      return state;
    },
    [resetPassword.rejected.toString()]: (state, { payload }) => {
      state.resetPwdError = payload;
      state.resetPwdformState = rejectedState();
      return state;
    },

    [libraryInitialPassword.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.libInitialPaswrdformState = errorState(payload.errorMsg);
      } else {
        state.libInitialPaswrd = payload
        state.libInitialPaswrdformState = fulfilledState();
      }
      return state;
    },
    [libraryInitialPassword.pending.toString()]: (state) => {
      state.libInitialPaswrdformState = pendingState();
      return state;
    },
    [libraryInitialPassword.rejected.toString()]: (state, { payload }) => {
      state.libInitialPaswrdError = payload;
      state.libInitialPaswrdformState = rejectedState();
      return state;
    },

    [fetchConfigData.fulfilled.toString()]: (state, { payload }) => {
      state.configData = payload
      return state;
    },

    [getRoutPathName.fulfilled.toString()]: (state, { payload }) => {
      state.routPathName = payload
      return state;
    },
  },
});

export const { clearState, clearUser,clearErrorMsg,clearResetFormstate,clearRoutPath } = UserSlice.actions;
export const userStateSelector = (state: RootState) => state.user;
export default UserSlice.reducer;
