import React, { useState } from 'react'
import { Divider, Row, Col, Dropdown, Menu } from "antd";
import { PurchaseTable } from "./purchaseTable";
import "./purchase.less";
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cameFromPurchaseDetail, getPurchaseDetails, resizeDetailsCols } from '../../../../redux/actions/purchases/purchasesActions';
import { clearPurchaseDetailList, PurchaseStateSelector } from '../../../../redux/reducers/purchases/purchases';
import { purchaseDetailsCol } from './tableColumns';
import { AppRoutes } from '../../../router/appRoutes';
import { searchPayloadForAuthor } from '../../../../utility/searchUtil';
import { getSearchString, onBackFillForm } from '../../../../redux/actions/search/searchActions';
import { userStateSelector } from '../../../../redux/reducers/user/userReducer';
import { asteriskIcon, downIcon, editIcon, list } from '../../../images';
import PaginationTag from '../../stateless/common/pagination';
import { Page } from '../../../constants/constants';
import { SideBarStateSelector, retainPageSize } from '../../../../redux/reducers/app/sideBarReducer';

const PurchaseDetails = () => {
  const location = useLocation<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { appUser } = useSelector(userStateSelector);
  const { retainedPageSize } = useSelector(SideBarStateSelector);
  const { purchaseDetailsData,PurchaseformState, detailsPurchaseColState } = useSelector(PurchaseStateSelector);
  const { purchaseDetail,activeTab } = location.state;
  const [allList, setAllList] = useState<any>([]);
  var currency = appUser &&  appUser.libraryData? appUser.libraryData[0]?.CurrencySymbol : "";
  const [pageSize, setPageSize] = useState<number>(retainedPageSize ?? 100)
  const [start, setStart] = useState<number>(Page.DEFAULT_START);
  const [lastCount , setLastCount] = useState(start+pageSize-1)
  const [detailsPurchaseResize,setDetailsPurchaseResize] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1)
  const optionList = [10,20,50,100,250,500]
  const goBack = () => {
    history.push(AppRoutes.PURCHASES);
    dispatch(cameFromPurchaseDetail({
      cameFromPurchaseDetail: true,
      openTab:activeTab
    }))
    dispatch(clearPurchaseDetailList())
  };
  const changePageSize = (size: number) => {
    setCurrentPage(1)
    setStart(Page.DEFAULT_START)
    dispatch(retainPageSize(size))
    setPageSize(size)
  }
  const onPageChange = (page: any,pageSize: any) => {
    const pageStart = (page-1)*pageSize+1;
    setStart(pageStart)
    setCurrentPage(page)
  }
  useEffect(()=>{
    dispatch(
      getPurchaseDetails({
        id:`${purchaseDetail?.PurchaseOrderId}`,
        buyer_id: "",
        lib_id:`${purchaseDetail?.LibraryId}`,
        status: 0,
        record_Count: pageSize,
        record_Start: start,
        sort_Direction: "",
        sort_Field: "",
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[purchaseDetail,pageSize,start])

  useEffect(()=>{
    setAllList(purchaseDetailsData)
  },[purchaseDetailsData])
  useEffect(()=>{
    let end = start+pageSize-1
    if(purchaseDetail.NumberOfTitles < end ){
      setLastCount(purchaseDetail.NumberOfTitles        )
    }
    else{
      setLastCount(end)
    }
  },[start,pageSize,purchaseDetail])

  useEffect(() => {
    if(detailsPurchaseResize.length > 0){
      dispatch(resizeDetailsCols(detailsPurchaseResize))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsPurchaseResize])

  const getAuthor =(book:any)=>{
    searchPayloadForAuthor(book?.Authors, appUser).then((data: any)=>{
      dispatch(getSearchString(data.strinParam))
      dispatch(onBackFillForm(data.searchParam))
      var advanceState: any = {
        searchDetails: data?.state?.searchDetails,
        gaString: {
          gaData: data?.strinParam,
        },
      };
      history.push(AppRoutes.SEARCHRESULTS, advanceState)
    })
  }

  const pageOptions = (
    <Menu className="header-dropdown">
      <Menu.Item key='page-size'>
        <Row>
          <Col style={{width : '20px'}}><img style={{width:"14px",height:"14px"}} src={list} alt=" "/></Col>
          <Col flex='auto' style={{ paddingLeft : '5px'}}>
            Page Size
          </Col>
        </Row>
      </Menu.Item>
      {optionList.map((data: number, index: number) => {
        return (
          <Menu.Item key={index} onClick={() =>changePageSize(data)}>
            <Row>
            <Col style={{width : '20px'}}>
            {pageSize === data? <img style={{width:"14px",height:"14px"}} src={asteriskIcon} alt="*"/> : ' ' }
            </Col>
            <Col style={{ paddingLeft : '5px'}}>
              {data}
            </Col>
            </Row>
          </Menu.Item>
        );
      })}
   
    </Menu>
  );


  return (
    <div>
       <button className="back-button" onClick={goBack}>{"<"} Back</button>
      <div className='purchaseOuterBox' >
        <div className='purchaseHeading'>Purchase- {purchaseDetail.PurchaseOrderId}</div>
        {/* <div>Purchase Date</div> */}
      </div>
      {/* <div className='dateCover'>
          <div>Date</div>
      </div> */}
      <Row >
        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="bold-titles-line-pointer">Buyer:</Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} className="bold-titles-grey">{purchaseDetail?.BuyerId}</Col>
        <Col xs={4} sm={4} md={3} lg={3} xl={3} className="bold-titles-line-pointer">PO Reference:</Col>
        <Col xs={6} sm={6} md={7} lg={7} xl={7} className="bold-titles-grey">{purchaseDetail?.ExternalRefId !== ""?purchaseDetail?.ExternalRefId:" "}</Col>
      </Row>
      <Row >
        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="bold-titles-line-pointer">Number of items:</Col>
        <Col xs={6} sm={6} md={6} lg={6} xl={6} className="bold-titles-grey">{(purchaseDetail?.NumberOfCopies)}({purchaseDetail?.NumberOfTitles} purchases/0 presale)</Col>
        <Col xs={4} sm={4} md={3} lg={3} xl={3} className="bold-titles-line-pointer">Total Amount:</Col>
        <Col xs={6} sm={6} md={7} lg={7} xl={7} className="bold-titles-grey">{currency}{(purchaseDetail?.TotalPurchaseAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}({currency}{(purchaseDetail?.TotalPurchaseAmount)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g,',')} purchase/{currency}0 presale)</Col>
        <Col> 
         <div className="editor-Button" >
                <Dropdown
                    overlay={pageOptions}
                    trigger={["click"]}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                  >
                  <button className="button-type editor">
                    <img src={editIcon} className="edit-icon" alt="" /> Editor &nbsp; &nbsp;
                    <img src={downIcon} className="down-icon" alt="" />
                  </button>
                </Dropdown>
              </div>
        </Col>
      </Row>
      <Divider className="carts-divider-top" />
        <div className='purchaseOuterBox'>
          <div>{start}-{lastCount} of {purchaseDetail.NumberOfTitles}</div>
        </div>
      <Divider className="carts-divider-top" />
      <PurchaseTable needRowData={false} data={allList} classN="purchaseTable left-sorter" columns={purchaseDetailsCol(history,getAuthor,currency)} formState={PurchaseformState} setColumnResize={setDetailsPurchaseResize} resizeCol={detailsPurchaseColState} rowKey={"PurchaseOrderDetailId"} />
      {!PurchaseformState?.loading && purchaseDetail.NumberOfTitles > (lastCount-start+1)?
      <>
      <div className="LibraryStuffPagination">
      <PaginationTag total={purchaseDetail.NumberOfTitles} current={currentPage} defaultPageSize={pageSize} onChange={onPageChange}/>
      </div>
      <Col >   
      <span className="searchTitleStyle">{start}-{lastCount} of {purchaseDetail.NumberOfTitles} Titles</span>
    </Col>
    </>      
       : null }
      </div>
  )
}

export default PurchaseDetails

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function setSearchByAuthor(state: any) {
    throw new Error('Function not implemented.');
  }
