import React, { useEffect } from "react";
import { Row, Col } from "antd";
import "./subGroups.less";
import "../../../stateless/common/customScrollBar/customScrollStyle.less";
import { useHistory, useLocation } from "react-router-dom";
import { CategoryTiles } from "../../../stateless/groupTiles";
import { fetchCategories } from "../../../../../redux/actions/categories/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { categorystateSelector } from "../../../../../redux/reducers/categories/categoryReducer";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import FeaturedList from "../../../../models/featuredList/category";
import { GroupArchived } from "../../../../constants/enums";


export const CategoryList = (props: any) => {
  const location = useLocation<any>();
  const history = useHistory();
  const dispatch = useDispatch()
  const backText = "< Back";
  const { group } = location.state;
  const { appUser } = useSelector(userStateSelector);
  const { categories, formState } = useSelector(categorystateSelector);

  useEffect(()=>{
    var libID =
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
    dispatch(
      fetchCategories({
        groupId: group?.FeaturedListGroupId,
        libraryId: libID,
      })
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const goBack = () => {
    history.goBack();
  };

  return (
    <SpinnerLoader loading={formState.loading} style={{ height: '50%' }}>
    <div className="categories">
    <button className="back-button" onClick={goBack}>
        {backText}
      </button>
    <div className="scrollContent scrollStyle groups">
      <Row>
        <Col>
          <span className="category-title">{group?.FeaturedListGroupName}</span>
        </Col>
      </Row>
      <Row gutter={[24,0]}>
        {categories?.map((data: FeaturedList, index: number) => {
              return (
                data?.FeaturedList?.Archived === GroupArchived.FALSE ?
                <Col
                  xs={8}
                  sm={8}
                  md={6}
                  lg={5}
                  xl={4}
                  xxl={3}
                  key={data?.FeaturedListId}
                  className="category-col"
                >
                  <CategoryTiles category={data?.FeaturedList} appUser={appUser}/>
                </Col>
                :
                null
              );
            })
          }
      </Row>
    </div>
    </div>
    </SpinnerLoader>
  );
};
