import React, { useEffect } from "react";
import { Row, Col} from "antd";
import "./groupList.less";
import "../../../stateless/common/customScrollBar/customScrollStyle.less";
import { AppRoutes } from "../../../../router/appRoutes";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearState } from "../../../../../redux/reducers/categories/categoryReducer";
import { CategoryRow } from "./categoryRows";
import moment from "moment";
import { clearSearchScroll } from "../../../../../redux/reducers/search/searchReducer";
import { clearSelectedChkBox } from "../../../../../redux/reducers/books/bookListReducer";
import { setGlobalCopy, setSelectedTitles } from "../../../../../redux/actions/books/bookActions";

export const GroupList = (props: any) => {
  const { group, appUser } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearSearchScroll())
    dispatch(clearSelectedChkBox())
    dispatch(setSelectedTitles([]))
    dispatch(setGlobalCopy(1))
    return () => {
      dispatch(clearState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const selectGroup = (selectedGroup: any) => {
    const state: any = {
      group: selectedGroup,
    };
    history.push(AppRoutes.CATEGORIES, state);
  };

  var dateFormat = appUser?.libraryData
    ? appUser.libraryData[0]?.DateFormat.toUpperCase()
    : "";

  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr).tz("Etc/GMT").format(dateFormat);
    return currentDate;
  };
  const getDateDiff = (date: any) => {
    var currentDte = moment(new Date()).format(dateFormat);
    var given = date;
    const diffDays = moment(currentDte).diff(given, "days");
    return diffDays;
  };

  return (
    <div>
      <Row className="category" key={`${group.FeaturedListGroupName}`}>
        <Col
          xs={20}
          sm={20}
          md={20}
          lg={22}
          xl={22}
          className="category-column"
        >
          <span className="category-title">{group.FeaturedListGroupName}</span>
          {
            !group?.IsShown ?
          group?.IsNew ? (
            <span className="FeatureListNewTag">NEW</span>
          ) : getDateDiff(dateModify(group?.ChangeDateTime)) <= 7  ? (
            <span className="spotlightDrawerNewTag">NEW</span>
          ) : null
          : null
        }
        </Col>
        {group.Items && group.ItemCount ? (
          <Col xs={4} sm={4} md={4} lg={2} xl={2}>
            <span className="view-all-link" onClick={() => selectGroup(group)}>
              View All
            </span>
          </Col>
        ) : null}
      </Row>
      <Row className="tiles-container" gutter={[20, 0]}>
        <CategoryRow categoryList={group.Items} appUser={appUser} />
      </Row>
    </div>
  );
};
