import { Modal, Row, Col, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchShelvByShelvId,
  onSelectShelve,
  updateCartShelvName,
} from "../../../../../redux/actions/shelves/shelfAction";
import {
  ShelfstateSelector,
  clearupdateShlvFormState,
} from "../../../../../redux/reducers/shelves/shelfReducer";
import Button from "../../../stateless/common/button";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
const { TextArea } = Input;

export const EditModal = (props: any) => {
  const dispatch = useDispatch();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const {
    isAutoModalVisible,
    setAutoModalVisible,
    shelvData,
    shelvDetailsById,
    setRowUnselect,
  } = props;
  const [updateFields, setUpdateFields] = useState<any>({
    shelveName: "",
    shelveDescription: "",
  });

  const { selectedShelve } = useSelector(ShelfstateSelector);

  const { updateShlvFormState, shelvIdFormState } =
    useSelector(ShelfstateSelector);
  useEffect(() => {
    if (updateFields.shelveName !== "") {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
    updateFields?.shelveDescription?.length > 100
      ? setDescriptionError(true)
      : setDescriptionError(false);
  }, [updateFields]);
  useEffect(() => {
    setUpdateFields({
      ...updateFields,
      shelveName: shelvData?.ShelfName,
      shelveDescription: shelvData?.Description,
    });
    if (
      isAutoModalVisible &&
      shelvData?.LibraryId !== undefined &&
      shelvData?.ShelfId !== undefined
    ) {
      var payloadB = {
        libraryId: shelvData?.LibraryId,
        catShelfId: shelvData?.ShelfId,
      };
      dispatch(fetchShelvByShelvId(payloadB));
      setRowUnselect(false);
    }
    if (!isAutoModalVisible) {
      setRowUnselect(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shelvData, isAutoModalVisible]);

  const handleDetails = (e: any) => {
    setUpdateFields({
      ...updateFields,
      [`${e.target.name}`]: e?.target?.value,
    });
  };

  const handelSubmit = () => {
    if (!btnDisable && !descriptionError) {
      var payload = {
        reqBody: {
          ...shelvDetailsById,
          ShelfName: updateFields?.shelveName,
          Description: updateFields?.shelveDescription,
        },
        getShelv: {
          libraryId: shelvData?.LibraryId,
          catShelfId: shelvData?.ShelfId,
          recordCount: 500,
          recordStart: 0,
        },
      };
      dispatch(updateCartShelvName(payload));
      if (selectedShelve.ShelfId === shelvDetailsById.ShelfId) {
        dispatch(onSelectShelve(payload.reqBody));
      }
    }
  };

  const handleCancel = () => {
    setAutoModalVisible(false);
    setBtnDisable(false);
    setDescriptionError(false);
    setUpdateFields({
      ...updateFields,
      shelveName: shelvData?.ShelfName,
      shelveDescription: shelvData?.Description,
    });
  };

  useEffect(() => {
    if (updateShlvFormState?.errorMessage) {
      message.error({
        content: updateShlvFormState?.errorMessage,
        key: "notificationMessage",
        duration: 4,
      });
      dispatch(clearupdateShlvFormState());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShlvFormState]);

  return (
    <Modal
      visible={isAutoModalVisible}
      centered
      wrapClassName="autoShelvesModal"
      onCancel={handleCancel}
    >
      <SpinnerLoader loading={shelvIdFormState?.loading}>
        <div className="autoShelvesTitle">Edit Shelf</div>

        <div className="mb-6 mt-6">
          <Row>
            <Col span={24}>
              <Input
                placeholder="Shelf name"
                className={`shelfTitle ${
                  btnDisable ? "manualShelveField" : ""
                }`}
                value={updateFields.shelveName}
                name="shelveName"
                onChange={handleDetails}
              />
              {btnDisable ? (
                <div className="manualShelveError">
                  Please enter a Shelf Name
                </div>
              ) : null}
            </Col>
          </Row>
        </div>

        <div className="mt-6 mb-6">
          <Row>
            <Col span={24}>
              <TextArea
                className={`scrollStyle isbnSearchScroll shelfDescription ${
                  descriptionError ? "manualShelveField" : ""
                }`}
                maxLength={100}
                placeholder="Description"
                autoSize={{ minRows: 3, maxRows: 5 }}
                onChange={handleDetails}
                name="shelveDescription"
                value={updateFields.shelveDescription}
              />
              {descriptionError && (
                <div className="manualShelveError">
                  Cannot enter more than 100 characters
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className="editDiv">
          <Button
            type={`primary savebtn ${
              btnDisable || descriptionError ? "editShelfSaveChange" : ""
            }`}
            htmlType="submit"
            onClick={handelSubmit}
          >
            Save Changes
          </Button>
          <Button type="editCancelBtn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </SpinnerLoader>
    </Modal>
  );
};
