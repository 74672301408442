import React from 'react'
import {Form} from 'antd'
import './emailLoginForm.less'
import AuthLayout from '../../../layouts/authLayout'
import CustomInput from '../../../common/inputField/customInput'
import Button from '../../../common/button'
import CustomPassword from '../../../common/inputField/customPassword'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../../../router/appRoutes'


export const EmailLoginForm = (props: any) => {
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const onSubmit = (data: any) => {
    if (props.loginCallback) {
      props.loginCallback(data)
    }
  }

  const onChangeHandle = (e:any) => {
    return e.target.value
  }


  return (
    <AuthLayout>
      <div className='loginBox'>
        <span className='loginHed'>Log In</span>
        <div className='loginText'>Please enter your email address and password.</div>
        <Form
          {...layout}
          name='auth'
          className='login-form'
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
          requiredMark={false}
        >
          <CustomInput
            customLabelClass={`loginLabelName`}
            labelSubName='Email'
            labelName='email'
            name='email'
            rules={[
              {
                required: true,
                message: 'Please enter a valid email address',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
            ]}
            onChange={onChangeHandle}
          />
          <CustomPassword
            customLabelClass={`loginLabelName`}
            labelSubName='Password'
            labelName='password'
            name='password'
            rules={[
              {
                required: true,
                message: 'Please enter a valid password',
              }
            ]}
            onChange={onChangeHandle}
          />
          <Form.Item className='formItemInput mb-0'>
            <Button type='primary loginButton' htmlType='submit'>
              Log In
            </Button>
            <div>
            <Link to={AppRoutes.FORGOTPWD} className="forgetPassword">Forgot password</Link>
            </div>        
          </Form.Item>
        </Form>
        <div className="version-info">{props?.appVersion !== "null"?props?.appVersion.replace(/"/g, ""):""}</div>
      </div>
    </AuthLayout>
  )
}
