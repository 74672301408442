import React from "react";
import { Col } from "antd";
import "./groupList.less";
import "../../../stateless/common/customScrollBar/customScrollStyle.less";
import { CategoryTiles } from "../../../stateless/groupTiles";
import { GroupArchived } from "../../../../constants/enums";

export const CategoryRow = (props: any) => {
  const { categoryList,appUser } = props;
  return (
   (categoryList && categoryList.length) ? 
   categoryList.filter((d:any) =>(d?.FeaturedList?.CoverArtDetails?.length > 0)).map((data: any) => {
        return (
          data?.FeaturedList?.Archived === GroupArchived.FALSE ?
          <Col
            xs={12}
            sm={8}
            md={7}
            lg={5}
            xl={4}
            xxl={3}
            key={`${data?.FeaturedListId}`}
          >
            <CategoryTiles category={data?.FeaturedList} appUser={appUser} />
          </Col>
          :
          null
        );
      })
  :
  null
  )  
};
