import User from "../web/models/user/user";
import { AppRoutes } from "../web/router/appRoutes";
import { AppStateStores } from "../web/services/storage/appStateService";
import { PortalTypeKey, PortalType} from "../web/constants/constants";
import { message } from "antd";
import moment from "moment";




enum StorageKeys {
  USER = "@@storageKeys/USER",
  ACCESS_TOKEN = "@@storageKeys/ACCESS_TOKEN",
}

export const loggedInRedirectRoute = (showSpotLight: boolean,appUser:any) => {
  if (showSpotLight) {
    if(appUser?.IsOnlyPatronMgr){
      return AppRoutes.PATRONS;
    }else{
      return AppRoutes.SPOTLIGHTLANDING;
    }
   
  } else {
    return AppRoutes.LANDING;
  }
};

export const isLoggedInAppUser = (): boolean => {
  let loggedIn = false;
  const user = localStorage.getItem(StorageKeys.USER);
  if (user) {
    const objUser = JSON.parse(user) as User;
    // eslint-disable-next-line eqeqeq
    loggedIn = objUser && objUser.userId !="";
  }
  return loggedIn;
};

export const setAppUser = (data: string): void => {
};

export const logoutAppUser = (): void => {
  localStorage.removeItem(AppStateStores.APP_STATE);
};

export const getAccessToken = (): string | null => {
  const bearerToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  return bearerToken;
};

export const setAccessToken = (data: string): void => {
  localStorage.setItem(StorageKeys.ACCESS_TOKEN, data);
};


export const concatNames = (firstName: string = "", lastName: string = "", middleName?: string) => {
  let name = "";
  if (firstName) {
    name = firstName.trim();

    if (middleName) {
      name = `${name} ${middleName}`.trim();
    }

    if (lastName) {
      name = `${name} ${lastName}`.trim();
    }
  } else if (middleName) {
    name = middleName.trim();

    if (lastName) {
      name = `${name} ${lastName}`.trim();
    }
  } else if (lastName) {
    name = lastName.trim();
  }
  return name.trim();
};

export const replaceAll = (searchRegExp: RegExp, baseText: string,  replacement: string): string => {
  const result = baseText.replace(searchRegExp, replacement);
  return result;
};


export const cameFromOrganisation = () => {
  if(window.location.search.includes(`${PortalTypeKey.CLIENT_PORTAL}`)) return true;
  else return false;
}

export const urlModification = (url: string, ) =>
{

  let modifiedUrl = url;
  if(cameFromOrganisation()) {
    if(url && url.includes('?')) {
      modifiedUrl = `${url}&${PortalTypeKey.CLIENT_PORTAL}=${PortalType.CLIENT_PORTAL}`;
    } else {
      modifiedUrl = `${url}?${PortalTypeKey.CLIENT_PORTAL}=${PortalType.CLIENT_PORTAL}`;
    }
  }

  return modifiedUrl;
}

export const showError = (data: any, ) =>{
  if(data?.Message !== "" && data?.Message !== undefined){
   return message.error({
      content:data?.Message,
      // key: "notificationMessage",
      duration: 4,
    })
  }else if(data?.ErrorMessage !== "" && data?.ErrorMessage !== undefined){
   return message.error({
      content:data?.ErrorMessage,
      // key: "notificationMessage",
      duration: 4,
    })
  }else{
    return undefined
  }
  }

  export const removeExtraCharacter=(txt:string)=>{
    const text = txt?txt:''
    if(text?.split('')[0] !== '#'){
     const editedTxt = text
     return editedTxt
    }else{
     const editedTxt = text?.slice(text.indexOf('#') + 1)
     return editedTxt
    }
  }

  export const formatedDate = (date: any) => {
    const formattedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSSSSSS');
    return formattedDate;
}