import React from 'react'
import { Form } from 'antd'
import './librarianLoginForm.less'
import AuthLayout from '../../../layouts/authLayout'
import CustomInput from '../../../common/inputField/customInput'
import Button from '../../../common/button'
import CustomPassword from '../../../common/inputField/customPassword'


export const LibrarianLoginForm = (props: any) => {
    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    }

    const onSubmit = (data: any) => {
        if (props.loginCallback) {
            props.loginCallback(data)
        }
    }

    const onChangeHandle = (e: any) => {
        return e.target.value
    }


    return (
        <AuthLayout>
            <div className='loginBox'>
                <span className='loginHed'>Librarian Log In</span>
                <div className='loginText'>Please enter your email address and password.</div>
                <Form
                    {...layout}
                    name='auth'
                    className='login-form'
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onSubmit}
                    requiredMark={false}
                >
                    <CustomInput
                        customLabelClass={`loginLabelName`}
                        labelSubName='Login as this librarian'
                        labelName='librarianemail'
                        name='librarianemail'
                        placeholder="Email"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a valid librarian email address',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid librarian email address',
                            },
                        ]}
                        onChange={onChangeHandle}
                    />
                    <CustomInput
                        customLabelClass={`loginLabelName`}
                        labelSubName='Email'
                        labelName='username'
                        name='username'
                        placeholder="Email"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a valid email address',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address',
                            },
                        ]}
                        onChange={onChangeHandle}
                    />
                    <CustomPassword
                        customLabelClass={`loginLabelName`}
                        labelSubName='Password'
                        labelName='password'
                        name='password'
                        placeholder="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a valid password',
                            }
                        ]}
                        onChange={onChangeHandle}
                    />
                    <Form.Item className='formItemInput mb-0'>
                        <Button type='primary loginButton' htmlType='submit'>
                            Log In
                        </Button>
                    </Form.Item>
                </Form>
                <div className="version-info">{props?.appVersion !== "null"?props?.appVersion.replace(/"/g, ""):""}</div>
            </div>
        </AuthLayout>
    )
}