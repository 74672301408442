/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Divider } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../../router/appRoutes";
import { UseGAEventTracker } from "../../../../../utility/useGAEventTracker";

export const FeatureBox = (props: any) => {
  const {
    data,
    className,
    header,
    // dateFormat,
    // endDate,
    handelCallBackFetureList,
    activeListId,
  } = props;
  const [selectedFeatureList, setSelectedFeatureList] =
    useState<number>(activeListId);
  const history = useHistory();

  // const dateModify = (date: any) => {
  //   const myStr = date.substr(0, 10);
  //   const currentDate = moment(myStr).tz("Etc/GMT").format(dateFormat);
  //   return currentDate;
  // };

  useEffect(() => {
    if (data?.length > 0 && activeListId > 0) {
      setSelectedFeatureList(activeListId);
    } else {
      if (data?.length) {
        setSelectedFeatureList(data[0]?.FeaturedListId);
      }
    }
  }, [data, activeListId]);

  const handelSelectedlist = (list: any, index: any) => {
    var newIndex = { indexNo: index };
    handelCallBackFetureList({ ...list, ...newIndex }); 
    setSelectedFeatureList(list?.FeaturedListId);
    UseGAEventTracker(
      "Spotlight featured lists",
      "featured lists selected",
      `The featured list selected in spotlight is ${list.FeaturedListName}`,
      NaN
    );
  };

  const handelMoreList = () => {
    history.push(AppRoutes.LANDING);
  };

  return (
    <div className={className}>
      <h1 className="feature-heading">{header}</h1>
      <Row>
        <Col span={24}>
          <ul className="featureListBox scrollStyle">
            {data?.map((items: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <li
                    className={`featureList ${
                      selectedFeatureList === items?.FeaturedListId
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => handelSelectedlist(items, index)}
                  >
                    {items?.FeaturedListName}
                    <br />
                    {/* {items?.CreatedDateTime
                      ? dateModify(items?.CreatedDateTime)
                      : ""}
                    -{endDate ? dateModify(endDate) : ""} */}
                  </li>
                  <Divider className="featureList-divider" />
                </React.Fragment>
              );
            })}
          </ul>
        </Col>
      </Row>
      <div className="feturBoxFooter">
        <h3 className="featureBocCliclList" onClick={handelMoreList}>
          More Curated Lists{" "}
          <span>
            <ArrowRightOutlined />
          </span>
        </h3>
      </div>
    </div>
  );
};
