import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import { RootState } from "../../store/rootReducer";
import { getCirculationSummary, setTab } from "../../actions/insight/insightActions";
import { InsightsState } from "../../states/insight/insightState";

const initialState: InsightsState = {
  insightData: [],
  insightError:[],
  formState:resetState(),
  tabVal:"1"
};

export const InsightSlice = createSlice({
  name: "insights",
  initialState: initialState,
  reducers: {
    clearInsightState: (state) => {
      state.insightData = []
      state.formState = resetState()
      state.insightError = []
      return state;
    },
    clearInsightError: (state) => {
      state.insightError = []
      return state;
    },
   resetReportTab:(state) => {
    state.tabVal = "1"
    return state;
  },
  },
  extraReducers: {
    [getCirculationSummary.fulfilled.toString()]: (state, { payload }) => {
      if(payload){
        state.insightData = payload
      };
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
      }
      return state;
    },
    [getCirculationSummary.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [getCirculationSummary.rejected.toString()]: (state, { payload }) => {
      state.insightError = payload
      state.formState = rejectedState(payload);
      return state;
    },
    [setTab.fulfilled.toString()]: (state, { payload }) => {
      state.tabVal = payload
      return state;
    },

  },
});

export const {clearInsightState,clearInsightError,resetReportTab} = InsightSlice.actions;
export const insightStateSelector = (state: RootState) => state.insights;
export default InsightSlice.reducer;