import React from 'react'
import {Form} from 'antd'
import './emailLoginForm.less'

import AuthLayout from '../../stateless/layouts/authLayout'
import CustomInput from '../../stateless/common/inputField/customInput'
import Button from '../../stateless/common/button'
import { Link } from 'react-router-dom'
import { AppRoutes } from '../../../router/appRoutes'

export const ForgetPassword= (props: any) => {
  const {loginCallback} = props
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const onSubmit = (data: any) => {
    loginCallback(data)
  }

  const onChangeHandle = (e:any) => {
    return e.target.value
  }


  return (
    <AuthLayout>
      <div className='loginBox'>
        <span className='loginHed'>Forgot Password</span>
        <div className='loginText'>Please enter your user id/email. You  will receive an email with further instructions to reset your password.</div>
        <Form
          {...layout}
          name='auth'
          className='login-form'  
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
          requiredMark={false}             
        >
          <CustomInput
            customLabelClass={`loginLabelName`}
            labelSubName='Email'
            labelName='email'
            name='email'         
            onChange={onChangeHandle}
            rules={[
              {
                required: true,
                message: 'Please enter a valid email address',
              },
              {
                type: 'email',
                message: 'Please enter a valid email address',
              },
            ]}
          />         
          <Form.Item className='formItemInput mb-0'>
            <Button type='primary loginButton' htmlType='submit'>
              Reset Password
            </Button>
            <div>
            <Link to={AppRoutes.LOGIN} className="forgetPassword">Back to Login</Link>
            </div>   
          </Form.Item>
        </Form>
        <div className="version-info">{props?.appVersion !== "null"?props?.appVersion.replace(/"/g, ""):""}</div>
      </div>
    </AuthLayout>
  )
}
