import React from 'react'
import { Row, Modal,Col } from "antd";
import SpinnerLoader from "../../../../stateless/common/spinnerLoader";

const SelectTitleModel = (props: any) => {
  const { showModel, setShowModel} = props

  const onCancelModel = () => {
    setShowModel(false)
  }

  return (
    <Modal
      wrapClassName="purchase-modal"
      visible={showModel}
      onCancel={onCancelModel}
      footer={
        <SpinnerLoader loading={false} className="moveCart">
        <Row justify="center">
          <Col span={24}>
            <span onClick={onCancelModel} className="moveCartCancel">
              Cancel
            </span>
          </Col>
        </Row>
        </SpinnerLoader>
      }
    >
      <SpinnerLoader loading={false}>
      <Row>
          <div className='moveCartBefSelect'>
            Please select titles before selecting the destination cart
          </div>
      </Row>
      </SpinnerLoader>
    </Modal>
  )
}

export default SelectTitleModel