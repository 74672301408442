import { createAsyncThunk } from "@reduxjs/toolkit";
import ActiveCart from "../../../web/models/cart/activeCart";
import { CartService } from "../../../web/services/carts/cartService";
import { expirationRules } from "../expirationRules/expirationAction";

const cartSrv = new CartService();

export const fetchCartsOnLandingPage = createAsyncThunk(
  "api/fetchCartsOnLandingPage",
  async (payload : any, thunkAPI) => {
    try {
      const data = await cartSrv.fetchCartsById(payload)
      if (data && data.length) {
        thunkAPI.dispatch(expirationRules())
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchCarts = createAsyncThunk(
    "api/fetchCarts",
    async (payload : any, thunkAPI) => {
      try {
        const data = await cartSrv.fetchCartsById(payload)
        if (data && data.length) {
          thunkAPI.dispatch(expirationRules())
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );


export const addItemtoCart = createAsyncThunk(
  "api/addtocarts",
  async (_args : any, thunkAPI) => {
    try {
      const data = await cartSrv.addtoCart(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
//save addItemtoCart array
export const activeCart = createAsyncThunk(
  "api/activeCart",
  async (_args : ActiveCart | any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const deleteCartbyId = createAsyncThunk(
  "api/deleteCart",
  async (_args : any, thunkAPI) => {
    try {
      const data = await cartSrv.deleteCart(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchBuyerCart = createAsyncThunk(
  "api/fetchBuyerCart",
  async (_arg : any, thunkAPI) => {
    try {
      const data = await cartSrv.fetchBuyerCart(_arg)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchBuyerCartById = createAsyncThunk(
  "api/fetchBuyerCartById",
  async (_arg : any, thunkAPI) => {
    try {
      const data = await cartSrv.fetchBuyerCartById(_arg)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchBuyerCartFirst = createAsyncThunk(
  "api/fetchBuyerCartFirst",
  async (_arg : any, thunkAPI) => {
    try {
      const data = await cartSrv.fetchBuyerCart(_arg)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const createNewCart = createAsyncThunk(
  "api/createCart",
  async (_args : any, thunkAPI) => {
    try {
      const data = await cartSrv.createCart(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({CartName: _args.CartName});
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue({error: e.data,CartName: _args.CartName});
    }
  }
);

export const onlyCreateNewCart = createAsyncThunk(
  "api/onlyCreateNewCart",
  async (_args : any, thunkAPI) => {
    try {
      const data = await cartSrv.createCart(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({CartName: _args.CartName});
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue({error: e.data,CartName: _args.CartName});
    }
  }
);

export const updatedNewCart = createAsyncThunk(
  "api/updatedNewCart",
  async (_args : any, thunkAPI) => {
    try {
      const data = await cartSrv.createCart(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({CartName: _args.CartName});
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue({error: e.data,CartName: _args.CartName});
    }
  }
);

export const fetchCartById = createAsyncThunk(
  "api/fetchCartDetails",
  async (_args : any, thunkAPI) => {
    try {
      const data = await cartSrv.fetchCartById(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchSelectedCartById = createAsyncThunk(
  "api/fetchSelectedCartById",
  async (_args: any, thunkAPI) => {
    try {
      const data = await cartSrv.fetchSelectedCartById(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchSelectedViwerCart = createAsyncThunk(
  "api/fetchSelectedViwerCart",
  async (_args: any, thunkAPI) => {
    try {
      const data = await cartSrv.fetchSelectedCartById(_args?.payload)
      if (data) {
        thunkAPI.dispatch(fetchBuyerCart(_args?.fetchData))
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const cancelSelectedCartItems = createAsyncThunk(
  "api/cancelSelectedCartItems",
  async (_args: any, thunkAPI) => {
    try {
      const data = await cartSrv.fetchSelectedCartById(_args)
      if (data) {
        var payload = {
          libId:_args?.LibraryId
        }
        thunkAPI.dispatch(fetchCarts(payload))
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const checkSelectedCartItems = createAsyncThunk(
  "api/checkSelectedCartItems",
  async (_args: any, thunkAPI) => {
    try {
      const data = await cartSrv.fetchSelectedCartById(_args?.removeItem)
      if (data) {
        thunkAPI.dispatch(fetchCartById(_args?.refrehCartData));
        thunkAPI.dispatch(fetchCarts(_args?.fetchCartList))
        return data;
      } else {
        thunkAPI.dispatch(fetchCartById(_args?.refrehCartData));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const setRefreshStatus = createAsyncThunk(
  "api/setRefreshStatus",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const setBuyerDetails = createAsyncThunk(
  "api/setBuyerDetails",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const markReady = createAsyncThunk(
  "api/readyforPurchase",
  async (_args : any, thunkAPI) => {
    try {
      const data = await cartSrv.purchaseCart(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const confirmPurchase = createAsyncThunk(
  "api/confirmPurchase",
  async (_args: any, thunkAPI) => {
    try {
      const data = await cartSrv.confirmPurchase(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchAutoCarts = createAsyncThunk(
  "api/fetchAutoCarts",
  async (libId : number, thunkAPI) => {
    try {
      const data = await cartSrv.fetchAutoCarts(libId)
      if (data && data.length) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data?.ErrorMessage);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const resetAutoCarts = createAsyncThunk(
  "api/resetAutoCarts",
  async (oldData : any, thunkAPI) => {
    try {
      const data = oldData
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const saveAutoCart = createAsyncThunk(
  "api/saveAutoCart",
  async (_args: any, thunkAPI) => {
    try {
      const data = await cartSrv.saveAutoCart(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const addNewRuleAutoCart = createAsyncThunk(
  "api/addNewRuleAutoCart",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const cartPermission = createAsyncThunk(
  "api/cartPermission",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const saveSelectedCartIDs = createAsyncThunk(
  "api/saveSelectedCartIDs",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const saveSelectedCatlogIDs = createAsyncThunk(
  "api/saveSelectedCatlogIDs",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const savedColumns = createAsyncThunk(
  "api/savedColumns",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const resizeColumns = createAsyncThunk(
  "api/resizeColumns",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const selectedCols = createAsyncThunk(
  "api/selectedCols",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const getAutoCArtHistory = createAsyncThunk(
  "api/getAutoCArtHistory",
  async (_args : any, thunkAPI) => {
    try {
      const data = await cartSrv.fetchAutoHistory(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const onApplyingFilters = createAsyncThunk(
  "api/onApplyingFilters",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) { 
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const recentTab = createAsyncThunk(
  "api/recentTab",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) { 
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const resizeHistoryCols = createAsyncThunk(
  "api/resizePreSaleCols",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const cartListFilter = createAsyncThunk(
  "api/cartListFilter",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) { 
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const moveItemtoCart = createAsyncThunk(
  "api/moveItemtoCart",
  async (_args : any, thunkAPI) => {
    try {
      const data = await cartSrv.movetoCart(_args.moveCart)
      if (data === true) {
        thunkAPI.dispatch(fetchCarts(_args.fetchCart))
        thunkAPI.dispatch(fetchCartById(_args.fetchDetails))
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const downloadCartsDetails = createAsyncThunk(
  "api/downloadCartsDetails",
  async (payload : any, thunkAPI) => {
    try {
      const data = await cartSrv.downloadCartsDetails(payload)
      if (data) {
        let blob = new Blob([data], { type: 'application/csv' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        return link.download = 'Report.csv'
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const cartDetailsFilterRemember = createAsyncThunk(
  "api/cartDetailsFilterRemember",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) { 
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const addFeaturedListToCart = createAsyncThunk(
  "api/addFeaturedListToCart",
  async (_args : any, thunkAPI) => {
    try {
      const data = await cartSrv.addFeaturedListToCart(_args)
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const pageRememberCart = createAsyncThunk(
  "api/pageRememberCart",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) { 
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
