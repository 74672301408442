/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Input, message, Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { searchPayloadForAuthor } from "../../../../utility/searchUtil";
import { getSearchString, onBackFillForm } from "../../../../redux/actions/search/searchActions";
import { AppRoutes } from "../../../router/appRoutes";
import Button from "../../stateless/common/button";
import SpinnerLoader from "../../stateless/common/spinnerLoader";
import { PurchaseTable } from "../purchases/purchaseTable";
import { holdMngmnt } from "../purchases/tableColumns";
import RemoveConfirmModel from "../carts/cartDetails/remove/removeModal";
import { clearErrMsg, clearPatronUpdate, PatronStateSelector, clearPatronData} from "../../../../redux/reducers/patron/patronReducer";
import { resizePendingCols, searchPatron, updateHoldPosition, updatePatron,recentPatronTab } from "../../../../redux/actions/patron/patronAction";

export const PatronsManagement = (props: any) => {
  const {
    formState,
    patronHoldFormState,
    patronReplaceFormState,
    patronData,
    errMsg,
    patronUpdated,
    patronHoldData,
    holdMgtResizeState,
    defaultPatronTab
  } = useSelector(PatronStateSelector);
  const { appUser } = useSelector(userStateSelector);
  const lib_Id = appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const history = useHistory();
  const [preSaleLoading, setPreSaleLoading] = useState<any>(patronHoldFormState)
  const [showRemove, setShowRemove] = useState(false);
  const [patronStatus, setPatronStatus] = useState<boolean>(false);
  const [patronId, setPatronId] = useState<string>("");
  const [newPatronNo, setNewPatronNo] = useState<string>("");
  const [holdMgtResize, setHoldMgtResize] = useState<any>([]);
  const [movePostionData, setMovePostionData] = useState<any>([]);

  useEffect(() => {
    return () => {
      dispatch(clearPatronUpdate());
    };
  }, []);

  useEffect(() => {
    if (holdMgtResize.length > 0) {
      dispatch(resizePendingCols(holdMgtResize));
    }
  }, [holdMgtResize]);

  const showNotification = (content: any, type: any) => {
    if (type === "success") {
      message
        .success({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
        .then(() => {
          dispatch(clearPatronUpdate());
        });
    } else if (type === "error") {
      message
        .error({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
        .then(() => {
          dispatch(clearErrMsg());
        });
    }
  };

  const serachPatron = () => {
    dispatch(clearPatronData())
    dispatch(searchPatron({ libraryId: lib_Id, PatronId: patronId }));
  };

  useEffect(() => {
    if (errMsg.length > 0) {
      showNotification(errMsg[0], "error");
    }
    if (patronUpdated) {
      setNewPatronNo("");
      showNotification("Card No. Updated Successfully", "success");
    }
  }, [errMsg, patronUpdated]);

  useEffect(() => {
    setPatronStatus(patronData?.IsActive);
  }, [patronData]);

  const handleNewPatronNo = (e: any) => {
    setNewPatronNo(e?.target?.value);
  };

  const createNewPatronId = () => {
    setPatronId(newPatronNo)
    dispatch(
      updatePatron({
        libraryId: lib_Id,
        newName: newPatronNo,
        oldName: patronData?.UserName,
      })
    );
  };

  const handleCancel = () => {
    setNewPatronNo("");
  };

  const movePosition = (data: any) => {
    setMovePostionData(data);
    setShowRemove(true);
  };

  const getAuthor = (book: any) => {
    searchPayloadForAuthor(book?.authorName, appUser).then((data: any) => {
      dispatch(getSearchString(data.strinParam));
      dispatch(onBackFillForm(data.searchParam));
      const advanceState: any = {
        searchDetails: data?.state?.searchDetails,
        gaString: {
          gaData: data?.strinParam,
        },
      };
      history.push(AppRoutes.SEARCHRESULTS, advanceState);
    });
  };

  const handleMovePositionConfirm = () => {
    dispatch(
      updateHoldPosition({
        libraryId: lib_Id,
        PatronId: patronData?.UserName,
        isbn: movePostionData?.ISBN,
        reqBody:{}
      })
    );
    
  };
  
  useEffect(() => {
    let loading
    if( patronHoldFormState.loading || patronReplaceFormState.loading) {
      loading = {...preSaleLoading,loading : true}
      setPreSaleLoading(loading)
    } else {
      setTimeout(()=> {
        loading = {...preSaleLoading,loading : false}
        setPreSaleLoading(loading)
      }, 100)
    }
  }, [formState,patronHoldFormState])

  const handleSelect = (e:any) => {
    dispatch(recentPatronTab(e))
  }

  useEffect(() => {
    return () => {
      dispatch(clearPatronData())
    }
  }, [])

  return (
    <>
      <Row className="head-container">
        <Col xs={12} sm={14} md={18} lg={20} xl={20}>
          <p className="page-title">Patrons Management</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Input
            name="newcardnumbermain"
            placeholder="Library Card Number"
            className="card-input pt-8"
            bordered={false}
            value={patronId}
            onChange={(e: any) => setPatronId(e?.target?.value)}
            onPressEnter={serachPatron}
          />
        </Col>
        <Col span={5} className={`ml-20 patronBtnSize ${!patronStatus ? "patronBtn" : ""}`}>
          <Button type="primary confirm-button" onClick={serachPatron}>
            Search
          </Button>
        </Col>
      </Row>
      <div className="patronLoader">
        <SpinnerLoader loading={formState.loading}>
        {!patronStatus && !patronData?.IsActive ? (
            <Empty description="No results found." />
          ) : null}
          {patronStatus ? (
            <>
              <div className="patronDetail">
                <span className="patronStatus">Status: </span>
                <span>Active</span>
              </div>
              {patronData?.Email?
                <div className="patronDetail">
                  <span className="patronStatus email">Email: </span>
                  <span>
                    {patronData?.Email ? patronData?.Email : ""}
                  </span>
                </div>:null
              }
              <div>
                <Tabs
                  className="cart-tabs"
                  defaultActiveKey={defaultPatronTab}  onChange={handleSelect}
                >
                  <TabPane
                    tab="Update Card Number"
                    key="1"
                    className="scrollStyle scrollContent updateCardScroll"
                  >
                    <SpinnerLoader loading={patronReplaceFormState.loading}>
                    <Row>
                      <Col sm={12} md={8} className="updateCardWrapper">
                        <div>Current Card Number</div>
                        <div className="presentCardN0">
                          {patronData?.UserName}
                        </div>
                        <div>New Card Number</div>
                        <div>
                          <Input
                            name="newcardnumber"
                            placeholder="Ex. 123456789"
                            className="card-input pt-8"
                            bordered={false}
                            value={newPatronNo}
                            onChange={handleNewPatronNo}
                          />
                        </div>
                      </Col>
                    </Row>
                    </SpinnerLoader>
                    {newPatronNo !== "" ? (
                      <>
                        <Row className="updateCardText">
                          <Col span={24}>
                            <p>
                              Any Activity on the patron's new barcode will be
                              gone. All of the patron's activity on the Current
                              Card Number will be accessible through the New
                              Card Number.
                            </p>
                          </Col>
                        </Row>
                        <Row justify="center" className="updateCardNo">
                          <Col span={5} className="ptb-25">
                            <Button
                              type="secondary cancel-button"
                              onClick={handleCancel}
                            >
                              Cancel
                            </Button>
                          </Col>

                          <Col span={5} className="ptb-25 ml-20">
                            <Button
                              type="primary confirm-button"
                              onClick={createNewPatronId}
                            >
                              Update
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </TabPane>
                  <TabPane tab="Hold Management" key="2">
                    <PurchaseTable
                      showPagination={false}
                      needRowData={false}
                      data={patronHoldData}
                      classN="purchaseTable left-sorter holdTable"
                      editAble={false}
                      columns={holdMngmnt(movePosition, history, getAuthor)}
                      formState={preSaleLoading}
                      setColumnResize={setHoldMgtResize}
                      resizeCol={holdMgtResizeState}
                      rowKey={"key"}
                    />
                  </TabPane>
                </Tabs>
                <RemoveConfirmModel
                  showRemove={showRemove}
                  setShowRemove={setShowRemove}
                  removeConfirm={handleMovePositionConfirm}
                  heading={"Confirm Move"}
                  message={`This will move ${patronData?.UserName} to the top of the hold list for ${movePostionData?.DisplayName}`}
                />
              </div>
            </>
          ) : null}
        </SpinnerLoader>
      </div>
    </>
  );
};
