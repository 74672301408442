/* eslint-disable import/no-anonymous-default-export */
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../components/routing/privateRoute";
import { PublicRoute } from "../components/routing/publicRoute";
import GaWrap from "../components/stateless/layouts/gaWrap";
import MainLayout from "../components/stateless/layouts/mainLayout";
import { AppRoutes, PrivateRoutes, PublicRoutes } from "./appRoutes";

export default (props: any) => {
  const pathName = window.location.pathname;
  const { isAuthenticated, appUser, logoutCallback,libLogUser,showSpotLight } = props;
  
  return (
    <Router>
      {/* Public Routes */}
      <GaWrap>
      <Switch>
        {PublicRoutes.map((x) => (
          <PublicRoute key={x.path} path={x.path} exact component={x.component} appUser={appUser} isAuthenticated={isAuthenticated} showSpotLight={showSpotLight}/>
        ))}
      </Switch>
      </GaWrap>

      {/* Private Routes */}
      {PrivateRoutes.map((x, index) => (
        <Route key={`${x.routePath.replaceAll("/", "")}-${index}`} path={x.routePath}>
          <MainLayout logoutCallback={logoutCallback} {...props}>
          <GaWrap {...props}>
            <Switch>
              {x.routes.map((r, rIndex) => (
                <PrivateRoute key={`${r.path.replaceAll("/", "")}-${rIndex}`} exact path={r.path} component={r.component} appUser={appUser} isAuthenticated={isAuthenticated} libLogUser={libLogUser} />
              ))} 
            </Switch>
            </GaWrap>
          </MainLayout>
        </Route>
      ))}
      {
          pathName === "/" ? (
            <Redirect to={AppRoutes.LOGIN} />
          ) : (
            <></>
          )
      }
    </Router>
  );
};