import { Modal, Input, Row, Col } from "antd";
import Button from "../../../stateless/common/button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteIcon, editIcon } from "../../../../images";
import SavedSearch from "../../../../models/search/savedSearch";
import { deleteSearchById, getSaveSearchResult, retainDefailtValue } from "../../../../../redux/actions/search/searchActions";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { SearchstateSelector, clearDeleted,clearResults, setSearchTab } from "../../../../../redux/reducers/search/searchReducer";

export const SavedListModal = (props: any) => {
  const {
    isModalVisible,
    setModalVisible,
    searchList,
    selectedSearchID,
    setDropDefaultVal
  } = props;
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [deleteID, setDeleteID] = useState<any>('')
  const { formState, searchDelete } = useSelector(SearchstateSelector);
  const [selectedSearch, setSelectedSearch] = useState<SavedSearch| any>({})
  const onConfirmSave = () => {
    setModalVisible(false);
  };
 const onEditClick =(search : SavedSearch)=>{
  dispatch(getSaveSearchResult(search))
  dispatch(setSearchTab('1'))
  setSelectedSearch(search)
  setModalVisible(false)
  dispatch(clearResults())
 }

 const onDeleteClick =(search : SavedSearch)=>{
  setSelectedSearch(search)
  setShowDelete(true)
 }
 const onDeleteConfirm =(search : SavedSearch) =>{
  setDeleteID(search.SearchSaveId)
  dispatch(deleteSearchById(search.SearchSaveId))
}
const  handleSearchEdit = (e: any) => {
var name = e.target.name; 
var value = e.target.value;
setSelectedSearch({...selectedSearch, [name]: value})
}
useEffect(()=>{
if(searchDelete){
  dispatch(clearDeleted())
  setShowDelete(false)
  dispatch(retainDefailtValue([]))
  if(selectedSearchID === deleteID){
    setDropDefaultVal(true)
    dispatch(clearResults())
  }
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[formState.isSuccess, searchDelete])

  return (
    <Modal
      visible={isModalVisible}
      wrapClassName="newCart-modal"
      onCancel={() => setModalVisible(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="search-modal-title">Saved Searches</div>
        {searchList?.map((search: SavedSearch, index: number)=>{
          return (
            <Row className="search-content" key={index}>
            <Col span={18}>
            <p className="saved-list-title">{search.SearchSaveName}</p>
            <span className="saved-list-description">{search.SearchSaveDescription}</span>
            </Col>
            <Col span={3}>
                <img src={editIcon} alt="" style={{cursor:"pointer"}} onClick={()=>onEditClick(search)}/>
            </Col>
            <Col span={3}>
                <img src={deleteIcon} alt="" style={{cursor:"pointer"}} onClick={()=>onDeleteClick(search)}/>
            </Col>
        </Row>
          )
        })}
       
        <Button type="primary searchSave" onClick={onConfirmSave}>
          Done
        </Button>
      </div>
      <Modal
      visible={showEdit}
      wrapClassName="newCart-modal"
      onCancel={() => setShowEdit(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="search-modal-title">Edit Saved Search</div>
        <Input
          name="SearchSaveName"
          className="new-modal-input"
          value={selectedSearch.SearchSaveName}
          onChange={handleSearchEdit}
        />
        <Input
          name="SearchSaveDescription"
          className="new-modal-input"
          value={selectedSearch.SearchSaveDescription}
          onChange={handleSearchEdit}
        />
        <Button type="primary searchSave" onClick={() => setShowEdit(false)} disabled>
          Save
        </Button>
        <Button type="cancel-search" onClick={() => setShowEdit(false)}>
          Cancel
        </Button>
      </div>
    </Modal>
    <Modal
      visible={showDelete}
      wrapClassName="newCart-modal"
      onCancel={() => setShowDelete(false)}
    >
      <SpinnerLoader loading={formState.loading}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className="search-modal-title">Delete Saved Search</div>
        <p className="delete-text"> <span className="delete">DELETE  {` `}</span>  “{selectedSearch.SearchSaveName}”?</p>
        <Button type="primary searchSave" onClick={() => setShowDelete(false)}>
          Cancel
        </Button>
        <Button type="delete-search" onClick={()=>onDeleteConfirm(selectedSearch)}>
        <span className="delete">Delete Saved Search</span>
        </Button>
      </div>
      </SpinnerLoader>
    </Modal>
    </Modal>
  );
};
