/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Form, Input, Radio } from "antd";
import "./../marc.less";
import Button from "../../../../stateless/common/button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMarcByIsbn } from "../../../../../../redux/actions/marc/marcActions";
import ComponentLoader from "../../../../stateless/common/spinnerLoader/componentLoader";
import { MarcstateSelector } from "../../../../../../redux/reducers/marc/marcReducer";

export const MarcRecordsTitle = (props: any) => {
  const { libraryId,libraryName } = props;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [isbnList, setIsbnList] = useState<any>([]);
  const [selectedFormat, setSelectedFormat] = useState("MRC");
  const { formState} = useSelector(MarcstateSelector);

  const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index && value !== "";
  };

  const onInput = (e: any) => {
    let value = e.target.value;
    setIsbnList(value);
  };

  const handleFormatChange = (e: any) => {
    setSelectedFormat(e.target.value);
  };

  const handleSearchClick = () => {
    const payloadData = {
      libraryId: libraryId,
      format: selectedFormat,
      isbn: isbnList,
      libraryName:libraryName?libraryName?.replaceAll(" ",""):""
    };
    dispatch(fetchMarcByIsbn(payloadData));
  };

  const handleInputBlur = (e: any) => {
    if (e.relatedTarget) {
      if (e.relatedTarget.id === "isbn-search-btn") {
        handleSearchClick();
      }
    }
  };

  useEffect(() => {
    return () => {
      setSelectedFormat("MRC");
      setIsbnList([]);
    };
  }, []);

  return (
    <div className="insightsMarcScreen marc_isbn_screen">
      <ComponentLoader loading={formState?.loading}> 
      <Row className="head-container">
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
            layout="horizontal"
            onFinish={handleSearchClick}
          >
            <div className="isbn-marc-Box">
              <span className="outerLabel">
                <p className="isbn-title">Search By ISBN</p>
              </span>
              <Form.Item
                name="isbn-list"
                rules={[
                  { required: true, message: "Please enter ISBN number!" },
                  {
                    pattern: /^[0-9,:\n; ]*$/,
                    message: `Please enter valid ISBN numbers (separated by comma, semicolon, colon or space)`,
                  },
                ]}
              >
                <Input
                  name="isbn-list"
                  placeholder="Copy and Paste or enter ISBN number here"
                  bordered={false}
                  onChange={onInput}
                  // onBlur={handleInputBlur}
                />
              </Form.Item>
            </div>

            <Form.Item>
              <span className="outerLabel">Format</span>
              <Radio.Group onChange={handleFormatChange} value={selectedFormat}>
                <Radio value="MRC"> MARC </Radio>
                <Radio value="XML"> XML </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item className="formItemInput mb-0 text-center">
              <Button type="primary loginButton" htmlType="submit">
                Generate MARC
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row></Row>
      </ComponentLoader>
    </div>
  );
};
