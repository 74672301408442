import { Modal, Input } from "antd";
import Button from "../../../stateless/common/button";
import { useDispatch } from "react-redux";
import { saveSearch } from "../../../../../redux/actions/search/searchActions";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import { Messages } from "../../../../constants/messages";

export const SaveSearchModal = (props: any) => {
  const {
    isModalVisible,
    setModalVisible,
    searchDetails,
    handleChange,
    showNotification,
  } = props;
  const dispatch = useDispatch();
  const onConfirmSave = () => {
    dispatch(saveSearch(searchDetails));
    setModalVisible(false);
    showNotification(Messages.SAVE_SEARCH, "loading");
  };
  

  return (
    <Modal
      visible={isModalVisible}
      wrapClassName="newCart-modal"
      onCancel={() => setModalVisible(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="search-modal-title">Save Search</div>
        <Form onFinish={onConfirmSave}>
          <FormItem name="SearchSaveName" rules={[{ required: true, message: 'Please enter the Search Name!' }]}>
        <Input
          name="SearchSaveName"
          className="new-modal-input"
          placeholder="Search Name"
          onChange={handleChange}
        />
        </FormItem>
        <Input
          name="SearchSaveDescription"
          className="new-modal-input"
          placeholder="Description"
          onChange={handleChange}
        />
        <Button type="primary searchSave" htmlType="submit">
          Save
        </Button>
        </Form>
        <Button type="cancel-search" onClick={() => setModalVisible(false)}>
          Cancel
        </Button>      
      </div>
    </Modal>
  );
};
