import React, { useRef, useState, useEffect, useCallback } from "react";
import { Layout, Row, Col, Typography } from "antd";
import { AppHeader } from "../common/appHeader";
import { SideNav } from "../common/sidenav";
import {
  StyledContent,
  StyledLayout,
  StyledSider,
  SiderRow,
} from "./mainLayout.styles";
import "./mainLayout.less";
import { useDispatch, useSelector } from "react-redux";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { useLocation } from "react-router-dom";
import CollectionSideBar from "../../containers/collection/collectionSideBar/CollectionSideBar";
import { SideBarStateSelector } from "../../../../redux/reducers/app/sideBarReducer";
import SearchSideBar from "../../containers/search/searchSideBar/SearchSideBar";
import { collapseSideBar, switchSearchSideBar } from "../../../../redux/actions/app/appActions";

const { Header } = Layout;

const MainLayout = (props: any) => {
  const { children } = props;
  const dispatch = useDispatch();
  const location = useLocation<any>();
  const [collapsed, setCollapsed] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [collapsedByIcon, setCollapsedByIcon] = useState(false);
  const [collapsByIconClicked, setCollapsByIconClicked] = useState(false);
  const [sidenavWidth, setSidenavWidth] = useState({
    width: "200px",
    minWidth: "100px",
  });
  const sidebarRef = useRef<any>(null);
  const [sidebarWidth, setSidebarWidth] = useState(200);
  const [padding, setPadding] = useState(200);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ellipsis, setEllipsis] = useState(true);
  const { Text } = Typography;
  const { libLogUser, appUser } = useSelector(userStateSelector);
  const { showSearchSideBar } = useSelector(SideBarStateSelector);
  // const { setCartDetailsFilter } = useSelector(CartStateSelector);
  var libraryName =
    appUser && appUser.libraryData ? appUser.libraryData[0]?.LibraryName : "";

  const isMyCollectionPage =
    location?.pathname === "/cloudLibrary/search/myCollectionResults";
  const isSearchPage = location?.pathname === "/cloudLibrary/search/results";

  const startResizing = useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      if (
        isResizing &&
        mouseMoveEvent.clientX -
          sidebarRef.current.getBoundingClientRect().left >=
          100 &&
        mouseMoveEvent.clientX -
          sidebarRef.current.getBoundingClientRect().left <=
          260
      ) {
        setSidebarWidth(
          mouseMoveEvent.clientX -
            sidebarRef.current.getBoundingClientRect().left
        );
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  useEffect(() => {
    setPadding(sidebarWidth);
  }, [sidebarWidth]);

  const handleCollapse = () => {
    if (!collapsed) {
      setPadding(0);
    } else {
      setPadding(sidebarWidth);
    }
    setCollapsed(!collapsed);
  };

  const handleCollapseIcon = () => {
    setCollapsedByIcon(!collapsedByIcon);
    dispatch(collapseSideBar(!collapsedByIcon));
    setPadding(0);
    setCollapsByIconClicked(true);
    setTimeout(() => {
      setCollapsByIconClicked(false);
    }, 500);
  };

  useEffect(() => {
    if (sidebarWidth < 200) {
      setCollapsedByIcon(true);
    } else {
      setCollapsedByIcon(false);
    }
  }, [sidebarWidth]);

  useEffect(() => {
    if (
      location.pathname === "/cloudLibrary/cart/cartDetails" &&
      !collapsedByIcon
    ) {
      handleCollapseIcon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // if(!location.pathname.includes('cart')){
      if (
        (isMyCollectionPage || isSearchPage) &&
        showSearchSideBar 
      ) {
        setSidebarWidth(320);
      } else {
        setSidebarWidth(200);
      }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearchSideBar, location]);

  useEffect(() => {
    if (collapsByIconClicked) {
      if (!collapsedByIcon) {
        setSidebarWidth(200);
        setSidenavWidth({ width: "200px", minWidth: "100px" });
      } else {
        setSidebarWidth(15);
        setSidenavWidth({ width: "25px", minWidth: "25px" });
      }
    }
  }, [collapsByIconClicked, collapsedByIcon]);

  const closeEditSearch = () => {
    dispatch(switchSearchSideBar(false));
  };

  return (
    <StyledLayout>
      <SiderRow>
        <div
          className="sideMenu"
          ref={sidebarRef}
          style={{
            width: sidebarWidth,
            display: collapsed ? "none" : "flex",
            minWidth: sidenavWidth?.minWidth,
          }}
        >
          {showSearchSideBar && (isMyCollectionPage || isSearchPage) ? (
            <div className="nav-collapse-arrow" onClick={closeEditSearch}>
              <div className="collapse-arrow">
                <span>x</span>
              </div>
            </div>
          ) : (
            <div className="nav-collapse-arrow" onClick={handleCollapseIcon}>
              <div className="collapse-arrow">
                <span>{collapsedByIcon ? ">" : "<"}</span>
              </div>
            </div>
          )}
          {isMyCollectionPage || isSearchPage ? (
            <StyledSider
              width="100%"
              trigger={null}
              collapsible
              collapsed={collapsed}
              collapsedWidth={12}
            >
              {collapsedByIcon &&
              sidebarWidth < 100 ? null : showSearchSideBar ? (
                isMyCollectionPage ? (
                  <CollectionSideBar />
                ) : (
                  <SearchSideBar />
                )
              ) : (
                <SideNav />
              )}
            </StyledSider>
          ) : (
            <StyledSider
              width="100%"
              trigger={null}
              collapsible
              collapsed={collapsed}
              collapsedWidth={12}
            >
              {collapsedByIcon && sidebarWidth < 100 ? null : <SideNav />}
            </StyledSider>
          )}

          <div className="app-sidebar-resizer" onMouseDown={startResizing} />
        </div>
        <div
          id={"scrollableDiv"}
          className={`content-container ${
            location?.pathname === "/cloudLibrary/cart/cartDetails"
              ? "detailViewHeight scrollOnCartScreen"
              : ""
          }`}
          style={{ paddingLeft: padding }}
        >
          <Row className="content-sub-container">
            <Col span={24}>
              <Header
                className={libLogUser ? "headerLib" : ""}
                style={{ paddingLeft: "30px" }}
              >
                {libLogUser ? (
                  <Row className={libLogUser ? "libRow" : ""}>
                    <Col span={24} className="longName">
                      <Text
                        style={ellipsis ? { width: 500 } : undefined}
                        ellipsis={ellipsis ? { tooltip: libraryName } : false}
                      >
                        {libraryName}
                      </Text>
                    </Col>
                  </Row>
                ) : null}
                <AppHeader collapsed={collapsed} onCollapse={handleCollapse} />
              </Header>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <StyledContent>{children}</StyledContent>
            </Col>
          </Row>
        </div>
      </SiderRow>
    </StyledLayout>
  );
};

export default MainLayout;
