import { createAsyncThunk } from "@reduxjs/toolkit";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import thunk from "redux-thunk";
import { showError } from "../../../utility/appUtil";
import { SettingsService } from "../../../web/services/settings/settings";

const settingsSrv = new SettingsService();

export const recentSettingTab = createAsyncThunk(
  "api/recentSettingTab",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchEbook = createAsyncThunk(
  "api/fetchEbook",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.fetchEbook(payload);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchAbook = createAsyncThunk(
  "api/fetchAbook",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.fetchAbook(payload);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const updateEbook = createAsyncThunk(
  "api/updateEbook",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.updateEbook(payload);
      if (data) {
        if (data?.ErrorMessage !== undefined && data?.ErrorMessage !== "") {
          return data;
        } else {
          thunkAPI.dispatch(fetchEbook({}));
        }
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const updateAbook = createAsyncThunk(
  "api/updateAbook",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.updateAbook(payload);
      if (data) {
        if (data?.ErrorMessage !== undefined && data?.ErrorMessage !== "") {
          return data;
        } else {
          thunkAPI.dispatch(fetchAbook({}));
        }
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchLibrarySettings = createAsyncThunk(
  "api/fetchLibrarySettings",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.fetchLibrarySettings(payload);
      if (data && data?.LibraryId) {
        return data;
      } else {
        showError(data)
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchLibraryLogo = createAsyncThunk(
  "api/fetchLibraryLogo",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.fetchLibraryLogo(payload);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const removeLibraryLogo = createAsyncThunk(
  "api/removeLibraryLogo",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.removeLibraryLogo(payload);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchLibraryLanguage = createAsyncThunk(
  "api/fetchLibraryLanguage",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.fetchLibraryLanguage(payload);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const updateLibrarySettings = createAsyncThunk(
  "api/updateLibrarySettings",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.updateLibSettings(payload);
      if (data) {
        setTimeout(()=>{
          thunkAPI.dispatch(fetchLibrarySettings(payload));
        },5000)
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchLibraryUsers = createAsyncThunk(
  "api/fetchLibraryUsers",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.fetchLibraryUsers(payload);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const addNewUser = createAsyncThunk(
  "api/addNewUser",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.addNewUser(payload?.reqBody);
      if (data) {
        if(data?.data) {
          thunkAPI.dispatch(fetchLibraryUsers(payload?.getPayload));
          return data;
        } else {
          return data?.error
        }
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "api/updateUser",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.updateUser(payload?.reqBody);
      if (data) {
        if(data?.data){
          thunkAPI.dispatch(fetchLibraryUsers(payload?.getPayload));
          return data;
        } else {
          return data?.error
        }
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchHideCategory = createAsyncThunk(
  "api/fetchHideCategory",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.fetchHideCategory(payload);
      if (data) {
        thunkAPI.dispatch(fetchRestrictedCategory({libraryId:payload?.libraryId}));
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchRestrictedCategory = createAsyncThunk(
  "api/fetchRestrictedCategory",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.fetchRestrictedCategory(payload);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const restrictCategory = createAsyncThunk(
  "api/restrictCategory",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.updateCategory(payload);
      if (data) {
        thunkAPI.dispatch(fetchRestrictedCategory({libraryId:payload?.libraryId}));
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const saveRestrictCategoryIDs = createAsyncThunk(
  "api/saveRestrictCategoryIDs",
  async (ids: any, thunkAPI) => {
    try {
      const data = ids
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const removeCategory = createAsyncThunk(
  "api/removeCategory",
  async (payload: any, thunkAPI) => {
    try {
      const data = await settingsSrv.updateCategory(payload);
      if (data) {
        thunkAPI.dispatch(fetchRestrictedCategory({libraryId:payload?.libraryId}));
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);