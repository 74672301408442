/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Radio, Input } from "antd";
import DropDown from "../../../stateless/common/dropDown";
import { dropdownIcon, searchIcon } from "../../../../images";
import "./slider.less";
import { useDispatch, useSelector } from "react-redux";
import { AutoCartType } from "../../../../constants/enums";
import DeactiveCartModel from "./deactiveCartModel/deactiveCartModel";
import { CartStateSelector } from "../../../../../redux/reducers/carts/cartsReducer";
import FormItem from "antd/lib/form/FormItem";

export const PurchasingForm = (props: any) => {
  const {
    cart,
    rulesData,
    managerOptions,
    notifyOptions,
    getNewRuleData,
    setErrorChild
  } = props;
  const newData = useRef(JSON.parse(JSON.stringify(rulesData)));
  const oldData = useRef(JSON.parse(JSON.stringify(rulesData)));
  const [manager, setManager] = useState<any>();
  const [notifyUsers, setNotifyUsers] = useState<any>([]);
  const [getNotifyUsers, setGetNotifyUsers] = useState<any>([]);
  const [switchActive, setSwitchActive] = useState<boolean>();
  const [spendLimit, setSpendLimit] = useState<any>("");
  const [poRefNo, setPoRefNo] = useState<any>("");
  const [cartName, setCartName] = useState<string>("");
  const [errorSpend, setErrorSpend] = useState<boolean>(false);
  const [showRadio, setShowRadio] = useState<boolean>(false);
  const [prevState, setprevState] = useState<boolean>();
  const [subActiveTab, setSubActiveTab] = useState<string>();
  const [purchaseFormData, setPurchaseFormData] = useState<any>({
    RuleName:"",
    PurchaseCapForPeriod: "",
    ExternalRefId: "",
    CartManagerUserId: "",
    NotificationUserIds: "",
    AutoBuy: false,
    DoNotBuy: false,
  });

  const dispatch = useDispatch();
  var auto: boolean = false;
  var manual: boolean = false;
  var selectedCartType = useRef<number>(0);

  useEffect(() => {
    setCartName(cart?.CartName)
    if (cart.CartName === AutoCartType.HOLDS) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 2) {
          setSpendLimit(newData?.current?.Rules[i]?.PurchaseCapForPeriod);
          setPoRefNo(newData?.current?.Rules[i]?.ExternalRefId);
          setPurchaseFormData({
            ...purchaseFormData,
            PurchaseCapForPeriod:
              newData?.current?.Rules[i]?.PurchaseCapForPeriod,
            ExternalRefId: newData?.current?.Rules[i]?.ExternalRefId,
          });
        }
      }
    } else if (cart.CartName === AutoCartType.CONTENT_EXP) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 1) {
          setSpendLimit(newData?.current?.Rules[i]?.PurchaseCapForPeriod);
          setPoRefNo(newData?.current?.Rules[i]?.ExternalRefId);
          setPurchaseFormData({
            ...purchaseFormData,
            PurchaseCapForPeriod:
              newData?.current?.Rules[i]?.PurchaseCapForPeriod,
            ExternalRefId: newData?.current?.Rules[i]?.ExternalRefId,
          });
        }
      }
    } else if (cart.CartName === AutoCartType.SUGGESTIONS) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 3) {
          setSpendLimit(newData?.current?.Rules[i]?.PurchaseCapForPeriod);
          setPoRefNo(newData?.current?.Rules[i]?.ExternalRefId);
          setPurchaseFormData({
            ...purchaseFormData,
            PurchaseCapForPeriod:
              newData?.current?.Rules[i]?.PurchaseCapForPeriod,
            ExternalRefId: newData?.current?.Rules[i]?.ExternalRefId,
          });
        }
      }
    }
  }, [cart.CartName, newData?.current]);

  useEffect(() => {
    if (subActiveTab === "1") {
      selectedCartType.current = 2;
    } else if (subActiveTab === "3") {
      selectedCartType.current = 3;
    } else {
      selectedCartType.current = 1;
    }
    getNotifyValue();
    getManagerValue();
  }, [subActiveTab]);

  useEffect( ()=> {
    if(errorSpend){
      setErrorChild(true)
    } else {
      setErrorChild(false)
    }
  },[errorSpend])

  const handleChange = (e: any) => {
    var value = e.target.value;
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        if (value === auto) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            AutoBuy: true,
            DoNotBuy: false,
          };
          setPurchaseFormData({
            ...purchaseFormData,
            AutoBuy: true,
            DoNotBuy: false,
          });
          setShowRadio(true);
        } else if (value === manual) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            AutoBuy: false,
            DoNotBuy: true,
          };
          setPurchaseFormData({
            ...purchaseFormData,
            AutoBuy: false,
            DoNotBuy: true,
          });
          setShowRadio(false);
        }
      }
    }
  };
  const getNotifyValue = () => {
    var tempNotify = null;
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        tempNotify = newData.current?.Rules[i]?.NotificationUserIds?.split(",");
      }
    }
    setNotifyUsers(tempNotify);
    return tempNotify;
  };

  const getManagerValue = () => {
    var tempMang = null;
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        tempMang = newData.current?.Rules[i]?.CartManagerUserId;
      }
    }
    setManager(tempMang);
    return tempMang;
  };

  const handleManagerSelect = (value: any) => {
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        setManager(value);
        newData.current.Rules[i] = {
          ...newData.current.Rules[i],
          CartManagerUserId: value,
        };
        setPurchaseFormData({ ...purchaseFormData, CartManagerUserId: value });
      }
    }
    return newData.current;
  };
  const handleNotifySelect = (value: any) => {
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        newData.current.Rules[i] = {
          ...newData.current.Rules[i],
          NotificationUserIds: value.join(","),
        };
        setNotifyUsers(value);
        setPurchaseFormData({
          ...purchaseFormData,
          NotificationUserIds: value.join(","),
        });
      }
    }
  };

  const getPurchaseType = () => {
    if (subActiveTab === "1") {
      selectedCartType.current = 2;
    } else if (subActiveTab === "3") {
      selectedCartType.current = 3;
    } else {
      selectedCartType.current = 1;
    }
    for (var i = 0; i < newData.current.Rules.length; i++) {
      if (
        newData.current.Rules[i].AutoBuyRuleType === selectedCartType.current
      ) {
        if (
          newData.current.Rules[i].AutoBuy &&
          !newData.current.Rules[i].DoNotBuy
        ) {
          auto = true;
          manual = false;
        } else {
          auto = false;
          manual = true;
        }
      }
    }
  };

  useEffect(() => {
    setPurchaseFormData({
      ...purchaseFormData,
      AutoBuy: auto,
      DoNotBuy: manual,
      PurchaseCapForPeriod: spendLimit,
      ExternalRefId: poRefNo,
      CartManagerUserId: manager,
      NotificationUserIds: notifyUsers !== undefined?notifyUsers !== null?notifyUsers.join(","):"":"",
      RuleName:cartName
    });
  }, [auto, manual, spendLimit, poRefNo, manager, notifyUsers,cartName]);

  const handleSpendlimit = (e: any) => {
    if (spendLimit === "" && e.target.value === "") {
      setSpendLimit("");
      setErrorSpend(true);
    } else if (spendLimit < 1 && e.target.value < 1) {
      setSpendLimit("");
      setErrorSpend(true);
    } else if (e.target.value < 1) {
      setSpendLimit("");
      setErrorSpend(true);
    } else if (JSON.stringify(e.target.value) === "") {
      setSpendLimit("");
      setErrorSpend(true);
    } else {
      setSpendLimit(e.target.value);
      setErrorSpend(false);
      if (cart.CartName === AutoCartType.HOLDS) {
        for (var i = 0; i < newData.current.Rules?.length; i++) {
          if (newData.current.Rules[i].AutoBuyRuleType === 2) {
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              PurchaseCapForPeriod: e.target.value,
            };
            setPurchaseFormData({
              ...purchaseFormData,
              PurchaseCapForPeriod: e.target.value,
            });
          }
        }
      } else if (cart.CartName === AutoCartType.CONTENT_EXP) {
        for (var i = 0; i < newData.current.Rules?.length; i++) {
          if (newData.current.Rules[i].AutoBuyRuleType === 1) {
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              PurchaseCapForPeriod: e.target.value,
            };
            setPurchaseFormData({
              ...purchaseFormData,
              PurchaseCapForPeriod: e.target.value,
            });
          }
        }
      } else if (cart.CartName === AutoCartType.SUGGESTIONS) {
        for (var i = 0; i < newData.current.Rules?.length; i++) {
          if (newData.current.Rules[i].AutoBuyRuleType === 3) {
            newData.current.Rules[i] = {
              ...newData.current.Rules[i],
              PurchaseCapForPeriod: e.target.value,
            };
            setPurchaseFormData({
              ...purchaseFormData,
              PurchaseCapForPeriod: e.target.value,
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    setShowRadio(auto);
  }, [auto]);
  const handlePoRefernce = (e: any) => {
    setPoRefNo(e.target.value);
    if (cart.CartName === AutoCartType.HOLDS) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 2) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            ExternalRefId: e.target.value,
          };
          setPurchaseFormData({
            ...purchaseFormData,
            ExternalRefId: e.target.value,
          });
        }
      }
    } else if (cart.CartName === AutoCartType.CONTENT_EXP) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 1) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            ExternalRefId: e.target.value,
          };
          setPurchaseFormData({
            ...purchaseFormData,
            ExternalRefId: e.target.value,
          });
        }
      }
    } else if (cart.CartName === AutoCartType.SUGGESTIONS) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 3) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            ExternalRefId: e.target.value,
          };
          setPurchaseFormData({
            ...purchaseFormData,
            ExternalRefId: e.target.value,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (subActiveTab === "1") {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 2) {
          setSwitchActive(newData.current?.Rules[i]?.Active);
          setprevState(newData.current?.Rules[i]?.Active);
        }
      }
    } else if (subActiveTab === "2") {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 1) {
          setSwitchActive(newData.current?.Rules[i]?.Active);
          setprevState(newData.current?.Rules[i]?.Active);
        }
      }
    } else if (subActiveTab === "3") {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 3) {
          setSwitchActive(newData.current?.Rules[i]?.Active);
          setprevState(newData.current?.Rules[i]?.Active);
        }
      }
    }
  }, [cart.CartName, subActiveTab]);

  useEffect(() => {
    if (cart.CartName === AutoCartType.HOLDS) {
      setSubActiveTab("1");
    } else if (cart.CartName === AutoCartType.CONTENT_EXP) {
      setSubActiveTab("2");
    } else {
      setSubActiveTab("3");
    }
  }, []);

  useEffect(() => {
    getNewRuleData(purchaseFormData)
  }, [purchaseFormData]);

  useEffect(()=> {
    if(purchaseFormData.AutoBuy === false) {
    if (cart.CartName === AutoCartType.HOLDS) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 2) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            PurchaseCapForPeriod: oldData.current.Rules[i].PurchaseCapForPeriod,
            ExternalRefId:oldData.current.Rules[i].ExternalRefId,
          };
          setSpendLimit(newData.current.Rules[i].PurchaseCapForPeriod);
          setPoRefNo(newData.current.Rules[i].ExternalRefId);
          setErrorSpend(false)
        }
      }
    } else if (cart.CartName === AutoCartType.CONTENT_EXP) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 1) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            PurchaseCapForPeriod: oldData.current.Rules[i].PurchaseCapForPeriod,
            ExternalRefId:oldData.current.Rules[i].ExternalRefId,
          };
          setSpendLimit(newData.current.Rules[i].PurchaseCapForPeriod);
          setPoRefNo(newData.current.Rules[i].ExternalRefId);
          setErrorSpend(false)
        }
      }
    } else if (cart.CartName === AutoCartType.SUGGESTIONS) {
      for (var i = 0; i < newData.current.Rules?.length; i++) {
        if (newData.current.Rules[i].AutoBuyRuleType === 3) {
          newData.current.Rules[i] = {
            ...newData.current.Rules[i],
            PurchaseCapForPeriod: oldData.current.Rules[i].PurchaseCapForPeriod,
            ExternalRefId:oldData.current.Rules[i].ExternalRefId,
          };
          setSpendLimit(newData.current.Rules[i].PurchaseCapForPeriod);
          setPoRefNo(newData.current.Rules[i].ExternalRefId);
          setErrorSpend(false)
        }
      }
    }
  }
  },[purchaseFormData])

  useEffect(()=>{
    var data = notifyUsers?.filter((item:any)=>{
      return item !== ""
    })
    setGetNotifyUsers(data)
  },[notifyUsers])

  return (
    <>
      {getPurchaseType()}
      {props.rulesData.AutocartProcessingStrategyId === "RuleLevel" ? (
        <Row className="details-row">
          {auto !== false ? (
            <>
              <Row>
                <Col className="label" span={11}>
                  Monthly Auto cart spending limits
                </Col>
                <Col span={13}>
                  <div className="card-dropdown-withLabel">
                    <Input
                      min={1}
                      name="CartSpending"
                      placeholder="1000"
                      className="card-input"
                      bordered={false}
                      value={spendLimit}
                      onChange={handleSpendlimit}
                    />
                    {errorSpend === false ? (
                      <></>
                    ) : (
                      <p style={{ color: "red" ,fontSize:"12px"}}>
                        Monthly Auto Cart Spending Limit is required, and must be at least 1
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="label" span={11}>
                  Auto Cart PO Reference #
                </Col>
                <Col span={13}>
                  <div className="card-dropdown-withLabel">
                    <Input
                      name="autoCartPurchase"
                      placeholder="Auto cart purchase"
                      className="card-input"
                      bordered={false}
                      value={poRefNo}
                      onChange={handlePoRefernce}
                    />
                  </div>
                </Col>
              </Row>
            </>
          ) : null}
          <Col span={24}>
            <Row>
              <p className="cart-title">Purchasing</p>
            </Row>
            <Row className="radio-container">
              <Radio.Group
                name="AutoBuyHold"
                value={auto ? auto : manual}
                onChange={handleChange}
              >
                <Col span={12}>
                  <Radio value={auto}>
                    <span className="radio-span">Auto Purchase</span>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value={manual}>
                    <span className="radio-span">Manual Purchase</span>
                  </Radio>
                </Col>
              </Radio.Group>
            </Row>
            <Row className="input-container">
              <Col span={24}>
                <div className="card-dropdown-withLabel">
                <FormItem
                          rules={[
                            {
                              required: true,
                              message: "it is required field !",
                            },

                          ]}
                          initialValue={manager}
                        >

                  <label>Autocart Manager</label>
                  <DropDown
                    placeholder="AutoCart Manager"
                    className="card-dropdown with-search"
                    bordered={false}
                    optionValue={managerOptions}
                    value={manager}
                    onChange={handleManagerSelect}
                    icon={dropdownIcon}
                  />
                  </FormItem>
                </div>
              </Col>
            </Row>
            <Row className="input-container">
              <Col span={24}>
                <div className="card-dropdown-withLabel">
                  <label>Email Notification</label>
                  <DropDown
                    showSearch
                    placeholder="Notification"
                    className="card-dropdown with-search"
                    bordered={false}
                    value={getNotifyUsers?.length > 0?getNotifyUsers:undefined}
                    optionValue={notifyOptions}
                    onChange={handleNotifySelect}
                    icon={searchIcon}
                    mode="multiple"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};
