import { message } from "antd";
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { showError } from "../../../utility/appUtil";
import libUsers from "../../models/libraryUser/libUserModel";
import Ppu from "../../models/ppu/ppu";
import HideCategory from "../../models/settings/hideCategory";
import Patron from "../../models/settings/patron";
import Patronhold from "../../models/settings/patronHold";

export class SettingsService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.CARTS));
  }
  async fetchPatronById(payload: any): Promise<any> {
    const response = await this.get(
      `getPatron?libraryId=${payload?.libraryId}&PatronId=${payload?.PatronId}`
    );
    if (response.data.success === 1) {
      var patron = this.setPatronData(response.data.data);
      return patron;
    } else return response?.data?.data;
  }

  async fetchPatronHoldById(payload: any): Promise<any> {
    const response = await this.get(
      `getPatronHold?libraryId=${payload?.libraryId}&PatronId=${payload?.PatronId}`
    );
    if (response.data.success === 1) {
      var patron = this.setPatronHoldData(response?.data?.data);
      return patron;
    } else return response?.data?.data;
  }

  async putNewPatronById(payload: any): Promise<any> {
    const response = await this.put(
      `updatePatron?libId=${payload?.libraryId}&newName=${payload?.newName}&oldName=${payload?.oldName}`
    );
    if (response.data.success === 1) {
      return true;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    };
  }

  async putHoldPosition(payload: any): Promise<any> {
    const response = await this.put(
      `updatePatronHold?libId=${payload?.libraryId}&isbn=${payload?.isbn}&patronId=${payload?.PatronId}`,payload?.reqBody);
    if (response.data.success === 1) {
      return true;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    };
  }

  async fetchEbook(payload: any): Promise<any> {
    const response = await this.get(`getPpuEbook`);
    if (response.data.success === 1) {
      var ppu = this.setPpuData(response.data.data);
      return ppu;
    } else {
      showError(response?.data?.data)
      return null
    };
  }

  async fetchAbook(payload: any):Promise<any> {
    const response = await this.get(`getPpuAbook`);
    if (response.data.success === 1) {
      var ppu = this.setPpuData(response.data.data);
      return ppu;
    }  else {
      showError(response?.data?.data)
      return null
    };
  }

  async updateEbook(payload: any): Promise<any> {
    const response = await this.post(`updatePpuEbook`,payload);
    if (response.data.success === 1){
      return true;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    };
  }

  async updateAbook(payload: any): Promise<any> {
    const response = await this.post(`updatePpuAbook`,payload);
    if (response.data.success === 1) {
      return true;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    };
  }
   
  async fetchLibrarySettings(payload: any): Promise<any> {
    const response = await this.get(`getLibrarySettings?library_Id=${payload?.libraryId}`);
    if (response.data.success === 1) {
      var libSettings = response?.data?.data;
      return libSettings
    } else return response?.data?.data;
  }

  async updateLibSettings(payload: any): Promise<any> {
    const response = await this.post(`updateLibrarySettings?library_Id=${payload?.libraryId}`,payload?.reqBody);
    if (response.data.success === 1){
      message.success({
        content:`${response?.data?.data}`,
        duration: 4,
      })
      return true;
    } else {
      message.error({
        content:`${response?.data?.data}`,
        duration: 4,
      })
      return false
    };
  }

  async fetchLibraryLogo(payload: any): Promise<any> {
    const response = await this.get(`getLibraryLogo?library_Id=${payload?.libraryId}`);
    if (response.data.success === 1) {
      var libSettings = response?.data?.data;
      return libSettings
    } else {
      showError(response?.data?.data)
      return null
    };
  }

  async removeLibraryLogo(payload: any): Promise<any> {
    const response = await this.delete(`removeLogo?library_Id=${payload}`);
    if (response.data.success === 1) {
      var libSettings = response?.data;
      return libSettings
    } else {
      showError(response?.data?.data)
      return null
    };
  }

  async fetchLibraryLanguage(payload: any): Promise<any> {
    const response = await this.get(`getLibraryLanguage`);
    if (response.data.success === 1) {
      var libSettings = response?.data?.data;
      return libSettings
    } else {
      showError(response?.data?.data)
      return null
    };
  }

  async fetchLibraryUsers(payload: any): Promise<any> {
    const response = await this.get(`getLibraryUsers?library_Id=${payload?.libraryId}&status=${payload?.activeOnly}&record_Count=${payload?.recordCount}&record_Start=${payload?.recordStart}&sort_Direction=${payload?.sortDirection}&sort_Field=${payload?.sortField}`);
    if (response.data.success === 1) {
      var libUsers = this.setLibUsers(response?.data?.data);
      return libUsers
    } else {
      showError(response?.data?.data)
      return null;
    }
  }


  async addNewUser(payload: any): Promise<any> {
    const response = await this.post(`addNewUser`,payload);
    if (response.data.success === 1){
      return {data:true};
    } else {
      showError(response?.data?.data)
      return {data:false, error:response?.data?.data}
    };
  }

  async updateUser(payload: any): Promise<any> {
    const response = await this.put(`updateUser`,payload);
    if (response.data.success === 1) {
      return {data:true};
    } else {
      showError(response?.data?.data)
      return {data:false, error:response?.data?.data}
    };
  }


  async fetchHideCategory(payload: any): Promise<any> {
    const response = await this.get(`getHideCategory?query=${payload?.query}`);
    if (response.data.success === 1) {
      var hideCategory = this.setHideCategoryData(response?.data?.data)
      return hideCategory
    } else {
      showError(response?.data?.data)
      return []
    };
  }


  async fetchRestrictedCategory(payload: any): Promise<any> {
    const response = await this.get(`getRestrictedCategory?library_Id=${payload?.libraryId}`);
    if (response.data.success === 1) {
      var hideCategory = this.setHideCategoryData(response?.data?.data)
      return hideCategory
    } else {
      showError(response?.data?.data)
      return []};
  }

  async updateCategory(payload: any): Promise<any> {
    const response = await this.put(`addCategoryToList?library_Id=${payload?.libraryId}`,payload?.reqBody);
    if (response.data.success === 1) {
      return true;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    };
  }






  private setPatronData(data: any) {
    const patron: Patron = {
      Catalog: data.Catalog,
      Classification: data.Classification,
      Email: data.Email,
      IsActive: data.IsActive,
      Type: data.Type,
      UserName: data.UserName,
    };
    return patron;
  }

  private setHideCategoryData(items: any) {
    var hideCategoryData = items?.map((data:any)=>{ 
      const hideCategory: HideCategory = {
        CatalogID: data?.CatalogID,
        CatalogId: data?.CatalogId,
        CatalogItemCategory: data?.CatalogItemCategory,
        CategoryGroup: data?.CategoryGroup,
        CategoryId: data?.CategoryId,
        CategoryName: data?.CategoryName,
        Id: data?.Id,
        ParentCategoryId: data?.ParentCategoryId,
        Path: data?.Path.slice(0,-1),
        SourceId: data?.SourceId,
        SubCategory: data?.SubCategory,
        SubTreeDocumentCount: data?.SubTreeDocumentCount,
      };
      return hideCategory;
    })
     return hideCategoryData
  }

  private setPatronHoldData(items: any) {
    var patronData = items?.map((data:any,index:any)=>{ 
      const patron: Patronhold = {
        CreateDate:data?.CreateDate,
        Creator: data?.Creator,
        DRMType:data?.DRMType,
        DisplayName:data?.DisplayName,
        DocumentID:data?.DocumentID,
        Format:data?.Format,
        ISBN:data?.ISBN,
        Owner:data?.Owner,
        Position:data?.Position,
        auther:data?.DisplayName.includes(' - ')?data?.DisplayName.substring(data?.DisplayName.lastIndexOf(" - ") + 3):"",
        title:data?.DisplayName.includes(' - ')?data?.DisplayName.slice(0, data?.DisplayName.lastIndexOf(' - ')):data?.DisplayName,
        key:index
      };
      return patron;
    })
     return patronData
  }

  private setPpuData(data: any) {
    const patron: Ppu = {
      Buyer: data?.Buyer !== undefined?data?.Buyer:"",
      EmailNotificationSubscribers: data?.EmailNotificationSubscribers !== undefined?data?.EmailNotificationSubscribers:[],
      IsPPUEnabled: data?.IsPPUEnabled !== undefined?data?.IsPPUEnabled:false,
      IsPatronLimitEnabled: data?.IsPatronLimitEnabled !== undefined?data?.IsPatronLimitEnabled:false,
      LibraryId: data?.LibraryId !== undefined?data?.LibraryId:0,
      MaxPatronLimit: data?.MaxPatronLimit !== undefined?data?.MaxPatronLimit:0,
      MaxTitlePriceAllowed: data?.MaxTitlePriceAllowed !== undefined?data?.MaxTitlePriceAllowed:0,
      MediaType: data?.MediaType !== undefined?data?.MediaType:0,
      PORefNumber: data?.PORefNumber !== undefined?data?.PORefNumber:"",
      RunningReserveTotal: data?.RunningReserveTotal !== undefined?data?.RunningReserveTotal:0,
      RunningTotal: data?.RunningTotal !== undefined?data?.RunningTotal:0,
      SpendingLimit: data?.SpendingLimit !== undefined?data?.SpendingLimit:0,
    };
    return patron;
  }

  private setLibUsers(items: any) {
    const libUsers: libUsers[] = items?.Users?.map((data: any,index:any) => {
      return {
        UserID:data?.UserID !== undefined?data?.UserID:"",
        EmailAddress:data?.EmailAddress !== undefined?data?.EmailAddress:"",
        FirstName:data?.FirstName !== undefined?data?.FirstName:"",
        LastName:data?.LastName !== undefined?data?.LastName:"",
        ScreenName:data?.ScreenName !== undefined?data?.ScreenName:"",
        IsActive:data?.IsActive !== undefined?data?.IsActive:false,
        status:data?.IsActive !== undefined?data?.IsActive === true?'Active':'Inactive':false,
        Password:data?.Password !== undefined?data?.Password:"",
        CreationTime:data?.CreationTime !== undefined?data?.CreationTime:"",
        ModificationTime:data?.ModificationTime !== undefined?data?.ModificationTime:"",
        UserLanguage:data?.UserLanguage !== undefined?data?.UserLanguage:"",
        IsLibraryAdmin:data?.IsLibraryAdmin !== undefined?data?.IsLibraryAdmin:false,
        IsLibraryUser:data?.IsLibraryUser !== undefined?data?.IsLibraryUser:false,
        IsLibraryBuyer:data?.IsLibraryBuyer !== undefined?data?.IsLibraryBuyer:false,
        IsFeaturedContentManager:data?.IsFeaturedContentManager !== undefined?data?.IsFeaturedContentManager:false,
        IsShelfAdmin: data?.IsShelfAdmin !== undefined?data?.IsShelfAdmin:false,
        IsDownloadStationUser:data?.IsDownloadStationUser !== undefined?data?.IsDownloadStationUser:false,
        IsPatronCommunicator: data?.IsPatronCommunicator !== undefined?data?.IsPatronCommunicator:false,
        IsPatronManager: data?.IsPatronManager !== undefined?data?.IsPatronManager:false,
        IsOnlyPatronMgr:data?.IsOnlyPatronMgr !== undefined?data?.IsOnlyPatronMgr:false,
        Library:data?.Library !== undefined?data?.Library:[],
        key:index
      };
    });
    return { TotalCount: items?.TotalUserCount, Users: libUsers };
  }
}

