import { createSlice } from "@reduxjs/toolkit";
import { resetState } from "../../../utility/stateUtil";
import {
  switchSearchSideBar,
  setSideBarItem,
  collapseSideBar
} from "../../actions/app/appActions";
import { AppState } from "../../states/app/appState";
import { RootState } from "../../store/rootReducer";

const initialState: AppState = {
  selectedSideBarItem: ["Spotlight"],
  formState: resetState(),
  showSearchSideBar: false,
  collapseSideBar: false,
  retainedPageSize: 100,
};
export const AppSlice = createSlice({
  name: "App",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      state.selectedSideBarItem = ["Spotlight"];
      return state;
    },
    retainPageSize: (state, { payload }) => {
      state.retainedPageSize = payload;
      return state;
    },
    clearRetainedPageSize: (state) => {
      state.retainedPageSize = initialState?.retainedPageSize;
      return state;
    },
  },
  extraReducers: {
    [setSideBarItem.fulfilled.toString()]: (state, { payload }) => {
      state.selectedSideBarItem = payload;
      return state;
    },
    [switchSearchSideBar.fulfilled.toString()]: (state, { payload }) => {
      state.showSearchSideBar = payload;
      return state;
    },
    [collapseSideBar.fulfilled.toString()]: (state, { payload }) => {
      state.collapseSideBar = payload;
      return state;
    },
  },
});

export const { clearState, retainPageSize, clearRetainedPageSize } =
 AppSlice.actions;
export const SideBarStateSelector = (state: RootState) => state.sideBar;
export default AppSlice.reducer;