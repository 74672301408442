import React, { useState, useEffect } from 'react'
import { Row, Modal, Col } from "antd";
import Button from "../../../../stateless/common/button";
import { useSelector, useDispatch } from "react-redux";
import {
  userStateSelector,
} from "../../../../../../redux/reducers/user/userReducer";

import {
  CartStateSelector, clearMoveFormState
} from "../../../../../../redux/reducers/carts/cartsReducer";

import {
  moveItemtoCart
} from "../../../../../../redux/actions/carts/cartAction";

import SpinnerLoader from "../../../../stateless/common/spinnerLoader";

const MoveToCartModel = (props: any) => {
  const { showModel, setShowModel, selectedCart, itemsToMove, owned, cartId, destinationCart,startCount,pageSize,setMoveTitleIsContinue } = props
  const dispatch = useDispatch();
  const { appUser } = useSelector(userStateSelector);

  const { moveTitleFormState,formState,fetchCartByIdFormState ,setCartDetailsFilter} = useSelector(CartStateSelector)

  var LibraryId = appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : "";
  var userId = appUser && appUser.libraryData ? appUser.libraryData[0].userId : "";

  const [loading, setLoading] = useState<boolean>(false)

  const onCancelModel = () => {
    setShowModel(false)
  }

  const handleYesSubmit = () => {
    setMoveTitleIsContinue(true)
    const detailsId = itemsToMove.map((d: any) => d.CartDetailId)
    const payload ={
    moveCart: {
      "CartDetailIds": detailsId,
      "DestinationCartId": destinationCart?.CartId,
      "SourceCartId": selectedCart.CartId,
      "MoveAllItems": false,
      "OnlyAddItems": true,
    },
    fetchCart: {
      "libId": LibraryId,
      "appuser": userId
    },
    fetchDetails: {
      "cartId": cartId,
      "count": pageSize,
      "libraryOwned": owned,
      "sortBy": setCartDetailsFilter?.sortOrder !== undefined ? setCartDetailsFilter?.sortOrder : "Sort By",
      "sortOrder": setCartDetailsFilter?.sort !== undefined ? setCartDetailsFilter?.sort : "asc",
      "start":startCount,
      "userId": userId !== undefined ? userId : setCartDetailsFilter?.user_id
    }
  }
    dispatch(moveItemtoCart(payload))
  }

  const handleNoSubmit = () => {
    setShowModel(false)
  }

  useEffect(() => {
    if(moveTitleFormState?.loading || formState?.loading || fetchCartByIdFormState?.loading){
      setLoading(true)
    } else if (!moveTitleFormState?.loading && !formState?.loading &&  !fetchCartByIdFormState?.loading) {
      setLoading(false)
    }
  }, [moveTitleFormState,formState,fetchCartByIdFormState])

  useEffect(() => {
    if(!loading && moveTitleFormState?.isSuccess ) {
      dispatch(clearMoveFormState())
      setShowModel(false)
    } else if(!loading && moveTitleFormState?.isError ){
      dispatch(clearMoveFormState())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveTitleFormState,loading])
  
  return (
    <Modal
      wrapClassName="purchase-modal"
      visible={showModel}
      onCancel={onCancelModel}
      footer={
        <SpinnerLoader loading={moveTitleFormState?.loading || formState.loading || fetchCartByIdFormState.loading } className="moveCart">
        <Row justify="center">
            <Col span={24}>
            <Button
              type="primary confirm-button moveConfirm"
              onClick={handleYesSubmit}
            >
              Continue
            </Button>
          </Col>
          <Col span={24}>
            <span onClick={handleNoSubmit} className="moveCartCancel">
              Cancel
            </span>
          </Col>
        </Row>
        </SpinnerLoader>
      }
    >
      <SpinnerLoader loading={moveTitleFormState?.loading || formState.loading || fetchCartByIdFormState?.loading }>
      <Row>
        <Col span={24}>
          <div className='moveCartTitle'>Move Title Between Carts Confirmation</div>
        </Col>
        <Col className='moveTitleText1'>
          This will move the selected items from cart "{selectedCart?.CartName}" to cart "{destinationCart?.CartName}". 
        </Col>
        <Col className='moveTitleText2'>
          Are you sure you want to move these items ?
        </Col>
      </Row>
      </SpinnerLoader>
    </Modal>
  )
}

export default MoveToCartModel