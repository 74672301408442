import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import {
  getPurchaseUser,
  getPurchase,
  getPurchaseDetails,
  getPurchasePending,
  getPurchaseComplete,
  resizePurchaseCols,
  resizeCompletedCols,
  resizePendingCols,
  resizeDetailsCols,
  getPrePub,
  deletePrePub,
  updatePrePub,
  searchFilters,
  recentTab,
  resizePreSaleCols,
  onApplyingFilters,
  cameFromPurchaseDetail,
  purchasesFilterRemember
} from "../../actions/purchases/purchasesActions";
import { PurchasesState } from "../../states/purchases/purchases";
import { RootState } from "../../store/rootReducer";

const initialState:PurchasesState = {
  formState:resetState(),
  PurchaseformState:resetState(),
  purchaseUserData:[],
  purchaseUserPendingData:[],
  purchaseUserCompleteData:[],
  purchaseOrderData:[],
  purchaseDetailsData:[],
  PurchaseCompleteState:resetState(),
  PurchasePending:resetState(),
  allPurchaseColState:[],
  completedPurchaseColState:[],
  completedPreSaleColState:[],
  pendingPurchaseColState:[],
  detailsPurchaseColState:[],
  preSaleFormState:resetState(),
  preSaleItems:[],
  preSaleTotalCount:0,
  selectedFilters: {},
  filters: {},
  defaultTab:"1",
  prePubFormState:resetState(),
  isFromPrchseDetil:[],
  purchasesFilter:{}
};

export const PurchaseSlice = createSlice({
  name: "purchase",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.PurchasePending = resetState()
      state.PurchaseCompleteState = resetState()
      state.PurchaseformState = resetState()
      state.preSaleFormState = resetState()
      state.purchaseOrderData = []
      state.purchaseDetailsData = []
      state.purchaseUserCompleteData = []
      state.purchaseUserPendingData = []
      state.preSaleItems = []
      state.isFromPrchseDetil = []
      state.preSaleTotalCount = 0

      return state;
    },
    clearResizePurchaseCols: (state) => {
      state.allPurchaseColState = []
      return state
    },
    clearResizeCompletedCols: (state) => {
      state.completedPurchaseColState = []
      return state
    },
    clearPurchaseDetailList: (state) => {
      state.purchaseDetailsData = []
      return state
    },
    clearResizePendingCols: (state) => {
      state.pendingPurchaseColState = []
      return state
    },
    clearResizeDetailsCols: (state) => {
      state.detailsPurchaseColState = []
      return state
    },
    clearPreSaleStates: (state) => {
      state.preSaleFormState = resetState()
      state.preSaleItems = []
      state.preSaleTotalCount = 0
      return state
    },
    clearPurchaseFilter: (state) => {
      state.purchasesFilter = []
      return state
    },
  },
  extraReducers: {
    [getPurchaseUser.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.PurchaseformState = errorState(payload.errorMsg);
      } else {
        state.PurchaseformState = fulfilledState();
        state.purchaseUserData = payload;
      }
      return state;
    },
    [getPurchaseUser.pending.toString()]: (state) => {
      state.PurchaseformState = pendingState();
      return state;
    },
    [getPurchaseUser.rejected.toString()]: (state, { payload }) => {
      state.PurchaseformState = rejectedState();
      return state;
    },

    [getPurchase.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.PurchaseformState = errorState(payload.errorMsg);
      } else {
        state.PurchaseformState = fulfilledState();
        state.purchaseOrderData = payload;
      }
      return state;
    },
    [getPurchase.pending.toString()]: (state) => {
      state.PurchaseformState = pendingState();
      return state;
    },
    [getPurchase.rejected.toString()]: (state, { payload }) => {
      state.PurchaseformState = rejectedState();
      return state;
    },

    [getPurchaseDetails.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.PurchaseformState = errorState(payload.errorMsg);
      } else {
        state.PurchaseformState = fulfilledState();
        state.purchaseDetailsData = payload;
      }
      return state;
    },
    [getPurchaseDetails.pending.toString()]: (state) => {
      state.PurchaseformState = pendingState();
      return state;
    },
    [getPurchaseDetails.rejected.toString()]: (state, { payload }) => {
      state.PurchaseformState = rejectedState();
      return state;
    },

    [getPurchaseComplete.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.PurchaseformState = errorState(payload.errorMsg);
      } else {
        state.PurchaseCompleteState = fulfilledState();
        state.purchaseUserCompleteData = payload;
      }
      return state;
    },
    [getPurchaseComplete.pending.toString()]: (state) => {
      state.PurchaseCompleteState = pendingState();
      return state;
    },
    [getPurchaseComplete.rejected.toString()]: (state, { payload }) => {
      state.PurchaseCompleteState = rejectedState();
      return state;
    },

    [getPurchasePending.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.PurchaseformState = errorState(payload.errorMsg);
      } else {
        state.PurchasePending = fulfilledState();
        state.purchaseUserPendingData = payload;
      }
      return state;
    },
    [getPurchasePending.pending.toString()]: (state) => {
      state.PurchasePending = pendingState();
      return state;
    },
    [getPurchasePending.rejected.toString()]: (state, { payload }) => {
      state.PurchasePending = rejectedState();
      return state;
    },
    [resizePurchaseCols.fulfilled.toString()]: (state, { payload }) => {
      state.allPurchaseColState = payload
      state.formState = fulfilledState();
    },
    [resizePurchaseCols.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [resizePurchaseCols.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [resizeCompletedCols.fulfilled.toString()]: (state, { payload }) => {
      state.completedPurchaseColState = payload
      state.formState = fulfilledState();
    },
    [resizeCompletedCols.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [resizeCompletedCols.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [resizePendingCols.fulfilled.toString()]: (state, { payload }) => {
      state.pendingPurchaseColState = payload
      state.formState = fulfilledState();
    },
    [resizePendingCols.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [resizePendingCols.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [resizeDetailsCols.fulfilled.toString()]: (state, { payload }) => {
      state.detailsPurchaseColState = payload
      state.formState = fulfilledState();
    },
    [resizeDetailsCols.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [resizeDetailsCols.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },

    [getPrePub.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.preSaleFormState = errorState(payload.errorMsg);
      } else {
        state.prePubFormState = fulfilledState();
        state.preSaleFormState = fulfilledState();
        state.preSaleItems = payload?.Items;
        state.preSaleTotalCount = payload?.TotalCount;
      }
      return state;
    },
    [getPrePub.pending.toString()]: (state) => {
      state.prePubFormState = pendingState();
      state.preSaleFormState = pendingState();
      return state;
    },
    [getPrePub.rejected.toString()]: (state, { payload }) => {
      state.prePubFormState = rejectedState();
      state.preSaleFormState = rejectedState();
      return state;
    },

    [updatePrePub.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.preSaleFormState = errorState(payload.errorMsg);
      } else {
        state.preSaleFormState = fulfilledState();
        state.preSaleItems = payload?.Items;
        state.preSaleTotalCount = payload?.TotalCount;
      }
      return state;
    },
    [updatePrePub.pending.toString()]: (state) => {
      state.preSaleFormState = pendingState();
      return state;
    },
    [updatePrePub.rejected.toString()]: (state, { payload }) => {
      state.preSaleFormState = rejectedState();
      return state;
    },

    [deletePrePub.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.preSaleFormState = errorState(payload.errorMsg);
      } else {
        state.preSaleFormState = fulfilledState();
        state.preSaleItems = payload?.Items;
        state.preSaleTotalCount = payload?.TotalCount;
      }
      return state;
    },
    [deletePrePub.pending.toString()]: (state) => {
      state.preSaleFormState = pendingState();
      return state;
    },
    [deletePrePub.rejected.toString()]: (state, { payload }) => {
      state.preSaleFormState = rejectedState();
      return state;
    },

    [searchFilters.fulfilled.toString()]: (state, { payload }) => {
      state.selectedFilters = payload
      state.formState = fulfilledState();
      return state;
    },
    [searchFilters.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [searchFilters.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },

    [onApplyingFilters.fulfilled.toString()]: (state, { payload }) => {
      state.filters = payload
      state.formState = fulfilledState();
      return state;
    },
    [onApplyingFilters.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [onApplyingFilters.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },

    [recentTab.fulfilled.toString()]: (state, { payload }) => {
      state.defaultTab = payload
      state.formState = fulfilledState();
      return state;
    },
    [recentTab.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [recentTab.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },
    [resizePreSaleCols.fulfilled.toString()]: (state, { payload }) => {
      state.completedPreSaleColState = payload
      state.formState = fulfilledState();
    },
    [resizePreSaleCols.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [resizePreSaleCols.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [cameFromPurchaseDetail.fulfilled.toString()]: (state, { payload }) => {
      state.isFromPrchseDetil = payload
      return state;
    },
    [purchasesFilterRemember.fulfilled.toString()]: (state, { payload }) => {
      state.purchasesFilter = payload
      return state;
    },
  },
});

export const { clearState,clearResizePurchaseCols,clearResizeCompletedCols,clearResizePendingCols,clearResizeDetailsCols,clearPurchaseDetailList,clearPurchaseFilter } = PurchaseSlice.actions;
export const PurchaseStateSelector = (state: RootState) => state.Purchase;
export default PurchaseSlice.reducer;
