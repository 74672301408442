/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */

import React, { useState } from "react";
import { Row, Col, InputNumber, Dropdown, Menu, Divider, Popover, message, Tooltip } from "antd";
import { useLocation } from "react-router-dom";
import Button from "../../../stateless/common/button";
import { audiobookLabel, ebookLabel, infoIcon, notFound, videobookLabel, whiteArrow } from "../../../../images";
import "./bookDetails.less";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { BookStateSelector, clearBooklistCurrentPage } from "../../../../../redux/reducers/books/bookListReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchBookbyId } from "../../../../../redux/actions/books/bookActions";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { CartStateSelector, clearAdded, setTab, clearErrorCart } from "../../../../../redux/reducers/carts/cartsReducer";
import Cart from "../../../../models/cart/cart";
import { addItemtoCart, cartDetailsFilterRemember } from "../../../../../redux/actions/carts/cartAction";
import { clearSelected } from "../../../../../redux/reducers/books/bookListReducer";
import { AppRoutes } from "../../../../router/appRoutes";
import { ExpirationstateSelector } from "../../../../../redux/reducers/expiration/expirationReducer";
import { validateLendingModelType } from "../validateLendingData";
import { NewCartModal } from "../../carts/newCartModal";
import { Messages } from "../../../../constants/messages";
import { searchPayloadForAuthor, searchPayloadForSeries, searchPayloadForNarrator } from "../../../../../utility/searchUtil";
import { getSearchString, onBackFillForm } from "../../../../../redux/actions/search/searchActions";
import { removeExtraCharacter, replaceAll } from "../../../../../utility/appUtil";
import { getPreviousLocaton } from "../../../../../redux/reducers/search/searchReducer";

export const BookDetails = (props: any) => {
  const location = useLocation<any>();
  const history = useHistory();
  const backText = "< Back";
  const dispatch = useDispatch();
  const { selectedBook, formState, booklistCurrentPage } = useSelector(BookStateSelector);
  const { appUser } = useSelector(userStateSelector);
  const { book,searchParam } = location?.state;
  const [copies, setCopies] = useState(1)
  const { carts, addedItem, addItemtoCartFormstate, activeCartArray, ErrorCart } = useSelector(CartStateSelector);
  const { expiration } = useSelector(ExpirationstateSelector)
  var currency = appUser &&  appUser.libraryData? appUser.libraryData[0]?.CurrencySymbol : ""
  var dateFormat = appUser && appUser.libraryData? appUser?.libraryData[0]?.DateFormat.toUpperCase(): "DD-MM-YYYY"
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [interestAge, setInterestAge] = useState<any>([]);
  const [usGrade, setUsGrade] = useState<any>([]);
  const [biblio, setBiblio] = useState<any>([]);
  const [screenSize, setDimension] = useState<any>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  var permissions =
  appUser && appUser.libraryData ? appUser.libraryData[0].FeaturePermissions : "";
  const User:any = appUser;
  
  var filteredCarts = permissions.includes("AudioBooks") ?
  carts.filter((data: Cart) => {
    return (data.CartOwner === appUser?.email  || data.IsCommunityCart === true);
  })
  :
  carts.filter((data: Cart) => {
    return (data.CartOwner === appUser?.email  || data.IsCommunityCart === true)
     && !data.CartTypeCode?.includes("Audio")}); 


     const getWindowSize = () => {
      setDimension({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  
    useEffect(()=>{
      window.addEventListener("resize", getWindowSize);
      return () => {
        window.removeEventListener("resize", getWindowSize);
      };
    },[screenSize])

  const userCarts = (
    <Menu className="header-dropdown">
      {filteredCarts.map((data: Cart, index: number) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => onAddtoCart(data)}
          >
            <p className="dropDown-title">{data.CartName}</p>
            <span className="dropDown-content">
              {currency} {(data.TotalCartAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')} . {data.NumberOfTitles} Titles
            </span>
          </Menu.Item>
        );
      })}
      <Menu.Divider />
      <Menu.Item key="All-Carts-Link" className="dropDown-link" onClick={()=>{history.push(AppRoutes.CART);dispatch(setTab("2"))}}>
        <p>All Carts</p>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="New-cart-Link" className="dropDown-link" onClick={()=>setShowModal(true)}>
        <p>New Cart</p>
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    var lib_Id =
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
    dispatch(
      fetchBookbyId({
        bookId: book?.CatalogItemId,
        libraryId: lib_Id,
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
  useEffect(()=>{
    return ()=>{
      dispatch(clearSelected())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const goBack = () => {
    history.goBack();
    dispatch(getPreviousLocaton(window.location.pathname))
    dispatch(cartDetailsFilterRemember(searchParam))
  };
  useEffect(()=>{
    if(addedItem && !loading){
      var lib_Id =
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
      dispatch(
        fetchBookbyId({
          bookId: book?.CatalogItemId,
          libraryId: lib_Id,
        })
      );
      showNotification(Messages.ITEM_ADDED, "success")
      dispatch(clearAdded())
      setCopies(1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedItem, loading])

  useEffect(() => {
    if(addItemtoCartFormstate?.isError && !loading){
      dispatch(clearAdded())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[addItemtoCartFormstate])
  const handleChange = (value : number) =>{
    setCopies(value)
  }
  const onAddtoCart = (cart: Cart) => {
    var lib_Id = appUser && appUser?.libraryData ? appUser?.libraryData[0]?.LibraryId : 0;
    var payloadData = {
      IsCommunityCart: cart?.IsCommunityCart,
      LibraryId: lib_Id,
      SaveAction: "S",
      CartDescription: cart?.CartDescription,
      CartId: cart?.CartId,
      CartName: cart?.CartName,
      AssociateShelfId: cart?.AssociateShelfId,
      CartDetail: [
        {
          CatalogItemId: selectedBook?.CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: copies,
          SaveAction: "S",
        },
      ],
    };
    dispatch(addItemtoCart(payloadData));
    const msg = replaceAll(/\{0\}/gi,Messages.ADD_TO_CART,`${cart?.CartName}`)
    showNotification(msg, "loading");
  };

  const replaceFunction = (string:any) =>{
    const final = string.replace(/[^\d.-]/g, '');
    return final
    }

  const lendingModelData = (id:any,checkoutLimit:any, timespanlimit:any, format:any) => {
    if(format !== "MP3"){
    const dataLendingModel = expiration?.filter((data: any) => {
      return data.ContentProviderId === id
    })

    let statement = validateLendingModelType(dataLendingModel)
    return statement
  } else {
    if(checkoutLimit === null && timespanlimit === null) {
      return ''
    } else {
      let desc = checkoutLimit + " checkouts"
      let year = " " + replaceFunction(timespanlimit) + " years after purchase"
      if(checkoutLimit !== null && timespanlimit !== null) {
        return desc + " or" + year
      } else if(checkoutLimit !== null){
        return desc
      } else {
        return year
      }
    }
  }
  }
  const InfoCarts = (
    <div>
      {selectedBook?.carts && selectedBook?.carts.length ? (
          selectedBook?.carts.map((bookCart: any, index: number) => {
            return (
              <div key={`cart${index}`}>
                <p className="book-cart">
                  {bookCart.CartName}
                </p>
                <span className="copies-text">{bookCart.NumberOfCopies} Copies</span>
                {selectedBook?.carts ? index < selectedBook?.carts.length - 1 && (
                  <Divider className="carts-divider" />
                ): null}
              </div>
            );
          })
        ) : (
          <div className="book-cart">No Carts</div>
        )
      }
    </div>
  );
  const showNotification =(content: any, type: any)=>{
    if(type === "loading"){
      setLoading(true)
      message.loading({
        content: content,
        key: "notificationMessage",
        duration: 4
      }).then(() => {
        setLoading(false)
      })
    }
    else{
      type === "success"?
      message.success({
        content: content,
        key: "notificationMessage",
      }):
      message.error({
        content: content,
        key: 'error'
      })
    }
  }

  const searchBySeries = () => {
    let Series = book?.Series?book?.Series:selectedBook?.Series
    searchPayloadForSeries(Series, appUser).then((data: any)=>{
      dispatch(getSearchString(data.strinParam))
      dispatch(onBackFillForm(data.searchParam));
      let advanceState: any = {
        searchDetails: {...data?.state?.searchDetails,series:[data?.state?.searchDetails?.series]},
        gaString: {
          gaData: data?.strinParam,
          currentPage : booklistCurrentPage
        },
      };
      dispatch(clearBooklistCurrentPage());
      history.push(AppRoutes.SEARCHRESULTS, advanceState)
    })
  }
  
  const handleKeyWord = (book: any) => {
    searchPayloadForAuthor(book?.Authors, appUser).then((data: any)=>{
      dispatch(getSearchString(data.strinParam))
      dispatch(onBackFillForm(data.searchParam))
      var advanceState: any = {
        searchDetails: data?.state?.searchDetails,
        gaString: {
          gaData: data?.strinParam,
          currentPage : booklistCurrentPage
        },
      };
      dispatch(clearBooklistCurrentPage());
      history.push(AppRoutes.SEARCHRESULTS, advanceState)
    })
  };

  const handleNarratorKeyWord = (book: any) => {
    searchPayloadForNarrator(book?.Narrator, appUser).then((data: any)=>{
      dispatch(getSearchString(data.strinParam))
      dispatch(onBackFillForm(data.searchParam))
      var advanceState: any = {
        searchDetails: data?.state?.searchDetails,
        gaString: {
          gaData: data?.strinParam,
          currentPage : booklistCurrentPage
        },
      };
      dispatch(clearBooklistCurrentPage());
      history.push(AppRoutes.SEARCHRESULTS, advanceState)
    })
  };

  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr)
      .tz("Etc/GMT")
      .format(dateFormat);
    return currentDate;
  };
  
  const getBookLabel = (format: string) => {
    switch (format) {
      case "EPUB": {
        return ebookLabel;
      }
      case "PDF": {
        return ebookLabel;
      }
      case "MP3": {
        return audiobookLabel;
      }
      case "VideoBook": {
        return videobookLabel;
      }
    }
  };

  useEffect(()=>{
    if(ErrorCart !== "" && (!loading)){
    dispatch(clearErrorCart())
    dispatch(clearAdded())
     }else{
       return undefined
     }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ErrorCart, loading])

   useEffect(() => {
    selectedBook?.PublisherAudienceInfo?.map((data:any)=> {
      if((data?.AudienceType)?.toUpperCase() === "INTEREST_AGE"){
        return setInterestAge(data)
      } else if ((data?.AudienceType)?.toUpperCase() === "US_GRADE"){
        return setUsGrade(data)
      } else if ((data?.AudienceType)?.toUpperCase() === "BIBLIOTHECA"){
        return setBiblio(data)
      }
       else return undefined
    })
   }, [selectedBook])

   const MyGroupTable = (CloudLinkGroupName:any,GroupSharableCopies:any,GroupHoldCount:any) => {
    return (
      <div className="my-group-table">
        <table>
          <thead>
            <tr>
              <td colSpan={2}>
                <b>{CloudLinkGroupName}</b>&nbsp;
                <Popover
                    content="Data is refreshed within 24 hours"
                    trigger={["hover", "click"]}
                    placement="bottomLeft"
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    overlayClassName="custom-popover"
                  >
                    <img
                      className="cloud-link-info-icon"
                      src={infoIcon}
                      alt="info"
                    />
                  </Popover>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="subHeading">
              <td>Shareable Copies</td>
              <td>Current Holds</td>
            </tr>
            <tr className="tableValues">
              <td>{GroupSharableCopies}</td>
              <td>{GroupHoldCount}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
    );
  };

  return (
    <div className="scrollContent scrollStyle book-details">
      <button className="back-button" onClick={goBack}>
        {backText}
      </button>
      <SpinnerLoader loading={formState.loading}>
      <Row gutter={[20, 20]} className="book-details">
        <Col span={5}>
          <Row>
            <Col span={24} style={{ justifyContent: "center" }}>
              <img
                className="selected-book-cover"
                src={selectedBook?.ImageUrl}
                alt="Cover"
                onError={(e:any)=>e.target.src=notFound}
              />
             {selectedBook ?  <img
              src={getBookLabel(selectedBook?.FileFormatName)}
              alt=""
              className="selected-book-label"
            /> : null }
            </Col>
          </Row>
        </Col>
        <Col span={18}>
          <Row className="title-container">
            <Col xs={12} sm={12} md={13} lg={18} xl={19} className="selectedBookTitleCover">
              <span className="selected-book-title">
                {selectedBook?.Title}
                {book.ItemState !== "FOR_SALE" && book.ItemState !== undefined && book.ItemState !== null ?
                <span className="book-state">{book.ItemState == 'PRE_SALE'?'PRE SALE':book.ItemState}</span>
                :
                null}
              </span>
              {
               selectedBook?.SubTitle !== "" ?
               <div>
                 {selectedBook?.SubTitle}
               </div>
               : null 
              }
              {selectedBook?.Series?.length ?
                <div>
                  <span  className="book-details-key">Series: </span>
                  <span className="book-details-value book-series-name book-series-family"  onClick={searchBySeries}><b className="book-series-family">{selectedBook?.Series}</b>
                  {selectedBook?.NumberInSeries?
                <span className="book-details-value" onClick={searchBySeries}><b className="book-author-value book-series-family">,{" "}#{removeExtraCharacter(typeof selectedBook?.NumberInSeries === "string"?selectedBook?.NumberInSeries:JSON.stringify(selectedBook?.NumberInSeries))}</b></span>
              : null}
              </span>
                </div>
              : null}
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} xl={3}>
              <span className="book-price-final book-price-fix">
                {currency}{
                  selectedBook?.RetailPrice ? 
                  Number(selectedBook?.RetailPrice).toFixed(2)
                  : 
                  null
                  }
              </span>
              {
                (book.PPUPrice > 0) ?
                <div className="book-ppu-price book-price-fix">PPU {currency +  Number.parseFloat(book.PPUPrice).toFixed(2)}</div>
                : null
              }
            </Col>
            <Col xs={6} sm={6} md={4} lg={2} xl={2}>
            <InputNumber
              name={selectedBook?.Title}
              defaultValue={1}
              min={1}
              onChange={handleChange}
            />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={16} lg={20} xl={20}>
              <span className="book-author-label">Author: <b className="book-author book-author-value" onClick={() => handleKeyWord(selectedBook)}>{selectedBook?.Authors}</b></span>
              {
                book?.Narrator?.length ?
                <span className="book-author-label book-narrator">Narrator: <b className="book-author book-author-value" onClick={() => handleNarratorKeyWord(selectedBook)}>{book?.Narrator}</b></span>
                :
                null
              }
            </Col>
          </Row>
          <Row className="title-container genre">
            <Col xs={12} sm={12} md={16} lg={17} xl={18} xxl={20}>
              <Row>
                {selectedBook?.Categories?.map(
                  (genreType: any, index: number) => {
                    return (
                      <span className="book-genre" key={index}>
                        {genreType}
                      </span>
                    );
                  }
                )}
              </Row>
            </Col>
            <Col xs={8} sm={8} md={6} lg={7} xl={6} xxl={4}>
            <div className="add_toCart_button">
              <Button onClick={() => onAddtoCart(activeCartArray)} type="add-main-cart-button">
                  Add to Cart
              </Button>
              <Divider type="vertical" className="cart_button_divider"/>
              <Dropdown
                overlay={userCarts}
                trigger={["click"]}
                getPopupContainer={(trigger: any) => trigger.parentNode}
              >
                <Button type="cart-tomain-dropdown">
                  <img
                    src={whiteArrow}
                    style={{ paddingLeft: "5px" }}
                    alt=""
                  />
                </Button>
              </Dropdown>
              </div>
            </Col>
          </Row>
          <Row className="title-container bookDetailsCover">
            <Col xs={12} sm={12} md={5} lg={screenSize?.width > 1190?3:4} xl={3}>
              <p className="book-details-key">Language:</p>
              <p className="book-details-key">Format:</p>
              <p className="book-details-key">Provider:</p>
              <p className="book-details-key">Publisher:</p>
              {selectedBook?.EditionType?<p className="book-details-key">Edition:</p>:null}
              <p className="book-details-key">Published Date:</p>
              <p className="book-details-key">Street Date:</p>
              <p className="book-details-key">Added to CAT:</p>
              <p className="book-details-key">Model:</p>
              {selectedBook?.Duration?<p className="book-details-key">Duration:</p>:null}
              <p className="book-details-key">ISBN:</p>
            </Col>
            <Col xs={12} sm={12} md={6} lg={5} xl={5}>
              <p className="book-details-value">{selectedBook?.Language.toUpperCase()}</p>
              <p className="book-details-value">
                {selectedBook?.FileFormatName}
              </p>
              <p className="book-details-value book-ellipsis"><Tooltip placement="topLeft" title={selectedBook?.Provider}>{selectedBook?.Provider}</Tooltip></p>
              <p className="book-details-value book-ellipsis"><Tooltip placement="topLeft" title={selectedBook?.Publisher}>{selectedBook?.Publisher}</Tooltip></p>
              <p className="book-details-value">{selectedBook?.EditionType?selectedBook?.EditionType:null }</p>
              <p className="book-details-value">
              {book.PublicationDate ? dateModify(book.PublicationDate)
                :
                'N/A'}
              </p>
              <p className="book-details-value">
              {book.StreetDate ? dateModify(book.StreetDate)
                :
                'N/A'}
              </p>
              <p className="book-details-value">
              {book.AddedToCatalogDate ? dateModify(book.AddedToCatalogDate)
                :
                'N/A'}
              </p>
              <p className="book-details-value book-ellipsis">
                {lendingModelData(selectedBook?.ContentProviderId,selectedBook?.CheckOutLimit, selectedBook?.TimeSpanLimit, selectedBook?.FileFormatName)?
              lendingModelData(selectedBook?.ContentProviderId,selectedBook?.CheckOutLimit, selectedBook?.TimeSpanLimit, selectedBook?.FileFormatName):'Perpetual'}</p>
              <p className="book-details-value">{selectedBook?.Duration?selectedBook?.Duration+' '+'minutes':null }</p>
              <p className="book-details-value">{selectedBook?.Eisbn}</p>
            </Col>
            <Divider type="vertical" className="books-divider verticalDivider" />
            <Col xs={12} sm={12} md={5} lg={3} xl={4}>
              <p className="book-details-key">Owned:</p>
              <p className="book-details-key">Holds:</p>
              <p className="book-details-key">In Circ.:</p>
              <p className="book-details-key">All Loans:</p>
              <p className="book-details-key">Suggested:</p>
              <p className="book-details-key">Holds Ratio:</p>
              {User?.libraryData?.length > 0
                  ? User?.libraryData[0]?.ConsortiumId
                    ? MyGroupTable(
                        selectedBook?.CloudLinkGroupName
                          ? selectedBook?.CloudLinkGroupName
                          : "",
                        !isNaN(selectedBook?.GroupSharableCopies)
                          ? selectedBook?.GroupSharableCopies
                          : "",
                        !isNaN(selectedBook?.GroupHoldCount) ? selectedBook?.GroupHoldCount : ""
                      )
                    : null
                  : null}
            </Col>
            <Col xs={12} sm={12} md={4} lg={2} xl={2}>
              <p className="book-details-value">
                {selectedBook?.NumberOfCopies}
              </p>
              <p className="book-details-value">
                {selectedBook?.NumberOfHolds}
              </p>
              <p className="book-details-value">
                {selectedBook?.NumberOfCurrentLoans}
              </p>
              <p className="book-details-value">
                {selectedBook?.NumberOfLoans}
              </p>
              <p className="book-details-value">{selectedBook?.NumberOfWish}</p>
              <p className="book-details-value">{selectedBook?.HoldRatio}</p>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <p className="book-details-key">Ordered:</p>
              <p className="book-details-key">Shelved:</p>
              <p className="book-details-key">In Cart:</p>
              <p className="book-details-key">Last Purchased:</p>
              <p className="book-details-key">Last Circulated:</p>
              <p className="book-details-key">Audience:</p>
            </Col>
            <Col xs={12} sm={12} md={4} lg={3} xl={3}>
              <p className="book-details-value">
                {selectedBook?.NumberOnOrder}
              </p>
              <p className="book-details-value">
                {selectedBook?.NumberOfShelves}
              </p>
              <p className="book-details-value">
                {selectedBook?.NumberInCarts}
                <Popover
                content={InfoCarts}
                trigger={["click","hover"]}
                placement="bottomLeft"
                getPopupContainer={(trigger: any) => trigger.parentNode}
              >
                <img className="infoCart-icon" src={infoIcon} alt="info" />
              </Popover>
              </p>
              <p className="book-details-value">
              {selectedBook?.LastPurchasedDate ? dateModify(selectedBook?.LastPurchasedDate)
              :
              'N/A'
              }
              </p>
              <p className="book-details-value">
              {selectedBook?.LastCheckoutDate ? dateModify(selectedBook?.LastCheckoutDate)
              :
              'N/A'
              }
              </p>
              <p className="book-details-value">{selectedBook?.TargetAudienceCodes?.toString()}</p>
              <p className="book-details-value">{biblio?.AudienceType !== undefined ?'Bibliotheca:'+' '+biblio?.MinValue+' '+'-'+' '+biblio?.MaxValue:null}</p>
              <p className="book-details-value">{interestAge?.AudienceType !== undefined ?'Interest Age:'+' '+interestAge?.MinValue+' '+'-'+' '+interestAge?.MaxValue:null}</p>
              <p className="book-details-value">{usGrade?.AudienceType !== undefined ?'US Grade:'+' '+usGrade?.MinValue+' '+'-'+' '+usGrade?.MaxValue:null}</p>
            </Col>
          </Row>
          <Row className="details-container">
            <Col span={24}>
              <span className="extra-details-title">Description</span>
            </Col>
            <Col span={24} >
              <p className="extra-details" dangerouslySetInnerHTML={{__html: selectedBook?.ItemDscr}}/>
            </Col>
          </Row>
        </Col>
      </Row>
      </SpinnerLoader>
      <NewCartModal isModalVisible={showModal} setModalVisible={setShowModal} appUser={appUser} isNewCartDetailsNeed={true}/> 
    </div>
  );
};
