/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Routers from "./web/router";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import {
  clearRoutPath,
  clearUser,
  userStateSelector,
} from "./redux/reducers/user/userReducer";
import "./App.css";
import { logOut } from "./redux/actions/user/authAction";
import { Button, message, Modal } from "antd";
// import moment from "moment";
// import packageJson from "../package.json";
import { clearGroups } from "./redux/reducers/groups/groupListReducer";
import { getNewAppData } from "./redux/actions/app/appActions";
import { clearFilters } from "./redux/reducers/books/bookListReducer";
import {
  clearSavedSearchFilter,
  clearSearchFilter,
} from "./redux/reducers/search/searchReducer";
import {
  clearCartFilters,
  clearColumns,
  clearResizeColumns,
  clearselectedCols,
  clearCartDetailFilter
} from "./redux/reducers/carts/cartsReducer";
import {
  clearPurchaseFilter,
  clearResizeCompletedCols,
  clearResizeDetailsCols,
  clearResizePendingCols,
  clearResizePurchaseCols,
} from "./redux/reducers/purchases/purchases";
import { clearShelfColomns } from "./redux/reducers/shelves/shelfReducer";
import { getConfigJson } from "./environment/envUtility";
import { clearRetainedPageSize } from "./redux/reducers/app/sideBarReducer";

function App(props: any) {
  const dispatch = useDispatch();
  let timeout: NodeJS.Timeout;
  const { appUser, libLogUser, routPathName,showSpotLight } = useSelector(userStateSelector);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [, setLogoutCalled] = useState(false);
  const [googleId, setGoogleId] = useState<string>("");
  const [timeoutEvent, setTimeoutEvent] = useState<NodeJS.Timeout>();
  const [isGaInitialise, setIsGaInitialise] = useState<boolean>(false);
  const [configJsonData, setConfigJsonData] = useState<any>([]);
  const [configCacheJsonData, setConfigCacheJsonData] = useState<any>([]);
  const [stopReloading, setStopReloading] = useState<boolean>(false);
  const currentURL = window?.location?.href;
    //  get env if cache cleared manualy
  window.addEventListener("storage", (event) => {
    if (
      localStorage.getItem("environment") !== null &&
      localStorage.getItem("appVersion") !== null
    ) {
      return undefined;
    } else {
      if (
        routPathName !== "/login" &&
        routPathName !== "/impersonatelibrarian" &&
        routPathName !== "/forgotPassword" &&
        routPathName !== "/resetPassword" &&
        routPathName !== "/"
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        window.location.reload();
      }
    }
  });

  const signOut = () => {
    dispatch(logOut(appUser?.userId));
    dispatch(clearUser());
    dispatch(clearGroups());
    dispatch(getNewAppData());
    dispatch(clearFilters());
    dispatch(clearSearchFilter());
    dispatch(clearCartDetailFilter());
    dispatch(clearSavedSearchFilter());
    dispatch(clearColumns());
    dispatch(clearResizeColumns());
    dispatch(clearselectedCols());
    dispatch(clearResizePurchaseCols());
    dispatch(clearResizeCompletedCols());
    dispatch(clearResizePendingCols());
    dispatch(clearResizeDetailsCols());
    dispatch(clearCartFilters());
    dispatch(clearShelfColomns());
    dispatch(clearPurchaseFilter());
    dispatch(clearRetainedPageSize());
    getConfigJson();
  };
  //for QA GA4 ID - G-8Y3520ZL4P 

  useEffect(() => {
    if (googleId !== "" && googleId !== undefined) {
      //conection to googoogle analytics
      ReactGA.initialize(googleId);
      setTimeout(() => {
        setIsGaInitialise(true);
      }, 100);
    }
  }, [googleId]);

  useEffect(() => {
    if (isGaInitialise) {
      if (routPathName !== "" && routPathName !== "/") {
        ReactGA.send({ hitType: "pageview", page:routPathName});
      } else {
        ReactGA.send({ hitType: "pageview", page:"/login"});
      }
    }
  }, [isGaInitialise, routPathName]);

  useEffect(() => {
    return () => {
      setIsGaInitialise(false);
      dispatch(clearRoutPath());
      let count: any = 0;
      localStorage?.setItem("refreshCount", count);
    };
  }, []);

  const sessionExpire = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setIsShowModal(true);
      document.removeEventListener("mousedown", sessionExpire);
      document.removeEventListener("keydown", sessionExpire);
      document.removeEventListener("mousemove", sessionExpire);
    }, 1000 * 60 * 120);
  };
  useEffect(() => {
    if (isAuthenticated) {
      document.addEventListener("mousedown", sessionExpire);
      document.addEventListener("keydown", sessionExpire);
      document.addEventListener("mousemove", sessionExpire);
    }
    return () => {
      clearTimeout(timeout);
      document.removeEventListener("mousedown", sessionExpire);
      document.removeEventListener("keydown", sessionExpire);
      document.removeEventListener("mousemove", sessionExpire);
    };
  }, [isAuthenticated]);

  const handleDecline = () => {
    setIsShowModal(false);
    dispatch(logOut(appUser?.userId));
  };
  const handleYes = () => {
    setIsShowModal(false);
    document.addEventListener("mousedown", sessionExpire);
    document.addEventListener("keydown", sessionExpire);
    document.addEventListener("mousemove", sessionExpire);
  };

  useEffect(() => {
    if (timeoutEvent) {
      clearTimeout(timeoutEvent);
      setLogoutCalled(false);
    }
    isShowModal &&
      setTimeoutEvent(
        setTimeout(() => {
          setIsShowModal(false);
          setLogoutCalled(true);
          dispatch(logOut(appUser?.userId));
        }, 1000 * 30)
      );
  }, [isShowModal]);

  useEffect(() => {
    if (appUser?.email !== undefined) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [appUser]);

  //find update if exist clear cachce of browser
  useEffect(() => {
    setStopReloading(false)
    var Versn = localStorage.getItem("appVersion");
    if (JSON.stringify(Versn) !== "null") {
      fetch("../config.json")
        .then((response) => response.json())
        .then((data) => {
          setGoogleId(data?.googleAnalyticsId)
          setConfigCacheJsonData({
            environment: localStorage?.getItem("environment"),
            appVersion: localStorage?.getItem("appVersion"),
            reportsUrl: localStorage?.getItem("reportsUrl"),
          });
          return setConfigJsonData(data);
        });
    } else {
      setTimeout(() => {
        fetch("../config.json")
          .then((response) => response.json())
          .then((data) => {
            setGoogleId(data?.googleAnalyticsId)
            localStorage.setItem("environment", data?.serviceUrl);
            localStorage.setItem("appVersion", data?.version);
            localStorage.setItem("reportsUrl", data?.reportsUrl);
            localStorage.setItem("reportsUrl", data?.reportsUrl);
            return setTimeout(() => {
              setStopReloading(true)
              setConfigJsonData(data);
              setConfigCacheJsonData(data);
            }, 500);
          });
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (
      configJsonData?.version !== undefined &&
      configCacheJsonData?.appVersion !== undefined
    ) {
      if (configJsonData?.version !== configCacheJsonData?.appVersion) {
        localStorage.clear();
        signOut();
        setTimeout(()=>{
          message.success({
            content: "We've found an update. Please login again",
            key: "updateFoundMessage",
            duration: 4,
          })
        },2000)
      } else {
        setGoogleId(configJsonData?.googleAnalyticsId)
        return undefined;
      }
    }else{
      if(stopReloading){
        window.location.reload();
        setStopReloading(false)
      }
    }
  }, [configJsonData, configCacheJsonData,stopReloading]);

  const params = new URLSearchParams(window.location.search);
  let token = params.get("token");
  let nature = params.get("nature");
  let actionRequestID = params.get("actionRequestID");
  let actionRequestSecret = params.get("actionRequestSecret");

  useEffect(() => {
    if (nature) {
      if (actionRequestID && actionRequestSecret) {
        let user = params.get("user");
        signOut();
        setTimeout(() => {
          const urlReset = `${window.location.origin}/setInitialPassword?user=${user}&token=${token}&actionRequestID=${actionRequestID}&actionRequestSecret=${actionRequestSecret}`;
          window.location.href = urlReset;
        }, 1000);
      } else {
        let user = params.get("user");
        signOut();
        setTimeout(() => {
          const urlReset = `${window.location.origin}/resetPassword?user=${user}&token=${token}`;
          window.location.href = urlReset;
        }, 1000);
      }
    }
  }, [nature, actionRequestID, actionRequestSecret, token]);

  return (
    <div>
      <Routers
        // eslint-disable-next-line eqeqeq
        isAuthenticated={appUser?.success == 1 ? true : false}
        appUser={appUser}
        libLogUser={libLogUser}
        showSpotLight={showSpotLight}
      />
      <Modal
        wrapClassName="logoutModal"
        className="timeLogOutModal"
        visible={isShowModal}
        closable={false}
        maskClosable={false}
        onOk={handleDecline}
        onCancel={handleYes}
        footer={[
          <Button key="back" className="yes-button" onClick={handleYes}>
            Yes
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="decline-button"
            onClick={handleDecline}
          >
            Decline
          </Button>,
        ]}
      >
        <p className="modalMsg">Are you still using the system ?</p>
      </Modal>
    </div>
  );
}

export default App;
