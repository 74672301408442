import React from 'react';
import { Row, Modal } from "antd";
import Button from "../../../stateless/common/button";
import { useSelector } from "react-redux";
import { PurchaseStateSelector } from '../../../../../redux/reducers/purchases/purchases';
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
const EditPoReferenceModel = ( props: any) => {
    const {editPOReference, setEditPOReference, editConfirm,message,message2,inputbox,label,showmsg,setPOReferenceChange} = props;
    const {preSaleFormState} = useSelector(PurchaseStateSelector);
    const handleYesSubmit =() =>{
      editConfirm()
      setEditPOReference(false)
      setPOReferenceChange(undefined)
    }
    const onCancelModel=()=>{
      setPOReferenceChange(undefined)
        setEditPOReference(false)
      }
    const handleNoSubmit = () => {
      setPOReferenceChange(undefined)
        setEditPOReference(false);
    }
  return (
    <Modal
    wrapClassName="purchase-modal edit-PO-reference"
    visible={editPOReference}
    onCancel={onCancelModel}
    footer={
          <Row justify="space-around">
          <Button
            type="primary confirm-button"
            onClick={handleYesSubmit}
          >
            Yes
          </Button>
          <Button
            type="smallRed confirm-button"
            onClick={handleNoSubmit}
          >
            No
          </Button>
          </Row>
      }
    >
        <SpinnerLoader loading={preSaleFormState.loading}>
        <p className="purchase-title">{props.heading}</p>
        <div className="remove-content">
        <p className='purchaseNote'>
          {showmsg === true ? <>{message} <br /></> : ""}  {message2}
        </p>
        </div>
        
        <div className="PO-reference-text">
           {label} {inputbox}
          
        </div>
       
        </SpinnerLoader>
    </Modal>
  )
};

export default EditPoReferenceModel;
