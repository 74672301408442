import React from "react";
import "./button.less";

const Button = (props: any) => {
  return (
      <button
      id={props?.id}
      className={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button> 
  );
};
export default Button;
