import { createAsyncThunk } from "@reduxjs/toolkit";
import { 
  AppVersionService } from "../../../web/services/user/authService";
const appVersionSrv = new AppVersionService()

export const setSideBarItem = createAsyncThunk(
    "api/setsidebaritem",
    async (data: any, thunkAPI) => {
      try {
        if (data) { 
          return data;
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const switchSearchSideBar = createAsyncThunk(
    "api/editSearch",
    async (data: boolean, thunkAPI) => {
      try {
        return data;
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

  export const collapseSideBar = createAsyncThunk(
    "api/collapseSideBar",
    async (data: boolean, thunkAPI) => {
      try {
        return data;
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

  export const getNewAppData = createAsyncThunk(
    "api/getNewAppData",
    async (_args, thunkAPI) => {
      try {
        const  data = await appVersionSrv.getNewAppVersion();
        if (data) { 
          return data;
        }else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const getRoutPathName = createAsyncThunk(
    "api/getRoutPathName",
    async (_args:string, thunkAPI) => {
      try {
        const data = _args;
        if (data) { 
          return data;
        }else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );


