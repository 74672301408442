/* eslint-disable eqeqeq */
export const validateLendingModelType = (data: any) => {
    var period = "";
    var output: any = [];
    for (var key in data) {
      if (data[key].ContentExpirationRuleType) {
        switch (data[key].ContentExpirationRuleType) {
          case "CheckoutCount": period = " checkout"; break;
          case "MonthsFromPurchaseDate": period = " month"; break;
          case "YearsFromPurchaseDate": period = " year"; break;
          case "DaysFromPurchaseDate": period = " day"; break;
          default: period = " unknown"; break;
        }
        if (data[key].Value != 1)
          period = period + "s";
        if (data[key].ContentExpirationRuleType != "CheckoutCount")
          period += " after purchase";
        output[key] = data[key].Value + period;
      }
    }
    return output.join(' or ')
  }

  