import { createAsyncThunk } from "@reduxjs/toolkit";
import { BookService } from "../../../web/services/books/bookService";

const bookSrv = new BookService();

export const fetchBooks = createAsyncThunk(
    "api/fetchBooks",
    async (_args: any, thunkAPI) => {
      try {
        const data = await bookSrv.fetchBooksByCategory({payload:_args?.payload,token:_args?.signal?.token})
        if (data && data?.books) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const fetchBooksForSpotLight = createAsyncThunk(
    "api/fetchBooksForSpotLight",
    async (_args: any, thunkAPI) => {
      try {
        const data = await bookSrv.fetchBooksByCategory({payload:_args?.payload,token:_args?.signal?.token})
        if (data && data.FeatureListName) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

export const fetchCartsByBookId = createAsyncThunk(
  "api/fetchCartsforBook",
  async (_args: any, thunkAPI) => {
    try {
      const data = await bookSrv.fetchCartsforBook(_args)
      if (data) { 
        var bookCartData = {
          book: _args.bookId,
          carts: data
        }
        return bookCartData;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchBookbyId = createAsyncThunk(
  "api/fetchBookDetails",
  async (_args: any, thunkAPI) => {
    try {
      const data = await bookSrv.fetchBookDetails(_args)
      if (data) {
        let bookCarts =  await bookSrv.fetchCartsforBook(_args)
        var bookdata = {...data, carts: bookCarts}
        return bookdata;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const onApplyingFilters = createAsyncThunk(
  "api/onApplyingFilters",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) { 
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const pageRememberBooklist = createAsyncThunk(
  "api/pageRememberBooklist",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) { 
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const isPageChange = createAsyncThunk(
  "api/isPageChange",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) { 
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const selectAllRemember = createAsyncThunk(
  "api/selectAllRemember",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
        return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const setCheckedValues = createAsyncThunk(
  "api/setCheckedValues",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
        return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const setSelectAllChkBox = createAsyncThunk(
  "api/setSelectAllChkBox",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
        return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const setSelectedTitles = createAsyncThunk(
  "api/setSelectedTitles",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
        return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const setGlobalCopy = createAsyncThunk(
  "api/setGlobalCopy",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
        return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);