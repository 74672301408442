import React from 'react'
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";

const PublicationDate = (props:any) => {

    const { appUser } = useSelector(userStateSelector);

    var dateFormat = appUser?.libraryData ? appUser.libraryData[0]?.DateFormat.toUpperCase() : ""

      const dateModify = (date: any) => {
        const myStr = date?.substr(0, 10);
        const currentDate = moment(myStr)
          .tz("Etc/GMT")
          .format(dateFormat);
        return currentDate;
      };

  return (
    <div>
      {
      !(props.date) ? "N/A" :
      props.date?.length < 10 ? "N/A" : dateModify(props.date)}
      </div>
  )
}

export default PublicationDate