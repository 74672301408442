import React, { useState, useEffect } from "react";
import { Row, Col, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PayPerUse } from "./settingTabs/payPerUse";
import { PatronsSettings } from "./settingTabs/patron";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { useHistory } from "react-router-dom";
import {
  fetchLibraryLanguage,
  fetchLibrarySettings,
} from "../../../../redux/actions/settings/settings";
import {
  clearSettingsState,
  setSettingTab,
  SettingStateSelector,
} from "../../../../redux/reducers/settings/settingsReducer";
import User from "../../../models/user/user";
import Library from "./settingTabs/library";
import LibraryStuff from "./settingTabs/libraryStuff";
import IsUnsaveDataModel from "../purchases/stayModel/leaveWithoutSaveModal";
import { fetchUsers } from "../../../../redux/actions/user/userAction";

const Settings = () => {
  const { TabPane } = Tabs;
  const history = useHistory();
  const dispatch = useDispatch();
  const { appUser, users } = useSelector(userStateSelector);
  const { selectedTab,libLanguage,libSettingsData } =
    useSelector(SettingStateSelector);
  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showStayModel, setShowStayModel] = useState<boolean>(false);
  const [unSaveData, setUnSaveData] = useState<boolean>(false);
  const [tempTabValue, setTempTabValue] = useState(selectedTab)
  const [urlpath, setUrlpath] = useState<string>("");

  const handleSelect = (e: string) => {
    if(unSaveData){
      setTempTabValue(e)
      setShowStayModel(true)
    } else {
      dispatch(setSettingTab(e))
      setTempTabValue(e)
    }
  };

  useEffect(() => {
    if (libSettingsData?.length < 1) {
      dispatch(
        fetchLibrarySettings({
          libraryId: lib_Id,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lib_Id,libSettingsData]);

  useEffect(() => {
    const userLength = users && users?.length ? users?.length : 0;
    if (userLength <= 0) {
      if(appUser?.isLibraryAdmin || appUser?.IsPatronCommunicator){
        dispatch(
          fetchUsers({
            libId: lib_Id,
            activeOnly: 1
          })
        );
      }
    }
    return () => {
      dispatch(clearSettingsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (libLanguage?.length < 1) {
      dispatch(fetchLibraryLanguage(lib_Id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lib_Id,libLanguage]);

  const sorterFun = (data: any, type: number) => {
    if (type === 0) {
      var sortedList = data?.sort((a: any, b: any) => {
        const nameA = a.text.toLowerCase();
        const nameB = b.text.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else {
      sortedList = data?.sort((a: any, b: any) => {
        const nameA = a.text.toLowerCase();
        const nameB = b.text.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    return sortedList;
  };

  const getUserOptions = (type: number) => {
    if (type === 1) {
      var temp = users?.filter((data: User) => {
        return data.isActive && data.isLibraryBuyer;
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      var temp = users?.filter((data: User) => {
        return data.isActive && (data.isLibraryBuyer || data.isLibraryUser);
      });
    }
    return temp?.map((user: User) => {
      return { text: `${user.firstName} ${user.lastName}`, value: user.userId };
    });
  };

  const handleStayYes = () => {
    setUnSaveData(false)
    setShowStayModel(false)
    dispatch(setSettingTab(tempTabValue))
    if(urlpath !== ""){
      setTimeout(()=>{
        history.push(urlpath)
      },50)
      setUrlpath("")
    }

  };

  useEffect(() => {
    const unblock = history.block(({ pathname }) => {
      if (!unSaveData) {
        unblock();
        history.push(pathname);
      } else{
        setShowStayModel(true)
        setUrlpath(pathname)
        return false;
      }
    });
  }, [unSaveData, history]);

  return (
    <>
      <Row className="head-container">
        <Col xs={12} sm={14} md={18} lg={20} xl={20}>
          <p className="page-title">Configuration</p>
        </Col>
      </Row>
      <div>
        <Tabs
          className="cart-tabs"
          defaultActiveKey={!appUser?.isLibraryAdmin ? "4" : selectedTab}
          activeKey={!appUser?.isLibraryAdmin ? "4" : selectedTab}
          onChange={handleSelect}
        >
          {appUser?.isLibraryAdmin ? 
          <>
          <TabPane tab="Library" key="1">
            <Library libId={lib_Id} />
          </TabPane>
            <TabPane tab="Patron" key="2">
              <PatronsSettings libId={lib_Id} setUnSaveData={setUnSaveData} unSaveData={unSaveData} />
            </TabPane>
          <TabPane tab="Library Staff" key="3">
            <LibraryStuff libId={lib_Id} appUser={appUser} />
          </TabPane>
        </>
: null
}
          {appUser?.isLibraryBuyer || appUser?.isLibraryAdmin ? (
            <TabPane tab="Pay Per Use" key="4">
              <PayPerUse
                libId={lib_Id}
                managerOptions={sorterFun(getUserOptions(1), 1)}
                notifyOptions={sorterFun(getUserOptions(0), 0)}
              />
            </TabPane>
          ) : null}
        </Tabs>
      </div>
      <IsUnsaveDataModel
        IsUnsaveDataShow={showStayModel}
        setDeclineUnsaveData={setShowStayModel}
        handleStayYes={handleStayYes}
        message={"Are you sure you want to leave without saving?"}
      />
    </>
  );
};

export default Settings;
