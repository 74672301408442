// Base Imports
import React from "react";
import { Redirect, Route } from "react-router-dom";

// Other Imports


export const PrivateRoute = (props: any) => {
  const { isAuthenticated, libLogUser } = props;

  // eslint-disable-next-line eqeqeq
  if (isAuthenticated && isAuthenticated == true) {
    return (
      <Route {...props} component={props.component} render={undefined} />
    );
  }
  return (
    <>
      {libLogUser ?
        <Redirect
          to={{ pathname: '/impersonatelibrarian', state: { from: props.location } }}
        />
        :
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      }
    </>
  );
};
