import { createAsyncThunk } from "@reduxjs/toolkit";
import { GroupService } from "../../../web/services/groups/groupService";

const groupService = new GroupService();

export const fetchGroupList = createAsyncThunk(
    "api/fetchGroups",
    async (_args : any, thunkAPI) => {
      try {
        const data = await groupService.fetchGroups(_args)
        if (data && data.length) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const fetchUnshownGroups = createAsyncThunk(
    "api/fetchUnshownGroups",
    async (_args : any, thunkAPI) => {
      try {
        const data = await groupService.fetchGroups(_args)
        if (data && data.length) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const fetchGroupByType = createAsyncThunk(
    "api/fetchGroupByType",
    async (_args : any, thunkAPI) => {
      try {
        const data = await groupService.fetchGroupByType(_args)
        if (data && data.length) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const fetchCheckedGroupIds = createAsyncThunk(
    "api/fetchCheckedGroupIds",
    async (_args : any, thunkAPI) => {
      try {
        const data = await groupService.fetchCheckedGroupIds(_args)
        if (data && data.length) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );


  export const selectGroups = createAsyncThunk(
    "api/selectGroups",
    async (_args : any, thunkAPI) => {
      try {
        const data = await groupService.selectGroups(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );


  export const unSelectGroups = createAsyncThunk(
    "api/unSelectGroups",
    async (_args : any, thunkAPI) => {
      try {
        const data = await groupService.unSelectGroups(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const fetchContentCorner = createAsyncThunk(
    "api/fetchContentCorner",
    async (_args : any, thunkAPI) => {
      try {
        const data = await groupService.fetchContentCorner(_args)
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const retainSpotlightPosition = createAsyncThunk(
    "api/retainSpotlightPosition",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const retainSwtichValue = createAsyncThunk(
    "api/retainSwtichValue",
    async (_args : boolean, thunkAPI) => {
      try {
        const data = _args
        return data;
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );