/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { CartStateSelector } from "../../../../../redux/reducers/carts/cartsReducer";

export const CompWrapper = (props: any) => {
    const [height, setHeight] = useState(500)
    const [scrollValue,setScrollValue] = useState(0);
    const {loading,getscrollHeight}=props;
    const{audioScrollHeight,ebookScrollHeight}=useSelector(CartStateSelector);

    /*  props used : name , otherClass, observe.
        name : it contain class that used to calculate height.
        otherClass : it contain extra classes.
        observe : it contain class of that element which impact height
    */

    const calHeight = () => {
        let targetComp, targetElement: any, elementHeight: number;
        targetComp = document.querySelector<HTMLElement>(`.${props.name}`); 
        targetElement = targetComp?.getBoundingClientRect().y ?? 0
        elementHeight = document.body.clientHeight;
        setHeight(elementHeight - targetElement);
    }

    useEffect(() => {
        calHeight()
        const resizeObserver = new ResizeObserver(() => {
            calHeight()
        })
        resizeObserver.observe(document.body)
        if (props.observe !== null) {
            let node;
            node = document.querySelector<HTMLElement>(`.${props.observe}`);

            if (node !== null) {
                resizeObserver.observe(node)
            }
        }
        return ()=>{
            resizeObserver.disconnect();
        }
    }, [])

    useEffect(()=>{
        if(loading === false){
          const element =document.getElementById(`scroll_${props.otherClass}`)
        if(`scroll_${props.otherClass}`=== "scroll_scroll"){
            element?.scrollTo(0,ebookScrollHeight)
        }else{
            element?.scrollTo(0,audioScrollHeight)
        }
        }
      },[loading])
    
      const handleScroll=()=>{
        if(loading === false){
          const element =document.getElementById(`scroll_${props.otherClass}`)
          let x = element?.scrollTop;
          x && setScrollValue(x)
          getscrollHeight(scrollValue)
        }
      }
      
    return (
        <>
            <div style={{ height: height }}
                className={`scrollContentAutoCart scrollStyle ${props.otherClass}`} id={`scroll_${props.otherClass}`} onScroll={ handleScroll }>
                {props.children}
            </div>
        </>
    )
}
