/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback,useState } from "react";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { checkLibrarianEmail } from "../../../../../../redux/actions/user/authAction";
import { userStateSelector, clearState, clearErrorMsg } from "../../../../../../redux/reducers/user/userReducer";
import SpinnerLoader from "../../../../stateless/common/spinnerLoader";
import { LibrarianLoginForm } from "../../../../stateless/librarianLogin/auth/librarianLoginForm";
import { LibAuthInput } from "../../../../../models/user/libAuthInput";

export const ValidateLibrarianEmail = (props: any) => {
    const dispatch = useDispatch();
    const [appVersion, setAppVersion] = useState<string>(JSON.stringify(localStorage?.getItem("appVersion")));
    const { formState,authErrMsg } = useSelector(userStateSelector);
    const onLoginCallback = useCallback((data: any) => {

        if (!data.username) {
            return;
        }

        let authUser: LibAuthInput = {
            username: data.username,
            password: data.password,
            email: data.librarianemail,
        };

        dispatch(checkLibrarianEmail(authUser));
    }, []);

      useEffect(()=>{
        setAppVersion(JSON.stringify(localStorage.getItem("appVersion")))
      },[localStorage.getItem("appVersion")])
  
    useEffect(() => {
        if (formState.isError) {
            message.error({
                key: "error",
                content: "Request Failed.",
            })
            dispatch(clearState())
        }
        if(authErrMsg.error !== undefined){
            message.error({
                key: "error",
                content: authErrMsg?.error,
            })
            dispatch(clearState())
        }
    }, [formState.isError,authErrMsg])
    useEffect(()=>{
        dispatch(clearErrorMsg())
        setAppVersion(JSON.stringify(localStorage.getItem("appVersion")))
        return(()=>{
            dispatch(clearErrorMsg())
        })
        },[])

    return (
        <>
            <SpinnerLoader loading={formState.loading}>
                <LibrarianLoginForm formState={formState} loginCallback={onLoginCallback} appVersion={appVersion} />
            </SpinnerLoader>
        </>
    )
}