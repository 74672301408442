/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Input, Form, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../stateless/common/button";
import {
  getSearchString,
  getUnmatchValues,
  onBackFillForm,
  onStringBackFillForm,
} from "../../../../../redux/actions/search/searchActions";
import {
  clearMatchIsbnData,
  clearResults,
  clearState,
  SearchstateSelector,
} from "../../../../../redux/reducers/search/searchReducer";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../../router/appRoutes";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import CSVReader from "react-csv-reader";
import FormItem from "antd/lib/form/FormItem";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { Page } from "../../../../constants/constants";
import { CSVLink } from "react-csv";
import {
  setGlobalCopy,
  setSelectedTitles,
} from "../../../../../redux/actions/books/bookActions";

export const IsbnSearch = (props: any) => {
  const { libraryId, isCollection } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [unmatchTextVal, setUnmatchTextVal] = useState<any>("");
  const {
    searchFormState,
    backFormFill,
    formState,
    selectedFilters,
    searchCollecFormState,
    RecordsNotMatched,
    MatchedPrice,
    NotMatchedData,
  } = useSelector(SearchstateSelector);
  const [isbnList, setIsbnList] = useState<any>([]);
  const { appUser } = useSelector(userStateSelector);
  var currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";
  var userCatalog =
    appUser && appUser.libraryData ? appUser.libraryData[0].NatureId : 0;
  const [strinDetails, setStrinDetails] = useState<any>({
    isbn: "",
  });
  const csvExportList = {
    filename: `unmatchedIsbnList.csv`,
    data: unmatchTextVal,
  };
  const { TextArea } = Input;

  const [form] = Form.useForm();

  const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index && value !== "";
  };

  //regex for replace comma,semicolon,whitespce,enterkey
  const regexForStringReplace = /;|,|\n|:| /gi;

  const removeDuplicateAndLongEISBN = (data:any) => {
    const regex = /^\d{13}$/;
    return data.reduce((accumulator:any, current:any) => {
      // Check if EISBN has more than 13 digits or if it's a duplicate
      if (!accumulator.some((item:any) => item.EISBN.length > 13 && item.EISBN === current.EISBN) && regex.test(current.EISBN)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
  };

  const handleForce = (data: any, fileInfo: any) => {
    if (
      (data && data[0]?.hasOwnProperty("EISBN")) ||
      data[0]?.hasOwnProperty("ISBN") ||
      data[0]?.hasOwnProperty("eISBN")
    ) {
      var objectLength = Object?.keys(data[0])?.length;
      if (
        (objectLength >= 1 && data[0].hasOwnProperty("EISBN")) ||
        data[0]?.hasOwnProperty("ISBN") ||
        data[0]?.hasOwnProperty("eISBN")
      ) {
        const dataFromCsvTable = Array?.from(
          new Set(data?.map((obj: any) => obj.ISBN || obj.eISBN || obj.EISBN))
        )?.map((isbn) => ({ EISBN: isbn }));
        const uniqueISBNs = removeDuplicateAndLongEISBN(dataFromCsvTable);
        setIsbnList(uniqueISBNs);
        var csvData: any = uniqueISBNs?.map((item: any) => {
          return item?.EISBN;
        });
        var csvVal = csvData?.toString();
        dispatch(onBackFillForm({ isbn: "" }));
        dispatch(getSearchString({ isbn: csvVal }));
      } else {
        message.error("No ISBN number found, please upload valid CSV file.");
      }
    } else {
      if (data && data.length >= 1) {
        const newData = data
          ?.filter((obj: any) => {
            const value = String(obj[Object.keys(obj)[0]]);
            const regex = /^\d{13}$/;
            return regex.test(value) && value.length === 13 ;
          })
          ?.map((obj: any) => ({ EISBN: String(obj[Object.keys(obj)[0]]) }));
        if (newData?.length !== 0) {
          setIsbnList(newData);
          var csvData: any = newData?.map((item: any) => {
            return item?.EISBN;
          });
          var csvVal = csvData?.toString();
          dispatch(onBackFillForm({ isbn: "" }));
          dispatch(getSearchString({ isbn: csvVal }));
        } else {
          message.error("No ISBN number found, please upload valid CSV file.");
        }
      } else {
        message.error("No ISBN number found, please upload valid CSV file.");
      }
    }
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toUpperCase().replace(/\W/g, "_"),
  };

  const onInput = (e: any) => {
    setValue(e.target.value);
    const inputValue = e.target.value;
    const inputval = inputValue.replace(regexForStringReplace, ",").split(",");
    const inputIsbn = inputval.filter(onlyUnique).toString();
    setStrinDetails({ ...strinDetails, isbn: inputIsbn });
  };
  const handleSearchClick = () => {
    var temp: any = value.replace(regexForStringReplace, ",").split(",");
    var uniqueValues = temp.filter(onlyUnique);
    dispatch(onBackFillForm(strinDetails));
    dispatch(getSearchString(strinDetails));
    dispatch(clearResults());
    dispatch(clearState());
    setIsbnList(
      uniqueValues.map((term: any) => {
        return {
          EISBN: term,
        };
      })
    );
  };

  useEffect(() => {
    if (formState?.isSuccess) {
      dispatch(clearResults());
      dispatch(clearState());
    } else {
      return undefined;
    }
  }, [formState]);

  const clearButton = () => {
    dispatch(onBackFillForm([]));
    dispatch(onStringBackFillForm([]));
    dispatch(getUnmatchValues([]));
    dispatch(clearMatchIsbnData());
    form.resetFields();
  };
  useEffect(() => {
    if (isbnList.length) {
      var state: any = {
        isbnSearch: {
          list: isbnList,
          libId: libraryId,
          sortField:
            selectedFilters.sortOrder !== undefined
              ? selectedFilters.sortOrder
              : null,
          sortDirection:
            selectedFilters.sort !== undefined ? selectedFilters.sort : "asc",
          catalogID: userCatalog,
          recordCount:
            selectedFilters.pageSize !== undefined
              ? selectedFilters.pageSize
              : Page.DEFAULT_COUNT,
          recordStart: Page.DEFAULT_START,
        },
        cameFromIsbn: true,
        isCameFromAdvanceSearch: true,
        gaString: {
          byFrom: "onIsbnSearchbtn",
          gaData: strinDetails,
        },
      };
      if (isCollection) {
        history.push(AppRoutes.COLLECTIONSEARCHRESULTS, state);
      } else {
        history.push(AppRoutes.SEARCHRESULTS, state);
      }
    }
  }, [isbnList, isCollection]);

  const handleInputBlur = (e: any) => {
    if (e.relatedTarget) {
      if (e.relatedTarget.id === "isbn-search-btn") {
        handleSearchClick();
      } else if (e.relatedTarget.id === "isbn-clear-btn") {
        clearButton();
      }
    }
  };
  const defaVal = (data: any) => {
    if (data !== undefined && data !== "") {
      return data;
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (backFormFill.isbn !== undefined) {
      setValue(defaVal(backFormFill?.isbn));
      setStrinDetails({ ...strinDetails, isbn: defaVal(backFormFill?.isbn) });
    } else {
      form.resetFields();
    }
  }, [backFormFill]);

  useEffect(() => {
    dispatch(setSelectedTitles([]));
    dispatch(setGlobalCopy(1));
    return () => {
      dispatch(clearMatchIsbnData());
    };
  }, []);

  useEffect(() => {
    setUnmatchTextVal(
      NotMatchedData ? NotMatchedData?.toString().split(",").join("\n") : ""
    );
  }, [NotMatchedData]);

  return (
    <div className="unmatched_isbn_scroll scrollStyle">
      <SpinnerLoader
        loading={
          isCollection
            ? searchCollecFormState?.loading
            : searchFormState?.loading
        }
      >
        <Row
          justify="space-between"
          className="isbn-container"
          gutter={[0, 40]}
        >
          <Col
            sm={24}
            md={24}
            lg={11}
            xl={11}
            className="isbn-input-col search_by_isbn_order"
          >
            <Row>
              <p className="isbn-title">Search By ISBN</p>
            </Row>
            <Form onFinish={handleSearchClick} form={form}>
              <FormItem
                initialValue={defaVal(backFormFill?.isbn)}
                name="isbn-list"
                rules={[
                  { required: true, message: "Please enter ISBN number!" },
                  {
                    pattern: /^[0-9,:\n; ]*$/,
                    message: `Please enter valid ISBN numbers (separated by comma, semicolon, colon or space)`,
                  },
                ]}
              >
                <TextArea
                  name="isbn-list"
                  placeholder="Copy and Paste or enter ISBN number(s) here"
                  className="card-input-with-search isbnSearchScroll scrollStyle "
                  bordered={false}
                  onChange={onInput}
                  autoSize
                  onBlur={handleInputBlur}
                />
              </FormItem>
              <Row>
                <Col span={12} className="button-container">
                  <Button
                    type="primary uploadButton"
                    htmlType="submit"
                    id="isbn-search-btn"
                  >
                    Search
                  </Button>
                </Col>
                <Col span={11} offset={1} className="button-container">
                  <input
                    type="button"
                    className=" primary clear-isbn-button"
                    value="Clear"
                    id="isbn-clear-btn"
                    onClick={clearButton}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col
            sm={24}
            md={24}
            lg={11}
            xl={11}
            className="isbn-input-col csv-up-height isbn_csv_file_order"
          >
            <h1>Drag and Drop CSV File Here</h1>
            <p>or</p>
            <CSVReader
              accept=".csv"
              cssClass="react-csv-input"
              onFileLoaded={handleForce}
              parserOptions={papaparseOptions}
            />
            <p className="csv-text">
              ISBN number needs to be in first column of the file
            </p>{" "}
            <p className="csv-text">
              The first row of the file must be a header
            </p>
          </Col>
        </Row>
        {RecordsNotMatched?.length !== 0 ? (
          <Row className="isbn-container">
            <Col sm={24} md={24} lg={11} xl={11} className="isbn-input-col ccc">
              <Row>
                <Col className="unmatched_isbn_title">
                  Total not matched: {RecordsNotMatched}
                </Col>

                <Col className="unmatched_isbn_title">
                  Total for Matched Results: {currency}
                  {MatchedPrice > 0 ? MatchedPrice?.toFixed(2) : 0}
                </Col>
                {unmatchTextVal !== "" ? (
                  <Col
                    sm={8}
                    md={4}
                    lg={24}
                    xl={5}
                    xxl={5}
                    className="unmatched_isbn_export_list"
                  >
                    <CSVLink className="export-list-link" {...csvExportList}>
                      Export List
                    </CSVLink>
                  </Col>
                ) : null}
              </Row>
              {unmatchTextVal !== "" ? (
                <Row>
                  <TextArea
                    className="unmatched_isbn__detail isbnSearchScroll scrollStyle"
                    autoSize
                    readOnly={true}
                    value={unmatchTextVal}
                  />
                </Row>
              ) : null}
            </Col>
          </Row>
        ) : null}
      </SpinnerLoader>
    </div>
  );
};
