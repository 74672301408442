// Package Imports
import { combineReducers } from "@reduxjs/toolkit";
import sideBarReducer from "../reducers/app/sideBarReducer";
import bookListReducer from "../reducers/books/bookListReducer";
import cartsReducer from "../reducers/carts/cartsReducer";
import categoryReducer from "../reducers/categories/categoryReducer";
import GroupListReducer from "../reducers/groups/groupListReducer";
import searchReducer from "../reducers/search/searchReducer";
import shelfReducer from "../reducers/shelves/shelfReducer";
import UserReducer from "../reducers/user/userReducer";
import ExpReducer from "../reducers/expiration/expirationReducer";
import PurChsReducer from "../reducers/purchases/purchases";
import patronReducer from "../reducers/patron/patronReducer";
import settingsReducer from "../reducers/settings/settingsReducer"
import messagesReducer from "../reducers/messages/messages"
import reportsReducer from "../reducers/reports/reportsReducer";
import insightsReducer from "../reducers/insight/insightsReducer";
import marcReducer from "../reducers/marc/marcReducer";

const appReducer = combineReducers({
    user: UserReducer,
    groups: GroupListReducer,
    categories: categoryReducer,
    Carts: cartsReducer,
    Books: bookListReducer,
    sideBar: sideBarReducer,
    Shelves: shelfReducer,
    Search: searchReducer,
    Expiration: ExpReducer,
    Purchase:PurChsReducer,
    settings:settingsReducer,
    messages:messagesReducer,
    Patron:patronReducer,
    reports:reportsReducer,
    insights:insightsReducer,
    Marc:marcReducer,
});

export type RootState = ReturnType<typeof appReducer>;
export default appReducer;