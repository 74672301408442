import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import { fetchCategories } from "../../actions/categories/categoryAction";
import { CategoriesState } from "../../states/categories/categoryState";
import { RootState } from "../../store/rootReducer";

const initialState: CategoriesState = {
  categories: [],
  formState: resetState(),
};

export const CategorySlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      state.categories = [];
      return state;
    },
  },
  extraReducers: {
    [fetchCategories.fulfilled.toString()]: (state, { payload }) => {
      state.categories = payload;

      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
      }
      return state;
    },
    [fetchCategories.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchCategories.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
  },
});

export const { clearState } = CategorySlice.actions;
export const categorystateSelector = (state: RootState) => state.categories;
export default CategorySlice.reducer;
