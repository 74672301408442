import { createAsyncThunk } from "@reduxjs/toolkit";
import { InsightsService } from "../../../web/services/insights/insightsService";
const insightSrv = new InsightsService()

  export const getCirculationSummary = createAsyncThunk(
    "api/getCirculationSummary",
    async (payload : any, thunkAPI) => {
      try {
        const data = await insightSrv.fetchCirculationSummary(payload)
        if (data && data.length) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const setTab = createAsyncThunk(
    "api/setTab",
    async (payload : any, thunkAPI) => {
      try {
        const data = payload
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );