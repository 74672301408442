/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { checkEmail,SingleSignOn } from "../../../../../../redux/actions/user/authAction";
import { userStateSelector, clearState, clearErrorMsg } from "../../../../../../redux/reducers/user/userReducer";
import { UserAuthInput } from "../../../../../models/user/userAuthInput";
import { EmailLoginForm } from "../../../../stateless/user/auth/loginForm";
import SpinnerLoader from "../../../../stateless/common/spinnerLoader";
import "./validate.less";

export const ValidateEmail = (props: any) => {
  const dispatch = useDispatch();
  const { formState,authErrMsg } = useSelector(userStateSelector);
  const [appVersion, setAppVersion] = useState<string>(JSON.stringify(localStorage?.getItem("appVersion")));
  const search = useLocation().search;
  const [showLoading, setShowLoading] = useState(false);
  const id:string | null = new URLSearchParams(search).get('id');
  const onLoginCallback = useCallback((data:any) => {
    if (!data.email) {
      return;
    }

    let authUser: UserAuthInput = {
      username: data.email,
      password: data.password,
    };
    dispatch(checkEmail(authUser));
  }, []);

  useEffect(()=>{
    if(id !== null && id !== '' && appVersion !== "null"){
      dispatch(SingleSignOn(id));
      setShowLoading(true)
    }else{
      setShowLoading(false)
      return undefined
    }
  },[id,appVersion])


  useEffect(()=>{
    dispatch(clearErrorMsg())
    dispatch(clearState())
    setAppVersion(JSON.stringify(localStorage.getItem("appVersion")))
  return(()=>{
    dispatch(clearErrorMsg())
  })
  },[])

  useEffect(()=>{
    setAppVersion(JSON.stringify(localStorage.getItem("appVersion")))
  },[localStorage.getItem("appVersion")])
  
  useEffect(() => {
    if (formState.isError) {
        message.error({
            key: "error",
            content: "Request Failed.",
        })
        setShowLoading(false)
        dispatch(clearState())
    }
    if(authErrMsg?.error !== undefined){
        message.error({
            key: "error",
            content: authErrMsg?.error,
        })
        setShowLoading(false)
        dispatch(clearState())
    }
}, [formState.isError,authErrMsg])

  return (
    <>
    {showLoading}
     <SpinnerLoader loading={formState.loading}>
     <SpinnerLoader className="nestedLoading" loading={showLoading}>
       {
         !showLoading?
         <EmailLoginForm formState={formState} loginCallback={onLoginCallback} appVersion={appVersion}/>:
         <></>
       }
      </SpinnerLoader>
      </SpinnerLoader>
    </>
  )
}