import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { showError } from "../../../utility/appUtil";

export class CategoryService extends BaseService {
    constructor() {
        super(getAPIBaseUrl(PortalModule.CATEGORY));
      }
    async fetchCategoriesById (params: any): Promise<any[]| any> {
        const groupId = params.groupId
        const libraryId = params.libraryId
        const response = await this.get(`/getCategoriesById?lib_id=${libraryId}&group_id=${groupId}`);
        if(response.data){
            return response.data
        }
        else {
          showError(response?.data?.data)
          return null}
      }
}