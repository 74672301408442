/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Drawer, DatePicker, Input, message } from "antd";
import moment from "moment";
import Button from "../../stateless/common/button";
import { useDispatch, useSelector } from "react-redux";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { dropdownIcon } from "../../../images";
import "./messages.less";
import {
  createMessages,
  deleteMessages,
  fetchMessages,
} from "../../../../redux/actions/messages/messages";
import {
  clearMsgStatus,
  clearState,
  MessagesStateSelector,
} from "../../../../redux/reducers/messages/messages";
import Messages from "../../../models/messages/messagesModel";
import { MessagesList } from "./messageList";
import RemoveConfirmModel from "../carts/cartDetails/remove/removeModal";
import MessageSearchBar from "./MessageSearchBar";

export const MessagesMain = (props: any) => {
  // eslint-disable-next-line no-empty-pattern
  const {} = props;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { appUser } = useSelector(userStateSelector);
  const {
    formState,
    deleteformState,
    messagesData,
    messagesCount,
    createformState,
    defaultTab,
    isMessageCreated,
    isMessageDeleted,
  } = useSelector(MessagesStateSelector);
  const { TextArea } = Input;
  const [tabVal, setTabVal] = useState<string>(defaultTab);
  const [msg, setMsg] = useState<any>([]);
  const [historymsg, setHistoryMsg] = useState<any>([]);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [startDateVal, setStartDateVal] = useState<any>("");
  const [endDateVal, setEndDateVal] = useState<any>("");
  const [showRemove, setShowRemove] = useState(false);
  const [deleteData, setDeleteData] = useState<any>([]);
  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  const [createMessage, setCreateMessage] = useState<any>({
    LibraryId: `${lib_Id}`,
    EndDate: "",
    Message: "",
    StartDate: "",
    Subject: "",
    SourceMessageId: "",
    CreatedBy: "",
    CreatedOn: "",
  });
  const dateFormat = "MM/DD/YYYY";

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const showNotification = (content: any, type: any) => {
    if (type === "success") {
      message
        .success({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
        .then(() => {
          dispatch(clearMsgStatus());
        });
    }
  };

  useEffect(() => {
    if (isMessageCreated) {
      showNotification("Message Sent successfully", "success");
    } else if (isMessageDeleted) {
      showNotification("Message Deleted successfully", "success");
    } else {
      return undefined;
    }
  }, [isMessageCreated, isMessageDeleted]);

  useEffect(() => {
    setTabVal(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (messagesData.length < 1) {
      dispatch(
        fetchMessages({
          libraryId: lib_Id,
          fromDate: moment().subtract(1, "weeks").format(dateFormat),
          recordCount: "500",
          recordStart: "1",
          sortDirection: "ASC",
          sortField: "CreatedOn",
          toDate: moment().format(dateFormat),
        })
      );
    }
  }, [lib_Id]);

  useEffect(() => {
    // historyData(messagesData);
    setMsg(messagesData)
  }, [messagesData]);

  const handleSelect = (e: string) => {
    setTabVal(e);
  };

  const dateStartFrom = (date: any, dateString: any) => {
    setStartDateVal(moment(JSON.stringify(dateString), "MM/DD/YYYY"));
    setCreateMessage({
      ...createMessage,
      StartDate: `${moment(dateString).format("MM/DD/YYYY")}`,
    });
    setDisable(true);
  };

  const dateEndTo = (date: any, dateString: any) => {
    setEndDateVal(moment(JSON.stringify(dateString), "MM/DD/YYYY"));
    setCreateMessage({
      ...createMessage,
      EndDate: `${moment(dateString).format("MM/DD/YYYY")}`,
    });
    setDisable(true);
  };

  const handleSubject = (e: any) => {
    setCreateMessage({
      ...createMessage,
      Subject: e?.target?.value,
    });
    setDisable(true);
  };

  const handleMessageTxt = (e: any) => {
    setCreateMessage({
      ...createMessage,
      Message: e?.target?.value,
    });
    setDisable(true);
  };

  const submitMsg = () => {
    var fetchPayload = {
      libraryId: createMessage?.LibraryId,
      fromDate: moment().subtract(1, "weeks").format(dateFormat),
      recordCount: "500",
      recordStart: "1",
      sortDirection: "ASC",
      sortField: "CreatedOn",
      toDate: moment().format(dateFormat),
    };
    var createPayload = {
      EndDate: createMessage?.EndDate,
      LibraryId: createMessage?.LibraryId,
      Message: createMessage?.Message,
      StartDate: createMessage?.StartDate,
      Subject: createMessage?.Subject,
    };
    if (createPayload?.StartDate !== "") {
      dispatch(
        createMessages({
          fetchPayload: fetchPayload,
          createPayload: createPayload,
        })
      );
    }
    setShowDrawer(false);
    onCancel();
  };

  const onCancel = () => {
    setShowDrawer(false);
    setCreateMessage({
      LibraryId: `${lib_Id}`,
      EndDate: "",
      Message: "",
      StartDate: "",
      Subject: "",
      SourceMessageId: "",
      CreatedBy: "",
      CreatedOn: "",
    });
    setStartDateVal("");
    setEndDateVal("");
  };

  useEffect(() => {
    if (showDrawer === false) {
      setCreateMessage({
        LibraryId: `${lib_Id}`,
        EndDate: "",
        Message: "",
        StartDate: "",
        Subject: "",
        SourceMessageId: "",
        CreatedBy: "",
        CreatedOn: "",
      });
      setStartDateVal("");
      setEndDateVal("");
    }
  }, [showDrawer]);

  useEffect(() => {
    if (
      createMessage?.EndDate === "" ||
      createMessage?.StartDate === "" ||
      createMessage.Message === "" ||
      createMessage.Subject === ""
    ) {
      setDisable(false);
    }
  }, [createMessage]);

  const onDelete = (data: any) => {
    setDeleteData(data);
    setShowRemove(true);
  };

  const onUpdate = (data: any) => {};

  const historyData = (data: any) => {
    let schedule: Messages[] = [];
    let history: Messages[] = [];
    // eslint-disable-next-line array-callback-return
    data?.filter((item: any) => {
      var currentDate = moment().format(dateFormat);
      var todayDate = new Date(currentDate).getTime();
      var msgDate = moment(item?.EndDate).format(dateFormat);
      var messageDate = new Date(msgDate).getTime();
      if (messageDate >= todayDate) {
        return schedule.push(item);
      } else {
        history.push(item);
      }
    });
    setMsg(schedule);
    setHistoryMsg(history);
  };

  const handleDelete = () => {
    var deletePayload = {
      LibraryId: deleteData?.LibraryId,
      sourceMessageIds: deleteData?.SourceMessageId,
    };
    var fetchPayload = {
      libraryId: deleteData?.LibraryId,
      fromDate: moment().subtract(1, "weeks").format(dateFormat),
      recordCount: "500",
      recordStart: "1",
      sortDirection: "ASC",
      sortField: "CreatedOn",
      toDate: moment().format(dateFormat),
    };
    dispatch(
      deleteMessages({
        fetchPayload: fetchPayload,
        deletePayload: deletePayload,
      })
    );
    setShowRemove(false);
  };

  const handleSearch = (payload: any) => {
    dispatch(fetchMessages(payload));
  };

  return (
    <div className="message-screen">
      <Row className="head-container">
        <Col xs={12} sm={14} md={18} lg={20} xl={20}>
          <p className="page-title">Messages</p>
        </Col>
        {/* <Col xs={12} sm={10} md={6} lg={4} xl={4}>
          <Button onClick={() => setShowDrawer(true)} type="serPurBtn primary">
            New Message
          </Button>
        </Col> */}
      </Row>
      <Row>
        <Col span={24}>
        <MessageSearchBar
            dateFormat={dateFormat}
            handleSearch={handleSearch}
            lib_Id={lib_Id}
            setShowDrawer={setShowDrawer}
          />
        </Col>
      </Row>
      <br/>
      <br/>
      <Row>
        <Col span={24}>
        <MessagesList
            srcData={msg}
            handleUpdate={onUpdate}
            handleDelete={onDelete}
            totalResults={messagesCount}
            loading={
              formState?.loading ||
              createformState?.loading ||
              deleteformState?.loading
            }
          />
        </Col>
      </Row>
      {/* <Tabs
        className="cart-tabs"
        defaultActiveKey={tabVal}
        activeKey={tabVal}
        onChange={handleSelect}
        tabBarExtraContent={
          <MessageSearchBar
            dateFormat={dateFormat}
            handleSearch={handleSearch}
            lib_Id={lib_Id}
          />
        }
      >
        <TabPane
          tab="Scheduled"
          key="1"
          className="scrollStyle search-results-list scrollOnMessScreen h-81 scrollAuto"
        >
          <MessagesList
            srcData={msg}
            handleUpdate={onUpdate}
            handleDelete={onDelete}
            totalResults={messagesCount}
            loading={
              formState?.loading ||
              createformState?.loading ||
              deleteformState?.loading
            }
          />
        </TabPane>

        <TabPane
          tab="History"
          key="2"
          className="scrollStyle search-results-list scrollOnMessScreen h-81 scrollAuto"
        >
          <MessagesList
            srcData={historymsg}
            handleUpdate={onUpdate}
            handleDelete={onDelete}
            totalResults={messagesCount}
            loading={
              formState?.loading ||
              createformState?.loading ||
              deleteformState?.loading
            }
          />
        </TabPane>
      </Tabs> */}
      <RemoveConfirmModel
        showRemove={showRemove}
        setShowRemove={setShowRemove}
        removeConfirm={handleDelete}
        heading={"Remove Confirmation"}
        message={"Are you sure you want to DELETE this message ?"}
        message2={"This action cannot be undone."}
      />
      <Drawer
        placement="right"
        width="428"
        onClose={() => {
          setShowDrawer(false);
        }}
        visible={showDrawer}
      >
        <div className="searchPurchasesSideBar">
          <Row className="head-container">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <p className="searchPurSidebarHed">New Message</p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="firstBox">
                <p className="searchPurSidebarSubHed bolding">Content</p>
                <Row>
                  <Col span={24} className="pb-12">
                    <Input
                      name="autoCartPurchase"
                      placeholder="Subject"
                      className="dropDownBox coloring"
                      value={createMessage?.Subject}
                      bordered={false}
                      onChange={handleSubject}
                      maxLength={80}
                    />
                  </Col>
                  <Col span={24}>
                    <TextArea
                      name="autoCartPurchase"
                      placeholder="Message"
                      value={createMessage?.Message}
                      className="dropDownBox coloring padding"
                      bordered={false}
                      autoSize={{ minRows: 5, maxRows: 5 }}
                      onChange={handleMessageTxt}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="firstBox bolding">
                <p className="searchPurSidebarSubHed bolding">When</p>
                {/* <Col span={24}>
                  <Radio.Group
                    onChange={handleStatus}
                    value={radioValue}
                    name="AutoBuy"
                  >
                    <Row>
                      <Col span={12} className="pb-8">
                        <Radio name="status" value={2}>
                          <span className="radio-span delHed">Now</span>
                        </Radio>
                      </Col>
                      <Col span={12} className="pb-8">
                        <Radio name="status" value={3}>
                          <span className="radio-span delHed">Later</span>
                        </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Col> */}
                <Row>
                  <Col span={6}>
                    <label>Start Date:</label>
                  </Col>
                  <Col span={18}>
                    <DatePicker
                      className="card-datepicker"
                      format={"MM/DD/YYYY"}
                      value={startDateVal}
                      suffixIcon={
                        <img className="iconImg" src={dropdownIcon} alt="" />
                      }
                      disabledDate={(d) => !d || d.isAfter(endDateVal)}
                      placeholder="Date"
                      onChange={dateStartFrom}
                    />
                  </Col>
                  <Col span={6}>
                    <label>End Date:</label>
                  </Col>
                  <Col span={18}>
                    <DatePicker
                      className="card-datepicker"
                      value={endDateVal}
                      format={"MM/DD/YYYY"}
                      disabledDate={(d) => !d || d.isBefore(startDateVal)}
                      suffixIcon={
                        <img className="iconImg" src={dropdownIcon} alt="" />
                      }
                      placeholder="Date"
                      onChange={dateEndTo}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {/* <div className="firstBox">
                <p className="searchPurSidebarSubHed">Delivery Method</p>
                <Row className="pb-12">
                  <Col span={18}>
                    <div className="delHed">In App</div>
                  </Col>
                  <Col span={6} className="deliveryPosition">
                    <Checkbox />
                  </Col>
                </Row>
                <Row className="pb-12">
                  <Col span={18}>
                    <div className="delHed">Text Message</div>
                  </Col>
                  <Col span={6} className="deliveryPosition">
                    <Checkbox />
                  </Col>
                </Row>
                <Row>
                  <Col span={18}>
                    <div className="delHed">Email</div>
                  </Col>
                  <Col span={6} className="deliveryPosition">
                    <Checkbox />
                  </Col>
                </Row>
              </div> */}
            </Col>
            <Col span={24} className="msg-slider-footer">
              {disable ? (
                <Button type="primary confirm-button" onClick={submitMsg}>
                  Schedule & Send
                </Button>
              ) : (
                <Button type="primary confirm-button messagebtn">
                  Schedule & Send
                </Button>
              )}
              <Button type="secondary confirm-button mt-20" onClick={onCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  );
};
