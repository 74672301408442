/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Divider,
  InputNumber,
  Menu,
  Dropdown,
  message,
  Empty,
  // List,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBooks,
  onApplyingFilters,
  pageRememberBooklist,
  selectAllRemember,
  setCheckedValues,
  setSelectAllChkBox,
  setSelectedTitles,
} from "../../../../../redux/actions/books/bookActions";
import "./bookList.less";
import Button from "../../../stateless/common/button";
import DropDown from "../../../stateless/common/dropDown";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { filterOptions, Page } from "../../../../constants/constants";
import {
  ascIcon,
  descIcon,
  editIcon,
  downIcon,
  dropdownIcon,
  whiteArrow,
  list,
  asteriskIcon,
} from "../../../../images";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import {
  BookStateSelector,
  clearCartsByBookId,
  clearSelected,
  clearSelectedChkBox,
  clearState,
  emtySelectedChkBox,
} from "../../../../../redux/reducers/books/bookListReducer";
import { Book } from "../../../../models/book/book";
import PaginationTag from "../../../stateless/common/pagination";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import {
  CartStateSelector,
  clearAdded,
  setTab,
  clearErrorCart,
} from "../../../../../redux/reducers/carts/cartsReducer";
import Cart from "../../../../models/cart/cart";
import { addItemtoCart } from "../../../../../redux/actions/carts/cartAction";
import { BookRow } from "./bookRowItem";
import { AppRoutes } from "../../../../router/appRoutes";
import { NewCartModal } from "../../carts/newCartModal";
import { Messages } from "../../../../constants/messages";
import { getAPIBaseUrl } from "../../../../../environment/api";
import { PortalModule } from "../../../../../environment/apiEnvironment";
import ExportIcon from "../../../../../assets/dummy/ExportIcon.png";
import { UseGAEventTracker } from "../../../../../utility/useGAEventTracker";
import {
  clearSearchScroll,
  SearchstateSelector,
} from "../../../../../redux/reducers/search/searchReducer";
import Axios from "axios";
import {
  SideBarStateSelector,
  retainPageSize,
} from "../../../../../redux/reducers/app/sideBarReducer";
import { AutoSizer, CellMeasurer, CellMeasurerCache,List } from "react-virtualized";

export const BookList = () => {
  const location = useLocation<any>();
  const { id }:any = useParams();
  const dispatch = useDispatch();
  const {
    totalCount,
    books,
    fetchBooksFormState,
    FeatureListName,
    ownedBooks,
    filters,
    booklistCurrentPage,
    globalSelectAll,
    checkedValues,
    selectAllChkBox,
    selectedTitles,
  } = useSelector(BookStateSelector);
  const {
    carts,
    addedItem,
    addItemtoCartFormstate,
    activeCartArray,
    ErrorCart,
  } = useSelector(CartStateSelector);
  const { retainedPageSize } = useSelector(SideBarStateSelector);
  const { appUser } = useSelector(userStateSelector);
  const { scrollHeight } = useSelector(SearchstateSelector);
  const history = useHistory();
  var stateA:any = {
    category:{
      FeatureListName:FeatureListName?FeatureListName:"",
      FeatureListId:id
    }
  }
  const { category } = location?.state?location?.state:stateA;
  const backText = "< Back";
  const [screenSize, getDimension] = useState<any>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const [bookListCopies, setBookListCopies] =
  useState<any>(books);
  const scrollRef = useRef<any>(null);
  const [checked, setChecked] = useState<any>([]);
  const [checkedPayload, setCheckedPayload] = useState<any>([]);
  const [unSelectAll, setUnSelectAll] = useState<boolean>(true);
  const [eptyCheckedArry, setEptyCheckedArry] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState(false);
  const [editCellRows, setEditCellRows] = useState<any>([]);
  const [totalQuantiy, setTotalQuantiy] = useState<any>([]);
  const [owned, setOwned] = useState(
    filters?.owned !== undefined ? filters.owned : false
  );
  const [bookList, setBookList] = useState<Book[]>([]);
  const [sortOrder, setSortOrder] = useState(
    filters?.sortOrder !== undefined ? filters.sortOrder : "Default"
  );
  const [selectAlChecked, setSelectAlChecked] = useState<boolean>(false);
  const [copies, setCopies] = useState<any>(1);
  const [globalC, setGlobalC] = useState<number>(1);
  const [start, setStart] = useState<number>(
    booklistCurrentPage?.start !== undefined
      ? booklistCurrentPage?.start
      : Page.DEFAULT_START
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSize, setPageSize] = useState<number>(
    retainedPageSize ?? 100
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [titleCount, setTitleCount] = useState<number>(5);
  const [sort, setSort] = useState(
    filters.sort !== undefined ? filters.sort : "asc"
  );
  const [selectedFormat, setSelectedFormat] = useState<string>(
    filters?.selectedFormat !== undefined ? filters.selectedFormat : "all"
  );
  const [sortIcon, setSortIcon] = useState(
    filters.sortIcon !== undefined ? filters.sortIcon : ascIcon
  );
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [updatingData, setUpdatingData] = useState(false);
  const [noData, setNoData] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addToCartNow, setAdToCartNow] = useState<boolean>(false);
  const [cartDescription, setCartDescription] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    booklistCurrentPage?.currentPage !== undefined
      ? booklistCurrentPage?.currentPage
      : 1
  );  const { showSearchSideBar, collapseSideBar } = useSelector(SideBarStateSelector);

  const [lastCount, setLastCount] = useState(start + pageSize - 1);
  const [scrollValue, setScrollValue] = useState(0);
  const [globalPrevious, setGlobalPrevious] = useState<any>();
  const [selectAllCancel, setSelectAllCancel] = useState<any>(false);
  var currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";
  var permissions =
    appUser && appUser.libraryData
      ? appUser.libraryData[0].FeaturePermissions
      : "";
  var filteredCarts = permissions.includes("AudioBooks")
    ? carts.filter((data: Cart) => {
        return (
          data.CartOwner === appUser?.email || data.IsCommunityCart === true
        );
      })
    : carts.filter((data: Cart) => {
        return (
          (data.CartOwner === appUser?.email ||
            data.IsCommunityCart === true) &&
          !data.CartTypeCode?.includes("Audio")
        );
      });
  const node = document.querySelector<HTMLElement>(".books");
  const optionList = [10, 20, 50, 100, 250, 500];

  const pageOptions = React.useMemo(() => (
    <Menu className="header-dropdown">
      <Menu.Item key="page-size">
        <Row>
          <Col style={{ width: "20px" }}>
            <img style={{ width: "14px", height: "14px" }} src={list} alt=" " />
          </Col>
          <Col flex="auto" style={{ paddingLeft: "5px" }}>
            Page Size
          </Col>
        </Row>
      </Menu.Item>
      {optionList.map((data: number, index: number) => (
        <Menu.Item key={index} onClick={() => changePageSize(data)}>
          <Row>
            <Col style={{ width: "20px" }}>
              {pageSize === data ? (
                <img
                  style={{ width: "14px", height: "14px" }}
                  src={asteriskIcon}
                  alt="*"
                />
              ) : (
                " "
              )}
            </Col>
            <Col style={{ paddingLeft: "5px" }}>{data}</Col>
          </Row>
        </Menu.Item>
      ))}
      <Menu.Item>
        <Row>
          <Col style={{ width: "20px" }}>
            <img
              style={{ width: "14px", height: "14px", marginTop: "5px" }}
              src={ExportIcon}
              alt=" "
            />
          </Col>
          <Col>
            <div className="exportListCsv">
              <a
                target="_blank"
                href={`${getAPIBaseUrl(
                  PortalModule.CARTS
                )}/exportSpotLight?lib_id=${
                  appUser && appUser?.libraryData
                    ? appUser?.libraryData[0]?.LibraryId
                    : 0
                }&feature_group_id=${
                  category?.FeatureListId
                }&record_count=${pageSize}&record_start=1&sortdirection=${sort}&filter_Library_Owned=${owned}&sortfield=${
                  sortOrder && sortOrder !== "Sort By" ? sortOrder : "SortOrder"
                }&filter_Format=${selectedFormat}&format=csv`}
                className="export-link"
                download
              >
                Export List
              </a>
            </div>
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  ), [optionList, pageSize, asteriskIcon, ExportIcon, sort, owned, sortOrder, selectedFormat, category?.FeatureListId, appUser?.libraryData]);
  
  
  const userCarts = React.useMemo(() => (
    <Menu className="header-dropdown">
      {filteredCarts.map((data: Cart, index: number) => (
        <Menu.Item key={index} onClick={() => onAddtoCart(data)}>
          <p className="dropDown-title">{data.CartName}</p>
          <span className="dropDown-content">
            {currency}
            {data.TotalCartAmount?.toFixed(2)
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            . {data.NumberOfTitles} Titles
          </span>
        </Menu.Item>
      ))}
      <Menu.Divider />
      <Menu.Item
        key="All-Carts-Link"
        className="dropDown-link"
        onClick={() => {
          history.push(AppRoutes.CART);
          dispatch(setTab("2"));
        }}
      >
        <p>All Carts</p>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="New-cart-Link"
        className="dropDown-link"
        onClick={() => setShowModal(true)}
      >
        <p>New Cart</p>
      </Menu.Item>
    </Menu>
  ), [filteredCarts, currency, history, dispatch, setTab, setShowModal]);
  
  

  const goBack = () => {
    if(category?.Archived || category?.totalBooks){
      history.goBack();
      dispatch(selectAllRemember(false));
    }else{
      history.push(AppRoutes.LANDING)
      dispatch(selectAllRemember(false));
    }
  };
  useEffect(() => {
    return () => {
      dispatch(clearState());
      dispatch(clearAdded());
      dispatch(clearCartsByBookId());
    };
  }, []);

  useEffect(() => {
    setTitleCount(totalCount);
  }, [totalCount]);

  const changePageSize = (size: number) => {
    updateAndLoad();
    setPageSize(size);
    dispatch(retainPageSize(size))
  };
  const updateAndLoad = () => {
    setDataLoading(true);
    setUpdatingData(true);
  };

  const showNotification = (content: any, type: any) => {
    if (type === "loading") {
      setLoading(true);
      message
        .loading({
          content: content,
          key: "notificationMessage",
          duration: 3,
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      type === "success"
        ? message.success({
            content: content,
            key: "notificationMessage",
          })
        : message.error({
            content: content,
            key: "error",
          });
          setChecked([])
          setCheckedPayload([])

    }
  };

  window.onbeforeunload = () => {
    dispatch(clearState());
    dispatch(clearAdded());
  };

  const handleDeselect = (e: any) => {};

  const clickArea = (id: any) => {};

  const handleSelectImg = (value: any, check: boolean) => {};
  useEffect(() => {
    if (fetchBooksFormState?.isSuccess) {
      setUpdatingData(false);
      if (bookList?.length) {
        setDataLoading(true);
        setNoData(false);
      } else {
        setNoData(true);
        setDataLoading(false);
      }
    }
    if (fetchBooksFormState?.isError) {
      setNoData(true);
      setUpdatingData(false);
      setDataLoading(false);
      dispatch(clearState());
    }
  }, [fetchBooksFormState, bookList]);

  useEffect(() => {
    if(books?.length > 0){
      setDataLoading(true);
      if (selectedFormat && selectedFormat === "all") {
        setBookList(books);
      } else {
        setBookList(filterFormat());
      }
    }
  }, [books, selectedFormat]);

  useEffect(() => {
    if (allSelected) {
      if (!selectAlChecked) {
        if (selectAllChkBox) {
          onSelectAll();
        }
      } else {
        onSelectAll();
        setChecked([]);
        setCheckedPayload([])
        setSelectAlChecked(false);
        dispatch(setSelectAllChkBox(false));
      }
    }
    setBookListCopies(bookList)
  }, [books]);

  const onPageChange = (page: any, pageSize: any) => {
    dispatch(setSelectAllChkBox(true));
    dispatch(clearSelectedChkBox());
    dispatch(clearSelected());
    setBookList([]);
    setGlobalC(1);
    dispatch(setSelectedTitles([]));
    if (!fetchBooksFormState.loading) {
      const pageStart = (page - 1) * pageSize + 1;
      setStart(pageStart);
      setCurrentPage(page);
      dispatch(clearSearchScroll());
    }
    setUnSelectAll(false);
    onCancel();
    setAllSelected(false);
    setSelectAllCancel(true);
  };
  useEffect(() => {
    !updatingData && setDataLoading(false);
  }, [bookList]);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    var lib_Id =
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
    updateAndLoad();
    var payload = {
      libId: lib_Id,
      categoryId: category.FeatureListId,
      startIndex: start,
      quantity: pageSize,
      sortBy: sortOrder,
      libraryOwned: owned,
      sortOrder: sort,
    }
    dispatch(
      fetchBooks({ payload: payload, signal: source })
    );
    UseGAEventTracker(
      "Spotlight category",
      "Featured List card selected",
      `User selected Featured Group - ${category?.FeatureListName}`,
      NaN
    );
    return () => {
      source.cancel();
    };
  }, [start, sortOrder, owned, sort, pageSize]);

  const handleOwnedFilter = (value: any) => {
    updateAndLoad();
    setStart(Page.DEFAULT_START);
    setOwned(value);
  };
  const handleFormatFilter = (value: any) => {
    updateAndLoad();
    node?.scroll(0, 0);
    if (value === selectedFormat) {
      formatAll(selectedFormat);
    } else {
      setSelectedFormat(value);
    }
  };
  const filterFormat = () => {
    const data = books?.filter((data: Book) => {
      return data.FileFormatName === selectedFormat;
    });

    data.length ? setNoData(false) : setNoData(true);
    return data;
  };
  const formatAll = (selectedFormat: string) => {
    setDataLoading(true);
    if (selectedFormat === "all") {
      setTimeout(() => {
        setUpdatingData(false);
      }, 500);
    } else {
      setTimeout(() => {
        setBookList(filterFormat());
        setUpdatingData(false);
      }, 500);
    }
  };
  useEffect(() => {
    formatAll(selectedFormat);
  }, [selectedFormat]);

  useEffect(() => {
    let end = start + pageSize - 1;
    if ((owned ? ownedBooks : totalCount) < end) {
      setLastCount(owned ? ownedBooks : totalCount);
    } else {
      setLastCount(end);
    }
  }, [start, pageSize, ownedBooks, totalCount, owned]);

  const handleSortFilter = (value: any) => {
    updateAndLoad();
    setSortOrder(value);
  };
  const onSelectAll = () => {
    setSelectAlChecked(true);
    dispatch(setSelectAllChkBox(true))
    // setAllSelected(true);
    setSelectAllCancel(false);
    setCopies(1);
    let checkedArray: any = [];
    let checkedPayload: any = [];
    books?.map((book:any)=>{
    if (checkedValues?.length > 0) {
      if( checkedValues?.includes(book?.CatalogItemId)){
          const index=bookListCopies.find((data:any)=>book?.CatalogItemId===data?.CatalogItemId)
        checkedArray?.push(book.CatalogItemId);
        checkedPayload?.push({
          CatalogItemId:book.CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: index?.Quantity
          ? index?.Quantity + (copies - 1)
          : copies,
          SaveAction: "S",
        });
    } }
    else {
      // checkedArray?.map((item: any) => {
        checkedArray?.push(book.CatalogItemId);
        const index=bookListCopies.find((data:any)=>book?.CatalogItemId===data?.CatalogItemId)
        checkedPayload?.push({
          CatalogItemId: book.CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: index?.Quantity
          ? index?.Quantity + (copies - 1)
          : copies,
         SaveAction: "S",
        });

    }    })

    if (eptyCheckedArry) {
      setChecked([]);
      setEptyCheckedArry(false);
    } else {
      setChecked(checkedArray);
    }
    setAllSelected(true)
    setCheckedPayload(checkedPayload);
    // dispatch(selectAllRemember(true));
  };
  const onCustomSelect = (checkedValues: any) => {
    // setChecked(checkedValues);
    // setCheckedPayload(
    //   checkedValues?.map((item: any) => {
    //     return {
    //       CatalogItemId: item,
    //       BuyerId: "",
    //       BuyerCartDetailId: 0,
    //       Quantity: copies,
    //       SaveAction: "S",
    //     };
    //   })
    // );
  };

  const onCancel = () => {
    setCopies(1);
    setChecked([]);
    setCheckedPayload([]);
    setAllSelected(false);
    dispatch(clearSelectedChkBox());
    dispatch(setSelectedTitles([]));
    setTotalQuantiy([]);
    setEditCellRows([]);
    setGlobalC(1);
  };

  const onSelectAllCheckBox = (e:any) => {
    if (e?.target?.checked) {
      setSelectAlChecked(true);
      dispatch(setSelectAllChkBox(true));
    }
     else {
      setSelectAlChecked(false);
      dispatch(setSelectAllChkBox(false));
    }
  };

  useEffect(() => {
    if (!selectAlChecked) {
      setChecked([]);
      setCheckedPayload([]);
    } else {
      let checkedArray: any = [];
      let checkedPayloads: any = [];
      // if (checkedValues?.length > 0) {
      //   checkedValues?.map((item: any) => {
      //     checkedArray?.push(item);
      //     checkedPayloads?.push({
      //       CatalogItemId: item,
      //       BuyerId: "",
      //       BuyerCartDetailId: 0,
      //       Quantity: copies,
      //       SaveAction: "S",
      //     });
      //   });
      // } else {
        books?.map((item: any) => {
          checkedArray?.push(item.CatalogItemId);
          checkedPayloads?.push({
            CatalogItemId: item.CatalogItemId,
            BuyerId: "",
            BuyerCartDetailId: 0,
            Quantity: copies,
            SaveAction: "S",
          });
        });
      // }

      setChecked(checkedArray);
      setCheckedPayload(checkedPayloads);
    }
  }, [selectAlChecked]);
  
  const onSortClick = () => {
    updateAndLoad();
    if (sort === "asc") {
      setSort("desc");
      setSortIcon(descIcon);
    } else {
      setSort("asc");
      setSortIcon(ascIcon);
    }
  };

  const googleAnalyticSpotlightLabel = bookList?.reduce(
    (total: any, item: any) => {
      var CatalogItemId = item?.CatalogItemId;
      var found = checked?.some((elem: any) => elem === CatalogItemId);
      if (found) total = total + item?.RetailPrice;
      return total;
    },
    0
  );

  const onAddtoCart = (cart: Cart) => {
    setAdToCartNow(true);
    setCartDescription(cart);
  };

/*   virtaulization const*/ 
 const containerRef = useRef<any>(null);
  const listRef = useRef<any>(null);
  // Cache to handle dynamic row heights
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 50, // Default height for rows until measured
    })
  ).current;
  const calculateDimensions = () => {
    if (containerRef.current) {
        // Clear cache and recompute row heights when necessary
        cache.clearAll();
        if (listRef.current) {
          listRef.current.recomputeRowHeights();
        }
    }
  };
  useEffect(() => {
    if (bookList?.length > 1) {
      containerRef.current.scrollTo(0, scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookList]);
  useEffect(() => {
    const syncScroll = () => {
      if (listRef.current && containerRef.current) {
        const listNode = listRef.current.Grid; // Access the internal Grid of List
        listNode.scrollTop = containerRef.current.scrollTop;
      }
    };

    const handleScroll = () => {
      syncScroll();
    };
   
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  useEffect(() => {
    // Calculate dimensions on mount
    calculateDimensions();

    // Add event listener to recalculate on window resize
    window.addEventListener('resize', calculateDimensions);

    return () => {
      // Cleanup the event listener on unmount
      window.removeEventListener('resize', calculateDimensions);
    };
  }, [showSearchSideBar, collapseSideBar, cache]);
  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  useEffect(() => {
    if (addToCartNow && cartDescription && checkedPayload) {
      var lib_Id =
        appUser && appUser?.libraryData
          ? appUser?.libraryData[0]?.LibraryId
          : 0;

      let checkedPayloads: any = [];
    const booksMap: Map<number, Book> = new Map(
      selectedTitles?.amtChangCopie?.map((b: Book) => [b.CatalogItemId, b])
    );
      
      if(checkedPayload.length > 0) {
        for (var i: any = 0; i < checkedPayload.length; i++) {
          var inputQuantity = booksMap.get(checkedPayload[i].CatalogItemId);
          checkedPayloads?.push({
            CatalogItemId: checkedPayload[i].CatalogItemId,
            BuyerId: "",
            BuyerCartDetailId: 0,
            Quantity: inputQuantity?.Quantity ?? copies,
            SaveAction: "S",
          });
        }
      } else if(totalQuantiy.length > 0){
        for (var i: any = 0; i < totalQuantiy.length; i++) {
          var inputQuantity = booksMap.get(totalQuantiy[i].CatalogItemId);
          checkedPayloads?.push({
            CatalogItemId: totalQuantiy[i].CatalogItemId,
            BuyerId: "",
            BuyerCartDetailId: 0,
            Quantity: inputQuantity?.Quantity ?? copies,
            SaveAction: "S",
          });
        }
      }
      var payloadData = {
        IsCommunityCart: cartDescription?.IsCommunityCart,
        LibraryId: lib_Id,
        SaveAction: "S",
        CartDescription: cartDescription?.CartDescription,
        CartId: cartDescription?.CartId,
        CartName: cartDescription?.CartName,
        AssociateShelfId: cartDescription?.AssociateShelfId,
        CartDetail: checkedPayloads,
      };      
      setTimeout(() => {
        if (checkedPayloads?.length > 0) {
          dispatch(addItemtoCart(payloadData));
          showNotification(
            `Adding items to ${cartDescription?.CartName}`,
            "loading"
          );
        } else {
          showNotification(`No items are selected`, "error");
        }
      }, 100);
      UseGAEventTracker(
        "Cart category",
        "Add all to cart from spotlight featured list",
        `User add all to cart having total price (${currency}${googleAnalyticSpotlightLabel})})`,
        checked?.length
      );
      setAdToCartNow(false);
      setCartDescription([]);
    }
  }, [checkedPayload, addToCartNow, cartDescription, appUser]);

  useEffect(() => {
    if (ErrorCart !== "" && !loading) {
      dispatch(clearErrorCart());
      dispatch(clearAdded());
    } else {
      return undefined;
    }
  }, [ErrorCart, loading]);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    if (addedItem && !loading) {
      showNotification(Messages.ITEM_ADDED, "success");
      setGlobalC(1);
      dispatch(setSelectedTitles([]));
      setTotalQuantiy([]);
      if (allSelected) {
        updateAndLoad();
        var lib_Id =
          appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
          var payload = {
            libId: lib_Id,
            categoryId: category.FeatureListId,
            startIndex: start,
            quantity: pageSize,
            sortBy: sortOrder,
            libraryOwned: owned,
            sortOrder: sort,
          }
        dispatch(
          fetchBooks(({ payload: payload, signal: source }))
        );
        setAllSelected(true);
      }
      dispatch(clearAdded());
    }
  }, [addedItem, loading]);

  useEffect(() => {
    if(books?.length > 0 ){
      setBookList(books);
    }
  }, [books]);

  useEffect(() => {
    const filtersApply = {
      owned,
      selectedFormat,
      sortOrder,
      pageSize,
      sort,
      sortIcon,
    };
    dispatch(onApplyingFilters(filtersApply));
  }, [owned, selectedFormat, sortOrder, pageSize, sort, sortIcon]);

  useEffect(() => {
    const currentPageState = {
      start,
      currentPage,
    };
    dispatch(pageRememberBooklist(currentPageState));
  }, [start, currentPage]);

  // useEffect(() => {
  //   if (bookList.length) {
  //     scrollRef?.current.scrollTo(0, scrollHeight ?? scrollValue);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [bookList]);

  const handleScroll = (event:any) => {
    if (listRef.current) {
      const scrollTop = event.target.scrollTop;
      listRef.current.scrollToPosition(scrollTop);
    }
  };

  useEffect(() => {
    if (allSelected) {
      if (checked?.length === 0) {
        setUnSelectAll(false);
      } else if (checked?.length === pageSize||checked?.length > 0) {
        setUnSelectAll(true);
      }
    }
    dispatch(setCheckedValues(checked));
  }, [checked, allSelected, pageSize]);

  const onSelectAllCancel = () => {
    dispatch(selectAllRemember(false));
    onCancel();
    setSelectAllCancel(true);
    dispatch(setSelectedTitles([]));
    setGlobalPrevious(1);
  };

  useEffect(() => {
    if (addItemtoCartFormstate?.isError && !loading) {
      dispatch(clearAdded());
    }
    if (addItemtoCartFormstate?.isSuccess && globalSelectAll) {
      setTotalQuantiy([]);
      setEptyCheckedArry(true);

      // dispatch(emtySelectedChkBox());
      // onSelectAll();
    }
    // onCancel()
    // if (globalSelectAll === true) {
    //     // dispatch(emtySelectedChkBox());
    //     onSelectAll();
    // }
  }, [addItemtoCartFormstate, globalSelectAll]);

  const onValueChange = (value: number) => {
    setGlobalPrevious(copies);
    setCopies(value);
    setGlobalC(value);
    if (totalQuantiy?.length || checkedPayload.length) {
      var ttqs = {
        globalCopie: value,
        amtChangCopie: totalQuantiy.length != 0 ? totalQuantiy?.map((item: any) => {
          let ay: any;
          if (checkedValues?.includes(item?.CatalogItemId)) {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity + (value - copies),
            };
          } else {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity,
            };
          }
          return ay;
        }) : checkedPayload?.map((item: any) => {
          let ay: any;
          if (checkedValues?.includes(item?.CatalogItemId)) {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity + (value - copies),
            };
          } else {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity,
            };
          }
          return ay;
        }),
      };
      if (ttqs) {
        dispatch(setSelectedTitles(ttqs));
        setTotalQuantiy(ttqs?.amtChangCopie);
      }
    }
  };
  const handleCheckboxChange = (id: any, isChecked: any) => {
    const newCheckedValues = isChecked == true
    ? [...checkedValues, id]
    : checkedValues.filter((value: any) => value !== id);
    dispatch(setCheckedValues(newCheckedValues));
    let checkedArray: any = [];
    let checkedPayload: any = [];
    // let checkedShelfPayload: any = [];
    bookList?.map((book: any) => {
      if (newCheckedValues?.includes(book?.CatalogItemId)) {
        checkedArray?.push(book.CatalogItemId);
        const index = bookListCopies?.find(
          (data: any) => book?.CatalogItemId === data?.CatalogItemId
        );
        const quant = index?.Quantity
          ? index.Quantity + (copies - 1)
          : copies;
        checkedPayload?.push({
          CatalogItemId: book.CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: quant,
          SaveAction: "S",
        });
        setChecked(newCheckedValues);
        setCheckedPayload(checkedPayload);

  }})}

  const onItemValChange = (value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    var newEditCellRows: any = { newEditCellRows };
    let foundItem = totalQuantiy?.find(
      (item: { CatalogItemId: any }) =>
        item?.CatalogItemId === value?.CatalogItemId
    );
    if (foundItem) {
      var res = totalQuantiy.map(
        (obj: { CatalogItemId: number }) =>
          [value].find(
            (o: { CatalogItemId: number }) =>
              o.CatalogItemId === obj.CatalogItemId
          ) || obj
      );
      setTotalQuantiy(res);
    } else {
      var ttq = [...totalQuantiy, value].filter(
        (obj: any) => !(obj && Object.keys(obj)?.length === 0)
      );
      setTotalQuantiy(ttq);
    }

    if (allSelected) {
      if (!checked?.includes(value?.CatalogItemId)) {
        let chkVal: any = [...checked];
        chkVal.push(value?.CatalogItemId);
        setChecked(chkVal);
      }
    }
  };

  useEffect(() => {
    if (totalQuantiy?.length !== 0) {
      var ttqs = {
        globalCopie: copies,
        amtChangCopie: totalQuantiy,
      };
      dispatch(setSelectedTitles(ttqs));
    } else {
      return undefined;
    }
  }, [totalQuantiy]);

  useEffect(() => {
    if (
      selectedTitles?.globalCopie !== undefined &&
      selectedTitles?.globalCopie !== 1
    ) {
      setGlobalC(selectedTitles?.globalCopie);
    }
  }, [selectedTitles]);
  const [paginationHide, setPaginationHide] = useState(false);
  useEffect(() => {
    if (totalCount > lastCount - start + 1 &&
      bookList &&
      bookList?.length) {
      setPaginationHide(false);
    }else{
      setPaginationHide(true);
    }
  }, [totalCount, lastCount,start,bookList]);

  return (
    <div className="book-list">
      <button className="back-button" onClick={goBack}>
        {backText}
      </button>
      <Row justify="space-between">
        <Col xs={24} sm={24} md={17} lg={19} xl={20} xxl={21}>
          <span className="CategoryName">
            {category.FeatureListName
              ? category.FeatureListName
              : category.FeaturedListName
              ? category.FeaturedListName
              : ""}
          </span>
        </Col>
        <Col xs={24} sm={24} md={7} lg={5} xl={4} xxl={3} className="pr-40">
          <div className="editor-Button">
            <Dropdown
              overlay={pageOptions}
              trigger={["click"]}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              disabled={allSelected}
            >
              <button className="button-type editor">
                <img src={editIcon} className="edit-icon" alt="" /> Editor
                &nbsp; &nbsp;
                <img src={downIcon} className="down-icon" alt="" />
              </button>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between" gutter={[20, 20]}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={allSelected ? 15 : 15}
          xl={allSelected ? 11 : 15}
        >
          <Row>
            <Col className="bookListTitleCountStyle">
              {selectedFormat === "all" ? (
                <span className="title-count">
                  {owned ? ownedBooks : totalCount > 0 ? start : "0"}-
                  {lastCount} of {owned ? ownedBooks : totalCount} Titles
                </span>
              ) : (
                <span className="title-count">
                  {bookList?.length} in {bookList?.length > 0 ? start : "0"}-
                  {lastCount} of {owned ? ownedBooks : totalCount} Titles
                </span>
              )}
            </Col>
            <Col span={4} className="filters-contatiner">
              <DropDown
                bordered={false}
                className="filters"
                value={owned}
                optionValue={filterOptions.ownership}
                icon={dropdownIcon}
                onChange={handleOwnedFilter}
              />
            </Col>
            <Col span={4} className="filters-contatiner">
              <DropDown
                bordered={false}
                defaultValue={
                  filters.selectedFormat !== undefined
                    ? filters.selectedFormat
                    : "Format"
                }
                className="filters"
                optionValue={filterOptions.formats}
                icon={dropdownIcon}
                onChange={handleFormatFilter}
              />
            </Col>
            <Col span={5} className="filters-contatiner">
              <DropDown
                defaultValue="Default"
                value={sortOrder}
                bordered={false}
                className="filters"
                optionValue={filterOptions.sorters}
                icon={dropdownIcon}
                onChange={handleSortFilter}
                showSort={true}
                sortIcon={sortIcon}
                onSortClick={onSortClick}
                childwidth={150}
              />
            </Col>
          </Row>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={allSelected ? 20 : 9}
          xl={allSelected ? 13 : 9}
        >
          {allSelected ? (
            <Row>
              <Col xs={7} sm={7} md={8} lg={7} xl={8}>
                <div
                  className={`add_toCart_button ${
                    totalQuantiy.length > 0 || checkedPayload.length > 0? "" : "disable-on-unselectall"
                  }`}
                >
                  <Button
                    onClick={() => onAddtoCart(activeCartArray)}
                    type={`add-main-cart-button ${
                      totalQuantiy.length > 0 || checkedPayload.length > 0 ? "" : "disable-on-unselectall"
                    }`}
                    disabled={totalQuantiy.length === 0 && checkedPayload.length === 0}
                    >
                    Add to Cart
                  </Button>
                  <Divider type="vertical" className="cart_button_divider" />
                  <Dropdown
                    overlay={userCarts}
                    className={`${
                      totalQuantiy.length > 0 || checkedPayload.length > 0 ? "" : "disable-on-unselectall"
                    }`}
                    disabled={!(checkedPayload.length > 0 || totalQuantiy.length > 0)}
                    trigger={["click"]}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    placement={"bottomRight"}
                  >
                    <Button
                      type={`cart-tomain-dropdown ${
                        checkedPayload.length > 0 || totalQuantiy.length > 0
                          ? ""
                          : "disable-on-unselectall"
                      }`}
                    >
                      <img
                        src={whiteArrow}
                        style={{ paddingLeft: "5px" }}
                        alt=""
                      />
                    </Button>
                  </Dropdown>
                </div>
              </Col>
              <Col xs={7} sm={7} md={6} lg={6} xl={7} className="cancel">
                <Button type="secondary" onClick={onSelectAllCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          ) : (
            <Row justify={!allSelected ? "end" : "start"}>
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={10}
                xl={10}
                className="select-all"
              >
                <Button
                  type="secondary"
                  disabled={!bookList.length}
                  onClick={onSelectAll}
                >
                  Select All
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Divider className="books-divider" />
      <SpinnerLoader loading={fetchBooksFormState?.loading}>
        <div
          ref={containerRef}
          id="scrollableDiv"
          style={
            screenSize?.dynamicHeight
              ? {
                  overflowY: "auto",
                  height: paginationHide
                    ? screenSize?.dynamicHeight - 180
                    : screenSize?.dynamicHeight - 140,
                }
              : {}
          }
          className={`scrollContent scrollStyle scrollOnBooksScreen ${
            paginationHide ? "collectionPageScroll" : "collectionResultScroll"
          }`}
          onScroll={handleScroll}
        >
          {allSelected ? (
            <>
              <Row>
                <Col className="selectAllColWrapper">
                  <Checkbox
                    className="unSelectAllCheckbox"
                    checked={selectAllChkBox}
                    onChange={onSelectAllCheckBox}
                  ></Checkbox>
                </Col>
                <Col className="selectAllColWrapper">
                  {/* <span>Copies : </span> */}
                  <InputNumber
                    defaultValue={globalC}
                    value={globalC}
                    min={1}
                    max={999}
                    disabled={!unSelectAll}
                    onChange={onValueChange}
                  />
                </Col>
                <Col className="pagination-tag">
                  {(owned ? ownedBooks : totalCount) > lastCount - start + 1 &&
                  bookList &&
                  bookList.length ? (
                    <PaginationTag
                      total={owned ? ownedBooks : totalCount}
                      current={currentPage}
                      defaultPageSize={pageSize}
                      onChange={onPageChange}
                    />
                  ) : null}
                </Col>
              </Row>
              <Divider className="books-divider" />
            </>
          ) : (
            <>
              <Row>
                <Col className="bottom-paging">
                  {(owned ? ownedBooks : totalCount) > lastCount - start + 1 &&
                  bookList &&
                  bookList.length ? (
                    <PaginationTag
                      total={owned ? ownedBooks : totalCount}
                      current={currentPage}
                      defaultPageSize={pageSize}
                      onChange={onPageChange}
                    />
                  ) : null}
                </Col>
              </Row>
              {(owned ? ownedBooks : totalCount) > lastCount - start + 1 &&
              bookList &&
              bookList.length ? (
                <Divider className="books-divider" />
              ) : null}
            </>
          )}
          {bookList && bookList.length ? (
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedValues}
              onChange={(checkedValues: any) => {
                onCustomSelect(checkedValues);
              }}
            >
              {/* virtualization */}
              <AutoSizer disableHeight>
                {({ width }: any) => (
                  <List
                    ref={listRef}
                    height={
                      containerRef.current
                        ? containerRef.current.clientHeight
                        : 0
                    }
                    rowCount={bookList.length} // Total number of rows
                    rowHeight={cache.rowHeight} // Dynamic row height based on content
                    width={width} // Full width of the container
                    deferredMeasurementCache={cache} // Cache for measuring rows
                    rowRenderer={({ index, key, parent, style }: any) => (
                      <CellMeasurer
                        key={key}
                        cache={cache}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        {({ measure }) => (
                          <div style={style} onLoad={measure}>
                            <BookRow
                              book={bookList[index]}
                              isCart={false}
                              allSelected={allSelected}
                              isAddTocartEnable={true}
                              editSelected={false}
                              filteredCarts={filteredCarts}
                              appUser={appUser}
                              showNotification={showNotification}
                              handleCheckbox={handleDeselect}
                              handleSelectImg={handleSelectImg}
                              isCollection={false}
                              clickArea={clickArea}
                              scrollValue={scrollValue}
                              onSelectAll={onSelectAll}
                              onCopie={onItemValChange}
                              globalCopie={copies}
                              globalPrevious={globalPrevious}
                              selectAllCancel={selectAllCancel}
                              selectAllChkBox={selectAllChkBox}
                              initialChecked={checkedValues.includes(
                                bookList[index].CatalogItemId
                              )}
                              globalC={globalC}
                              onCheckboxChange={handleCheckboxChange}
                            />
                          </div>
                        )}
                      </CellMeasurer>
                    )}
                    overscanRowCount={10} // Render extra rows for smoother scrolling
                    style={{
                      overflow: "hidden", // Explicitly hide overflow to prevent scrollbars
                    }}
                    autoHeight // Automatically adjusts the height based on the content
                  />
                )}
              </AutoSizer>
            </Checkbox.Group>
          ) : (
            !fetchBooksFormState?.loading &&
            !dataLoading &&
            !bookList?.length &&
            noData && (
              <Empty
                style={{ marginTop: "20%" }}
                description="No results found."
              />
            )
          )}
          {(owned ? ownedBooks : totalCount) > lastCount - start + 1 &&
          bookList &&
          bookList.length ? (
            <>
              <PaginationTag
                total={owned ? ownedBooks : totalCount}
                current={currentPage}
                defaultPageSize={pageSize}
                onChange={onPageChange}
              />
              <Col>
                {selectedFormat === "all" ? (
                  <span className="bookListTitleStyle">
                    {owned ? ownedBooks : totalCount > 0 ? start : "0"}-
                    {lastCount} of {owned ? ownedBooks : totalCount} Titles
                  </span>
                ) : (
                  <span className="bookListTitleStyle">
                    {bookList?.length} in {bookList?.length > 0 ? start : "0"}-
                    {lastCount} of {owned ? ownedBooks : totalCount} Titles
                  </span>
                )}
              </Col>
            </>
          ) : null}
          <div className="spinner-container">
            {/* <SpinnerLoader
              loading={fetchBooksFormState?.loading || dataLoading}
            /> */}
          </div>
        </div>{" "}
      </SpinnerLoader>

      <NewCartModal
        isModalVisible={showModal}
        setModalVisible={setShowModal}
        appUser={appUser}
        isNewCartDetailsNeed={true}
      />
    </div>
  );
};
