/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./marc.less";
import { MarcRecordsCatalog } from "./marcRecordsComp/marcRecordCatalog";
import { MarcRecordsTitle } from "./marcRecordsComp/marcRecordTitle";
import ComponentLoader from "../../../stateless/common/spinnerLoader/componentLoader";
import { MarcstateSelector } from "../../../../../redux/reducers/marc/marcReducer";

export const MarcRecords = (props: any) => {
  const { libraryId,libraryName } = props;
  const { formState } = useSelector(MarcstateSelector)
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const { TabPane } = Tabs;
  const handleSelect = (e: any) => {
    setActiveTab(e);
  };

  return (
    <div className="insightsMarcRecord">
        {/* <Row className="head-container">
          <Col xs={12} sm={14} md={18} lg={20} xl={20}>
            <p className="page-title">MARC Records</p>
          </Col>
        </Row> */}
        <Tabs
          className="cart-tabs marc_records_tab"
          activeKey={activeTab}
          onChange={handleSelect}
        >
          <TabPane tab="Generate MARC Records For Your Catalog" key="1" className="scroll-tab">
            <MarcRecordsCatalog libraryId={libraryId} libraryName={libraryName} />
          </TabPane>
          <TabPane tab="Generate MARC Records For a Title" key="2">
            <MarcRecordsTitle libraryId={libraryId} libraryName={libraryName} />
          </TabPane>
        </Tabs>
    </div>
  );
};
