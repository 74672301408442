// Base Imports
import React from "react";
import ReactDOM from "react-dom";

// Package Imports
import "antd/dist/antd.css";
import './index.css';
import App from './App';

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./redux/store";


let persistor = persistStore(store);
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <App store={store} />
    </PersistGate>
  </Provider>, // </React.StrictMode>,
  document.getElementById("root")
);
