import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import {
  createMessages,
  deleteMessages,
  fetchMessages,
  recentTab,
} from "../../actions/messages/messages";
import { Messages } from "../../states/messages/messages";
import { RootState } from "../../store/rootReducer";

const initialState: Messages = {
  formState: resetState(),
  createformState: resetState(),
  deleteformState: resetState(),
  messagesData: [],
  messagesCount: 0,
  defaultTab: "1",
  isMessageCreated:false,
  isMessageDeleted:false
};

export const MessagesSlice = createSlice({
  name: "messages",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      state.createformState = resetState();
      state.deleteformState = resetState();
      state.messagesData = [];
      state.messagesCount = 0;
      state.defaultTab = "1";
      return state;
    },
    clearMsgStatus:(state)=>{
      state.isMessageCreated = false;
      state.isMessageDeleted = false;
    }
  },
  extraReducers: {
    [fetchMessages.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
        state.messagesData = payload.Items;
        state.messagesCount = payload.TotalCount;
      }
      return state;
    },
    [fetchMessages.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchMessages.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [createMessages.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.createformState = errorState(payload.errorMsg);
      } else {
        state.createformState = fulfilledState();
        state.isMessageCreated = true
      }
      return state;
    },
    [createMessages.pending.toString()]: (state) => {
      state.createformState = pendingState();
      return state;
    },
    [createMessages.rejected.toString()]: (state, { payload }) => {
      state.createformState = rejectedState();
      return state;
    },
    [deleteMessages.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.deleteformState = errorState(payload.errorMsg);
      } else {
        state.deleteformState = fulfilledState();
        state.isMessageDeleted = true
      }
      return state;
    },
    [deleteMessages.pending.toString()]: (state) => {
      state.deleteformState = pendingState();
      return state;
    },
    [deleteMessages.rejected.toString()]: (state, { payload }) => {
      state.deleteformState = rejectedState();
      return state;
    },
    [recentTab.fulfilled.toString()]: (state, { payload }) => {
      state.defaultTab = payload;
      return state;
    },
    [recentTab.pending.toString()]: (state) => {
      return state;
    },
    [recentTab.rejected.toString()]: (state, { payload }) => {
      return state;
    },
  },
});

export const { clearState,clearMsgStatus } = MessagesSlice.actions;
export const MessagesStateSelector = (state: RootState) => state.messages;
export default MessagesSlice.reducer;
