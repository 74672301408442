/* eslint-disable react/jsx-no-target-blank */
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu, message, Spin } from "antd";
import {
  CloudStorageItems,
  ManagementItems,
  Settings,
} from "../../../../constants/sideNavConstants";
import "./styles.less";
import { settings, cloudLibrary } from "../../../../images";
import { SideBarStateSelector } from "../../../../../redux/reducers/app/sideBarReducer";
import { setSideBarItem } from "../../../../../redux/actions/app/appActions";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import {
  clearCollecResults,
  clearMatchIsbnData,
  clearQuickSearchValue,
  clearSaveSearchResult,
  resetSearchForm,
} from "../../../../../redux/reducers/search/searchReducer";
import { recentTab } from "../../../../../redux/actions/carts/cartAction";
import {
  clearShelvListState,
  setTab,
} from "../../../../../redux/reducers/shelves/shelfReducer";
import {
  clearReportToken,
  reportstateSelector,
} from "../../../../../redux/reducers/reports/reportsReducer";
import { LoadingOutlined } from "@ant-design/icons";
import { cameFromPurchaseDetail } from "../../../../../redux/actions/purchases/purchasesActions";
import { clearSelectedChkBox } from "../../../../../redux/reducers/books/bookListReducer";
import { selectAllRemember } from "../../../../../redux/actions/books/bookActions";
import { clearHideOwnedSwtich, clearRetainSpotlightPos, groupstateSelector } from "../../../../../redux/reducers/groups/groupListReducer";
import { resetReportTab } from "../../../../../redux/reducers/insight/insightsReducer";
export const SideNav = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<any>();
  const { appUser } = useSelector(userStateSelector);
  const { isThereNewsLetter } = useSelector(groupstateSelector);
  const { selectedSideBarItem } = useSelector(SideBarStateSelector);
  const { reportFormState, reportError } = useSelector(reportstateSelector);
  const [managementMenu, setManagementMenu] = useState<any>([]);
  const [settingShow, setSettingShow] = useState<any>(false);

  const onSelect = (item: any) => {
    dispatch(clearHideOwnedSwtich());
    dispatch(selectAllRemember(false));
    dispatch(clearSelectedChkBox());
    dispatch(clearCollecResults());
    dispatch(setSideBarItem(item.name));
    dispatch(clearQuickSearchValue());
    if (item?.name === "Purchases") {
      dispatch(
        cameFromPurchaseDetail({
          cameFromPurchaseDetail: false,
          openTab: "1",
        })
      );
      history.push(item.url);
    }else if(item?.name ==='Resources'){
      window.open(item.url, '_blank');
    } else {
      history.push(item.url);
    }

    if (item.name === "Search" || item.name === "My Collection") {
      dispatch(clearSaveSearchResult());
      dispatch(resetSearchForm());
      dispatch(clearMatchIsbnData());
    }

    if (item.name === "Carts") {
      dispatch(recentTab("1"));
    }

    if (item.name === "Spotlight") {
      dispatch(clearRetainSpotlightPos());
    }

     if (item.name === "Reports") {
      // dispatch(resetReportTab());
    }

    if (item.name === "Shelves") {
      dispatch(setTab("1"));
    } else {
      dispatch(clearShelvListState());
    }
  };
  const onSelectSettings = (item: any) => {
    history.push(item.url);
    dispatch(clearQuickSearchValue());
  };

  var libraryName =
    appUser && appUser.libraryData ? appUser.libraryData[0]?.LibraryName : "";

  const newArray = (data: any, show: boolean) => {
    return {
      name: data.name,
      url: data.url,
      show: show,
    };
  };

  useEffect(() => {
    var menuItems = ManagementItems?.map((data: any) => {
      if (data.name === "My Collection") {
        if (
          !appUser?.isLibraryUser &&
          !appUser?.isLibraryBuyer &&
          !appUser?.isShelfAdmin
        ) {
          return newArray(data, false);
        } else {
          return newArray(data, true);
        }
      } else if (data.name === "Purchases") {
        if (!appUser?.isLibraryBuyer) {
          return newArray(data, false);
        } else {
          return newArray(data, true);
        }
      } else if (data.name === "Messages") {
        if (appUser?.IsPatronCommunicator) {
          return newArray(data, true);
        } else {
          return newArray(data, false);
        }
      } else if (data.name === "Reports") {
        if (appUser?.IsOnlyPatronMgr && appUser?.IsPatronManager) {
          return newArray(data, false);
        } else {
          return newArray(data, true);
        }
      } else if (data.name === "Support") {
        return newArray(data, true);
      } else if (data.name === "Patrons") {
        if (appUser?.IsOnlyPatronMgr) {
          dispatch(setSideBarItem("Patrons"));
          return newArray(data, true);
        } else if (appUser?.IsPatronManager) {
          return newArray(data, true);
        } else {
          return newArray(data, false);
        }
      } else if (data.name === "Shelves") {
        if (!appUser?.isShelfAdmin) {
          return newArray(data, false);
        } else {
          return newArray(data, true);
        }
      } else {
        return newArray(data, true);
      }
    });
    setManagementMenu(menuItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser]);

  useEffect(() => {
    if (location.pathname === "/cloudLibrary/settings") {
      setSettingShow(true);
    } else {
      setSettingShow(false);
    }
  }, [location]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const RenderLoading = (
    <Spin indicator={antIcon} className="renderSideNavLoading" />
  );

  useEffect(() => {
    if (reportError?.Message !== "" && reportError?.Message !== undefined) {
      message.error({
        content: reportError?.Message,
        key: "notificationMessage",
        duration: 4,
      });
      dispatch(clearReportToken());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportError]);
  

  return (
    <>
      <div className="icon-container">
        <img src={cloudLibrary} alt="" className="cloudIcon" />
      </div>
      <div className="main-sidebar scrollStyle">
        <Menu selectedKeys={selectedSideBarItem} style={{ paddingTop: "40px" }}>
          {
            appUser?.isLibraryUser?
            <Menu.ItemGroup key="g1" title="CONTENT CORNER">
            {CloudStorageItems.map((x: any, index) => {
              return (
                <Menu.Item
                  key={x.name}
                  className={`menu-item-row ${
                    settingShow ? "sideSetting" : ""
                  } ${
                    x.name === "Spotlight"
                      ? isThereNewsLetter
                        ? "showSideMenuSpotlight"
                        : "hideSideMenuSpotlight"
                      : ""
                  }`}
                  onClick={() => onSelect(x)}
                >
                  {x.showTag ? (
                    <StyledContainer>
                      {x.name}
                    </StyledContainer>
                  ) : (
                    x.name
                  )}
                </Menu.Item>
              );
            })}
          </Menu.ItemGroup>:null
          }
   
          <Menu.ItemGroup key="g2" title="LIBRARY MANAGEMENT">
            {managementMenu.map((x: any, index: any) => {
              if (!x.show) {
                return null;
              } else {
                return (
                  <Menu.Item
                    key={x.name}
                    className={`${
                      x.name === "Purchases" ||
                      x.name === "My Collection" ||
                      x.name === "Messages" ||
                      x.name === "Patrons" ||
                      x.name === "Support" ||
                      x.name === "Resources" ||
                      x.name === "Shelves" ||
                      x.name === "Reports"
                        ? " menu-item-row"
                        : " menu-item-row libraryOptions"
                    } ${settingShow ? "sideSetting" : ""}`}
                    onClick={() => onSelect(x)}
                  >
                    {x.name}
                    {x.name === "Reports" ? (
                      reportFormState?.loading ? (
                        <span className="sideNavLoader">{RenderLoading}</span>
                      ) : null
                    ) : null}
                  </Menu.Item>
                );
              }
            })}
          </Menu.ItemGroup>
        </Menu>
        <div>
          <button className="settings-button settingsText">
            <div className="libraryName">{libraryName}</div>
          </button>
          <button
            className={`settings-button settingsLink ${
              settingShow ? "showSetting" : ""
            }`}
          >
            <div className="settingLink">
              {Settings.map((x: any, index: any) => {
                if (!appUser?.isLibraryAdmin && !appUser?.isLibraryBuyer) {
                  return null;
                } else {
                  return (
                    <span
                      key={x.name}
                      onClick={() => onSelectSettings(x)}
                      className="settingsBtn"
                    >
                      <img src={settings} className="settingsIcon" alt="" />
                      {x.name}
                    </span>
                  );
                }
              })}
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

const StyledContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
