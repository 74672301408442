/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Divider, Dropdown, Menu, Row, Switch, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ParagraphBox } from "./components/paragraphBox";
import "./components/newSpotLight.less";
import { FeatureBox } from "./components/featureBox";
import { BookListSlider } from "./components/bookListSlider";
import ProfileImg from "../../../../assets/images/profileA.jpg";
import ProfileAuImg from "../../../../assets/images/australianUser.jpg";
import Button from "../../stateless/common/button";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import {
  fetchContentCorner,
  retainSpotlightPosition,
  retainSwtichValue,
} from "../../../../redux/actions/groups/groupActions";
import {
  clearContentCornerAll,
  groupstateSelector,
} from "../../../../redux/reducers/groups/groupListReducer";
import moment from "moment";
import SpinnerLoader from "../../stateless/common/spinnerLoader";
import { fetchBooksForSpotLight, onApplyingFilters } from "../../../../redux/actions/books/bookActions";
import {
  BookStateSelector,
  cleaFetureListData,
} from "../../../../redux/reducers/books/bookListReducer";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../router/appRoutes";
import {
  CartStateSelector,
  clearAdded,
  setTab,
} from "../../../../redux/reducers/carts/cartsReducer";
import { whiteArrow } from "../../../images";
import Cart from "../../../models/cart/cart";
import { NewCartModal } from "../carts/newCartModal";
import { replaceAll } from "../../../../utility/appUtil";
import { Messages } from "../../../constants/messages";
import { addFeaturedListToCart } from "../../../../redux/actions/carts/cartAction";
import Axios from "axios";
import { clearSearchScroll } from "../../../../redux/reducers/search/searchReducer";
import { UseGAEventTracker } from "../../../../utility/useGAEventTracker";
import { ConsoleSqlOutlined } from "@ant-design/icons";

export const Spotlight = (props: any) => {
  const dispatch = useDispatch();
  const { appUser }: any = useSelector(userStateSelector);
  const { activeCartArray, carts,addedItem } = useSelector(CartStateSelector);
  const history = useHistory();
  const { fetchBooksFormState, fetureListData, filters }: any =
    useSelector(BookStateSelector);
  const {
    ContentCorner,
    ContentformState,
    retainSpotlightPos,
    hideOwnedSwtich
  }: any = useSelector(groupstateSelector);
  const [getDynamiCount, setGetDynamiCount] = useState<number>(9);
  const [switchChecked, setSwitchChecked] = useState<boolean>(false);
  const [btnHit, setBtnHit] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [cartDescription, setCartDescription] = useState<any>([]);
  const [addToCartNow, setAdToCartNow] = useState<boolean>(false);
  const [start, setStart] = useState<number>(0);
  const [selectedListDetails, setSelectedListDetails] = useState<any>({
    CreatedDateTime: "",
    AuthorDetails: "",
    Author: "",
    EndDateTime: "",
    payload: {
      libId:
        appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0,
      categoryId: 0,
      startIndex: start,
      quantity: getDynamiCount,
      sortBy: "Sort By",
      libraryOwned: false,
      sortOrder: null,
    },
  });

  var CountryCode = appUser && appUser.libraryData
  ? appUser?.libraryData[0]?.CountryCode.toUpperCase()
  : ("US").toUpperCase();

  var dateFormat =
    appUser && appUser.libraryData
      ? appUser?.libraryData[0]?.DateFormat.toUpperCase()
      : "DD-MM-YYYY";
  const [screenSize, getDimension] = useState<any>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  var currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";
  var permissions =
    appUser && appUser.libraryData
      ? appUser.libraryData[0].FeaturePermissions
      : "";
  var filteredCarts = permissions.includes("AudioBooks")
    ? carts.filter((data: Cart) => {
        return (
          data.CartOwner === appUser?.email || data.IsCommunityCart === true
        );
      })
    : carts.filter((data: Cart) => {
        return (
          (data.CartOwner === appUser?.email ||
            data.IsCommunityCart === true) &&
          !data.CartTypeCode?.includes("Audio")
        );
      });

  const showNotification = (content: any, type: any) => {
    if (type === "loading") {
      message
        .loading({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
        .then(() => {
        });
    } else {
      type === "success"
        ? message.success({
            content: content,
            key: "notificationMessage",
          })
        : message.error({
            content: content,
            key: "error",
          });
    }
  };

  useEffect(()=>{
    setSwitchChecked(hideOwnedSwtich);
    dispatch(clearSearchScroll());
  },[])

  // For calculating number of books to display as per screen width
  useEffect(() => {
    const dynamicWidth = parseInt(screenSize?.dynamicWidth);
    let dynamicCount = 9;
  
    if (dynamicWidth <= 718) {
      dynamicCount = 1;
    } else if (dynamicWidth <= 870) {
      dynamicCount = 2;
    } else if (dynamicWidth <= 1050) {
      dynamicCount = 3;
    } else if (dynamicWidth <= 1205) {
      dynamicCount = 4;
    } else if (dynamicWidth <= 1390) {
      dynamicCount = 5;
    } else if (dynamicWidth <= 1700) {
      dynamicCount = 6;
    } else if (dynamicWidth <= 1900) {
      dynamicCount = 8;
    }
    setGetDynamiCount(dynamicCount);
  }, [screenSize?.dynamicWidth]);

  useEffect(() => {
    if (appUser?.libraryData?.length > 0) {
      if (appUser?.libraryData[0]?.CountryCode) {
        dispatch(fetchContentCorner(appUser?.libraryData[0]?.CountryCode));
      }
    }
  }, [appUser]);

  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr).tz("Etc/GMT").format(dateFormat);
    return currentDate;
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    if (
      ContentCorner &&
      ContentCorner?.NewsLetterDetail &&
      ContentCorner?.NewsLetterDetail?.length > 0
    ) {
      setStart(retainSpotlightPos?.sliderStart)
      setSelectedListDetails({
        ...selectedListDetails,
        CreatedDateTime: ContentCorner?.NewsLetterDetail[retainSpotlightPos?.featurlistIndex]?.CreatedDateTime,
        AuthorDetails:
          ContentCorner?.NewsLetterDetail[retainSpotlightPos?.featurlistIndex]?.NewsLetterDescription,
        Author: ContentCorner?.NewsLetterDetail[retainSpotlightPos?.featurlistIndex]?.FeaturedListName,
        EndDateTime: ContentCorner?.EndDate,
        FeaturedListName: ContentCorner?.NewsLetterDetail?.length > 0
        ? ContentCorner?.NewsLetterDetail[retainSpotlightPos?.FeaturedListName]?.FeaturedListName
        : "",
        payload: {
          libId:
            appUser && appUser.libraryData
              ? appUser.libraryData[0].LibraryId
              : 0,
          categoryId:
            ContentCorner?.NewsLetterDetail?.length > 0
              ? ContentCorner?.NewsLetterDetail[retainSpotlightPos?.featurlistIndex]?.FeaturedListId
              : 0,
          startIndex: retainSpotlightPos?.sliderStart,
          quantity: getDynamiCount,
          sortBy: "Sort By",
          libraryOwned: false,
          sortOrder: null,
        },
      });
    }
  }, [ContentCorner]);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    const filtersApply = {
      owned:hideOwnedSwtich,
      pageSize : filters?.pageSize ?? 100,
    };
    dispatch(onApplyingFilters(filtersApply));
    if (selectedListDetails && selectedListDetails?.payload?.categoryId !== 0) {
      var payload = {
        ...selectedListDetails?.payload,
        startIndex: start,
        libraryOwned:hideOwnedSwtich
      }
      dispatch(
        fetchBooksForSpotLight({ payload: payload, signal: source })
      );
      if(btnHit){
        setBtnHit(false)
        dispatch(retainSpotlightPosition({
          ...retainSpotlightPos,
          sliderStart: start,
        }));
      }
    }
    return () => {
      source.cancel();
    };
  }, [selectedListDetails, start,hideOwnedSwtich]);

  const onSwitch = (checked: boolean) => {
    if(checked ===false){
      setSwitchChecked(false);
    }else{
      setSwitchChecked(true);
    }
  };

 

  const handelCallBackFetureList = (list: any) => {
    setSwitchChecked(false);
    var modifiedList = {
      CreatedDateTime: list?.CreatedDateTime,
      AuthorDetails: list?.NewsLetterDescription,
      Author: list?.FeaturedListName,
      EndDateTime: ContentCorner?.EndDate,
      FeaturedListName:list?.FeaturedListName,
      payload: {
        libId:
          appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0,
        categoryId: list.FeaturedListId,
        startIndex: start,
        quantity: getDynamiCount,
        sortBy: "Sort By",
        libraryOwned: false,
        sortOrder: null,
      },
    };
    dispatch(retainSpotlightPosition({
      ...retainSpotlightPos,
      featurlistId: list.FeaturedListId,
      featurlistIndex: list?.indexNo,
      FeaturedListName:list?.FeaturedListName,
    }));
    
    if (modifiedList) {
      setSelectedListDetails(modifiedList);
    }
    setStart(0);
  };

  useEffect(() => {
    return () => {
      dispatch(clearContentCornerAll());
      dispatch(cleaFetureListData());
    };
  }, []);

  const handleNextItems = (item: any) => {
    setStart(function (prevCount) {
      return (prevCount += getDynamiCount);
    });
    setBtnHit(true)
  };

  const HandleResetStart = (item: any) => {
    setStart(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= getDynamiCount);
      } else {
        return (prevCount = 0);
      }
    });
    setBtnHit(true)
  };

  const userCarts = (
    <Menu className="header-dropdown">
      {filteredCarts.map((data: Cart, index: number) => {
        return (
          <Menu.Item key={index} onClick={() => onAddtoCart(data)}>
            <p className="dropDown-title">{data.CartName}</p>
            <span className="dropDown-content">
              {currency}{" "}
              {data.TotalCartAmount.toFixed(2)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              . {data.NumberOfTitles} Titles
            </span>
          </Menu.Item>
        );
      })}
      <Menu.Divider />
      <Menu.Item
        key="All-Carts-Link"
        className="dropDown-link"
        onClick={() => {
          history.push(AppRoutes.CART);
          dispatch(setTab("2"));
        }}
      >
        <p>All Carts</p>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="New-cart-Link"
        className="dropDown-link"
        onClick={() => setShowModal(true)}
      >
        <p>New Cart</p>
      </Menu.Item>
    </Menu>
  );

  const onAddtoCart = (cart: Cart) => {
    setAdToCartNow(true);
    setCartDescription(cart);
    UseGAEventTracker(
      "Spotlight add to cart",
      "selected featured lists add to cart",
      `The selected featured list ${fetureListData?.FeatureListName} added to cart`,
      NaN
    );
  };

  useEffect(() => {
    if (addToCartNow && cartDescription) {
      var lib_Id =
        appUser && appUser?.libraryData
          ? appUser?.libraryData[0]?.LibraryId
          : 0;
      var payloadData = {
        CartId: cartDescription?.CartId,
        SaveAction: "S",
        FeaturedListId: selectedListDetails?.payload?.categoryId,
        LibraryId: lib_Id,
        FilterLibraryOwned: switchChecked,
      };
      dispatch(addFeaturedListToCart(payloadData));
      const msg = replaceAll(
        /\{0\}/gi,
        Messages.ADD_TO_CART,
        `${cartDescription?.CartName}`
      );
      showNotification(msg, "loading");
      setAdToCartNow(false);
      setCartDescription([]);
    }
    dispatch(retainSwtichValue(switchChecked))
  }, [addToCartNow, cartDescription, appUser, switchChecked]);

  useEffect(() => {
    if (addedItem) {
      showNotification(Messages.ITEM_ADDED, "success");
      dispatch(clearAdded())
    }
  }, [addedItem]);

  useEffect(()=>{
    if(ContentformState?.isError){
     history?.push(AppRoutes.LANDING)
    }
  },[ContentformState])

  const roundofNumber = (x: any) => {
    let num = Number.parseFloat(x).toFixed(2);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const extarctEmail = (textVal: string) => {
    const text: any = textVal;
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    const extractedEmails = text?.match(emailRegex);
    if (extractedEmails) {
      return extractedEmails[0];
    } else {
      return "";
    }
  };

  function removeEmailsFromString(inputString: string) {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    const stringWithoutEmails = inputString?.replace(emailRegex,"");
    if (stringWithoutEmails) {
      return stringWithoutEmails?.replace(/\s+$/, '');
    } else {
      return "";
    }
  }

  return (
    <SpinnerLoader loading={ContentformState?.loading}>
      <div className="newSpotlight scrollStyle" >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={17}>
                <ParagraphBox
                  className={"paragraphBox mainBox"}
                  data={ContentCorner}
                  boxType={0}
                  dateFormat={dateFormat}
                />
              </Col>
              <Col>
                <Divider type="vertical" className="verticalDivider" />
              </Col>
              <Col span={6}>
                <div className="profile">
                  <div className="profileImage">
                    <img src={CountryCode === "AU"?ProfileAuImg:ProfileImg} alt="profileImg" />
                  </div>
                  <div className="profilePara">
                    <p className="author-name">{ContentCorner?.Author}</p>
                    <p>{removeEmailsFromString(ContentCorner?.AuthorDetails).replace(/,*$/,'')}, <span className="pEmailAdress">{extarctEmail(ContentCorner?.AuthorDetails)}</span></p>
                    {/* <p>{extarctEmail(ContentCorner?.AuthorDetails)}</p> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Divider className="spotlight-divider"/>
            <div className="sectionB">
              <Row>
                <Col span={6} className="borderRight">
                  <FeatureBox
                    className={"featureBox"}
                    data={
                      ContentCorner?.NewsLetterDetail
                        ? ContentCorner?.NewsLetterDetail
                        : []
                    }
                    boxType={0}
                    header={"FEATURED"}
                    dateFormat={dateFormat}
                    endDate={ContentCorner?.EndDate}
                    handelCallBackFetureList={handelCallBackFetureList}
                    activeListId={retainSpotlightPos?.featurlistId}
                  />
                </Col>
                <Col span={18}>
                  <Row>
                    <Col span={24}>
                      <SpinnerLoader loading={fetchBooksFormState?.loading}>
                      {!hideOwnedSwtich ? (
                          <BookListSlider
                            bookList={{
                              ...fetureListData,
                              FeaturedListName:
                                selectedListDetails?.FeaturedListName,
                            }}
                            className={"spotlightbookSlider"}
                            boxType={0}
                            dynamiCount={getDynamiCount}
                            handleNextItems={handleNextItems}
                            HandleResetStart={HandleResetStart}
                            start={start}
                          />
                        ) : hideOwnedSwtich &&
                          fetureListData?.ownedBooks > 0 ? (
                          <BookListSlider
                            bookList={{
                              ...fetureListData,
                              FeaturedListName:
                                selectedListDetails?.FeaturedListName,
                            }}
                            className={"spotlightbookSlider"}
                            boxType={0}
                            dynamiCount={getDynamiCount}
                            handleNextItems={handleNextItems}
                            HandleResetStart={HandleResetStart}
                            start={start}
                          />
                        ) : (
                          <BookListSlider
                            bookList={{
                              ...fetureListData,
                              FeaturedListName:
                                selectedListDetails?.FeaturedListName,
                              books: [],
                            }}
                            className={"spotlightbookSlider"}
                            boxType={0}
                            dynamiCount={getDynamiCount}
                            handleNextItems={handleNextItems}
                            HandleResetStart={HandleResetStart}
                            start={start}
                          />
                        )}
                      </SpinnerLoader>
                    </Col>
                  </Row>
                  <Divider className="sectionBdevider" />
                  <Row className="middle-bottom">
                    <Col span={16}>
                      <ParagraphBox
                        className={"paragraphBoxSecond"}
                        data={selectedListDetails}
                        boxType={1}
                        dateFormat={dateFormat}
                      />
                    </Col>
                    <Col span={1}>
                      <Divider type="vertical" className="verticalDivider" />
                    </Col>
                    <Col span={7} className="featured-list-details">
                      {/* <SpinnerLoader
                        loading={
                          fetchBooksFormState.loading ||
                          addItemtoCartFormstate?.loading
                        }
                      > */}
                        <div className="addToCardComponent">
                          <div>
                            <span>List Created</span>:
                            <span>
                              {" "}
                              {dateModify(
                                fetureListData?.listCreated
                                  ? fetureListData?.listCreated
                                  : ""
                              )}
                            </span>
                          </div>
                          <div>
                            <span>List Updated</span>:
                            <span>
                              {" "}
                              {dateModify(
                                fetureListData?.listUpdated
                                  ? fetureListData?.listUpdated
                                  : ""
                              )}
                            </span>
                          </div>
                          <br />
                          <div>
                            <span>Total Titles</span>:
                            <span>
                              {" "}
                              {fetureListData?.totalBooks
                                ? fetureListData?.totalBooks
                                : 0}
                            </span>
                          </div>
                          <div>
                            <span>Total Cost</span>:
                            <span>
                              {" "}
                              {fetureListData?.totalBooksCost
                                ?currency+roundofNumber(fetureListData?.totalBooksCost)
                                : currency+"0.00"}
                            </span>
                          </div>
                          <div>
                            <span>Total Titles Not Owned</span>:
                            <span>
                              {" "}
                              {fetureListData?.ownedBooks
                                ? fetureListData?.ownedBooks
                                : 0}
                            </span>
                          </div>
                          <div>
                            <span>Total Cost Not Owned</span>:
                            <span>
                              {" "}
                              {fetureListData?.totalNotOwnedBooksCost
                                ? currency+roundofNumber(fetureListData?.totalNotOwnedBooksCost)
                                : currency+"0.00"}
                            </span>
                          </div>
                          <span className="switch-wrapper">
                            <Switch
                              className="ppu-switch"
                              onChange={onSwitch}
                              defaultChecked={switchChecked}
                              checked={switchChecked}
                            />
                            <label>
                              <b>&nbsp;&nbsp;Hide owned titles</b>
                            </label>
                          </span>
                          <div className="spotlight-add-to-cart-button">
                          <div className="add_toCart_button add-toCart-shadow add_toShelves_single_button align-button">
                            <Button
                              onClick={() => onAddtoCart(activeCartArray)}
                              type={`add-main-cart-button`}
                              disabled={false}
                            >
                              Add to Cart
                            </Button>
                            <Divider
                              type="vertical"
                              className="cart_button_divider"
                            />
                            <Dropdown
                              overlay={userCarts}
                              trigger={["click"]}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                              disabled={false}
                              placement={"bottomRight"}
                            >
                              <Button type={`cart-tomain-dropdown`}>
                                <img
                                  src={whiteArrow}
                                  style={{ paddingLeft: "5px" }}
                                  alt=""
                                />
                              </Button>
                            </Dropdown>
                          </div>
                        </div>
                        </div>
                      
                      {/* </SpinnerLoader> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <NewCartModal
          isModalVisible={showModal}
          setModalVisible={setShowModal}
          appUser={appUser}
          isNewCartDetailsNeed={true}
        />
      </div>
    </SpinnerLoader>
  );
};
