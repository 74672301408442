/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./groupList.less";
import "../../../stateless/common/customScrollBar/customScrollStyle.less";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCheckedGroupIds,
  fetchGroupByType,
  fetchGroupList,
  selectGroups,
  unSelectGroups,
} from "../../../../../redux/actions/groups/groupActions";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { GroupList } from "../groupList";
import {
  groupstateSelector,
  setLandingPageScroll,
} from "../../../../../redux/reducers/groups/groupListReducer";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { setSideBarItem } from "../../../../../redux/actions/app/appActions";
import { Button, Checkbox, Col, Drawer, Row, Tooltip } from "antd";
import moment from "moment-timezone";
import {
  ascIcon,
  descIcon,
} from "../../../../images";
import { clearBooklistCurrentPage } from "../../../../../redux/reducers/books/bookListReducer";
import { fetchUsers } from "../../../../../redux/actions/user/userAction";
import { selectAllRemember } from "../../../../../redux/actions/books/bookActions";

export const Groups = () => {
  const dispatch = useDispatch();
  const { appUser,users } = useSelector(userStateSelector);
  const {
    formState,
    groups,
    checkedGroups,
    prevCheckedGroups,
    groupType,
    chkGrpformState,
    unSelectformState,
    selectformState,
    landingScroll,
  } = useSelector(groupstateSelector);
  const [customize, setCustomize] = useState<boolean>(false);
  const [getscrollheight, setGetScrollHeight] = useState<number>();
  const [scrollValue, setScrollValue] = useState(0);
  const [groupData, setGroupData] = useState<any>();
  const [groupIcon,setGroupIcon] = useState<any>(ascIcon);
  const [dateIcon,setDateIcon] = useState<any>(ascIcon);

  const closeDrawer = () => {
    setCustomize(false);
  };

  function arrayEquals(a: any, b: any) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  const FetchUserGroups = () => {
    var groupRequestBody = {
      libId:
        appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0,
      countryCode:
        appUser && appUser.libraryData
          ? appUser.libraryData[0].CountryCode
          : "",
      limit: 6,
      showOnlyUnshown: false,
    };
    return dispatch(fetchGroupList(groupRequestBody));
  };

  useEffect(() => {
    dispatch(setSideBarItem("Curated Lists"));
    dispatch(clearBooklistCurrentPage())
    dispatch(selectAllRemember(false))
    if (!(groups && groups.length)) {
      FetchUserGroups();
      dispatch(fetchCheckedGroupIds({}));
    }
    // FetchUnshownUserGroups()
    const userLength = users && users?.length ? users?.length : 0;
    if (userLength <= 0) {
      const libId =
        appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
        if(appUser?.isLibraryAdmin || appUser?.IsPatronCommunicator){
          dispatch(
            fetchUsers({
              libId,
              activeOnly: 1
            })
          );
        }
    }
  }, []);
  


  useEffect(() => {

    if (
      customize === false &&
      arrayEquals(prevCheckedGroups, checkedGroups) === false
    ) {
      FetchUserGroups();
      dispatch(fetchCheckedGroupIds({}));
    }
  }, [prevCheckedGroups, checkedGroups, customize]);

  var dateFormat = appUser?.libraryData
    ? appUser.libraryData[0]?.DateFormat.toUpperCase()
    : "";
  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr).tz("Etc/GMT").format(dateFormat);
    return currentDate;
  };
  const getDateDiff = (date: any) => {
    var currentDte =  moment(new Date()).format(dateFormat);
    var given = date
    const diffDays = moment(currentDte).diff(given, 'days')
    return  diffDays
  };
  const coloumnChange = (e: any) => {
    var checked = e.target.checked;
    var checkedId = e.target.id;
    if (checked === true) {
      dispatch(selectGroups({ groupId: checkedId }));
    } else if (checked === false) {
      dispatch(unSelectGroups({ groupId: checkedId }));
    } else {
      return undefined;
    }
  };

  const handelSideBar = () => {
    dispatch(setLandingPageScroll(getscrollheight));
    setCustomize(true);
    if (groupType?.length < 1) {
      return dispatch(fetchGroupByType({ isGroupType: false }));
    } else {
      if (arrayEquals(prevCheckedGroups, checkedGroups) === false) {
        return dispatch(fetchGroupByType({ isGroupType: false }));
      }
    }
  };

  const handleScroll = () => {
    if (formState.loading === false) {
      const element = document.getElementById(`landingPageScrren`);
      let x = element?.scrollTop;
      x && setScrollValue(x);
      dispatch(setLandingPageScroll(x))
      setGetScrollHeight(scrollValue);
    }
  };

  useEffect(() => {
    if (formState.loading === false) {
      const element = document.getElementById(`landingPageScrren`);
      element?.scrollTo(0, landingScroll);
    }
  }, [formState.loading]);

  useEffect(() => {
    const groupTypeSorted = groupType?.slice().sort((a:any,b:any) => a.FeaturedListGroupName.localeCompare(b.FeaturedListGroupName))
    setGroupData(groupTypeSorted)
  }, [groupType])

  const GroupSort = () => {
    if(groupIcon === '') {
      setGroupIcon(ascIcon)
    }
    if(groupIcon === ascIcon) {
      setGroupIcon(descIcon)
    }
    if(groupIcon === descIcon) {
      setGroupIcon(ascIcon)
    }
  }

  useEffect(() => {
    if(groupIcon === ascIcon) {
      const groupTypeSorted = groupType?.slice().sort((a:any,b:any) => a.FeaturedListGroupName.localeCompare(b.FeaturedListGroupName))
    setGroupData(groupTypeSorted)
    }
    if(groupIcon === descIcon) {
      const groupTypeSorted = groupType?.slice().sort((a:any,b:any) => b.FeaturedListGroupName.localeCompare(a.FeaturedListGroupName))
      setGroupData(groupTypeSorted)
    }
  }, [groupIcon])
  
  const dateSort = () => {
    if(dateIcon === '') {
      setDateIcon(ascIcon)
    }
    if(dateIcon === ascIcon) {
      setDateIcon(descIcon)
    }
    if(dateIcon === descIcon) {
      setDateIcon(ascIcon)
    }
  }

  useEffect(() => {
    if(dateIcon === ascIcon) {
      const groupTypeSorted = groupType?.slice().sort((a:any,b:any) => a.ChangeDateTime.localeCompare(b.ChangeDateTime))
      setGroupData(groupTypeSorted)
    }
    if(dateIcon === descIcon) {
      const groupTypeSorted = groupType?.slice().sort((a:any,b:any) => b.ChangeDateTime.localeCompare(a.ChangeDateTime))
      setGroupData(groupTypeSorted)
    }
  }, [dateIcon])
 
  return (
    <div className={`groups-container ${formState.loading?'groups-container-loading':''}`}>
      <Row>
        <Col xs={9} sm={10} md={11} lg={14} xl={16} xxl={17}></Col>
        <Col xs={5} sm={5} md={6} lg={5} xl={4}>
          <Button className="primary CustomizeButtonSpotlight" onClick={handelSideBar}>
            Customize
          </Button>
        </Col>
      </Row>
      <SpinnerLoader loading={formState.loading}>
        <div
          className="scrollContent scrollStyle groups CustomizeButtonPadding"
          id="landingPageScrren"
          onScroll={handleScroll}
        >
          <div className="listBox">
          {groups && groups.length
            ? groups?.map((data: any, index: number) => {
                if (data?.IsShown === true) {
                  return (
                    <div key={`${data.FeaturedListGroupName}`}>
                      <GroupList group={data} appUser={appUser} />
                    </div>
                  );
                }
                else { return []} 
              })
            : null}
          {groups && groups.length
            ? groups
                ?.filter((item) =>
                  checkedGroups?.includes(item?.FeaturedListGroupId)
                )
                ?.map((data: any) => {
                  return (
                    <div key={`${data.FeaturedListGroupName}`}>
                      <GroupList group={data} appUser={appUser} />
                    </div>
                  );
                })
            : null}
            </div>
        </div>
      </SpinnerLoader>
      <Drawer
        placement="right"
        width="428"
        className="spotLightContentDrawerWrapper"
        onClose={closeDrawer}
        visible={customize}
      >
        <div className="spotLightDrawerWrapper">
          <div className="spotlightDrawerTitle">Customize Display</div>
          <div className="sub-drawer scrollStyle">
            <div className="drawer-padding-adjusted">
              <Row className="spotlightDrawerTablePadding">
                <Col
                  span={"14"}
                  className="spotlightDrawerTableTitle GroupNamepadding"
                  onClick={GroupSort}
                >
                  Group Name
                  <img src={groupIcon} style={{height:"15px", paddingRight:"5px",marginLeft: "2px"}} alt="" />
                </Col>
                <Col className="spotlightDrawerTableTitle" onClick={dateSort}>
                  Last Updated Date
                  <img src={dateIcon} style={{height:"15px", paddingRight:"5px",marginLeft: "2px"}} alt="" />
                </Col>
              </Row>
              <SpinnerLoader
                loading={
                  chkGrpformState?.loading ||
                  selectformState?.loading ||
                  unSelectformState?.loading
                }
              >

                {groupData?.map((item: any) => {
                  return (
                    <Row key={item?.FeaturedListGroupId}>
                      <Col className="spotlightDrawerTableText" span={"14"}>
                        <Checkbox
                          id={item?.FeaturedListGroupId}
                          className="spotLightDrawerCheckbox"
                          onChange={coloumnChange}
                          checked={checkedGroups?.includes(
                            item?.FeaturedListGroupId
                          )}
                        >
                          <Tooltip title={item?.FeaturedListGroupName} placement="bottom"> 
                          {item?.FeaturedListGroupName}
                          </Tooltip>
                        </Checkbox>
                      </Col>
                      <Col className="spotlightDrawerTableDate">
                        {dateModify(item?.ChangeDateTime)}
                        {item?.IsNew ? (
                          <span className="spotlightDrawerNewTag">NEW</span>
                        ) : getDateDiff(dateModify(item?.ChangeDateTime)) <= 7 ? (
                          <span className="spotlightDrawerNewTag">NEW</span>
                        ) : null}
                      </Col>
                    </Row>
                  );
                })}
              </SpinnerLoader>
            </div>
            <div className="library-save">
              <Button
                className="secondary spotlightDrawerCancelBtn"
                onClick={closeDrawer}
              >
                Close
              </Button>
              {/* {showSaveBtn ? (
                <Button
                  className="secondary spotlightDrawerSaveBtn"
                  onClick={saveLanding}
                >
                  Save
                </Button>
              ) : null} */}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
