import React from 'react'
import { Row, Modal, } from "antd";
import Button from "../../../stateless/common/button";

const BudgetModel = (props: any) => {
    const {showModal,setShowModal,title,message1,message2,message3,onConfirm} = props

      const onCloseModel=()=>{
        setShowModal(false)
      }

      const onHandleOk=()=>{
        onConfirm(true)
        setShowModal(false)
      }


  return (
    <Modal
    wrapClassName="purchase-modal"
    visible={showModal}
    onCancel={onCloseModel}
    footer={
          <Row justify="space-around">
          <Button
            type="primary confirm-button"
            onClick={onHandleOk}
          >
            Ok
          </Button>
          <Button
            type="smallRed confirm-button"
            onClick={onCloseModel}
          >
            Cancel
          </Button>
          </Row>
      }
    >
        <p className="purchase-title">{title}</p>
        <div className="remove-content">
        <p className='purchaseNote'>
          <span>{message1}</span>
          <span>{message2}</span>
          <span>{message3}</span>
        </p>
        </div>
    </Modal>
  )
}

export default BudgetModel