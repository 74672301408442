import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SearchstateSelector } from "../../../../../redux/reducers/search/searchReducer";
import { AppRoutes } from "../../../../router/appRoutes";
import { Empty } from "antd"
import SpinnerLoader from "../spinnerLoader";
import { notFound } from "../../../../images";
import { Book } from "../../../../models/book/book";

export const SearchRenderer = React.memo((props: any) => {
  const { setDisplay, selectItem, enterHit } = props;
  const { quickSearchResults, quickSearchState } = useSelector(SearchstateSelector)
  const history = useHistory()
  const onBookSelect = (book: any) => {
    var bookState: any = {
      book: book,
    };
    selectItem(book.Title);
    setDisplay(false);
    history.push(AppRoutes.ITEMDETAILS, bookState);
  };
  return (
    <div className="search-dropdown">
      {quickSearchState?.loading && !enterHit ?
        <SpinnerLoader loading={quickSearchState?.loading} />
        :
        quickSearchResults && quickSearchResults?.length ? quickSearchResults?.map((item: Book) => {
          return (
            <div
              key={item?.CatalogItemId}
              className="search-options"
              style={{
                display: "flex",
                alignItems: "center",
                lineHeight: "48px",
              }}
              onClick={() => onBookSelect(item)}
            >
              <span>
                <img src={item?.ImageUrl} onError={(e: any) => { e.target.src = notFound }} alt="" className="bookCover" />
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span className="bookFormat">{item?.Title}</span>
                <span className="bookFormat"> 
                  {item?.FileFormatName} &#8226; {item?.NumberInSeries? "#"+item?.NumberInSeries: ""} {item?.Series? item?.Series: ""}</span>
              </div>
            </div>
          );
        }) : !quickSearchState?.loading && <Empty style={{ paddingTop: "20px", paddingBottom: "20px" }} description="No results found." />}
      {/* </SpinnerLoader> */}
    </div>
  );
});
