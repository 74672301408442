import { createAsyncThunk } from "@reduxjs/toolkit";
import { showError } from "../../../utility/appUtil";
import {PurchaseService} from "../../../web/services/purchases/purchasesService";
const purchaseSrv = new PurchaseService()

  export const getPurchaseUser = createAsyncThunk(
    "api/getPurchaseUser",
    async (_args:any, thunkAPI) => {
      try {
        const  data = await purchaseSrv.fetchPurchaseUser(_args);
        if (data && data.length) { 
          return data;
        }else {
          showError(data)
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const getPurchase = createAsyncThunk(
    "api/getPurchase",
    async (_args:any, thunkAPI) => {
      try {
        const  data = await purchaseSrv.fetchPurchaseOrder(_args);
        if (data) { 
          return data;
        }else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );
  
  export const getPurchaseDetails = createAsyncThunk(
    "api/getPurchaseDetails",
    async (_args:any, thunkAPI) => {
      try {
        const  data = await purchaseSrv.fetchPurchaseDetails(_args);
        if (data && data?.length) { 
          return data;
        }else {
          showError(data)
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );
  
  export const getPurchasePending = createAsyncThunk(
    "api/getPurchasePending",
    async (_args:any, thunkAPI) => {
      try {
        const  data = await purchaseSrv.fetchPurchaseOrder(_args);
        if (data) { 
          return data;
        }else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const getPurchaseComplete = createAsyncThunk(
    "api/getPurchaseComplete",
    async (_args:any, thunkAPI) => {
      try {
        const  data = await purchaseSrv.fetchPurchaseOrder(_args);
        if (data) { 
          // var filterData = [data]?.map((item: any) => {
          //   return item?.Results;
          // })[0].filter((dt:any)=>{
          //   return dt?.IsComplete === true;
          // })
          return data;
        }else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const resizePurchaseCols = createAsyncThunk(
      "api/resizePurchaseCols",
      async (_args: any, thunkAPI) => {
        try {
          const data = _args
          if (data) {
            return data;
          } else {
            return thunkAPI.rejectWithValue(data);
          }
        } catch (e: any) {
          return thunkAPI.rejectWithValue(e.data);
        }
      }
    );

  export const resizeCompletedCols = createAsyncThunk(
    "api/resizeCompletedCols",
    async (_args: any, thunkAPI) => {
      try {
        const data = _args
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const resizePendingCols = createAsyncThunk(
    "api/resizePendingCols",
    async (_args: any, thunkAPI) => {
      try {
        const data = _args
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const resizeDetailsCols = createAsyncThunk(
    "api/resizeDetailsCols",
    async (_args: any, thunkAPI) => {
      try {
        const data = _args
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );
 
  export const resizePreSaleCols = createAsyncThunk(
    "api/resizePreSaleCols",
    async (_args: any, thunkAPI) => {
      try {
        const data = _args
        if (data) {
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const getPrePub = createAsyncThunk(
    "api/getPrePub",
    async (_args:any, thunkAPI) => {
      try {
        const  data = await purchaseSrv.fetchPreSaleData(_args);
        if (data && data?.TotalCount) { 
          return data;
        }else {
          showError(data)
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );


  export const updatePrePub = createAsyncThunk(
      "api/updatePrePub",
      async (_args: any, thunkAPI) => {
        try {
          const data = await purchaseSrv.updatePreSale(_args);
          if (data && data?.TotalCount) {
            const obj = {
              lib_Id:_args.lib_id,
              record_Count:_args.body.record_Count,
              record_Start:_args.body.record_Start,
              sort_Direction:_args.body.sortDirection,
              sort_Field:_args.body.sortField
            }
            thunkAPI.dispatch(getPrePub(obj));
            return data;
          } else {
            showError(data)
            return thunkAPI.rejectWithValue(data);
          }
        } catch (e: any) {
          return thunkAPI.rejectWithValue(e.data);
        }
      }
    );

  export const deletePrePub = createAsyncThunk(
    "api/deletePrePub",
    async (_args:any, thunkAPI) => {
      try {
        const  data = await purchaseSrv.deletePreSale(_args);
        if (data && data?.TotalCount) { 
          return data;
        }else {
          showError(data)
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const searchFilters = createAsyncThunk(
    "api/searchFilters",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

  export const onApplyingFilters = createAsyncThunk(
    "api/onApplyingFilters",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const recentTab = createAsyncThunk(
    "api/recentTab",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );

  export const cameFromPurchaseDetail = createAsyncThunk(
    "api/cameFromPurchaseDetail",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );
  export const purchasesFilterRemember = createAsyncThunk(
    "api/purchasesFilterRemember",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );