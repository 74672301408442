/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable use-isnan */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React from "react";
import {
  Row,
  Col,
  Card,
  Input,
  DatePicker,
  message,
  Form,
  TreeSelect,
  Empty,
} from "antd";
import "./searchForm.less";
import DropDown from "../../../stateless/common/dropDown";
import { filterOptions, Page } from "../../../../constants/constants";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { downIcon, searchIcon } from "../../../../images";
import Button from "../../../stateless/common/button";
import { useState } from "react";
import { ItemFormat } from "../../../../models/search/mediaType";
import { useEffect } from "react";
import moment from "moment";
import { SaveSearchModal } from "./saveSearchModal";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSaved,
  clearSaveSearchResult,
  SearchstateSelector,
  setSearchAfterEdit,
  setSearchTab,
  clearAdvanceSearchData,
  clearResults,
  clearState,
  setSearch,
} from "../../../../../redux/reducers/search/searchReducer";
import { useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../router/appRoutes";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import {
  fetchSearchList,
  getSaveSearchResult,
  getSearchString,
  saveSearch,
  onBackFillForm,
  onStringBackFillForm,
  getUnmatchValues,
} from "../../../../../redux/actions/search/searchActions";
import FormItem from "antd/lib/form/FormItem";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import Category from "../../../../models/search/category";
import { Messages } from "../../../../constants/messages";
import { setGlobalCopy, setSelectedTitles } from "../../../../../redux/actions/books/bookActions";
import MultiSelect from "../../../stateless/common/multiSelect";

export const AdvancedSearchForm = React.memo((props: any) => {
  const {
    audienceDataOptions,
    providerOptions,
    publisherOptions,
    formatOptions,
    languageOptions,
    setKey,
    libraryId,
    setShowLoading,
    isCollection,
    clearForm,
    showPpuField
  } = props;
  const { RangePicker } = DatePicker;
  const {
    formState,
    searchFormState,
    searchSaved,
    categoriesOptions,
    saveSearchResults,
    backFormFill,
    stringBackFormFill,
    searchStrings
  } = useSelector(SearchstateSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const editableData: any =
    saveSearchResults?.searchParams !== undefined
      ? saveSearchResults?.searchParams
      : [];
  const [selectedFormats, setSelectedFormats] = useState<any>(["1", "2", "3"]);
  const [dates, setDates] = useState<any>([]);
  const [customDates, setCustomDates] = useState<any>([]);
  const [cloudDate, setCloudDate] = useState<any>([]);
  const [hackValue, setHackValue] = useState<any>();
  const [value, setValue] = useState<any>();
  const [cloudHackValue, setCloudHackValue] = useState<any>();
  const [cloudValue, setCloudValue] = useState<any>();
  const [daysVal, setDaysVal] = useState<any>();
  const [cloudDaysVal, setCloudDaysVal] = useState<any>();
  const [treevalue, setTreeValue] = useState<any>();
  const [treeParentvalue, setTreeParentvalue] = useState<any>([]);
  const [treeChildvalue, setTreeChildvalue] = useState<any>([]);
  const [parentTreeChildvalue, setParentTreeChildvalue] = useState<any>();
  const [treeData, setTreeData] = useState<any>();
  const [newCatObj, setNewCatObj] = useState<any>();
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [showCustomDateRange, setShowCustomDateRange] = useState<boolean>(false);
  const [showCloudDateRange, setShowCloudDateRange] = useState<boolean>(false);
  const [editedData, setEditedData] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [haskeyWord, setHaskeyWord] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>("");
  const [narratorVisible, setNarratorVisible] = useState<boolean>(true);
  const [narratorValue, setNarratorValue] = useState<any>(undefined);
  const [selFormat, setSelFormats] = useState<any>();
  const { appUser } = useSelector(userStateSelector);
  const location = useLocation<any>();
  var userCatalog =
    appUser && appUser.libraryData ? appUser.libraryData[0].NatureId : 0;
  const advancedSearchInitialdata = {
    SearchSaveId:
      saveSearchResults?.SearchSaveId !== undefined
        ? saveSearchResults?.SearchSaveId
        : "",
    SearchSaveName:
      saveSearchResults?.SearchSaveName !== undefined
        ? saveSearchResults?.SearchSaveName
        : "",
    AuthorSearch: [],
    Narrator: "",
    CategorySearch: [],
    FromPublisherDate: "",
    FromPurchaseDate: "",
    HoldRatio: "",
    ItemFormatId: 0,
    Language: [],
    MaximumPriceAmount: "",
    MinimumPriceAmount: "",
    ExcludeSelfPublished: false,
    PublisherSearch: [],
    QuickSearch: [],
    SubCategorySearch: [],
    TitleDaysOld: 0,
    PreSaleDays: 0,
    ExPpuTitleDays: 0,
    TitleSearch: [],
    ToPublisherDate: "",
    ToPurchaseDate: "",
    TargetAudienceIds: [],
    ContentProviderSearch: [],
    AddedToCatalogDateFrom: "",
    AddedToCatalogDateTo: "",
    AddedToCatalogDaysOld: 0,
    ItemFormatIdSet: selectedFormats,
    SearchSaveDescription: "",
    SharingStatusCode: "P",
    LibraryId: libraryId,
    NotInCollection:isCollection?false:true,
    InCollection:isCollection?true:null,
    EISBN: "",
    ChangeDateTime: "",
    ChangeUserId: "",
    CreateDateTime: "",
    CreateUserId: "",
    AllowedSharingStatusCodes: ["P"],
    MediaTypeIdSet: [],
    CatalogId: userCatalog,
    series: null,
    poRefNumber: "",
    recordCount: Page.DEFAULT_COUNT,
    recordStart: Page.DEFAULT_START,
    sortField: "",
    sortDirection: "ASC",

  };
  const saveID = {
    SearchSaveId:
      saveSearchResults?.SearchSaveId !== undefined
        ? saveSearchResults?.SearchSaveId
        : "",
    SearchSaveName:
      saveSearchResults?.SearchSaveName !== undefined
        ? saveSearchResults?.SearchSaveName
        : "",
    SharingStatusCode: "P",
    LibraryId: libraryId,
    NotInCollection: false,
    EISBN: "",
    ChangeDateTime: "",
    ChangeUserId: "",
    CreateDateTime: "",
    CreateUserId: "",
    AllowedSharingStatusCodes: ["P"],
    MediaTypeIdSet: [],
    CatalogId: userCatalog,
    recordCount: 50,
    recordStart: 1,
    sortField: "",
    sortDirection: "",
    PPUCriteria:"1"
  };
  const editSaveInitialData = Object.assign(
    saveID,
    saveSearchResults?.searchParams
  );
  const [searchDetails, setSearchDetails] = useState<any>(
    saveSearchResults?.searchParams !== undefined
      ? editSaveInitialData
      : advancedSearchInitialdata
  );
  const showQuery = {
    title: "",
    author: "",
    narrator: "",
    category: "",
    subCategory: "",
    audience: "",
    language:"",
    keyword: "",
    series: "",
    poRefNumber: "",
    persaleTitle: !isCollection ? filterOptions.preSale[0].text : "",
    exppuTitle: filterOptions.ppuTitles[0].text,
    HoldRatio: "",
    MaximumPriceAmount: "",
    MinimumPriceAmount: "",
    publisher: "",
    publisheWithin: "",
    provider: "",
    dateAdedToCl: "",
    formats: "all",
    filters: "",
    publishDays: "",
    PPUCriteria:filterOptions.PPUCriteria[1].text,
    POReference: "",
    customDateRange: "",
    EISBN:"",
    QuickSearch:[]
  }

  const [strinDetails, setStrinDetails] = useState<any>(showQuery);
  //my collection filelds
  const myColObj = {
    PPUCriteria: "1",
    POReference: "",
  }
  const [collectionObj, setCollectionObj] = useState<any>(myColObj);

  useEffect(() => {
    setTimeout(()=>{
      setStrinDetails(stringBackFormFill?.HoldRatio !== undefined?stringBackFormFill:strinDetails);
    },500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringBackFormFill]);

  const showNotification = (content: any, type: any) => {
    type === "loading"
      ? message.loading({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
      : message.success({
          content: content,
          key: "notificationMessage",
        });
  };

  const setEditInitialData = (editableData: any) => {
    setSearchDetails({
      ...searchDetails,
      SearchSaveId: saveSearchResults?.SearchSaveId,
      SearchSaveName: saveSearchResults?.SearchSaveName,
      AuthorSearch:
        editableData?.AuthorSearch !== undefined
          ? editableData?.AuthorSearch
          : [],
      Narrator:
        editableData?.Narrator !== undefined ? editableData?.Narrator : "",
      CategorySearch:
        editableData?.CategorySearch !== undefined
          ? editableData?.CategorySearch
          : [],
      FromPublisherDate:
        editableData?.FromPublisherDate !== undefined
          ? editableData?.FromPublisherDate
          : "",
      HoldRatio:
        editableData?.HoldRatio !== undefined ? editableData?.HoldRatio : "",
      ItemFormatId:
        editableData?.ItemFormatId !== undefined
          ? editableData?.ItemFormatId
          : 0,
      Language:
        editableData?.Language !== undefined ? editableData?.Language : [],
      MaximumPriceAmount:
        editableData?.MaximumPriceAmount !== undefined
          ? editableData?.MaximumPriceAmount
          : "",
      MinimumPriceAmount:
        editableData?.MinimumPriceAmount !== undefined
          ? editableData?.MinimumPriceAmount
          : "",
      ExcludeSelfPublished:
        editableData?.ExcludeSelfPublished !== undefined
          ? editableData?.ExcludeSelfPublished
          : false,
      PublisherSearch:
        editableData?.PublisherSearch !== undefined
          ? editableData?.PublisherSearch
          : [],
      QuickSearch:
        editableData?.QuickSearch !== undefined
          ? editableData?.QuickSearch
          : [],
      SubCategorySearch:
        editableData?.SubCategorySearch !== undefined
          ? editableData?.SubCategorySearch
          : [],
      TitleDaysOld:
        editableData?.TitleDaysOld !== undefined
          ? editableData?.TitleDaysOld
          : 0,
      PreSaleDays:
        editableData?.PreSaleDays !== undefined ? editableData?.PreSaleDays : 0,
      ExPpuTitleDays:
        editableData?.ExPpuTitleDays !== undefined
          ? editableData?.ExPpuTitleDays
          : 0,
      TitleSearch:
        editableData?.TitleSearch !== undefined
          ? editableData?.TitleSearch
          : [],
      ToPublisherDate:
        editableData?.ToPublisherDate !== undefined
          ? editableData?.ToPublisherDate
          : "",
      TargetAudienceIds:
        editableData?.TargetAudienceIds !== undefined
          ? editableData?.TargetAudienceIds
          : [],
      ContentProviderSearch:
        editableData?.ContentProviderSearch !== undefined
          ? editableData?.ContentProviderSearch
          : [],
      AddedToCatalogDateFrom:
        editableData?.AddedToCatalogDateFrom !== undefined
          ? editableData?.AddedToCatalogDateFrom
          : "",
      AddedToCatalogDateTo:
        editableData?.AddedToCatalogDateTo !== undefined
          ? editableData?.AddedToCatalogDateTo
          : "",
      AddedToCatalogDaysOld:
        editableData?.AddedToCatalogDaysOld !== undefined
          ? editableData?.AddedToCatalogDaysOld
          : 0,
      ItemFormatIdSet:
        editableData?.ItemFormatIdSet !== undefined
          ? editableData?.ItemFormatIdSet
          : selectedFormats,
      SearchSaveDescription:
        editableData?.SearchSaveDescription !== undefined
          ? editableData?.SearchSaveDescription
          : "",
      series: editableData?.series !== undefined ? editableData?.series : "",
      poRefNumber:
        editableData?.poRefNumber !== undefined
          ? editableData?.poRefNumber
          : "",
      FromPurchaseDate:
      editableData?.FromPurchaseDate !== undefined
        ? editableData?.FromPurchaseDate
        : "",
      ToPurchaseDate:
      editableData?.ToPurchaseDate !== undefined
        ? editableData?.ToPurchaseDate
        : "",
    });

    editableData?.ItemFormatIdSet !== undefined &&
      setSelectedFormats(editableData?.ItemFormatIdSet?.map(String));
  };

   const onClear = () => {
    setHaskeyWord(false)
    dispatch(clearAdvanceSearchData());
    dispatch(getUnmatchValues([]))
    dispatch(onBackFillForm([]));
    setStrinDetails(showQuery)
    dispatch(onStringBackFillForm([]));
    setTreeValue(undefined);
    setCollectionObj({...collectionObj,PPUCriteria:"1",POReference:""})
    setSearchDetails({
      ...advancedSearchInitialdata
    });
    setNarratorValue("");
    setNarratorVisible(true);
    setShowCustomDateRange(false);
    setShowDateRange(false);
    setShowCloudDateRange(false);
  };

  // if save search have data
  useEffect(() => {
    dispatch(getSaveSearchResult(saveSearchResults));
    setCollectionObj({...collectionObj,PPUCriteria:"1",POReference:""})
    dispatch(setSelectedTitles([]))
    dispatch(setGlobalCopy(1))
  }, []);

  useEffect(() => {
    if (formState?.isSuccess) {
      dispatch(clearResults());
      dispatch(clearState());
    } else {
      return undefined;
    }
  }, [formState]);

  useEffect(() => {
    if (saveSearchResults?.searchParams !== undefined) {
      if (
        parseInt(editableData?.AddedToCatalogDaysOld) == 0 &&
        editableData?.AddedToCatalogDateFrom == ""
      ) {
        // setShowCloudDateRange(false);
      } else if (editableData?.AddedToCatalogDateFrom !== "") {
        // setShowCloudDateRange(true);
      } else if (editableData?.AddedToCatalogDateFrom == undefined) {
        // setShowCloudDateRange(false);
      }
    }
    if(editableData?.ItemFormatIdSet === undefined){
      setSelectedFormats(["1", "2", "3"])
      setStrinDetails({ ...strinDetails, formats: "all" });
      setShowCustomDateRange(false)
      // setShowCloudDateRange(false)
      // setShowDateRange(false);
    }
    if(editableData?.FromPurchaseDate) {
      setShowCustomDateRange(true)
     }
    setEditInitialData(saveSearchResults?.searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editableData?.AddedToCatalogDateFrom,
    editableData?.AddedToCatalogDaysOld,
    saveSearchResults?.searchParams,
    editableData?.FromPurchaseDate
  ]);

  const regexForStringReplace = /,/gi;
  const handleAuthorChange = (e: any) => {
    var temp: any = e.target.value
      ?.replace(regexForStringReplace, ",")
      .split(";");
    setStrinDetails({ ...strinDetails, author: temp.toString() });
    setSearchDetails({ ...searchDetails, AuthorSearch: temp });
  };
  const handleNarratorChange = (e: any) => {
    var temp: any = e.target.value;
    setStrinDetails({ ...strinDetails, narrator: temp.toString() });
    setSearchDetails({ ...searchDetails, Narrator: temp });
    setNarratorValue(temp);
  };
  const handleKeyWord = (e: any) => {
    setHaskeyWord(e?.target?.value?true:false)
    setSearchDetails({ ...searchDetails, QuickSearch: [e?.target?.value] });
      setStrinDetails({ ...strinDetails, keyword: e?.target?.value });
    };
    const handleTitle = (e: any) => {
      setSearchDetails({ ...searchDetails, TitleSearch: [e?.target?.value] });
      setStrinDetails({ ...strinDetails, title: e?.target?.value });
    };
    const handlePublisherChange = (value: any, defaultValue: any) => {
      var providerObj = publisherOptions?.map((item: any) => {
        if(value?.includes(item?.value)){
         return item?.title
        }
        })?.filter((items:any)=>{
          return items !== undefined
        });
        setStrinDetails({
          ...strinDetails,
          publisher:providerObj?.toString(),
        });
        setSearchDetails({ ...searchDetails, PublisherSearch: value });
    };
    const handleProviderChange = (value: any, defaultValue: any) => {
      var providerObj = providerOptions?.map((item: any) => {
        if(value?.includes(item?.value)){
         return item?.title
        }
        })?.filter((items:any)=>{
          return items !== undefined
        });
        setStrinDetails({
          ...strinDetails,
          provider:providerObj?.toString(),
        });
        setSearchDetails({ ...searchDetails, ContentProviderSearch: value });
    };
    const handleLanguageChange = (value: any, defaultValue: any) => {
      var langObj = languageOptions?.map((item: any) => {
      if(value?.includes(item?.value)){
       return item?.title
      }
      })?.filter((items:any)=>{
        return items !== undefined
      });
      setStrinDetails({
        ...strinDetails,
        language:langObj?.toString(),
      });
      setSearchDetails({ ...searchDetails, Language: value });
    };
    const handleAudienceChange = (value: any, defaultValue: any) => {
      let pushData: any[] = [];
      var audienceObj = defaultValue.map((item: any) => {
        return item?.children[1]?.props?.children;
      });
      pushData.push(audienceObj);
      setStrinDetails({ ...strinDetails, audience: pushData[0].toString() });
      setSearchDetails({ ...searchDetails, TargetAudienceIds: value });
    };

    const handlePreSale = (value: any, defaultValue: any) => {
      setStrinDetails({
        ...strinDetails,
        persaleTitle: defaultValue?.children[1]?.props?.children,
      });
      setSearchDetails({ ...searchDetails, PreSaleDays: value });
    };

    const handleExPpuTitle = (value: any, defaultValue: any) => {
     if(isCollection){
        setCollectionObj({
          ...collectionObj,
          PPUCriteria: value,
        });
      }else{
        setSearchDetails({ ...searchDetails,  PPUCriteria: value, });
      } 
      setStrinDetails({
        ...strinDetails,
        PPUCriteria:defaultValue?.children[1]?.props?.children,
      });
    };
    const handleFormatChange = (e: any) => {
      e.target.checked
        ? setSelectedFormats(selectedFormats.concat(e.target.name))
        : setSelectedFormats(
            selectedFormats.filter((value: any) => {
              return value !== e.target.name;
            })
          );
    };
    useEffect(() => {
      setSelFormats(selectedFormats);
      // eslint-disable-next-line array-callback-return
      var Formatval = selectedFormats.map((item: any) => {
        if (item == "1") {
          return "EPUB";
        } else if (item == "2") {
          return "PDF";
        } else if (item == "3") {
          return "MP3";
        }
      });
      if (selectedFormats?.length >= 3) {
        setStrinDetails({ ...strinDetails, formats: "all" });
      } else {
        setStrinDetails({ ...strinDetails, formats: Formatval.toString() });
      }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFormats]);

    useEffect(() => {
      setSearchDetails({ ...searchDetails, ItemFormatIdSet: selFormat });
      // eslint-disable-next-line array-callback-return
      var Formatval = selectedFormats.map((item: any) => {
        if (item == "1") {
          return "EPUB";
        } else if (item == "2") {
          return "PDF";
        } else if (item == "3") {
          return "MP3";
        }
      });
      if (selectedFormats?.length >= 3) {
        setStrinDetails({ ...strinDetails, formats: "all" });
      } else {
        setStrinDetails({ ...strinDetails, formats: Formatval.toString() });
      }
    }, [selFormat]);

    useEffect(() => {
      if (searchSaved) {
        showNotification(Messages.SAVE_SEARCH_SUCCESS, "success");
        dispatch(clearSaved());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.isSuccess, searchSaved]);

    const handleDateRangeSelect = (value: number) => {
      if (value !== -2) {
        setDaysVal(value);
        setShowDateRange(false);
        if (value === -1) {
          setShowDateRange(true);
        } else {
          setShowDateRange(false);
        }
      } else {
        setDaysVal(0)
        setShowDateRange(false);
      }
    };
    
    const handleCloudDateRangeSelect = (value: number) => {
      if (value !== -2) {
        setCloudDaysVal(value);
        setShowCloudDateRange(false);
        if (value === -1) {
          setShowCloudDateRange(true);
        } else {
          setShowCloudDateRange(false);
        }
      } else {
        setCloudDaysVal(0)
        setShowCloudDateRange(false);
      }
    };
    useEffect(() => {
      if (daysVal !== undefined) {
        setStrinDetails({
          ...strinDetails,
          publishDays: `${daysVal} days`,
          publisheWithin: "",
        });
        setSearchDetails({
          ...searchDetails,
          FromPublisherDate: "",
          ToPublisherDate: "",
          TitleDaysOld: daysVal !== -1 ? daysVal : "",
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daysVal]);
    useEffect(() => {
      if (cloudDaysVal !== undefined) {
        setStrinDetails({
          ...strinDetails,
          dateAdedToCl: `${cloudDaysVal} days`,
        });
        setSearchDetails({
          ...searchDetails,
          AddedToCatalogDateFrom: "",
          AddedToCatalogDaysOld: cloudDaysVal !== -1 ? cloudDaysVal : "",
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cloudDaysVal]);
    const handleRangeSelect = (date: any, dateString: any) => {
      setDates(dateString);
      setStrinDetails({
        ...strinDetails,
        publisheWithin: dateString.toString(),
        publishDays: "",
      });
    };
    const handleDateSelect = (date: any, dateString: any) => {
      setCloudDate(dateString);
      setStrinDetails({ ...strinDetails, dateAdedToCl: dateString.toString() });
    };
    const handleInputChanges = (e: any) => {
      let x:any = []
      var name = e.target.name;
      var value = e.target.value;
      setStrinDetails({ ...strinDetails, [name]: value });
      if(e?.target?.name === 'series'){
        x?.push(value)
        setSearchDetails({ ...searchDetails, [name]: x });
      }else{
        setSearchDetails({ ...searchDetails, [name]: value });
      }
    };

    useEffect(() => {
      var max = parseFloat(strinDetails?.MaximumPriceAmount);
      var min = parseFloat(strinDetails?.MinimumPriceAmount);
      if (max < min) {
        setShowError("Max value should be greater than or equal to min value");
      } else {
        setShowError("");
      }
    }, [strinDetails?.MaximumPriceAmount, strinDetails?.MinimumPriceAmount]);

    const handleFilterChange = (e: any) => {
      var name = e.target.name;
      var checked = e.target.checked;
      if (checked === true) {
        setStrinDetails({ ...strinDetails, filters: "applied" });
      } else {
        setStrinDetails({ ...strinDetails, filters: "" });
      }
      if(e.target.name === "ExcludeSelfPublished"){
        setSearchDetails({ ...searchDetails, [name]: checked});
      }
      if(e.target.name === "InCollection"){
        setSearchDetails({ ...searchDetails, [name]: checked? false : null});
      }
    };
    useEffect(() => {
      setSearchDetails({
        ...searchDetails,
        FromPublisherDate: dates[0],
        ToPublisherDate: dates[1],
        TitleDaysOld: "",
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates]);
    useEffect(() => {
      setSearchDetails({
        ...searchDetails,
        AddedToCatalogDateFrom: cloudDate[0],
        AddedToCatalogDateTo: cloudDate[1],
        AddedToCatalogDaysOld: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudDate]);

  const onSearch = () => {
    dispatch(clearResults());
    dispatch(clearState());
    dispatch(getSearchString(strinDetails));
    if (showError.length < 5) {
      if (isCollection) {
        dispatch(onBackFillForm({ ...searchDetails, ...collectionObj }));
        dispatch(
          getSaveSearchResult({ searchParams: {...searchDetails, ...collectionObj} })
        );
        var collecState: any = {
          fromAdvancedSearch: true,
          searchDetails: { ...searchDetails, ...collectionObj },
          isCameFromAdvanceSearch:true,
          gaString: {
            byFrom: "onCollectionSearchbtn",
            gaData: strinDetails,
          },
        };
        history.push(AppRoutes.COLLECTIONSEARCHRESULTS, collecState);
      } else {
        dispatch(
          getSaveSearchResult({ searchParams: searchDetails })
        );
        dispatch(onBackFillForm(searchDetails));
        var advanceState: any = {
          fromAdvancedSearch: true,
          searchDetails: searchDetails,
          gaString:{
            byFrom:'onSearchbtn',
            gaData:strinDetails
          }
        };
        history.push(AppRoutes.SEARCHRESULTS, advanceState);
      }
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    setSearchDetails({ ...saveSearchResults?.searchParams });
    setStrinDetails({ ...searchStrings });
  }, [location]);

  useEffect(() => {
    setSearchDetails({
      ...searchDetails,
      CategorySearch: treeParentvalue,
      SubCategorySearch: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeParentvalue, treeChildvalue, parentTreeChildvalue]);

  const getNewCatObject = (categoriesOptions: any) => {
    function onlyUnique(value: any, index: any, self: any) {
      return self?.indexOf(value) === index;
    }
    var totalCatGroup = categoriesOptions?.map((data: any) => {
      var group = data?.CategoryGroup;
      return group;
    }, []);
    var totalUniqCatG = totalCatGroup?.filter(onlyUnique);

    function pushNewArray(groupN: string) {
      var filterData = categoriesOptions?.filter((data: any) => {
        return data?.CategoryGroup === groupN;
      });
      return filterData;
    }
    var newFilterObject = totalUniqCatG?.map((item: any) => {
      return {
        CategoryGroup: item,
        category: pushNewArray(item),
      };
    });
    var finalNewData = newFilterObject?.map((item: any) => {
      if (item?.category?.length > 1) {
        return {
          CategoryGroup: item?.CategoryGroup,
          category: item?.category,
        };
      } else {
        return {
          CategoryGroup: item?.CategoryGroup,
          category: item?.category[0]?.SubCategory,
        };
      }
    });
    return finalNewData;
  };

  const getTreeDropOptions = (newCatObj: any) => {
    var newData = newCatObj?.map((data: any) => {
      return {
        title: data?.CategoryGroup,
        value: `${data?.category[0]?.ParentCategoryId}`,
        key: `${data?.category[0]?.ParentCategoryId}`,
        parentVal: `${data?.category[0]?.ParentCategoryId}`,
        children: data?.category?.map((data: Category) => {
          return {
            title: data?.CategoryName,
            value: `${data?.CategoryId}`,
            key: `${data?.CategoryId}`,
            parentVal: `${data?.CategoryId}`,
            children:
              data?.SubCategory?.length === 0
                ? []
                : data?.SubCategory?.map((child: any) => {
                    return {
                      title: child?.CategoryName,
                      value: `${child?.CategoryId}`,
                      parentVal: `${child?.CategoryId}`,
                      key: `${child?.CategoryId}`,
                      childVal: `${child?.CategoryId}`,
                      children: child?.SubCategory?.length === 0 ? [] : 
                      child?.SubCategory?.map((childB: any) => {
                          return {
                            title: childB?.CategoryName,
                            value: `${childB?.CategoryId}`,
                            parentVal: `${childB?.CategoryId}`,
                            key: `${childB?.CategoryId}`,
                            childVal: `${childB?.CategoryId}`,
                          };
                        }),
                    };
                  }),
          };
        }),
      };
    });
    return newData;
  };

  useEffect(() => {
    setNewCatObj(getNewCatObject(categoriesOptions));
  }, [categoriesOptions]);

  useEffect(() => {
    if(newCatObj){
      var A:any = newCatObj, x= 0, y= 1;
      A[x] = A?.splice(y, 1, A[x])[0];
      if(A?.length > 1){
        setTreeData(getTreeDropOptions(newCatObj));
      }  
    }
  }, [newCatObj]);

  const onChangeTreeSelect = (value: any, label: any, extra: any) => {
    setTreeValue(value);
    var parentId = treeData?.map((item: any) => {
      return item?.value;
    });
    var reuduceGroupParentId = value?.filter((item: any) => {
      return !parentId?.includes(item);
    });
    setTreeParentvalue(reuduceGroupParentId);
    setStrinDetails({
      ...strinDetails,
      category: label?.toString(),
    });
  };

  const tProps = {
    treeData,
    value: treevalue,
    onChange: onChangeTreeSelect,
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_ALL,
    placeholder: "Category and Subjects",
    style: {
      width: "100%",
    },
  };

  const filterPresaleOption = (value: number) => {
    var data = filterOptions?.preSale.filter((item: any) => {
      var data = item?.value == value;
      return data;
    });
    if (data[0]?.text !== undefined) {
      return `${data[0]?.text}`;
    } else {
      return filterOptions.preSale[0].text;
    }
  };

  const filterPPUCiteriaOption = (value: number) => {
    var data = filterOptions?.PPUCriteria.filter((item: any) => {
      var data = item?.value === value;
      return data;
    });
    if (data[0]?.text !== undefined) {
      return `${data[0]?.text}`;
    } else {
      return filterOptions.ppuTitles[1].text;
    }
  };

  var cat =
    editableData?.CategorySearch !== undefined
      ? editableData?.CategorySearch
      : [];
  var subCat =
    editableData?.SubCategorySearch !== undefined
      ? editableData?.SubCategorySearch
      : [];
  const categoryAllIds =
    editableData?.length !== 0
      ? [...cat, ...subCat]?.map(function (e) {
          return e.toString();
        })
      : [];

  useEffect(() => {
    if (searchSaved == true) {
      if (saveSearchResults?.SearchSaveId !== undefined) {
        setShowLoading(true);
        dispatch(setSearchAfterEdit(saveSearchResults));
        dispatch(clearSaveSearchResult());
        dispatch(setSearchTab("3"));
        dispatch(setSearch(editedData))
      } else {
        dispatch(setSearchTab("1"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSaved]);

  useEffect(() => {
    setEditedData(searchDetails);
  }, [searchDetails, saveSearchResults?.searchParams]);

  const onSavesearch = () => {
    if (showError.length < 5) {
      setShowModal(true);     
    }
  };

  const handleEditsearch = (e: any) => {
    e.preventDefault();
    dispatch(saveSearch(editedData));
  };

  const handleEditsearchCancel = () => {
    setShowLoading(true);
    dispatch(setSearchAfterEdit(saveSearchResults));
    dispatch(setSearch([]))
    dispatch(clearSaveSearchResult());
    dispatch(setSearchTab("3"));
  };

  const dateConverter = (date: any) => {
    if (date !== "") return moment(date, "");
    else {
      return undefined;
    }
  };

  const convertStringInt = (data: any) => {
    let result = data.map((i: any) => Number(i));
    return result;
  };

  useEffect(() => {
    if (selectedFormats.includes("3")) {
      setNarratorVisible(true);
    } else {
      setNarratorVisible(false);
      var temp: any = "";
      setStrinDetails({ ...strinDetails, narrator: temp.toString() });
      setSearchDetails({ ...searchDetails, Narrator: temp });
      setNarratorValue("");
    }
  }, [selectedFormats]);

  const handlePoref = (e: any) => {
    var value = e.target.value;
    setCollectionObj({ ...collectionObj, POReference: value });
    setStrinDetails({ ...strinDetails, POReference: value });
  };

  useEffect(() => {
    if (strinDetails.persaleTitle === "" && !isCollection) {
      setStrinDetails({
        ...strinDetails,
        persaleTitle: filterOptions.preSale[0].text,
      });
    }
  }, [strinDetails]);
  
  useEffect(() => {
    setSearchDetails({
      ...searchDetails,
      FromPurchaseDate: customDates[0],
      ToPurchaseDate: customDates[1],
    });
  },[customDates])

  const handleCustomRange = (date: any, dateString: any) => {
    setCustomDates(dateString);
    setStrinDetails({
      ...strinDetails,
      customDateRange: dateString.toString(),
    });
  }

  const handleCustomDateRange = (value:number) => {
    if(value === -1) {
    setShowCustomDateRange(true)
    } else {
      setShowCustomDateRange(false)
    }
  }

  useEffect(()=>{
     if(clearForm){
       setTimeout(()=>{
        onClear()
      },1000)
     }
  },[clearForm,isCollection])

  // For showing datpicker if applicable
  useEffect(() => {
    let titleDaysValue =
      editableData?.TitleDaysOld !== undefined
        ? editableData?.FromPublisherDate !== ""
          ? -1
          : editableData?.TitleDaysOld !== 0
          ? parseInt(editableData?.TitleDaysOld)
            ? [parseInt(editableData?.TitleDaysOld)]
            : -1
          : undefined
        : undefined;

    let catalogDaysValue =
      editableData?.AddedToCatalogDaysOld !== undefined
        ? editableData?.AddedToCatalogDateFrom !== ""
          ? -1
          : parseInt(editableData?.AddedToCatalogDaysOld) !== 0
          ? parseInt(editableData?.AddedToCatalogDaysOld)
            ? [parseInt(editableData?.AddedToCatalogDaysOld)]
            : -1
          : undefined
        : undefined;
    if (titleDaysValue === -1) {
      setShowDateRange(true);
    }
    if (catalogDaysValue === -1) {
      setShowCloudDateRange(true);
    }
  }, [editableData?.TitleDaysOld]);

  useEffect(() => {
    if (editableData?.QuickSearch && editableData?.QuickSearch[0]?.length > 0) {
      setHaskeyWord(true);
    }else{
      setHaskeyWord(false);
    }
  }, [editableData?.QuickSearch]);
  return (
    <SpinnerLoader loading={searchFormState?.loading || formState?.loading}>
      <div className="scrollContent scrollStyle searchContainer">
        <Form onFinish={onSearch} >
          <Row className="form-container" gutter={[20, 20]}>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Card className="searchForm-card">
                    <p className="card-title">Search Details</p>
                    <Input
                      className="card-input"
                      name="keyword"
                      placeholder="Keywords"
                      onChange={handleKeyWord}
                      defaultValue={
                        editableData?.QuickSearch?.length !== 0 
                          ? editableData?.QuickSearch?.toString()
                          : ""
                      }
                    />
                    <Input
                          className="card-input"
                          placeholder="Title"
                          onChange={handleTitle}
                          disabled={haskeyWord}
                          defaultValue={editableData?.TitleSearch?editableData.TitleSearch:""}
                    />
                    <Input
                      name="AuthorSearch"
                      placeholder="Author(s)"
                      className="card-input"
                      disabled={haskeyWord}
                      bordered={false}
                      onChange={handleAuthorChange}
                      defaultValue={
                        editableData?.AuthorSearch?.length !== 0 
                          ? editableData?.AuthorSearch?.toString()
                          : ""
                      }
                    />
                    <Input
                      className="card-input"
                      name="series"
                      disabled={haskeyWord}
                      placeholder="Series"
                      onChange={handleInputChanges}
                      defaultValue={
                        editableData?.series !== null
                          ? editableData?.series
                          : ""
                      }
                    />
                     <Input
                      name="Narrator"
                      placeholder="Narrator"
                      className={`${
                        narratorVisible
                          ? "card-input"
                          : "card-input narator-input"
                      }`}
                      bordered={false}
                      value={narratorValue}
                      disabled={narratorVisible ? false : true}
                      onChange={handleNarratorChange}
                      defaultValue={
                        editableData?.Narrator !== ""
                          ? editableData?.Narrator?.toString()
                          : ""
                      }
                    />
                    { !isCollection ? 
                    <DropDown
                      className="card-dropdown"
                      placeholder="Presale Titles"
                      bordered={false}
                      optionValue={filterOptions.preSale}
                      icon={downIcon}
                      onChange={handlePreSale}
                      defaultValue={filterPresaleOption(
                        editableData?.PreSaleDays
                      )}
                    />
                    : null }
                    <FormItem
                      name="HoldRatio"
                      rules={[
                        {
                          pattern: /^([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                          message:
                            "hold ratio must be greater than or equal to 0 !",
                        },
                      ]}
                      initialValue={
                        editableData?.HoldRatio === 0
                          ? undefined
                          : editableData?.HoldRatio
                      }
                    >
                      <Input
                        className="card-input"
                        name="HoldRatio"
                        placeholder="Hold Ratio Equal or Above  (Ex. 1, 1.5, 2...)"
                        onChange={handleInputChanges}
                        defaultValue={
                          editableData?.HoldRatio !== undefined
                            ? editableData?.HoldRatio
                            : ""
                        }
                        disabled={searchDetails?.InCollection !== false?false:true} 
                      />
                    </FormItem>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card className="searchForm-card">
                    <p className="card-title">Providers</p>
                         <MultiSelect
                      className="card-dropdown with-search"
                      placeholder="Publishers"
                      onChange={handlePublisherChange}
                      defaultValue={
                        editableData?.PublisherSearch?.length !== 0
                          ? editableData?.PublisherSearch
                          : undefined
                      }
                      option={publisherOptions}
                      maxTagCount={3}
                      isContentProvider={true}
                      headingText={"Publishers"}
                    />
                   <MultiSelect
                      className="card-dropdown with-search"
                      placeholder="Content Providers"
                      onChange={handleProviderChange}
                      defaultValue={
                        editableData?.ContentProviderSearch?.length !== 0
                          ? editableData?.ContentProviderSearch
                          : undefined
                      }
                      option={providerOptions}
                      maxTagCount={3}
                      isContentProvider={true}
                      headingText={"Content Providers"}
                    />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card className="searchForm-card">
                    <p className="card-title">Formats</p>
                    {formatOptions?.map((data: any) => {
                      return data?.ItemFormat?.map((itemFormat: ItemFormat) => {
                        return (
                          <Checkbox
                            className="cards-checkbox"
                            name={`${itemFormat?.ItemFormatId}`}
                            key={itemFormat?.ItemFormatId}
                            defaultChecked={
                              editableData?.ItemFormatIdSet !== undefined
                                ? convertStringInt(
                                    editableData?.ItemFormatIdSet
                                  ).includes(itemFormat?.ItemFormatId)
                                : true
                            }
                            onChange={handleFormatChange}
                          >
                            {" "}
                            {itemFormat.ItemFormatName}
                          </Checkbox>
                        );
                      });
                    })}
                  </Card>
                </Col>
              </Row>
              {/* <Row>
                <Col span={24}>
                  <Card className="searchForm-card">
                    <p className="card-title">Lending Model</p>
                    <Row>
                      <Col sm={24} md={24} lg={12} xl={12}>
                        <Checkbox className="cards-checkbox">
                          {" "}
                          Perpetual
                        </Checkbox>
                        <Checkbox className="cards-checkbox">
                          {" "}
                          Time Expiration
                        </Checkbox>
                        <Checkbox className="cards-checkbox">
                          {" "}
                          Simultaneous Access
                        </Checkbox>
                      </Col>
                      <Col sm={24} md={24} lg={12} xl={12}>
                        <Checkbox className="cards-checkbox">
                          {" "}
                          Checkout Expiration
                        </Checkbox>

                        <Checkbox className="cards-checkbox">
                          {" "}
                          Time & Checkout Expiration
                        </Checkbox>

                        <Checkbox className="cards-checkbox"> Free</Checkbox>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row> */}
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <Card className="searchForm-card">
                    <p className="card-title">Category and Subject</p>
                    <TreeSelect
                      showSearch
                      notFoundContent={<Empty description="No results found."/>}
                      maxTagCount={2}
                      showArrow={true}
                      suffixIcon={<img src={searchIcon} alt =""/>}
                      defaultValue={categoryAllIds?.length !== 0 ? categoryAllIds : undefined} 
                      className="treeSelectDropdown with-search"
                      {...tProps}
                      allowClear
                      multiple
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterTreeNode={(search: any, item: any) => {
                        return (
                          item.title
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) >= 0
                        );
                      }}
                    />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card className="searchForm-card">
                    <p className="card-title">Audience</p>
                    <DropDown
                      placeholder="All Audiences"
                      showSearch={true}
                      className="card-dropdown with-search"
                      bordered={false}
                      optionValue={audienceDataOptions}
                      icon={searchIcon}
                      mode="multiple"
                      onChange={handleAudienceChange}
                      defaultValue={
                        editableData?.TargetAudienceIds?.length !== 0 
                          ? editableData?.TargetAudienceIds
                          : undefined
                      }
                    />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card className="searchForm-card">
                    <p className="card-title">Pricing</p>
                    <Row>
                      <Col
                        sm={12}
                        md={12}
                        lg={7}
                        xl={7}
                        className="pricing-col"
                      >
                        <FormItem
                          name="MinimumPriceAmount"
                          rules={[
                            {
                              pattern: /^([0-9]*\.?[0-9]{1,2})$/,
                              message: "Please enter a valid amount!",
                            },
                          ]}
                          initialValue={
                            editableData?.MinimumPriceAmount !== undefined
                              ? editableData?.MinimumPriceAmount
                              : ""
                          }
                        >
                          <Input
                            className="card-input"
                            name="MinimumPriceAmount"
                            placeholder="Min"                         
                            onChange={handleInputChanges}
                          />
                        </FormItem>
                      </Col>
                      <Col
                        sm={12}
                        md={12}
                        lg={7}
                        xl={7}
                        className="pricing-col"
                      >
                        <FormItem
                          name="MaximumPriceAmount"
                          className={showError.length > 2 ? "errorShow" : ""}
                          rules={[
                            {
                              pattern: /^([0-9]*\.?[0-9]{1,2})$/,
                              message: "Please enter a valid amount!",
                            },
                            {
                              pattern: /[1-9]/,
                              message: "Max value cannot be 0",
                            },
                          ]}
                          initialValue={
                            editableData?.MaximumPriceAmount !== undefined
                              ? parseFloat(editableData?.MaximumPriceAmount) <
                                parseFloat(editableData?.MinimumPriceAmount)
                                ? ""
                                : editableData?.MaximumPriceAmount !== "0"
                                ? editableData?.MaximumPriceAmount
                                : ""
                              : ""
                          }
                        >
                          <Input
                            className="card-input"
                            name="MaximumPriceAmount"
                            placeholder="Max"
                            onChange={handleInputChanges}
                          />
                        </FormItem>
                        {showError !== "" ? (
                          <p className="showError">{showError}</p>
                        ) : null}
                      </Col>
                      <Col
                        sm={24}
                        md={24}
                        lg={10}
                        xl={10}
                        className="pricing-col"
                      >
                        {/* <Checkbox className="cards-checkbox">
                          Discounted Items Only
                        </Checkbox> */}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card className="searchForm-card">
                    <p className="card-title">Dates and Languages</p>
                    <div className="searchByDatePurchase">
                      {isCollection ?
                    <DropDown
                        className="card-dropdown"
                        placeholder="Date Purchased"
                        bordered={false}
                        optionValue={filterOptions.datePurchased}
                        icon={downIcon}
                        onChange={handleCustomDateRange}
                        defaultValue={
                          editableData?.FromPurchaseDate !== undefined
                          ? editableData?.FromPurchaseDate !== ""
                          ? -1
                            : undefined
                            : undefined
                        }
                      />
                        : null}
                      {showCustomDateRange ?
                        (
                          <RangePicker
                            placeholder={["Custom Date Range", ""]}
                            format={"MM/DD/YYYY"}
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                            value={hackValue}
                            onChange={handleCustomRange}
                            defaultValue={
                              editableData?.FromPurchaseDate && editableData?.ToPurchaseDate?
                                  [moment(
                                          dateConverter(editableData?.FromPurchaseDate)
                                        ),
                                    moment(
                                      dateConverter(editableData?.ToPurchaseDate)
                                          )
                                  ]
                                : undefined
                            }
                            className="card-datepicker"
                            suffixIcon={<img src={downIcon} alt="" />}
                            separator={<img src="" alt="" />}
                          />)
                      : null}
                    </div>

                    <div className="searchbyDateSelect">
                      <DropDown
                        className="card-dropdown"
                        placeholder="Published Within"
                        bordered={false}
                        optionValue={filterOptions.dateDuration}
                        icon={downIcon}
                        onChange={handleDateRangeSelect}
                        TitleDaysOld
                        defaultValue={
                          editableData?.TitleDaysOld !== undefined
                            ? editableData?.FromPublisherDate !== ""
                              ? -1
                              : editableData?.TitleDaysOld !== 0
                              ? parseInt(editableData?.TitleDaysOld)
                                ? [parseInt(editableData?.TitleDaysOld)]
                                : -1
                              : undefined
                            : undefined
                        }
                      />
                      {showDateRange ? (
                        <RangePicker
                          placeholder={["Custom Date Range", ""]}
                          format={"MM/DD/YYYY"}
                          getPopupContainer={(trigger: any) =>
                            trigger.parentNode
                          }
                          value={hackValue || value}
                          onChange={handleRangeSelect}
                          defaultValue={
                            editableData?.FromPublisherDate !== undefined
                              ? editableData?.FromPublisherDate !== ""
                                ? [
                                    moment(
                                      dateConverter(
                                        editableData?.FromPublisherDate
                                      )
                                    ),
                                    moment(
                                      dateConverter(
                                        editableData?.ToPublisherDate
                                      )
                                    ),
                                  ]
                                : undefined
                              : undefined
                          }
                          className="card-datepicker"
                          suffixIcon={<img src={downIcon} alt="" />}
                          separator={<img src="" alt="" />}
                        />
                      ) : null}
                    </div>
                    <div className="searchbyDateSelect">
                      {}
                      <DropDown
                        className="card-dropdown"
                        placeholder="Date Added to cloudLibrary"
                        bordered={false}
                        optionValue={filterOptions.CloudDateDuration}
                        icon={downIcon}
                        onChange={handleCloudDateRangeSelect}
                        defaultValue={
                          editableData?.AddedToCatalogDaysOld !== undefined
                            ? editableData?.AddedToCatalogDateFrom !== ""
                              ? -1
                              : parseInt(
                                  editableData?.AddedToCatalogDaysOld
                                ) !== 0
                              ? parseInt(editableData?.AddedToCatalogDaysOld)
                                ? [
                                    parseInt(
                                      editableData?.AddedToCatalogDaysOld
                                    ),
                                  ]
                                : -1
                              : undefined
                            : undefined
                        }
                      />
                      {showCloudDateRange ? (
                        <>
                          <RangePicker
                            placeholder={["Custom cloudLibrary Date Range", ""]}
                            format={"MM/DD/YYYY"}
                            getPopupContainer={(trigger: any) =>
                              trigger.parentNode
                            }
                            value={cloudHackValue || cloudValue}
                            onChange={handleDateSelect}
                            defaultValue={
                              editableData?.AddedToCatalogDateFrom && editableData?.AddedToCatalogDateTo
                                  ? [
                                      moment(
                                        dateConverter(
                                          editableData?.AddedToCatalogDateFrom
                                        )
                                      ),
                                      moment(
                                        dateConverter(
                                          editableData?.AddedToCatalogDateTo
                                        )
                                      ),
                                    ]
                                  : undefined
                            }
                            className="card-datepicker"
                            suffixIcon={<img src={downIcon} alt="" />}
                            separator={<img src="" alt="" />}
                          />
                        </>
                      ) : null}
                    </div>
                    <MultiSelect
                      className="card-dropdown with-search"
                      placeholder="Language(s)"
                      onChange={handleLanguageChange}
                      defaultValue={
                        editableData?.Language
                          ? editableData?.Language
                          : undefined
                      }
                      option={languageOptions}
                      maxTagCount={3}
                      maxSelection={10}
                      id="languages"
                    />
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card className="searchForm-card">
                    <p className="card-title">Filters</p>
                    <Row>
                      {isCollection ? (
                        <Col sm={24} md={24} lg={24} xl={24}>
                          {
                            showPpuField?.Ebook || showPpuField?.Abook?
                            <DropDown
                            className="card-dropdown"
                            placeholder="Exclude PPU Titles"
                            bordered={false}
                            optionValue={filterOptions.PPUCriteria}
                            icon={downIcon}
                            onChange={handleExPpuTitle}
                            defaultValue={filterPPUCiteriaOption(
                              editableData?.PPUCriteria
                            )}
                          />:null
                          }
                          <Input
                            className="card-input"
                            name="poReferenceNumber"
                            placeholder="PO Reference Number"
                            onChange={handlePoref}
                            defaultValue={
                              collectionObj?.POReference !== null
                                ? collectionObj?.POReference
                                : ""
                            }
                          />
                        </Col>
                      ) : (
                        <>
                        <Col sm={24} md={24} lg={24} xl={24}>
                        <DropDown
                            className="card-dropdown"
                            placeholder="Exclude PPU Titles"
                            bordered={false}
                            optionValue={filterOptions.PPUCriteria}
                            icon={downIcon}
                            onChange={handleExPpuTitle}
                            defaultValue={filterPPUCiteriaOption(
                              collectionObj?.PPUCriteria
                            )}
                          />
                        </Col>
                          <Col sm={24} md={24} lg={12} xl={12}>
                            <Checkbox
                              className="cards-checkbox"
                              name="ExcludeSelfPublished"
                              onChange={handleFilterChange}
                              defaultChecked={
                                editableData?.ExcludeSelfPublished !== undefined
                                  ? (editableData?.ExcludeSelfPublished).toString().toLowerCase() ==
                                    "True".toLowerCase()
                                    ? true
                                    : false
                                  : false
                              }
                            >
                              {" "}
                              Exclude self-published
                            </Checkbox>
                          </Col>
                          <Col sm={24} md={24} lg={12} xl={12}>
                            <Checkbox
                              className="cards-checkbox"
                              name="InCollection"
                              onChange={handleFilterChange}
                              disabled={searchDetails?.HoldRatio?true:false}
                              defaultChecked={
                                editableData?.InCollection !== null || editableData?.InCollection !==  undefined
                                  ? (editableData?.InCollection)?.toString()?.toLowerCase() ===
                                    "False"?.toLowerCase()
                                    ? true
                                    : false
                                  : false
                              }
                            >
                              {" "}
                              Exclude owned or ordered
                            </Checkbox>
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {saveSearchResults?.SearchSaveId !== undefined ? (
            <>
              <Row justify="end" className="search-footer">
                <Col span={6} className="action-button">
                  <Button type="primary " onClick={handleEditsearchCancel}>
                    Cancel
                  </Button>
                </Col>
                <Col span={6} className="action-button">
                  <Button type="primary " onClick={handleEditsearch}>
                    Save Search
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row justify="end" className="search-footer" style={{display:"none"}}>
                <Col span={5} className="clear-button" order={2}>
                  <input
                    type="button"
                    className="primary"
                    value="Clear"
                    id="advanced-clear-btn"
                    onClick={onClear}
                  />
                </Col>
                <Col span={5} className="action-button primarySearch" order={3}>
                  <Button type="primary" id="advanced-search-btn">
                    Search
                  </Button>
                </Col>
                {isCollection ? null : (
                  <Col span={4} className="action-button linkButton" order={1}>                 
                      <input
                    type="button"
                    className="Search_Save_btn"
                    value="Save Search"
                    id="advanced-save-search-btn"
                    onClick={onSavesearch}
                  />
                  </Col>
                )}
              </Row>
              <SaveSearchModal
                isModalVisible={showModal}
                setModalVisible={setShowModal}
                handleChange={handleInputChanges}
                searchDetails={searchDetails}             
                showNotification={showNotification}
                setKey={setKey}
              />
            </>
          )}
        </Form>
      </div>
    </SpinnerLoader>
  );
});
