/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  Menu,
} from "antd";
import Button from "../../stateless/common/button";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import {
  getPurchase,
  getPurchaseComplete,
  getPurchasePending,
} from "../../../../redux/actions/purchases/purchasesActions";
import DropDown from "../../stateless/common/dropDown";
import {
  downIcon,
  dropdownIcon,
  editIcon,
  list,
  asteriskIcon,
} from "../../../images";
import { CSVLink } from "react-csv";
import ExportIcon from "../../../../assets/dummy/ExportIcon.png";
import { PurchaseStateSelector } from "../../../../redux/reducers/purchases/purchases";
export const PurchaseHeader = (props: any) => {
  const {
    totalCount,
    preSale,
    libId,
    currentTab,
    dateOption,
    buyerOption,
    serachHeader,
    setSearchSideFilter,
    searchSideFilter,
    handlePageSize,
    allSelected,
    pageSizeVal,
    startCount,
    data,
    lastCount,
  } = props;
  const dispatch = useDispatch();
  const { appUser } = useSelector(userStateSelector);
  const { purchasesFilter } = useSelector(PurchaseStateSelector);
  const optionList = [10, 20, 50, 100, 250, 500];
  const [pageSize, setPageSize] = useState<number>(pageSizeVal);
  const [exportData, setExportData] = useState<any>([]);
  var dateFormat = appUser?.libraryData ? appUser.libraryData[0]?.DateFormat.toUpperCase() : ""
  const [searchHFilter, setSearchHFilter] = useState<any>({
    lib_Id: `${libId}`,
    ExternalRef_id: "",
    buyer_id: "",
    days_Old: "30",
    end_Date: "",
    status:2,
    record_Count: "100",
    record_Start: "1",
    sort_Direction: "DESC",
    sort_Field: "PurchaseOrderDate",
    start_Date: "",
  });

  useEffect(() => {
    setPageSize(pageSizeVal);
  }, [pageSizeVal]);

  const headers = [
    { label: "Title", key: "refineTitle" },
    { label: "Authors", key: "refineAuther" },
    { label: "PublicationDate", key: "PublicationDate" },
    { label: "Street Date", key: "StreetDate" },
    { label: "Eisbn", key: "EISBN" },
    { label: "Price", key: "RetailPrice" },
    { label: "Buyer", key: "BuyerId" },
    { label: "PO Reference", key: "ExternalRefId" },
    { label: "Quantity", key: "Quantity" },
  ];
  const csvExportList = {
    filename: `pre-saleList.csv`,
    headers: headers,
    data: exportData,
  };
  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr)
      .tz("Etc/GMT")
      .format(dateFormat);
    return currentDate;
  };

  useEffect(() => {
    if(data !== undefined){
     const tableValue = (data?.map((item: any) => {
        var Title = item?.Title
        const refineTitle = Title?.replaceAll('"', '');
        var Auther = item?.Authors
        const refineAuther = Auther?.replaceAll('"', '');
        const StreetDate = item?.StreetDate === undefined || null ? "": dateModify(item?.StreetDate)
        const PublicationDate = item?.PublicationDate === undefined || null ? "": dateModify(item?.PublicationDate)
      return {
        
        ...item ,refineTitle:refineTitle && refineTitle,refineAuther:refineAuther && refineAuther,StreetDate:StreetDate,PublicationDate:PublicationDate
      }
    }))
    setExportData(tableValue)
    }
  }, [data])

  const pageOptions = (
    <Menu className="header-dropdown">
      <Menu.Item key="page-size">
        <Row>
          <Col style={{ width: "20px" }}>
            <img style={{ width: "14px", height: "14px" }} src={list} alt=" " />
          </Col>
          <Col flex="auto" style={{ paddingLeft: "5px" }}>
            Page Size
          </Col>
        </Row>
      </Menu.Item>
      {optionList.map((data: number, index: number) => {
        return (
          <Menu.Item key={index} onClick={() => handlePageSize(data)}>
            <Row>
              <Col style={{ width: "20px" }}>
                {pageSize === data ? (
                  <img
                    style={{ width: "14px", height: "14px" }}
                    src={asteriskIcon}
                    alt="*"
                  />
                ) : (
                  " "
                )}
              </Col>
              <Col style={{ paddingLeft: "5px" }}>{data}</Col>
            </Row>
          </Menu.Item>
        );
      })}
      {preSale !== false ? (
        <Menu.Item>
          <Row>
            <Col style={{ width: "20px" }}>
              <img style={{ width: "14px", height: "14px" }} src={ExportIcon} alt=" " />
            </Col>
            <Col>
              <div className="export-list-padding">
                <CSVLink className="export-list-link" {...csvExportList}>
                  Export List
                </CSVLink>
              </div>
            </Col>
          </Row>
        </Menu.Item>)
        : null}
    </Menu>
  );

  const handleDateFilter = (e: any) => {
    setSearchHFilter({ ...searchHFilter, days_Old: JSON.stringify(e) });
    setSearchSideFilter({ ...searchSideFilter, days_Old: JSON.stringify(e) });
    if (currentTab === "1") {
      dispatch(
        getPurchase({
          ...searchHFilter,
          lib_Id: `${libId}`,
          days_Old: JSON.stringify(e),
          buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
          status:0,
          end_Date: "",
          start_Date: "",
          ExternalRef_id: "",
          record_Count: pageSizeVal,
        })
      );
    } else if (currentTab === "2") {
      dispatch(
        getPurchaseComplete({
          ...searchHFilter,
          lib_Id: `${libId}`,
          days_Old: JSON.stringify(e),
          buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
          status:2,
          end_Date: "",
          start_Date: "",
          ExternalRef_id: "",
          record_Count: pageSizeVal,
        })
      );
    } else {
      dispatch(
        getPurchasePending({
          ...searchHFilter,
          lib_Id: `${libId}`,
          days_Old: JSON.stringify(e),
          buyer_id: searchSideFilter?.buyer_id !== "Buyers" ? searchSideFilter?.buyer_id : "",
          status:1,
          end_Date: "",
          start_Date: "",
          ExternalRef_id: "",
          record_Count: pageSizeVal,
        })
      );
    }
  };

  const handleBuyerFilter = (e: any) => {
    setSearchSideFilter({
      ...searchSideFilter,
      buyer_id: e !== "Buyers" ? e : "",
    });
    setSearchHFilter({ ...searchHFilter, buyer_id: e !== "Buyers" ? e : "" });
    if (currentTab === "1") {
      dispatch(
        getPurchase({
          ...searchHFilter,
          lib_Id: `${libId}`,
          buyer_id: e !== "Buyers" ? e : "",
          days_Old: searchSideFilter?.days_Old,
          status:0,
          end_Date: "",
          start_Date: "",
          ExternalRef_id: "",
          record_Count: pageSizeVal,
        })
      );
    } else if (currentTab === "2") {
      dispatch(
        getPurchaseComplete({
          ...searchHFilter,
          lib_Id: `${libId}`,
          buyer_id: e !== "Buyers" ? e : "",
          days_Old: searchSideFilter?.days_Old,
          status:2,
          end_Date: "",
          start_Date: "",
          ExternalRef_id: "",
          record_Count: pageSizeVal,
        })
      );
    } else {
      dispatch(
        getPurchasePending({
          ...searchHFilter,
          lib_Id: `${libId}`,
          buyer_id: e !== "Buyers" ? e : "",
          days_Old: searchSideFilter?.days_Old,
          status:1,
          end_Date: "",
          start_Date: "",
          ExternalRef_id: "",
          record_Count: pageSizeVal,
        })
      );
    }
  };
  return (
    <>
      <Row justify="space-between" className="pb-16" gutter={[20, 20]}>
        <Col
          xs={24}
          sm={24}
          md={preSale !== false ? 12 : 12}
          lg={preSale !== false ? 12 : 12}
          xl={preSale !== false ? 12 : 12}
        >
          <Row>
            {preSale === false ? (
              <>
                <Col  md={12} lg={9} xl={7} className="count">
                  <p className="m-0">{startCount > lastCount ? "0" : startCount}-{lastCount} of {totalCount} Purchases</p>
                </Col>
                <Col  md={6} lg={7} xl={7} className="filters-contatiner">
                  <DropDown
                    bordered={false}
                    className="filters"
                    defaultValue={(purchasesFilter.days_Old !== undefined) ? purchasesFilter.daysOld : "30"}
                    value={parseInt(searchSideFilter?.days_Old)}
                    optionValue={dateOption}
                    icon={dropdownIcon}
                    onChange={handleDateFilter}
                  />
                </Col>
                <Col md={6} lg={7} xl={7} className="filters-contatiner">
                  <DropDown
                    bordered={false}
                    defaultValue={(purchasesFilter.buyer !== undefined) ? purchasesFilter.buyer : ""}
                    value={
                      searchSideFilter?.buyer_id !== ""
                        ? searchSideFilter?.buyer_id
                        : "Buyers"
                    }
                    className="filters"
                    optionValue={buyerOption}
                    icon={dropdownIcon}
                    onChange={handleBuyerFilter}
                  />
                </Col>
              </>
            ) : (
              <Col span={24} className="count">
                <p className="searchTitleStyle m-0">
                  {startCount > lastCount ? "0" : startCount}-{lastCount} of {totalCount} Titles
                </p>
              </Col>
            )}
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={preSale !== false ? 12 : 12}
          lg={preSale !== false ? 12 : 12}
          xl={preSale !== false ? 12 : 12}
        >

          <div className="editor-Button purchase-Editor">
            <div className="exportColListCsv">
              {preSale !== false ? null
                :
                <Button
                  onClick={() => serachHeader(true)}
                  type="serPurBtn secondary"
                >
                  Search Purchases
                </Button>
              }
            </div>
            <div className="exportColListCsv">
              <Dropdown
                overlay={pageOptions}
                trigger={["click"]}
                disabled={allSelected}
              >
                <button className="button-type editor">
                  <img src={editIcon} className="edit-icon" alt="" /> Editor
                  &nbsp; &nbsp;
                  <img src={downIcon} className="down-icon" alt="" />
                </button>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
