import React from "react";
import { Row, Modal } from "antd";
import Button from "../../../stateless/common/button";
import { useSelector } from "react-redux";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { PurchaseStateSelector } from "../../../../../redux/reducers/purchases/purchases";

const DeActivateUserModal = (props: any) => {
  const { showModal, setShowModal, setConfirmed } = props;
  const { preSaleFormState } = useSelector(PurchaseStateSelector);

  const handleYesSubmit = () => {
    setConfirmed(true);
  };

  const onCancelModel = () => {
    setShowModal(false);
  };

  const handleCancelSubmit = () => {
    setShowModal(false);
  };

  return (
    <Modal
      wrapClassName="deactivate-user-modal"
      visible={showModal}
      onCancel={onCancelModel}
      footer={
        <Row justify="space-around">
          <Button type="primary confirm-button" onClick={handleYesSubmit}>
            OK
          </Button>
          <Button type="smallRed confirm-button" onClick={handleCancelSubmit}>
            Cancel
          </Button>
        </Row>
      }
    >
      <SpinnerLoader loading={preSaleFormState.loading}>
        <div className="remove-content">
          <p className="purchaseNote">
            <ul>
              <li>
                <div className="no-wrap">
                  Please ensure all pre-sale orders submitted by this user are
                  deleted and ordered by another user.
                </div>
              </li>
              <li>
                <div>
                  Ensure this user is not designated as the buyer for any
                  Auto-Carts.
                </div>
              </li>
              <li>
                <div>
                  Ensure this user is not designated as the buyer in your PPU
                  Settings.
                </div>
              </li>
            </ul>
          </p>
        </div>
      </SpinnerLoader>
    </Modal>
  );
};

export default DeActivateUserModal;
