import { createAsyncThunk } from "@reduxjs/toolkit";
import { ExpirationService } from "../../../web/services/expiration/expirationService";

const ExpSrv = new ExpirationService();

export const expirationRules = createAsyncThunk(
    "api/fetchExpirationRules",
    async ( thunkAPI) => {
      try {
        const data = await ExpSrv.fetchExpirationRules()
        if (data && data.length) {
          return data;
        } else {
        }
      } catch (e: any) {
      }
    }
  );