/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Dropdown, Menu, Tooltip, message } from "antd";
import { cart, downIcon } from "../../../../images";
import "./appHeader.less";
import {
  userStateSelector,
  clearUser,
} from "../../../../../redux/reducers/user/userReducer";
import { useSelector, useDispatch } from "react-redux";
import { concatNames } from "../../../../../utility/appUtil";
import { logOut } from "../../../../../redux/actions/user/authAction";
import { SearchBar } from "./searchBar";
import { CartStateSelector, clearCreated, setTab, clearColumns, clearResizeColumns, clearselectedCols, clearCartFilters,clearCartDetailFilter, clearSesionRemain, clearSessionChk } from "../../../../../redux/reducers/carts/cartsReducer";
import Cart from "../../../../models/cart/cart";
import { activeCart, fetchBuyerCart, fetchCarts } from "../../../../../redux/actions/carts/cartAction";
import { clearContentCornerAll, clearGroups, clearNewsLetterState } from "../../../../../redux/reducers/groups/groupListReducer";
import { NewCartModal } from "../../../containers/carts/newCartModal";
import { AppRoutes } from "../../../../router/appRoutes";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Messages } from "../../../../constants/messages";
import { getNewAppData } from "../../../../../redux/actions/app/appActions";
import { clearFilters } from "../../../../../redux/reducers/books/bookListReducer";
import { clearSearchFilter, clearSavedSearchFilter, SearchstateSelector } from "../../../../../redux/reducers/search/searchReducer";
// import { getConfigJson } from "../../../../../environment/envUtility";
import { clearResizePurchaseCols,clearResizeCompletedCols,clearResizePendingCols,clearResizeDetailsCols, clearPurchaseFilter } from "../../../../../redux/reducers/purchases/purchases";
import { clearState, PurchaseStateSelector } from "../../../../../redux/reducers/purchases/purchases";
import { clearShelfColomns, clearShelvListState, ShelfstateSelector } from "../../../../../redux/reducers/shelves/shelfReducer"
import {
  fetchMediaTypesError,
} from "../../../../../redux/actions/search/searchActions";
import {
  groupstateSelector
} from "../../../../../redux/reducers/groups/groupListReducer";
import { fetchShelves } from "../../../../../redux/actions/shelves/shelfAction";
import { clearReportToken } from "../../../../../redux/reducers/reports/reportsReducer";
import { clearRetainedPageSize } from "../../../../../redux/reducers/app/sideBarReducer";


export const AppHeader = (props: any) => {
  const history = useHistory();
  const { appUser } = useSelector(userStateSelector);
  const { carts,activeCartArray, addedItem, cartCreated, createCartFormstate,cartUpdated,buyerCart,authDenied,formState} = useSelector(CartStateSelector)
  const {mediaTypesFormState} = useSelector(SearchstateSelector)
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false)
  const [clearText, setClearText] = useState(false)
  const [hasValue, setHasValue] = useState(false)
  const [searchSpan, setSearchSpan] = useState<number>(6)
  var libID =
  appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  var currency = appUser &&  appUser.libraryData? appUser.libraryData[0]?.CurrencySymbol : ""
  var permissions =
  appUser && appUser.libraryData ? appUser.libraryData[0].FeaturePermissions : "";
  const location = useLocation<any>();
  const { purchaseOrderData } = useSelector(PurchaseStateSelector);
  const {shelves} = useSelector(ShelfstateSelector)
  const {
    groups,
  } = useSelector(groupstateSelector);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCart, setSelectedCart] = useState<Cart>({
    CartName: "",
    TotalCartAmount: 0,
    NumberOfTitles: 0,
    TotalCartPrepubAmount: 0,
  })


  const signOut = () => {
    dispatch(logOut(appUser?.userId));
    dispatch(clearUser());
    dispatch(clearSesionRemain())
    dispatch(clearGroups());
    dispatch(getNewAppData())
    dispatch(clearFilters())
    dispatch(clearSearchFilter())
    dispatch(clearCartDetailFilter());
    dispatch(clearSavedSearchFilter())
    dispatch(clearColumns())
    dispatch(clearResizeColumns())
    dispatch(clearselectedCols())
    dispatch(clearResizePurchaseCols())
    dispatch(clearResizeCompletedCols())
    dispatch(clearResizePendingCols())
    dispatch(clearResizeDetailsCols())
    dispatch(clearCartFilters())
    dispatch(clearShelfColomns())
    dispatch(clearReportToken())
    dispatch(clearPurchaseFilter());
    dispatch(clearSessionChk())
    dispatch(clearShelvListState())
    dispatch(clearContentCornerAll())
    dispatch(clearNewsLetterState())
    dispatch(clearRetainedPageSize());
  };

  useEffect(()=>{
      if(authDenied){
        setTimeout(()=>{
          signOut()
        },1000)
      }
  },[authDenied])


  useEffect(()=>{
    var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
    if(buyerCart === null || buyerCart.length === 0 ){
      var payload:any = {
        userId:lib_Id,
        recordStart:1,
        recordCount:1
      }
      if(appUser?.isLibraryBuyer){
        dispatch(fetchBuyerCart(payload))
      }
      // getConfigJson()
    }
  },[])

  useEffect(() => {
    addedItem && dispatch(fetchCarts({
      libId:libID,
      appuser:appUser?.email
    })) 
  }, [addedItem])
  
  useEffect(()=>{
    if(carts && carts.length){
      setSelectedCart(carts[0])
    }
  },[carts])

  useEffect(()=>{
    if(activeCartArray?.CartId !== undefined){
      const activeCartRow = carts?.filter((item:any)=>{
        return item?.CartId === activeCartArray?.CartId
      })
      if(activeCartRow.length !== 0){
      dispatch(activeCart(activeCartRow[0])) 
      }
      else{
        const activeCartRow = carts?.filter((item:any)=>{
          return item?.IsDefault === true
      })
      dispatch(activeCart(activeCartRow[0])) 
      }
    }else{
      const activeCartRow = carts?.filter((item:any)=>{
        return (item?.IsDefault === true && item.CartOwner === appUser?.email )
     })
     dispatch(activeCart(activeCartRow[0])) 
    }
  
    },[carts])

  useEffect(()=>{
    if(createCartFormstate?.isError){
      dispatch(clearCreated())
    }
    else if(cartCreated){
      var lib_Id =
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
      dispatch(fetchCarts({
        libId:lib_Id,
        appuser:appUser?.email
      }))
      message.success({
        content: Messages.CART_CREATED,
        key: "notificationMessage",
      })
      dispatch(clearCreated())
    }else if(cartUpdated){
      // eslint-disable-next-line @typescript-eslint/no-redeclare
      var lib_Id =
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
      dispatch(fetchCarts({
        libId:lib_Id,
        appuser:appUser?.email
      }))
      message.success({
        content: "Cart updated successfully.",
        key: "notificationMessage",
      })
      dispatch(clearCreated())
    }
  },[createCartFormstate])
  
  const onCartSelect = (data: any) =>{
    setSelectedCart(data)
    dispatch(activeCart(data)) 
  }

  useEffect(() => {
    if((location.pathname !== "/libraryManagement/purchases") && (location.pathname !== "/libraryManagement/purchaseDetails") && (purchaseOrderData?.length !== 0) ) {
      dispatch(clearState())
      }
      if(!authDenied && !formState.loading){
        if(groups && groups.length && (location.pathname === "/cloudLibrary/landing")){
          dispatch(fetchMediaTypesError());
        }
      }
     
  }, [location,authDenied])

  useEffect(() => {
    if(mediaTypesFormState?.errorMessage) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      signOut()
    }
  }, [mediaTypesFormState])
  

  const DropdownTitle = () => {
    return (
      <div style={{ display: "flex" }}>
        <div className="cart-dropdown">
          <Tooltip title={activeCartArray?.CartName} placement="bottom">
            <p className="dropDown-title">{activeCartArray?.CartName}</p>
            <p className="dropDown-title-content">{currency}{(activeCartArray?.TotalCartAmount?.toFixed(2))?.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}  &#8226;  {activeCartArray?.NumberOfTitles} Titles</p>
          </Tooltip>
        </div>
        <img src={downIcon} className="downIcon" alt="down" />
      </div>
    );
  };


  // this function will be called on click of cart icon
  const handleCartClick = () => {
    let state = {
      cart: activeCartArray
    }
    history.push(AppRoutes.CARTDETAILS, state)

  }

  const newCartModel = () => {
    if(shelves.length < 1){
      dispatch(fetchShelves(libID))
    }
    setShowModal(true)
  }

const userCarts = (
  <Menu className="header-dropdown">
    { 
      permissions.includes("AudioBooks") ?
      carts.filter((data: Cart)=>{
        return data.CartOwner === appUser?.email || data.IsCommunityCart === true
      })
      .map((data: Cart, index: number)=>{
        return (  
        <Menu.Item key={index} onClick={()=>onCartSelect(data)}>
          <p className="dropDown-title">{data.CartName}</p>
          <span className="dropDown-content">{currency}{(data.TotalCartAmount.toFixed(2))?.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')} &#8226; {data.NumberOfTitles} Titles</span>
        </Menu.Item>
      )
    })
    :
    carts.filter((data: Cart)=>{
      return (data.CartOwner === appUser?.email || data.IsCommunityCart === true)
      && !data.CartTypeCode?.includes("Audio")
    })
    .map((data: Cart, index: number)=>{
      return (  
      <Menu.Item key={index} onClick={()=>onCartSelect(data)}>
        <p className="dropDown-title">{data.CartName}</p>
        <span className="dropDown-content">{currency}{data.TotalCartAmount.toFixed(2)} &#8226; {data.NumberOfTitles} Titles</span>
      </Menu.Item>
    )
  })
  }
  <Menu.Divider />
      <Menu.Item key="All-Carts-Link" className="dropDown-link" >
      <Link to={AppRoutes.CART} onClick={()=>dispatch(setTab("2"))}>All Carts</Link>
      </Menu.Item>
    <Menu.Divider />
      <Menu.Item key="New-cart-Link" className="dropDown-link" onClick={newCartModel}>
        <p>New Cart</p>
      </Menu.Item>
    </Menu>
)
  const UserMenuItems = (
    <Menu className="header-dropdown">
      <Menu.Item key="3" className="sign-out" onClick={signOut}>
        Sign Out
      </Menu.Item>
    </Menu>
  );

  const serchSpanComp =(number:number)=>{
    setSearchSpan(number)
  }

  useEffect(()=>{
   if(clearText){
    setSearchSpan(6)
    setClearText(false)
   }
   if(hasValue){
     setHasValue(false)
     setSearchSpan(12)
   }
  },[clearText,hasValue])

  return (
    <Row className="main-header" justify="space-between">
      <Col sm={22} md={23} lg={22} xl={20} xxl={16}>
        <Row className="main-header" gutter={[20,0]}>
          <Col sm={8} md={8} lg={searchSpan} xl={searchSpan} className="search-cloumn search-position" >
            {!appUser?.IsOnlyPatronMgr?<SearchBar libId={libID} setSpan={setSearchSpan} span={searchSpan} setClearText={setClearText} setHasValue={setHasValue}/>:null}
          </Col>
          <Col sm={8} md={8} lg={6} xl={6} className="cart">
            {
              !appUser?.IsOnlyPatronMgr?
              <Dropdown.Button
              overlay={userCarts}
              trigger={["click"]}
              icon={<DropdownTitle />}
              className="cart-menu"
              onClick={handleCartClick}
            >
              <img src={cart} alt="cart" />
            </Dropdown.Button>:null
            }
         
          </Col>
          {/* <Col sm={2} md={1} lg={1} xl={1}>
            <img src={notification} className="bellIcon" alt="notifications" />
          </Col> */}

          <Col sm={6} md={5} lg={4} xl={4} className="User">
            <Dropdown overlay={UserMenuItems} trigger={["click"]} placement="bottomRight">
              <span>
                {concatNames(appUser?.firstName, appUser?.lastName)}
                <img src={downIcon} className="userMenu" alt="down" />
              </span>
            </Dropdown>
          </Col>
        </Row>
      </Col>
      <NewCartModal isModalVisible={showModal} setModalVisible={setShowModal} appUser={appUser} isNewCartDetailsNeed={false}/> 
    </Row>
  );
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function activeCartArray(arg0: string, activeCartArray: any) {
  throw new Error("Function not implemented.");
}

