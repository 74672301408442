import { useState, useEffect, useRef, createRef } from "react";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import {
  Row,
  Col,
  Checkbox,
  Tabs,
  Input,
  List,
  Divider,
  Form,
  Empty,
} from "antd";
import Button from "../../../stateless/common/button";
import { dropdownIcon } from "../../../../images";
import { useDispatch, useSelector } from "react-redux";
import {
  clearHideCatagoryData,
  SettingStateSelector,
} from "../../../../../redux/reducers/settings/settingsReducer";
import "./libraryStyles.less";
import { patronCheckboxes, patronInputs } from "../settingUtility";
import {
  fetchHideCategory,
  fetchRestrictedCategory,
  removeCategory,
  restrictCategory,
  saveRestrictCategoryIDs,
  updateLibrarySettings,
} from "../../../../../redux/actions/settings/settings";
export const PatronsSettings = (props: any) => {
  const { libId, setUnSaveData, unSaveData } = props;
  const formRef = createRef<any>();
  const { Search } = Input;
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const {
    libSettingsData,
    libSettingsFormState,
    hideCatgoryData,
    hideCatgoryFormState,
    restrictedCat,
    restrictCatgoryFormState,
    SaveRestrictedIds,
    getLibraryFormState,
    updateLibraryFormState,
  } = useSelector(SettingStateSelector);
  const [showCarrat, setShowCarrat] = useState<boolean>(false);
  const inputRef = useRef<any>(null);
  const [patronLabel, setPatronLabel] = useState<any>([]);
  const [displayCategoryChange, setDisplayCategoryChange] = useState("");
  const [globalLabel, setGlobalLabel] = useState<any>([]);
  const [restrictedIds, setRestrictedIds] = useState<any>([]);
  const [editing, setEditing] = useState<boolean>(false);
  const [hideData, setHideData] = useState<any>([]);
  const [restrictData, setrestrictData] = useState<any>([]);
  const [updateSettings, setUpdateSettings] = useState<any>(libSettingsData);
  const [formLoading, setFormLoading] = useState(false);
  const carratClick = () => {
    setShowCarrat(!showCarrat);
    setDisplayCategoryChange("");
    dispatch(clearHideCatagoryData());
  };

  useEffect(() => {
    if (libSettingsData?.LibraryId !== undefined) {
      setGlobalLabel(patronCheckboxes(libSettingsData));
      setPatronLabel(patronInputs(libSettingsData));
      setUpdateSettings(libSettingsData);
    }
  }, [libSettingsData]);

  useEffect(() => {
    setGlobalLabel(patronCheckboxes(updateSettings));
  }, [updateSettings]);

  useEffect(() => {
    if (
      !updateSettings.IsAudioBookTrendingShelfEnabled &&
      !updateSettings.IsEbookTrendingShelfEnabled
    ) {
      setUpdateSettings({
        ...updateSettings,
        IsTrendingShelfEnabled: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateSettings.IsAudioBookTrendingShelfEnabled,
    updateSettings.IsEbookTrendingShelfEnabled,
  ]);

  const onSelectCheckBox = (e: any) => {
    if (e.target.id === "AllowGlobalCatalogSearch") {
      setUpdateSettings({
        ...updateSettings,
        [`${e.target.id}`]: e.target.checked === true ? 1 : 0,
      });
    } else if (e.target.id === "PatronPIN") {
      setUpdateSettings({
        ...updateSettings,
        [`${e.target.id}`]: e.target.checked === true ? 1 : 0,
      });
    } else if (e.target.id === "SupportLoanHistory") {
      setUpdateSettings({
        ...updateSettings,
        [`${e.target.id}`]: e.target.checked === true ? 1 : 0,
      });
    } else if (e.target.id === "IsTrendingShelfEnabled") {
      setUpdateSettings({
        ...updateSettings,
        [`${e.target.id}`]: e.target.checked,
        IsAudioBookTrendingShelfEnabled: e.target.checked,
        IsEbookTrendingShelfEnabled: e.target.checked,
      });
    } else {
      setUpdateSettings({
        ...updateSettings,
        [`${e.target.id}`]: e.target.checked,
      });
    }
  };

  useEffect(() => {
    var res = hideCatgoryData.filter(
      (item: { Id: any }) => !SaveRestrictedIds?.includes(item?.Id)
    );
    setHideData(res);
  }, [hideCatgoryData, SaveRestrictedIds]);

  useEffect(() => {
    setrestrictData(restrictedCat);
  }, [restrictedCat]);

  const handleEditSave = () => {
    let formData = new FormData();

    formData.append(
      "Twitter",
      JSON.stringify(updateSettings?.Twitter) !== "null"
        ? updateSettings?.Twitter
        : ""
    );
    formData.append(
      "URLName",
      JSON.stringify(updateSettings?.URLName) !== "null"
        ? updateSettings?.URLName
        : ""
    );
    formData.append(
      "SupportEmail",
      JSON.stringify(updateSettings?.SupportEmail) !== "null"
        ? updateSettings?.SupportEmail
        : ""
    );
    formData.append(
      "LibraryWebsite",
      JSON.stringify(updateSettings?.LibraryWebsite) !== "null"
        ? updateSettings?.LibraryWebsite
        : ""
    );
    formData.append(
      "SupportWebsite",
      JSON.stringify(updateSettings?.SupportWebsite) !== "null"
        ? updateSettings?.SupportWebsite
        : ""
    );
    if (updateSettings?.AllowGlobalCatalogSearch === 1) {
      formData.append("AllowGlobalCatalogSearch", "on");
    } else {
      formData.append("AllowGlobalCatalogSearch", "off");
    }

    if (updateSettings?.SupportLoanHistory === 1) {
      formData.append("SupportLoanHistory", "on");
    } else {
      formData.append("SupportLoanHistory", "off");
    }

    if (updateSettings?.IsTrendingShelfEnabled === true) {
      formData.append("IsTrendingShelfEnabled", "on");
    } else {
      formData.append("IsTrendingShelfEnabled", "off");
    }

    if (updateSettings?.IsEbookTrendingShelfEnabled === true) {
      formData.append("IsEbookTrendingShelfEnabled", "on");
    } else {
      formData.append("IsEbookTrendingShelfEnabled", "off");
    }

    if (updateSettings?.IsAudioBookTrendingShelfEnabled === true) {
      formData.append("IsAudioBookTrendingShelfEnabled", "on");
    } else {
      formData.append("IsAudioBookTrendingShelfEnabled", "off");
    }

    if (updateSettings?.PatronPIN === 1) {
      formData.append("PatronPIN", "on");
    } else {
      formData.append("PatronPIN", "off");
    }

    formData.append(
      "MaxPatronWishes",
      updateSettings?.MaxPatronWishes !== ""
        ? JSON.stringify(updateSettings?.MaxPatronWishes) !== "null"
          ? updateSettings?.MaxPatronWishes
          : "0"
        : "0"
    );
    formData.append(
      "MaxLoanedDocuments",
      JSON.stringify(updateSettings?.MaxLoanedDocuments) !== "null"
        ? updateSettings?.MaxLoanedDocuments
        : ""
    );
    formData.append(
      "MaxHeldDocuments",
      JSON.stringify(updateSettings?.MaxHeldDocuments) !== "null"
        ? updateSettings?.MaxHeldDocuments
        : ""
    );
    formData.append(
      "MaxLoanTimeDuration",
      JSON.stringify(updateSettings?.MaxLoanTimeDuration) !== "null"
        ? updateSettings?.MaxLoanTimeDuration
        : ""
    );
    formData.append(
      "MaxHoldTimeDuration",
      JSON.stringify(updateSettings?.MaxHoldTimeDuration) !== "null"
        ? updateSettings?.MaxHoldTimeDuration
        : ""
    );
    formData.append(
      "LanguageForApps",
      JSON.stringify(updateSettings?.LanguageForApps) !== "null"
        ? updateSettings?.LanguageForApps
        : ""
    );
    dispatch(
      updateLibrarySettings({
        libraryId: libId,
        reqBody: formData,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchRestrictedCategory({ libraryId: libId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let ids: any[] = [];
    restrictedCat?.map((item: any) => {
      return ids?.push(item?.Id);
    });
    setRestrictedIds(ids);
  }, [restrictedCat]);

  const getHideCategory = (value: any) => {
    if (displayCategoryChange !== "") {
      dispatch(fetchHideCategory({ query: value, libraryId: libId }));
    }
  };

  const handleRemove = (data: any) => {
    let list = restrictedIds?.filter((item: any) => {
      return item !== data?.Id;
    });
    setrestrictData(
      restrictData?.filter((item: any) => {
        return item?.Id !== data?.Id;
      })
    );
    if (list) {
      dispatch(
        removeCategory({
          libraryId: libId,
          reqBody: { categories: list },
        })
      );
    }
  };
  const handleRestrict = (data: any) => {
    let list = [...SaveRestrictedIds, data?.Id];
    setHideData(
      hideData?.filter((item: any) => {
        return item?.Id !== data?.Id;
      })
    );
    if (list) {
      dispatch(
        restrictCategory({
          libraryId: libId,
          reqBody: { categories: list },
        })
      );
    }
  };

  const onBlur = () => {
    setEditing(!editing);
    if (editing) {
    }
  };

  const handleInput = (e: any) => {
    if (e.target.name === "MaxPatronWishes") {
      setUpdateSettings({
        ...updateSettings,
        [`${e.target.name}`]: e?.target?.value,
      });
    } else {
      setUpdateSettings({
        ...updateSettings,
        [`${e.target.name}`]: parseInt(e?.target?.value),
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const checkstatus = (value: any) => {
    if (value === 0) {
      return false;
    } else if (value === 1) {
      return true;
    } else if (value === true) {
      return true;
    } else if (value === false) {
      return false;
    }
  };

  const handelCancel = () => {
    setUpdateSettings(libSettingsData);
    formRef.current.setFieldsValue(libSettingsData);
  };

  useEffect(() => {
    if (JSON.stringify(updateSettings) !== JSON.stringify(libSettingsData)) {
      setUnSaveData(true);
    } else {
      setUnSaveData(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSettings, libSettingsData]);

  useEffect(() => {
    if (!unSaveData) {
      handelCancel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unSaveData]);

  useEffect(() => {
    dispatch(saveRestrictCategoryIDs(restrictedIds));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictedIds]);

  useEffect(() => {
    if (updateLibraryFormState?.isSuccess) {
      setFormLoading(true);
    }
  }, [updateLibraryFormState]);

  useEffect(() => {
    if (getLibraryFormState?.loading) {
      setFormLoading(false);
    } else if (
      !getLibraryFormState?.loading &&
      !updateLibraryFormState?.loading
    ) {
      setFormLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLibraryFormState]);

  return (
    <SpinnerLoader loading={libSettingsFormState.loading || formLoading}>
      <Form
        className="patronForm"
        onFinish={handleEditSave}
        onFinishFailed={onFinishFailed}
        ref={formRef}
      >
        <div className="scrollContent scrollStyle patronScroll">
          <div className="globalSetingsOptions">
            <div className="global-setting-title">Global Settings</div>
            {globalLabel?.map((item: any) => {
              if (
                item?.name === "IsAudioBookTrendingShelfEnabled" ||
                item?.name === "IsEbookTrendingShelfEnabled"
              ) {
                return (
                  <div
                    key={item.name}
                    className={`global-setting-checkbox ${item?.label}`}
                  >
                    {updateSettings?.IsTrendingShelfEnabled ? (
                      <Checkbox
                        id={item?.name}
                        onChange={onSelectCheckBox}
                        checked={checkstatus(updateSettings[`${item?.name}`])}
                      >
                        {item?.label}
                      </Checkbox>
                    ) : null}
                  </div>
                );
              } else {
                return (
                  <div
                    key={item.name}
                    className={`global-setting-checkbox ${item?.label}`}
                  >
                    <Checkbox
                      id={item?.name}
                      onChange={onSelectCheckBox}
                      checked={checkstatus(updateSettings[`${item?.name}`])}
                    >
                      {item?.label}
                    </Checkbox>
                  </div>
                );
              }
            })}
          </div>
          <Row>
            <Col span={18}>
              <hr className="col-wrapper margin-adjust" />
            </Col>
          </Row>
          {patronLabel?.map((item: any) => {
            if (
              item?.name === "MaxPatronWishes" &&
              updateSettings.AllowGlobalCatalogSearch === 0
            ) {
              return (
                <Row key={item.name}>
                  <Col md={7} lg={6} xl={6}>
                    <div className={`global-setting-label ${item?.name}`}>
                      {item?.label}
                    </div>
                    <Form.Item
                      style={{
                        margin: 0,
                      }}
                      name={item?.name}
                      initialValue={updateSettings[`${item?.name}`]}
                    >
                      <Input
                        value={updateSettings[`${item?.name}`]}
                        ref={inputRef}
                        name={item?.name}
                        onChange={handleInput}
                        onBlur={onBlur}
                        className="patronInput patronDisableInput"
                        maxLength={3}
                        disabled={true}
                        id={item?.name}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              );
            } else if (item?.name === "MaxHeldDocuments") {
              return (
                <Row key={item.name}>
                  <Col md={7} lg={6} xl={6}>
                    <div className={`global-setting-label ${item?.name}`}>
                      {item?.label}
                    </div>
                    <Form.Item
                      style={{
                        margin: 0,
                      }}
                      name={item?.name}
                      rules={[
                        {
                          required: true,
                          message: `${item?.label} is required.`,
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: `${item?.label} should contain only numbers.`,
                        },
                      ]}
                      initialValue={updateSettings[`${item?.name}`]}
                    >
                      <Input
                        value={updateSettings[`${item?.name}`]}
                        ref={inputRef}
                        name={item?.name}
                        onChange={handleInput}
                        onBlur={onBlur}
                        className="patronInput"
                        maxLength={3}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              );
            } else if (
              item?.name === "MaxPatronWishes" &&
              updateSettings.AllowGlobalCatalogSearch === 1
            ) {
              return (
                <Row key={item.name}>
                  <Col md={7} lg={6} xl={6}>
                    <div className={`global-setting-label ${item?.name}`}>
                      {item?.label}
                    </div>
                    <Form.Item
                      style={{
                        margin: 0,
                      }}
                      name={item?.name}
                      rules={[
                        {
                          pattern: /^[0-9]+$/,
                          message: `${item?.label} should contain only numbers.`,
                        },
                        {
                          pattern: /[1-9]/,
                          message: `${item?.label} value cannot be 0`,
                        },
                      ]}
                      initialValue={updateSettings[`${item?.name}`]}
                    >
                      <Input
                        value={updateSettings[`${item?.name}`]}
                        ref={inputRef}
                        name={item?.name}
                        onChange={handleInput}
                        onBlur={onBlur}
                        className="patronInput"
                        maxLength={3}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              );
            } else {
              return (
                <Row key={item.name}>
                  <Col md={7} lg={6} xl={6}>
                    <div className={`global-setting-label ${item?.name}`}>
                      {item?.label}
                    </div>
                    <Form.Item
                      style={{
                        margin: 0,
                      }}
                      name={item?.name}
                      rules={[
                        {
                          required: true,
                          message: `${item?.label} is required.`,
                        },
                        {
                          pattern: /^[0-9]+$/,
                          message: `${item?.label} should contain only numbers.`,
                        },
                        {
                          pattern: /[1-9]/,
                          message: `${item?.label} value cannot be 0`,
                        },
                      ]}
                      initialValue={updateSettings[`${item?.name}`]}
                    >
                      <Input
                        value={updateSettings[`${item?.name}`]}
                        ref={inputRef}
                        name={item?.name}
                        onChange={handleInput}
                        onBlur={onBlur}
                        className="patronInput"
                        maxLength={3}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              );
            }
          })}
          <Row className="patronHideCategory">
            <Col span={10}>
              <div className="global-setting-label">Hide Category</div>
            </Col>
            <Col span={2} className="categoryCarrat">
              <img
                src={dropdownIcon}
                alt="notfound"
                onClick={carratClick}
                className={`${!showCarrat ? "rotateimg90" : ""}`}
              />
            </Col>
            <Col span={10}></Col>
            {showCarrat ? (
              <Col span={12}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Restricted Categories" key="1">
                    <SpinnerLoader loading={restrictCatgoryFormState?.loading}>
                      <List
                        dataSource={restrictData}
                        locale={{
                          emptyText: <Empty description="No results found." />,
                        }}
                        renderItem={(item: any) => (
                          <>
                            <div id={item?.index} className="categoryList">
                              <Row>
                                <Col className="categoryList_text" span={18}>
                                  {item?.CategoryName?.toUpperCase()}/
                                  {item?.Path?.toString()?.toLowerCase()}
                                </Col>
                                <Col
                                  className="categoryList_button_red"
                                  span={6}
                                >
                                  <span onClick={() => handleRemove(item)}>
                                    Remove
                                  </span>
                                </Col>
                              </Row>
                            </div>
                            <Divider />
                          </>
                        )}
                      />
                    </SpinnerLoader>
                  </TabPane>
                  <TabPane tab="Display Categories" key="2">
                    <Search
                      placeholder="Subject"
                      className="display-search-icon"
                      onSearch={getHideCategory}
                      onChange={(e: any) =>
                        setDisplayCategoryChange(e.target.value)
                      }
                      value={displayCategoryChange}
                      enterButton
                    />
                    <SpinnerLoader loading={hideCatgoryFormState?.loading}>
                      <List
                        dataSource={hideData}
                        locale={{
                          emptyText: <Empty description="No results found." />,
                        }}
                        renderItem={(item: any) => (
                          <>
                            <div id={item?.index} className="categoryList">
                              <Row>
                                <Col className="categoryList_text" span={18}>
                                  {item?.CategoryName?.toUpperCase()}/
                                  {item?.Path?.toString()?.toLowerCase()}
                                </Col>
                                <Col className="categoryList_button" span={6}>
                                  <span onClick={() => handleRestrict(item)}>
                                    Restrict
                                  </span>
                                </Col>
                              </Row>
                            </div>
                            <Divider />
                          </>
                        )}
                      />
                    </SpinnerLoader>
                  </TabPane>
                </Tabs>
              </Col>
            ) : null}
          </Row>
          <div className="patronFooter">
            {JSON.stringify(updateSettings) !==
            JSON.stringify(libSettingsData) ? (
              <Row className="patron-button-wrapper">
                <Col xs={7} sm={6} md={5} lg={4} className="patronCancelBtn">
                  <Form.Item>
                    <Button
                      type="secondary cancel-button"
                      onClick={handelCancel}
                    >
                      {" "}
                      Cancel
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={7} sm={6} md={5} lg={4}>
                  <Form.Item>
                    <Button
                      type="primary confirm-button"
                      onSubmit={handleEditSave}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
      </Form>
    </SpinnerLoader>
  );
};
