/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Input, Switch } from "antd";
import Button from "../../../stateless/common/button";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../../../stateless/common/dropDown";
import {
  dropdownIcon,
  searchIcon,
  eBookIcon,
  audioBookIcon,
} from "../../../../images";
import "./ppuStyles.less";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import {
  fetchAbook,
  fetchEbook,
  updateAbook,
  updateEbook,
} from "../../../../../redux/actions/settings/settings";
import { SettingStateSelector } from "../../../../../redux/reducers/settings/settingsReducer";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import BudgetModel from "./budgetModel";
import { infoIcon } from "../../../../images";
import { Popover } from "antd";

export const PayPerUse = (props: any) => {
  const { libId, managerOptions, notifyOptions } = props;
  const dispatch = useDispatch();
  const {
    eBookData,
    aBookData,
    ppuEbookState,
    ppuAbookState,
    ppuEbookUpdateState,
    ppuAbookUpdateState,
  } = useSelector(SettingStateSelector);
  const { appUser } = useSelector(userStateSelector);
  var currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";
  const [ebookInitial, setEbookInitial] = useState<any>({
    Buyer: "",
    EmailNotificationSubscribers: [],
    IsPPUEnabled: false,
    IsPatronLimitEnabled: false,
    LibraryId: 0,
    MaxPatronLimit: 1,
    MaxTitlePriceAllowed: 0,
    MediaType: 1,
    PORefNumber: "",
    RunningReserveTotal: 0,
    RunningTotal: 0,
    SpendingLimit: 0,
  });
  const [abookInitial, setAbookInitial] = useState<any>({
    Buyer: "",
    EmailNotificationSubscribers: [],
    IsPPUEnabled: false,
    IsPatronLimitEnabled: false,
    LibraryId: 0,
    MaxPatronLimit: 1,
    MaxTitlePriceAllowed: 0,
    MediaType: 2,
    PORefNumber: "",
    RunningReserveTotal: 0,
    RunningTotal: 0,
    SpendingLimit: 0,
  });
  var lib_Id =
  appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  const [notifyUsers, setNotifyUsers] = useState<any>([]);
  const [notifyAbokUsers, setNotifyAbokUsers] = useState<any>([]);
  const [getNotifyUsers, setGetNotifyUsers] = useState<any>([]);
  const [getNotifyAbokUsers, setGetNotifyAbokUsers] = useState<any>([]);
  const [ebookSpendingLimit, setEbookSpendingLimit] = useState<boolean>(false);
  const [ebookTitlePrice, setebookTitlePrice] = useState<boolean>(false);
  const [ebookPatronLimit, setEbookPatronLimit] = useState<boolean>(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [abookSpendingLimit, setAbookSpendingLimit] = useState<boolean>(false);
  const [abookTitlePrice, setAbookTitlePrice] = useState<boolean>(false);
  const [abookPatronLimit, setAbookPatronLimit] = useState<boolean>(false);
  const [spendingBtnHook, setSpendingBtnHook] = useState<boolean>(false);
  const [titlePriceHook, setTitlePriceHook] = useState<boolean>(false);
  const [modelConfirm, setModelConfirm] = useState<boolean>(false);
  const [abookspendingBtnHook, setAbookSpendingBtnHook] =
    useState<boolean>(false);
  const [abooktitlePriceHook, setAbookTitlePriceHook] =
    useState<boolean>(false);
  const [popoverState,setPopoverState] = useState<boolean>(false);

  useEffect(() => {
    var data = notifyUsers?.filter((item: any) => {
      return item !== "";
    });
    setGetNotifyUsers(data);
  }, [notifyUsers]);

  useEffect(() => {
    var data = notifyAbokUsers?.filter((item: any) => {
      return item !== "";
    });
    setGetNotifyAbokUsers(data);
  }, [notifyAbokUsers]);

  useEffect(() => {
    setEbookInitial({ ...ebookInitial, LibraryId: libId });
    setAbookInitial({ ...abookInitial, LibraryId: libId });
  }, []);
  useEffect(() => {
    setAbookInitial(aBookData);
    setEbookInitial(eBookData);
    setNotifyUsers(eBookData?.EmailNotificationSubscribers)
    setNotifyAbokUsers(aBookData?.EmailNotificationSubscribers) 
  }, [eBookData, aBookData, managerOptions, notifyOptions]);

  useEffect(() => {
    if (eBookData?.length < 1) {
      dispatch(fetchEbook({}));
    } else if(aBookData?.length < 1){
      dispatch(fetchAbook({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ lib_Id,eBookData, aBookData]);

  const validateInput = (data: any) => {
    let fvalue = data
      .replace(/[a-z]/g, "")
      .replace(/^0+/, "")
      .replace(/[!@#$%^&*]/g, "")
      .replace(/\s/g, "");
    fvalue =
      fvalue.indexOf(".") >= 0
        ? fvalue.substr(0, fvalue.indexOf(".")) +
          fvalue.substr(fvalue.indexOf("."), 3)
        : fvalue;
    return fvalue;
  };

  const handleInput = (e: any, media: number) => {
    if (media === 1) {
      if (ebookInitial?.IsPPUEnabled) {
        if (e?.target?.name === "PORefNumber") {
          setEbookInitial({
            ...ebookInitial,
            [e?.target?.name]: e?.target?.value,
          });
        } else if (e?.target?.name === "SpendingLimit") {
          let value = e?.target?.value;
          value = validateInput(value);
          setEbookInitial({
            ...ebookInitial,
            [e?.target?.name]: value,
          });
        } else if (e?.target?.name === "MaxTitlePriceAllowed") {
          let value = e?.target?.value;
          value = validateInput(value);
          setEbookInitial({
            ...ebookInitial,
            [e?.target?.name]: value,
          });
        } else if (e?.target?.name === "MaxPatronLimit") {
          let value = e?.target?.value;
          value = value
            .replace(/[a-z]/g, "")
            .replace(/[!.@#$%^&*]/g, "")
            .replace(/^[^1-9]+/g, "")
            .replace(/\s/g, "");
          setEbookInitial({
            ...ebookInitial,
            [e?.target?.name]: value,
          });
        } else {
          setEbookInitial({
            ...ebookInitial,
            [e?.target?.name]: parseInt(e?.target?.value),
          });
        }
      }
    } else if (media === 2) {
      if (abookInitial?.IsPPUEnabled) {
        if (e?.target?.name === "PORefNumber") {
          setAbookInitial({
            ...abookInitial,
            [e?.target?.name]: e?.target?.value,
          });
        } else if (e?.target?.name === "SpendingLimit") {
          let value = e?.target?.value;
          value = validateInput(value);
          setAbookInitial({
            ...abookInitial,
            [e?.target?.name]: value,
          });
        } else if (e?.target?.name === "MaxTitlePriceAllowed") {
          let value = e?.target?.value;
          value = validateInput(value);
          setAbookInitial({
            ...abookInitial,
            [e?.target?.name]: value,
          });
        } else if (e?.target?.name === "MaxPatronLimit") {
          let value = e?.target?.value;
          value = value
            .replace(/[a-z]/g, "")
            .replace(/[!.@#$%^&*]/g, "")
            .replace(/^[^1-9]+/g, "")
            .replace(/\s/g, "");
          setAbookInitial({
            ...abookInitial,
            [e?.target?.name]: value,
          });
        } else {
          setAbookInitial({
            ...abookInitial,
            [e?.target?.name]: parseInt(e?.target?.value),
          });
        }
      } else {
        return undefined;
      }
    }
  };

  const onSwitch = (checked: any, media: number) => {
    if (media === 1) {
      setEbookInitial({ ...ebookInitial, IsPPUEnabled: checked });
    } else if (media === 2) {
      setAbookInitial({ ...abookInitial, IsPPUEnabled: checked });
    } else {
      return undefined;
    }
  };

  const onSwitchPatronLimit = (checked: any, media: number) => {
    if (media === 1 && ebookInitial?.IsPPUEnabled) {
      setEbookInitial({ ...ebookInitial, IsPatronLimitEnabled: checked });
    } else if (media === 2 && abookInitial?.IsPPUEnabled) {
      setAbookInitial({ ...abookInitial, IsPatronLimitEnabled: checked });
    } else {
      return undefined;
    }
  };

  const handleManagerSelect = (value: any, media: number) => {
    if (media === 1 && ebookInitial?.IsPPUEnabled) {
      setEbookInitial({ ...ebookInitial, Buyer: value });
    } else if (media === 2 && abookInitial?.IsPPUEnabled) {
      setAbookInitial({ ...abookInitial, Buyer: value });
    } else {
      return undefined;
    }
  };
  const handleNotifySelect = (value: any, media: number) => {
    if (media === 1 && ebookInitial?.IsPPUEnabled) {
      setNotifyUsers(value);
      setEbookInitial({
        ...ebookInitial,
        EmailNotificationSubscribers: value
          .join(",")
          .split(",")
          ?.filter((item: any) => {
            return item !== "";
          }),
      });
    } else if (media === 2 && abookInitial?.IsPPUEnabled) {
      setNotifyAbokUsers(value);
      setAbookInitial({
        ...abookInitial,
        EmailNotificationSubscribers: value
          .join(",")
          .split(",")
          ?.filter((item: any) => {
            return item !== "";
          }),
      });
    } else {
      return undefined;
    }
  };

  const modelcheck = () => {
    if (
      (ebookInitial?.IsPPUEnabled && ebookInitial?.SpendingLimit <
        ebookInitial?.RunningTotal + ebookInitial?.RunningReserveTotal) ||
        (abookInitial?.IsPPUEnabled && abookInitial?.SpendingLimit <
        abookInitial?.RunningTotal + abookInitial?.RunningReserveTotal)
    ) {
      setShowModal(true);
      return true;
    } else {
      setShowModal(false);
      return false;
    }
  };

  const handleSave = () => {
    if (!saveBtnDisabled) {
      if (modelcheck() === false) {
        dispatch(updateEbook({ ...ebookInitial, LibraryId: libId,MaxPatronLimit:ebookInitial?.MaxPatronLimit < 1?1:ebookInitial?.MaxPatronLimit }));
        dispatch(updateAbook({ ...abookInitial, LibraryId: libId,MaxPatronLimit:abookInitial?.MaxPatronLimit < 1?1:abookInitial?.MaxPatronLimit }));
      }
    }
  };
  
  useEffect(() => {
    if (
      ebookInitial?.IsPatronLimitEnabled && ebookInitial?.IsPPUEnabled &&
      ebookInitial?.MaxPatronLimit === ""
    ) {
      setEbookPatronLimit(true);
    } else {
      setEbookPatronLimit(false);
    }
    if (ebookInitial?.SpendingLimit === "" && ebookInitial?.IsPPUEnabled) {
      setEbookSpendingLimit(true);
    } else {
      setEbookSpendingLimit(false);
    }
    if (ebookInitial?.MaxTitlePriceAllowed === "" && ebookInitial?.IsPPUEnabled) {
      setebookTitlePrice(true);
    } else {
      setebookTitlePrice(false);
    }
    if (abookInitial?.SpendingLimit === "" && abookInitial?.IsPPUEnabled) {
      setAbookSpendingLimit(true);
    } else {
      setAbookSpendingLimit(false);
    }
    if (abookInitial?.MaxTitlePriceAllowed === "" && abookInitial?.IsPPUEnabled) {
      setAbookTitlePrice(true);
    } else {
      setAbookTitlePrice(false);
    }
    if (
      abookInitial?.IsPatronLimitEnabled && abookInitial?.IsPPUEnabled &&
      abookInitial?.MaxPatronLimit === ""
    ) {
      setAbookPatronLimit(true);
    } else {
      setAbookPatronLimit(false);
    }
    if (abookInitial?.IsPPUEnabled && abookInitial?.SpendingLimit === null) {
      setAbookSpendingBtnHook(true);
    } else {
      setAbookSpendingBtnHook(false);
    }
    if (
      abookInitial?.IsPPUEnabled &&
      abookInitial.MaxTitlePriceAllowed === null
    ) {
      setAbookTitlePriceHook(true);
    } else {
      setAbookTitlePriceHook(false);
    }
    if(ebookInitial.IsPPUEnabled && ebookInitial.SpendingLimit === null) {
      setSpendingBtnHook(true);
    } else {
      setSpendingBtnHook(false);
    }
    if(ebookInitial.IsPPUEnabled && ebookInitial.MaxTitlePriceAllowed === null) {
      setTitlePriceHook(true);
    } else {
      setTitlePriceHook(false);
    }
  }, [ebookInitial, abookInitial]);

  useEffect(() => {
    if(ebookSpendingLimit || ebookTitlePrice || ebookPatronLimit || abookSpendingLimit || abookTitlePrice || abookPatronLimit){
      setSaveBtnDisabled(true)
    } else {
      setSaveBtnDisabled(false)
    }
  }, [ebookSpendingLimit,ebookTitlePrice,ebookPatronLimit,abookSpendingLimit,abookTitlePrice,abookPatronLimit])

  useEffect(() => {
    if(!titlePriceHook && !spendingBtnHook && !abookspendingBtnHook && !abooktitlePriceHook) {
      setSaveBtnDisabled(false);
    } else {
      setSaveBtnDisabled(true);
    }
  }, [spendingBtnHook,titlePriceHook,abookspendingBtnHook,abooktitlePriceHook])

  useEffect(() => {
    if (modelConfirm) {
      dispatch(updateEbook({ ...ebookInitial, LibraryId: libId }));
      dispatch(updateAbook({ ...abookInitial, LibraryId: libId }));
      setModelConfirm(false)
    }else{
      return undefined
    }
  }, [modelConfirm, ebookInitial, abookInitial]);

const hidePopover = () => {
  setPopoverState(!popoverState)
}


  return (
    <>
      <SpinnerLoader
        loading={
          ppuEbookState.loading ||
          ppuAbookState.loading ||
          ppuEbookUpdateState.loading ||
          ppuAbookUpdateState.loading
        }
      >
        <Row className="head-contai75vhner ppu-wrapper scrollStyle scrollContent">
          <Col xs={24} sm={12} md={12} className="ppu-Ebook pb-25">
            <Row className="ml-20">
              <Col className="ptb-25">
                <span>
                  <img src={eBookIcon} alt="icon" className="pb-2" />
                </span>
                <span className="ebookTitle">eBook Settings</span>
                <span className="switch-wrapper">
                  <Switch
                    className="ppu-switch"
                    defaultChecked={ebookInitial?.IsPPUEnabled}
                    checked={ebookInitial?.IsPPUEnabled}
                    onChange={(checked: any) => onSwitch(checked, 1)}
                  />
                </span>
                <span className="pl-10 switch-text">
                  {ebookInitial?.IsPPUEnabled ? "ON" : "OFF"}
                </span>
              </Col>
            </Row>
            <div className="whitebg ml-20 pl-20 pr-20 pt-15">
              <Row>
                <Col span={24}>
                <div className="card-dropdown-withLabel ppuLabel">
                    {ebookInitial?.IsPPUEnabled ? (
                      <label className="popover-content-width">Monthly Budget <Popover
                      trigger={["click"]}
                      onVisibleChange={hidePopover}
                      placement="bottomLeft"
                      content={`The Pay per use service will 
                      purchase content until the set
                       monthly spending limit is reached.
                       The spending limit is based on
                        purchases made by the pay per 
                        use service in each calendar
                        month.
                       `}
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                  >
                      <img className="info-icon" src={infoIcon} alt="info" />
                  </Popover></label>
                    ) : null}
                    <Input
                      name="SpendingLimit"
                      placeholder="Monthly Budget"
                      className={`card-input pt-8 ${saveBtnDisabled && ebookInitial?.SpendingLimit == null  ? "ppuMandatoryField" : ""}`}
                      bordered={false}
                      value={
                        ebookInitial?.IsPPUEnabled
                          ? ebookInitial?.SpendingLimit
                          : undefined
                      }
                      onChange={(e: any) => handleInput(e, 1)}
                      maxLength={10}
                      disabled={!ebookInitial?.IsPPUEnabled}
                    />
                  </div>
                </Col>
                {ebookSpendingLimit && ebookInitial?.IsPPUEnabled ? (
                  <span className="ebookMonthlyBudget">
                    Monthly Budget is required and can be entered as nnn.xx (up
                    to 9999999.99)
                  </span>
                ) : null}
              </Row>
              <Row gutter={20} className="mr0">
                <Col span={12} className="onFieldDisable">
                <div className={`card-dropdown-withLabel ${popoverState ? "ppuzindex" : ""}`}>
                {ebookInitial?.IsPPUEnabled ? (
                  <label className="popover-content-width">Max Title Price <Popover
                  onVisibleChange={hidePopover}
                trigger={["click"]}
                placement="bottomLeft"
                content={"Any title over this price limit will not be offered to the patron as a PPU title."}
                getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover></label>
            ) : null}
                  <Input
                    name="MaxTitlePriceAllowed"
                    placeholder="Max Title Price"
                    className={`card-input pt-8 ${saveBtnDisabled && ebookInitial?.MaxTitlePriceAllowed == null  ? "ppuMandatoryField" : ""}`}
                    bordered={false}
                    value={
                      ebookInitial?.IsPPUEnabled
                        ? ebookInitial?.MaxTitlePriceAllowed
                        : undefined
                    }
                    onChange={(e: any) => handleInput(e, 1)}
                    maxLength={8}
                    disabled={!ebookInitial?.IsPPUEnabled}
                  />
                  </div>
                </Col>
              </Row>
              {ebookTitlePrice && ebookInitial?.IsPPUEnabled ? (
                  <Row>
                  <div className="ebooktitleprice">
                    Max title price can be entered as nnn.xx (up to 99999.99)
                  </div>
                  </Row>
                ) : null}
              <Row gutter={20} className="mr0 mt-5 alignBase">
                <Col span={5}>
                  <span className="switch-wrapper">
                    <Switch
                      className="ppu-Sec-switch"
                      defaultChecked={ebookInitial?.IsPatronLimitEnabled}
                      checked={ebookInitial?.IsPatronLimitEnabled}
                      onChange={(checked: any) =>
                        onSwitchPatronLimit(checked, 1)
                      }
                      disabled={!ebookInitial?.IsPPUEnabled}
                    />
                  </span>
                  <span className="pl-10 switchSec-text">
                    {ebookInitial?.IsPatronLimitEnabled ? "ON" : "OFF"}
                  </span>
                </Col>
                <Col span={5} offset={1}>
                  <span>Patron Limit</span>
                </Col>
                {ebookInitial.IsPatronLimitEnabled &&
                ebookInitial?.IsPPUEnabled ? (
                  <Col span={12}>
                    <div className="card-dropdown-withLabel labelCover">
                  <label className="popover-content-width labeltext">Max Patron Limit <Popover
                  onVisibleChange={hidePopover}
                trigger={["click"]}
                placement="bottomLeft"
                content={"Patrons  can borrow a limited amount of PPU ebooks only."}
                getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover></label>
                    <Input
                      name="MaxPatronLimit"
                      placeholder="Max Patron Limit"
                      className="card-input pt-8 ppuzindex"
                      bordered={false}
                      value={
                        ebookInitial?.IsPPUEnabled
                          ? ebookInitial?.MaxPatronLimit < 1 ?1:ebookInitial?.MaxPatronLimit
                          : undefined
                      }
                      type="number"
                      min={1}
                      onChange={(e: any) => handleInput(e, 1)}
                      disabled={!ebookInitial?.IsPPUEnabled}
                    />
                    {ebookPatronLimit && ebookInitial?.IsPPUEnabled ? (
                      <div className="ebookMonthlyBudget">
                        Max patron limit is required when enabled and can be
                        entered as nnn
                      </div>
                    ) : null}
                    </div>
                  </Col>
                ) : null}
              </Row>
              <Row gutter={20} className="mr0 mt-15">
                <Col span={24}>
                  <div className="card-dropdown-withLabel ppuDropdown">
                    {ebookInitial?.IsPPUEnabled ? <label className="popover-content-width">Buyer <Popover
                    onVisibleChange={hidePopover}
                trigger={["click"]}
                placement="bottomLeft"
                content={"The Library buyer who will be associated with pay per use purchases."} 
                getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover></label> : null}
                    <DropDown
                      placeholder="Buyer"
                      className="card-dropdown with-search pt-3"
                      bordered={false}
                      optionValue={managerOptions}
                      value={
                        ebookInitial?.IsPPUEnabled
                          ? ebookInitial?.Buyer
                          : undefined
                      }
                      disabled={!ebookInitial?.IsPPUEnabled}
                      onChange={(value: any) => handleManagerSelect(value, 1)}
                      icon={dropdownIcon}
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={20} className="mr0 mt-12">
                <Col span={24}>
                  <div className={`card-dropdown-withLabel ${popoverState ? "ppuzindex" : ""}`}>
                    {ebookInitial?.IsPPUEnabled ? (
                      <label>Email Notification</label>
                    ) : null}
                    <DropDown
                      showSearch
                      placeholder="Notification"
                      className="card-dropdown with-search pt-3"
                      bordered={false}
                      value={
                        ebookInitial?.IsPPUEnabled
                          ? getNotifyUsers?.length > 0
                            ? getNotifyUsers
                            : undefined
                          : undefined
                      }
                      optionValue={notifyOptions}
                      onChange={(value: any) => handleNotifySelect(value, 1)}
                      icon={searchIcon}
                      mode="multiple"
                      disabled={!ebookInitial?.IsPPUEnabled}
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={20} className="mr0 mt-5">
                <Col span={24} className="onFieldDisable">
                <div className={`card-dropdown-withLabel ${popoverState ? "ppuzindex" : ""}`}>
                {ebookInitial?.IsPPUEnabled ? (
                  <label className="popover-content-width">PO Reference # <Popover
                  onVisibleChange={hidePopover}
                trigger={["click"]}
                placement="bottomLeft"
                content={"Purchase order number will be associated with each purchase made through the Pay per use process."}
                getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover></label>
            ) : null}
                  <Input
                    name="PORefNumber"
                    placeholder="PO Reference #"
                    className="card-input pt-8"
                    bordered={false}
                    value={
                      ebookInitial?.IsPPUEnabled
                        ? ebookInitial?.PORefNumber
                        : undefined
                    }
                    onChange={(e: any) => handleInput(e, 1)}
                    disabled={!ebookInitial?.IsPPUEnabled}
                  />
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="a-center whitebg ptb-25 mt-12 ml-20 pl-20 pr-20">
              <Col span={10}>
                <span className="ppu-price-text">Total Spent This Month:</span>
              </Col>
              <Col span={8}>
                <div className="ppu-Middle-line"></div>
              </Col>
              <Col span={6} className="currencyWrapper">
                <span className="ppu-price-text">
                  {currency}
                  {ebookInitial?.IsPPUEnabled ? ebookInitial?.RunningTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',') : 0}
                </span>
              </Col>
            </Row>
            {/* ml-20 pl-20 pr-20 */}
            <Row
              justify="space-between"
              className="a-center whitebg ptb-25 mt-12 ml-20 pl-20 pr-20"
            >
              <Col span={10}>
                <span className="ppu-price-text popover-content-width">
               Amount Currently in Reserve:<Popover
                trigger={["click"]}
                onVisibleChange={hidePopover}
                placement="bottomLeft"
                content={"Reserve amount reflects the books that were on hold but are now available for the patron to borrow but they have not done so yet."} 
                getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover>
                </span>
              </Col>
              <Col span={8}>
                <div className="ppu-Middle-line"></div>
              </Col>
              <Col span={6} className="currencyWrapper">
                <span className="ppu-price-text">
                  {currency}
                  {ebookInitial?.IsPPUEnabled
                    ? ebookInitial?.RunningReserveTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')
                    : 0}
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12} className="ppu-Audio pb-25">
            <Row className="ml-20">
              <Col className="ptb-25">
                <span>
                  <img src={audioBookIcon} alt="icon" className="pb-9" />
                </span>
                <span className="ebookTitle">AudioBook Settings</span>
                <span className="switch-wrapper">
                  <Switch
                    className="ppu-switch"
                    defaultChecked={abookInitial?.IsPPUEnabled}
                    checked={abookInitial?.IsPPUEnabled}
                    onChange={(checked: any) => onSwitch(checked, 2)}
                  />
                </span>
                <span className="pl-10 switch-text">
                  {abookInitial?.IsPPUEnabled ? "ON" : "OFF"}
                </span>
              </Col>
            </Row>
            <div className="whitebg ml-20 pl-20 pr-20 pt-15">
              <Row>
                <Col span={24}>
                  <div className="card-dropdown-withLabel ppuLabel">
                    {abookInitial?.IsPPUEnabled ? (
                      <label className="popover-content-width">Monthly Budget <Popover
                      onVisibleChange={hidePopover}
                      trigger={["click"]}
                      placement="bottomLeft"
                      content={`The Pay per use service will 
                      purchase content until the set
                       monthly spending limit is reached.
                       The spending limit is based on
                        purchases made by the pay per 
                        use service in each calendar
                        month.
                       `}
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                  >
                      <img className="info-icon" src={infoIcon} alt="info" />
                  </Popover></label>
                    ) : null}
                    <Input
                      name="SpendingLimit"
                      placeholder="Monthly Budget"
                      className={`card-input pt-8 ${saveBtnDisabled && abookInitial?.SpendingLimit == null  ? "ppuMandatoryField" : ""}`}
                      bordered={false}
                      value={
                        abookInitial?.IsPPUEnabled
                          ? abookInitial?.SpendingLimit
                          : undefined
                      }
                      onChange={(e: any) => handleInput(e, 2)}
                      disabled={!abookInitial?.IsPPUEnabled}
                    />
                  </div>
                </Col>
                {abookSpendingLimit && abookInitial?.IsPPUEnabled ? (
                  <span className="ebookMonthlyBudget">
                    Monthly Budget is required and can be entered as nnn.xx (up
                    to 9999999.99)
                  </span>
                ) : null}
              </Row>
              <Row>
                <Col span={12} className="onFieldDisable">
                <div className={`card-dropdown-withLabel ${popoverState ? "ppuzindex" : ""}`}>
                {abookInitial?.IsPPUEnabled ? (
                  <label className="popover-content-width">Max Title Price <Popover
                  onVisibleChange={hidePopover}
                trigger={["click"]}
                placement="bottomLeft"
                content={"Any title over this price limit will not be offered to the patron as a PPU title."}
                getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover></label>
              ) : null}
                  <Input
                    name="MaxTitlePriceAllowed"
                    placeholder="Max Title Price"
                    className={`card-input pt-8 ${saveBtnDisabled && abookInitial?.MaxTitlePriceAllowed == null  ? "ppuMandatoryField" : ""}`}
                    bordered={false}
                    value={
                      abookInitial?.IsPPUEnabled
                        ? abookInitial?.MaxTitlePriceAllowed
                        : undefined
                    }
                    onChange={(e: any) => handleInput(e, 2)}
                    disabled={!abookInitial?.IsPPUEnabled}
                  />
                  </div>
                </Col>
              </Row>
              {abookTitlePrice && abookInitial?.IsPPUEnabled ? (
                  <Row>
                  <div className="ebooktitleprice">
                    Max title price can be entered as nnn.xx (up to 99999.99)
                  </div>
                  </Row>
                ) : null}
              <Row className="mt-5 alignBase">
                <Col span={5}>
                  <span className="switch-wrapper">
                    <Switch
                      className="ppu-Sec-switch"
                      defaultChecked={abookInitial?.IsPatronLimitEnabled}
                      checked={abookInitial?.IsPatronLimitEnabled}
                      onChange={(checked: any) =>
                        onSwitchPatronLimit(checked, 2)
                      }
                      disabled={!abookInitial?.IsPPUEnabled}
                    />
                  </span>
                  <span className="pl-10 switchSec-text">
                    {abookInitial?.IsPatronLimitEnabled ? "ON" : "OFF"}
                  </span>
                </Col>
                <Col span={5} offset={1}>
                  <span>Patron Limit</span>
                </Col>
                {abookInitial.IsPatronLimitEnabled &&
                abookInitial?.IsPPUEnabled ? (
                  <Col span={12}>
                    <div className="card-dropdown-withLabel labelCover">
                  <label className="popover-content-width labeltext">Max Patron Limit <Popover
                trigger={["click"]}
                onVisibleChange={hidePopover}
                placement="bottomLeft"
                content={"Patrons  can borrow a limited amount of PPU audio books only."}
                getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover></label>
                    <Input
                      name="MaxPatronLimit"
                      placeholder="Max Patron Limit"
                      className="card-input pt-8"
                      bordered={false}
                      value={
                        abookInitial?.IsPPUEnabled
                          ? abookInitial?.MaxPatronLimit < 1?1:abookInitial?.MaxPatronLimit
                          : undefined
                      }
                      min={1}
                      type="number"
                      onChange={(e: any) => handleInput(e, 2)}
                      disabled={!abookInitial?.IsPPUEnabled}
                    />
                    {abookPatronLimit && abookInitial?.IsPPUEnabled ? (
                      <div className="ebookMonthlyBudget">
                        Max patron limit is required when enabled and can be
                        entered as nnn
                      </div>
                    ) : null}
                    </div>
                  </Col>
                ) : null}
              </Row>
              <Row className="mt-15">
                <Col span={24}>
                  <div className="card-dropdown-withLabel ppuDropdown">
                    {abookInitial?.IsPPUEnabled ? <label className="popover-content-width">Buyer <Popover
                    onVisibleChange={hidePopover}
                trigger={["click"]}
                placement="bottomLeft"
                content={"The Library buyer who will be associated with pay per use purchases."} 
                getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover></label> : null}
                    <DropDown
                      placeholder="Buyer"
                      className="card-dropdown with-search pt-3"
                      bordered={false}
                      optionValue={managerOptions}
                      value={
                        abookInitial?.IsPPUEnabled
                          ? abookInitial?.Buyer
                          : undefined
                      }
                      onChange={(value: any) => handleManagerSelect(value, 2)}
                      icon={dropdownIcon}
                      disabled={!abookInitial?.IsPPUEnabled}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="ml0 mt-12 pb-2">
                <Col span={24}>
                  <div className={`card-dropdown-withLabel ${popoverState ? "ppuzindex" : ""}`}>
                    {abookInitial?.IsPPUEnabled ? (
                      <label>Email Notification</label>
                    ) : null}
                    <DropDown
                      showSearch
                      placeholder="Notification"
                      className="card-dropdown with-search pt-3"
                      bordered={false}
                      value={
                        abookInitial?.IsPPUEnabled
                          ? getNotifyAbokUsers?.length > 0
                            ? getNotifyAbokUsers
                            : undefined
                          : undefined
                      }
                      optionValue={notifyOptions}
                      onChange={(value: any) => handleNotifySelect(value, 2)}
                      icon={searchIcon}
                      mode="multiple"
                      disabled={!abookInitial?.IsPPUEnabled}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="ml0">
                <Col span={24} className="onFieldDisable">
                <div className={`card-dropdown-withLabel ${popoverState ? "ppuzindex" : ""}`}>
                {abookInitial?.IsPPUEnabled ? (
                  <label className="popover-content-width">PO Reference # <Popover
                  onVisibleChange={hidePopover}
                trigger={["click"]}
                placement="bottomLeft"
                content={"Purchase order number will be associated with each purchase made through the Pay per use process."}
                getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover></label>
            ) : null}
                  <Input
                    name="PORefNumber"
                    placeholder="PO Reference #"
                    className="card-input pt-8"
                    bordered={false}
                    value={
                      abookInitial?.IsPPUEnabled
                        ? abookInitial?.PORefNumber
                        : undefined
                    }
                    onChange={(e: any) => handleInput(e, 2)}
                    disabled={!abookInitial?.IsPPUEnabled}
                  />
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="a-center whitebg ptb-25 mt-12 ml-20 pl-20 pr-20">
              <Col span={10}>
                <span className="ppu-price-text ">Total Spent This Month:</span>
              </Col>
              <Col sm={5} md={8}>
                <div className="ppu-Middle-line"></div>
              </Col>
              <Col span={6} className="currencyWrapper">
                <span className="ppu-price-text ">
                  {currency}
                  {abookInitial?.IsPPUEnabled ? abookInitial?.RunningTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',') : 0}
                </span>
              </Col>
            </Row>
            <Row
              justify="space-between"
              className="a-center whitebg ptb-25 mt-12 ml-20 pl-20 pr-20"
            >
              <Col span={10}>
                <span className="ppu-price-text popover-content-width">
               Amount Currently in Reserve: <Popover
                trigger={["click"]}
                placement="bottomLeft"
                content={"Reserve amount reflects the books that were on hold but are now available for the patron to borrow but they have not done so yet."} 
                getPopupContainer={(trigger: any) => trigger.parentNode}
                onVisibleChange={hidePopover}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover>
                </span>
              </Col>
              <Col sm={5} md={8}>
                <div className="ppu-Middle-line"></div>
              </Col>
              <Col span={6} className="currencyWrapper">
                <span className="ppu-price-text ">
                  {currency}
                  {abookInitial?.IsPPUEnabled
                    ? abookInitial?.RunningReserveTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')
                    : 0}
                </span>
              </Col>
            </Row>
          </Col>
          <div className="whitebg footer-ppu">
            <Col span={5} offset={19} className="pb-25 patronBtnSize">
              <Button
                type={`primary confirm-button ${
                  saveBtnDisabled ? "ppu-btn" : ""
                }`}
                onClick={handleSave}
              >
                Save
              </Button>
            </Col>
          </div>
        </Row>
        <BudgetModel
          showModal={showModal}
          setShowModal={setShowModal}
          title={"Confirm Monthly Budget"}
          onConfirm={setModelConfirm}
          message1={"This update to your "}
          message2={"budget is lower than the amount already spent "}
          message3={
            "and in reserve for your patrons this month so the change will take place at the beginning of next month. This is to avoid affecting titles already allocated to your patrons."
          }
        />
      </SpinnerLoader>
    </>
  );
};
