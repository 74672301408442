import { Modal, Row, Col, Input } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { filterOptions } from "../../../constants/constants";
import { dropdownIcon } from "../../../images";
import { AppRoutes } from "../../../router/appRoutes";
import Button from "../../stateless/common/button";
import DropDown from "../../stateless/common/dropDown";

const { TextArea } = Input;

export const AutoShelves = (props: any) => {
  const { isAutoModalVisible, setAutoModalVisible } = props;
  const [filterVal, setFilterVal] = useState<string>("Title");
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [updateFields, setUpdateFields] = useState<any>({
    shelveName: "",
    shelveDescription: "",
  });

  useEffect(() => {
    if (updateFields.shelveName !== "") {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
    updateFields?.shelveDescription?.length > 100
      ? setDescriptionError(true)
      : setDescriptionError(false);
  }, [updateFields]);

  const history = useHistory();
  const handelFilter = (value: any) => {
    setFilterVal(value);
  };
  const onContinue = () => {
    if (!btnDisable && !descriptionError) {
      let state = {
        createAutoShelv: {
          shelveName: updateFields?.shelveName,
          shelveDescription: updateFields?.shelveDescription,
          SortStrategy: filterVal,
        },
      };
      history.push(AppRoutes.AUTOMATEDSHELF, state);
    }
  };
  const handleDetails = (e: any) => {
    setUpdateFields({
      ...updateFields,
      [`${e.target.name}`]: e?.target?.value,
    });
  };
  return (
    <Modal
      visible={isAutoModalVisible}
      centered
      wrapClassName="autoShelvesModal"
      onCancel={() => setAutoModalVisible(false)}
    >
      <div className="autoShelvesTitle">New Automated Shelf</div>

      <div className="mb-6 mt-6">
        <Row>
          <Col span={24}>
            <Input
              placeholder="Shelf name"
              className={`shelfTitle ${btnDisable ? "manualShelveField" : ""}`}
              value={updateFields.shelveName}
              name="shelveName"
              onChange={handleDetails}
              onPressEnter={onContinue}
            />
            {btnDisable ? (
              <div className="manualShelveError">Please enter a Shelf Name</div>
            ) : null}
          </Col>
        </Row>
      </div>

      <div className="mt-6 mb-6">
        <Row>
          <Col span={24}>
            <TextArea
              className={`scrollStyle isbnSearchScroll shelfDescription ${
                descriptionError ? "manualShelveField" : ""
              }`}
              maxLength={100}
              placeholder="Description"
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={handleDetails}
              onPressEnter={onContinue}
              name="shelveDescription"
              value={updateFields.shelveDescription}
            />
            {descriptionError && (
              <div className="manualShelveError">
                Cannot enter more than 100 characters
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div className="mt-6 mb-40">
        <Row>
          <Col span={24}>
            <DropDown
              bordered={false}
              className="autoShelvedropdown"
              optionValue={filterOptions.shelfFilters}
              value={filterVal}
              icon={dropdownIcon}
              onChange={handelFilter}
            />
          </Col>
        </Row>
      </div>
      <div className={`${btnDisable || descriptionError ? "manualShelfBtn" : ""}`}>
        <Button type="medium" onClick={() => onContinue()}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};
