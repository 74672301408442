import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getRoutPathName } from "../../../../redux/actions/app/appActions";

const GaWrap = (props: any) => {
  const { children } = props;
  const dispatch = useDispatch();
  const location = useLocation<any>();

  const [routeName, setRouteName] = useState<string>(location?.pathname);

  useEffect(() => {
    setRouteName(location?.pathname);
  }, [location]);

  useEffect(() => {
    dispatch(getRoutPathName(routeName))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeName]);

  return <>{children}</>;
};

export default GaWrap;
