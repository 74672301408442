/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  DatePicker,
  Form,
  Radio,
  Row,
  Col,
  message,
  Divider,
  Tag,
} from "antd";
import Button from "../../../../stateless/common/button";
import "./../marc.less";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMarcByDateRange,
  fetchStatusOfMarcTask,
  fetchMarcDownload,
  fetchLatestStatusOfTask,
} from "../../../../../../redux/actions/marc/marcActions";
import {
  clearState,
  MarcstateSelector,
  setContent,
  setFormat,
  setTimeFrameData,
  setCurrentCollection,
  setPreSale,
  resetFilters,
  setNotIncludeCollection,
} from "../../../../../../redux/reducers/marc/marcReducer";
import moment from "moment";
import { formatedDate } from "../../../../../../utility/appUtil";
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import SpinnerLoader from "../../../../stateless/common/spinnerLoader";

export const MarcRecordsCatalog = (props: any) => {
  var intervalIdRef = useRef<any | null>(null);
  const { libraryId, libraryName } = props;
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    latestStatusData,
    statusResult,
    formStateDateRange,
    formStateLatestStatus,
    formStateMarcStatus,
    dateRangeData,
    marcDownloadData,
    timeFrameData,
    contentRadios,
    format,
    preSales,
    currentCollection,
    notIncludeCollection,
  } = useSelector(MarcstateSelector);
  const [selectedContent, setSelectedContent] = useState(contentRadios); //"Ebook"
  const [timeFrame, setTimeFrame] = useState(timeFrameData); //"7Days"
  const [selectedFormat, setSelectedFormat] = useState(format); //"MRC"
  const [fullSetCurrentCollection, setFullSetCurrentCollection] =
    useState<string>(currentCollection); //"INCLUDE_ALL"
  const [presaleOnly, setPresaleOnly] = useState<boolean>(preSales); //false
  const [disableInclude, setDisbaleInclude] = useState<boolean>(false);
  const [notIncludeCollectionItems, setNotIncludeCollectionItems] =
    useState(notIncludeCollection); //false
  const { RangePicker } = DatePicker;
  const [taskId, setTaskId] = useState(null);


  const currentDate = moment();
  const [hideGenerateBtn, setHideGenerateBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const todayDate = formatedDate(currentDate.format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(
    formatedDate(currentDate.clone().subtract(7, "days").format("YYYY-MM-DD")) +
    "-00:00"
  );
  const [startDateValue, setStartDateVal] = useState(todayDate + "-00:00");
  const [endDateValue, setEndDateVal] = useState(
    formatedDate(currentDate.clone().subtract(7, "days").format("YYYY-MM-DD"))
  );
  const [endDate, setEndDate] = useState(todayDate + "-00:00");
  const [fileStartDt, setFileStartDt] = useState(
    formatedDate(currentDate.clone().subtract(7, "days").format("YYYY-MM-DD"))
  );
  const [fileEndDt, setFileEndDt] = useState(todayDate);
  const { appUser } = useSelector(userStateSelector);
  const [radioValue, setRadioValue] = useState("");

  /*generate MARC button function*/
  const onSubmit = () => {
    const payloadData = {
      libraryId: libraryId,
      userId: appUser?.userId,
      contentType: selectedContent,
      isPreSale: presaleOnly,
      expiredFilter: "EXCLUDE_ALL",
      existingFilter: fullSetCurrentCollection, //INCLUDE_ALL OR EXCLUDE_IF_OWNED
      format: selectedFormat,
      startDate: startDate,
      endDate: endDate,
      fileStartDt: fileStartDt,
      fileEndDt: fileEndDt,
      page: "",
      pageSize: "",
      currentDate: startDate && endDate ? "" : todayDate,
      todayDate: currentDate.format("YYYYMMDD"),
      libraryName: libraryName ? libraryName?.replaceAll(" ", "") : "",
    };
    dispatch(fetchMarcByDateRange(payloadData));
  };

  const handleContentChange = (e: any) => {
    setSelectedContent(e.target.value);
    dispatch(setContent(e.target.value));
  };

  useEffect(() => {
    if(timeFrameData=="7Days"){
      setEndDate(todayDate + "-00:00");
      setStartDate(
        formatedDate(
          currentDate.clone().subtract(7, "days").format("YYYY-MM-DD")
        ) + "-00:00"
      );
    }
    setSelectedContent(contentRadios);
    setSelectedFormat(format);
    setPresaleOnly(preSales);
    setFullSetCurrentCollection(currentCollection);
    setTimeFrame(timeFrameData);
    setNotIncludeCollectionItems(notIncludeCollection)
  }, [contentRadios, format, preSales, timeFrameData, currentCollection, notIncludeCollection]);

  const handleTimeFrameChange = (e: any) => {
    const value = e?.target?.value;
    dispatch(setTimeFrameData(value));
    setRadioValue(value);
    setDisbaleInclude(false);
    setTimeFrame(e.target.value);
    if (e.target.value === "7Days") {
      setEndDate(todayDate + "-00:00");
      setStartDate(
        formatedDate(
          currentDate.clone().subtract(7, "days").format("YYYY-MM-DD")
        ) + "-00:00"
      );
      setFileStartDt(
        currentDate.clone().subtract(7, "days").format("MM-DD-YYYY")
      );
      setFileEndDt(currentDate.format("MM-DD-YYYY"));
    } else if (e.target.value === "30Days") {
      setEndDate(todayDate + "-00:00");
      setStartDate(
        formatedDate(
          currentDate.clone().subtract(30, "days").format("YYYY-MM-DD")
        ) + "-00:00"
      );
      setFileStartDt(
        currentDate.clone().subtract(30, "days").format("MM-DD-YYYY")
      );
      setFileEndDt(currentDate.format("MM-DD-YYYY"));
    } else if (e.target.value === "thisMonth") {
      setStartDate(
        formatedDate(
          currentDate.clone().startOf("month").format("YYYY-MM-DD")
        ) + "-00:00"
      );
      setEndDate(
        formatedDate(currentDate.clone().endOf("month").format("YYYY-MM-DD")) +
        "-00:00"
      );
      setFileStartDt(currentDate.clone().startOf("month").format("MM-DD-YYYY"));
      setFileEndDt(currentDate.clone().endOf("month").format("MM-DD-YYYY"));
    } else if (e.target.value === "DateRange") {
      setFileStartDt("");
      setFileEndDt("");
      setStartDate("");
      setEndDate("");
    } else if (e.target.value === "INCLUDE_ALL") {
      setFullSetCurrentCollection("INCLUDE_ALL");
      dispatch(setCurrentCollection("INCLUDE_ALL"))
      setStartDate("");
      setEndDate("");
      setDisbaleInclude(true);
    } else {
      setStartDate("");
      setEndDate("");
      setFullSetCurrentCollection("EXCLUDE_ALL");
      dispatch(setCurrentCollection("EXCLUDE_ALL"))

    }
  };

  const handleChangeDateRange = (dates: any, dateStrings: any) => {
    setStartDateVal(dateStrings[0]);
    setEndDateVal(dateStrings[1]);
    setFileStartDt(moment(dateStrings[0]).format("MM-DD-YYYY"));
    setFileEndDt(moment(dateStrings[0]).format("MM-DD-YYYY"));
    setStartDate(dateStrings[0] ? formatedDate(dateStrings[0]) + "-00:00" : "");
    setEndDate(dateStrings[1] ? formatedDate(dateStrings[1]) + "-00:00" : "");
  };
  const handleOK = () => {
    dispatch(clearState());
    dispatch(resetFilters());
    if (intervalIdRef?.current !== null) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const handleFormatChange = (e: any) => {
    setSelectedFormat(e.target.value);
    dispatch(setFormat(e.target.value));
  };

  const handlePresaleOnly = (e: any) => {
    setPresaleOnly(e.target.checked);
    dispatch(setPreSale(e.target.checked));
  };

  const handleNotIncludeCollectionItems = (e: any) => {
    setNotIncludeCollectionItems(e.target.checked);
    dispatch(setNotIncludeCollection(e.target.checked))
    if (e.target.checked) {
      setFullSetCurrentCollection("EXCLUDE_IF_OWNED");
      dispatch(setCurrentCollection("EXCLUDE_IF_OWNED"));
    } else {
      setFullSetCurrentCollection("INCLUDE_ALL");
      dispatch(setCurrentCollection("INCLUDE_ALL"));
    }
  };

  /*after download api is success*/
  const handleDownload = () => {
    const payload = {
      id:
        statusResult?.taskDetails?.uuid ||
        latestStatusData?.data?.data?.taskDetails?.uuid,
      contentType:
        statusResult?.taskDetails?.contentType ||
        latestStatusData?.data?.data?.taskDetails?.contentType,
      libraryName:
        statusResult?.taskDetails?.libraryId ||
        latestStatusData?.data?.data?.taskDetails?.libraryId,
      isPreSale:
        statusResult?.taskDetails?.preSale ||
        latestStatusData?.data?.data?.taskDetails?.preSale,
      format:
        statusResult?.taskDetails?.format ||
        latestStatusData?.data?.data?.taskDetails?.format,
    };
    setLoading(true);
    dispatch(fetchMarcDownload(payload));
  };


  //Function for className of status
  const getTagClass = (
    statusResult: any,
    latestStatusData: any
  ) => {
    const isInProgressOrPending =
      statusResult?.status === "IN_PROGRESS" ||
      latestStatusData?.data?.data?.status === "IN_PROGRESS" ||
      statusResult?.status === "PENDING" ||
      latestStatusData?.data?.data?.status === "PENDING";
    if (isInProgressOrPending) return "Pending";
    const isNotFoundFailed =
      ((statusResult?.status === "FAILED" || latestStatusData?.data?.data?.status === "FAILED") &&
        (statusResult?.httpStatus === "NOT_FOUND" ||
          latestStatusData?.data?.data?.httpStatus === "NOT_FOUND"));
    if (isNotFoundFailed) return "Failed";
    const isFailed =
      statusResult?.status === "FAILED" || latestStatusData?.data?.data?.status === "FAILED";
    if (isFailed) return "Failed";
    const isComplete =
      statusResult?.status === "COMPLETE" || latestStatusData?.data?.data?.status === "COMPLETE";
    if (isComplete) return "complete";
    return "grey"; // Default color if none of the conditions are met
  };

  //function for status label
  const getTagLabel = (
    statusResult: any,
    latestStatusData: any
  ) => {
    const isNotFoundFailed =
      (statusResult?.status === "FAILED" &&
        statusResult?.httpStatus === "NOT_FOUND") ||
      (latestStatusData?.data?.data?.status === "FAILED" &&
        latestStatusData?.data?.data?.httpStatus === "NOT_FOUND");
    if (isNotFoundFailed) return "No Data Found";
    const isPending =
      statusResult?.status === "PENDING" || latestStatusData?.data?.data?.status === "PENDING";
    if (isPending) return "Pending";
    const isInProgress =
      statusResult?.status === "IN_PROGRESS" || latestStatusData?.data?.data?.status === "IN_PROGRESS";
    if (isInProgress) return "Processing";
    const isComplete =
      statusResult?.status === "COMPLETE" || latestStatusData?.data?.data?.status === "COMPLETE";
    if (isComplete) return "Complete";
    const isRead = statusResult?.status === "READ" || latestStatusData?.data?.data?.status === "READ";
    if (isRead) return "Read";
    const isFailed =
      statusResult?.status === "FAILED" || latestStatusData?.data?.data?.status === "FAILED";
    if (isFailed) return "Failed";

    return "";
  };


  /*after dateRange api gets */
  useEffect(() => {
    if (dateRangeData && dateRangeData?.data?.url) {
      setHideGenerateBtn(true);
      setLoading(true);
      if (dateRangeData?.data?.url && dateRangeData?.data?.url?.includes("id=")) {
        setTaskId(dateRangeData?.data?.url.split("id=")[1]);
        dispatch(
          fetchStatusOfMarcTask({
            libraryId: libraryId,
            taskId: dateRangeData?.data?.url.split("id=")[1],
          })
        );
      }
    } else {
      setHideGenerateBtn(false);
      setLoading(false);
    }
  }, [dateRangeData]);

  useEffect(() => {
    setLoading(false);
    if (
      (statusResult?.httpStatusCode == 200 ||
        statusResult?.httpStatusCode == 202 || statusResult?.httpStatusCode == 404)
    ) {
      if (
        statusResult?.status !== "COMPLETE" &&
        statusResult?.status !== "FAILED") {
        if (statusResult?.taskDetails?.uuid) setTaskId(statusResult?.taskDetails?.uuid)
        if (intervalIdRef.current != null) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
        const endDate = new Date(endDateValue);
        const startDate = new Date(startDateValue);
        let yearsDifference = endDate.getFullYear() - startDate.getFullYear();
        if (yearsDifference > 2 || radioValue === "INCLUDE_ALL") {
          if (intervalIdRef?.current) clearInterval(intervalIdRef.current);
          if (statusResult?.taskDetails?.uuid) {
            intervalIdRef.current = setInterval(() => {
              dispatch(
                fetchStatusOfMarcTask({
                  libraryId: libraryId,
                  taskId: statusResult?.taskDetails?.uuid,
                })
              );
            }, 60000);
          }
        } else {
          if (intervalIdRef.current) clearInterval(intervalIdRef.current);
          if (statusResult?.taskDetails?.uuid) {
            intervalIdRef.current = setInterval(() => {
              dispatch(
                fetchStatusOfMarcTask({
                  libraryId: libraryId,
                  taskId: statusResult?.taskDetails?.uuid,
                })
              );
            }, 10000);
          }
        }
        return () => {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        };
      } else {
        if (intervalIdRef?.current !== null) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
      }
    }
  }, [statusResult]);

  useEffect(() => {
    if (disableInclude) {
      setNotIncludeCollectionItems(false);
      dispatch(setNotIncludeCollection(false))
    }
  }, [disableInclude]);

  /*   To handle error for dateRange API*/
  useEffect(() => {
    if (
      dateRangeData &&
      dateRangeData?.length !== 0 &&
      dateRangeData?.data?.success === 0 &&
      dateRangeData?.data?.data?.message
    ) {
      setHideGenerateBtn(false);
      setLoading(false);
      message.error({
        content: dateRangeData?.data?.data?.message,
        key: "notificationMessage",
      });
    }
  }, [dateRangeData]);

  /*   check if loggedInUser has any task in queue or not if yes then show latest Status of task
   */
  useEffect(() => {
    if (appUser?.userId) {
      dispatch(
        fetchLatestStatusOfTask({
          libraryId: libraryId,
          userId: appUser?.userId,
        })
      );
    }
    setLoading(false);
    setHideGenerateBtn(false);
  }, []);

  /*  UseEffect ,when latestStatusData?.data?.data?.status API gets hit */
  useEffect(() => {
    if (
      latestStatusData?.data?.success === 1 &&
      latestStatusData?.data?.data
    ) {
      // setLoading(true);
      setHideGenerateBtn(true);
      // if ((latestStatusData?.data?.data?.status !== "COMPLETE" && latestStatusData?.data?.data?.status !== "FAILED") ) {
      setTaskId(latestStatusData?.data?.data?.taskDetails?.uuid);
      if (latestStatusData?.data?.data?.taskDetails?.uuid) {
        dispatch(
          fetchStatusOfMarcTask({
            libraryId: libraryId,
            taskId: latestStatusData?.data?.data?.taskDetails?.uuid,
          })
        );
      }
    } else {
      setLoading(false);
    }
  }, [latestStatusData]);

  /*  useEffect for when download api get hits successfully */
  useEffect(() => {
    if (marcDownloadData && marcDownloadData?.status === "SUCCESS") {
      setHideGenerateBtn(false);
      setLoading(false);
      dispatch(clearState());
      dispatch(resetFilters());
    } else if (
      marcDownloadData?.length === 0 ||
      marcDownloadData?.status === "ERROR"
    ) {
      setLoading(false);
      dispatch(clearState());
      // dispatch(resetFilters());
    }
  }, [marcDownloadData]);

  /* useEffect to disable the filters*/
  useEffect(() => {
    if (hideGenerateBtn) {
      setIsFormDisabled(true);
    } else if (!hideGenerateBtn) {
      setIsFormDisabled(false);
      // dispatch(resetFilters());
    }
  }, [hideGenerateBtn]);
  const TaskData = latestStatusData?.data?.data?.taskDetails;

  /*   to retain the filters value */
  useEffect(() => {
    if (latestStatusData && latestStatusData?.length !== 0) {
      const timestampStart = TaskData?.startDate;
      const timestampEnd = TaskData?.endDate;
      const diffInDays = moment(timestampEnd).diff(
        moment(timestampStart),
        "days"
      );
      let result;
      if (diffInDays === 7) {
        result = "7Days";
      } else if (diffInDays === 30) {
        result = "30Days";
      } else if (moment(timestampStart).isSame(moment(timestampEnd), "month")) {
        result = "thisMonth";
      } else if (Number.isNaN(diffInDays)) {
        result = "INCLUDE_ALL";
      } else result = "DateRange";

      dispatch(setTimeFrameData(result));
      dispatch(setContent(TaskData?.contentType));
      dispatch(setFormat(TaskData?.format));
      dispatch(setPreSale(TaskData?.preSale));
      dispatch(setCurrentCollection(TaskData?.existingFilter));
      if (TaskData?.existingFilter !== "INCLUDE_ALL") {
        dispatch(setNotIncludeCollection(true));
      }
    }
  }, [latestStatusData]);


  return (
    <SpinnerLoader
      loading={
        formStateDateRange.loading || formStateLatestStatus.loading || loading
      }
    >
      <div className="insightsMarcScreen">
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <Form
                  form={form}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  layout="horizontal"
                >
                  <Form.Item>
                    <span className="outerLabel">Content</span>
                    <Radio.Group
                      onChange={handleContentChange}
                      value={selectedContent}
                      disabled={isFormDisabled}
                    >
                      <Radio value="EBOOK"> eBooks </Radio>
                      <Radio value="AUDIOBOOK"> audioBooks </Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item label="" valuePropName="checked">
                    <Checkbox
                      disabled={isFormDisabled}
                      onChange={handlePresaleOnly}
                      checked={presaleOnly}
                      className={`fontStronger  pl-30 ${isFormDisabled && (preSales==true) ? 'checkbox-disabled' : ''}`}
                    >
                      Generate MARC for presale titles only
                    </Checkbox>
                  </Form.Item>

                  <Form.Item className="radioDisplyBlock">
                    <span className="outerLabel">Time Frame</span>
                    <Radio.Group
                      disabled={isFormDisabled}
                      onChange={handleTimeFrameChange}
                      value={timeFrame}
                    >
                      <Radio value="7Days"> Last 7 Days </Radio>
                      <Radio value="30Days"> Last 30 Days </Radio>
                      <Radio value="thisMonth"> This Month</Radio>
                      <Radio value="DateRange">
                        {" "}
                        Date Range{" "}
                        {timeFrame === "DateRange" ? (
                          <span>
                            <RangePicker
                              onChange={handleChangeDateRange}
                              className="Marc-datepicker"
                              disabled={isFormDisabled}
                            />
                          </span>
                        ) : null}
                      </Radio>
                      <Checkbox
                        disabled={isFormDisabled}
                        onChange={handleNotIncludeCollectionItems}
                        checked={notIncludeCollectionItems}
                        className={`fontStronger pl-30 mb-2 ${isFormDisabled && (notIncludeCollection === true) ? 'checkbox-disabled' : ''}`}
                        >
                        Do not include titles already in my Collection
                      </Checkbox>
                      <Radio value={"INCLUDE_ALL"}
                        disabled={isFormDisabled}>
                        {" "}
                        Full Set of Current Collection{" "}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item>
                    <span className="outerLabel">Format</span>
                    <Radio.Group
                      disabled={isFormDisabled}
                      onChange={handleFormatChange}
                      value={selectedFormat}
                    >
                      <Radio value="MRC"> MARC </Radio>
                      <Radio value="XML"> XML </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {
                    // Condition to show "Download MARC" button and task status messages
                    (hideGenerateBtn && !loading) ||
                      (statusResult?.data &&
                        statusResult?.length !== 0 &&
                        statusResult?.data?.success != 0) ||
                      (latestStatusData?.length !== 0 &&
                        latestStatusData &&
                        latestStatusData?.data?.data?.success != 0) ? (
                      <Row justify="center">
                        <Divider style={{ marginBottom: "2vh" }} />
                        <Col span={24}>
                          <span>
                            <span
                              className="outerLabel"
                              style={{ marginTop: "2vh" }}
                            >
                              Request Id
                            </span>
                            <span>
                              {taskId}
                            </span>
                          </span>
                        </Col>

                        <Col span={24}>
                          <span>
                            <span className="outerLabel">Status</span>
                            <Tag
                              className={getTagClass(
                                statusResult,
                                latestStatusData
                              )}
                            >
                              {getTagLabel(
                                statusResult,
                                latestStatusData
                              )}
                            </Tag>
                          </span>
                        </Col>

                        <Col span={24}>
                          {statusResult?.status === "PENDING" ||
                            latestStatusData?.data?.data?.status === "PENDING" ? (
                            <><p
                              className="outerLabel"
                              style={{ marginBottom: "25px"  }}
                            >
                              **Your MARC record request is pending. Navigating
                              away from this page will not affect processing.
                              Please return to this page to check the status and
                              download your file.
                            </p> <div className="marc-div"></div></>

                          ) : statusResult?.status === "IN_PROGRESS" ||
                            latestStatusData?.data?.data?.status === "IN_PROGRESS" ? (
                           <> <p className="outerLabel"  style={{ marginBottom: "25px" }}>
                              **Your MARC records are generating. Navigating
                              away from this page will not affect processing.
                              Please return to this page to check the status and
                              download your file.
                            </p>  <div className="marc-div"></div></>

                          ) : (statusResult?.status === "FAILED" &&
                              statusResult?.httpStatus != "NOT_FOUND") ||
                            (latestStatusData?.data?.data?.status ===
                              "FAILED" &&
                              latestStatusData?.data?.data?.httpStatus !=
                                "NOT_FOUND") ? (
                            <Row justify="center">
                              <Col span={24}>
                                <p
                                  className="outerLabel"
                                  style={{ marginBottom: "8px" }}
                                >
                                  **Your MARC record generation failed. You may
                                  try again or go to the Support menu and open a
                                  ticket containing the information above.
                                </p>
                              </Col>
                              <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                <Button
                                  type="primary marc-ok"
                                  onClick={handleOK}
                                >
                                  OK
                                </Button>
                              </Col>
                            </Row>
                          ) : (statusResult?.status === "FAILED" &&
                              statusResult?.httpStatus == "NOT_FOUND") ||
                            (latestStatusData?.data?.data?.status ===
                              "FAILED" &&
                              latestStatusData?.data?.data?.httpStatus ==
                                "NOT_FOUND" &&
                              latestStatusData?.data?.data?.httpStatusCode ==
                                404) ? (
                            <>
                              <p
                                className="outerLabel"
                                style={{ marginTop: "4vh",marginBottom: "8px"  }}
                              >
                                **There is no MARC data available for the
                                requested date range. Adjust your criteria and
                                resubmit your request.
                              </p>
                              <Row justify="center">
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  xxl={4}
                                >
                                  <Button
                                    type="primary marc-ok"
                                    onClick={handleOK}
                                  // className=""

                                  // style={{ marginTop: "8vh",marginBottom:"20px"}}
                                  >
                                    OK
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          ) : statusResult?.status === "COMPLETE" ||
                            latestStatusData?.data?.data?.status === "COMPLETE" ? (
                            <Row justify="center">
                              <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                <Button
                                  type="primary loginButton"
                                  onClick={handleDownload}
                                >
                                  Download MARC
                                </Button>
                                <div className="marc-div"></div>
                              </Col>
                            </Row>
                          ) : null}
                        </Col>
                      </Row>
                    ) : null
                  }

                  {
                    // Condition to show "Generate MARC" button
                    (latestStatusData?.length === 0 ||
                      statusResult?.length === 0) &&
                    !hideGenerateBtn && (
                      <Form.Item className="formItemInput mb-0 text-center">
                        <Button
                          disabled={
                            !(
                              (timeFrame && timeFrame !== "DateRange") ||
                              endDate !== ""
                            )
                          }
                          onClick={onSubmit}
                          type="primary loginButton"
                        >
                          Generate MARC
                        </Button>
                      </Form.Item>
                    )
                  }
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </SpinnerLoader>
  );
};
