export enum APIEnvironment {
  DEV = "DEV",
  QA = "QA",
  DEMO = "DEMO",
  PROD = "PROD",
}

export enum PortalModule {
  COMMANFORALL = "baseUrl",
  VERSION = "version",
  AUTH = "baseUrl",
  GROUPS = "baseUrl",
  CATEGORY = "baseUrl",
  CARTS = "baseUrl",
  SEARCH = "baseUrl",
  BOOKS = "baseUrl",
  USER = "baseUrl",
  ORGANIZATION = "baseUrl",
  ROLE = "baseUrl",
  EVENT ="baseUrl",
  AUDIT ="baseUrl",
  ALERT = "baseUrl",
  DEVICE = "baseUrl"
}
