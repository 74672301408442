/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip } from "antd";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { AppRoutes } from "../../../../router/appRoutes";
import { generatePath, useHistory } from "react-router-dom";
import { notFound } from "../../../../images";
import { useSelector } from "react-redux";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Empty } from 'antd';
import { groupstateSelector } from "../../../../../redux/reducers/groups/groupListReducer";
import { UseGAEventTracker } from "../../../../../utility/useGAEventTracker";
export const BookListSlider = (props: any) => {
  const {
    bookList,
    className,
    dynamiCount,
    handleNextItems,
    HandleResetStart,
    start,
  } = props;
  const { appUser } = useSelector(userStateSelector);
  const { hideOwnedSwtich } = useSelector(groupstateSelector);
  const history = useHistory();
  const [totalBooks, setTotalBooks] = useState<number>(0);
  const currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";

  const onSelecttitle = (row: any) => {
    history.push(AppRoutes.ITEMDETAILS, {
      book: row,
    });
  };

  const selectGroup = (selectedGroup: any) => {
    const state: any = {
      category: selectedGroup,
    };
    var id: any = state?.category?.FeatureListId
      ? state?.category?.FeatureListId
      : "";
    history.push(generatePath(AppRoutes.BOOKLIST, { id }), state);
    UseGAEventTracker(
      "Spotlight",
      "selected featured lists view all titles",
      `${selectedGroup?.FeatureListName}`,
      NaN
    );
  };

  useEffect(()=>{
    if(hideOwnedSwtich){
      setTotalBooks(bookList?.ownedBooks)
    }else{
      setTotalBooks(bookList?.totalBooks)
    }
  },[hideOwnedSwtich,bookList])

  return (
    <div className={className}>
      <div className="newRowC">
        <Row>
          <Col span={24}>
            <div className="card-books-container">
              {bookList?.books?.length > 0 ? (
                <Row>
                  <>
                    <Col className="left-arrow-container">
                      {start > 0 ? (
                        <div className="leftSideSLider">
                          <div className="Arrow-Slider-container">
                            <ArrowLeftOutlined
                              className="arrow-right"
                              onClick={() =>
                                HandleResetStart(bookList?.FeatureListId)
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                    </Col>
                    <Col className="card-books">
                      <SpinnerLoader loading={false}>
                        {bookList?.books
                          ?.slice(0, dynamiCount)
                          ?.map((item: any, index: any) => {
                            return (
                              <Tooltip
                                title={item?.Title}
                                key={index}
                                placement="bottom"
                              >
                                <div className="shelves-img-container">
                                  <img
                                    onClick={() => onSelecttitle(item)}
                                    src={item?.ImageUrl}
                                    alt="img"
                                    onError={(e: any) => {
                                      e.target.src = notFound;
                                    }}
                                  />
                                  <div className="shelve-img-transbox">
                                    <p className="shelve-img-title">
                                      {item?.Title}
                                    </p>
                                    <p className="shelve-img-price">
                                      {currency}
                                      {item?.RetailPrice.toFixed(2)}
                                    </p>
                                  </div>
                                </div>
                              </Tooltip>
                            );
                          })}
                      </SpinnerLoader>
                    </Col>
                    <Col className="rightSideSLider">
                      <Row>
                        <Col span={24}>
                          <span
                            className="view-all-link"
                            onClick={() => selectGroup(bookList)}
                          >
                            View All
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          {totalBooks? (
                            totalBooks > start + dynamiCount ? (
                              <div className="Arrow-Slider-container">
                                <ArrowRightOutlined
                                  className="arrow-right"
                                  onClick={() =>
                                    handleNextItems(bookList?.FeatureListId)
                                  }
                                />
                              </div>
                            ) : null
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </>
                </Row>
              ) : (
                <Row>
                  <Col span={24}><div><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div></Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
