/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSideBarItem } from "../../../../redux/actions/app/appActions";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import "../search/search.less";
import { AdvancedSearchForm } from "../search/advancedSearch/index";
import { IsbnSearch } from "../search/isbnSearch/index";
import {
  fetchAudienceData,
  fetchCategoryOptions,
  fetchContentProvidersNew,
  fetchLanguages,
  fetchMediaTypes,
  fetchPublisherWithRules,
  fetchppuBooksStatus,
  getUnmatchValues,
  onBackFillForm,
  onStringBackFillForm,
} from "../../../../redux/actions/search/searchActions";
import {
  SearchstateSelector,
  setSearchTab,
  clearSaveSearchResult,
  clearSearchScroll,
  clearString,
  clearAdvanceSearchData
} from "../../../../redux/reducers/search/searchReducer";
import Category from "../../../models/search/category";
import { clearBooklistCurrentPage } from "../../../../redux/reducers/books/bookListReducer";
import { setSelectedTitles, setGlobalCopy } from "../../../../redux/actions/books/bookActions";
import Button from "../../stateless/common/button";
import { infoIcon } from "../../../images";

export const CollectionSearch = React.memo((props: any) => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { appUser } = useSelector(userStateSelector);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedKey, setSelectedKey] = useState("1");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showLoading, setShowLoading] = useState(false);
  const [showShowPpuBooks, setShowPpuBooks] = useState<any>([]);
  const [clearAdvancSearch, setClearAdvancSearch] = useState<boolean>(false);
  const {
    audienceData,
    providerDataNew,
    publishersDataNew,
    mediaTypes,
    languages,
    categoriesOptions,
    selectedTab,
    saveSearchResults,
    backFormFill,
    resetSearchForm,
    ppuBooksStatus
  } = useSelector(SearchstateSelector);
  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;

  useEffect(() => {
    dispatch(setSideBarItem("My Collection"));
    if (!(audienceData && audienceData.length)) {
      dispatch(fetchAudienceData());
    }
    if (!(providerDataNew && providerDataNew.length)) {
      dispatch(fetchContentProvidersNew());
    }
    if (!(publishersDataNew && publishersDataNew.length)) {
      dispatch(fetchPublisherWithRules());
    }
    if (!(mediaTypes && mediaTypes.length)) {
      dispatch(fetchMediaTypes());
    }
    if (!(languages && languages.length)) {
      dispatch(fetchLanguages());
    }
    if (!(categoriesOptions && categoriesOptions.length)) {
      dispatch(fetchCategoryOptions());
    }
    if(backFormFill?.isbn !== undefined){
      dispatch(setSearchTab('2'));
    }else{
      dispatch(setSearchTab('1'));
    }
    dispatch(setGlobalCopy(1))
    dispatch(setSelectedTitles([]))
    dispatch(fetchppuBooksStatus([]))
  }, []);

  const getAudienceOptions = React.useMemo(() => {
    var audienceOptions = audienceData?.map((data: any) => {
      return { text: data.AgeClassificationId === 2 ? "Young Adult" : data.Name , value: data.AgeClassificationId };
    });
    return audienceOptions;
  },[audienceData]);

  const getProviderOptions = React.useMemo(() => {
    let providerOptions = [];
    let providers = providerDataNew?.ContentProviders
      ? providerDataNew?.ContentProviders
      : [];
    if (providers) {
      providerOptions = providers?.map((data: any) => {
        return {
          text: (
            <>
              {data.Name + " "}
              {data.rules ? (
                <span>
                  <img
                    className="cloud-link-info-icon"
                    src={infoIcon}
                    alt="info"
                  />
                  {' '+data.rules}
                </span>
              ) : null}
            </>
          ),
          value: data.ContentProviderId,
          title: (
            <>
              {data.Name + " "}
              {data.rules ? (
                <span>
                  <img
                    className="cloud-link-info-icon"
                    src={infoIcon}
                    alt="info"
                  />
                  {' '+data.rules}
                </span>
              ) : null}
            </>
          ),
          titleB: data.Name + " " + data.rules,
        };
      });
      return providerOptions;
    }
  },[providerDataNew]);
  const getPublisherOptions = React.useMemo(() => {
    let publisherOptions = [];
    let providers = publishersDataNew?.ContentProviders
      ? publishersDataNew?.ContentProviders
      : [];
    if (providers) {
      publisherOptions = providers?.map((data: any) => {
        return {
          text: (
            <>
              {data.PublisherName + " "}
              {data.rules ? (
                <span>
                  <img
                    className="cloud-link-info-icon"
                    src={infoIcon}
                    alt="info"
                  />
                  {' '+data.rules}
                </span>
              ) : null}
            </>
          ),
          value: data.PublisherId,
          title: (
            <>
              {data.PublisherName + " "}
              {data.rules ? (
                <span>
                  <img
                    className="cloud-link-info-icon"
                    src={infoIcon}
                    alt="info"
                  />
                  {' '+data.rules}
                </span>
              ) : null}
            </>
          ),
          titleB: data.PublisherName + " " + data.rules,
        };
      });
      return publisherOptions;
    }
  },[publishersDataNew]);
  const getLanguageOptions = React.useMemo(() => {
    var languageOptions = languages
    ?.filter((language: any) => !(language.LanguageName === "Not Specified" && language.LanguageCode !== "n/a"))
    ?.map((data: any) => {
      return { title: data.LanguageName, value: data.LanguageCode, titleB: data.LanguageName };
    });
    return languageOptions;
  },[languages]);
  const getCategoryOptions = React.useMemo(() => {
    var CategoryOptions = categoriesOptions?.map((data: Category) => {
      return { text: data.CategoryName, value: data.CategoryId };
    });
    return CategoryOptions;
  },[categoriesOptions]);
  const handleSelect = (e: any) => {
    dispatch(clearSearchScroll());
    dispatch(setSearchTab(e));
    if(saveSearchResults?.length !== 0){
      dispatch(clearSaveSearchResult())
      dispatch(getUnmatchValues([]))
      dispatch(onBackFillForm([]))
      dispatch(onStringBackFillForm([]))
    }
    if(e === "1"){
      setClearAdvancSearch(true)
      dispatch(clearString())
      dispatch(clearAdvanceSearchData());
      dispatch(onStringBackFillForm([]));
      dispatch(onBackFillForm([]));
    }
  };

  useEffect(()=>{
    dispatch(clearSearchScroll());
    dispatch(clearBooklistCurrentPage())
return(()=>{
  dispatch(clearSaveSearchResult())
})
  },[])

  const clickAction = (id : string) =>{
    const element = document.getElementById(id);
    if(element){
      element.click();
    }
  }

  useEffect(()=>{
    if(resetSearchForm){
      setClearAdvancSearch(true)
      dispatch(clearString())
      dispatch(clearAdvanceSearchData());
      dispatch(onStringBackFillForm([]));
      dispatch(onBackFillForm([]));
    }
  },[resetSearchForm])

  useEffect(()=>{
     if(ppuBooksStatus){
       setShowPpuBooks(ppuBooksStatus)
     }
  },[ppuBooksStatus])

  return (
    <div>
      <Row className="head-container">
        <Col xs={12} sm={14} md={18} lg={20} xl={20}>
          <p className="page-title">My Collection</p>
        </Col>
      </Row>
      <Tabs
        className="search-tabs"
        defaultActiveKey={selectedTab}
        activeKey={selectedTab}
        onChange={handleSelect}
        tabBarExtraContent={
          selectedTab === "1" &&
          <Row justify="end" className="search-top">
            <Col span={5} className="clear-button" order={2}>
              <input
                type="button"
                className="primary"
                value="Clear"
                onClick={() => clickAction("advanced-clear-btn")}
              />
            </Col>
            <Col span={5} className="action-button primarySearch" order={3}>
              <Button
                type="primary"
                onClick={() => clickAction("advanced-search-btn")}
              >
                Search
              </Button>
            </Col>
          </Row>
        }
      >
        <TabPane
          tab={
            saveSearchResults?.SearchSaveId !== undefined
              ? saveSearchResults?.SearchSaveName !== ""
                ? `Edit Advanced Search`
                : `Advanced Search`
              : `Advanced Search`
          }
          key="1"
        >
          <AdvancedSearchForm
            audienceDataOptions={getAudienceOptions}
            providerOptions={getProviderOptions}
            publisherOptions={getPublisherOptions}
            languageOptions={getLanguageOptions}
            categoryOptions={getCategoryOptions}
            formatOptions={mediaTypes}
            setKey={setSelectedKey}
            libraryId={lib_Id}
            setShowLoading={setShowLoading}
            isCollection={true}
            clearForm={clearAdvancSearch}
            showPpuField={showShowPpuBooks}
          />
        </TabPane>
        {saveSearchResults?.SearchSaveId !== undefined? <></> : (
          <>
            <TabPane tab="ISBN" key="2">
              <IsbnSearch isCollection={true} libraryId={lib_Id} />
            </TabPane>
          </>
        )}
      </Tabs>
    </div>
  );
});


