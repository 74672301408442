import { createAsyncThunk } from "@reduxjs/toolkit";
import { LibAuthInput } from "../../../web/models/user/libAuthInput";
import { UserAuthInput } from "../../../web/models/user/userAuthInput";
import { AuthService } from "../../../web/services/user/authService";

const authSvc = new AuthService();

export const checkEmail = createAsyncThunk(
  "login",
  async (user: UserAuthInput, thunkAPI) => {
    try {
      const data = await authSvc.checkEmail(user);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const checkLibrarianEmail = createAsyncThunk(
  "login",
  async (user: LibAuthInput, thunkAPI) => {
    try {
      const data = await authSvc.checkLibrarianEmail(user);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const logOut = createAsyncThunk(
  "api/logout",
  async (user:any,thunkAPI) => {
    try {
      const data = await authSvc.logOut({username:user});
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
export const SingleSignOn = createAsyncThunk(
  "SingleSignOn",
  async (id: string, thunkAPI) => {
    try {
      const data = await authSvc.singleSignOn(id);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (userId: string, thunkAPI) => {
    try {
      const data = await authSvc.forgotPassword(userId);
      if (data?.ErrorCode === undefined) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data?.ErrorMessage);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (reqBody: any, thunkAPI) => {
    try {
      const data = await authSvc.resetPassword(reqBody);
      if (data?.ErrorCode === undefined) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data?.ErrorMessage);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const libraryInitialPassword = createAsyncThunk(
  "libraryInitialPassword",
  async (reqBody: any, thunkAPI) => {
    try {
      const data = await authSvc.libraryInitialPassword(reqBody);
      if (data?.ErrorCode === undefined) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data?.ErrorMessage);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);
