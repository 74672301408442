import { AppRoutes } from "../router/appRoutes"

export const DashbordItems = [
    {
        name: "Insights",
        url: AppRoutes.INSIGHT,
        allowed:true,
        showTag:true,
        isLink:false
    },
]

export const CloudStorageItems = [
    {
        name: "Spotlight",
        url: AppRoutes.SPOTLIGHTLANDING,
        allowed:true,
        showTag:true,
        isLink:false
    },
    {
        name: "Curated Lists",
        url: AppRoutes.LANDING,
        allowed:true,
        showTag:false,
        isLink:false
    },
    {
        name: "Carts",
        url: AppRoutes.CART,
        allowed:true,
        showTag:false,
        isLink:false
    },
    {
        name: "Search",
        url: AppRoutes.SEARCH,
        allowed:true,
        showTag:false,
        isLink:false
    },
]

export const ManagementItems = [
    {
        name: "My Collection",
        url: AppRoutes.COLLECTION,
        allowed:true,
        show:true,
        isLink:false
    },
    {
        name: "Shelves",
        url: AppRoutes.SHELVES,
        allowed:true,
        show:true,
        isLink:false
    },
    {
        name: "Patrons",
        url: AppRoutes.PATRONS,
        allowed:true,
        show:true,
        isLink:false
    },
    {
        name: "Messages",
        url: AppRoutes.MESSAGES,
        allowed:true,
        show:true,
        isLink:false
    },
    {
        name: "Reports",
        url: AppRoutes.INSIGHT,
        allowed:true,
        showTag:true,
        isLink:false
    },
    {
        name: "Purchases",
        url: AppRoutes.PURCHASES,
        allowed:true,
        show:true,
        isLink:false
    },
    {
        name: "Support",
        url: AppRoutes.SUPPORT,
        allowed:true,
        show:true,
        isLink:false
    },
    {
        name: "Resources",
        url: 'https://www.oclc.org/go/en/cloudlibrary-marketing-toolkit.html',
        allowed:true,
        show:true,
        isLink:true
    },
]
export const Settings = [
    {
        name: "Settings",
        url: AppRoutes.SETTINGS,
        allowed:true,
        show:true
    },
]
