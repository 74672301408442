import { Page } from "./constants"

export const searchString = {
    title: "",
    author: "",
    narrator: "",
    category: "",
    subCategory: "",
    audience: "",
    language: "",
    keyword: "",
    series: "",
    persaleTitle: "",
    HoldRatio: "",
    MaximumPriceAmount: "",
    MinimumPriceAmount: "",
    publisher: "",
    publisheWithin: "",
    provider: "",
    dateAdedToCl: "",
    formats: "all",
    filters: "",
    publishDays: "",
}

export const searchBody = {
    SearchSaveId:"",
    SearchSaveName:"",
    AuthorSearch:[],
    Narrator:"",
    CategorySearch:[],
    FromPublisherDate:"",
    HoldRatio:"",
    ItemFormatId:0,
    Language:"",
    MaximumPriceAmount:"",
    MinimumPriceAmount:"",
    ExcludeSelfPublished:false,
    PublisherSearch:[],
    QuickSearch:[],
    series: null,
    SubCategorySearch:[],
    TitleDaysOld:0,
    PreSaleDays:0,
    TitleSearch:[],
    ToPublisherDate:"",
    TargetAudienceIds:[],
    ContentProviderSearch:[],
    AddedToCatalogDateFrom:"",
    AddedToCatalogDateTo:"",
    AddedToCatalogDaysOld:0,
    ItemFormatIdSet:["1", "2", "3"],
    SearchSaveDescription:"",
    SharingStatusCode: "P",
    LibraryId: 0,
    NotInCollection: false,
    EISBN: "",
    ChangeDateTime:"",
    ChangeUserId: "",
    CreateDateTime: "",
    CreateUserId: "",
    AllowedSharingStatusCodes: ["P"],
    MediaTypeIdSet: [],
    CatalogId: 0,
    recordCount: Page.DEFAULT_COUNT,
    recordStart: Page.DEFAULT_START,
    sortField: "",
    sortDirection: "",
}
