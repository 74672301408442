/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable eqeqeq */
import { Row, Col, Modal, Input } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmPurchase,
  deleteCartbyId,
  fetchCarts,
  markReady,
  fetchSelectedCartById,
  cancelSelectedCartItems
} from "../../../../../../redux/actions/carts/cartAction";
import {
  CartStateSelector,
  clearReady,
  setCartReady,
} from "../../../../../../redux/reducers/carts/cartsReducer";
import { userStateSelector } from "../../../../../../redux/reducers/user/userReducer";
import { UseGAEventTracker } from "../../../../../../utility/useGAEventTracker";
import Button from "../../../../stateless/common/button";
import SpinnerLoader from "../../../../stateless/common/spinnerLoader";

export const PurchaseConfirmModal = (props: any) => {
  const dispatch = useDispatch();
  const {
    showPurchase,
    cartData,
    setShowPurchase,
    numberofCopies,
    setPurchased,
    libId,
    currency,
    selectedItemsData,
    numberOfTitles,
    totalAmount,
    setKeepActive,
    isPaginationOn,
    setCurrentPage,
    setStartCount,
    buyAllCart
  } = props;
  const {
    cartReady,
    selectedCart,
    buyerCart,
    formState,
    fetchCartByIdFormState,
    addItemtoCartFormstate,
    selectedCartIds,
    selectedCatlogIds,
    viewerData,
    buyerCartByIdformState,
  } = useSelector(CartStateSelector);
  const { appUser } = useSelector(userStateSelector);
  const [keepCart, setKeepCart] = useState(true);
  const [ref, setRef] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const [totalAmountSum, setTotalAmountSum] = useState<any>();
  const [titlePurchaseSum, setTitlePurchaseSum] = useState<any>();
  const [titlePreSaleSum, setTitlePreSaleSum] = useState<any>();
  const [copiesPurchaseSum, setCopiesPurchaseSum] = useState<any>();
  const [copiesPreSaleSum, setCopiesPreSaleSum] = useState<any>();
  const [totalPreSaleAmountSum, setTotalPreSaleAmountSum] = useState<any>();
  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  const BookState = "PRE_SALE";

  useEffect(() => {
    var viewData = viewerData?.CartDetails
    if( selectedCart?.CartOwner === appUser?.email || selectedCart?.IsCommunityCart){
      var fiterSelectedRow = cartData?.filter((item: any) => {
        return selectedCartIds?.includes(JSON.stringify(item?.CartDetailId));
      });
    }else{
      var fiterSelectedRow = viewData?.filter((item: any) => {
        return selectedCatlogIds?.includes(JSON.stringify(item?.CatalogItemId));
      });
    }
    if (fiterSelectedRow?.length !== 0) {
      var fiterSelectedRowTitlePreSale = fiterSelectedRow?.filter(
        (item: any) => {
          return item?.ItemState == BookState;
        }
      );
      var fiterSelectedRowTitlePurchase = fiterSelectedRow?.filter(
        (item: any) => {
          return item?.ItemState !== BookState;
        }
      );
      var fiterSelectedRowPreSaleQuantity = fiterSelectedRow?.map(
        (item: any) => {
          if (item?.ItemState == BookState) {
            return item?.Quantity;
          } else {
            return 0;
          }
        }
      );
      var fiterSelectedRowPurchase = fiterSelectedRow?.map((item: any) => {
        if (item?.ItemState !== BookState) {
          return item?.Quantity;
        } else {
          return 0;
        }
      });
      var viewerTotalCopiesAmount = fiterSelectedRow?.map((item: any) => {
        if (item?.ItemState !== BookState) {
          return (item?.Quantity) * (item?.RetailPrice);
        } else {
          return 0;
        }
      });
      var fiterSelectedRowPreSalePrice = fiterSelectedRow?.map((item: any) => {
        if (item?.ItemState == BookState) {
          return item?.totalPrice;
        } else {
          return 0;
        }
      });
      setTitlePreSaleSum(fiterSelectedRowTitlePreSale?.length);
      setCopiesPreSaleSum(
        fiterSelectedRowPreSaleQuantity?.reduce((a: any, b: any) => a + b, 0)
      );
      setTotalPreSaleAmountSum(
        fiterSelectedRowPreSalePrice?.reduce((a: any, b: any) => a + b, 0)
      );
      setTitlePurchaseSum(fiterSelectedRowTitlePurchase?.length);
      setCopiesPurchaseSum(
        fiterSelectedRowPurchase?.reduce((a: any, b: any) => a + b, 0)
      );
    }
    if( selectedCart?.CartOwner === appUser?.email || selectedCart?.IsCommunityCart){
      var ItemsPrice = fiterSelectedRow?.map((price: any) => {
        return price?.totalPrice;
      });
      setTotalAmountSum(ItemsPrice?.reduce((a: any, b: any) => a + b, 0));
    }else{
      var ItemsPrice = fiterSelectedRow?.map((price: any) => {
        return price?.RetailPrice;
      });
      setTotalAmountSum(viewerTotalCopiesAmount?.reduce((a: any, b: any) => a + b, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCartIds,cartData,viewerData]);

  useEffect(() => {
    if (
      formState?.loading == false &&
      fetchCartByIdFormState?.loading == false &&
      addItemtoCartFormstate?.loading == false &&
      buyerCartByIdformState?.loading == false
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [
    formState?.loading,
    fetchCartByIdFormState?.loading,
    addItemtoCartFormstate?.loading,
    buyerCartByIdformState?.loading,
    showPurchase
  ]);

  useEffect(()=>{
    setKeepActive(keepCart)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[keepCart])

  useEffect(() => {
    if (cartReady) {
      dispatch(clearReady());
      dispatch(
        confirmPurchase({
          id: libId,
          ExternalRefId: ref,
        })
      );
      setRef(null);
      if (!keepCart && ((selectedCart?.CartName)?.toLowerCase() !== "mycart")) {
        setKeepActive(false)
        dispatch(deleteCartbyId(selectedCart?.CartId));
        setShowPurchase(false);
        setPurchased(true);
      }else{
        dispatch(fetchCarts({
            libId,
          appuser:appUser?.email
        }));
        setShowPurchase(false);
        setPurchased(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartReady]);

  var totalCopies: any = document.querySelector(
    ".purchase-modal .PurchaseTotalCopies"
  )?.innerHTML?.split('(')[0];

  var totalAmounts: any = document.querySelector(
    ".purchase-modal .PurchaseTotalAmount"
  )?.innerHTML?.split('(')[0];

  // var actionLabel = `Purchased ${selectedCart?.CartName} ${
  //   totalAmounts ? totalAmounts : ""
  // } total copies ${totalCopies ? totalCopies : ""}by Librarian (${
  //   selectedCart?.CartOwner
  // }) for ${
  //   appUser && appUser.libraryData
  //     ? appUser?.libraryData[0]?.LibraryName
  //     : ""
  // }`
  var actionLabel = `Purchased confirmed with selected ${selectedCart?.CartName}, total amount ${
    totalAmounts ? totalAmounts : ""
  }, total copies ${totalCopies ? totalCopies : ""}by Librarian for ${
    appUser && appUser.libraryData
      ? appUser?.libraryData[0]?.LibraryName
      : ""
  }`

  const handleSubmit = () => {
    if (
      formState?.loading == false &&
      fetchCartByIdFormState?.loading == false &&
      addItemtoCartFormstate?.loading == false &&
      buyerCartByIdformState?.loading == false
    ) {
    if(!isPaginationOn){
      if (selectedItemsData?.CartId !== undefined && selectedItemsData?.CartDetailBuyerSave?.length !== selectedCart?.NumberOfTitles) {
        if(selectedCart?.CartOwner === appUser?.email || selectedCart?.IsCommunityCart){
            dispatch(fetchSelectedCartById(selectedItemsData));
        }else{
          dispatch(setCartReady())
          }
        } else {
          dispatch(
            markReady({
              cartId: selectedCart?.CartId,
            })
          );
        }
    }else{
      if(selectedCart?.CartOwner === appUser?.email || selectedCart?.IsCommunityCart){
          dispatch(fetchSelectedCartById(selectedItemsData));
      }else{
        dispatch(setCartReady())
        }
      if(selectedItemsData?.CartDetailBuyerSave?.length === cartData?.length) {
        setStartCount(1)
        setCurrentPage(1)
      }
      }
      UseGAEventTracker(
        "Cart details",
        "Purchase Confirm",
        JSON.stringify(actionLabel),
        totalCopies ? totalCopies : ""
      );
    }
  };

  const handleSubmitAllCart =()=>{
    if (
      formState?.loading == false &&
      fetchCartByIdFormState?.loading == false &&
      addItemtoCartFormstate?.loading == false &&
      buyerCartByIdformState?.loading == false
    ) {
      dispatch(
        markReady({
          cartId: selectedCart?.CartId,
        })
      );
      UseGAEventTracker(
        "Cart details",
        "Purchase Confirm",
        JSON.stringify(actionLabel),
        totalCopies ? totalCopies : ""
      );
    }
  };

  const onCancelModel = () => {
    setKeepActive(true)
    setKeepCart(true)
    if(selectedCart?.CartOwner === appUser?.email || selectedCart?.IsCommunityCart ){
      if (
        fetchCartByIdFormState?.loading == false &&
        addItemtoCartFormstate?.loading == false &&
        buyerCartByIdformState?.loading == false
      ){ 
        setShowPurchase(false);
      }
    }else{
      if(!buyAllCart){
        var cancelPayload = {
          IsCommunityCart: selectedCart?.IsCommunityCart,
          LibraryId: lib_Id,
          SaveAction: "S",
          CartDescription: "",
          CartId: buyerCart?.CartId,
          CartName: buyerCart?.CartName,
          AssociateShelfId: selectedCart?.AssociateShelfId,
          CartDetail:[],
          CartDetailBuyerSave: buyerCart?.CartDetail?.length === 0 ? buyerCart?.CartDetail?.map((item: any) => {
                  return {
              ShopperCartDetailId:item?.CartDetailId,
                    BuyerCartDetailId: "",
              Quantity:0,
                    SaveAction: "D",
                  };
          }) : buyerCart?.CartDetail?.map((item: any) => {
                  return {
              ShopperCartDetailId:item.CartDetailId,
                    BuyerCartDetailId: "",
              Quantity:0,
                    SaveAction: "D",
                  };
                }),
        };
        dispatch(cancelSelectedCartItems(cancelPayload))
      }else{
        setShowPurchase(false);
      }

      }
  };

  const handleRefChange = (e: any) => {
    setRef(e.target.value);
  };
  const handleChekBoxChange = (e: any) => {
    if (e.target.checked) {
      setKeepCart(true);
    } else {
      setKeepCart(false);
    }
  };

  const roundofNumber = (x: any) => {
    let num = Number.parseFloat(x).toFixed(2);
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')
  };

  return (
    <Modal
      wrapClassName="purchase-modal"
      visible={showPurchase}
      onCancel={onCancelModel}
      footer={
        <>
        {
          buyAllCart?
            <Row justify="center">
          <Button type="primary confirm-button" onClick={handleSubmitAllCart}>
                Confirm Purchase
              </Button>
        </Row>:
            <Row justify="center">
              <Button type="primary confirm-button" onClick={handleSubmit}>
                Confirm Purchase
              </Button>
            </Row>
        }

          {
            (selectedCart?.CartName)?.toLowerCase() !== "mycart" ?
            <Row justify="center" style={{ paddingTop: "30px" }}>
              <Checkbox checked={keepCart} onChange={handleChekBoxChange}>
                <span>
                  Keep <b>{selectedCart?.CartName}</b> active after purchase
                </span>
              </Checkbox>
            </Row>
          : null
          }
          <Row justify="center">
            <Button type="cancel-button removeModalBtn" onClick={onCancelModel}>
              Cancel
            </Button>
          </Row>
        </>
      }
    >
      <SpinnerLoader loading={loading}>
        <p className="purchase-title">Purchase Confirmation</p>
        <p className="purchase-description">
          Due to the nature of digital materials, we cannot accept a return of a
          digital file. Please be sure you are satisfied with your selections
          before clicking Buy to complete your purchase.
        </p>
        <p className="purchase-description">
          Taxes are not included in the pricing shown. Any applicable taxes will be
          applied at the time of invoicing.
        </p>
        <div className="purchase-content">
        {selectedCart?.CartOwner === appUser?.email || selectedCart?.IsCommunityCart || buyAllCart?
            <>
              <Row>
                <Col span={8}>
                  <p className="footer-label">Total Titles:</p>
                </Col>
                <Col span={16}>
                  <p className="footer-label">
                    {selectedCartIds?.length !== 0 ? (
                      <>
                        {titlePurchaseSum + titlePreSaleSum} ({titlePurchaseSum}{" "}
                        purchases / {titlePreSaleSum} presale)
                      </>
                    ) : (
                      <>
                    {selectedCart?.NumberOfTitles} ({numberOfTitles} purchases /{" "}
                    {selectedCart?.NumberOfPrepubTitles} presale)
                      </>
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <p className="footer-label">Total Copies:</p>
                </Col>
                <Col span={16}>
                  <p className="footer-label PurchaseTotalCopies">
                    {selectedCartIds?.length !== 0 ? (
                      <>
                    {copiesPurchaseSum + copiesPreSaleSum} ({copiesPurchaseSum}{" "}
                    purchases/ {copiesPreSaleSum} presale)
                      </>
                    ) : (
                      <>
                    {selectedCart?.NumberofCopies} ({numberofCopies} purchases /{" "}
                    {selectedCart?.NumberOfPrepubCopies} presale)
                      </>
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <p className="footer-label">
                    <b>Total Amount:</b>
                  </p>
                </Col>
                <Col span={16}>
                  <p className="footer-label">
                    {selectedCartIds?.length !== 0 ? (
                      <>
                        <b className="PurchaseTotalAmount">
                          {currency}
                          {roundofNumber(totalAmountSum)}
                        </b>
                        <span>
                      ({roundofNumber(totalAmountSum - totalPreSaleAmountSum)}{" "}
                          purchases / {roundofNumber(totalPreSaleAmountSum)}{" "}
                          presale)
                        </span>
                      </>
                    ) : (
                      <>
                        <b className="PurchaseTotalAmount">
                          {currency}
                          {selectedCart?.TotalCartAmount}{" "}
                        </b>
                        <span>
                          ({totalAmount} purchases /{" "}
                          {selectedCart?.TotalCartPrepubAmount} presale)
                        </span>
                      </>
                    )}
                  </p>
                </Col>
              </Row>
            </>
        :
            <>
              {/* if restricted */}
              <Row>
                <Col span={8}>
                  <p className="footer-label">Total Titles:</p>
                </Col>
                <Col span={16}>
                  <p className="footer-label">
                    {viewerData?.NumberOfTitles !== undefined ? (
                      <>
                    {viewerData?.NumberOfTitles} ({viewerData?.NumberOfTitles - viewerData?.NumberOfPrepubTitles}{" "}
                        purchases / {viewerData?.NumberOfPrepubTitles} presale)
                      </>
                    ) : (
                      <>
                    {selectedCart?.NumberOfTitles} ({numberOfTitles} purchases /{" "}
                    {selectedCart?.NumberOfPrepubTitles} presale)
                      </>
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <p className="footer-label">Total Copies:</p>
                </Col>
                <Col span={16}>
                  <p className="footer-label">
                    {viewerData?.NumberofCopies !== undefined ? (
                      <>
                    {viewerData?.NumberofCopies} ({viewerData?.NumberofCopies - viewerData?.NumberOfPrepubCopies}{" "}
                        purchases/ {viewerData?.NumberOfPrepubCopies} presale)
                      </>
                    ) : (
                      <>
                    {selectedCart?.NumberofCopies} ({numberofCopies} purchases /{" "}
                    {selectedCart?.NumberOfPrepubCopies} presale)
                      </>
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <p className="footer-label">
                    <b>Total Amount:</b>
                  </p>
                </Col>
                <Col span={16}>
                  <p className="footer-label">
                    {viewerData?.TotalCartAmount !== undefined ? (
                      <>
                        <b>
                          {currency}
                      {(viewerData?.TotalCartAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g,','))}
                        </b>
                        <span>
                      ({viewerData?.TotalCartAmount - viewerData?.TotalCartPrepubAmount}{" "}
                          purchases / {viewerData?.TotalCartPrepubAmount}{" "}
                          presale)
                        </span>
                      </>
                    ) : (
                      <>
                        <b>
                          {currency}
                          {selectedCart?.TotalCartAmount}{" "}
                        </b>
                        <span>
                          ({totalAmount} purchases /{" "}
                          {selectedCart?.TotalCartPrepubAmount} presale)
                        </span>
                      </>
                    )}
                  </p>
                </Col>
              </Row>
            </>
         }
          <p className="purchaseNote">
            <b>NOTE:</b> Presale pricing is subject to change up until the street
            date.
          </p>
          <Row style={{ paddingTop: "38px" }}>
            <Col span={10}>
              <p className="footer-label">
                <b>PO Reference Number :</b>
              </p>
            </Col>
            <Col span={12}>
              <Input
                placeholder="(Optional)"
                value={ref}
                onChange={handleRefChange}
              />
            </Col>
          </Row>
        </div>
      </SpinnerLoader>
    </Modal>
  );
};
