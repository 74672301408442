// Base Imports
import React from "react";

// Package Imports
import { Spin } from "antd";
import {LoadingOutlined} from "@ant-design/icons"

// Other Imports

const ComponentLoader = (props: any) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return <Spin spinning={props.loading} className={props.className} tip="Loading..." indicator={antIcon}>{props.children}</Spin>;
};

export default ComponentLoader;
