import moment from "moment";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
  return buildDate;
};

export const convertAndDownloadBlob =async(response,fileName)=>{
  const blob = await response.blob(); // create a new Blob object.
  const url = window.URL.createObjectURL(blob); // create a new object url
  const a = document.createElement("a"); // create a new anchor element
  a.href = url; // set its href to the object URL
  a.download = `${fileName}`;  // set its download attribute to the deisred filename.
  a.click(); // programmatically clicking the anchor element to trigger the file download.
}
