import React from 'react'
import { Form, Input } from 'antd'
import './customInput.style.less'
import './customPassword.style.less'

const CustomPassword = (props: any) => {
  return (
    <Form.Item
      label={props.labelSubName}
      name={props.labelName}
      rules={props.rules}
      className={`mb-0 ${props.customLabelClass}`}
    >
      <Input.Password
        placeholder={props.placeholder}
        name={props.name}
        className="customPassword"
        visibilityToggle={false}
      />
    </Form.Item>
  )
}

export default CustomPassword
