import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import {fetchCheckedGroupIds, fetchContentCorner, fetchGroupByType, fetchGroupList, fetchUnshownGroups, retainSpotlightPosition, retainSwtichValue, selectGroups, unSelectGroups } from "../../actions/groups/groupActions";
import { GroupListState } from "../../states/groups/groupState";
import { RootState } from "../../store/rootReducer";

const initialState: GroupListState = {
  groups: [],
  groupType: [],
  unShownroups: [],
  checkedGroups: [],
  prevCheckedGroups:[],
  formState: resetState(),
  chkGrpformState: resetState(),
  selectformState: resetState(),
  unSelectformState: resetState(),
  landingScroll:0,
  ContentformState:resetState(),
  ContentCorner:[],
  retainSpotlightPos:{
    featurlistId: 0,
    sliderStart: 0,
    featurlistIndex:0,
    FeaturedListName:0
  },
  isThereNewsLetter:false,
  hideOwnedSwtich:false
};

export const GroupSlice = createSlice({
  name: "groups",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      state.chkGrpformState = resetState();
      state.unSelectformState = resetState();
      state.selectformState = resetState();
      return state;
    },
    clearRetainSpotlightPos: (state) => {
      state.retainSpotlightPos = {
        featurlistId: 0,
        sliderStart: 0,
        featurlistIndex:0
      };
      return state;
    },
    clearContentCornerState: (state) => {
      state.ContentformState = resetState();
      return state;
    },
    clearHideOwnedSwtich: (state) => {
      state.hideOwnedSwtich = false
      return state;
    },
    clearNewsLetterState: (state) => {
      state.isThereNewsLetter =false;
      return state;
    },
    clearContentCorner: (state) => {
      state.ContentCorner = [];
      return state;
    },
    clearContentCornerAll: (state) => {
      state.ContentformState = resetState();
      state.ContentCorner = [];
      return state;
    },
    clearGroups: (state) => {
      state.groups = [];
      state.unShownroups = [];
      state.groupType = [];
      state.checkedGroups = [];
      state.prevCheckedGroups = [];
      return state;
    },
    setLandingPageScroll: (state,{ payload}) => {
      state.landingScroll = payload;
      return state;
    },
  },
  extraReducers: {
    [fetchGroupList.fulfilled.toString()]: (state, { payload }) => {
      if(payload && payload.length){
        state.groups = payload
      };
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
      }
      return state;
    },
    [fetchGroupList.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchGroupList.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },

    [fetchUnshownGroups.fulfilled.toString()]: (state, { payload }) => {
      if(payload && payload.length){
        state.unShownroups = payload
      };
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
      }
      return state;
    },
    [fetchUnshownGroups.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [fetchUnshownGroups.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },

    [fetchGroupByType.fulfilled.toString()]: (state, { payload }) => {
      if(payload && payload.length){
        state.groupType = payload
      };
      if (payload.errorMsg) {
        state.chkGrpformState = errorState(payload.errorMsg);
      } else {
        state.chkGrpformState = fulfilledState();
      }
      return state;
    },
    [fetchGroupByType.pending.toString()]: (state) => {
      state.chkGrpformState = pendingState();
      return state;
    },
    [fetchGroupByType.rejected.toString()]: (state, { payload }) => {
      state.chkGrpformState = rejectedState(payload);
      return state;
    },

    [fetchCheckedGroupIds.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.chkGrpformState = errorState(payload.errorMsg);
      } else {
        state.checkedGroups = payload
        state.prevCheckedGroups = payload
        state.chkGrpformState = fulfilledState();
      }
      return state;
    },
    [fetchCheckedGroupIds.pending.toString()]: (state) => {
      state.chkGrpformState = pendingState();
      return state;
    },
    [fetchCheckedGroupIds.rejected.toString()]: (state, { payload }) => {
      state.chkGrpformState = rejectedState(payload);
      return state;
    },

    [selectGroups.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.selectformState = errorState(payload.errorMsg);
      } else {
        state.checkedGroups = payload
        state.selectformState = fulfilledState();
      }
      return state;
    },
    [selectGroups.pending.toString()]: (state) => {
      state.selectformState = pendingState();
      return state;
    },
    [selectGroups.rejected.toString()]: (state, { payload }) => {
      state.selectformState = rejectedState(payload);
      return state;
    },

    [unSelectGroups.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.unSelectformState = errorState(payload.errorMsg);
      } else {
        state.checkedGroups = payload
        state.unSelectformState = fulfilledState();
      }
      return state;
    },
    [unSelectGroups.pending.toString()]: (state) => {
      state.unSelectformState = pendingState();
      return state;
    },
    [unSelectGroups.rejected.toString()]: (state, { payload }) => {
      state.unSelectformState = rejectedState(payload);
      return state;
    },

    [fetchContentCorner.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.ContentformState = errorState(payload.errorMsg);
      } else {
        state.ContentCorner = payload
        state.isThereNewsLetter = payload?.NewsLetterDetail?.length > 0?true:false
        state.ContentformState = fulfilledState();
      }
      return state;
    },
    [fetchContentCorner.pending.toString()]: (state) => {
      state.ContentformState = pendingState();
      return state;
    },
    [fetchContentCorner.rejected.toString()]: (state, { payload }) => {
      state.ContentformState = rejectedState(payload);
      state.isThereNewsLetter = false
      return state;
    },
  
    [retainSpotlightPosition.fulfilled.toString()]: (state, { payload }) => {
      state.retainSpotlightPos = payload
      return state;
    },
    [retainSwtichValue.fulfilled.toString()]: (state, { payload }) => {
      state.hideOwnedSwtich = payload
      return state;
    },
  },
});

export const { clearState, clearGroups,setLandingPageScroll,clearContentCornerState,clearContentCorner,clearContentCornerAll,clearRetainSpotlightPos,clearNewsLetterState,clearHideOwnedSwtich } = GroupSlice.actions;
export const groupstateSelector = (state: RootState) => state.groups;
export default GroupSlice.reducer;