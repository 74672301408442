import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import { fetchBooks,fetchCartsByBookId, fetchBookbyId, onApplyingFilters, pageRememberBooklist, selectAllRemember, setCheckedValues, setSelectAllChkBox, setSelectedTitles, setGlobalCopy, isPageChange, fetchBooksForSpotLight } from "../../actions/books/bookActions";
import { BookState } from "../../states/books/bookState";
import { RootState } from "../../store/rootReducer";

const initialState: BookState = {
  totalCount: 0,
  ownedBooks: 0,
  books: [],
  searchParam:[],
  selectedBook: null,
  carts: {},
  formState: resetState(),
  fetchBooksFormState: resetState(),
  filters: {},
  FeatureListName:"",
  booklistCurrentPage: {},
  currentPageFormState: resetState(),
  globalSelectAll:false,
  checkedValues:[],
  selectAllChkBox:true,
  selectedTitles:[],
  bookGlobalValue:1,
  isPageChanges:{
    isPageChanges:false,
    isSelectAll:false
  },
  fetureListData:[]
};

export const BookSlice = createSlice({
  name: "books",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      state.books =[];
      state.FeatureListName ="";
      state.totalCount = 0;
      state.ownedBooks = 0
      state.isPageChanges = {
        isPageChanges:false,
        isSelectAll:false
      }
      return state;
    },
    clearSelected: (state) => {
      state.selectedBook = null
      return state;
    },
    clearSelectedChkBox: (state) => {
      state.checkedValues =[];
      state.selectAllChkBox = true
      return state;
    },
    emtySelectedChkBox: (state) => {
      state.checkedValues =[];
      state.selectAllChkBox = false;
      state.selectedTitles=[];
      state.selectedTitles=1;
      return state;
    },
    clearCartsByBookId: (state) => {
      state.carts = {}
      return state
    },
    clearFilters: (state) => {
      state.filters = {}
      return state
    },
    clearBooklistCurrentPage: (state) => {
      state.booklistCurrentPage = {start:1,currentPage:1}
      return state
    },
    cleaFetureListData: (state) => {
      state.fetureListData = []
      return state
    },
  },
  extraReducers: {
    [fetchBooks.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.fetchBooksFormState = errorState(payload.errorMsg);
      } else {
        state.fetchBooksFormState = fulfilledState();
        state.totalCount = payload.totalBooks
        state.books = payload.books;
        state.ownedBooks = payload.ownedBooks;
        state.FeatureListName = payload.FeatureListName
      }
      return state;
    },
    [fetchBooks.pending.toString()]: (state) => {
      state.fetchBooksFormState = pendingState();
      return state;
    },
    [fetchBooks.rejected.toString()]: (state, { payload }) => {
      state.fetchBooksFormState = rejectedState();
      return state;
    },
    [fetchBooksForSpotLight.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.fetchBooksFormState = errorState(payload.errorMsg);
      } else {
        state.fetchBooksFormState = fulfilledState();
        state.fetureListData = payload
      }
      return state;
    },
    [fetchBooksForSpotLight.pending.toString()]: (state) => {
      state.fetchBooksFormState = pendingState();
      return state;
    },
    [fetchBooksForSpotLight.rejected.toString()]: (state, { payload }) => {
      state.fetchBooksFormState = rejectedState();
      return state;
    },
    [fetchCartsByBookId.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.carts = {...state.carts, [payload.book] : payload.carts} 
      }
      return state;
    },
    [fetchCartsByBookId.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [fetchBookbyId.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
      } else {
        state.formState = fulfilledState();
        state.selectedBook = payload
      }
      return state;
    },
    [fetchBookbyId.pending.toString()]: (state, { payload }) => {
      state.formState = pendingState();
      return state;
    },
    [fetchBookbyId.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState();
      return state;
    },
    [onApplyingFilters.fulfilled.toString()]: (state, { payload }) => {
      state.filters = payload
      state.formState = fulfilledState();
      return state;
    },
    [onApplyingFilters.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [onApplyingFilters.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },
    [pageRememberBooklist.fulfilled.toString()]: (state, { payload }) => {
      state.booklistCurrentPage = payload
      state.currentPageFormState = fulfilledState();
      return state;
    },
    [pageRememberBooklist.pending.toString()]: (state) => {
      state.currentPageFormState = pendingState();
      return state;
    },
    [pageRememberBooklist.rejected.toString()]: (state, { payload }) => {
      state.currentPageFormState = rejectedState(payload);
      return state;
    },
    [selectAllRemember.fulfilled.toString()]: (state, { payload }) => {
      state.globalSelectAll = payload
      return state;
    },
    [setCheckedValues.fulfilled.toString()]: (state, { payload }) => {
      state.checkedValues = payload
      return state;
    },
    [setSelectAllChkBox.fulfilled.toString()]: (state, { payload }) => {
      state.selectAllChkBox = payload
      return state;
    },
    [setSelectedTitles.fulfilled.toString()]: (state, { payload }) => {
      state.selectedTitles = payload
      return state;
    },
    [setGlobalCopy.fulfilled.toString()]: (state, { payload }) => {
      state.bookGlobalValue = payload
      return state;
    },
    [isPageChange.fulfilled.toString()]: (state, { payload }) => {
      state.isPageChanges = {
        isPageChanges:payload.isPageChanges,
        isSelectAll:payload.isSelectAll
      }
      return state;
    },
  },
});

export const { clearState, clearSelected, clearCartsByBookId, clearFilters, clearBooklistCurrentPage,clearSelectedChkBox,emtySelectedChkBox,cleaFetureListData } = BookSlice.actions;
export const BookStateSelector = (state: RootState) => state.Books;
export default BookSlice.reducer;