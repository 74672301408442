/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Divider, Row } from "antd";
import React from "react";
import CoverImg from "../../../../assets/images/catSupportCover.jpeg";
import "./support.less";

export const Support = (props: any) => {
  return (
    <div className="supportConatiner scrollContent scrollStyle search-results-list scrollOnBooksScreen">
      <Row>
        <Col span={24}>
          <div className="coverImage">
            <img alt="cover" src={CoverImg} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={23}>
          <div className="titleSupport titleSupport-1">
            <h2><span><img alt="icon" src="https://cloudlibrary.atlassian.net/rest/servicedesk/1/customer/viewport-resources/portal-logo/3/5" /></span>&nbsp;cloudLibrary ServiceDesk</h2>
          </div>
          <div className="titleSupport titleSupport_2">
            <h1>
              <h1>Need Support ?</h1>
            </h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <div className="titleSupportSpan titleSupportSpan-1">
            <p>Already have a cloudLibrary</p>
            <p>ServiceDesk account</p>
            <div className="titleSupportLink_1">
              <a href="https://oclc.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noreferrer">
                CLICK HERE
              </a>
            </div>
          </div>
        </Col>
        <Divider type="vertical" />
        <Col span={11}>
          <div className="titleSupportSpan titleSupportSpan-2">
            <p>First time accessing</p>
            <p>cloudLibrary ServiceDesk</p>
            <div className="titleSupportLink_2">
              Email :
              <a href="mailto: help@oclc.atlassian.net" target="_blank" rel="noreferrer">
               &nbsp;help@oclc.atlassian.net
              </a>
            </div>
            <p>When you receive a confirmation email from cloudLibrary ServiceDesk, open the email, click <strong>View Request</strong> and follow the prompts.</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
