// Base Imports
import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { fetchCartsOnLandingPage } from "../../../../redux/actions/carts/cartAction";
import { clearSessionChk } from "../../../../redux/reducers/carts/cartsReducer";
import { loggedInRedirectRoute } from "../../../../utility/appUtil";

// Other Imports

export const PublicRoute = (props: any) => {
  const { isAuthenticated, appUser,showSpotLight } = props;
  const dispatch = useDispatch();
  if (isAuthenticated && appUser) {
    const libID =  appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
    dispatch(clearSessionChk())
    dispatch(fetchCartsOnLandingPage({
      libId:libID,
      appuser:appUser?.email
    }))
    // dispatch(fetchShelves(libID));
  }
  return  isAuthenticated ? (
    <Redirect to={loggedInRedirectRoute(showSpotLight,appUser)} />
  ) : (
    <Route {...props} component={props.component} render={undefined} />
  );
};

