/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Row,
  Col,
  List,
  Empty,
} from "antd";
import SpinnerLoader from "../../stateless/common/spinnerLoader";


export const MessagesList = (props: any) => {
  const { 
    handleDelete, srcData, loading } =  props;

  const onDelete = (data: any) => {
    handleDelete(data);
  };

  let listHeight = (window?.innerHeight - 200)+"px"

  return (
    <SpinnerLoader
    loading={loading}
  >
    <div className="messageListViewWrapper scrollStyle" style={{"height":listHeight}}>
    <List
      dataSource={srcData}
      className="messageListView"
      locale={{emptyText:<Empty description="No results found."/>}}
      renderItem={(item: any) => (
        <div className="messageStyle">
          <Row className="messBox">
            <Col span={18} className="">
              <div className="messBoxHed">{item?.Subject}</div>
              <div className="messBoxText">{item?.Message}</div>
              <div className="messWhen">
                <span className="messWhenClr">
                  <label className="messWhenClrLabel">Start: </label>
                  {item?.StartDate.slice(0,10)}
                </span>
                <span className="messWhenClr">
                  <label className="messWhenClrLabel">&nbsp; End: </label>
                    {item?.EndDate.slice(0,10)}
                </span>
              </div>
            </Col>
            <Col span={6} className="messBoxRight">
              <div className="messWhen messWhenCreated textRight">Created on {item?.CreatedOn}
              </div>
              <div className="textRight">
                {/* <span className="updtBtn" onClick={() => onUpdate(item)}>
                  Update
                </span>{" "} */}
                <span
                  className="deleBtn updtBtn"
                  onClick={() => onDelete(item)}
                >
                  Delete
                </span>
              </div>
            </Col>
          </Row>
        </div>
      )}
    />
    </div>
    </SpinnerLoader>
  );
};
