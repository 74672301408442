/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Col, message, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../stateless/common/button";
import "./shelves.less";

import { ShelvesModal } from "./shelvesModal";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { fetchShelves } from "../../../../redux/actions/shelves/shelfAction";
import {
  clearState,
  ShelfstateSelector,
} from "../../../../redux/reducers/shelves/shelfReducer";
import { EditModal } from "./ShelvesDetails/editModal";
import { AllShelves } from "./allShelvesIndex";

export const Shelves = () => {
  const dispatch = useDispatch();
  const { appUser } = useSelector(userStateSelector);
  const {
    shelvDetailFormState,
    shelvDetailById,
    // selectedTab,
    updateShlvFormState,
  } = useSelector(ShelfstateSelector);
  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  const [shelv, setShelv] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<boolean>(false);
  const [isEditModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [showEditModel, setShowEditModel] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [screenSize, getDimension] = useState<any>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const [getDynamiCount, setGetDynamiCount] = useState<number>(15);

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const openCreateShelf = (checked: any) => {
    setShowModal(true);
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    if (parseInt(screenSize?.dynamicWidth) > 2000) {
      setGetDynamiCount(15);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 2000) {
      setGetDynamiCount(14);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 1838) {
      setGetDynamiCount(13);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 1725) {
      setGetDynamiCount(12);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 1618) {
      setGetDynamiCount(11);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 1504) {
      setGetDynamiCount(10);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 1390) {
      setGetDynamiCount(9);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 1276) {
      setGetDynamiCount(8);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 1200) {
      setGetDynamiCount(7);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 1054) {
      setGetDynamiCount(6);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 942) {
      setGetDynamiCount(5);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 830) {
      setGetDynamiCount(4);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 718) {
      setGetDynamiCount(3);
    }
    if (parseInt(screenSize?.dynamicWidth) <= 718) {
      setGetDynamiCount(2);
    }
  }, [screenSize?.dynamicWidth]);

  useEffect(() => {
    dispatch(fetchShelves(lib_Id));
  }, [lib_Id]);

  useEffect(() => {
    if (shelvDetailFormState?.loading) {
      setEditModalVisible(false);
    }
  }, [shelvDetailFormState]);

  useEffect(() => {
    if (showEditModel) {
      setEditModalVisible(true);
    } else {
      setEditModalVisible(false);
    }
  }, [showEditModel]);

  useEffect(() => {
    if (updateShlvFormState?.isSuccess) {
      message.success({
        content: "Shelf edited successfully",
        key: "notificationMessage",
      });
      setEditModalVisible(false);
    }
  }, [updateShlvFormState]);

  return (
    <div>
      {/* <SpinnerLoader loading={formState.loading}> */}
      <Row className="head-container">
        <Col xs={12} sm={14} md={18} lg={20} xl={20}>
          <p className="page-title">Shelves</p>
        </Col>
        <Col xs={12} sm={10} md={6} lg={4} xl={4}></Col>
      </Row>
      <div className="shleves-header hideShelvsTabs">
        <AllShelves
          libId={lib_Id}
          imageUrl={appUser?.imageUrl}
          isModalOpen={showModal}
          dynamiCount={getDynamiCount}
          setShelvData={setShelv}
          setShowEditMode={setShowEditModel}
          isEditModalOpen={isEditModalVisible}
          unSelectRow={selectedRow}
        />
        <div className="shelves-header-buttons">
          <Row className="shelv-header-btnBox">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {/* <div className="shelv-leftBtn">
                <Button type="primary new-cart">Patron Preview</Button>
              </div> */}
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="shelv-rightBtn">
                <Button
                  type="primary new-cart"
                  onClick={() => openCreateShelf(true)}
                >
                  Create Shelf
                </Button>
              </div>
            </Col>
            <ShelvesModal
              isModalVisible={showModal}
              setModalVisible={setShowModal}
            />
          </Row>
        </div>
      </div>
      <Row>
        <Col span={24}></Col>
      </Row>
      <EditModal
        shelvData={shelv}
        shelvDetailsById={shelvDetailById}
        isAutoModalVisible={isEditModalVisible}
        setAutoModalVisible={setEditModalVisible}
        setRowUnselect={setSelectedRow}
      />
      {/* </SpinnerLoader> */}
    </div>
  );
};
