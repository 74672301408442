import ReactGA from "react-ga4";

export const UseGAEventTracker = (
  category: string,
  action: any,
  label: any,
  value: number
) => {
  if (!isNaN(value)) {
    return ReactGA.event({
      category:category,
      action:action,
      label:label,
      value:value
    });
  } else {
    return ReactGA.event({
      category:category,
      action:action,
      label:label
    });
  }
};
