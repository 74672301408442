/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSideBarItem } from "../../../../redux/actions/app/appActions";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import "./search.less";
import { AdvancedSearchForm } from "./advancedSearch";
import { IsbnSearch } from "./isbnSearch";
import {
  fetchAudienceData,
  fetchCategoryOptions,
  fetchContentProviders,
  fetchContentProvidersNew,
  fetchLanguages,
  fetchMediaTypes,
  fetchPublisherWithRules,
  fetchPublishers,
  getUnmatchValues,
  onBackFillForm,
  onStringBackFillForm,
} from "../../../../redux/actions/search/searchActions";
import {
  SearchstateSelector,
  setSearchTab,
  clearSaveSearchResult,
  clearRetainValue,
  clearSearchScroll,
  clearString,
  clearAdvanceSearchData,
  clearMatchIsbnData,
  clearResults,
  resetSearchFormState,
} from "../../../../redux/reducers/search/searchReducer";
import { SavedSearchList } from "./savedSearch";
import Category from "../../../models/search/category";
import { clearBooklistCurrentPage } from "../../../../redux/reducers/books/bookListReducer";
import {
  selectAllRemember,
  setGlobalCopy,
  setSelectedTitles,
} from "../../../../redux/actions/books/bookActions";
import Button from "../../stateless/common/button";
import { infoIcon } from "../../../images";

export const Search = React.memo((props: any) => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { appUser } = useSelector(userStateSelector);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedKey, setSelectedKey] = useState("1");
  const [showLoading, setShowLoading] = useState(false);
  const [clearAdvancSearch, setClearAdvancSearch] = useState<boolean>(false);
  const {
    audienceData,
    providerDataNew,
    mediaTypes,
    languages,
    categoriesOptions,
    selectedTab,
    saveSearchResults,
    backFormFill,
    resetSearchForm,
    publishersDataNew
  } = useSelector(SearchstateSelector);
  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;

  useEffect(() => {
    dispatch(setSideBarItem("Search"));
    if (!(audienceData && audienceData.length)) {
      dispatch(fetchAudienceData());
    }
    if (!(providerDataNew && providerDataNew.length)) {
      dispatch(fetchContentProvidersNew());
    }
    if (!(publishersDataNew && publishersDataNew.length)) {
      dispatch(fetchPublisherWithRules());
    }
    // if (!(publishers && publishers.length)) {
    //   dispatch(fetchPublishers());
    // }
    if (!(mediaTypes && mediaTypes.length)) {
      dispatch(fetchMediaTypes());
    }
    if (!(languages && languages.length)) {
      dispatch(fetchLanguages());
    }
    if (!(categoriesOptions && categoriesOptions.length)) {
      dispatch(fetchCategoryOptions());
    }
    if (backFormFill?.isbn !== undefined) {
      dispatch(setSearchTab("2"));
      dispatch(clearRetainValue());
    } else if (backFormFill?.savedId !== undefined) {
      dispatch(setSearchTab("3"));
    } else {
      dispatch(setSearchTab("1"));
      dispatch(clearRetainValue());
    }
  }, []);

  const getAudienceOptions = React.useMemo(() => {
    return audienceData?.map((data: any) => {
      return {
        text: data.AgeClassificationId === 2 ? 'Young Adult' : data.Name,
        value: data.AgeClassificationId,
      };
    });
  }, [audienceData]);
  

const getPublisherOptions = React.useMemo(() => {
  if (!publishersDataNew) return [];
  let publisherOptions = [];
  let providers = publishersDataNew?.ContentProviders ?? [];
  if (providers.length > 0) {
    publisherOptions = providers?.map((data: any) => {
      return {
        text: (
          <>
            {data.PublisherName + " "}
            {data.rules ? (
              <span>
                <img
                  className="cloud-link-info-icon"
                  src={infoIcon}
                  alt="info"
                />
                {' ' + data.rules}
              </span>
            ) : null}
          </>
        ),
        value: data.PublisherId,
        title: (
          <>
            {data.PublisherName + " "}
            {data.rules ? (
              <span>
                <img
                  className="cloud-link-info-icon"
                  src={infoIcon}
                  alt="info"
                />
                {' ' + data.rules}
              </span>
            ) : null}
          </>
        ),
        titleB: data.PublisherName + " " + data.rules,
      };
    });
  }

  return publisherOptions;
}, [publishersDataNew]);

  const getProviderOptions = React.useMemo(() => {
    let providerOptions = [];
        let providers = providerDataNew?.ContentProviders ?? [];
      if (providers.length > 0) {
      providerOptions = providers
        ?.filter((data: any) => data?.IsActive !== false) 
        ?.map((data: any) => {
          return {
            text: (
              <>
                {data.Name + " "}
                {data.rules ? (
                  <span>
                    <img
                      className="cloud-link-info-icon"
                      src={infoIcon}
                      alt="info"
                    />
                    {' ' + data.rules}
                  </span>
                ) : null}
              </>
            ),
            value: data.ContentProviderId,
            title: (
              <>
                {data.Name + " "}
                {data.rules ? (
                  <span>
                    <img
                      className="cloud-link-info-icon"
                      src={infoIcon}
                      alt="info"
                    />
                    {' ' + data.rules}
                  </span>
                ) : null}
              </>
            ),
            titleB: data.Name + " " + data.rules,
          };
        });
    }
      return providerOptions;
  }, [providerDataNew]);
  

  // const getPublisherOptions = () => {
  //   var publisherOptions = publishers?.map((data: any) => {
  //     return { text: data.PublisherName, value: data.PublisherId };
  //   });
  //   return publisherOptions;
  // };
  const getLanguageOptions = React.useMemo(() => {
    var languageOptions = languages
    ?.filter((language: any) => !(language.LanguageName === "Not Specified" && language.LanguageCode !== "n/a"))
    ?.map((data: any) => {
        return {
          title: data.LanguageName,
          value: data.LanguageCode,
          titleB: data.LanguageName,
        };
      });
    return languageOptions;
  },[languages]);

  const getCategoryOptions = React.useMemo(() => {
    var CategoryOptions = categoriesOptions?.map((data: Category) => {
      return { text: data.CategoryName, value: data.CategoryId };
    });
    return CategoryOptions;
  },[categoriesOptions]);

  const handleSelect = (e: any) => {
    setTimeout(()=>{
      dispatch(resetSearchFormState())
    },2000)
    dispatch(clearMatchIsbnData());
    dispatch(selectAllRemember(false));
    dispatch(setSelectedTitles([]));
    dispatch(setGlobalCopy(1));
    dispatch(clearBooklistCurrentPage());
    dispatch(clearSearchScroll());
    dispatch(setSearchTab(e));
    if (saveSearchResults?.length !== 0) {
      dispatch(getUnmatchValues([]));
      dispatch(clearSaveSearchResult());
      dispatch(onBackFillForm([]));
      dispatch(onStringBackFillForm([]));
      dispatch(clearRetainValue());
    }
    if (e === "1") {
      setClearAdvancSearch(true);
      dispatch(clearString());
      dispatch(clearAdvanceSearchData());
      dispatch(onStringBackFillForm([]));
      dispatch(onBackFillForm([]));
    }
  };

  useEffect(() => {
    dispatch(clearBooklistCurrentPage());
    // dispatch(selectAllRemember(false))
    return () => {
      dispatch(clearSaveSearchResult());
    };
  }, []);

  useEffect(() => {
    if (selectedTab !== "3") {
      dispatch(clearSearchScroll());
      dispatch(clearRetainValue());
      dispatch(clearResults());
    }
  }, [selectedTab]);

  const clickAction = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.click();
    }
  };

  useEffect(() => {
    if (resetSearchForm) {
      setClearAdvancSearch(true);
      dispatch(clearString());
      dispatch(clearAdvanceSearchData());
      dispatch(onStringBackFillForm([]));
      dispatch(onBackFillForm([]));
    }
  }, [resetSearchForm]);

  useEffect(() => {
    if (saveSearchResults?.SearchSaveId && saveSearchResults?.SearchSaveName) {
      setTimeout(() => {
        dispatch(resetSearchFormState());
      }, 1000);
    }
  }, [saveSearchResults]);

  return (
    <div>
      <Row className="head-container">
        <Col xs={12} sm={14} md={18} lg={20} xl={20}>
          <p className="page-title">Cloud Store Search</p>
        </Col>
      </Row>
      <Tabs
        className="search-tabs"
        defaultActiveKey={selectedTab}
        activeKey={selectedTab}
        onChange={handleSelect}
        tabBarExtraContent={
          selectedTab === "1" &&
          saveSearchResults?.SearchSaveId === undefined && (
            <Row justify="end" className="search-top">
              <Col span={5} className="clear-button" order={2}>
                <input
                  type="button"
                  className="primary"
                  value="Clear"
                  onClick={() => clickAction("advanced-clear-btn")}
                />
              </Col>
              <Col span={5} className="action-button primarySearch" order={3}>
                <Button
                  type="primary"
                  onClick={() => clickAction("advanced-search-btn")}
                >
                  Search
                </Button>
              </Col>
              <Col span={4} className="action-button linkButton" order={1}>
                <input
                  type="button"
                  className="Search_Save_btn"
                  value="Save Search"
                  onClick={() => clickAction("advanced-save-search-btn")}
                />
              </Col>
            </Row>
          )
        }
      >
        <TabPane
          tab={
            saveSearchResults?.SearchSaveId !== undefined
              ? saveSearchResults?.SearchSaveName !== ""
                ? `Edit Advanced Search`
                : `Advanced Search`
              : `Advanced Search`
          }
          key="1"
        >
          <AdvancedSearchForm
            audienceDataOptions={getAudienceOptions}
            providerOptions={getProviderOptions}
            publisherOptions={getPublisherOptions}
            languageOptions={getLanguageOptions}
            categoryOptions={getCategoryOptions}
            formatOptions={mediaTypes}
            setKey={setSelectedKey}
            libraryId={lib_Id}
            setShowLoading={setShowLoading}
            isCollection={false}
            clearForm={clearAdvancSearch}
          />
        </TabPane>
        {saveSearchResults?.SearchSaveId !== undefined ? (
          <></>
        ) : (
          <>
            <TabPane tab="ISBN" key="2">
              <IsbnSearch libraryId={lib_Id} />
            </TabPane>
            <TabPane tab="Saved" key="3">
              <SavedSearchList
                isCollection={false}
                showLoading={showLoading}
                setShowLoading={setShowLoading}
              />
            </TabPane>
          </>
        )}
      </Tabs>
    </div>
  );
});


