import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { showError } from "../../../utility/appUtil";
import { removeQuotes } from "../../../utility/searchUtil";
import { Page } from "../../constants/constants";
import { Book } from "../../models/book/book";
import AudienceData from "../../models/search/audienceData";
import Category from "../../models/search/category";
import ContentProvider from "../../models/search/contentProvider";
import MediaType from "../../models/search/mediaType";
import Publisher from "../../models/search/publisher";
import SavedSearch from "../../models/search/savedSearch";
import {AppStateService }from "../storage/appStateService"

export class SearchService extends BaseService {
    constructor() {
        super(getAPIBaseUrl(PortalModule.SEARCH));
      }
      
    async fetchAudienceData (): Promise<AudienceData[]| any> {
        const response = await this.get(`getAudienceData`);
        if(response.data.success === 1){
            var audienceData = this.setAudienceData(response.data.data)
            return audienceData;
        }
        else {
          showError(response?.data?.data)
          return response?.data?.data
        }
      }
    async fetchContentProviders (): Promise<ContentProvider[]| any> {
      var userCatalog = AppStateService.getState("userCatalog")
      const response = await this.get(`getContentPoviders?catalog_id=${userCatalog}`);
        if(response.data.success === 1){
            var contentProviders = this.setContentProviderData(response.data.data)
            return contentProviders;
        }
        else {
          showError(response?.data?.data)
          return null
        }
    }
    async fetchContentProvidersNew (): Promise<ContentProvider[]| any> {
      var userCatalog = AppStateService.getState("userCatalog")
      var CountryCode = AppStateService.getState("CountryCode")
      const response = await this.get(`getContentProvidersData?catalogId=${userCatalog}&countryCode=${CountryCode}`);
        if(response.data.success === 1){
            var contentProviders = this.setContentProviderDataNew(response.data.data)
            return contentProviders;
        }
        else {
          showError(response?.data?.data)
          return null
        }
    }
    async fetchPublisherWithRules (): Promise<ContentProvider[]| any> {
      var userCatalog = AppStateService.getState("userCatalog")
      var CountryCode = AppStateService.getState("CountryCode")
      const response = await this.get(`getPublisherWithRuleData?catalogId=${userCatalog}&countryCode=${CountryCode}`);
        if(response.data.success === 1){
            var publishers = this.setPublishersWithRules(response.data.data)
            return publishers;
        }
        else {
          showError(response?.data?.data)
          return null
        }
    }
    async fetchPublishers (): Promise<Publisher[]| any> {
      var userCatalog = AppStateService.getState("userCatalog")
      const response = await this.get(`getAllpublishers?catalog_id=${userCatalog}`);
        if(response.data.success === 1){
            var publishers = this.setPublisherData(response.data.data)
            return publishers;
        }
        else {
          showError(response?.data?.data)
          return null
        }
    }
    async fetchMediaTypes (): Promise<MediaType[]| any> {
      const response = await this.get(`getMediaType`);
        if(response.data.success === 1){
            var mediatypeList = this.setMediaData(response.data.data)
            return mediatypeList;
        }
        else {
          showError(response?.data?.data)
          return null
        }
    }
    async fetchMediaTypesError (): Promise<MediaType[]| any> {
      const response = await this.get(`getMediaType`);
      if(response.data.success === 1){ 
            var mediatypeList = this.setMediaData(response.data.data)
            return mediatypeList;
        } 
        else {
          showError(response?.data?.data)
          return {...response.data,error:true}
        }
    }
    async fetchLanguages (): Promise<any[]| any> {
      const response = await this.get(`getLanguages`);
        if(response.data.success === 1){
            return response.data.data;
        }
        else {
          showError(response?.data?.data)
          return null
        }
    }
    async fetchCategories (): Promise<Category[]| any> {
      var userCatalog = AppStateService.getState("userCatalog")
      const response = await this.get(`getCategories?catalog_id=${userCatalog}`);
        if(response.data.success === 1){
            var categories = this.setCategoryData(response.data.data)
            return categories
        }
        else {
          showError(response?.data?.data)
          return null
        }
    }
    async sendSearchData (payload: any): Promise<any> {
      const response =await this.post(`sendSearchData`, payload)
      if(response.data.success === 1){
        return true
    }
    else return response?.data?.data
    }

    async fetchSearchResults (payload: any): Promise<Book[]| any> {
      const response = await this.post(`getSearchResults`, payload)
      if(response.data.success === 1){
        var results =  this.setSearchResults(response.data)
        return {success:true, data: results, total: response.data.total_items}
    }
    else{
      showError(response?.data?.data)
      return {success:false, data: [], total: 0}
    }
    }
    async fetchSearchList (): Promise<Book[]| any> {
      const response = await this.get(`getSavedSearches`)
      if(response.data.success === 1){
        var searchList =  this.setSearchList(response.data.data)
        return searchList
    }
    else return response?.data?.data
    }
    async searchEisbn (payload: any): Promise<any[]| any> {
      const response = await this.post(`eisbnSearch`, payload)
      if(response.data.success === 1){
        return response.data.data    
    }
    else {
      showError(response?.data?.data)
      return null
    }
    }

   //search with cancel feature
    async fetchSearchResultsNew (payload: any): Promise<Book[]| any> {
      if(payload?.payload?.LibraryId !== undefined){
        const response = await this.post(`getSearchResults`,payload?.payload,{
          cancelToken: payload?.token
        })
        if(response.data.success === 1){
          var results =  this.setSearchResults(response.data)
          return {success:true, data: results, total: response.data.total_items}
      }
      else{
        return response?.data?.data
      }
      }
    }

    //quick search with cancel feature
    async fetchQuickSearchResultsNew(payload: any): Promise<Book[] | any> {
      if (payload?.payload?.LibraryId !== undefined) {
        const response = await this.post(`getSearchResults`, payload?.payload, {
          cancelToken: payload?.token,
        });
        if (response.data.success === 1) {
          var results = this.setSearchResults(response.data);
          return {
            success: true,
            data: results,
            total: response.data.total_items,
          };
        } else {
          return response?.data?.data;
        }
      }
    }

     //search for Export
     async fetchSearchResultsExport(payload: any): Promise<Book[] | any> {
      var exportCsv = payload?.functionForExport
      if (payload?.payload?.LibraryId !== undefined) {
        const response = await this.post(`getSearchResults`, payload?.payload, {
          cancelToken: payload?.token,
        });
        if (response?.data?.success === 1) {
          var results:any = this.setSearchResultsExports(response.data?.data, payload?.dateModify);
        
        return exportCsv(results)
        } else {
          return exportCsv({error: "Error Occured"});
        }
      }
    }
  //isbn with cancel feature
  async searchEisbnNew(payload: any): Promise<any[] | any> {
    const response = await this.post(`eisbnSearch`, payload?.payload, {
      cancelToken: payload?.token,
    });
    if (response.data.success === 1) {
      return response.data.data;
    } else return response?.data?.data;
  }

  //My Collection search results
  async fetchCollecSearchResults(payload: any): Promise<Book[] | any> {
    const response = await this.post(
      `getCollectionSearchResults`,
      payload?.payload,
      {
        cancelToken: payload?.token,
      }
    );
    if (response.data.success === 1) {
      var results = this.setSearchResults(response.data);
      return { success: true, data: results, total: response.data.total_items };
    } else {
      return response?.data?.data;
    }
  }

  //Export for Collection
  async fetchCollectionResultsExport(payload: any): Promise<Book[] | any> {
    var exportCsv = payload?.functionForExport
    if (payload?.payload?.LibraryId !== undefined) {
      const response = await this.post(`getCollectionSearchResults`, payload?.payload, {
        cancelToken: payload?.token,
      });
      if (response?.data?.success === 1) {
        var results:any = this.setSearchResultsExports(response.data?.data, payload?.dateModify);
        return exportCsv(results);
      } else {
        return exportCsv({error: "Error Occured"});
      }
    }
  }

     //My Collection isbn with cancel feature
     async searchCollecEisbn (payload: any): Promise<any[]| any> {
      const response = await this.post(`eisbnSearch`, payload?.payload,{
        cancelToken: payload?.token
      })
      if(response.data.success === 1){
        return response.data.data    
    }
    else {
      showError(response?.data?.data)     
      return null
    }
    }

    //fetchSearchbyEisbn with cancel feature
    async fetchSearchbyEisbnNew (payload: any): Promise<any[]| any> {
      var id = payload.id
      var libId = payload.libId
      var sortField = payload.sortField? payload.sortField : "BulkISBNSearchId";
      var sortDirection = payload.sortDirection; 
      var recordCount = payload.recordCount
      var recordStart = payload.recordStart
      const response = await this.get(`eisbnSearchByGuid?guiId=${id}&lib_id=${libId}&record_count=${recordCount}&record_start=${recordStart}&sortdirection=${sortDirection}&sortfield=${sortField}`,{
        cancelToken: payload?.token
      })
      if(response.data.success === 1){
        var results =  this.setIsbnSearchResults(response.data)
        return {data: results?.books, total: response?.data?.data?.RecordsMatched,RecordsNotMatched: response?.data?.data?.RecordsNotMatched,MatchedPrice: response?.data?.data?.MatchedPrice,totalIsbnNumbers:results?.totalIsnNos,totalPrice:results?.totalPrice }
    }
    else return response?.data?.data
    }

    // isbn export
    async searchIsbnExport(payload: any): Promise<any[] | any> {
      var exportCsv = payload?.functionForExport
      const response = await this.post(`eisbnSearch`, payload?.payload, {
        cancelToken: payload?.token,
      });
      if (response?.data?.success === 1) {
        return response.data.data;
      } else{
        return exportCsv({error: "Error Occured"});
      }
    }

    // CSV export Service for ISBN -> works for both Search and My Collection
    async fetchIsbnSearchExport (payload: any): Promise<any[]| any> {
      var exportCsv = payload?.functionForExport
      var id = payload.id
      var libId = payload.libId
      var sortField = payload.sortField? payload.sortField : "BulkISBNSearchId";
      var sortDirection = payload.sortDirection; 
      var recordCount = 1000
      var recordStart = payload.recordStart

      const response = await this.get(`eisbnSearchByGuid?guiId=${id}&lib_id=${libId}&record_count=${recordCount}&record_start=${recordStart}&sortdirection=${sortDirection}&sortfield=${sortField}`,{
        cancelToken: payload?.token
      })
      if(response?.data?.success === 1){
        var results =  this.setSearchResultsExports(response.data?.data?.ResultItems, payload?.dateModify);
        return exportCsv(results);
      }
      else {
        return exportCsv({error: "Error Occured"});
      }
    }
    
    //fetchSearchbyEisbn total unmatched with cancel feature
    async fetchSearchbyEisbnTotalUnmatch (payload: any): Promise<any[]| any> {
      var id = payload.id
      var libId = payload.libId
      var sortField = payload.sortField? payload.sortField : "BulkISBNSearchId";
      var sortDirection = payload.sortDirection; 
      var recordCount = payload.recordCount
      var recordStart = payload.recordStart
      const response = await this.get(`eisbnSearchNotMatchByGuid?guiId=${id}&lib_id=${libId}&record_count=${recordCount}&record_start=${recordStart}&sortdirection=${sortDirection}&sortfield=${sortField}`,{
        cancelToken: payload?.token
      })
      if(response.data.success === 1){
        var results =  this.setNotMatchedData(response.data.data)
        return results
    }
    else return response?.data?.data
    }
    // totalIsbnNumbers:
    async fetchSearchbyEisbn (payload: any): Promise<any[]| any> {
      var id = payload.id
      var libId = payload.libId
      var sortField = payload.sortField? payload.sortField : "BulkISBNSearchId";
      var sortDirection = payload.sortDirection; 
      var recordCount = payload.recordCount
      var recordStart = payload.recordStart
      const response = await this.get(`eisbnSearchByGuid?guiId=${id}&lib_id=${libId}&record_count=${recordCount}&record_start=${recordStart}&sortdirection=${sortDirection}&sortfield=${sortField}`)
      if(response.data.success === 1){
        var results =  this.setIsbnSearchResults(response.data)
        return {data:results?.books, total: response.data.data.RecordsMatched,totalIsbnNumbers:results?.totalIsnNos,totalPrice:results?.totalPrice }
    }
    else {
      showError(response?.data?.data)
      return null
    }
    }
    async deleteSearch (searchId: number): Promise<any> {
      const response =await this.delete(`deleteSearch?id=${searchId}`)
      if(response.data.success === 1){
        return true
    }
    else {
      showError(response?.data?.data)
      return response?.data?.data
    }
    }

    async clearTargetAudience(payload: any): Promise<AudienceData[] | any> {
      const response =await this.delete(`clearAudience?libId=${payload?.libid}&ids=${payload?.catalogIyemId}`)
      if (response.data.success === 1) {
        var PreSaleData = response?.data?.data;
        return PreSaleData;
      } else {
        showError(response?.data?.data)
        return null
      };
    }

    async targetAudience (payload: any): Promise<any> {
      const response = await this.put(`saveTargetAudience`, payload);
      if(response.data.success === 1){
        return response.data.data;
    }
    else {
      showError(response?.data?.data)
      return false
    }
    }

    async targetDeleteAudience (payload: any): Promise<any> {
      var libId = payload.LibraryId
      var catlogIds = payload.CartDetail.toString()
      const response = await this.delete(`clearAudience?libId=${libId}&ids=${catlogIds}`);
      if(response.data.success === 1){
        return true;
    }
    else {
      showError(response?.data?.data)
      return false
    }
    }


      private setAudienceData(data: any) {
        var audience: AudienceData[] = data.map((audience: any)=>{
          return {
              AgeClassificationId: audience.AgeClassificationId,
              Name: audience.Name,
              ParentAgeClassificationId : audience.ParentAgeClassificationId
            }
        })
        return audience;
      }
      private setContentProviderData(data: any) {
        var contentProviders: ContentProvider[] = data.map((provider: any)=>{
          return {
            ContentProviderId: provider.ContentProviderId,
            Name: provider.Name,
            CreateDateTime: provider.CreateDateTime,
            CatalogID: provider.CatalogID,
            }
        })
        return contentProviders;
      }
      private setContentProviderDataNew(data: any) {
        const providers = {
          ContentProviders: data?.ContentProviders && data?.ContentProviders?.length > 0?data?.ContentProviders:[],
        }
           return providers
      }
      private setPublishersWithRules(data: any) {
        const publishers = {
          ContentProviders: data?.publisherWithRules && data?.publisherWithRules?.length > 0?data?.publisherWithRules:[],
        }
           return publishers
      }
      private setPublisherData(data: any) {
        var publishers: Publisher[] = data.map((publisher: any)=>{
          return {
            PublisherId: publisher.PublisherId,
            PublisherName: publisher.PublisherName,
            CatalogID: publisher.CatalogID,
            }
        })
        return publishers;
      }
      private setMediaData(data: any) {
        var media: MediaType[] = data.map((media: MediaType)=>{
          return {
           MediaTypeId: media.MediaTypeId,
           MediaType: media.MediaType,
           Description: media.Description,
           ItemFormat: media.ItemFormat
            }
        })
        return media;
      }
      private setSearchResults(data: any) {
        const books: Book[] = data?.data.map((book: any)=>{
            return {
                AddedToCatalogDate: book?.AddedToCatalogDate,
                CatalogItemId: book?.CatalogItemId,
                Title: book?.Title,
                SubTitle: book?.SubTitle,
                Authors: book?.Authors,
                MediaTypeId: book?.MediaTypeId,
                FileFormatName: book?.FileFormatName,
                Language: book?.Language,
                LastPurchasedDate: book?.LastPurchasedDate,
                LastCheckoutDate: book?.LastCheckoutDate,
                ImageUrl: `https://${data?.image_url}?type=DOCUMENTIMAGE&documentID=${book?.ImageId}&size=LARGE&token=nobody`,
                RetailPrice: book?.RetailPrice,
                Narrator: book?.Narrator,
                IsPPU:book?.IsPPU,
                NumberOfCopies: book?.NumberOfCopies,
                NumberOfHolds: book?.NumberOfHolds,
                NumberOfLoans: book?.NumberOfLoans,
                NumberOfCurrentLoans: book?.NumberOfCurrentLoans,
                NumberOfWish: book?.NumberOfWish,
                NumberInCarts: book?.NumberInCarts,
                NumberOnOrder: book?.NumberOnOrder,
                NumberOfShelves: book?.NumberOfShelves,
                Categories: book?.Categories[0],
                HoldRatio: book?.HoldRatio,
                PublicationDate:  book?.PublicationDate,
                Publisher: book?.Imprint,
                PPUPrice: book?.PPUPrice,
                Provider: book?.ContentProviderName,
                Eisbn: book?.EISBN,
                EditionType: book?.EditionType,
                StreetDate: book?.StreetDate,
                ItemState: book?.ItemState,
                ContentProviderId:book?.ContentProviderId,
                Series: book?.Series,
                NumberInSeries: book?.NumberInSeries,
                AudienceData:book?.AudienceData,
                TimeSpanLimit:book?.TimeSpanLimit,
                CheckOutLimit:book?.CheckOutLimit,
                Quantity:1,
                GroupHoldCount:book?.GroupHoldCount,
                GroupSharableCopies:book?.GroupSharableCopies,
                CloudLinkGroupName:book?.CloudLinkGroupName,
            }
        })
        return books;
      }

      //Function for mapping export data with CSV file headers
      private setSearchResultsExports(data: any, dateModify: any) {
       
        const books: Book[] = data?.map((item: any) => {
          const str = item?.Categories[0];
          const res = str?.split(":");
          const LastCheckoutDate =
            item?.LastCheckoutDate !== null || undefined
              ? dateModify(item?.LastCheckoutDate)
              : "";
          const PublicationDate =
            item?.PublicationDate !== null || undefined
              ? dateModify(item?.PublicationDate)
              : "";
          const StreetDate =
            item?.StreetDate !== null || undefined
              ? dateModify(item?.StreetDate)
              : "";
          const AddedToCatalogDate =
            item?.AddedToCatalogDate !== null || undefined
              ? dateModify(item?.AddedToCatalogDate)
              : "";
          const LastPurchasedDate =
            item?.LastPurchasedDate !== null || undefined
              ? dateModify(item?.LastPurchasedDate)
              : "";
          return {
            Eisbn: item?.EISBN,
            refineTitle: removeQuotes(item?.Title),
            refineAuther: item?.Authors,
            Series: item?.Series?item?.Series:'',
            NumberInSeries: item?.NumberInSeries?item?.NumberInSeries:'',
            Narrator: item?.Narrator,
            Publisher: item?.Imprint,
            Provider : item?.ContentProviderName,
            FileFormatName: item?.FileFormatName,
            PublicationDate: PublicationDate,
            Language: item?.Language,
            category : res && res[0],
            subCategory: res && res[1],
            StreetDate: StreetDate,
            RetailPrice: item?.RetailPrice,
            AddedToCatalogDate: AddedToCatalogDate,
            NumberOfCopies: item?.NumberOfCopies,
            NumberOfHolds: item?.NumberOfHolds,
            NumberOfLoans: item?.NumberOfLoans,
            NumberOfCurrentLoans: item?.NumberOfCurrentLoans,
            NumberOfWish: item?.NumberOfWish,
            HoldRatio: item?.HoldRatio,
            NumberOnOrder: item?.NumberOnOrder,
            NumberOfShelves: item?.NumberOfShelves,
            NumberInCarts: item?.NumberInCarts,
            LastPurchasedDate: LastPurchasedDate,
            LastCheckoutDate: LastCheckoutDate,
            IsPPU: item?.IsPPU,
            PPUPrice: item?.PPUPrice,
            GroupSharableCopies: item?.GroupSharableCopies,
            GroupHoldCount: item?.GroupHoldCount,
          };
        });
        return books;
      }

      private setIsbnSearchResults(data: any) {
        let totalIsbn:any = []
        let totalIsbnPrice:any = []
        const books: Book[] = data?.data?.ResultItems.map((book: any)=>{
            totalIsbn.push(book?.EISBN)
            totalIsbnPrice?.push(book?.RetailPrice)
            return {
              AddedToCatalogDate: book?.AddedToCatalogDate,
              CatalogItemId: book?.CatalogItemId,
              Title: book?.Title,
              SubTitle: book?.SubTitle,
              Authors: book?.Authors,
              MediaTypeId: book?.MediaTypeId,
              FileFormatName: book?.FileFormatName,
              Language: book?.Language,
              LastPurchasedDate: book?.LastPurchasedDate,
              LastCheckoutDate: book?.LastCheckoutDate,
              ImageUrl: `https://${data?.image_url}?type=DOCUMENTIMAGE&documentID=${book?.ImageId}&size=LARGE&token=nobody`,
              RetailPrice: book?.RetailPrice,
              Narrator: book?.Narrator,
              NumberOfCopies: book?.NumberOfCopies,
              NumberOfHolds: book?.NumberOfHolds,
              NumberOfLoans: book?.NumberOfLoans,
              NumberOfCurrentLoans: book?.NumberOfCurrentLoans,
              NumberOfWish: book?.NumberOfWish,
              NumberInCarts: book?.NumberInCarts,
              NumberOnOrder: book?.NumberOnOrder,
              NumberOfShelves: book?.NumberOfShelves,
              Categories: book?.Categories[0],
              HoldRatio: book?.HoldRatio,
              PublicationDate: book?.PublicationDate,
              Publisher: book?.Imprint,
              PPUPrice: book?.PPUPrice,
              Provider: book?.ContentProviderName,
              Eisbn: book?.EISBN,
              StreetDate: book?.StreetDate,
              ItemState: book?.ItemState,
              ContentProviderId: book?.ContentProviderId,
              Series: book?.Series,
              NumberInSeries: book?.NumberInSeries,
              AudienceData: book?.AudienceData,
              TimeSpanLimit: book?.TimeSpanLimit,
              CheckOutLimit: book?.CheckOutLimit,
              Quantity: 1,
              GroupHoldCount: book?.GroupHoldCount,
              GroupSharableCopies: book?.GroupSharableCopies,
              CloudLinkGroupName: book?.CloudLinkGroupName,
              EditionType: book?.EditionType,
            };
        })
        var totalIsbnC = {books:books,totalIsnNos:totalIsbn,totalPrice:totalIsbnPrice}
        return totalIsbnC
      }
      private setNotMatchedData(data: any) {
        const notMtach: any[] = data.map((cat: any)=>{
            return cat?.EISBN
        })
        return notMtach;
      }
      private setCategoryData(data: any) {
        const categories: Category[] = data.map((cat: any)=>{
            return {
              CategoryId: cat?.CategoryId,
              ParentCategoryId: cat?.ParentCategoryId,
              CategoryName: cat?.CategoryName,
              CategoryGroup:cat?.CategoryGroup,
              SubTreeDocumentCount: cat?.SubTreeDocumentCount,
              CatalogId: cat?.CatalogId,
              SourceId: cat?.SourceId,
              SubCategory:cat?.SubCategory
            }
        })
        return categories;
      }
      private setSearchList(data: any) {
        var userCatalog = AppStateService.getState("userCatalog")
        const categories: SavedSearch[] = data.map((cat: SavedSearch)=>{
            return {
             
              SearchSaveDescription: cat.SearchSaveDescription,
              SearchSaveId: cat.SearchSaveId,
              SearchSaveName: cat.SearchSaveName,
              ChangeDateTime: cat.ChangeDateTime,
              ChangeUserId: cat.ChangeUserId,
              CreateDateTime: cat.CreateDateTime,
              CreateUserId: cat.CreateUserId,
              searchParams: {
                AuthorSearch: cat.AuthorSearch  && cat.AuthorSearch !== null?  cat.AuthorSearch: [],
                AddedToCatalogDateFrom: `${cat.AddedToCatalogDateFrom}`,
                AddedToCatalogDateTo: `${cat.AddedToCatalogDateTo}`,
                AddedToCatalogDaysOld: `${cat.AddedToCatalogDaysOld}`,
                CategorySearch:  cat.CategorySearch && cat.CategorySearch !== null?  cat.CategorySearch: [],  
                ContentProviderSearch: cat.ContentProviderSearch && cat.ContentProviderSearch !== null?  cat.ContentProviderSearch: [],
                EISBN:`${cat.EISBN}`,
                // eslint-disable-next-line eqeqeq
                ExcludeSelfPublished: cat.ExcludeSelfPublished == ""? false :  cat.ExcludeSelfPublished,
                FromPublisherDate: `${cat.FromPublisherDate}`,
                FromPurchaseDate: `${cat.FromPurchaseDate}`,
                HoldRatio: cat.HoldRatio,
                InCollection: cat.InCollection.toLocaleLowerCase(),
                ItemFormatId: `${cat.ItemFormatId}`,
                ItemFormatIds: cat.ItemFormatIds && cat.ItemFormatIds !== null? cat.ItemFormatIds: {},
                ItemFormatIdSet: cat.ItemFormatIdSet && cat.ItemFormatIdSet !== null? cat.ItemFormatIdSet: [],
                Language: cat.Language,
                LibraryId: cat.LibraryId,
                MaximumPriceAmount: `${cat.MaximumPriceAmount}`,
                MediaTypeIdSet: cat.MediaTypeIdSet && cat.MediaTypeIdSet !== null? cat.MediaTypeIdSet: [],
                MinimumPriceAmount: `${cat.MinimumPriceAmount}`,
                NotInCollection: cat.NotInCollection.toLocaleLowerCase(),
                POReference: `${cat.POReference}`,
                PPUCriteria: `${cat.PPUCriteria}`,
                PreSaleDays: cat.PreSaleDays,
                PublisherSearch: cat.PublisherSearch && cat.PublisherSearch !== null? cat.PublisherSearch: [],
                QuickSearch: cat.QuickSearch && cat.QuickSearch !== null? cat.QuickSearch: [],
                recordCount: Page.DEFAULT_COUNT,
                recordStart: Page.DEFAULT_START,
                sortDirection: cat.SortDirection && cat.SortDirection !== null? cat.SortDirection: "ASC",
                sortField: cat.SortField && cat.SortField !== null? cat.SortField: "",
                SubCategorySearch:  cat.SubCategorySearch && cat.SubCategorySearch !== null? cat.SubCategorySearch: [],
                TargetAudienceIds: cat.TargetAudienceIds && cat.TargetAudienceIds !== null? cat.TargetAudienceIds: [],
                TitleDaysOld: cat.TitleDaysOld,
                TitleSearch: cat.TitleSearch && cat.TitleSearch !== null? cat.TitleSearch : [] ,
                ToPublisherDate: cat.ToPublisherDate,
                ToPurchaseDate: cat.ToPurchaseDate,
                CatalogId: userCatalog,
                series: cat.Series,
              }
            }
        })
        return categories;
      }
      
      async fetchppuBooksStatus (): Promise<Category[]| any> {
        const response = await this.get(`getPpuBookStatus`);
          if(response.data.success === 1){
              var ppuBooks = response?.data?.data
              return ppuBooks
          }
          else {
            showError(response?.data?.data)
            return null
          }
      }
}
