/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import { Row, Col, Tabs, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSideBarItem } from "../../../../redux/actions/app/appActions";
import Button from "../../stateless/common/button";
import "./carts.less"
import { CartStateSelector, clearAutoCartData, clearDeleted, setTab,clearAutoByRuleError } from "../../../../redux/reducers/carts/cartsReducer";
import { CartList } from "./cartList";
import Cart from "../../../models/cart/cart";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { NewCartModal } from "./newCartModal";
import { fetchCarts,fetchAutoCarts } from "../../../../redux/actions/carts/cartAction";
import { Messages } from "../../../constants/messages";
import { onBackFillForm,onStringBackFillForm } from "../../../../redux/actions/search/searchActions";
import { AutoCartList } from "./autoCarts";
import { ShelfstateSelector } from "../../../../redux/reducers/shelves/shelfReducer";
import { fetchShelves } from "../../../../redux/actions/shelves/shelfAction";

export const Carts = (props: any) =>{
    const dispatch = useDispatch()
    const { carts, cartDeleted, formState, selectedTab, autoCartsData, saveAutoCartFormState } = useSelector(CartStateSelector)
    const {appUser} = useSelector(userStateSelector)
    var lib_Id =
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
    var permissions = appUser && appUser.libraryData ? appUser.libraryData[0].FeaturePermissions : "";
    const [showModal, setShowModal] = useState(false)  
    const { TabPane } = Tabs;
    const {shelves} = useSelector(ShelfstateSelector)
    var userCarts = carts.filter((data: Cart)=>{
        return data.CartOwner === appUser?.email
    })

    var filteredCarts = permissions.includes("AudioBooks") ?
    carts
    :
    carts.filter((data: Cart) => {
      return !data.CartTypeCode?.includes("Audio")});
   

    useEffect(()=>{        
        dispatch(setSideBarItem("Carts"))
        if(appUser?.isLibraryBuyer){
          dispatch(fetchAutoCarts(lib_Id))
        }
        return () => {
          dispatch(clearAutoCartData())
          dispatch(clearAutoByRuleError())
        }
    },[])
    useEffect(()=>{
        if(cartDeleted){
          dispatch(fetchCarts({
            libId:lib_Id,
            appuser:appUser?.email
          }))
          message.success({
            content: Messages.CART_DELETED,
            key: "notificationMessage",
          })
          dispatch(clearDeleted())
        }
      },[cartDeleted])

    useEffect(() => {
      if(saveAutoCartFormState?.isSuccess) {
        dispatch(fetchCarts({
          libId:lib_Id,
          appuser:appUser
        }))
      }
    }, [saveAutoCartFormState])

    const onNewCart = () => {
        setShowModal(true)
    }
    const handleSelect = (e:any) => {
      dispatch(setTab(e))
    }
    useEffect(()=>{
      dispatch(onBackFillForm([]))
      dispatch(onStringBackFillForm([]))
    },[])

    useEffect(()=>{
      if(shelves.length < 1){
        dispatch(fetchShelves(lib_Id));
      }
    },[shelves])
    return (
        <div>
            <Row className="head-container">
                <Col xs={12} sm={14} md={18} lg={20} xl={20}>
                    <p className="page-title">
                        Carts
                    </p>
                </Col>
                <Col xs={12} sm={10} md={6} lg={4} xl={4}>
                    <Button type="primary new-cart" onClick={onNewCart}>New Cart</Button>
                </Col>
            </Row>
            <Tabs className="cart-tabs" defaultActiveKey={selectedTab}  onChange={handleSelect}>
            <TabPane tab="My Carts" key="1">
            <CartList cartlist={userCarts} formState={formState} name="My_Carts"/>
            </TabPane>
            <TabPane tab="All Carts" key="2">
              <CartList cartlist={filteredCarts} formState={formState} tab={2} name="All_Carts"/>
            </TabPane>
            {appUser?.isLibraryBuyer === true ?
              <TabPane tab="Auto Carts" key="3">
                <AutoCartList autoCartsData={autoCartsData} formState={formState} carts={carts}/>
              </TabPane>
              :
              <></>
            }
          </Tabs>
          <NewCartModal isModalVisible={showModal} setModalVisible={setShowModal} appUser={appUser} editable={false} isNewCartDetailsNeed={true} />
        </div>
    )
}