/* eslint-disable @typescript-eslint/no-redeclare */

import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Menu,
  Dropdown,
  message,
  Table,
  Divider,
  Checkbox,
  Empty,
} from "antd";
import "./shelf.less";
import Button from "../../../stateless/common/button";
import { filterOptions } from "../../../../constants/constants";
import DropDown from "../../../stateless/common/dropDown";
import {
  downIcon,
  dropdownIcon,
  editIcon,
  notFound, ebookLabel, audiobookLabel, videobookLabel
} from "../../../../images";

import ExportIcon from "../../../../../assets/dummy/ExportIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { Resizable } from "react-resizable";
import { ExpirationstateSelector } from "../../../../../redux/reducers/expiration/expirationReducer";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchShelfDetails,
  fetchShelvByShelvId,
  removeShelvItems,
  resizeCompletedCols,
  updateCartShelv,
  onSelectShelve,
  savedShelfColumns,
  retainShelvPostion,
  retainShelvState
} from "../../../../../redux/actions/shelves/shelfAction";
import {
  clearRemoveError,
  clearShelvDetails,
  clearUpdateError,
  setTab,
  ShelfstateSelector,
  clearNewShelv
} from "../../../../../redux/reducers/shelves/shelfReducer";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { shelvDetailHeader } from "../../purchases/tableColumns";
import { CSVLink } from "react-csv";
import { AppRoutes } from "../../../../router/appRoutes";
import RemoveConfirmModel from "../../carts/cartDetails/remove/removeModal";
import { Messages } from "../../../../constants/messages";
import { searchPayloadForAuthor } from "../../../../../utility/searchUtil";
import {
  fetchAudienceData,
  fetchCategoryOptions,
  fetchContentProviders,
  fetchLanguages,
  fetchMediaTypes,
  fetchPublishers,
  getSearchString,
  onBackFillForm,
} from "../../../../../redux/actions/search/searchActions";
import { fetchCartsByBookId } from "../../../../../redux/actions/books/bookActions";
import { BookStateSelector } from "../../../../../redux/reducers/books/bookListReducer";
import { validateLendingModelType } from "../../curatedList/validateLendingData";
import moment from "moment-timezone";
import { SearchstateSelector } from "../../../../../redux/reducers/search/searchReducer";

const ResizeableTitle = (props: any) => {
  const { onResize, onResizeStop, onResizeStart, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th key="a" {...restProps} />
    </Resizable>
  );
};

export const ShelvesDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<any>();
  const backText = "< Back";
  const { shelv, cammeFromAutoCreate,shelvListPostion,shelvListState }:any = location?.state;
  const {
    shelvDetails,
    removeError,
    shelvDetailFormState,
    shelvDetailById,
    updateShlvFormState,
    shelvIdFormState,
    removeformState,
    updateShelvError,
    // previousLocation,
    newAutoShelveFormState,
    savedShelfCols,
    selectedShelve
  } = useSelector(ShelfstateSelector);
  const { appUser } = useSelector(userStateSelector);
  const { carts } = useSelector(BookStateSelector);
  const [showRemove, setShowRemove] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [buttonClicked, setButtonClicked] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [showSaveBtn, setShowSaveBtn] = useState<boolean>(false);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showRemoveBtn, setShowRemoveBtn] = useState<boolean>(false);
  // const [shelvStatus, setShelvStatus] = useState<boolean>(false);
  const [filterVal, setFilterVal] = useState<string>("Title");
  const [goTo, setGoTo] = useState<string>("1");
  var dateFormat = appUser?.libraryData ? appUser.libraryData[0]?.DateFormat.toUpperCase() : ""
  const getAuthor = (book: any) => {
    searchPayloadForAuthor(book?.Authors, appUser).then((data: any) => {
      dispatch(getSearchString(data.strinParam));
      dispatch(onBackFillForm(data.searchParam));
      var advanceState: any = {
        searchDetails: data?.state?.searchDetails,
        gaString: {
          gaData: data?.strinParam,
        },
      };
      history.push(AppRoutes.SEARCHRESULTS, advanceState)
    });
  };
  const fetchInfo = (CatalogItemId: any) => {
    if (!carts[CatalogItemId]) {
      var lib_Id =
        appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
      dispatch(
        fetchCartsByBookId({
          libraryId: lib_Id,
          bookId: CatalogItemId,
        })
      );
    }
  };
  var currency = appUser &&  appUser.libraryData? appUser.libraryData[0]?.CurrencySymbol : ""

  const getBookLabel = (format: any) => {
    switch (format) {
      case "EPUB": {
        return ebookLabel;
      }
      case "PDF": {
        return ebookLabel;
      }
      case "MP3": {
        return audiobookLabel;
      }
      case "VideoBook": {
        return videobookLabel;
      }
    }
  };

  const [tColumns, setTColumns] = useState<any>(
    shelvDetailHeader(
      appUser?.imageUrl,
      notFound,
      history,
      getAuthor,
      fetchInfo,
      currency,
      getBookLabel
    )
  );
  const [columnResize, setColumnResize] = useState<any>([]);
  const [removePayload, setRemovePayload] = useState<any>([]);
  const [saveChangePayload, setSaveChangePayload] = useState<any>([]);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [isNewAutoCreated, setIsNewAutoCreated] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<any>({});
  const [getSelectedRows, setGetSelectedRows] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [exportData, setExportData] = useState<any>([]);
  const { expiration } = useSelector(ExpirationstateSelector);
  const [finalColumn, setFinalColumn] = useState<any>( savedShelfCols !== undefined && savedShelfCols?.length > 0  ? savedShelfCols :
    shelvDetailHeader(
      appUser?.imageUrl,
      notFound,
      history,
      getAuthor,
      fetchInfo,
      currency,
      getBookLabel
    )
  );
  const [visibleMenu, setVisibleMenu] = useState<boolean>(false);
  const {
    audienceData,
    providerData,
    publishers,
    mediaTypes,
    languages,
    categoriesOptions,
  } = useSelector(SearchstateSelector);

  const goBack = () => {
    history.push(AppRoutes.SHELVES);
    // if (previousLocation === "/libraryManagement/shelves") {
    //   history.push(AppRoutes.SHELVES);
    // } else {
    //   history.goBack();
    // }
    // dispatch(setTab(shelvStatus === true ? "1" : "2"));
  };
 

  useEffect(() => {
    shelvDetailHeader(
      appUser?.imageUrl,
      notFound,
      history,
      getAuthor,
      fetchInfo,
      currency,
      getBookLabel
    );
    return () => {
      dispatch(clearShelvDetails());
      dispatch(clearRemoveError());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTColumns(
      shelvDetailHeader(
        appUser?.imageUrl,
        notFound,
        history,
        getAuthor,
        fetchInfo,
        currency,
        getBookLabel
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser?.imageUrl]);

  useEffect(() => {
    if (shelvDetails?.length === 0) {
      var payload = {
        libraryId: shelv?.LibraryId,
        catShelfId: shelv?.ShelfId,
        recordCount: 500,
        recordStart: 0,
      };
      dispatch(fetchShelfDetails(payload));
    }
    // setShelvStatus(shelv?.IsActive);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shelv]);

  useEffect(() => {
    if (shelvDetailById?.length === 0) {
      var payloadB = {
        libraryId: shelv?.LibraryId,
        catShelfId: shelv?.ShelfId,
      };
      dispatch(fetchShelvByShelvId(payloadB));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    if(shelvListPostion && shelvListState){
      dispatch(retainShelvPostion(shelvListPostion))
      dispatch(retainShelvState(shelvListState))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [shelv,shelvListPostion,shelvListState]);

  const replaceFunction = (string: any) => {
    const final = string.replace(/[^\d.-]/g, "");
    return final;
  };

  const lendingModelData = (
    id: number,
    checkoutLimit: any,
    timespanlimit: any,
    format: any
  ) => {
    if (format !== "MP3") {
      const dataLendingModel = expiration?.filter((data: any) => {
        return data.ContentProviderId === id;
      });

      let statement = validateLendingModelType(dataLendingModel);
      var data = statement;
      if (data !== "") {
        return data;
      } else {
        return "Perpetual";
      }
    } else {
      if (checkoutLimit === null && timespanlimit === null) {
        return "Perpetual";
      } else {
        let desc = checkoutLimit + " checkouts";
        let year =
          " " + replaceFunction(timespanlimit) + " years after purchase";
        if (checkoutLimit !== null && timespanlimit !== null) {
          return desc + " or" + year;
        } else if (checkoutLimit !== null) {
          return desc;
        } else {
          return year;
        }
      }
    }
  };

  useEffect(() => {
    setData(
      shelvDetails?.map((item: any) => {
        return {
          ...item,
          Model: lendingModelData(
            item?.ContentProviderId,
            item?.CheckOutLimit,
            item?.TimeSpanLimit,
            item?.FileFormatName
          ),
        };
      })
    );
    setGetSelectedRows([]);
    setShowSaveBtn(
      shelvDetailById?.CatShelfCriteria?.SortStrategy !== filterVal
        ? true
        : false
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shelvDetails]);

  useEffect(() => {
    setFilterVal(shelvDetailById?.CatShelfCriteria?.SortStrategy);
  }, [shelvDetailById]);

  const components = {
    header: {
      cell: ResizeableTitle,
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (columnResize.length > 0) {
      dispatch(resizeCompletedCols(finalColumn));
    }

   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnResize]);


  const handleResize = (index: any) => (e: any, size: any) => {
    const nextColumns = [...finalColumn]
    nextColumns[index] = {
      ...nextColumns[index],
      width: size?.size?.width,
    };
    setColumnResize(nextColumns);
    setFinalColumn(nextColumns)
  };

  const tColumn = tColumns?.map((col: any, index: number) => ({
    ...col,
    onHeaderCell: (tColumn: any) => ({
      width: tColumn?.width,
      onResize: handleResize(index),
    }),
  }));

  useEffect(() => {
    setRowSelection({
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        setGetSelectedRows(
          selectedRows?.map((item: any) => {
            return { catalogItemId: item?.CatalogItemId };
          })
        );
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    });
  }, []);


  const handelFilter = (value: any) => {
    setIsloading(true);
    setFilterVal(value);
    setShowSaveBtn(
      shelvDetailById?.CatShelfCriteria?.SortStrategy !== value ? true : false
    );
    var sortVal = { ...shelvDetailById.CatShelfCriteria, SortStrategy: value };
    var payload = {
      reqBody: { ...shelvDetailById, CatShelfCriteria: sortVal },
      getShelv: {
        libraryId: shelv?.LibraryId,
        catShelfId: shelv?.ShelfId,
        recordCount: 500,
        recordStart: 0,
      },
    };
    setSaveChangePayload(payload);
  };

  const onHandleSaveChange = () => {
    dispatch(updateCartShelv(saveChangePayload));
  };

  useEffect(() => {
    if (updateShlvFormState?.isSuccess === true) {
      dispatch(clearShelvDetails());
      setData([]);
    }
    if (updateShlvFormState?.isSuccess === true && isPublished === true) {
      setIsPublished(false);
      dispatch(setTab(goTo));
      history.push(AppRoutes.SHELVES);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShlvFormState, isPublished, goTo]);

  useEffect(() => {
    if (removeformState?.isSuccess === true) {
      message.success({
        content: Messages.ITEM_REMOVED,
        key: "notificationMessage",
      });
      dispatch(clearShelvDetails());
      setData([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeformState]);

  const onHandlePublish = (val: string) => {
    if (shelv?.IsActive === true) {
       var payload = {
        reqBody: { ...shelvDetailById, IsActive: false },
        getShelv: {
          libraryId: shelv?.LibraryId,
          catShelfId: shelv?.ShelfId,
          recordCount: 500,
          recordStart: 0,
        },
      };
    } else {
      var payload = {
        reqBody: { ...shelvDetailById, IsActive: true },
        getShelv: {
          libraryId: shelv?.LibraryId,
          catShelfId: shelv?.ShelfId,
          recordCount: 500,
          recordStart: 0,
        },
      };
    }
    if(payload?.getShelv?.catShelfId === selectedShelve?.ShelfId) {
      const updateSelectShelve = {...selectedShelve, IsActive: payload?.reqBody?.IsActive}
      dispatch(onSelectShelve(updateSelectShelve))
    }
    dispatch(updateCartShelv(payload));
    setIsPublished(true);
    setGoTo(val);
  };

  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr)
      .tz("Etc/GMT")
      .format(dateFormat);
    return currentDate;
  };

  useEffect(() => {
    if (data !== undefined) {
      const tableValue = data?.map((item: any) => {
        var Title = item?.Title;
        const refineTitle = Title.replaceAll('"', "");
        var Auther = item?.Authors;
        const refineAuther = Auther.replaceAll('"', "");
        var Price = item?.RetailPrice;
        const refinePrice =Price?.toFixed(2);
        var PPUPrice = item?.PPUPrice;
        const refinePPUPrice =PPUPrice?.toFixed(2);
        const PublicationDate = item?.PublicationDate !== null || undefined ? dateModify(item?.PublicationDate):""
        const arr: any[] = [];
        const arr2: any[] = [];
        const str = item?.Categories;
        const refineSeries = item?.Series + item?.NumberInSeries
        str?.map((item: any) => {
          const res = item?.split(":");
          arr.push(res[0]);
          arr2.push(res[1]);
          return []
        });
        return {
          ...item,
          Categories: arr.length > 0?arr[0]:arr,
          subCategory:arr2.length > 0?arr2[0]:arr2,
          refinePrice: refinePrice,
          refinePPUPrice:refinePPUPrice,
          refineTitle: refineTitle && refineTitle,
          refineAuther: refineAuther && refineAuther,
          refineSeries: refineSeries,
          PublicationDate:PublicationDate
        };
      });
      setExportData(tableValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const headerValue = () => {
    var exportColumn:any = []
    for(var j = 0; j < tColumn.length; j++ ){
      if(tColumn[j]?.title === "Title"){
        exportColumn.push({ label: "Title", key: "refineTitle" })
      } else if(tColumn[j]?.title === "Author"){
        exportColumn.push({ label: "Author", key: "Authors" })
      } else if(tColumn[j]?.title === "Narrator"){
        exportColumn.push({ label: "Narrator", key: "Narrator" })
      } else if(tColumn[j]?.title === "Series"){
        exportColumn.push({ label: "Series", key: "refineSeries" })
      }else if(tColumn[j]?.dataIndex === "Categories"){
        exportColumn.push({ label: "Category", key: "Categories" })
        exportColumn.push({ label: "Sub Category", key: "subCategory" });
      }else if(tColumn[j]?.title === "Format"){
        exportColumn.push({ label: "Format", key: "FileFormatName" })
      } else if(tColumn[j]?.title === "eISBN"){
        exportColumn.push({ label: "eISBN", key: "EISBN" })
      } else if(tColumn[j]?.title === "Publisher"){
        exportColumn.push({ label: "Publisher", key: "Imprint" })
      } else if(tColumn[j]?.title === "Provider"){
        exportColumn.push({ label: "Provider", key: "ContentProviderName" })
      } else if(tColumn[j]?.title === "Model"){
        exportColumn.push({ label: "Model", key: "Model" })
      } else if(tColumn[j]?.title === "Publication Date"){
        exportColumn.push({ label: "Publication Date", key: "PublicationDate" })
      } else if(tColumn[j]?.dataIndex === "LastPurchasedDate"){
        exportColumn.push({ label: "Last Purchased", key: "LastPurchasedDate" })
      } else if(tColumn[j]?.dataIndex === "LastCheckoutDate"){
        exportColumn.push({ label: "Last Circulated", key: "LastCheckoutDate" })
      }else if(tColumn[j]?.title === "Owned"){
        exportColumn.push({ label: "Owned", key: "NumberOfCopies" })
      } else if(tColumn[j]?.title === "Hold"){
        exportColumn.push({ label: "Hold", key: "NumberOfHolds" })
      } else if(tColumn[j]?.dataIndex === "NumberOfLoans"){
        exportColumn.push({ label: "All Loans", key: "NumberOfLoans" })
      }else if(tColumn[j]?.dataIndex === "NumberOnOrder"){
        exportColumn.push({ label: "Ordered", key: "NumberOnOrder" })
      }else if(tColumn[j]?.title === "In Circ."){
        exportColumn.push({ label: "Circ", key: "NumberOfCurrentLoans" })
      } else if(tColumn[j]?.title === "Suggested"){
        exportColumn.push({ label: "Suggested", key: "NumberOfWish" })
      } else if(tColumn[j]?.title === "PPU Price"){
        exportColumn.push({ label: "PPU Price", key: "refinePPUPrice" })       
      } else if(tColumn[j]?.title === "Price"){
        exportColumn.push({ label: "Price", key: "refinePrice" })
      } else if(tColumn[j]?.title === "Carts"){
        exportColumn.push({ label: "Carts", key: "NumberInCarts" })
      } else if(tColumn[j]?.title === "Shelved"){
        exportColumn.push({ label: "Shelved", key: "NumberOfShelves" })
      }
    }
    return exportColumn
  }

  const headers = headerValue()


  const csvExportList = {
    filename: `ShelfDetailsList.csv`,
    headers: headers,
    data: exportData,
  };

  useEffect(() => {
    var removPayload = {
      libraryId: shelv?.LibraryId,
      shelfId: shelv?.ShelfId,
      items: getSelectedRows,
    };
    var getShelvPayload = {
      libraryId: shelv?.LibraryId,
      catShelfId: shelv?.ShelfId,
      recordCount: 500,
      recordStart: 0,
    };
    var totalPayload = { removeItem: removPayload, fetchSelv: getShelvPayload };
    setShowRemoveBtn(getSelectedRows?.length > 0 ? true : false);
    setRemovePayload(totalPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelectedRows]);

  const handleDelete = () => {
    dispatch(removeShelvItems(removePayload));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (
      shelvDetailFormState?.loading ||
      removeformState?.loading ||
      updateShlvFormState?.loading ||
      shelvIdFormState?.loading ||
      loading
    ) {
      setIsloading(false);
    } else {
      setIsloading(true);
    }

  }, [
    shelvDetailFormState,
    removeformState,
    updateShlvFormState,
    shelvIdFormState,
    loading,
  ]);

  useEffect(() => {
    let errorr = [removeError];
    var dtat = errorr?.map((data: any) => {
      return data?.ErrorMessage;
    });
    if (dtat[0] !== undefined) {
      message.error({
        key: "error",
        content: dtat[0],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeError]);

  const additemstoshelves = () => {
    dispatch(onSelectShelve(shelv))
    history.push(AppRoutes.COLLECTION);
  };

  useEffect(() => {
    if (updateShelvError?.length > 0 && updateShelvError[0]?.ErrorDetails !== undefined) {
      message.error({
        content: updateShelvError[0]?.ErrorDetails[1],
        key: "notificationMessage",
      });
      dispatch(clearUpdateError());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShelvError]);

  useEffect(() => {
    if (updateShelvError?.length > 0 && updateShelvError[0]?.ErrorDetails !== undefined) {
      message.error({
        content: updateShelvError[0]?.ErrorDetails[1],
        key: "notificationMessage",
      });
      dispatch(clearUpdateError());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShelvError]);

  useEffect(() => {
    if (cammeFromAutoCreate) {
      if (shelvDetailById.CurrentStatus === "Ready") {
        setIsNewAutoCreated(true);
      } else {
        setIsNewAutoCreated(false);
      }
    } else {
      setIsNewAutoCreated(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cammeFromAutoCreate, shelvDetailById]);

  useEffect(() => {
    if(newAutoShelveFormState.isSuccess){
      message.success({
        content: "Shelf Created Successfully",
        key: 'success'
      })
      dispatch(clearNewShelv())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAutoShelveFormState])

  const editCriteriaBtn = () => {
      if (!(audienceData && audienceData.length)) {
      dispatch(fetchAudienceData());
      }
      if (!(providerData && providerData.length)) {
      dispatch(fetchContentProviders());
      }
      if (!(publishers && publishers.length)) {
      dispatch(fetchPublishers());
      }
      if (!(mediaTypes && mediaTypes.length)) {
      dispatch(fetchMediaTypes());
      }
      if (!(languages && languages.length)) {
      dispatch(fetchLanguages());
      }
      if (!(categoriesOptions && categoriesOptions.length)) {
      dispatch(fetchCategoryOptions());
      }
    let state = {
      createAutoShelv: {
        shelveName: shelvDetailById?.ShelfName,
        shelveDescription: shelvDetailById?.Description,
        SortStrategy: shelvDetailById?.SortStrategy,
        editCriteriaData: shelvDetailById,
      },
    };
    history.push(AppRoutes.AUTOMATEDSHELF, state);
  };

 const coloumnChange = (e: any) => {
    var checkColumnsState : any = finalColumn;
    var checkColumns = [...checkColumnsState]
    for(var j = 0; j < checkColumns.length; j++ ) {
      if(e.target.id === checkColumns[j].title) {
        let temp_col = { ...checkColumns[j] };
            temp_col.check = e.target.checked
            checkColumns[j] = temp_col
            setFinalColumn(checkColumns)
      }
    }
  }

  useEffect(() => {
    dispatch(savedShelfColumns(finalColumn))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalColumn])
  

  useEffect(() => {
    const ac = [...finalColumn]
    const a = ac?.filter((d:any) => d.check);
    setTColumns(a)
  }, [finalColumn])

  const menu = (
    <Menu className="header-dropdown">
     <Menu.Item key="export-shelf-list">
        <Row>
        <Col style={{ width: "20px" }}>
            <img style={{ width: "14px", height: "14px"}} src={ExportIcon} alt=" " />
          </Col>
          <Col style={{ paddingLeft: "5px" }}>
          <CSVLink className="linkColor shelveExport" {...csvExportList}>
              Export List
          </CSVLink>
          </Col>
        </Row>
      </Menu.Item>
      {/* <Menu.Item key="customizeColoumn">
          <Checkbox
            id="customizeColoumn"
            onChange={allColumnChange}
            checked={allColumn}
          >
            Customize Columns
          </Checkbox>
      </Menu.Item> */}
      <Menu.Item key="customizeColoumn">
        <Row>
        <Col style={{ width: "20px" }}>
            {/* <img style={{ width: "14px", height: "14px"}} src={ExportIcon} alt=" " /> */}
          </Col>
          <Col style={{ paddingLeft: "5px" }} className="shelveExport shelveDetailCol">
          Customize Columns
          </Col>
        </Row>
      </Menu.Item>
      <Menu.ItemGroup className="shelfMenuGroup">
        {finalColumn?.map((column: any, index: number) => {
          return (
            <Menu.Item key={column.title}>
              <Checkbox
                id={column.title}
                onChange={coloumnChange}
                checked={column.check}
              >
                {column.title}
              </Checkbox>
            </Menu.Item>
          );
        })}
      </Menu.ItemGroup>
    </Menu>
  );

  const handleVisibleChange = (flag: any) => {
    setVisibleMenu(flag)
  };

  return (
    <div className="shelvDetails">
      <button className="back-button" onClick={goBack}>
        {backText}
      </button>
      <Row className="shelfHeadWrapper">
        <Col sm={10} md={12} lg={9} xl={7} xxl={5}>
          <p className="page-title">
            {shelvDetailById?.ShelfName !== undefined
              ? shelvDetailById?.ShelfName
              : shelv?.ShelfName}
          </p>
        </Col>
        {/* <Col sm={6} md={6} lg={10} xl={13} xxl={15}>
          {isNewAutoCreated ? null : (
            <Button
              type="editShelfBtn"
              onClick={() => {
                setEditModalVisible(true);
              }}
            >
              Edit
            </Button>
          )}
        </Col> */}
        <Col sm={5} md={4} lg={4} xl={3} xxl={3} className="shelfHeadCol">
          {shelv?.IsManual ? (
            <Button type="primary shelveDetailBtn pink-tag shelfDetailsBtn">MANUAL</Button>
          ) : (
            <Button type="primary shelveDetailBtn blue-tag shelfDetailsBtn">AUTOMATED</Button>
          )}
          {shelv?.IsActive ? (
            <Button type="primary shelveDetailBtn green-tag shelfDetailsBtn">LIVE</Button>
          ) : (
            <Button type="primary shelveDetailBtn yellow-tag shelfDetailsBtn">DRAFT</Button>
          )}
        </Col>
      </Row>
      <Row className="shelvesDiscription-row">
        <p className="title-count">
          {shelvDetailById?.Description !== undefined
            ? shelvDetailById?.Description
            : shelv?.Description}
        </p>
      </Row>
      <SpinnerLoader
        className="purchaseTableLoader"
        loading={
          shelvDetailFormState?.loading ||
          removeformState?.loading ||
          updateShlvFormState?.loading ||
          shelvIdFormState?.loading ||
          loading
        }
      >
        <Divider className="carts-divider-top" />
        <Row className="shelfHeadWrapper ShelveExportRow">
          <Col sm={10} md={12} lg={12} xl={12} className="filter-wrapper">
            <Col sm={5} md={4} lg={4} xl={3}>
              {!isNewAutoCreated ? (
                <span className="title-count">
                  {data?.length !== 0 && data?.length !== undefined
                    ? data?.length
                    : 0}{" "}
                  Titles
                </span>
              ) : (
                <span className="title-count">Updating Titles</span>
              )}
            </Col>
            <Col
              sm={12}
              md={10}
              lg={8}
              xl={6}
              className="shelves-edit-dropdown"
            >
              <DropDown
                bordered={false}
                className="filters"
                optionValue={filterOptions.shelfFilters}
                value={filterVal}
                icon={dropdownIcon}
                onChange={handelFilter}
                disabled={isNewAutoCreated ? true : false}
              />
            </Col>
          </Col>
          <Col
            sm={14}
            md={12}
            lg={8}
            xl={7}
            className="exportBtnWrapper shelves-buttons"
          >
            {/* <CSVLink className="shelvesExport linkColor" {...csvExportList}>
              Export Shelf List
            </CSVLink> */}
            <Col span={10} className="exportBtnWrapper shelves-addItem">
              {!shelv?.IsManual ? (
                <Button type="primary" onClick={editCriteriaBtn}>
                  Edit Criteria
                </Button>
              ) : null}
              {shelvDetailById.IsManual ? (
                <Button type="primary" onClick={additemstoshelves}>
                  Add Items
                </Button>
              ) : null}
            </Col>
            <Col span={12}>
            <div className="history-editor-Button">
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                onVisibleChange={handleVisibleChange}
                visible={visibleMenu}
              >
                <button className="button-type editor">
                  <img src={editIcon} className="edit-icon" alt="" /> Editor
                  &nbsp; &nbsp;
                  <img src={downIcon} className="down-icon" alt="" />
                </button>
              </Dropdown>
            </div>
            </Col>
            <Col span={1}></Col>
          </Col>
        </Row>
        <Divider className="carts-divider-top" />
        {!isNewAutoCreated ? (
          <Table
            rowSelection={rowSelection}
            locale={{emptyText:<Empty description="No results found."/>}}
            className="cart-table checkboxClickableAreaWiden"
            columns={tColumn}
            size="small"
            pagination={false}
            dataSource={data}
            components={components}
            showSorterTooltip={false}
            scroll={{ y: "calc(100vh - 340px)", x: window.length }}
          />
        ) : (
          <span>
            <span className="shelvdetailUpdatingShelv">Updating Shelf</span>
            <Divider className="automated-divider-bottom" />
          </span>
        )}

        <Row className="shelvesfooterbtn">
          <Col sm={22} md={9} lg={13} xl={15} className="removebtnMargin">
            {showRemoveBtn && shelv?.IsManual ? (
              <span
                style={{ width: "100%" }}
                className="removeBtnLink removeBtnAdjusted"
              >
                <Button
                  onClick={() => setShowRemove(true)}
                  type="primary mark-ready-button"
                >
                  Remove selected
                </Button>
              </span>
            ) : null}
          </Col>
          {showSaveBtn ? (
            <Col sm={22} md={14} lg={10} xl={8} className="exportBtnWrapper">
              {isloading && !isNewAutoCreated? (
                <Button
                  onClick={onHandleSaveChange}
                  type="secondary cancel-button"
                >
                  Save Changes
                </Button>
              ) : null}
              {shelv?.IsActive === true ? (
                <Button
                  disabled={isNewAutoCreated ? true : false}
                  onClick={onHandlePublish}
                  type={`primary cancel-button ${isNewAutoCreated?"saveChangeBtnRemoved":""}`}
                >
                  Unpublish
                </Button>
              ) : (
                <Button
                  disabled={isNewAutoCreated ? true : false}
                  onClick={onHandlePublish}
                  type={`primary cancel-button ${isNewAutoCreated?"saveChangeBtnRemoved":""}`}
                >
                  Publish
                </Button>
              )}
            </Col>
          ) : (
            <Col sm={22} md={14} lg={10} xl={8} className="exportBtnWrapper">
              {shelv?.IsActive === true ? (
                <Button
                  disabled={isNewAutoCreated ? true : false}
                  onClick={() => onHandlePublish("2")}
                  type={`primary cancel-button ${
                    showSaveBtn ? null : "unpublish-button"
                  }`}
                >
                  Unpublish
                </Button>
              ) : (
                <Button
                  disabled={isNewAutoCreated ? true : false}
                  onClick={() => onHandlePublish("1")}
                  type={`primary cancel-button ${
                    showSaveBtn ? null : "unpublish-button"
                  }`}
                >
                  Publish
                </Button>
              )}
            </Col>
          )}
        </Row>
      </SpinnerLoader>
      <RemoveConfirmModel
        showRemove={showRemove}
        setShowRemove={setShowRemove}
        setButtonClicked={setButtonClicked}
        setSelectedRow={setSelectedRow}
        removeConfirm={handleDelete}
        heading={"Remove Confirmation"}
        message={
          "Are you sure you want to remove the selected book(s) from the Shelf?"
        }
      />
    </div>
  );
};
