import { Col, Divider, Row } from "antd";
import { TooltipComponent } from "../../../stateless/common/tooltip";
import { useSelector } from "react-redux";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";

const ActivityBar = ({
  yourPatronHolds,
  cloudLinkPatronHolds,
  infoMessage,
}: any) => {
  const { patronHoldsMessage, cloudLinkHoldsMessage } = infoMessage;

  const { appUser }: any = useSelector(userStateSelector);
  const hasCloudLink = appUser?.libraryData[0].ConsortiumId;

  return (
    <>
      <Row>
        <Col span={24} className="activity-container">
          <h1>Current Activity</h1>
          <Row>
            <Col span={11}>
              <div className="activity-stats-left ">
                <div>
                  <p className="pr-15 position-relative no-wrap">
                    Your Patron Holds
                    <TooltipComponent message={patronHoldsMessage} />
                  </p>
                  <h1>{yourPatronHolds.toLocaleString()}</h1>
                </div>
              </div>
            </Col>
            <Col span={2} className="activity-bar-divider">
              <Divider type="vertical" className="verticalDivider" />
            </Col>
            <Col span={11}>
              <div className="activity-stats-right">
                <div>
                  <p className="pr-15 position-relative no-wrap">
                    cloudLink Patron Holds
                    <TooltipComponent message={cloudLinkHoldsMessage} />
                  </p>

                  <h1>
                    {cloudLinkPatronHolds === 0
                      ? hasCloudLink
                        ? 0
                        : "N/A"
                      : cloudLinkPatronHolds.toLocaleString()}{" "}
                  </h1>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ActivityBar;
