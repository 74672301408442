import { Popover } from "antd";
import { infoIcon } from "../../../../images";

export const TooltipComponent = ({ message, width }: any) => {
  return (
    <Popover
      className="reports-info-icon"
      content={<div style={{ width: width || "500px" }}> {message} </div>}
      trigger={["hover", "click"]}
      placement="bottomLeft"
      getPopupContainer={(trigger: any) => trigger.parentNode.parentNode}
    >
      {<img className="info-icon" src={infoIcon} alt="info" />}
    </Popover>
  );
};
