import { message } from "antd";
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { showError } from "../../../utility/appUtil";
import { convertAndDownloadBlob } from "../../../utility/utils";
import moment from "moment";

export class MarcService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.GROUPS));
  }

  async fetchMarcByDateRange(params: any): Promise<any> {
    var libraryId = params.libraryId;
    var userId = params.userId;
    var contentType = params.contentType;
    var isPreSale = params.isPreSale;
    var expiredFilter = params.expiredFilter;
    var existingFilter = params.existingFilter;
    var format = params.format;
    var startDate = params.startDate;
    var endDate = params.endDate;
    var page = params.page;
    var pageSize = params.pageSize;
    var todayDate = params?.todayDate;
    var libraryName = params?.libraryName
    var name = `${todayDate}_${isPreSale ? "pre-sale_" : ""}${contentType}_${libraryName}`

    var baseUrl = localStorage.getItem("environment")
      ? localStorage.getItem("environment")
      : "";
    if (!baseUrl?.endsWith('/')) {
      baseUrl += '/';
    }
    const url = `${baseUrl}getRecordByDateRange?libraryId=${libraryId}&userId=${userId}&contentType=${contentType}&isPreSale=${isPreSale}&expiredFilter=${expiredFilter}&format=${format}&existingFilter=${existingFilter}&startDate=${startDate}&endDate=${endDate}${page ? `&page=${page}` + "&" : ""
      }${pageSize ? `pageSize=${pageSize}` + "&" : ""}`;
    const response = await this.get(url, {
      method: "GET",
    });


    if (response?.data) {

      return response;
    } else {
      // response.json().then(function (data) {
      if (response?.data.httpStatusCode === 404) {
        message.error({
          content: "Sorry! No marc data available for requested purchase date range. Please adjust your dates and try again.",
          duration: 4,
        });
      } else if(response?.status === 429) {
        // do nothing
      }
      else {
        message.error({
          content: response?.data?.errorMessage,
          duration: 4,
        });
      }
      return { status: "ERROR", errorMsg: response?.data?.errorMessage };
    };

  }

  async fetchMarcByIsbn(params: any): Promise<any> {
    var libraryId = params.libraryId;
    var libraryName = params.libraryName;
    var format = params.format;
    var isbn = params.isbn;
    var baseUrl = localStorage.getItem("environment")
      ? localStorage.getItem("environment")
      : "";
    if (!baseUrl?.endsWith('/')) {
      baseUrl += '/';
    }
    const url = `${baseUrl}getRecordByIsbn?libraryId=${libraryId}&format=${format}&isbn=${isbn}`;
    const response = await fetch(url, {
      method: "GET",
    });
    const contentLength: any = response.headers.get("Content-type");
    const containsTextMarc = contentLength.includes("text/marc");
    const containsTextXml = contentLength.includes("text/xml");
    if (containsTextMarc || containsTextXml) {
      let type = "mrc";
      if (containsTextXml) {
        type = "xml";
      }
      var fileName = `${isbn + "_" + libraryName}.${type}`;
      convertAndDownloadBlob(response, fileName);
      return { status: "SUCCESS" };
    } else {
      response.json().then(function (data) {
        if (data?.data?.status === 404) {
          message.error({
            content: "Sorry! No marc data available for requested ISBN.",
            duration: 4,
          });
        } else {
          message.error({
            content: data?.data?.message,
            duration: 4,
          });
        }
        return { status: "ERROR", errorMsg: data?.data?.message };
      });
    }
  }

  async fetchLatestStatusOfTask(params: any) {
    const { libraryId, userId } = params;
    const response = await this.get(`getLatestStatusOfTask/${libraryId}?userId=${userId}`);
    if (response.data.success === 1) {
      return response;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    }
  }

  async fetchStatusOfMarcTask(params: any) {
    const { libraryId, taskId } = params;
    const response = await this.get(`/library/${libraryId}/tasks/${taskId}`);

    if (response.data.success === 1) {
      var statusResponse = response?.data?.data
      return statusResponse;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    }
  }

  // async fetchMarcDownload(params: any) {
  //   var  id  = params?.id;
  //   var baseUrl = localStorage.getItem("environment")
  //     ? localStorage.getItem("environment")
  //     : "";
  // if (!baseUrl?.endsWith('/')) {
  //   baseUrl += '/';
  // }
  //   const response = await fetch(`getMarcDownload?id=${id}`,{method:"GET"});
  //   const contentLength: any = response.headers.get("Content-type");
  //   const containsTextMarc = contentLength.includes("text/marc");
  //   const containsTextXml = contentLength.includes("text/xml");
  //   if (containsTextMarc || containsTextXml) {
  //     let type = "mrc";
  //     if (containsTextXml) {
  //       type = "xml";
  //     }
  //     var fileName = `file_${id}.${type}`;
  //     convertAndDownloadBlob(response, fileName);
  //     return { status: "SUCCESS" };
  //   } else {
  //     response.json().then(function (data) {
  //       if (data?.data?.status === 404) {
  //         message.error({
  //           content: "Sorry! No marc data available for requested Id.",
  //           duration: 4,
  //         });
  //       } else {
  //         message.error({
  //           content: data?.data?.message,
  //           duration: 4,
  //         });
  //       }
  //       return { status: "ERROR", errorMsg: data?.data?.message };
  //     });
  //   }
  // }

  async fetchMarcDownload(params: any) {
    const currDate = moment();

    var id = params?.id;
    var todayDate = currDate.format("YYYYMMDD");
    var isPreSale = params.isPreSale;
    var contentType = params.contentType;
    var libraryName = params?.libraryName;
    var format = params?.format;
    let currentDate = new Date();
    let hours = String(currentDate.getHours()).padStart(2, '0');
    let minutes = String(currentDate.getMinutes()).padStart(2, '0');
    let seconds = String(currentDate.getSeconds()).padStart(2, '0');
    let currentTimeForFile = `${hours}${minutes}${seconds}`;

    var baseUrl = localStorage.getItem("environment") || ""; // Use default empty string if not found
    if (!baseUrl?.endsWith('/')) {
      baseUrl += '/';
    }
    const url = `${baseUrl}getMarcDownload?id=${id}&format=${format}`;
    var name = `${todayDate}_${`${currentTimeForFile}`}_${isPreSale ? "pre-sale_" : ""}${contentType}_${libraryName}`

    try {
      const response = await fetch(url, { method: "GET" });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const contentType = response.headers.get("Content-type") || "";

      const containsTextMarc = contentType.includes("application/marc") || contentType.includes("text/marc");
      const containsTextXml = contentType.includes("application/xml") || contentType.includes("text/xml");

      if (containsTextMarc || containsTextXml) {
        let type;
        if (containsTextXml) {
          type = "xml";
        } else if (containsTextMarc) {
          type = "mrc";
        }
        var fileName = `${name}.${type}`;
        await convertAndDownloadBlob(response, fileName);
        return { status: "SUCCESS" };
      } else {
        throw new Error("Unexpected content type");
      }

    } catch (error: any) {
      if (error.message.includes("Unexpected content type")) {
        message.error({
          content: "The file type returned is not supported.",
          duration: 4,
        });
      } else if (error.message.includes("HTTP error")) {
        message.error({
          content: "Failed to fetch the file. Please try again.",
          duration: 4,
        });
      } else {
        message.error({
          content: "An error occurred while fetching the file.",
          duration: 4,
        });
      }
      return { status: "ERROR", errorMsg: error.message };
    }
  }

}