/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from "react";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword} from "../../../../../../redux/actions/user/authAction";
import { userStateSelector, clearState, clearErrorMsg } from "../../../../../../redux/reducers/user/userReducer";
import SpinnerLoader from "../../../../stateless/common/spinnerLoader";
import "./validate.less";
import { ForgetPassword } from "../../../../stateless/forgetPassword/forgetPwd";

export const ForgotPwd = (props: any) => {
  const dispatch = useDispatch();
  const { formState,forgotPwdError,forgotPwd } = useSelector(userStateSelector);
  const [appVersion, setAppVersion] = useState<string>(JSON.stringify(localStorage?.getItem("appVersion")));
  const [showLoading] = useState(false);
  useEffect(()=>{
    setAppVersion(JSON.stringify(localStorage.getItem("appVersion")))
  },[localStorage.getItem("appVersion")])

  const onLoginCallback = useCallback((data:any) => {
    if (!data.email) {
      return;
    }
    dispatch(forgotPassword(data.email));
  }, []);

  useEffect(()=>{
    return(()=>{
      dispatch(clearErrorMsg())
      dispatch(clearState())
    })
  },[])

  useEffect(()=>{
    if(formState?.isError){
      if(forgotPwdError !== ''){
        message.error({
          content: forgotPwdError,
          key: 'error'
        })
        dispatch(clearErrorMsg())
      }
    }
  },[forgotPwdError,formState])

  useEffect(()=>{
    if(forgotPwd !== ''){
      message.success({
        content: forgotPwd,
        key: "notificationMessage",
      })
      dispatch(clearState())
    }
  },[forgotPwd,formState])
  
  return (
    <>
    {showLoading}
     <SpinnerLoader loading={formState.loading}>
     <SpinnerLoader className="nestedLoading" loading={showLoading}>
       {
         !showLoading?
         <ForgetPassword formState={formState} appVersion={appVersion} loginCallback={onLoginCallback}/>:
         <></>
       }
      </SpinnerLoader>
      </SpinnerLoader>
    </>
  )
}