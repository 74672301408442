import { Tooltip } from "antd";
import { AppRoutes } from "../../../router/appRoutes";
import PublicationDate from "../carts/cartDetails/publicationDate";
import Button from "../../stateless/common/button";
import { CartPopup } from "../carts/cartDetails/cartPopup";

export const allPurchaseCulm = (purchaseDetailsList: any, currency: any) => {
  var data = [
    {
      width: 50,
      title: "Purchase Number",
      dataIndex: "PurchaseOrderId",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) => a.PurchaseOrderId - b.PurchaseOrderId,
      render: (PurchaseOrderId: number, row: any) => {
        return (
          <Tooltip title={PurchaseOrderId}>
            <p
              onClick={() => purchaseDetailsList(row)}
              className="column bold-titles"
            >
              {PurchaseOrderId}
            </p>
          </Tooltip>
        );
      },
    },
    {
      width: 150,
      title: "PO Reference",
      dataIndex: "ExternalRefId",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) =>
        a.ExternalRefId.localeCompare(b.ExternalRefId),
      render: (ExternalRefId: number) => {
        return (
          <Tooltip title={ExternalRefId}>
            <p className="column bold-titles">{ExternalRefId}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Date",
      dataIndex: "PurchaseOrderDate",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) =>
        a.PurchaseOrderDate.localeCompare(b.PurchaseOrderDate),
      render: (Date: string) => {
        return <PublicationDate date={Date} />;
      },
    },
    {
      width: 50,
      title: "Buyer",
      dataIndex: "BuyerId",
      className: "column tableBack",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a.BuyerId.localeCompare(b.BuyerId),
      render: (BuyerId: string) => {
        return (
          <Tooltip title={BuyerId}>
            <p className="column bold-titles">{BuyerId}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Copies",
      dataIndex: "NumberOfCopies",
      className: "column tableBack",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a.NumberOfCopies - b.NumberOfCopies,
      render: (NumberOfCopies: number) => {
        return (
          <Tooltip title={NumberOfCopies}>
            <p className="column bold-titles">{NumberOfCopies}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Amount",
      dataIndex: "TotalPurchaseAmount",
      className: "column tableBack",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a.TotalPurchaseAmount - b.TotalPurchaseAmount,
      render: (TotalPurchaseAmount: any) => {
        return (
          <Tooltip title={`${currency}${(Number.parseFloat(TotalPurchaseAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}`}>
            <p className="column bold-titles">
              {currency}
              {(Number.parseFloat(TotalPurchaseAmount).toFixed(2))?.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}
            </p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Status",
      dataIndex: "IsComplete",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) => a.IsComplete - b.IsComplete,
      render: (IsComplete: boolean) => {
        return (
          <Tooltip title={IsComplete ? "Completed" : "Pending"}>
            <p
              className={`${
                IsComplete
                  ? "completed column bold-titles"
                  : "pending column bold-titles"
              }`}
            >
              {IsComplete ? "Completed" : "Pending"}
            </p>
          </Tooltip>
        );
      },
    },
  ];

  return data;
};

export const completedCulm = (purchaseDetailsList: any, currency: any) => {
  var data = [
    {
      width: 50,
      title: "Purchase Number",
      dataIndex: "PurchaseOrderId",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) => a.PurchaseOrderId - b.PurchaseOrderId,
      render: (PurchaseOrderId: number, row: any) => {
        return (
          <Tooltip title={PurchaseOrderId}>
            <p
              onClick={() => purchaseDetailsList(row)}
              className="column bold-titles"
            >
              {PurchaseOrderId}
            </p>
          </Tooltip>
        );
      },
    },
    {
      width: 150,
      title: "PO Reference",
      dataIndex: "ExternalRefId",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) =>
        a.ExternalRefId.localeCompare(b.ExternalRefId),
      render: (ExternalRefId: number) => {
        return (
          <Tooltip title={ExternalRefId}>
            <p className="column bold-titles">{ExternalRefId}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Date",
      dataIndex: "PurchaseOrderDate",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) =>
        a.PurchaseOrderDate.localeCompare(b.PurchaseOrderDate),
      render: (Date: string) => {
        return <PublicationDate date={Date} />;
      },
    },
    {
      width: 50,
      title: "Buyer",
      dataIndex: "BuyerId",
      className: "column tableBack",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a.BuyerId.localeCompare(b.BuyerId),
      render: (BuyerId: string) => {
        return (
          <Tooltip title={BuyerId}>
            <p className="column bold-titles">{BuyerId}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Copies",
      dataIndex: "NumberOfCopies",
      className: "column tableBack",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a.NumberOfCopies - b.NumberOfCopies,
      render: (NumberOfCopies: number) => {
        return (
          <Tooltip title={NumberOfCopies}>
            <p className="column bold-titles">{NumberOfCopies}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Amount",
      dataIndex: "TotalPurchaseAmount",
      className: "column tableBack",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a.TotalPurchaseAmount - b.TotalPurchaseAmount,
      render: (TotalPurchaseAmount: any) => {
        return (
          <Tooltip title={`${currency}${(Number.parseFloat(TotalPurchaseAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}`}>
            <p className="column bold-titles">
              {currency}
              {(Number.parseFloat(TotalPurchaseAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}
            </p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Status",
      dataIndex: "IsComplete",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) => a.IsComplete - b.IsComplete,
      render: (IsComplete: boolean) => {
        return (
          <Tooltip title={IsComplete ? "Completed" : "Pending"}>
            <p
              className={`${
                IsComplete
                  ? "completed column bold-titles"
                  : "pending column bold-titles"
              }`}
            >
              {IsComplete ? "Completed" : "Pending"}
            </p>
          </Tooltip>
        );
      },
    },
  ];

  return data;
};

export const pendingCulm = (purchaseDetailsList: any, currency: any) => {
  var data = [
    {
      width: 50,
      title: "Purchase Number",
      dataIndex: "PurchaseOrderId",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) => a.PurchaseOrderId - b.PurchaseOrderId,
      render: (PurchaseOrderId: number, row: any) => {
        return (
          <Tooltip title={PurchaseOrderId}>
            <p
              onClick={() => purchaseDetailsList(row)}
              className="column bold-titles"
            >
              {PurchaseOrderId}
            </p>
          </Tooltip>
        );
      },
    },
    {
      width: 150,
      title: "PO Reference",
      dataIndex: "ExternalRefId",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) =>
        a.ExternalRefId.localeCompare(b.ExternalRefId),
      render: (ExternalRefId: number) => {
        return (
          <Tooltip title={ExternalRefId}>
            <p className="column bold-titles">{ExternalRefId}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Date",
      dataIndex: "PurchaseOrderDate",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) =>
        a.PurchaseOrderDate.localeCompare(b.PurchaseOrderDate),
      render: (Date: string) => {
        return <PublicationDate date={Date} />;
      },
    },
    {
      width: 50,
      title: "Buyer",
      dataIndex: "BuyerId",
      className: "column tableBack",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a.BuyerId.localeCompare(b.BuyerId),
      render: (BuyerId: string) => {
        return (
          <Tooltip title={BuyerId}>
            <p className="column bold-titles">{BuyerId}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Copies",
      dataIndex: "NumberOfCopies",
      className: "column tableBack",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a.NumberOfCopies - b.NumberOfCopies,
      render: (NumberOfCopies: number) => {
        return (
          <Tooltip title={NumberOfCopies}>
            <p className="column bold-titles">{NumberOfCopies}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Amount",
      dataIndex: "TotalPurchaseAmount",
      className: "column tableBack",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a.TotalPurchaseAmount - b.TotalPurchaseAmount,
      render: (TotalPurchaseAmount: any) => {
        return (
          <Tooltip title={`${currency}${(Number.parseFloat(TotalPurchaseAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}`}>
            <p className="column bold-titles">
              {currency}
              {(Number.parseFloat(TotalPurchaseAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}
            </p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Status",
      dataIndex: "IsComplete",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) => a.IsComplete - b.IsComplete,
      render: (IsComplete: boolean) => {
        return (
          <Tooltip title={IsComplete ? "Completed" : "Pending"}>
            <p
              className={`${
                IsComplete
                  ? "completed column bold-titles"
                  : "pending column bold-titles"
              }`}
            >
              {IsComplete ? "Completed" : "Pending"}
            </p>
          </Tooltip>
        );
      },
    },
  ];

  return data;
};

export const purchaseDetailsCol = (history: any, getAuthor: any ,currency:any) => {
  return [
    {
      title: "Title",
      dataIndex: "Title",
      key: "Tit",
      width: 50,
      ellipsis: true,
      className: "column",
      render: (Title: string, row: any) => {
        return (
          <Tooltip title={Title}>
            <p
              className="column bold-titles"
              onClick={() =>
                history.push(AppRoutes.ITEMDETAILS, {
                  book: row,
                })
              }
            >
              {Title}
            </p>
          </Tooltip>
        );
      },
      sorter: (a: any, b: any) => a.Title.localeCompare(b.Title),
    },
    {
      title: "Authors",
      dataIndex: "Authors",
      key: "Auth",
      width: 50,
      ellipsis: true,
      className: "column",
      render: (Authors: string, row: any) => {
        return (
          <Tooltip title={Authors}>
            <p className="column bold-titles" onClick={() => getAuthor(row)}>
              {Authors}
            </p>
          </Tooltip>
        );
      },
      sorter: (a: any, b: any) => a.Authors.localeCompare(b.Authors),
    },
    {
      title: "eISBN",
      dataIndex: "EISBN",
      key: "EIS",
      width: 50,
      ellipsis: true,
      className: "column",
      render: (EISBN: number) => {
        return (
          <Tooltip title={EISBN}>
            <p className="column bold-titles">{EISBN}</p>
          </Tooltip>
        );
      },
      sorter: (a: any, b: any) => a.EISBN - b.EISBN,
    },
    {
      title: "Unit Price",
      dataIndex: "NetAmount",
      key: "NetAmo",
      width: 50,
      ellipsis: true,
      className: "column",
      render: (NetAmount: any) => {
        return (
          <Tooltip title={`${currency}${(Number.parseFloat(NetAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}`}>
            <p className="column bold-titles">
            {currency}
              {(Number.parseFloat(NetAmount).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}
            </p>
          </Tooltip>
        );
      },
      sorter: (a: any, b: any) => a.NetAmount - b.NetAmount,
    },
    {
      title: "Quan.",
      dataIndex: "Quantity",
      key: "Quan",
      width: 50,
      ellipsis: true,
      className: "column",
      render: (Quantity: number) => {
        return (
          <Tooltip title={Quantity}>
            <p className="column bold-titles">{Quantity}</p>
          </Tooltip>
        );
      },
      sorter: (a: any, b: any) => a.Quantity - b.Quantity,
    },
    {
      title: "Status",
      dataIndex: "PurchaseOrderStatusCode",
      key: "PurchaseOrderStatu",
      width: 50,
      ellipsis: true,
      className: "column",
      render: (PurchaseOrderStatusCode: string) => {
        return (
          <Tooltip title={PurchaseOrderStatusCode}>
            <p className="column bold-titles">{PurchaseOrderStatusCode}</p>
          </Tooltip>
        );
      },
      sorter: (a: any, b: any) =>
        a.PurchaseOrderStatusCode.localeCompare(b.PurchaseOrderStatusCode),
    },
    {
      title: "Message",
      dataIndex: "ResultMessage",
      key: "ResultMes",
      width: 50,
      ellipsis: false,
      className: "column",
      render: (ResultMessage: string) => {
        return (
          <Tooltip title={ResultMessage}>
            <p className="column bold-titles">{ResultMessage}</p>
          </Tooltip>
        );
      },
      sorter: (a: any, b: any) =>
        a.ResultMessage.localeCompare(b.ResultMessage),
    },
  ];
};

export const autoCartHistory = (
  buyerImgUrl: any,
  notFound: any,
  history: any,
  currency: any
) => {
  return [
    {
      width: 50,
      title: " ",
      dataIndex: "ImageId",
      ellipsis: true,
      className: "historyBold",
      render: (ImageId: any, row: any) => {
        return (
          <div className="title_colm">
            {JSON.stringify(ImageId) !== "null" ? (
              <span>
                <img
                  className="item-cover"
                  src={`https://${buyerImgUrl}?type=DOCUMENTIMAGE&documentID=${row["ImageId"]}&size=SMALL&token=nobody`}
                  alt=""
                  onError={(e: any) => {
                    e.target.src = notFound;
                  }}
                />
              </span>
            ) : null}
          </div>
        );
      },
    },
    {
      width: 50,
      title: "Date",
      dataIndex: "Date",
      ellipsis: true,
      className: "historyBold",
      check: true,
      sorter: (a: any, b: any) => a?.Date.localeCompare(b?.Date),
      render: (Date: string) => {
        return <PublicationDate className="column bold-titles" date={Date} />;
      },
    },
    {
      width: 50,
      title: "Auto -Purchased",
      dataIndex: "Purchased",
      ellipsis: true,
      className: "historyBold",
      check: true,
      sorter: (a: any, b: any) => a?.Purchased - b?.Purchased,
      render: (Purchased: boolean) => {
        return (
          <Tooltip title={Purchased}>
            <p className="column bold-titles">{Purchased ? "Y" : "N"}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 150,
      title: "Title",
      dataIndex: "Title",
      className: "historyBold",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a?.Title - b?.Title,
      render: (Title: string, row: any) => {
        return (
          <Tooltip title={Title}>
            <p
              className="book-name-link bold-titles textUnder"
              onClick={() => history.push(AppRoutes.ITEMDETAILS, { book: row })}
            >
              {Title}
            </p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Media",
      dataIndex: "MediaTypeId",
      className: "historyBold",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a?.MediaTypeId - b?.MediaTypeId,
      render: (MediaTypeId: number) => {
        return (
          <Tooltip title={MediaTypeId !== 2 ? "Ebook" : "Audiobook"}>
            <p className="column bold-titles">
              {MediaTypeId !== 2 ? "Ebook" : "Audiobook"}
            </p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "eISBN",
      dataIndex: "EISBN13",
      className: "historyBold",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a?.EISBN13 - b?.EISBN13,
      render: (EISBN13: number) => {
        return (
          <Tooltip title={EISBN13}>
            <p className="column bold-titles">{EISBN13}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "	Quantity",
      dataIndex: "Quantity",
      ellipsis: true,
      className: "historyBold",
      check: true,
      sorter: (a: any, b: any) => a?.Quantity - b?.Quantity,
      render: (Quantity: boolean) => {
        return (
          <Tooltip title={Quantity}>
            <p className="column bold-titles">{Quantity}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "	Price",
      dataIndex: "Cost",
      ellipsis: true,
      className: "historyBold",
      check: true,
      sorter: (a: any, b: any) => a?.Cost - b?.Cost,
      render: (Cost: any) => {
        return (
          <Tooltip title={`${currency}${(Number.parseFloat(Cost).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}`}>
            {JSON.stringify(Cost)!== "null" ?
            <p className="column bold-titles">
              {currency}
              {(Number.parseFloat(Cost).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g,',')}
            </p>
            :""}
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Type",
      dataIndex: "Type",
      ellipsis: true,
      className: "historyBold",
      check: true,
      sorter: (a: any, b: any) => a?.Type - b?.Type,
      render: (Type: any) => {
        return (
          <Tooltip title={Type}>
            <p className="column bold-titles">{Type}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "	Note",
      dataIndex: "Note",
      ellipsis: false,
      className: "historyBold",
      check: true,
      sorter: (a: any, b: any) => a?.Note - b?.Note,
      render: (Note: any) => {
        return (
          <Tooltip title={Note}>
            <p className="column bold-titles">{Note}</p>
          </Tooltip>
        );
      },
    },
  ];
};


export const holdMngmnt = (movePosition: any, history:any, getAuthor:any) => {
  var data = [
    {
      width: 50,
      title: "Title",
      dataIndex: "title",
      key:"title",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) => a.title.localeCompare(b.title),
      render: (title: any, row: any) => {
        return (
          <Tooltip title={title}>
            <p
              className="column bold-titles"
            >
              {title}
            </p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Author",
      dataIndex: "auther",
      key:"auther",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      sorter: (a: any, b: any) => a.auther.localeCompare(b.auther),
      render: (auther: any, row:any) => {
        return (
          <Tooltip title={auther}>
            <p className="column bold-titles" 
            >{auther}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Hold Created",
      dataIndex: "CreateDate",
      key:"CreateDate",
      ellipsis: true,
      className: "column tableBack",
      check: true,
      render: (CreateDate: string) => {
        return <PublicationDate date={CreateDate} />;
      },
    },
    // {
    //   width: 50,
    //   title: "Hold Position",
    //   dataIndex: "Position",
    //   className: "column tableBack",
    //   key:"Position",
    //   ellipsis: true,
    //   check: true,
    //   sorter: (a: any, b: any) => a.Position - b.Position,
    //   render: (Position: number) => {
    //     return (
    //       <Tooltip title={Position}>
    //         <p className="column bold-titles">{Position + 1}</p>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      width: 50,
      title: "ISBN",
      dataIndex: "ISBN",
      key:"ISBN",
      className: "column tableBack",
      ellipsis: true,
      check: true,
      sorter: (a: any, b: any) => a.ISBN - b.ISBN,
      render: (ISBN: any) => {
        return (
          <Tooltip title={ISBN}>
            <p className="column bold-titles">{ISBN}</p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "",
      dataIndex: "Position",
      key:"Position",
      className: "column tableBack",
      render: (Position: number, row: any) => {
        return (
            <>
            {(Position === 0 ) ?
              <Button
              // onClick={() => movePosition(row)}
              type="secondary cancel-button holdbtn"
            >
            Patron is in #1 position
            </Button>
            :
                <Button
              onClick={() => movePosition(row)}
              type="secondary cancel-button holdbtn"
            >
             Move To #1 position
            </Button>
            }
            </>
        );
      },
    },
  ];

  return data;
};

// library staff screen
export const libraryStuffCol = () =>{
  let data = [
    {
      width: 50,
      title: "First Name",
      dataIndex: "FirstName",
      key:"firstname",
      ellipsis: true,
      sorter: (a: any, b: any) => a.FirstName.localeCompare(b.FirstName),
    },
    {
      width: 50,
      title: "Last Name",
      dataIndex: "LastName",
      key:"lastname",
      ellipsis: true,
      sorter: (a: any, b: any) => a.LastName.localeCompare(b.LastName),
    },
    {
      width: 50,
      title: "Email",
      dataIndex: "EmailAddress",
      key:"email",
      ellipsis: true,
      sorter: (a: any, b: any) => a.EmailAddress.localeCompare(b.EmailAddress),
    },
    {
      width: 50,
      title: "Status",
      dataIndex: "IsActive",
      key:"status",
      ellipsis: true,
      sorter: (a: any, b: any) => a.IsActive - b.IsActive,
      render: (IsActive: boolean, row: any) => {
        return (
            <>
            { IsActive ? 
             <span className="libraryActive">Active</span>
              :
              <span className="libraryInActive">Inactive</span>
            }
            </>
        );
      },
    }
  ]
  return data;
}

//shelv deatils header  
export const shelvDetailHeader = (
  imageUrl: any,
  notFound: any,
  history: any,
  getAuthor:any,
  fetchInfo:any,
  currency:any,
  getBookLabel:any
) => {
  return [
    {
      title: "Cover",
      dataIndex: ['ImageId','FileFormatName'],
      width: 150,
      key: "ImageId",
      ellipsis: true,
      check: true,
      render: (text: any, row: any) => {
        return (
          <div className="CartCoverColm">
          <img
            className="item-cover"
            src={`https://${imageUrl}?type=DOCUMENTIMAGE&documentID=${row["ImageId"]}&size=LARGE&token=nobody`}
            alt=""
            onClick={() => history.push(AppRoutes.ITEMDETAILS, { book: row })}
            onError={(e: any) => {
              e.target.src = notFound;
            }}
          />
          <img
              src={getBookLabel(row['FileFormatName'])}
              alt=""
              className="cart-book-label"
            />
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      width: 200,
      ellipsis: true,
      check: true,
      render: (text: any, row: any) => {
        return (
          <div className="title_colm shelves-edit-title-padding">
            <span className="cover_conten">
              <p className="ItemState">
                {row["ItemState"] === "PRE_SALE" ? (
                  <span className="book-state-carts">PRE SALE</span>
                ) : row["ItemState"] === "NOT_FOR_SALE" ? (
                  <span className="notFor_sale">NOT FOR SALE</span>
                ) : (
                 ""
                )}
              </p>
              <Tooltip title={row["Title"]}>
              <p
                className="col-font-size book-name-link bold-titles textUnder"
                onClick={() => history.push(AppRoutes.ITEMDETAILS, { book: row })}
              >
                {row["Title"]}
              </p>
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Author",
      dataIndex: "Authors",
      key: "Authors",
      width: 100,
      ellipsis: true,
      check: true,
      render: (Authors: string, row: any) => {
        return (
          <Tooltip title={Authors}>
            <p className="column bold-titles author-padding" onClick={() => getAuthor(row)}>
              {Authors}
            </p>
          </Tooltip>
        );
      },
    },
    {
      width: 50,
      title: "Narrator",
      dataIndex: "Narrator",
      ellipsis: true,
      className: "column",
      check: true,
    },
    {
      width:50,
      title: "Series",
      dataIndex: ['Series','NumberInSeries'],
      ellipsis: true,
      className: "column",
      check: true,
      render:(text: any, row: any)=>{
        return(
          <>
          <p className="series-padding">{row["Series"]}</p>
          {row["NumberInSeries"] !== ""
          ?
          <p className="series-padding"> #{row["NumberInSeries"]} </p>
          :
          null
        }
          </>
        )
      }
    },
    {
      title: "Categories",
      dataIndex: "Categories",
      key: "Categories",
      width: 50,
      ellipsis: true,
      check: true,
    },
    {
      title: "Format",
      dataIndex: "FileFormatName",
      key: "FileFormatName",
      width: 50,
      ellipsis: true,
      check: true,
    },
    {
      title: "eISBN",
      dataIndex: "EISBN",
      key: "EISBN",
      width: 50,
      ellipsis: true,
      check: true,
    },
    {
      width: 50,
      title: "Publisher",
      dataIndex: "Imprint",
      ellipsis: true,
      className: "column",
      check: true,
    },
    {
      title: "Provider",
      dataIndex: "ContentProviderName",
      key: "ContentProviderName",
      width: 50,
      ellipsis: true,
      check: true,
    },
    {
      title: "Model",
      dataIndex: "Model",
      key: "Model",
      width: 50,
      ellipsis: true,
      check: true,
    },
    {
      width: 50,
      title: "Publication Date",
      dataIndex: "PublicationDate",
      ellipsis: true,
      className: "column",
      check: true,
      render:(PDate:Text)=>{
        return <PublicationDate date={PDate} />
      }
    },
    {
      width: 50,
      title: "Last Purchased Date",
      dataIndex: "LastPurchasedDate",
      ellipsis: true,
      className: "column",
      check: true,
      render:(PDate:Text)=>{
        return <PublicationDate date={PDate} />
      }
    },
    {
      width: 50,
      title: "Last Circulated Date",
      dataIndex: "LastCheckoutDate",
      ellipsis: true,
      className: "column",
      check: true,
      render:(PDate:Text)=>{
        return <PublicationDate date={PDate} />
      }
    },
    {
      title: "Owned",
      dataIndex: "NumberOfCopies",
      key: "NumberOfCopies",
      width: 50,
      ellipsis: true,
      check: true,
    },
    {
      title: "Hold",
      dataIndex: "NumberOfHolds",
      key: "NumberOfHolds",
      width: 50,
      ellipsis: true,
      check: true,
    },
    {
      title: " All Loans",
      dataIndex: "NumberOfLoans",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
    },
    {
      title: "Ordered",
      dataIndex: "NumberOnOrder",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
    },
    {
      title: "In Circ.",
      dataIndex: "NumberOfCurrentLoans",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
    },
    {
      title: "Suggested",
      dataIndex: "NumberOfWish",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
    },
    {
      title: "PPU Price",
      dataIndex: "PPUPrice",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
      render: (price: number) => {
        return <span>{currency}{(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,',')}</span>;
      },
    },
    {
      title: "Price",
      dataIndex: "RetailPrice",
      className: "column",
      ellipsis: true,
      width: 50,
      check: true,
      render: (price: number) => {
        return <span>{currency}{(price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g,',')}</span>;
      },
    },
    {
      width: 50,
      title: "Carts",
      dataIndex: ["NumberInCarts", "CatalogItemId"],
      className: "column",
      ellipsis: true,
      check: true,
      render: (text: any, row: any) => {
        return (
          <p className="book-details-value col-font-size" onMouseEnter={() => fetchInfo(row["CatalogItemId"])}>
            <CartPopup cartId={row["NumberInCarts"]} CatalogItemId={row["CatalogItemId"]} />
          </p>
        );
      },
    },
    {
      title: "Shelved",
      dataIndex: "NumberOfShelves",
      key: "NumberOfShelves",
      width: 50,
      ellipsis: true,
      check: true,
    },
  ];
};