import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { showError } from "../../../utility/appUtil";
import { Book } from "../../models/book/book";

export class BookService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.BOOKS));
  }
  async fetchBooksByCategory(params: any): Promise<Book[] | any> {
    var libraryId = params.payload.libId;
    var categoryId = params.payload.categoryId;
    var startIndex = params.payload.startIndex;
    var quantity = params.payload.quantity;
    var sortOrder = params.payload.sortOrder;
    var endpoint = `getFeaturedList?lib_id=${libraryId}&feature_list_id=${categoryId}&record_count=${quantity}&record_start=${startIndex}`;
    if (params.payload.libraryOwned === true) {
      endpoint = `${endpoint}&filterLibraryOwned=${params.payload.libraryOwned}`;
    }
    if (params.payload.sortBy !== null && params.payload.sortBy !== "Sort By") {
      endpoint = `${endpoint}&sortdirection=${sortOrder}&sortfield=${params.payload.sortBy}`;
    }
    const response = await this.get(endpoint,{
      cancelToken: params?.token
    });
    if (response.data.success === 1) {
      var categoryItems = this.setBookData(response.data);
      return categoryItems;
    } else {
      showError(response?.data?.data);
      return null;
    }
  }
  async fetchCartsforBook(params: any): Promise<any> {
    var catalogitemId = params.bookId;
    var libId = params.libraryId;
    const response = await this.get(
      `cartByBookId?CatalogItemId=${catalogitemId}&lib_id=${libId}`
    );
    if (response.data.success === 1) {
      return response.data.data;
    } else {
      showError(response.data.data);
      return null;
    }
  }
  async fetchBookDetails(params: any): Promise<Book | null> {
    var bookId = params.bookId;
    var libId = params.libraryId;
    const response = await this.get(
      `getBookDetails?lib_id=${libId}&book_id=${bookId}`
    );
    if (response.data.success === 1) {
      let data = this.setSelectedBook(response.data);
      return data;
    } else {
      showError(response?.data?.data);
      return null;
    }
  }
  private setBookData(data: any) {
    const books: Book[] = data?.data?.FeatureListDetail.map((book: any) => {
      return {
        CatalogItemId: book?.CatalogItemId,
        Title: book?.Title,
        SubTitle: book?.SubTitle,
        Authors: book?.Authors,
        MediaTypeId: book?.MediaTypeId,
        FileFormatName: book?.FileFormatName,
        Language: book?.Language,
        ImageUrl: `https://${data?.image_url}?type=DOCUMENTIMAGE&documentID=${book?.ImageId}&size=LARGE&token=nobody`,
        RetailPrice: book?.RetailPrice,
        NumberOfCopies: book?.NumberOfCopies,
        NumberOfHolds: book?.NumberOfHolds,
        NumberOfLoans: book?.NumberOfLoans,
        NumberOfCurrentLoans: book?.NumberOfCurrentLoans,
        NumberOfWish: book?.NumberOfWish,
        NumberInCarts: book?.NumberInCarts,
        NumberOnOrder: book?.NumberOnOrder,
        NumberOfShelves: book?.NumberOfShelves,
        Narrator: book?.Narrator,
        Categories: book?.Categories !== null ? book?.Categories[0] : null,
        HoldRatio: book?.HoldRatio,
        PublicationDate: book?.PublicationDate,
        Publisher: book?.Imprint,
        Provider: book?.ContentProviderName,
        Eisbn: book?.EISBN,
        EditionType: book?.EditionType,
        StreetDate: book?.StreetDate,
        ItemState: book?.ItemState,
        LastPurchasedDate: book?.LastPurchasedDate,
        LastCheckoutDate: book?.LastCheckoutDate,
        ContentProviderId: book?.ContentProviderId,
        PPUPrice: book?.PPUPrice,
        AddedToCatalogDate: book?.AddedToCatalogDate,
        Series: book?.Series,
        NumberInSeries: book?.NumberInSeries,
        AudienceData: book?.AudienceData,
        TargetAudienceCodes: book?.TargetAudienceCodes,
        TimeSpanLimit: book?.TimeSpanLimit,
        CheckOutLimit: book?.CheckOutLimit,
        Quantity: 1,
        GroupHoldCount: book?.GroupHoldCount,
        GroupSharableCopies: book?.GroupSharableCopies,
        CloudLinkGroupName: book?.CloudLinkGroupName,
      };
    });
    return {
      totalBooks: data?.data?.NumberOfBooks,
      books: books,
      ownedBooks: data?.data?.NumberOfBooksNotInCollection,
      listCreated: data?.data?.CreateDateTime,
      listUpdated: data?.data?.DateLastAdded,
      totalBooksCost: data?.data?.CostOfBooks,
      totalNotOwnedBooksCost: data?.data?.CostOfBooksNotInCollection,
      FeatureListId: data?.data?.FeatureListId,
      FeatureListName:data?.data?.FeatureListName,
    };
  }
  private setSelectedBook(data: any) {
    const book: Book = {
      FeatureListDetailId: data?.data?.FeatureListDetailId,
      FeatureListId: data?.data?.FeatureListId,
      CatalogItemId: data?.data?.CatalogItemId,
      Title: data?.data?.Title,
      SubTitle: data?.data?.SubTitle,
      Authors: data?.data?.Authors,
      MediaTypeId: data?.data?.MediaTypeId,
      FileFormatName: data?.data?.FileFormatName,
      Language: data?.data?.Language,
      ImageUrl: `https://${data?.image_url}?type=DOCUMENTIMAGE&documentID=${data?.data?.ImageId}&size=LARGE&token=nobody`,
      RetailPrice: data?.data?.RetailPrice,
      Narrator: data?.data?.Narrator,
      NumberOfCopies: data?.data?.NumberOfCopies,
      NumberOfHolds: data?.data?.NumberOfHolds,
      NumberOfLoans: data?.data?.NumberOfLoans,
      NumberOfCurrentLoans: data?.data?.NumberOfCurrentLoans,
      NumberOfWish: data?.data?.NumberOfWish,
      NumberInCarts: data?.data?.NumberInCarts,
      NumberOnOrder: data?.data?.NumberOnOrder,
      NumberOfShelves: data?.data?.NumberOfShelves,
      Categories: data?.data?.CategoryPaths,
      HoldRatio: data?.data?.HoldRatio,
      PublicationDate: data?.data?.PublicationDate,
      Publisher: data?.data?.Imprint,
      Provider: data?.data?.ContentProviderName,
      Eisbn: data?.data?.EISBN,
      EditionType: data?.data?.EditionType,
      Duration: data?.data?.Duration,
      PublisherAudienceInfo: data?.data?.PublisherAudienceInfo,
      StreetDate: data?.data?.StreetDate,
      ItemState: data?.data?.ItemState,
      ItemDscr: data?.data?.ItemDscr,
      ContentProviderId: data?.data?.ContentProviderId,
      LastPurchasedDate: data?.data?.LastPurchasedDate,
      LastCheckoutDate: data?.data?.LastCheckoutDate,
      AddedToCatalogDate: data?.data?.AddedToCatalogDate,
      Series: data?.data?.Series,
      NumberInSeries: data?.data?.NumberInSeries,
      AudienceData: data?.data?.AudienceData,
      TargetAudienceCodes: data?.data?.TargetAudienceCodes,
      TimeSpanLimit: data?.data?.TimeSpanLimit,
      CheckOutLimit: data?.data?.CheckOutLimit,
      GroupHoldCount: data?.data?.GroupHoldCount,
      GroupSharableCopies: data?.data?.GroupSharableCopies,
      CloudLinkGroupName: data?.data?.CloudLinkGroupName,
    };

    return book;
  }
}
