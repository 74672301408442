import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import User from "../../models/user/user";

export class UserService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.AUTH));
  }

  async fetchUserList(params: any) {
    var libId = params.libId
    var activeOnly = params.activeOnly
    const response = await this.get(`getUsers?lib_id=${libId}&active_Only=${activeOnly}&record_count=100&sort_Direction=ASC&sort_Field=ScreenName`);
    if(response.data.success === 1){
        var userList = this.setUserList(response.data.data.Users)
        return userList
    } else return null
    
  }
  private setUserList(data: any) {
    const users: User[] = data.map((userObj:any)=>{
    return {
      firstName: userObj.FirstName ?? "",
      lastName: userObj.LastName ?? "",
      email: userObj.EmailAddress ?? "",
      userId: userObj.UserID ?? "",
      isActive: userObj.IsActive ?? [],
      isLibraryAdmin: userObj.IsLibraryAdmin,
      isLibraryBuyer: userObj.IsLibraryBuyer ?? "",
      isLibraryUser: userObj.IsLibraryUser ?? "",
      libraryuserObj: userObj.Library ?? "",
    };
    })
    return users;
  }

}