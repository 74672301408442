import { Modal, Input, Select, Spin } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Button from "../../stateless/common/button";
import { downIcon } from "../../../images";
import Shelf from "../../../models/shelf/shelf";
import { useEffect, useState } from "react";
import { createNewCart,onlyCreateNewCart,updatedNewCart } from "../../../../redux/actions/carts/cartAction";
import { ShelfstateSelector } from "../../../../redux/reducers/shelves/shelfReducer";
import { useDispatch, useSelector } from "react-redux";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import {LoadingOutlined} from "@ant-design/icons"

export const NewCartModal = (props: any) => {
  const { isModalVisible, setModalVisible, appUser,selectedCart,editable,isNewCartDetailsNeed } = props;
  var libID =
  appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  const {shelves, shelvFormState} = useSelector(ShelfstateSelector)
  const [prevent, setPrevent] = useState(true)
  const [cartDisable, setCartDisable] = useState(false);
  const dispatch = useDispatch()
  const { Option } = Select;
  var libraryShelves = shelves?.filter((data:any)=>
  data.IsManual === true).map((data: Shelf)=>{
    return ({text: data.ShelfName, value: data.ShelfId})
  })
  const arry = [];
  arry.push({text: "No Shelf", value: 0 })

  const optionValues = [...arry , ...libraryShelves];

  const [form] = useForm();
 
  
  const handleCheckbox = (e: any) =>{
    if(e.target.checked){
      setPrevent(false)
    } else{
      setPrevent(true)
    }
  }
  const onConfirmCreate = (data: any) =>{

    const filterValue = shelves?.filter((item: any) => {
      return item?.ShelfId === data?.shelf ;
    })
    
    const savePayload = {
      CartName : data.cartName,
      CartDescription: data?.description ?? "",
      IsCommunityCart: prevent,
      ShelfName: filterValue[0]?.ShelfName ?? "",
      AssociateShelfId: filterValue[0]?.ShelfName !== undefined ? data?.shelf : 0,
      LibraryId: libID,
      CartId: selectedCart?.CartId,
      CartOwner: selectedCart?.CartOwner,
      CartTypeCode: selectedCart?.CartTypeCode,
      TotalCartAmount: selectedCart?.TotalCartAmount,
      NumberOfTitles: selectedCart?.NumberOfTitles,
      CartOwnerFirstName: selectedCart?.CartOwnerFirstName,
      CartOwnerLastName: selectedCart?.CartOwnerLastName,
      IsDefault:selectedCart?.IsDefault,
      NumberOfCopies: selectedCart?.NumberOfCopies,
      NumberOfPrepubCopies: selectedCart?.NumberOfPrepubCopies,
      NumberOfPrepubTitles: selectedCart?.NumberOfPrepubTitles,
      TotalCartPrepubAmount: selectedCart?.TotalCartPrepubAmount,
      UserId: selectedCart?.UserId,
    }
    
    let createCart = {
      CartName: data.cartName,
      AssociateShelfId: data?.shelf ?? 0,
      CartDescription: data?.description ?? "",
      IsCommunityCart: prevent,
      SaveAction: "S",
      LibraryId: libID,
    }

    if(data?.shelf && data?.shelf === "Connect to shelf"){
         delete createCart['AssociateShelfId']
    }

    if (
      editable
        ? dispatch(updatedNewCart(savePayload))
        : isNewCartDetailsNeed
        ? dispatch(
            createNewCart(createCart)
          )
        : dispatch(
            onlyCreateNewCart(createCart)
          )
    )
      setModalVisible(false);
    form.resetFields();
    setPrevent(true);
  };

  const cancelbtn = () => {
    setModalVisible(false)
    form.resetFields()
  }

  useEffect(()=>{
    if(isModalVisible === true){
      form.setFieldsValue({
        cartName :editable?selectedCart?.CartName:"",
        description :editable?selectedCart?.CartDescription:"",
        shelf:editable?selectedCart?.AssociateShelfId !== 0 ? selectedCart?.AssociateShelfId : "Connect to shelf":"Connect to shelf",
        checkbox: editable?!selectedCart?.IsCommunityCart:false
      })
      selectedCart?.IsCommunityCart !== null && selectedCart?.IsCommunityCart !== undefined && setPrevent(selectedCart?.IsCommunityCart)
      if(selectedCart?.IsDefault && editable) {
        setCartDisable(true)
      } else {
        setCartDisable(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps     
  },[selectedCart,isModalVisible])

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  
  const RenderIcon = () => {
    return <img src={downIcon} alt =""/>
}

const RenderLoading = () => {
  return <Spin indicator={antIcon} className="renderLoading" />;
}

  return (
    <Modal
      visible={isModalVisible}
      wrapClassName="newCart-modal"
      onCancel={() => {
        setModalVisible(false)
        form.resetFields()
        setPrevent(true)
      }}
      > {editable ? 
        <div className="modal-title">Edit Cart</div>:
        <div className="modal-title">Create New Cart</div>
      }
      <Form onFinish={onConfirmCreate} form={form}>
        <FormItem name="cartName" rules={[{ required: true, message: 'Please enter the Cart Name!' }, {pattern:/^\S*$/, message: 'Cart Name can not contain spaces.'}]} >
          <Input name="cartName" className={`new-modal-input ${cartDisable ? "cartModelNameField" : ''}`} placeholder="Cart Name" disabled={cartDisable} />
        </FormItem>
        <FormItem name="description">
          <Input name="description" className="new-modal-input" placeholder="Description" />
        </FormItem>
        <FormItem name="shelf">
        {/* <DropDown
            name="shelf"
            placeholder="Connect to shelf"
            icon={downIcon}
            optionValue={optionValues}
            bordered={false}
            className="new-modal-input"
         /> */}

          <Select
          placeholder="Connect to shelf"
          suffixIcon={shelvFormState?.loading ? RenderLoading : RenderIcon}
          bordered={false}
          className="new-modal-input"
          loading={shelvFormState?.loading}
          >
            {optionValues.map((option: any, index: any) => {
          return (
            <Option key={option.value} value={option.value}>
             {option.text}
            </Option>
          )
            })
          }
          </Select>
        </FormItem>
        <FormItem name="checkbox">
          <div className="new-cart-checkbox">
            <Checkbox checked={!prevent} name="checkbox" onChange={handleCheckbox} />
            <div className="optional-text">Prevent others from making changes</div>
          </div>
        </FormItem>
        {!editable ?
        <Button type="primary" htmlType="submit">Create Cart</Button>
        :
        <div>
        <Button type="primary" htmlType="submit" onFinish={onConfirmCreate}>Save Changes</Button>
        <Button type="cartCancelBtn" onClick={cancelbtn}>Cancel</Button>
        </div>
}
      </Form>
    </Modal>
  );
};
