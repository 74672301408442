import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./carts.less";
import Cart from "../../../models/cart/cart";
import {
  deleteIcon,
  editIcon,
  userCart,
  nonUserCart,
  userPrivate,
} from "../../../images";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import Button from "../../stateless/common/button";
import {
  deleteCartbyId,
  cartListFilter,
} from "../../../../redux/actions/carts/cartAction";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../router/appRoutes";
import SpinnerLoader from "../../stateless/common/spinnerLoader";
import DropDown from "../../stateless/common/dropDown";
import { filterOptions } from "../../../constants/constants";
import { ascIcon, descIcon, dropdownIcon } from "../../../images";
import {
  CartStateSelector,
  setScroll,
} from "../../../../redux/reducers/carts/cartsReducer";
import { NewCartModal } from "./newCartModal";

export const CartList = (props: any) => {
  const { cartlist, formState, tab } = props;
  const { appUser } = useSelector(userStateSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [isEditClick, setIsEditClick] = useState<boolean>(false);
  const [selectedCart, setSelectedCart] = useState<any>();
  const [editModal, setEditModal] = useState(false);
  const { cartFilters, scrollHeight } = useSelector(CartStateSelector);
  const [cartsList, setCartList] = useState<any>(cartlist);
  const [sortOrder, setSortOrder] = useState(
    cartFilters.sortOrder !== undefined ? cartFilters.sortOrder : "Default"
  );
  const [sortIcon, setSortIcon] = useState(
    cartFilters.sortIcon !== undefined ? cartFilters.sortIcon : ascIcon
  );
  const [sort, setSort] = useState(
    cartFilters.sort !== undefined ? cartFilters.sort : "asc"
  );
  const [scrollValue, setScrollValue] = useState(0);
  var currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";

  useEffect(() => {
    setCartList(cartlist);
  }, [cartlist]);
  const getUserName = (data: Cart) => {
    if (data.CartOwner === appUser?.email) {
      return "You";
    } else {
      var cartOwner = data?.CartOwnerFirstName
        ? data?.CartOwnerFirstName + " " + data.CartOwnerLastName
        : null;
      return cartOwner;
    }
  };
  const getCartLogo = (data: Cart) => {
    if (data.CartOwner === appUser?.email) {
      return userPrivate;
    } else if (data.CartOwner !== appUser?.email && data.IsCommunityCart) {
      return userCart;
    } else {
      return nonUserCart;
    }
  };
  const isDeletable = (data: Cart) => {
    if (
      (data.CartOwner === appUser?.email && !data.IsDefault) ||
      (appUser?.isLibraryAdmin && data.IsCommunityCart) ||
      appUser?.isLibraryBuyer ||
      appUser?.isLibraryUser
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isEditable = (data: Cart) => {
    if (
      data.CartOwner === appUser?.email ||
      data.IsCommunityCart ||
      appUser?.isLibraryBuyer
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isAutoCart = (data: any) => {
    if (
      data?.CartOwner?.toLowerCase() === "auto" &&
      data?.CartTypeCode?.toLowerCase().indexOf("autobuy") >= 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!editModal && isEditClick) {
      setIsEditClick(false);
    }
  }, [editModal, isEditClick]);

  useEffect(() => {
    if (!editModal) {
      const element = document.getElementById(`scroll_${props.name}`);
      element?.scrollTo(0, scrollHeight ?? scrollValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModal]);

  const onDeleteClick = (e: any, data: Cart) => {
    e.stopPropagation();
    setSelectedCart(data);
    setShowModal(true);
  };
  const onEditClick = (e: any, data: Cart) => {
    e.stopPropagation();
    if (
      data.CartOwner === appUser?.email ||
      (appUser?.isLibraryAdmin && data.IsCommunityCart) ||
      appUser?.isLibraryBuyer ||
      appUser?.isLibraryUser
    ) {
      setSelectedCart(data);
      setEditModal(true);
      setIsEditClick(true);
      dispatch(setScroll(scrollValue));
    }
  };
  const onConfirm = () => {
    dispatch(deleteCartbyId(selectedCart.CartId));
    setShowModal(false);
  };
  const onCancel = () => {
    setShowModal(false);
  };
  const onCartSelect = (data: Cart) => {
    let state = {
      cart: data,
    };
    history.push(AppRoutes.CARTDETAILS, state);
    dispatch(setScroll(scrollValue));
  };

  const handleSortFilter = (value: any) => {
    setSortOrder(value);
  };

  const onSortClick = () => {
    if (sort === "asc") {
      setSort("desc");
      setSortIcon(descIcon);
    } else {
      setSort("asc");
      setSortIcon(ascIcon);
    }
  };

  useEffect(() => {
    var cartFilter = {
      sortOrder,
      sort,
      sortIcon,
    };
    var list = [...cartsList];
    var sortedlist;
    if (sortOrder === null) {
      setCartList(cartlist);
    }
    if (sortOrder === "CartName") {
      sortedlist = list.sort((a: any, b: any) => {
        if (a.CartName.toLowerCase() < b.CartName.toLowerCase()) {
          return -1;
        }
        if (a.CartName.toLowerCase() > b.CartName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      if (sort === "asc") {
        setCartList(sortedlist);
      }
      if (sort === "desc") setCartList(sortedlist.reverse());
    }

    if (sortOrder === "CartOwner") {
      sortedlist = list.sort((a: any, b: any) => {
        if (a.cartOwnedby.toLowerCase() < b.cartOwnedby.toLowerCase()) {
          return -1;
        }
        if (a.cartOwnedby.toLowerCase() > b.cartOwnedby.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      if (sort === "asc") {
        setCartList(sortedlist);
      }
      if (sort === "desc") {
        setCartList(sortedlist.reverse());
      }
    }
    dispatch(cartListFilter(cartFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOrder, sort]);

  useEffect(() => {
    if (formState.loading === false) {
      const element = document.getElementById(`scroll_${props.name}`);
      element?.scrollTo(0, scrollHeight ?? scrollValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.loading]);

  const handleScroll = () => {
    if (formState.loading === false) {
      const element = document.getElementById(`scroll_${props.name}`);
      let x = element?.scrollTop;
      x && setScrollValue(x);
    }
  };
  return (
    <SpinnerLoader loading={formState.loading}>
      {tab === 2 ? (
        <Col span={5} className="pb-10">
          <DropDown
            defaultValue="Default"
            value={sortOrder}
            bordered={false}
            className="filters"
            optionValue={filterOptions.allCarts}
            icon={dropdownIcon}
            onChange={handleSortFilter}
            showSort={true}
            sortIcon={sortIcon}
            onSortClick={onSortClick}
            childwidth={150}
          />
        </Col>
      ) : null}
      <div
        className="scrollContent scrollStyle cartList"
        id={`scroll_${props.name}`}
        onScroll={handleScroll}
      >
        {cartsList.map((data: Cart, index: number) => {
          return (
            <Row
              className="cart-item cursor-pointer"
              key={index}
              onClick={() => onCartSelect(data)}
            >
              <Col span={24}>
                <Row>
                  <Col xs={12} sm={12} md={14} lg={16} xl={16}>
                    <span className="cart-title">{data.CartName}</span>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={10}
                    lg={8}
                    xl={8}
                    className="cart-edit-value "
                  ></Col>
                </Row>
                <Row className="cart-details cartRowGap">
                  <Col xs={12} sm={8} md={5} lg={5} xl={4}>
                    <img
                      src={getCartLogo(data)}
                      className="owned-icon"
                      alt="owned"
                    />
                    <span className="font-11">
                      Owned By {getUserName(data)}
                    </span>
                  </Col>
                  <Col xs={9} sm={6} md={2} lg={2} xl={2}>
                    <Tooltip
                      title={`${currency} ${data.TotalCartAmount.toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    >
                      <p className="ellipsis-text">
                        <span className="cart-amount pl-5 font-11">
                          {currency}
                          {data.TotalCartAmount.toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                      </p>
                    </Tooltip>
                  </Col>
                  <Col xs={9} sm={7} md={3} lg={3} xl={3}>
                    <Tooltip
                      title={`${data.NumberOfTitles} titles`}
                    >
                      <p className="ellipsis-text">
                        <span className="cart-amount pl-5 font-11">
                          {data.NumberOfTitles} titles
                        </span>
                      </p>
                    </Tooltip>
                  </Col>
                  <Col xs={15} sm={17} md={5} lg={5} xl={5} className="pr-15">
                    {data.ShelfName !== "" ? (
                      <Tooltip title={data.ShelfName}>
                        <p className="ellipsis-text">
                          <span
                            className={`font-11 className="pr-15" cart-amount ${
                              data.CartOwner === appUser?.email ||
                              (appUser?.isLibraryAdmin && data.IsCommunityCart)
                                ? "shelfName"
                                : ""
                            }`}
                            onClick={(e) => onEditClick(e, data)}
                          >
                            Connected Shelf: {data.ShelfName}
                          </span>
                        </p>
                      </Tooltip>
                    ) : (
                      <p style={{ whiteSpace: "nowrap" }}>
                        <span
                          className={`font-11 cart-amount ${
                            data.CartOwner === appUser?.email ||
                            (appUser?.isLibraryAdmin && data.IsCommunityCart)
                              ? "shelfName"
                              : ""
                          }`}
                          onClick={(e) => onEditClick(e, data)}
                        >
                          Connected Shelf: None
                        </span>
                      </p>
                    )}
                  </Col>
                  <Col xl={5} lg={4} md={4} className="pr-15">
                    {data?.CartDescription ? (
                      <Tooltip title={data.CartDescription}>
                        <p className="ellipsis-text">
                          <span className="font-11">
                            Description: {data?.CartDescription}
                          </span>
                        </p>
                      </Tooltip>
                    ) : (
                      <p>
                        <span className="font-11">Description: n/a</span>
                      </p>
                    )}
                  </Col>
                  <Col
                    className="cart-label"
                    xs={4}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={3}
                  >
                    <span
                      className={`cart-bYlabel ${
                        data.IsCommunityCart
                          ? "cart-label-green"
                          : "cart-label-red"
                      } font-11`}
                      onClick={(e) => onEditClick(e, data)}
                    >
                      {data.IsCommunityCart ? "Community Cart" : "Private Cart"}
                    </span>
                  </Col>
                  <Col
                    xs={7}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    className="cart-icon-container"
                  >
                    <Col span={12} onClick={(e) => onEditClick(e, data)}>
                      {isEditable(data) && !isAutoCart(data) ? (
                        <img
                          src={editIcon}
                          style={{ cursor: "pointer" }}
                          alt="editIcon"
                        />
                      ) : null}
                    </Col>
                    <Col span={12} onClick={(e) => onDeleteClick(e, data)}>
                      {isDeletable(data) && !isAutoCart(data) ? (
                        <img
                          src={deleteIcon}
                          style={{ cursor: "pointer" }}
                          alt="deleteIcon"
                        />
                      ) : null}
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
        <Modal
          wrapClassName="delete-cart"
          visible={showModal}
          centered
          footer={
            <>
              <Button type="smallRed confirm-button" onClick={onConfirm}>
                Delete
              </Button>
              <Button type="secondary cancel-button" onClick={onCancel}>
                Cancel
              </Button>
            </>
          }
          onCancel={onCancel}
        >
          <div className="delete-message">
            Delete <strong>{selectedCart?.CartName}</strong>?
          </div>
        </Modal>
        {/* <EditCartModal isModalVisible={editModal} setModalVisible={setEditModal} appUser={appUser} editCartName={selectedCart?.CartName} editCartDescription={selectedCart?.CartDescription} /> */}
        <NewCartModal
          isModalVisible={editModal}
          setModalVisible={setEditModal}
          appUser={appUser}
          selectedCart={selectedCart}
          editable={isEditClick ? true : false}
          isNewCartDetailsNeed={true}
        />
      </div>
    </SpinnerLoader>
  );
};
