import { message } from "antd";
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import Expiration from "../../models/expiration/expirationModel";

export class InsightsService extends BaseService {
    constructor() {
        super(getAPIBaseUrl(PortalModule.CARTS));
      }

      showError = (data: any, ) =>{
        if(data?.Message !== "" && data?.Message !== undefined){
         return message.error({
            content:data?.Message,
            duration: 4,
          })
        }else if(data?.ErrorMessage !== "" && data?.ErrorMessage !== undefined){
         return message.error({
            content:data?.ErrorMessage,
            duration: 4,
          })
        }else{
          return undefined
        }
        }

    async fetchCirculationSummary (libid:any): Promise<Expiration[]| any> {
        const response = await this.get(`circulationSummary?lib_id=${libid}`);
        if(response.data.success === 1){
            var circulationSummaryData = response?.data.data
            return circulationSummaryData;
        }
        else {
            this.showError(response?.data?.data)
            return null
        }
      }


}