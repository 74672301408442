/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import { Row, Modal, Tooltip, Dropdown, Menu, message, Col } from "antd";
import Button from "../../../stateless/common/button";
import { useSelector,useDispatch } from "react-redux";
import { PurchaseStateSelector } from '../../../../../redux/reducers/purchases/purchases';
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { downIcon } from '../../../../images';
import { ShelfstateSelector,clearAddItemtoShelf } from "../../../../../redux/reducers/shelves/shelfReducer";
import { onSelectShelve, addItemtoShelve } from "../../../../../redux/actions/shelves/shelfAction";

const AddToShelfModel = ( props: any) => {
    const {addToShelfModel, setAddToShelfModel, messageforModel,label, selectedRows,addedSucess,setShowDeletebtn} = props;
    const {preSaleFormState} = useSelector(PurchaseStateSelector);
    const { shelves,selectedShelve, shelvFormState, addItemShelf } = useSelector(ShelfstateSelector);

  const dispatch = useDispatch();
  const [activeShelveName, setactiveShelveName] = useState<any>(selectedShelve !== undefined ? selectedShelve : "")
  const [activeShelveCount, setActiveShelveCount] = useState<any>();
  const [shelvesData, setShelvesData] = useState<any>(shelves)

  const showNotification = (content: any, type: any) => {
    if (type === "loading") {
      message
        .loading({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
    } else {
      type === "success"
        ? message.success({
          content: content,
          key: "notificationMessage",
        })
        : message.error({
          content: content,
          key: "error",
        });
    }
  };

    const handleYesSubmit =() =>{
    if(activeShelveName?.ShelfName !== "Add to shelf"){
      
    const payLoadData = {
      "ShelfId": activeShelveName?.ShelfId,
      "LibraryId": activeShelveName?.LibraryId,
      "ShelfName": activeShelveName?.ShelfName,
      "Description": activeShelveName?.Description,
      "ReaktorShelfId": activeShelveName?.ReaktorShelfId,
      "SortOrder": activeShelveName?.SortOrder,
      "IsActive": activeShelveName?.IsActive,
      "IsManual": activeShelveName?.IsManual,
      "Items": selectedRows,
      "SortStrategy": activeShelveName?.SortStrategy,
      "CatShelfAllItems": activeShelveName?.CatShelfAllItems,
      "IsQuickConnect": activeShelveName?.IsQuickConnect,
      "CatShelfCriteria": activeShelveName?.CatShelfCriteria,
      "CurrentStatus": activeShelveName?.CurrentStatus,
      "ItemsCount": activeShelveName?.ItemsCount,
      "CreateDateTime": activeShelveName?.CreateDateTime,
      "CreateUserId": activeShelveName?.CreateUserId,
      "ChangeDateTime": activeShelveName?.ChangeDateTime,
      "ChangeUserId": activeShelveName?.ChangeUserId
    }
    dispatch(addItemtoShelve(payLoadData))
    const msg = (
      `Adding to ${activeShelveName?.ShelfName}`
    );
    showNotification(msg, "loading");
    }
  } 

    const onCancelModel=()=>{
      setAddToShelfModel(false)
      }
    const handleNoSubmit = () => {
      setAddToShelfModel(false);
    }

    const selectShelve = (data: any) => {
      setactiveShelveName(data)
      setActiveShelveCount(data?.ItemsCount)
      if(data.ShelfName !== "Add to shelf"){
        dispatch(onSelectShelve(data))
      }
    }

    const shelveStatus = (status: any) => {
      if (status) {
        return <span className="shelves-live">LIVE</span>
      } else {
        return <span className="shelves-draft">DRAFT</span>
      }
    }

    const shelvesList = (
      <Menu className="header-dropdown">
        {shelvesData.map((data: any, index: number) => {
           {
            if(data.ShelfName === "Add to shelf") {
              return (
                <Menu.Item key={index}
                  onClick={() => selectShelve(data)}
                >
                  <Tooltip title={data?.ShelfName} placement="bottom">
                  <p className="dropDown-title dropdown-shelves-title  shelves-list-ellipse"><span>{data.ShelfName}</span></p>
                  </Tooltip>
                </Menu.Item>
              );
            } else {
              return (
                <Menu.Item key={index}
                  onClick={() => selectShelve(data)}
                >
                  <Tooltip title={data?.ShelfName} placement="bottom">
                  <p className="dropDown-title dropdown-shelves-title  shelves-list-ellipse"><span>{data.ShelfName}</span> <span className="status-span">{shelveStatus(data.IsActive)}</span></p>
                  <span className="dropDown-content">
                    {data.ItemsCount} titles
                  </span>
                  </Tooltip>
                </Menu.Item>
              );
            }
          }

          
        })}
      </Menu>
    );

    const DropdownTitle = () => {
      return (
        <div style={{ display: "flex" }}>
          <div className="cart-dropdown shelf-dropdown">
            <Tooltip title={activeShelveName?.ShelfName} placement="bottom">         
             <p className="dropDown-title shelf-dropdown-name">Shelf:<span className="shelves-title-ellipse">
               &nbsp;&nbsp;{activeShelveName?.ShelfName}</span>
              {activeShelveCount !== undefined ? <span className="shelves-titles">{activeShelveCount} Titles</span> : null }
               {activeShelveName?.IsActive !== undefined ?<span>{shelveStatus(activeShelveName?.IsActive)}</span> : null }
               </p>          
            </Tooltip>
          </div>
          <img src={downIcon} className="downIcon" alt="down" />
        </div>
      );
    };


  useEffect(() => {
      const copyshelve = [...shelves]
      const filteredList = copyshelve?.filter((item:any) => item.IsManual && item.IsQuickConnect === false)
      const arry = [];
      arry.push({ShelfName: "Add to shelf", value: 0 })
      const optionValues = [...arry , ...filteredList];
      setShelvesData(optionValues)
      if (selectedShelve?.length < 1) {
        dispatch(onSelectShelve(filteredList[0]))
        setactiveShelveName(filteredList[0])
        setActiveShelveCount(filteredList[0]?.ItemsCount)
      }
      if(selectedShelve !== undefined){
        const a = shelves.filter((d:any) => d.ShelfId === selectedShelve.ShelfId)
        setActiveShelveCount(a[0]?.ItemsCount)
      }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shelves,selectedShelve])


  useEffect(() => {
    if(addItemShelf?.isSuccess){
      showNotification(`Added to ${activeShelveName?.ShelfName}`, "success");
      dispatch(clearAddItemtoShelf())
      setShowDeletebtn(false)
      setAddToShelfModel(false)
      addedSucess()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addItemShelf])
  
    
  return (
    <Modal
    wrapClassName="purchase-modal edit-PO-reference"
    visible={addToShelfModel}
    onCancel={onCancelModel}
    footer={
          <Row justify="space-around">
               <Button
            type="secondary confirm-button"
            onClick={handleNoSubmit}
          >
            Cancel
          </Button>
          <Button
            type={`primary confirm-button ${activeShelveName?.ShelfName !== "Add to shelf" ? "" : "selectShelfSelected"}`}
            onClick={handleYesSubmit}
          >
            Save
          </Button>       
          </Row>
      }
    >
        <SpinnerLoader loading={preSaleFormState.loading || shelvFormState.loading}>
        <p className="purchase-title">{props.heading}</p>
        <div className="remove-content">
        <p className='purchaseNote'>
           {messageforModel}
        </p>
        </div>
        
        <div className="add_to_shelf_text">
          <Row>
            <Col className="destination_shelf" span={8}> 
            {label} 
            </Col>
            <Col span={16}>          
           {
                <div className="cart shelve-title-in-myCollection">
                  <Dropdown.Button
                    overlay={shelvesList}
                    trigger={["click"]}
                    icon={<DropdownTitle />}
                    className="shelf-menu purchase-add-to-shelf"
                  >
                  </Dropdown.Button>
                </div>
            }
            </Col>
          </Row>         
        </div>
       
        </SpinnerLoader>
    </Modal>
  )
};

export default AddToShelfModel;
