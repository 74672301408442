import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { showError } from "../../../utility/appUtil";
import PreSale from "../../models/purchases/presale";
import AudienceData from "../../models/search/audienceData";

export class PurchaseService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.SEARCH));
  }
  async fetchPurchaseUser(payload: any): Promise<AudienceData[] | any> {
    const response = await this.get(
      `getPurchaseOrderUser?lib_Id=${payload?.lib_Id}&ExternalRef_id=${payload?.ExternalRef_id}&record_Count=${payload?.record_Count}&sort_Direction=${payload?.sort_Direction}&sort_Field=${payload?.sort_Field}`
    );
    if (response.data.success === 1) {
      var purchaseOrderDetails = response?.data?.data;
      return purchaseOrderDetails;
    } else return response?.data?.data;
  }

  async fetchPurchaseOrder(payload: any): Promise<AudienceData[] | any> {
    if(payload?.lib_Id !== undefined){
      const response = await this.get(
        `getPurchaseOrder?lib_Id=${payload?.lib_Id}&ExternalRef_id=${payload?.ExternalRef_id}&buyer_id=${payload?.buyer_id}&days_Old=${payload?.days_Old}&end_Date=${payload?.end_Date}&status=${payload?.status}&record_Count=${payload?.record_Count}&record_Start=${payload?.record_Start}&sort_Direction=${payload?.sort_Direction}&sort_Field=${payload?.sort_Field}&start_Date=${payload?.start_Date}`
        );
        if (response.data.success === 1) {
          var purchaseOrderDetails = response?.data?.data;
          return purchaseOrderDetails;
        } else {
          showError(response?.data?.data)
          return response?.data?.data
        };

      }
  }

  async fetchPurchaseDetails(payload: any): Promise<AudienceData[] | any> {
    const response = await this.get(
      `getPurchaseDetails?id=${payload?.id}&buyer_id=${payload?.buyer_id}&lib_id=${payload?.lib_id}&status=${payload?.status}&record_Count=${payload?.record_Count}&record_Start=${payload?.record_Start}&sort_Direction=${payload?.sort_Direction}&sort_Field=${payload?.sort_Field}`
    );
    if (response.data.success === 1) {
      var purchaseOrderDetails = response?.data?.data;
      return purchaseOrderDetails;
    } else return response?.data?.data;
  }

  async fetchPreSaleData(payload: any): Promise<AudienceData[] | any> {
    const response = await this.get(
      `getPrePub?lib_id=${payload?.lib_Id}&record_Count=${payload?.record_Count}&record_Start=${payload?.record_Start}&sort_Direction=${payload?.sort_Direction}&sort_Field=${payload?.sort_Field}`
    );
    if (response.data.success === 1) {
      var PreSaleData = this.setPreSaleData(response?.data?.data);
      return PreSaleData;
    } else return response?.data?.data;
  }

  async updatePreSale(payload: any): Promise<AudienceData[] | any> {
    const response = await this.put(
      `updatePrePub?lib_id=${payload?.lib_id}`,
      payload?.body
    );
    if (response.data.success === 1) {
      var PreSaleData =  this.setPreSaleData(response?.data?.data);
      return PreSaleData;
    } else return response?.data?.data;
  }

  async deletePreSale(payload: any): Promise<AudienceData[] | any> {
    const response = await this.delete(
      `deletePrePub?id=${payload?.Id}&ids=${payload?.Ids}&lib_id=${payload?.lib_id}&record_Count=${payload?.record_Count}&record_Start=${payload?.record_Start}&sort_Direction=${payload?.sort_Direction}&sort_Field=${payload?.sort_Field}`
    );
    if (response.data.success === 1) {
      var PreSaleData =  this.setPreSaleData(response?.data?.data);
      return PreSaleData;
    } else return response?.data?.data;
  }

  private setPreSaleData(data: any) {
    const books: PreSale[] = data?.Items?.map((book: any) => {
      return {
        key: book?.PrepubPendingPurchaseId,
        Authors: book?.Authors,
        BuyerId: book?.BuyerId,
        CatalogItemId: book?.CatalogItemId,
        ChangeDateTime: book?.ChangeDateTime,
        ChangeUserId: book?.ChangeUserId,
        CreateDateTime: book?.CreateDateTime,
        CreateUserId: book?.CreateUserId,
        DestinationShelfId: book?.DestinationShelfId,
        EISBN: book?.EISBN,
        ExternalRefId: book?.ExternalRefId,
        FileFormatName: book?.FileFormatName,
        ImageId: book?.ImageId,
        Imprint: book?.Imprint,
        ItemState: book?.ItemState,
        LibraryId: book?.LibraryId,
        MediaTypeId: book?.MediaTypeId,
        Message: book?.Message,
        PrepubPendingPurchaseId: book?.PrepubPendingPurchaseId,
        PublicationDate: book?.PublicationDate,
        Quantity: book?.Quantity,
        RetailPrice: book?.RetailPrice,
        ShelfName: book?.ShelfName,
        Status: book?.Status,
        StreetDate: book?.StreetDate,
        SubTitle: book?.SubTitle,
        Title: book?.Title,
      };
    });
    return { TotalCount: data?.TotalCount, Items: books };
  }
}
