/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable use-isnan */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useMemo } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  DatePicker,
  message,
  Form,
  TreeSelect,
  Empty,
} from "antd";
// import DropDown from "../../../stateless/common/dropDown";
import { filterOptions, Page } from "../../../../constants/constants";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { downIcon, searchIcon } from "../../../../images";
import Button from "../../../stateless/common/button";
import { useState } from "react";
import { ItemFormat } from "../../../../models/search/mediaType";
import { useEffect } from "react";
import moment from "moment";
import "./AdvancedSearchSideForm.less";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSaved,
  clearSaveSearchResult,
  SearchstateSelector,
  setSearchAfterEdit,
  setSearchTab,
  clearAdvanceSearchData,
  clearResults,
  clearState,
  setSearch,
} from "../../../../../redux/reducers/search/searchReducer";
import { useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../../router/appRoutes";
import {
  getSaveSearchResult,
  getSearchString,
  onBackFillForm,
  onStringBackFillForm,
  getUnmatchValues,
  fetchSearchResults
} from "../../../../../redux/actions/search/searchActions";
import FormItem from "antd/lib/form/FormItem";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import Category from "../../../../models/search/category";
import { Messages } from "../../../../constants/messages";
import {
  setGlobalCopy,
  setSelectedTitles,
} from "../../../../../redux/actions/books/bookActions";
import DropDown from "../../../stateless/common/dropDown";
import { BookStateSelector, clearBooklistCurrentPage } from "../../../../../redux/reducers/books/bookListReducer";
import MultiSelect from "../../../stateless/common/multiSelect";

export const AdvancedSearchSideForm = React.memo((props: any) => {
  const {
    audienceDataOptions,
    providerOptions,
    publisherOptions,
    formatOptions,
    languageOptions,
    libraryId,
    isCollection,
    clearForm,
  } = props;
  const { RangePicker } = DatePicker;
  const {
    formState,
    searchSaved,
    categoriesOptions,
    saveSearchResults
  } = useSelector(SearchstateSelector);
  const { booklistCurrentPage } = useSelector(BookStateSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  let editableData =
    saveSearchResults?.searchParams !== undefined
      ? saveSearchResults?.searchParams
      : [];

  const location = useLocation<any>();
  const [selectedFormats, setSelectedFormats] = useState<any>(["1", "2", "3"]);
  const [dates, setDates] = useState<any>([]);
  const [cloudDate, setCloudDate] = useState<any>([]);
  const [daysVal, setDaysVal] = useState<any>();
  const [cloudDaysVal, setCloudDaysVal] = useState<any>();
  const [treevalue, setTreeValue] = useState<any>();
  const [haskeyWord, setHaskeyWord] = useState<boolean>(false);
  const [treeParentvalue, setTreeParentvalue] = useState<any>([]);
  const [treeData, setTreeData] = useState<any>();
  const [newCatObj, setNewCatObj] = useState<any>();
  const [editedData, setEditedData] = useState<any>();
  const [showError, setShowError] = useState<string>("");
  const [narratorVisible, setNarratorVisible] = useState<boolean>(true);
  const [selFormat, setSelFormats] = useState<any>();
  const { appUser } = useSelector(userStateSelector);
  var userCatalog =
    appUser && appUser.libraryData ? appUser.libraryData[0].NatureId : 0;
  const advancedSearchInitialdata = {
    SearchSaveId:
      saveSearchResults?.SearchSaveId !== undefined
        ? saveSearchResults?.SearchSaveId
        : "",
    SearchSaveName:
      saveSearchResults?.SearchSaveName !== undefined
        ? saveSearchResults?.SearchSaveName
        : "",
    AuthorSearch: [],
    Narrator: "",
    CategorySearch: [],
    FromPublisherDate: "",
    FromPurchaseDate: "",
    HoldRatio: "",
    ItemFormatId: 0,
    Language: [],
    SubCategorySearch: [],
    NotInCollection:false,
    InCollection:null,
    PPUCriteria:"1",
    MaximumPriceAmount: "",
    MinimumPriceAmount: "0",
    ExcludeSelfPublished: false,
    PublisherSearch: [],
    QuickSearch: [],
    TitleDaysOld: 0,
    PreSaleDays: 0,
    ExPpuTitleDays: 0,
    TitleSearch: [],
    ToPublisherDate: "",
    ToPurchaseDate: "",
    TargetAudienceIds: [],
    ContentProviderSearch: [],
    AddedToCatalogDateFrom: "",
    AddedToCatalogDateTo: "",
    AddedToCatalogDaysOld: 0,
    ItemFormatIdSet: ["1", "2", "3"],
    SearchSaveDescription: "",
    SharingStatusCode: "P",
    LibraryId: libraryId,
    EISBN: "",
    ChangeDateTime: "",
    ChangeUserId: "",
    CreateDateTime: "",
    CreateUserId: "",
    AllowedSharingStatusCodes: ["P"],
    MediaTypeIdSet: [],
    CatalogId: userCatalog,
    series: null,
    poRefNumber: "",
    recordCount: Page.DEFAULT_COUNT,
    recordStart: Page.DEFAULT_START,
    sortField: "",
    sortDirection: "ASC",
  };
  const saveID = {
    SearchSaveId:
      saveSearchResults?.SearchSaveId !== undefined
        ? saveSearchResults?.SearchSaveId
        : "",
    SearchSaveName:
      saveSearchResults?.SearchSaveName !== undefined
        ? saveSearchResults?.SearchSaveName
        : "",
    SharingStatusCode: "P",
    LibraryId: libraryId,
    NotInCollection: false,
    EISBN: "",
    ChangeDateTime: "",
    ChangeUserId: "",
    CreateDateTime: "",
    CreateUserId: "",
    AllowedSharingStatusCodes: ["P"],
    MediaTypeIdSet: [],
    CatalogId: userCatalog,
    recordCount: 50,
    recordStart: 1,
    sortField: "",
    sortDirection: "",
    PPUCriteria:"1"
  };
  const editSaveInitialData = Object.assign(
    saveID,
    saveSearchResults?.searchParams
  );
  const [searchDetails, setSearchDetails] = useState<any>(
    saveSearchResults?.searchParams !== undefined
      ? editSaveInitialData
      : advancedSearchInitialdata
  );
  const showQuery = {
    title: "",
    author: "",
    narrator: "",
    category: "",
    isbn: "",
    subCategory: "",
    audience: "",
    Language: "",
    keyword: "",
    series: "",
    poRefNumber: "",
    persaleTitle: !isCollection ? filterOptions.preSale[0].text : "",
    exppuTitle: filterOptions.ppuTitles[0].text,
    HoldRatio: "",
    MaximumPriceAmount: "",
    MinimumPriceAmount: "",
    publisher: "",
    publisheWithin: "",
    provider: "",
    dateAdedToCl: "",
    formats: "all",
    filters: "",
    publishDays: "",
    PPUCriteria: isCollection ? filterOptions.PPUCriteria[0].text : filterOptions.PPUCriteria[1].text,
    POReference: "",
    customDateRange: "",
  };
  let [form] = Form.useForm();

  const [strinDetails, setStrinDetails] = useState<any>(showQuery);
  //my collection filelds
  const myColObj = {
    PPUCriteria: "1",
    POReference: "",
  };
  const [collectionObj, setCollectionObj] = useState<any>(myColObj);

  const showNotification = (content: any, type: any) => {
    type === "loading"
      ? message.loading({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
      : message.success({
          content: content,
          key: "notificationMessage",
        });
  };

  const setEditInitialData = (editableData: any) => {
    setSearchDetails({
      ...searchDetails,
      SearchSaveId: saveSearchResults?.SearchSaveId,
      SearchSaveName: saveSearchResults?.SearchSaveName,
      AuthorSearch:
        editableData?.AuthorSearch !== undefined
          ? editableData?.AuthorSearch
          : [],
      Narrator:
        editableData?.Narrator !== undefined ? editableData?.Narrator : "",
      EISBN: editableData?.EISBN !== undefined ? editableData?.EISBN : "",
      CategorySearch:
        editableData?.CategorySearch !== undefined
          ? editableData?.CategorySearch
          : [],
      FromPublisherDate:
        editableData?.FromPublisherDate !== undefined
          ? editableData?.FromPublisherDate
          : "",
      HoldRatio:
        editableData?.HoldRatio !== undefined ? editableData?.HoldRatio : 0,
      ItemFormatId:
        editableData?.ItemFormatId !== undefined
          ? editableData?.ItemFormatId
          : 0,
      Language:
        editableData?.Language !== undefined ? editableData?.Language : [],
      MaximumPriceAmount:
        editableData?.MaximumPriceAmount !== undefined
          ? editableData?.MaximumPriceAmount
          : "",
      MinimumPriceAmount:
        editableData?.MinimumPriceAmount !== undefined
          ? editableData?.MinimumPriceAmount
          : "0",
      ExcludeSelfPublished:
        editableData?.ExcludeSelfPublished !== undefined
          ? editableData?.ExcludeSelfPublished
          : false,
      NotInCollection:
        editableData?.NotInCollection !== undefined
          ? editableData?.NotInCollection
          : false,
      PublisherSearch:
        editableData?.PublisherSearch !== undefined
          ? editableData?.PublisherSearch
          : [],
      QuickSearch:
        editableData?.QuickSearch !== undefined
          ? editableData?.QuickSearch
          : [],
      SubCategorySearch:
        editableData?.SubCategorySearch !== undefined
          ? editableData?.SubCategorySearch
          : [],
      TitleDaysOld:
        editableData?.TitleDaysOld !== undefined
          ? editableData?.TitleDaysOld
          : 0,
      PreSaleDays:
        editableData?.PreSaleDays !== undefined ? editableData?.PreSaleDays : 0,
      ExPpuTitleDays:
        editableData?.ExPpuTitleDays !== undefined
          ? editableData?.ExPpuTitleDays
          : 0,
      TitleSearch:
        editableData?.TitleSearch !== undefined
          ? editableData?.TitleSearch
          : [],
      ToPublisherDate:
        editableData?.ToPublisherDate !== undefined
          ? editableData?.ToPublisherDate
          : "",
      TargetAudienceIds:
        editableData?.TargetAudienceIds !== undefined
          ? editableData?.TargetAudienceIds
          : [],
      ContentProviderSearch:
        editableData?.ContentProviderSearch !== undefined
          ? editableData?.ContentProviderSearch
          : [],
      AddedToCatalogDateFrom:
        editableData?.AddedToCatalogDateFrom !== undefined
          ? editableData?.AddedToCatalogDateFrom
          : "",
      AddedToCatalogDateTo:
        editableData?.AddedToCatalogDateTo !== undefined
          ? editableData?.AddedToCatalogDateTo
          : "",
      AddedToCatalogDaysOld:
        editableData?.AddedToCatalogDaysOld !== undefined
          ? editableData?.AddedToCatalogDaysOld
          : 0,
      ItemFormatIdSet:
        editableData?.ItemFormatIdSet !== undefined
          ? editableData?.ItemFormatIdSet
          : selectedFormats,
      SearchSaveDescription:
        editableData?.SearchSaveDescription !== undefined
          ? editableData?.SearchSaveDescription
          : "",
      series: editableData?.series !== undefined ? editableData?.series : "",
      poRefNumber:
        editableData?.poRefNumber !== undefined
          ? editableData?.poRefNumber
          : "",
      FromPurchaseDate:
        editableData?.FromPurchaseDate !== undefined
          ? editableData?.FromPurchaseDate
          : "",
      ToPurchaseDate:
        editableData?.ToPurchaseDate !== undefined
          ? editableData?.ToPurchaseDate
          : "",
    });

    editableData?.ItemFormatIdSet !== undefined &&
      setSelectedFormats(editableData?.ItemFormatIdSet?.map(String));
  };

  const scrollToTop = () => {
    const scrollableForm: any = document.querySelector(
      ".advance-search-side-form"
    );
    scrollableForm.scrollTop = 0;
  };

  const onClear = () => {
    setHaskeyWord(false)
    form.resetFields();
    setSearchDetails(advancedSearchInitialdata)
    dispatch(getSaveSearchResult({ searchParams: {advancedSearchInitialdata}}));
    dispatch(clearAdvanceSearchData());
    scrollToTop();
    dispatch(getUnmatchValues([]));
    dispatch(onBackFillForm([]));
    setStrinDetails(showQuery);
    dispatch(onStringBackFillForm([]));
    setTreeValue(undefined);
    setCollectionObj({ ...collectionObj, PPUCriteria: "0", POReference: "" });
    setNarratorVisible(true);
  };

  // if save search have data
  useEffect(() => {
    dispatch(getSaveSearchResult(saveSearchResults));
    setCollectionObj({ ...collectionObj, PPUCriteria: "0", POReference: "" });
    dispatch(setSelectedTitles([]));
    dispatch(setGlobalCopy(1));
    // Setting height of side bar
    const element : any = document.querySelector('.advance-search-side-form');
    if (element) {
      element.style.height = `${window.innerHeight - 100}px`;
      element.style.paddingBottom = '10px';
    }
  }, []);

  useEffect(() => {
    if (formState?.isSuccess) {
      // dispatch(clearResults());
      dispatch(clearState());
    } else {
      return undefined;
    }
  }, [formState]);

  useEffect(() => {
    if (editableData?.ItemFormatIdSet === undefined) {
      setSelectedFormats(["1", "2", "3"]);
      setStrinDetails({ ...strinDetails, formats: "all" });
    }

    setEditInitialData(saveSearchResults?.searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editableData?.AddedToCatalogDateFrom,
    editableData?.AddedToCatalogDaysOld,
    saveSearchResults?.searchParams,
  ]);


  const regexForStringReplace = /,/gi;
  const handleAuthorChange = (e: any) => {
    var temp: any = e.target.value
      ?.replace(regexForStringReplace, ",")
      .split(";");
    setStrinDetails({ ...strinDetails, author: temp.toString() });
    setSearchDetails({ ...searchDetails, AuthorSearch: temp });
  };
  const handleNarratorChange = (e: any) => {
    var temp: any = e.target.value;
    setStrinDetails({ ...strinDetails, narrator: temp.toString() });
    setSearchDetails({ ...searchDetails, Narrator: temp });
  };
  const handleKeyWord = (e: any) => {
    setHaskeyWord(e?.target?.value?true:false)
    setSearchDetails({ ...searchDetails, QuickSearch: [e?.target?.value] });
    setStrinDetails({ ...strinDetails, keyword: e?.target?.value });
  };

  const handleTitle = (e: any) => {
    setSearchDetails({ ...searchDetails, TitleSearch: [e?.target?.value] });
    setStrinDetails({ ...strinDetails, title: e?.target?.value });
  };
  const handlePublisherChange = (value: any, defaultValue: any) => {
    var providerObj = publisherOptions?.map((item: any) => {
      if(value?.includes(item?.value)){
       return item?.title
      }
      })?.filter((items:any)=>{
        return items !== undefined
      });
      setStrinDetails({
        ...strinDetails,
        publisher:providerObj?.toString(),
      });
      setSearchDetails({ ...searchDetails, PublisherSearch: value });
  };
  const handleProviderChange = (value: any, defaultValue: any) => {
    var providerObj = providerOptions?.map((item: any) => {
      if(value?.includes(item?.value)){
       return item?.title
      }
      })?.filter((items:any)=>{
        return items !== undefined
      });
      setStrinDetails({
        ...strinDetails,
        provider:providerObj?.toString(),
      });
      setSearchDetails({ ...searchDetails, ContentProviderSearch: value });
  };
  const handleAudienceChange = (value: any, defaultValue: any) => {
    let pushData: any[] = [];
    var audienceObj = defaultValue.map((item: any) => {
      return item?.children[1]?.props?.children;
    });
    pushData.push(audienceObj);
    setStrinDetails({ ...strinDetails, audience: pushData[0].toString() });
    setSearchDetails({ ...searchDetails, TargetAudienceIds: value });
  };
  const handleLanguageChange = (value: any, defaultValue: any) => {
    var langObj = languageOptions?.map((item: any) => {
      if(value?.includes(item?.value)){
       return item?.title
      }
      })?.filter((items:any)=>{
        return items !== undefined
      });
      setStrinDetails({
        ...strinDetails,
        language:langObj?.toString(),
      });
      setSearchDetails({ ...searchDetails, Language: value });
  };

  const handlePreSale = (value: any, defaultValue: any) => {
    setStrinDetails({
      ...strinDetails,
      persaleTitle: defaultValue?.children[1]?.props?.children,
    });
    setSearchDetails({ ...searchDetails, PreSaleDays: value });
  };

  const handleFormatChange = (e: any) => {
    e.target.checked
      ? setSelectedFormats(selectedFormats.concat(e.target.name))
      : setSelectedFormats(
          selectedFormats.filter((value: any) => {
            return value !== e.target.name;
          })
        );
  };
  useEffect(() => {
    setSelFormats(selectedFormats);
    // eslint-disable-next-line array-callback-return
    var Formatval = selectedFormats.map((item: any) => {
      if (item == "1") {
        return "EPUB";
      } else if (item == "2") {
        return "PDF";
      } else if (item == "3") {
        return "MP3";
      }
    });
    if (selectedFormats?.length >= 3) {
      setStrinDetails({ ...strinDetails, formats: "all" });
    } else {
      setStrinDetails({ ...strinDetails, formats: Formatval.toString() });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormats]);

  useEffect(() => {
    setSearchDetails({ ...searchDetails, ItemFormatIdSet: selFormat });
    // eslint-disable-next-line array-callback-return
    var Formatval = selectedFormats.map((item: any) => {
      if (item == "1") {
        return "EPUB";
      } else if (item == "2") {
        return "PDF";
      } else if (item == "3") {
        return "MP3";
      }
    });
    if (selectedFormats?.length >= 3) {
      setStrinDetails({ ...strinDetails, formats: "all" });
    } else {
      setStrinDetails({ ...strinDetails, formats: Formatval.toString() });
    }
  }, [selFormat]);

  useEffect(() => {
    if (searchSaved) {
      showNotification(Messages.SAVE_SEARCH_SUCCESS, "success");
      dispatch(clearSaved());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isSuccess, searchSaved]);

  const handleDateRangeSelect = (value: number) => {
    if (value !== -2) {
      setDaysVal(value);
    } else {
      setDaysVal(0);
    }
  };

  const handleCloudDateRangeSelect = (value: number) => {
    if (value !== -2) {
      setCloudDaysVal(value);
      if (value === -1) {
      } else {
      }
    } else {
      setCloudDaysVal(0);
    }
  };
  useEffect(() => {
    if (daysVal !== undefined) {
      setStrinDetails({
        ...strinDetails,
        publishDays: `${daysVal} days`,
        publisheWithin: "",
      });
      setSearchDetails({
        ...searchDetails,
        FromPublisherDate: "",
        ToPublisherDate: "",
        TitleDaysOld: daysVal !== -1 ? daysVal : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysVal]);
  useEffect(() => {
    if (cloudDaysVal !== undefined) {
      setStrinDetails({
        ...strinDetails,
        dateAdedToCl: `${cloudDaysVal} days`,
      });
      setSearchDetails({
        ...searchDetails,
        AddedToCatalogDateFrom: "",
        AddedToCatalogDaysOld: cloudDaysVal !== -1 ? cloudDaysVal : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudDaysVal]);
  const handleRangeSelect = (date: any, dateString: any) => {
    setDates(dateString);
    setStrinDetails({
      ...strinDetails,
      publisheWithin: dateString.toString(),
      publishDays: "",
    });
  };
  const handleDateSelect = (date: any, dateString: any) => {
    setCloudDate(dateString);
    setStrinDetails({ ...strinDetails, dateAdedToCl: dateString.toString() });
  };
  const handleInputChanges = (e: any) => {
    let x:any = []
    var name = e.target.name;
    var value = e.target.value;
    setStrinDetails({ ...strinDetails, [name]: value });
    if(e?.target?.name === 'series'){
      x?.push(value)
      setSearchDetails({ ...searchDetails, [name]: x });
    }else{
      setSearchDetails({ ...searchDetails, [name]: value });
    }
  };

  useEffect(() => {
    var max = parseFloat(strinDetails?.MaximumPriceAmount);
    var min = parseFloat(strinDetails?.MinimumPriceAmount);
    if (max < min) {
      setShowError("Max value should be greater than or equal to min value");
    } else {
      setShowError("");
    }
  }, [strinDetails?.MaximumPriceAmount, strinDetails?.MinimumPriceAmount]);

  const handleExPpuTitle = (value: any, defaultValue: any) => {
    setSearchDetails({
      ...searchDetails,
      PPUCriteria: value,
    });
    setStrinDetails({
      ...strinDetails,
      PPUCriteria: defaultValue?.children[1]?.props?.children,
    });
  };

  const handleFilterChange = (e: any) => {
    var name = e.target.name;
    var checked = e.target.checked;
    if (checked !== false) {
      setStrinDetails({ ...strinDetails, filters: "applied" });
    } else {
      setStrinDetails({ ...strinDetails, filters: "" });
    }
    if(e.target.name === "ExcludeSelfPublished"){
      setSearchDetails({ ...searchDetails, [name]: checked});
    }
    if(e.target.name === "InCollection"){
      setSearchDetails({ ...searchDetails, [name]: checked? false : null});
    }
  };
  useEffect(() => {
    setSearchDetails({
      ...searchDetails,
      FromPublisherDate: dates[0],
      ToPublisherDate: dates[1],
      TitleDaysOld: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);
  useEffect(() => {
    setSearchDetails({
      ...searchDetails,
      AddedToCatalogDateFrom: cloudDate[0],
      AddedToCatalogDateTo: cloudDate[1],
      AddedToCatalogDaysOld: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudDate]);

  const onSearch = () => {
    dispatch(clearResults());
    dispatch(clearState());
    dispatch(getSearchString(strinDetails));
    if (showError.length < 5) {
      dispatch(onBackFillForm(searchDetails));
      const searchValues = { ...searchDetails };
      if (
        searchValues?.QuickSearch &&
        typeof searchValues.QuickSearch[0] === "string" &&
        /^\d{13}$/.test(searchValues.QuickSearch[0])
      ) {
        searchValues.QuickSearch[0] = "";
      }
      var advanceState: any = {
        fromAdvancedSearch: true,
        searchDetails: { ...searchDetails, EISBN: "" },
        gaString: {
          byFrom: "onSearchbtn",
          gaData: strinDetails,
          currentPage: booklistCurrentPage,
        },
      };
      dispatch(clearBooklistCurrentPage());
      history.push(AppRoutes.SEARCHRESULTS, advanceState);
    }
  };

  useEffect(() => {
    setSearchDetails({
      ...searchDetails,
      CategorySearch: treeParentvalue,
      SubCategorySearch: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeParentvalue ]);

  const getNewCatObject = (categoriesOptions: any) => {
    function onlyUnique(value: any, index: any, self: any) {
      return self?.indexOf(value) === index;
    }
    var totalCatGroup = categoriesOptions?.map((data: any) => {
      var group = data?.CategoryGroup;
      return group;
    }, []);
    var totalUniqCatG = totalCatGroup?.filter(onlyUnique);

    function pushNewArray(groupN: string) {
      var filterData = categoriesOptions?.filter((data: any) => {
        return data?.CategoryGroup === groupN;
      });
      return filterData;
    }
    var newFilterObject = totalUniqCatG?.map((item: any) => {
      return {
        CategoryGroup: item,
        category: pushNewArray(item),
      };
    });
    var finalNewData = newFilterObject?.map((item: any) => {
      if (item?.category?.length > 1) {
        return {
          CategoryGroup: item?.CategoryGroup,
          category: item?.category,
        };
      } else {
        return {
          CategoryGroup: item?.CategoryGroup,
          category: item?.category[0]?.SubCategory,
        };
      }
    });
    return finalNewData;
  };

  const getTreeDropOptions = (newCatObj: any) => {
    var newData = newCatObj?.map((data: any) => {
      return {
        title: data?.CategoryGroup,
        value: `${data?.category[0]?.ParentCategoryId}`,
        key: `${data?.category[0]?.ParentCategoryId}`,
        parentVal: `${data?.category[0]?.ParentCategoryId}`,
        children: data?.category?.map((data: Category) => {
          return {
            title: data?.CategoryName,
            value: `${data?.CategoryId}`,
            key: `${data?.CategoryId}`,
            parentVal: `${data?.CategoryId}`,
            children:
              data?.SubCategory?.length === 0
                ? []
                : data?.SubCategory?.map((child: any) => {
                    return {
                      title: child?.CategoryName,
                      value: `${child?.CategoryId}`,
                      parentVal: `${child?.CategoryId}`,
                      childVal: `${child?.CategoryId}`,
                      key: `${child?.CategoryId}`,
                      children: child?.SubCategory?.length === 0 ? [] : 
                      child?.SubCategory?.map((childB: any) => {
                          return {
                            title: childB?.CategoryName,
                            value: `${childB?.CategoryId}`,
                            parentVal: `${childB?.CategoryId}`,
                            key: `${childB?.CategoryId}`,
                            childVal: `${childB?.CategoryId}`,
                          };
                        }),
                    };
                  }),
          };
        }),
      };
    });
    return newData;
  };

  useEffect(() => {
    setNewCatObj(getNewCatObject(categoriesOptions));
  }, [categoriesOptions]);

  useEffect(() => {
    if (newCatObj) {
      var A: any = newCatObj,
        x = 0,
        y = 1;
      A[x] = A?.splice(y, 1, A[x])[0];
      if (A?.length > 1) {
        setTreeData(getTreeDropOptions(newCatObj));
      }
    }
  }, [newCatObj]);

  const onChangeTreeSelect = (value: any, label: any, extra: any) => {
    setTreeValue(value);
    var parentId = treeData?.map((item: any) => {
      return item?.value;
    });
    var reuduceGroupParentId = value?.filter((item: any) => {
      return !parentId?.includes(item);
    });
    setTreeParentvalue(reuduceGroupParentId);
    setStrinDetails({
      ...strinDetails,
      category: label?.toString(),
    });
  };

  const tProps = {
    treeData,
    value: treevalue,
    onChange: onChangeTreeSelect,
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_ALL,
    placeholder: "Category and Subjects",
    style: {
      width: "100%",
    },
  };

  const filterPresaleOption = (value: number) => {
    var data = filterOptions?.preSale.filter((item: any) => {
      var data = item?.value == value;
      return data;
    });
    if (data[0]?.text !== undefined) {
      return `${data[0]?.text}`;
    } else {
      return filterOptions.preSale[0].text;
    }
  };

  const filterPPUCiteriaOption = (value: number) => {
    var data = filterOptions?.PPUCriteria.filter((item: any) => {
      var data = item?.value === value;
      return data;
    });
    if (data[0]?.text !== undefined) {
      return `${data[0]?.text}`;
    } else {
      return filterOptions.ppuTitles[1].text;
    }
  };

  // const filterLanguageOption = (value: string) => {
  //   var data = languageOptions?.filter((item: any) => {
  //     var data = item?.value == value;
  //     return data;
  //   });
  //   if (data && data.length > 0 && data[0]?.text !== undefined) {
  //     return `${data[0]?.text}`;
  //   } else {
  //     return undefined;
  //   }
  // };

  useEffect(() => {
    if (searchSaved == true) {
      if (saveSearchResults?.SearchSaveId !== undefined) {
        dispatch(setSearchAfterEdit(saveSearchResults));
        dispatch(clearSaveSearchResult());
        dispatch(setSearchTab("3"));
        dispatch(setSearch(editedData));
      } else {
        dispatch(setSearchTab("1"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSaved]);

  useEffect(() => {
    setEditedData(searchDetails);
  }, [searchDetails, saveSearchResults?.searchParams]);

  const dateConverter = (date: any) => {
    if (date !== "") return moment(date, "");
    else {
      return undefined;
    }
  };

  useEffect(() => {
    if (selectedFormats.includes("3")) {
      setNarratorVisible(true);
    } else {
      setNarratorVisible(false);
      var temp: any = "";
      setStrinDetails({ ...strinDetails, narrator: temp.toString() });
      setSearchDetails({ ...searchDetails, Narrator: temp });
    }
  }, [selectedFormats]);

  useEffect(() => {
    if (strinDetails.persaleTitle === "" && !isCollection) {
      setStrinDetails({
        ...strinDetails,
        persaleTitle: filterOptions.preSale[0].text,
      });
    }
  }, [strinDetails]);

  useEffect(() => {
    if (clearForm) {
      setTimeout(() => {
        onClear();
      }, 1000);
    }
  }, [clearForm, isCollection]);

  const getInputFields = (inputObj: any) => {
    return {
      Keyword:
        inputObj?.QuickSearch?.length !== 0
          ? inputObj?.QuickSearch?.toString()
          : "",
      EISBN: inputObj ? inputObj.isbn : "",
      Title:
        inputObj?.TitleSearch?.length !== 0
          ? inputObj?.TitleSearch?.toString()
          : "",
      AuthorSearch:
        inputObj?.AuthorSearch?.length !== 0
          ? inputObj?.AuthorSearch?.toString()
          : "",
      Narrator: inputObj?.Narrator !== "" ? inputObj?.Narrator?.toString() : "",
      Series: inputObj?.series !== null ? inputObj?.series : "",
      PresaleTitles: filterPresaleOption(inputObj?.PreSaleDays),
      HoldRatio: inputObj?.HoldRatio !== undefined ? inputObj?.HoldRatio : "",
      Publishers:
        inputObj?.PublisherSearch?.length !== 0
          ? inputObj?.PublisherSearch
          : [],

      ContentProviders:
        inputObj?.ContentProviderSearch?.length !== 0
          ? inputObj?.ContentProviderSearch
          : [],
      EPUB:
        inputObj?.ItemFormatIdSet !== undefined
          ? inputObj?.ItemFormatIdSet.includes("1")
          : true,
      PDF:
        inputObj?.ItemFormatIdSet !== undefined
          ? inputObj?.ItemFormatIdSet.includes("2")
          : true,
      MP3:
        inputObj?.ItemFormatIdSet !== undefined
          ? inputObj?.ItemFormatIdSet.includes("3")
          : true,
      CategoryAllIds:
        inputObj?.CategorySearch !== 0 ? inputObj?.CategorySearch : undefined,
      AllAudiences:
        inputObj?.TargetAudienceIds?.length !== 0
          ? inputObj?.TargetAudienceIds
          : undefined,

      MinimumPriceAmount:
        inputObj?.MinimumPriceAmount !== undefined
          ? inputObj?.MinimumPriceAmount
          : "",

      MaximumPriceAmount:
        inputObj?.MaximumPriceAmount !== undefined
          ? parseFloat(inputObj?.MaximumPriceAmount) <
            parseFloat(inputObj?.MinimumPriceAmount)
            ? ""
            : inputObj?.MaximumPriceAmount !== "0"
            ? inputObj?.MaximumPriceAmount
            : ""
          : "",


      FromPurchaseDate:
        inputObj?.FromPurchaseDate !== undefined
          ? [
              moment(dateConverter(inputObj?.FromPurchaseDate)),
              moment(dateConverter(inputObj?.ToPurchaseDate)),
            ]
          : undefined,

      PublishedWithin:
        inputObj?.TitleDaysOld !== undefined
          ? inputObj?.FromPublisherDate !== ""
            ? -1
            : inputObj?.TitleDaysOld !== 0
            ? parseInt(inputObj?.TitleDaysOld) ? [parseInt(inputObj?.TitleDaysOld)] : -1
            : undefined
          : undefined,

      FromPublisherDate:
        inputObj?.FromPublisherDate !== undefined
          ? inputObj?.FromPublisherDate !== ""
            ? [
                moment(dateConverter(inputObj?.FromPublisherDate)),
                moment(dateConverter(inputObj?.ToPublisherDate)),
              ]
            : undefined
          : undefined,

      DateAddedToCloudLibrary:
        inputObj?.AddedToCatalogDaysOld !== undefined
          ? inputObj?.AddedToCatalogDateFrom !== ""
            ? -1
            : parseInt(inputObj?.AddedToCatalogDaysOld) !== 0
            ? parseInt(inputObj?.AddedToCatalogDaysOld) ? [parseInt(inputObj?.AddedToCatalogDaysOld)] : -1
            : undefined
          : undefined,

      CustomCloudLibraryDateRange:
        inputObj?.AddedToCatalogDateFrom !== undefined
          ? inputObj?.AddedToCatalogDateFrom !== ""
            ? [
                moment(dateConverter(inputObj?.AddedToCatalogDateFrom)),
                moment(dateConverter(inputObj?.AddedToCatalogDateTo)),
              ]
            : undefined
          : undefined,

      Language: inputObj?.Language!==0
      ? inputObj?.Language
      : [],
      ExcludePPUTitles:filterPPUCiteriaOption(inputObj?.PPUCriteria),
      PoReferenceNumber:
        collectionObj?.POReference !== null ? collectionObj?.POReference : "",
      ExcludeSelfPublished:
        inputObj?.ExcludeSelfPublished !== undefined
          ? (inputObj?.ExcludeSelfPublished).toString().toLowerCase() ==
            "True".toLowerCase()
            ? true
            : false
          : false,
      NotInCollection:
        inputObj?.NotInCollection !== undefined
          ? (inputObj?.NotInCollection)?.toString()?.toLowerCase() ===
            "True"?.toLowerCase()
            ? true
            : false
          : false,
          InCollection:inputObj?.InCollection !== null || undefined
          ? (inputObj?.InCollection)?.toString()?.toLowerCase() ===
          "False"?.toLowerCase()
          ? true
          : false
        : false,
      ExcludeAbridgedAudiobooks:
        inputObj?.ExcludeAbridgedAudiobooks !== undefined
          ? inputObj?.ExcludeAbridgedAudiobooks
          : false,
    };
  };

  const fieldValues : any = useMemo(() => getInputFields(searchDetails), [searchDetails]);

  useEffect(() => {
    if (!location?.state?.cameFromIsbn) {
      const inputValues = getInputFields(location?.state?.searchDetails);
      if (
        inputValues?.Keyword &&
        inputValues?.Keyword?.length > 12 &&
        !isNaN(inputValues?.Keyword)
      ) {
        inputValues.Keyword = "";
      }
      form.setFieldsValue(inputValues);
      setStrinDetails({ ...location?.state?.gaString?.gaData });
      setSearchDetails(location?.state?.searchDetails);
    }
  }, [location]);

  useEffect(() => {
    if (fieldValues?.Keyword && fieldValues?.Keyword?.length > 0) {
      setHaskeyWord(true);
    } else {
      setHaskeyWord(false);
    }
  }, [fieldValues?.Keyword]);

  return (
    // <SpinnerLoader loading={searchFormState?.loading || formState?.loading}>
    <div className="advanced-search-side-bar">
      <div>
        <p className="heading-para">Advanced Search</p>
        <div className="heading-button-container">
          <Col span={24}>
            <Card>
              <Row className="advance-search-header">
                <Col span={2}></Col>
                <Col span={10} className="clear-button">
                  <input
                    type="button"
                    className="primary"
                    value="CLEAR"
                    id="advanced-clear-btn"
                    onClick={onClear}
                  />
                </Col>
                <Col span={10} className="action-button primarySearch">
                  <Button
                    type="primary"
                    id="advanced-search-btn"
                    onClick={onSearch}
                  >
                    SEARCH
                  </Button>
                </Col>
                <Col span={2}></Col>
              </Row>
            </Card>
          </Col>
        </div>
        <Form
          form={form}
          onFinish={onSearch}
          className="sideForm scrollStyle"
          // style={{height:"92vh",overflowY:"scroll",overflowX:"hidden"}}
        >
          <Row className="form-container" gutter={[20, 20]}>
            <Col span={24} className="row-spacing">
              <Row>
                <Col span={24}>
                  <Card className="searchForm-card">
                    <p className="card-title">Search Details</p>
                    <Form.Item name="Keyword">
                      <Input
                        className="card-input"
                        placeholder="Keywords"
                        onChange={handleKeyWord}
                        value={fieldValues?.Keyword}
                      />
                    </Form.Item>
                      <Form.Item name="Title">
                        <Input
                          className="card-input"
                          placeholder="Title"
                          onChange={handleTitle}
                          disabled={haskeyWord}
                          value={fieldValues.Title}
                        />
                      </Form.Item>
                      <Form.Item name="AuthorSearch">
                        <Input
                          name="AuthorSearch"
                          placeholder="Author(s)"
                          className="card-input"
                          bordered={false}
                          disabled={haskeyWord}
                          onChange={handleAuthorChange}
                          value={fieldValues.AuthorSearch}
                        />
                      </Form.Item>
                      <Form.Item name="Series">
                        <Input
                          className="card-input"
                          name="series"
                          disabled={haskeyWord}
                          placeholder="Series"
                          onChange={handleInputChanges}
                          value={fieldValues.Series}
                        />
                      </Form.Item>
                      <Form.Item name="Narrator">
                        <Input
                          name="Narrator"
                          placeholder="Narrator"
                          className={`${
                            narratorVisible
                              ? "card-input"
                              : "card-input narator-input"
                          }`}
                          bordered={false}
                          disabled={narratorVisible ? false : true}
                          onChange={handleNarratorChange}
                          value={fieldValues.Narrator}
                        />
                      </Form.Item>


                      {!isCollection ? (
                        <Form.Item name="PresaleTitles">
                          <DropDown
                            className="card-dropdown"
                            placeholder="Presale Titles"
                            bordered={false}
                            optionValue={filterOptions.preSale}
                            icon={downIcon}
                            onChange={handlePreSale}
                            defaultValue={fieldValues.PresaleTitles}
                            value={fieldValues.PresaleTitles}
                          />
                        </Form.Item>
                      ) : null}
                      <FormItem
                        name="HoldRatio"
                        rules={[
                          {
                            pattern: /^([0-9]+\.?[0-9]*|\.[0-9]+)$/,
                            message:
                              "hold ratio must be greater than or equal to 0 !",
                          },
                        ]}
                      >
                        <Input
                          className="card-input"
                          name="HoldRatio"
                          placeholder="Hold Ratio Equal or Above  (Ex. 1, 1.5, 2...)"
                          onChange={handleInputChanges}
                          value={fieldValues.HoldRatio}
                          disabled={searchDetails?.InCollection !== false?false:true}
                        />
                      </FormItem>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Card className="searchForm-card">
                      <div className="provider-div">
                        <p className="card-title">Formats</p>
                        {formatOptions?.map((data: any) => {
                          return data?.ItemFormat?.map(
                            (itemFormat: ItemFormat) => {
                              return (
                                <Form.Item
                                  name={itemFormat.ItemFormatName}
                                  valuePropName="checked"
                                  initialValue={true}
                                >
                                  <Checkbox
                                    className="cards-checkbox"
                                    key={itemFormat?.ItemFormatId}
                                    name={itemFormat?.ItemFormatId.toString()}
                                    value={fieldValues[itemFormat?.ItemFormatName]}
                                    onChange={handleFormatChange}
                                  >
                                    {itemFormat.ItemFormatName}
                                  </Checkbox>
                                </Form.Item>
                              );
                            }
                          );
                        })}
                      </div>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Card className="searchForm-card">
                      <p className="card-title">Dates</p>

                      <div className="searchbyDateSelect">
                        <Form.Item name="PublishedWithin">
                          <DropDown
                            className="card-dropdown"
                            placeholder="Published Within"
                            bordered={false}
                            optionValue={filterOptions.dateDuration}
                            icon={downIcon}
                            onChange={handleDateRangeSelect}
                            TitleDaysOld
                            value={fieldValues.PublishedWithin}
                          />
                        </Form.Item>

                        {fieldValues.PublishedWithin === -1 ? (
                          <Form.Item name="FromPublisherDate">
                            <RangePicker
                              placeholder={["Custom Date Range", ""]}
                              format={"MM/DD/YYYY"}
                              getPopupContainer={(trigger: any) =>
                                trigger.parentNode
                              }
                              onChange={handleRangeSelect}
                              value={
                                fieldValues?.FromPublisherDate !== undefined
                                  ? fieldValues?.FromPublisherDate !== ""
                                    ? [
                                        moment(
                                          dateConverter(
                                            fieldValues?.FromPublisherDate
                                          )
                                        ),
                                        moment(
                                          dateConverter(
                                            fieldValues?.ToPublisherDate
                                          )
                                        ),
                                      ]
                                    : undefined
                                  : undefined
                              }
                              className="card-datepicker"
                              suffixIcon={<img src={downIcon} alt="" />}
                              separator={<img src="" alt="" />}
                            />
                          </Form.Item>
                        ) : null}
                      </div>
                      <div className="searchbyDateSelect">
                        <Form.Item name="DateAddedToCloudLibrary">
                          <DropDown
                            className="card-dropdown"
                            placeholder="Date Added to cloudLibrary"
                            bordered={false}
                            optionValue={filterOptions.CloudDateDuration}
                            icon={downIcon}
                            onChange={handleCloudDateRangeSelect}
                            value={fieldValues.DateAddedToCloudLibrary}
                          />
                        </Form.Item>
                        {fieldValues.DateAddedToCloudLibrary === -1 ? (
                          <>
                            <Form.Item name="CustomCloudLibraryDateRange">
                              <RangePicker
                                placeholder={[
                                  "Custom cloudLibrary Date Range",
                                  "",
                                ]}
                                format={"MM/DD/YYYY"}
                                getPopupContainer={(trigger: any) =>
                                  trigger.parentNode
                                }
                                onChange={handleDateSelect}
                                value={
                                  fieldValues?.AddedToCatalogDateFrom !== undefined
                                    ? fieldValues?.AddedToCatalogDateFrom !== ""
                                      ? [
                                          moment(
                                            dateConverter(
                                              fieldValues?.AddedToCatalogDateFrom
                                            )
                                          ),
                                          moment(
                                            dateConverter(
                                              fieldValues?.AddedToCatalogDateTo
                                            )
                                          ),
                                        ]
                                      : undefined
                                    : undefined
                                }
                                className="card-datepicker"
                                suffixIcon={<img src={downIcon} alt="" />}
                                separator={<img src="" alt="" />}
                              />
                            </Form.Item>
                          </>
                        ) : null}
                      </div>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Card className="searchForm-card pb-10">
                      <p className="card-title">Category and Subject</p>
                      <Form.Item name="CategoryAllIds">
                        <TreeSelect
                          showSearch
                          notFoundContent={
                            <Empty description="No results found." />
                          }
                          maxTagCount={2}
                          showArrow={true}
                          suffixIcon={<img src={searchIcon} alt="" />}
                          defaultValue={fieldValues.CategoryAllIds}
                          className="category-div treeSelectDropdown with-search"
                          {...tProps}
                          allowClear
                          multiple
                          getPopupContainer={(trigger) => trigger.parentNode}
                          filterTreeNode={(search: any, item: any) => {
                            return (
                              item.title
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) >= 0
                            );
                          }}
                        />
                      </Form.Item>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Card className="searchForm-card">
                      <p className="card-title">Audience</p>
                      <Form.Item name="AllAudiences">
                        <DropDown
                          placeholder="All Audiences"
                          showSearch={true}
                          className="card-dropdown with-search"
                          bordered={false}
                          optionValue={audienceDataOptions}
                          icon={searchIcon}
                          mode="multiple"
                          onChange={handleAudienceChange}
                          value={fieldValues.AllAudiences}
                        />
                      </Form.Item>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Card className="searchForm-card">
                      <p className="card-title">Providers</p>

                      <Form.Item name="ContentProviders">
                      <MultiSelect
                        className="card-dropdown with-search"
                        placeholder="Content Providers"
                        onChange={handleProviderChange}
                        defaultValue={fieldValues?.ContentProviders}
                        option={providerOptions}
                        maxTagCount={1}
                        isContentProvider={true}
                        headingText={"Content Providers"}
                      />
                      
                      </Form.Item>
                      <Form.Item name="Publishers">
                          <MultiSelect
                        className="card-dropdown with-search"
                        placeholder="Publishers"
                        onChange={handlePublisherChange}
                        defaultValue={fieldValues?.Publishers}
                        option={publisherOptions}
                        maxTagCount={1}
                        isContentProvider={true}
                        headingText={"Publishers"}
                      />
                      </Form.Item>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Card className="searchForm-card">
                      <p className="card-title">Languages</p>
                      <Form.Item name="Language">
                      <MultiSelect
                        className="card-dropdown with-search"
                        placeholder="Language(s)"
                        onChange={handleLanguageChange}
                        defaultValue={
                          fieldValues?.Language
                            ? fieldValues?.Language
                            : undefined
                        }
                        option={languageOptions}
                        maxTagCount={1}
                        maxSelection={10}
                        id="languages"
                      />
                      </Form.Item>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Card className="searchForm-card">
                      <p className="card-title">Pricing</p>
                      <Row>
                        <Col span={12} className="pricing-col">
                          <FormItem
                            name="MinimumPriceAmount"
                            rules={[
                              {
                                pattern: /^([0-9]*\.?[0-9]{1,2})$/,
                                message: "Please enter a valid amount!",
                              },
                            ]}
                          >
                            <Input
                              className="card-input"
                              name="MinimumPriceAmount"
                              placeholder="Min"
                              onChange={handleInputChanges}
                              value={fieldValues.MinimumPriceAmount}
                            />
                          </FormItem>
                        </Col>
                        <Col span={12} className="pricing-col">
                          <FormItem
                            name="MaximumPriceAmount"
                            className={showError.length > 2 ? "errorShow" : ""}
                            rules={[
                              {
                                pattern: /^([0-9]*\.?[0-9]{1,2})$/,
                                message: "Please enter a valid amount!",
                              },
                              {
                                pattern: /[1-9]/,
                                message: "Max value cannot be 0",
                              },
                            ]}
                          >
                            <Input
                              className="card-input"
                              name="MaximumPriceAmount"
                              placeholder="Max"
                              onChange={handleInputChanges}
                              value={fieldValues.MaximumPriceAmount}
                            />
                          </FormItem>
                          {showError !== "" ? (
                            <p className="showError">{showError}</p>
                          ) : null}
                        </Col>
                        <Col
                          sm={24}
                          md={24}
                          lg={10}
                          xl={10}
                          className="pricing-col"
                        >
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Card className="searchForm-card">
                      <p className="card-title">Filters</p>
                      <Col span={24}>
                      <DropDown
                              className="card-dropdown"
                              placeholder="Exclude PPU Titles"
                              bordered={false}
                              optionValue={filterOptions.PPUCriteria}
                              icon={downIcon}
                              onChange={handleExPpuTitle}
                              value={fieldValues.ExcludePPUTitles}
                            />
                        </Col>
                      <Form.Item name="InCollection" valuePropName="checked">
                        <Checkbox
                          className="cards-checkbox"
                          name="InCollection"
                          onChange={handleFilterChange}
                          disabled={searchDetails?.HoldRatio?true:false}
                          defaultChecked={fieldValues.InCollection}
                        >
                          {" "}
                          Exclude owned or ordered
                        </Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="ExcludeSelfPublished"
                        valuePropName="checked"
                      >
                        <Checkbox
                          className="cards-checkbox"
                          name="ExcludeSelfPublished"
                          onChange={handleFilterChange}
                          defaultChecked={fieldValues.ExcludeSelfPublished}
                        >
                          {" "}
                          Exclude self-published
                        </Checkbox>
                      </Form.Item>
                    </Card>
                    <Card className="filterC searchForm-card">
                   </Card>
                  </Col>
                </Row>
                <Row style={{display:"none"}}>
                  <Col span={24}>
                    <Card className="searchForm-card">
                      {saveSearchResults?.SearchSaveId !== undefined ? (
                        <>
                        </>
                      ) : (
                        <>
                          <Row justify="end" className="search-footer">
                            <Col span={22} className="clear-button" order={2}>
                              <input
                                type="button"
                                className="primary"
                                value="Clear"
                                id="advanced-clear-btn"
                                onClick={onClear}
                              />
                            </Col>
                            <Col
                              span={24}
                              className="action-button primarySearch"
                              order={3}
                            >
                              <Button type="primary" id="advanced-search-btn">
                                Search
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    // </SpinnerLoader>
  );
});
