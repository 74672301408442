/* eslint-disable eqeqeq */
import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { APIResponseError } from "../../constants/enums";
import User from "../../models/user/user";


export class AppVersionService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.VERSION));
  }

   async getNewAppVersion() {
    const response = await this.get(`version`);    
      if (response?.status === 200) {
        const data = response?.data;
        const appData = data
        return appData;
      }else{
          return null;
        }
  }

}
export class AuthService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.AUTH));
  }

  /**
   * This will return name of Organization in case of valid email
   * @param user
   * @returns
   */
  async checkEmail(userInput: any) {
    const response = await this.post(`login`,userInput);
    if (response && response.data) {
      if (response?.status==200 && response?.data.success === 1) {
        const data = response.data;
        const userData = this.setUserData(data);
        return userData;
      }else if(response?.data?.error !== undefined){
        const error = {error:response?.data?.error?.ErrorMessage}
         return error
      }
      else{
        const error = APIResponseError.NOT_FOUND;
        if (error) {
          return null;
        }
      } 
    }
    return null;
  }

  async singleSignOn(id: any) {
    const response = await this.post(`ssoLogin?id=${id}`);
    if (response && response.data) {
      if (response?.status==200 && response?.data.success === 1) {
        const data = response.data;
        const userData = this.setUserData(data);
        return userData;
      }else{
        const error = APIResponseError.NOT_FOUND;
        if (error) {
          return null;
        }
      } 
    }
    return null;
  }

  async checkLibrarianEmail(userInput: any) {
    const response = await this.post(`librarylogin`,userInput);
    if (response && response.data) {
      if (response?.status==200 && response?.data.success === 1) {
        const data = response.data;
        const addKey = Object.assign(data,{libLogin:true})
        const userData = this.setUserData(addKey);
        return userData;
      }else if(response?.data?.error !== undefined){
          const error = {error:response?.data?.error}
           return error
        }
      } 
    return null;
  }

  async logOut(userInput: any) {
    const response = await this.post(`signOut`,userInput);
    if (response && response.data) {
      if (response?.status==200) {
        const data = response;
        return data;
      }else{
        const error = APIResponseError.NOT_FOUND;
        if (error) {
        }
      } 
    }
    return null;
  }

  async forgotPassword (payload: string): Promise<any> {
    const encodedPayload = encodeURIComponent(payload);
    const response = await this.get(`forgotPassword?user_Id=${encodedPayload}`);
    if(response.data.success === 1){
      var data = 'An email has been sent with further details to reset your password.';
      return data;
    }
    else return response?.data?.data
  }


  async resetPassword(payload: any): Promise<any> {
    const response = await this.post(`resetPassword`, payload);
    if (response.data.success === 1) {
      return true;
    } else return response?.data?.data;
  }

  async libraryInitialPassword(payload: any): Promise<any> {
    const response = await this.post(`libraryInitialPassword`, payload);
    if (response.data.success === 1) {
      return true;
    } else return response?.data?.data;
  }

  async legacyCatSsO(id: any) {
    const response = await this.get(`getSsoToken?user_ID=${id}`);
    if (response && response.data) {
      if (response?.status==200 && response?.data.success === 1) {
        const data = response?.data?.data;
        const userData = this.setSsoTokenData(data);
        return userData;
      }else{
        console.log(response?.data)
        return null;
      } 
    }
    return null;
  }

  async validateSsOToken(token: any) {
    const response = await this.get(`validateSsoToken?user_Token=${token}`);
    if (response && response.data) {
      if (response?.status==200 && response?.data.success === 1) {
        const data = response?.data?.data;
        const userData = this.setUserData(data);
        return userData;
      }else{
        console.log(response?.data)
        return null;
      } 
    }
    return null;
  }

  async redirectSignOn(id: any) {
    const response = await this.get(`redirectWithSso?user_ID=${id}`);
    if (response && response.data) {
      if (response?.status==200 && response?.data.success === 1) {
        const data = response.data;
        const userData = this.setUserData(data);
        return userData;
      }else{
        const error = APIResponseError.NOT_FOUND;
        if (error) {
          return null;
        }
      } 
    }
    return null;
  }

  async getAppVersion() {
    const response = await this.get(`getVersion`);    
      if (response?.data.success === 1) {
        const data = response.data.data;
        const appData = this.setAppData(data)
        return appData;
      }else{
          return null;
        }
  }
  /**
   *
   * @param user
   * @returns
   */
   private setUserData(data: any) {
    const user: User = {
      firstName: data.FirstName ?? "",
      lastName: data.LastName ?? "",
      email: data.EmailAddress ?? "",
      userId: data.UserID ?? "",
      isActive: data.IsActive ?? [],
      isLibraryAdmin: data.IsLibraryAdmin,
      isLibraryBuyer: data.IsLibraryBuyer ?? "",
      isLibraryUser: data.IsLibraryUser ?? "",
      libraryData: data.Library ?? "",
      success:data.success ?? 0,
      libLogin:data.libLogin,
      isShelfAdmin: data.IsShelfAdmin ?? "",
      imageUrl: data.image_url ?? "",
      IsPatronCommunicator: data.IsPatronCommunicator ?? "",
      IsPatronManager: data.IsPatronManager ?? "",
      IsOnlyPatronMgr:data?.IsOnlyPatronMgr??""
    };
    return user;
  }
  private setAppData(data: any) {
    const app: any = {
      version: data?.DisplayVersion,
      EnvironmentInfo: data?.EnvironmentInfo,
    };
    return app;
  }

  private setSsoTokenData(data: any) {
    const app: any = {
      ClaimSet: data?.ClaimSet,
      CreateDateTime: data?.CreateDateTime,
      ExpirationDateTime: data?.ExpirationDateTime,
      Signature: data?.Signature,
      UserId: data?.UserId,
      UserTokenId: data?.UserTokenId,
      ValidationURL: data?.ValidationURL,
    };
    return app;
  }

}
