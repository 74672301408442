import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { message } from "antd";
import Cart from "../../models/cart/cart";
import Group from "../../models/group/group";

export class CartService extends BaseService {
    constructor() {
        super(getAPIBaseUrl(PortalModule.CARTS));
      }

      showError = (data: any, ) =>{
        if(data?.Message !== "" && data?.Message !== undefined){
          var authText = "Authorization has been denied for this request."
          message.error({
            content:(data?.Message?.includes("denied") || (data?.Message.toUpperCase() === authText.toUpperCase())?"Session Expired. Please log in again.":data?.Message),
            duration: 4,
          })
          if(data?.Message?.includes("denied") || (data?.Message.toUpperCase() === authText.toUpperCase())){
            return true
          }else{
            return false
          }
        }else if(data?.ErrorMessage !== "" && data?.ErrorMessage !== undefined){
          message.error({
            content:data?.ErrorMessage,
            duration: 4,
          })
         return false
        }else{
          return undefined
        }
        }

    async fetchCartsById (payload:any): Promise<Cart[]| any> {
        const response = await this.get(`cartItemList?lib_id=${payload.libId}`);
        if(response.data.success === 1){
            var userCarts = this.setCartDataForCartList(response.data.data,payload.appuser)
            return userCarts;
        }
        else{
          var isAuth = this.showError(response?.data?.data)
          if(isAuth){
           return {authDenied:true}
          }else{
           return {authDenied:false}
          }
        } 
      }
      async fetchBuyerCart (data: any): Promise<Cart[]| any> {
        // eslint-disable-next-line @typescript-eslint/no-redeclare
        var userId= data?.userId
        var recordStart= data?.recordStart !== undefined?data?.recordStart :1
        var recordCount= data?.recordCount !== undefined?data?.recordCount :1
        const response = await this.get(`fetchBuyerCart?user_id=${userId}&record_count=${recordCount}&record_start=${recordStart}`);
        if(response.data.success === 1){
            return response?.data;
        }
        else{
          this.showError(response?.data?.data)
          return null
        } 
      }

      async fetchBuyerCartById (data: any): Promise<Cart[]| any> {
        // eslint-disable-next-line @typescript-eslint/no-redeclare
        var libId= data?.libraryId
        var cartId= data?.cartId
        var quantity= data?.quantity
        const response = await this.get(`fetchBuyerCartById?lib_Id=${libId}&cart_id=${cartId}&quantity=${quantity}`);
        if(response.data.success === 1){
            return response?.data;
        }
        else{
          this.showError(response?.data?.data)
          return null
        } 
      }

      async addtoCart (payload: any): Promise<Cart[]| any> {
        const response = await this.post(`/addToCart`, payload);
        if(response.data.success === 1){
            return response.data.data;
        } else if (response.data.success === 0){
          this.showError(response?.data?.data)
            return false;
        }
        else return null
      }

      async movetoCart (payload: any): Promise<Cart[]| any> {
        const response = await this.post(`/moveToCart`, payload);
        if(response.data.success === 1){
            return true
        } else if (response.data.success === 0){
          this.showError(response?.data?.data)
            return response.data.data;
        }
        else{
          return null
        } 
      }

      async deleteCart (cartId: number): Promise<Cart[]| any> {
        const response = await this.delete(`deleteCart?cart_id=${cartId}`);
        if(response.data.success === 1){
            return true
        }
        else{
          this.showError(response?.data?.data)
          return null
        } 
      }
      async createCart (payload: any): Promise<Cart| any> {
        const response = await this.post(`/createCart`, payload);
        if(response.data.success === 1){
            return response.data.data;
        }
        else{
          this.showError(response?.data?.data)
          return null
        } 
      }
      async fetchCartById (params: any): Promise<Cart| any> {
        var userId= params.userId
        var cartId= params.cartId
        var count= params.count
        var startIndex= params.start
        var sortOrder = params.sortOrder
        var endpoint = `cartDetails?user_id=${userId}&cart_id=${cartId}&record_count=${count}&record_start=${startIndex}`
        if(params.sortBy && params.sortBy !== "Sort By"){
          endpoint = `${endpoint}&sortdirection=${sortOrder}&sortfield=${params.sortBy}`
      }
      if(params.libraryOwned){
        endpoint = `${endpoint}&filterLibraryOwned=${params.libraryOwned}`
      }
        const response = await this.get(endpoint);
        if(response.data.success === 1){
            var cartDetails = this.setCartData(response.data.data)
            var image_url = response?.data?.image_url
            return ({cartDetails: cartDetails, imageUrl : image_url});
        }
        else{
          var x = response?.data?.data
          if(x?.ErrorMessage === "Error while executing You do not have the proper security to process this request.,You do not have the proper security to process this request."){
            this.showError({...x,ErrorMessage:"You do not have the correct permission to process this request."})
          }else{
            this.showError(response?.data?.data)
          }
          return null
        } 
      }
      async purchaseCart (payload: any): Promise<Cart| any> {
        const response = await this.post(`buyCart`, payload);
        if(response.data.success === 1){
            const cart = this.setCartData(response.data.data)
            return cart
        }
        else{
          this.showError(response?.data?.data)
          return null
        } 
      }
      async fetchSelectedCartById (payload: any): Promise<Cart| any> {
        const response = await this.post(`selectedBuyCart`, payload);
        if(response.data.success === 1){
            const cart = this.setCartData(response.data.data)
            return cart
        }
        else{
          this.showError(response?.data?.data)
          return false
        } 
      }
      async confirmPurchase (payload: any): Promise<any> {  
        const response = await this.post(`generatePo`, payload);
        if(response.data.success === 1){
            return true;
        }
        else{
          this.showError(response?.data?.data)
          return false
        } 
      }

      async fetchAutoHistory (payload: any): Promise<Cart[]| any> {
        const response = await this.get(`getHistory?startDate=${payload?.startDate}&libId=${payload?.libId}&recordCount=${payload?.recordCount}&recordStart=${payload?.recordStart}&sortDirection=${payload?.sortDirection}&sortField=${payload?.sortField}&endDate=${payload?.endDate}`);
        if(response.data.success === 1){
            var userCarts = response.data.data
            return userCarts;
        }
        else{
          this.showError(response?.data?.data)
          return null
        } 
      }

      async saveAutoCart (payload: any): Promise<any> {
        const response = await this.put(`saveAutoBuy`, payload);
        if(response.data.success === 1){
          return true;
      }
      else{
        this.showError(response?.data?.data)
        return null
      } 
      }

      async downloadCartsDetails (payload:any): Promise<any> {
        const response = await this.get(`exportCartDetails?user_id=${payload?.userId}&cart_id=${payload?.CartId
        }&record_count=${payload?.NumberOfTitles}&record_start=1&filter_Format=${payload?.formatValue}`);
        if(response.data.success === 1){
            var userCarts = response.data
            return userCarts;
        }
        else{
          this.showError(response?.data?.data)
          return null
        } 
      }

      async updateCartItemQuantity (payload: any): Promise<any> {  
        const response = await this.post(`updateCartQuantity`, payload);
        if(response.data.success === 1){
            return true;
        }
        else{
          this.showError(response?.data?.data)
          return false
        } 
      }

      async addFeaturedListToCart (payload: any): Promise<Group[]| any> {
        const response = await this.post(`/addFeaturedListToCart`, payload);
        if(response.data.success === 1){
          const addFeaturedListToCart = response.data.data
          return addFeaturedListToCart;
        }
        else {
          this.showError(response?.data?.data)
          return false
        }
      }

      private setCartsData(data: any) {
        const carts: Cart[] = data.map((cart: any)=>{
            return {
                CartId: cart?.CartId,
                CartName: cart?.CartName,
                CartOwner: cart?.CartOwner,
                CartTypeCode: cart?.CartTypeCode,
                TotalCartAmount: cart?.TotalCartAmount,
                NumberOfTitles: cart?.NumberOfTitles,
                CartOwnerFirstName: cart?.CartOwnerFirstName,
                CartOwnerLastName: cart?.CartOwnerLastName,
                IsCommunityCart: cart?.IsCommunityCart,
                AssociateShelfId: cart?.AssociateShelfId,
                CartDescription: cart?.CartDescription,
                IsDefault:cart?.IsDefault
            }
        })
        return carts;
}
      private setCartData(data: any) {
        const carts: Cart = {
                CartId: data.CartId,
                CartName: data.CartName,
                CartOwner: data.CartOwner,
                CartTypeCode: data.CartTypeCode,
                TotalCartAmount: data.TotalCartAmount,
                TotalCartPrepubAmount: data.TotalCartPrepubAmount,
                NumberOfTitles: data.NumberOfTitles,
                CartOwnerFirstName: data.CartOwnerFirstName,
                CartOwnerLastName: data.CartOwnerLastName,
                IsCommunityCart: data.IsCommunityCart,
                AssociateShelfId: data.AssociateShelfId,
                CartDescription: data.CartDescription,
                CartDetails: data.CartDetail,
                NumberofCopies: data.NumberOfCopies,
                NumberOfPrepubCopies: data.NumberOfPrepubCopies,
                NumberOfPrepubTitles: data.NumberOfPrepubTitles,
            }
        return carts;
}

private setCartDataForCartList(data: any,appuser:any) {
  const newArr = data.map((data:any) => ({...data, cartUser: appuser}))
  const carts: Cart[] = newArr.map((cart: any)=>{
      return {
          CartId: cart?.CartId,
          CartName: cart?.CartName,
          CartOwner: cart?.CartOwner,
          CartTypeCode: cart?.CartTypeCode,
          TotalCartAmount: cart?.TotalCartAmount,
          NumberOfTitles: cart?.NumberOfTitles,
          CartOwnerFirstName: cart?.CartOwnerFirstName,
          CartOwnerLastName: cart?.CartOwnerLastName,
          IsCommunityCart: cart?.IsCommunityCart,
          AssociateShelfId: cart?.AssociateShelfId,
          CartDescription: cart?.CartDescription,
          IsDefault:cart?.IsDefault,
          ShelfName:cart?.ShelfName,
          NumberOfCopies: cart?.NumberOfCopies,
          NumberOfPrepubCopies: cart?.NumberOfPrepubCopies,
          NumberOfPrepubTitles: cart?.NumberOfPrepubTitles,
          TotalCartPrepubAmount: cart?.TotalCartPrepubAmount,
          UserId: cart?.UserId,
          cartOwnedby: (cart?.CartOwner ===  cart?.cartUser) ? "you" : cart?.CartOwnerFirstName +" " + cart?.CartOwnerLastName
      }
  })
  return carts;
}

async fetchAutoCarts (libraryId: number): Promise<Cart[]| any> {
  const response = await this.get(`autoBuyRules?lib_id=${libraryId}`);
  if(response.data.success === 1){
      var userCarts = response.data.data
      return userCarts;
  } else {
    this.showError(response.data.data)
    return  response.data.data
  }
  
}
}