import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserService } from "../../../web/services/user/userService";

const userSrv = new UserService();

export const fetchUsers = createAsyncThunk(
  "api/fetchUsers",
  async (_args: any, thunkAPI) => {
    try {
      const data = await userSrv.fetchUserList(_args);
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const fetchConfigData = createAsyncThunk(
  "api/fetchConfigData",
  async (_args: any, thunkAPI) => {
    try {
      const data = _args;
      if (data) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);