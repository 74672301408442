import React from "react";
import { SearchstateSelector } from "../../../../../redux/reducers/search/searchReducer";
import { useSelector } from "react-redux";

import Category from "../../../../models/search/category";
import { userStateSelector } from "../../../../../redux/reducers/user/userReducer";
import { CollectionSearchSideForm } from "./CollectionSearchSideForm";
import { infoIcon } from "../../../../images";

function CollectionSideBar() {
  const {
    audienceData,
    providerDataNew,
    publishersDataNew,
    mediaTypes,
    languages,
    categoriesOptions,
  } = useSelector(SearchstateSelector);
  const { appUser } = useSelector(userStateSelector);

  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;

  const getAudienceOptions = React.useMemo(() => {
    var audienceOptions = audienceData?.map((data: any) => {
      return { text: data.AgeClassificationId === 2 ? "Young Adult" : data.Name , value: data.AgeClassificationId };
    });
    return audienceOptions;
  },[audienceData]);
  const getProviderOptions =React.useMemo( () => {
    let providerOptions = [];
    let providers = providerDataNew?.ContentProviders
      ? providerDataNew?.ContentProviders
      : [];
    if (providers) {
      providerOptions = providers?.map((data: any) => {
        return {
          text: (
            <>
              {data.Name + " "}
              {data.rules ? (
                <span>
                  <img
                    className="cloud-link-info-icon"
                    src={infoIcon}
                    alt="info"
                  />
                  {' '+data.rules}
                </span>
              ) : null}
            </>
          ),
          value: data.ContentProviderId,
          title: (
            <>
              {data.Name + " "}
              {data.rules ? (
                <span>
                  <img
                    className="cloud-link-info-icon"
                    src={infoIcon}
                    alt="info"
                  />
                  {' '+data.rules}
                </span>
              ) : null}
            </>
          ),
          titleB: data.Name + " " + data.rules,
        };
      });
      return providerOptions;
    }
  },[]);
  const getPublisherOptions = React.useMemo(() => {
    let publisherOptions = [];
    let providers = publishersDataNew?.ContentProviders
      ? publishersDataNew?.ContentProviders
      : [];
    if (providers) {
      publisherOptions = providers?.map((data: any) => {
        return {
          text: (
            <>
              {data.PublisherName + " "}
              {data.rules ? (
                <span>
                  <img
                    className="cloud-link-info-icon"
                    src={infoIcon}
                    alt="info"
                  />
                  {' '+data.rules}
                </span>
              ) : null}
            </>
          ),
          value: data.PublisherId,
          title: (
            <>
              {data.PublisherName + " "}
              {data.rules ? (
                <span>
                  <img
                    className="cloud-link-info-icon"
                    src={infoIcon}
                    alt="info"
                  />
                  {' '+data.rules}
                </span>
              ) : null}
            </>
          ),
          titleB: data.PublisherName + " " + data.rules,
        };
      });
      return publisherOptions;
    }
  },[]);
  const getLanguageOptions = React.useMemo(() => {    
    var languageOptions = languages
    ?.filter((language: any) => !(language.LanguageName === "Not Specified" && language.LanguageCode !== "n/a"))
    ?.map((data: any) => {
      return { title: data.LanguageName, value: data.LanguageCode,titleB: data.LanguageName };
    });
    return languageOptions;
  },[languages]);
  const getCategoryOptions = React.useMemo(() => {
    var CategoryOptions = categoriesOptions?.map((data: Category) => {
      return { text: data.CategoryName, value: data.CategoryId };
    });
    return CategoryOptions;
  },[categoriesOptions]);

  return (
    <div>
      <CollectionSearchSideForm
        audienceDataOptions={getAudienceOptions}
        providerOptions={getProviderOptions}
        publisherOptions={getPublisherOptions}
        languageOptions={getLanguageOptions}
        categoryOptions={getCategoryOptions}
        formatOptions={mediaTypes}
        libraryId={lib_Id}
        isCollection={true}
        clearForm={false}
      />
    </div>
  );
}

export default React.memo(CollectionSideBar);
