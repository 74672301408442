import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import { showError } from "../../../utility/appUtil";
import Messages from "../../models/messages/messagesModel";

export class MessagesService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.COMMANFORALL));
  }

  async fetchMessages(params: any): Promise<any> {
    var libraryId = params.libraryId;
    var fromDate = params.fromDate;
    var recordCount = params.recordCount;
    var recordStart = params.recordStart;
    var sortDirection = params.sortDirection;
    var sortField = params.sortField;
    var toDate = params.toDate;
    const response = await this.get(
      `getMessages?fromDate=${fromDate}&libraryId=${libraryId}&recordCount=${recordCount}&recordStart=${recordStart}&sortDirection=${sortDirection}&sortField=${sortField}&toDate=${toDate}`
    );
    if (response.data.success === 1) {
      var msg = this.setMessagesData(response?.data?.data);
      return msg;
    } else {
      showError(response?.data?.data)
      return null
    }
  }

  async createMessage(payload: any): Promise<any> {
    const response = await this.post(`createMessages`, payload);
    if (response.data.success === 1) {
      return true;
    } else {
      showError(response?.data?.data)
      return null
    };
  }

  async deleteMessage(payload: any): Promise<any> {
    var LibraryId = payload?.LibraryId
    var sourceMessageIds = payload?.sourceMessageIds
    const response = await this.delete(
      `deleteMessages?LibraryId=${LibraryId}&sourceMessageIds=${sourceMessageIds}`
    );
    if (response.data.success === 1) {
      return true;
    } else {
      showError(response?.data?.data)
      return null
    };
  }

  private setMessagesData(data: any) {
    const messages: Messages[] = data?.Items?.map((messages: any) => {
      return {
        CreatedBy: messages.CreatedBy,
        CreatedOn: messages.CreatedOn,
        EndDate: messages.EndDate,
        LibraryId: messages.LibraryId,
        Message: messages.Message,
        SourceMessageId: messages.SourceMessageId,
        StartDate: messages.StartDate,
        Subject: messages.Subject,
      };
    });
    return { TotalCount: data?.TotalCount, Items: messages };
  }

  private setMessage(data: any) {
    const messages: Messages[] = data?.Items?.map((messages: any) => {
      return {
        CreatedBy: messages.CreatedBy,
        CreatedOn: messages.CreatedOn,
        EndDate: messages.EndDate,
        LibraryId: messages.LibraryId,
        Message: messages.Message,
        SourceMessageId: messages.SourceMessageId,
        StartDate: messages.StartDate,
        Subject: messages.Subject,
      };
    });
    return messages;
  }
}
