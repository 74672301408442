import { createAsyncThunk } from "@reduxjs/toolkit";
import { CategoryService } from "../../../web/services/categories/categoryService";

const categorySrv = new CategoryService();

export const fetchCategories = createAsyncThunk(
    "api/fetchCategories",
    async (_args: any, thunkAPI) => {
      try {
        const data = await categorySrv.fetchCategoriesById(_args)
        if (data && data.Items.length) {
          return data.Items;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );