import { createAsyncThunk } from "@reduxjs/toolkit";
import { MessagesService } from "../../../web/services/messages/messagesService";

const MsgSrv = new MessagesService();



export const fetchMessages = createAsyncThunk(
  "api/fetchMessages",
  async (_args: any, thunkAPI) => {
    try {
      const data = await MsgSrv.fetchMessages(_args)
      if (data) { 
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const createMessages = createAsyncThunk(
  "api/createMessages",
  async (_args: any, thunkAPI) => {
    try {
      const data = await MsgSrv.createMessage(_args?.createPayload)
      if (data) { 
        return thunkAPI.dispatch(fetchMessages(_args?.fetchPayload));
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const deleteMessages = createAsyncThunk(
  "api/deleteMessages",
  async (_args: any, thunkAPI) => {
    try {
      const data = await MsgSrv.deleteMessage(_args?.deletePayload)
      if (data) { 
        return thunkAPI.dispatch(fetchMessages(_args?.fetchPayload));
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);

export const recentTab = createAsyncThunk(
  "api/recentTab",
  async (_args : any, thunkAPI) => {
    try {
      const data = _args
      if (data) { 
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e.data);
    }
  }
);