import { createAsyncThunk } from "@reduxjs/toolkit";
import { showError } from "../../../utility/appUtil";
import { SearchService } from "../../../web/services/search/searchService";

const ROOT = 'api'

export const FETCH_API_REQUEST = `${ROOT}/FETCH_API_REQUEST`;
export const FETCH_API_SUCCESS = `${ROOT}/FETCH_API_SUCCESS`;
export const FETCH_API_FAILURE = `${ROOT}/FETCH_API_FAILURE`;
export const FETCH_API_CANCEL = `${ROOT}/FETCH_API_CANCEL`;

export const fetchApiRequest = () => ({
  type: FETCH_API_REQUEST
});

export const fetchApiCancel = () => ({
  type: FETCH_API_CANCEL
});

export const fetchApiSuccess = (data:any) => ({
  type: FETCH_API_SUCCESS,
  data
});

export const fetchApiError = (message:any) => ({
  type: FETCH_API_FAILURE,
  message
});

const searchSrv = new SearchService();

export const fetchAudienceData = createAsyncThunk(
    "api/audienceData",
    async (_args, thunkAPI) => {
      try {
        const data = await searchSrv.fetchAudienceData()
        if (data && data.length) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchContentProviders = createAsyncThunk(
    "api/contentProviders",
    async (_args, thunkAPI) => {
      try {
        const data = await searchSrv.fetchContentProviders()
        if (data && data.length) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchContentProvidersNew = createAsyncThunk(
    "api/contentProvidersNew",
    async (_args, thunkAPI) => {
      try {
        const data = await searchSrv.fetchContentProvidersNew()
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchPublisherWithRules = createAsyncThunk(
    "api/fetchPublisherWithRules",
    async (_args, thunkAPI) => {
      try {
        const data = await searchSrv.fetchPublisherWithRules()
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchPublishers = createAsyncThunk(
    "api/publishers",
    async (_args, thunkAPI) => {
      try {
        const data = await searchSrv.fetchPublishers()
        if (data && data.length) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchMediaTypes = createAsyncThunk(
    "api/mediaTypes",
    async (_args, thunkAPI) => {
      try {
        const data = await searchSrv.fetchMediaTypes()
        if (data && data.length) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchMediaTypesError = createAsyncThunk(
    "api/mediaTypesError",
    async (_args, thunkAPI) => {
      try {
        const data = await searchSrv.fetchMediaTypesError()
        if (data.error === undefined) {
          return data;
        } else if (data.error) {
          return data
        }
        else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchLanguages = createAsyncThunk(
    "api/fetchLanguages",
    async (_args, thunkAPI) => {
      try {
        const data = await searchSrv.fetchLanguages()
        if (data && data.length) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchCategoryOptions = createAsyncThunk(
    "api/fetchCategoryOptions",
    async (_args, thunkAPI) => {
      try {
        const data = await searchSrv.fetchCategories()
        if (data && data.length) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const saveSearch = createAsyncThunk(
    "api/saveSearch",
    async (_args : any, thunkAPI) => {
      try {
        const data = await searchSrv.sendSearchData(_args)
        if (data) { 
          setTimeout(()=>{
             thunkAPI.dispatch(fetchSearchList())
          },100)
          return data;
        } else {
          showError(data)
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchSearchResults = createAsyncThunk(
    "api/fetchSearchResults",
    async (_args : any, thunkAPI) => {
      try {
        const data = await searchSrv.fetchSearchResults(_args)
        if (data.success ) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

  export const fetchSearchResultsExport = createAsyncThunk(
    "api/fetchSearchResultsExport",
    async (_args : any, thunkAPI) => {
      try {
        const data = await searchSrv.fetchSearchResultsExport(_args);
        if (data ) { 
          return true;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

  // Action for ISBN export -> works for both myCollection and Search
  export const fetchIsbnSearchExport = createAsyncThunk(
    "api/fetchIsbnSearchExport",
    async (_args:any, thunkAPI) => {
      var exportCsv = _args?.functionForExport;
      var payload = {
        CatalogId: _args?.payload.catalogID,
        BulkISBNSearchItems : _args?.payload.list,
        SearchType :_args?.payload?.SearchType
      }
      try {
        const data = await searchSrv.searchIsbnExport({payload:payload,token:_args?.signal?.token, functionForExport: _args.functionForExport})
        if (data && data?.ErrorMessage === undefined ) {
          const bookList = await searchSrv.fetchIsbnSearchExport({
            id: data,
            libId: _args?.payload.libId,
            sortField: _args?.payload.sortField,
            sortDirection: _args?.payload.sortDirection,
            recordCount:1000,
            recordStart: _args?.payload.recordStart,
            token:_args?.signal?.token,
            dateModify : _args?.dateModify,
            functionForExport : _args?.functionForExport,
          });
          if(bookList){
            return true;
          }
          else {
            return thunkAPI.rejectWithValue(data);
          }
        } else {
          thunkAPI.dispatch(fetchApiCancel());
          return exportCsv({error: "Error Occured"});
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

  export const fetchSearchList = createAsyncThunk(
    "api/savedsearchList",
    async (_args, thunkAPI) => {
      try {
        const data = await searchSrv.fetchSearchList()
        if (data && data.length ) { 
          return data;
        } else {
          showError(data)
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchIsbnSearch = createAsyncThunk(
    "api/fetchIsbnSearch",
    async (_args: any, thunkAPI) => {
      var payload = {
        CatalogId: _args.catalogID,
        BulkISBNSearchItems : _args.list
      }
      try {
        const data = await searchSrv.searchEisbn(payload)
        if (data) { 
          const bookList = await searchSrv.fetchSearchbyEisbn({
            id: data,
            libId: _args.libId,
            sortField: _args.sortField,
            sortDirection: _args.sortDirection,
            recordCount: _args.recordCount,
            recordStart: _args.recordStart
          });
          return bookList
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

  //cancel serach results
  export const fetchSearchResultsNew = createAsyncThunk(
    "api/fetchSearchResultsNew",
    async (_args : any, thunkAPI) => {
      thunkAPI.dispatch(fetchApiRequest());
      try {
        const data = await searchSrv.fetchSearchResultsNew({payload:_args?.payload,token:_args?.signal?.token})
        if (data.success ) { 
          return data;
        } else {
          showError(data)
          thunkAPI.dispatch(fetchApiSuccess(data));
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

//fetch ibnsearch with cancel   
  export const fetchIsbnSearcNew = createAsyncThunk(
    "api/fetchIsbnSearcNew",
    async (_args:any, thunkAPI) => {
      var payload = {
        CatalogId: _args?.payload.catalogID,
        BulkISBNSearchItems : _args?.payload.list,
        SearchType :_args?.payload?.SearchType
      }
      thunkAPI.dispatch(fetchApiRequest());
      try {
        const data = await searchSrv.searchEisbnNew({payload:payload,token:_args?.signal?.token})
        if (data && data?.ErrorMessage === undefined ) {
          const bookList = await searchSrv.fetchSearchbyEisbnNew({
            id: data,
            libId: _args?.payload.libId,
            sortField: _args?.payload.sortField,
            sortDirection: _args?.payload.sortDirection,
            recordCount: _args?.payload.recordCount,
            recordStart: _args?.payload.recordStart,
            token:_args?.signal?.token
          });
          thunkAPI.dispatch(fetchApiSuccess(bookList));
          if(bookList && bookList?.total) {
            if(bookList?.RecordsNotMatched && bookList?.RecordsNotMatched > 0){
              const bookListWithUnmatchData = await searchSrv.fetchSearchbyEisbnTotalUnmatch({
                id: data,
                libId: _args?.payload.libId,
                sortField: _args?.payload.sortField,
                sortDirection: _args?.payload.sortDirection,
                recordCount: bookList?.RecordsNotMatched,
                recordStart: _args?.payload.recordStart,
                token:_args?.signal?.token
              });
              return {...bookList,totalUnmatchData:bookListWithUnmatchData}
            }else{
              return {...bookList,totalUnmatchData:[]}
            }
          } else {
            showError(bookList)
            return bookList
          }
        } else {
          showError(data);
          thunkAPI.dispatch(fetchApiCancel());
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

    //my collection serach results
    export const fetchColecSearchResults = createAsyncThunk(
      "api/fetchColecSearchResults",
      async (_args : any, thunkAPI) => {
        thunkAPI.dispatch(fetchApiRequest());
        try {
          const data = await searchSrv.fetchCollecSearchResults({payload:_args?.payload,token:_args?.signal?.token})
          if (data.success ) { 
            return data;
          } else {
            showError(data)
            thunkAPI.dispatch(fetchApiSuccess(data));
            return thunkAPI.rejectWithValue(data);
          }
        } catch (e: any) {
          return thunkAPI.rejectWithValue(e.data);
        }
      }
    )


    export const fetchColecSearchResultsExports = createAsyncThunk(
      "api/fetchColecSearchResultsExports",
      async (_args : any, thunkAPI) => {
        try {
          const data = await searchSrv.fetchCollectionResultsExport(_args)
          if (data ) { 
            return data;
          } else {
            showError(data)
            return thunkAPI.rejectWithValue(data);
          }
        } catch (e: any) {
          return thunkAPI.rejectWithValue(e.data);
        }
      }
    )

  export const deleteSearchById = createAsyncThunk(
    "api/deleteSearchById",
    async (_args: number, thunkAPI) => {
      try {
        const data = await searchSrv.deleteSearch(_args)
        if (data) { 
          setTimeout(()=>{
            thunkAPI.dispatch(fetchSearchList())
         },100)
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchQuickSearch = createAsyncThunk(
    "api/quickSearch",
    async (_args : any, thunkAPI) => {
      try {
        const data = await searchSrv.fetchSearchResults(_args)
        if (data.success ) { 
          return data.data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

    export const fetchQuickSearchNew = createAsyncThunk(
    "api/fetchQuickSearchNew",
    async (_args : any, thunkAPI) => {
      try {
        const data = await searchSrv.fetchQuickSearchResultsNew({payload:_args?.payload,token:_args?.signal?.token})
        if (data.success ) { 
          return data.data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const fetchQuickSearchIsbnNew = createAsyncThunk(
    "api/fetchQuickSearchIsbnNew",
    async (_args:any, thunkAPI) => {
      var payload = {
        CatalogId: _args?.payload.catalogID,
        BulkISBNSearchItems : _args?.payload.list,
        SearchType :_args?.payload?.SearchType
      }
      thunkAPI.dispatch(fetchApiRequest());
      try {
        const data = await searchSrv.searchEisbnNew({payload:payload,token:_args?.signal?.token})
        if (data && data?.ErrorMessage === undefined ) {
          const bookList = await searchSrv.fetchSearchbyEisbnNew({
            id: data,
            libId: _args?.payload.libId,
            sortField: _args?.payload.sortField,
            sortDirection: _args?.payload.sortDirection,
            recordCount: _args?.payload.recordCount,
            recordStart: _args?.payload.recordStart,
            token:_args?.signal?.token
          });
          thunkAPI.dispatch(fetchApiSuccess(bookList));
          if(bookList && bookList?.total) {
            return bookList
          } else {
            showError(bookList)
            return bookList
          }
        } else {
          showError(data);
          thunkAPI.dispatch(fetchApiCancel());
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const getSearchString = createAsyncThunk(
    "api/getSearchString",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const getSaveSearchResult = createAsyncThunk(
    "api/getSaveSearchResult",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const onBackFillForm = createAsyncThunk(
    "api/onBackFillForm",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const onStringBackFillForm = createAsyncThunk(
    "api/onStringBackFillForm",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const searchFilters = createAsyncThunk(
    "api/searchFilters",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const savedSearchFilters = createAsyncThunk(
    "api/savedSearchFilters",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

  export const cleraFitureList = createAsyncThunk(
    "api/cleraFitureList",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

  export const retainDefailtValue = createAsyncThunk(
    "api/retainDefailtValue",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )
  export const saveTargetAudience = createAsyncThunk(
    "api/saveTargetAudience",
      async (pData: any, thunkAPI) => {
        var fetchD = {
          payload: pData?.payload,
          signal: pData?.signal,
        }
        try {
          const data = await searchSrv.targetAudience(pData?.payloadTarget)
          if (data) {
            if(pData?.isbnSearch !== undefined){
              thunkAPI.dispatch(fetchIsbnSearcNew({payload:pData?.isbnSearch,signal:pData?.signal}));
            }else{
              thunkAPI.dispatch(fetchColecSearchResults(fetchD));
            }
            thunkAPI.dispatch(cleraFitureList([]));
            return data
          } else {
            return thunkAPI.rejectWithValue(data);
          }
        } catch (e: any) {
          return thunkAPI.rejectWithValue(e.data);
        }
      }
  );

  export const deleteTargetAudience = createAsyncThunk(
    "api/deleteTargetAudience",
      async (pData: any, thunkAPI) => {
        var fetchD = {
          payload: pData?.payload,
          signal: pData?.signal,
        }
        try {
          const data = await searchSrv.targetDeleteAudience(pData?.payloadTarget)
          if (data) {
            if(pData?.isbnSearch !== undefined){
              thunkAPI.dispatch(fetchIsbnSearcNew({payload:pData?.isbnSearch,signal:pData?.signal}));
            }else{
              thunkAPI.dispatch(fetchColecSearchResults(fetchD));
            }
            thunkAPI.dispatch(cleraFitureList([]));
            return data
          } else {
            return thunkAPI.rejectWithValue(data);
          }
        } catch (e: any) {
          return thunkAPI.rejectWithValue(e.data);
        }
      }
  );

  export const getUnmatchValues = createAsyncThunk(
    "api/getUnmatchValues",
    async (_args : any, thunkAPI) => {
      try {
        const data = _args
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )

  export const fetchppuBooksStatus = createAsyncThunk(
    "api/fetchppuBooksStatus",
    async (_args : any, thunkAPI) => {
      try {
        const data = await searchSrv.fetchppuBooksStatus()
        if (data) { 
          return data;
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  )