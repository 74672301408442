import { APIEnvironment } from "./apiEnvironment";

export const apiEnv: APIEnvironment = APIEnvironment.DEV;

//get env from local storage
let envInLs =()=>{
  if(localStorage.getItem("environment") !== null){
     return localStorage.getItem("environment")
  }else{
    return undefined
  }
}


//fetch baee url from config.json
export const getAPIBaseUrl =  (module: any) => {
  const runningEnv = envInLs()
  if (runningEnv) {
    return runningEnv
  }else{
    return "";
  }
};