import React, { useState } from 'react'
import {Form, message} from 'antd'
import './emailLoginForm.less'

import AuthLayout from '../../stateless/layouts/authLayout'
import Button from '../../stateless/common/button'
import CustomPassword from '../../stateless/common/inputField/customPassword'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from '../../../../redux/actions/user/authAction'
import { useEffect } from 'react'
import SpinnerLoader from '../common/spinnerLoader'
import { clearErrorMsg, clearResetFormstate, clearState, userStateSelector } from '../../../../redux/reducers/user/userReducer'
import { Link, useHistory } from 'react-router-dom'
import { AppRoutes } from '../../../router/appRoutes'


export const ResetPassword = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { resetPwdError,resetPwd,resetPwdformState } = useSelector(userStateSelector);
  const [locationParam, setLocationParam] = useState<any>([]);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  }

  useEffect(()=>{
    dispatch(clearResetFormstate());
    return(()=>{
      dispatch(clearErrorMsg())
      dispatch(clearState())
    })

      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    const params = new URLSearchParams(window.location.search)
    let token = params.get('token')
    let user = params.get('user')
    let nature = params.get('nature')
    if(token === undefined || JSON.stringify(token) === 'null' || ''){
        history.push(AppRoutes.LOGIN)
    }
    setLocationParam(
      {
        token:token !== undefined || null?token:'',
        user:user!== undefined || null?user:'',
        nature:nature!== undefined || null?nature:''
      }
    )

      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onSubmit = (data: any) => {
    if (data['confirm password'] !== data['new password']) {
      return;
    }
    let authUser: any = {
      ConfirmNewPassword:data['confirm password'],
      NewPassword:data['new password'],
      Token:locationParam?.token
    };
    dispatch(resetPassword(authUser));
  }

  const onChangeHandle = (e:any) => {
    return e.target.value
  }

  useEffect(() => {
    if (resetPwdformState?.isError) {
      if (resetPwdError !== "") {
        message.error({
          content: resetPwdError,
          key: "error",
        });
        dispatch(clearErrorMsg());
      }
    }

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPwdError, resetPwdformState]);

  useEffect(() => {
    if (resetPwd === true) {
      message.success({
        content: "Your Password has been changed successfully",
        key: "notificationMessage",
      });
      history.push(AppRoutes.LOGIN);
    }

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPwd]);

  return (
    <AuthLayout>
      <SpinnerLoader loading={resetPwdformState.loading}>
      <div className='loginBox'>
        <span className='loginHed'>Reset Password</span>
        <div className='loginText'>Please enter a new password.</div>
        <Form
          {...layout}
          name='auth'
          className='login-form'             
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
          requiredMark={false}         
        >
          <CustomPassword
            customLabelClass={`loginLabelName`}
            labelSubName='New Password'
            labelName='new password'
            name='newPassword'         
            onChange={onChangeHandle}
            rules={[ 
              {
                required: true,
                message: 'Minimum 5 characters required for New password'
              },          
              {
                min: 5, 
                message: 'Minimum 5 characters required for New password'
              }
            ]}
          />
          <CustomPassword
            customLabelClass={`loginLabelName`}
            labelSubName='Confirm Password'
            labelName='confirm password'
            name='confirmPassword'          
            onChange={onChangeHandle}
            rules={[ 
              {
                required: true,
                message: 'Password confirmation does not match!'
              },          
              ( {getFieldValue}:{getFieldValue:any}) => ({
                validator(_:any, value:any) {
                  if (!value || getFieldValue('new password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password confirmation does not match!'));
                },
              }),
            ]}
          />
          <Form.Item className='formItemInput mb-0'>
            <Button type='primary loginButton' htmlType='submit'>
              Reset Password
            </Button>
            <div>
            <Link to={AppRoutes.LOGIN} className="forgetPassword">Login Page</Link>
            </div>  
          </Form.Item>
        </Form>
      </div>
      </SpinnerLoader>
    </AuthLayout>
  )
}
