import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportsService } from "../../../web/services/reports/reportsService";

const reportService = new ReportsService();
const reportBaseUrl = localStorage.getItem("reportsUrl")

export const fetchReportToken = createAsyncThunk(
    "api/fetchReportToken",
    async (_args : any, thunkAPI) => {
      try {
        const data = await reportService.fetchRepotsToken(_args)
        if (data?.UserTokenId !== undefined && data?.UserTokenId !== "") {
          var urlReport = `${reportBaseUrl}/Account/LogOn?SSOToken=${data?.UserTokenId}`
          return {data:data,url:urlReport}
        } else {
          return thunkAPI.rejectWithValue(data);
        }
      } catch (e: any) {
        return thunkAPI.rejectWithValue(e.data);
      }
    }
  );