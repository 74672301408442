import { useEffect, useState } from "react";
import { Modal, Row, Col } from "antd";
import { AutoShelves } from "./autoShelves";
import { ManualShelves } from "./manualShelves";
import { SearchstateSelector } from "../../../../redux/reducers/search/searchReducer";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchAudienceData,
    fetchCategoryOptions,
    fetchContentProviders,
    fetchLanguages,
    fetchMediaTypes,
    fetchPublishers,
  } from "../../../../redux/actions/search/searchActions";

export const ShelvesModal = (props: any) => {
    const dispatch = useDispatch();
    const { isModalVisible, setModalVisible } = props;

    const [ isAutoModalVisible, setAutoModalVisible ] = useState(false);
    const openAutoShelf = (checked: any)=>{
        setAutoModalVisible(true)
    };

    const [ isManualModalVisible, setManualModalVisible ] = useState(false);
    const openManualShelf = (checked: any)=>{
        setManualModalVisible(true)
    };
 const {
    audienceData,
    providerData,
    publishers,
    mediaTypes,
    languages,
    categoriesOptions,
  } = useSelector(SearchstateSelector);
     
  useEffect(() => {
    if(isAutoModalVisible){
        if (!(audienceData && audienceData.length)) {
        dispatch(fetchAudienceData());
        }
        if (!(providerData && providerData.length)) {
        dispatch(fetchContentProviders());
        }
        if (!(publishers && publishers.length)) {
        dispatch(fetchPublishers());
        }
        if (!(mediaTypes && mediaTypes.length)) {
        dispatch(fetchMediaTypes());
        }
        if (!(languages && languages.length)) {
        dispatch(fetchLanguages());
        }
        if (!(categoriesOptions && categoriesOptions.length)) {
        dispatch(fetchCategoryOptions());
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoModalVisible]);
  
    return (
        <>
        <Modal
        visible={isModalVisible}
        centered
        wrapClassName="shelvesModal"
        onCancel={() => setModalVisible(false)}
        >
            <div className="shelvesTitle">Create Shelf</div>
            
            <div className="autoSelfCard mb-6" onClick={() => {openAutoShelf(true); setModalVisible(false)}}>
                <Row>
                    <Col span={24}>
                        <div className="cardTitel">Automated Shelf</div>
                    </Col>
                    <Col span={24}>
                        <div className="cardDetail">Save time! Choose your settings and shelves will be automatically updated.</div>
                    </Col>
                </Row>
            </div>

            <div className="autoSelfCard mt-6" onClick={() => {openManualShelf(true); setModalVisible(false)}}>
                <Row>
                    <Col span={24}>
                        <div className="cardTitel">Manual Shelf</div>
                    </Col>
                    <Col span={24}>
                        <div className="cardDetail">Make it your own! Customize your shelf by manually adding, removing and organizing titles.</div>
                    </Col>
                </Row>
            </div>
        </Modal>
        <AutoShelves isAutoModalVisible={isAutoModalVisible} setAutoModalVisible={setAutoModalVisible} />
        <ManualShelves isManualModalVisible={isManualModalVisible} setManualModalVisible={setManualModalVisible} />
        </>

    );
};
