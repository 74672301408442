/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  Form,
  Input,
  InputNumber,
  Empty,
} from "antd";
import "antd/dist/antd.css";
import { Resizable, } from "react-resizable";
import SpinnerLoader from "../../stateless/common/spinnerLoader";
import "./purchase.less";

const EditableContext = React.createContext({});

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = (props: any) => {
  const {
    title,
    editable,
    children,
    dataIndex,
    inputType,
    record,
    handleSave,
    handleQanSave,
    ...restProps
  } = props;
  const [editing, setEditing] = useState<boolean>(false);
  const inputRef = useRef<any>(null);
  const inputQuanRef = useRef<any>(null);
  const form = useContext<any>(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const saveQuantity = async (value: any) => {
    try {
      const values = value;
      inputQuanRef.current.focus();
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
      handleQanSave({ ...record, Quantity: values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  let isInput = inputType === "number";
  if (editable) {
    if (isInput) {
      childNode = (
        <InputNumber
          defaultValue={children[1]}
          ref={inputQuanRef}
          min={1}
          max={999}
          onChange={saveQuantity}
          disabled={record?.ItemState !== "NOT_FOR_SALE"?false:true}
        />
      );
    } else {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : record?.ItemState !== "NOT_FOR_SALE" ? (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onDoubleClick={toggleEdit}
        >
          {children[1]}
        </div>
      ) : (
        <div
          className="not-editable-cell-value-wrap"
          style={{
            paddingRight: 24,
            cursor: "not-allowed"
          }}
        >
          {children[1]}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

const ResizeableTitle = (props: any) => {
  const { onResize, onResizeStop, onResizeStart, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th key="a" {...restProps} />
    </Resizable>
  );
};

export const PurchaseTable = (props: any) => {
  const {
    data,
    columns,
    formState,
    setColumnResize,
    resizeCol,
    editAble,
    classN,
    needRowData,
    getQuantity,
    getpoRef,
    getSelectedRows,
    resetData,
    showPagination,
    setclickedRowData,
    rowKey
  } = props;
  const [screenSize, setScreenSize] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialColumns, setInitialColumns] = useState<any>([]);
  const [loading, setLoading] = useState<any>(formState);
  const [tColumns, setTColumns] = useState<any>(columns);
  const [dataSource, setDataSource] = useState<any>(data);

  useEffect(() => {
    setTColumns(columns);
  }, [columns]);

  useEffect(()=>{
    setLoading(formState)
  },[formState])

  useEffect(() => {
    if (resizeCol?.length > 0) {
      setTColumns(resizeCol);
    }
  }, [resizeCol]);

  const handleResize = (index: any) => (e: any, size: any) => {
    const nextColumns = [...tColumns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size?.size?.width,
    };
    setTColumns(nextColumns);
    setColumnResize(nextColumns);
    
  };

  const tColumn = tColumns?.map((col: any, index: number) => ({
    ...col,
    onHeaderCell: (tColumn: any) => ({
      width: tColumn?.width,
      onResize: handleResize(index),
    }),
  }));

  const customColEdit =  tColumns?.map((col: any, index: number) => ({
    ...col,
    onHeaderCell: (tColumn: any) => ({
      width: tColumn?.width,
      onResize: handleResize(index),
    }),
    onCell: (record: any) => ({
      record,
      inputType: col.dataIndex === "Quantity" ? "number" : "text",
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      handleSave,
      handleQanSave,
    }),
  }));

  useEffect(() => {
    setInitialColumns(columns);
    let windowWidth = window.innerWidth;
    if (windowWidth < 1300) {
      setScreenSize(true);
    } else {
      setScreenSize(false);
    }
  }, []);

  const handleSave = (row: any) => {
    getpoRef(row);
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };
  const handleQanSave = (row: any) => {
    getQuantity(row);
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  useEffect(() => {
    setDataSource([])
    setTimeout(()=>{
      setLoading({...formState,loading:false})
      setDataSource(data)
    },50)
  }, [data]);

  const components = {
    header: {
      cell: ResizeableTitle,
    },
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  useEffect(()=>{
    if(resetData){
      setLoading({...formState,loading:true})
      setDataSource([])
      setTimeout(()=>{
        setLoading({...formState,loading:false})
        setDataSource(data)
      },50)
    }
  },[resetData])

  useEffect(() => {
    if (editAble) {
      setRowSelection({
        onChange: (selectedRowKeys: any, selectedRows: any) => {
          getSelectedRows({
            selectedRows: selectedRows,
            selectedRowsKey: selectedRowKeys,
          });
        },
        getCheckboxProps: (record: any) => ({
          disabled: record.name === "Disabled User",
          // Column configuration not to be checked
          name: record.name,
        }),
      });
    } else {
      setRowSelection(undefined);
    }
  }, [editAble]);

  return (
    <SpinnerLoader className="purchaseTableLoader" loading={formState?.loading || loading?.loading}>
      <div>
        <Table
          rowSelection={rowSelection}
          locale={{emptyText:<Empty description="No results found."/>}}
          className={classN}
          rowClassName={() => "editable-row"}
          columns={editAble ? customColEdit : tColumn}
          size="small"
          pagination={false}
          dataSource={editAble ? dataSource : data}       
          components={components}
          //@ts-ignore
          scroll={showPagination?{x: screenSize }:{ y: "calc(80vh - 280px)", x: screenSize }}
          showSorterTooltip={false}
          rowKey={rowKey}
          onRow={(r:any) => ({
            onClick: () => (needRowData?setclickedRowData(r):undefined)
          })}
        />
      </div>
    </SpinnerLoader>
  );
};
