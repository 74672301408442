import { Col, DatePicker, Row } from "antd";
import React, { useState } from "react";
import DropDown from "../../stateless/common/dropDown";
import { filterOptions } from "../../../constants/constants";
import { downIcon } from "../../../images";
import moment from "moment";
import Button from "../../stateless/common/button";

const MessageSearchBar = ({
  dateFormat,
  handleSearch,
  lib_Id,
  setShowDrawer,
}: any) => {
  const { RangePicker } = DatePicker;

  const [showDatePicker, setShowDatePicker] = useState(false);

  const [payload, setPayload] = useState({
    libraryId: lib_Id,
    fromDate: moment().subtract(7, "days").format(dateFormat),
    recordCount: "500",
    recordStart: "1",
    sortDirection: "ASC",
    sortField: "CreatedOn",
    toDate: moment().format(dateFormat),
  });

  const handleRangeSelect = (date: any, dateString: any) => {
    let [fromDate, toDate] = dateString.toString().split(",");
    setPayload({
      ...payload,
      fromDate: fromDate,
      toDate: toDate,
    });
  };

  const handleDaysSelect = (value: any) => {
    if (value === -1) {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
      let fromDate = moment().subtract(value, "days").format(dateFormat);
      setPayload({
        ...payload,
        fromDate: fromDate,
        toDate: moment().format(dateFormat),
      });
    }
  };

  const onSearch = () => {
    handleSearch(payload);
  };

  return (
    <Row>
      <Col xs={12} sm={14} md={19} lg={19} xl={19}>
        <Row>
        <Col>
            <span className="messageSearchlable"> Search messages created</span>
          </Col>
          <Col className="pr center-align">
            <DropDown
              className="message-dropdown"
              placeholder="Date Added to cloudLibrary"
              bordered={false}
              optionValue={filterOptions.messagesDateRange}
              icon={downIcon}
              onChange={handleDaysSelect}
              defaultValue={filterOptions.messagesDateRange[0].text}
            />
          </Col>

          {showDatePicker && (
            <Col className="datepicker-container pr center-align">
              <RangePicker
                format={"MM/DD/YYYY"}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                onChange={handleRangeSelect}
                className="message-datepicker"
                separator={<img src="" alt="" />}
              />
            </Col>
          )}
          <Col>
            <Button onClick={onSearch} type="serPurBtn primary msg-search-btn">
              Search
            </Button>
          </Col>
        </Row>
      </Col>
      <Col  xs={4} sm={4} md={5} lg={5} xl={5}>
        <Button
          onClick={() => setShowDrawer(true)}
          type="serPurBtn primary msg-search-btn ml-5"
        >
          New Message
        </Button>
      </Col>
    </Row>
  );
};

export default MessageSearchBar;
