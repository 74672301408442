/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Row, Col, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./insight.less";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { Dashboard } from "./dashboard";
import {
  getCirculationSummary,
  setTab,
} from "../../../../redux/actions/insight/insightActions";
import {
  clearInsightState,
  insightStateSelector,
} from "../../../../redux/reducers/insight/insightsReducer";
import SpinnerLoader from "../../stateless/common/spinnerLoader";
import { fetchReportToken } from "../../../../redux/actions/reports/reportsActions";
import { MarcRecords } from "./marcRecords";

export const LibraryReports = (props: any) => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { appUser } = useSelector(userStateSelector);
  const { formState, tabVal } = useSelector(insightStateSelector);

  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  var lib_Name =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryName : 0;

  const handelReports = () => {
    dispatch(fetchReportToken({ email_Id: appUser?.email }));
  };

  const handleSelect = (e: any) => {
    if (e === "2") {
      dispatch(setTab("1"));
      handelReports();
    } else {
      dispatch(setTab(e));
    }
  };

  useEffect(() => {
    dispatch(getCirculationSummary(lib_Id));
  }, [lib_Id]);

  useEffect(() => {
    return () => {
      dispatch(clearInsightState());
    };
  }, []);

  return (
    <div className="insightsScreen">
      <Row className="head-container">
        <Col xs={12} sm={14} md={18} lg={20} xl={20}>
          <p className="page-title">Library Reports</p>
        </Col>
      </Row>
      <Tabs className="cart-tabs" activeKey={tabVal} onChange={handleSelect}>
        <TabPane tab="Dashboard" key="1">
          <SpinnerLoader loading={formState.loading}>
            <Dashboard />
          </SpinnerLoader>
        </TabPane>
        <TabPane tab="Reports" key="2">
          <></>
        </TabPane>
        <TabPane tab="MARC Records" key="3">
          <MarcRecords libraryId={lib_Id} libraryName={lib_Name}/>
        </TabPane>
      </Tabs>
    </div>
  );
};
