import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import { fetchReportToken } from "../../actions/reports/reportsActions";
import { ReportsState } from "../../states/reports/reportsState";
import { RootState } from "../../store/rootReducer";

const initialState: ReportsState = {
  reportData: [],
  reportUrl: "",
  reportError: [],
  formState:resetState(),
  reportFormState:resetState(),
};

export const ReportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    clearReportState: (state) => {
      state.reportData = []
      state.reportError = []
      state.reportUrl = ""
      state.formState = resetState()
      state.reportFormState = resetState()
      return state;
    },
    clearReportToken: (state) => {
      state.reportData = []
      state.reportError = []
      state.reportUrl = ""
      state.reportFormState = resetState()
      return state;
    },
    clearReportUrl: (state) => {
      state.reportUrl = ""
      return state;
    },
  },
  extraReducers: {
    [fetchReportToken.fulfilled.toString()]: (state, { payload }) => {
      if(payload){
        state.reportData = payload.data
        state.reportUrl = payload.url
      };
      if (payload.errorMsg) {
        state.reportFormState = errorState(payload.errorMsg);
      } else {
        state.reportFormState = fulfilledState();
      }
      return state;
    },
    [fetchReportToken.pending.toString()]: (state) => {
      state.reportFormState = pendingState();
      return state;
    },
    [fetchReportToken.rejected.toString()]: (state, { payload }) => {
      state.reportError = payload
      state.reportFormState = rejectedState(payload);
      return state;
    },

  },
});

export const {clearReportState,clearReportToken,clearReportUrl} = ReportsSlice.actions;
export const reportstateSelector = (state: RootState) => state.reports;
export default ReportsSlice.reducer;