import React from 'react';
import { Row, Modal } from "antd";
import Button from "../../../../stateless/common/button";
import './deactiveCartModel.less'

const DeactiveCartModel = (props:any) => {
    const { deactiveCart, setDeactiveCart, forwardCheck } = props

    const onCancelModel=()=>{
        forwardCheck(true)
        setDeactiveCart(false)
    }

    const handleSubmit = () => {
        forwardCheck(false)
        setDeactiveCart(false)
    }
  return (
      <Modal
        wrapClassName='deactive-model'
        visible={deactiveCart}
        onCancel={onCancelModel}
        footer = {
            <>
                <Row justify="center">
                    <Button
                    type="secondary deactiveBtn"
                    onClick={handleSubmit}
                    >
                    Yes, deactivate
                    </Button>
                </Row>
                <Row justify="center">
                    <Button
                    type="primary deactiveBtn"
                    onClick={onCancelModel}
                    >
                    No, Cancel
                    </Button>
                </Row>
            </>
        }
      >
          <div className='deactive-title'>
              Are you sure you would like to deactivate
          </div>
      </Modal>
  );
};

export default DeactiveCartModel;
