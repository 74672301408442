import { useState, useEffect } from "react";
import { Modal, Row, Col, Input, message } from "antd";
import Button from "../../stateless/common/button";
import { useDispatch, useSelector } from "react-redux";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import {
  ShelfstateSelector,
} from "../../../../redux/reducers/shelves/shelfReducer";
import {
  createNewShelve,onSelectShelve
} from "../../../../redux/actions/shelves/shelfAction";
import SpinnerLoader from "../../stateless/common/spinnerLoader";
import { clearNewShelv } from "../../../../redux/reducers/shelves/shelfReducer";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../router/appRoutes";

const { TextArea } = Input;

export const ManualShelves = (props: any) => {
  const { isManualModalVisible, setManualModalVisible } = props;
  const [updateFields, setUpdateFields] = useState<any>({
    "shelveName":"",
    "shelveDescription":""
  });
  const [btnDisable, setBtnDisable] = useState<boolean>(false)
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [customloading, setCustomLoading] = useState<boolean>(false)
  const { appUser } = useSelector(userStateSelector);
  var LibraryId =
  appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  
  const { newShelveFormState, formState, shelves } = useSelector(ShelfstateSelector);
  const dispatch = useDispatch();
  const history = useHistory();
   const handleDetails = (e:any) => {
    setUpdateFields({
        ...updateFields,
        [`${e.target.name}`]: (e?.target?.value),
      });
  }

  const handleSave = () => {
    if(!btnDisable && !descriptionError){
        const payload = {
            "ShelfId": 0,
            "LibraryId": LibraryId,
            "ShelfName": updateFields.shelveName,
            "ReaktorShelfId": null,
            "Description": updateFields.shelveDescription,
            "IsActive": false,

            "IsManual": true,
            "SortOrder": 0,
            "NextRefreshTime": null,
            "SortStrategy": "Title",

            "CatShelfCriteria": {
                "AudienceTypeId": null,
                "Author": null,
                "Category": null,
                "itemformatid": {
                    "Ebooks": null
                },
                "ItemFormatIdSet": null,
                "MediaTypeIdSet": [
                    1
                ],
                "Language": "",
                "MaxBooks": 250,
                "MostPopularDays": "0",
                "Publisher": null,
                "PublishedWithinDays": "0",
                "PurchasedWithinDays": "0",
                "SortStrategy": "Title",
                "TargetAudienceIds": null,
                "TreatNewCopiesAsNewBooks": "F",
                "MediaTypeIdSetAsString": 1
            },
            "ShelfItems": [],
            "CatShelfAllItems": [],
            "Items": [],
            "ItemsCount": 0
        }
        dispatch(createNewShelve(payload))
        setCustomLoading(true)
    }
  }

  useEffect(() => {
    if( updateFields.shelveName !== "" ){
        setBtnDisable(false)
    }else {
        setBtnDisable(true)
    }
    updateFields?.shelveDescription?.length > 100
      ? setDescriptionError(true)
      : setDescriptionError(false);
  }, [updateFields])
  
  const closeModel = () => {
    setManualModalVisible(false)
    setUpdateFields({
        "shelveName":"",
        "shelveDescription":""
      })
    setBtnDisable(false)
    setDescriptionError(false);
  }

  const showNotification =(msg: any, type: any)=>{
    if(type === "success"){
      message.success({
        content: msg,
        key: 'success'
      })
    }
    else{
    const text = msg.split(","); 
    message.error({
      content: text[1],
      key: 'error'
    })
    dispatch(clearNewShelv())
    setCustomLoading(false)
    }
  }

  useEffect(() => {
  if(newShelveFormState.isError){
    showNotification(newShelveFormState?.errorMessage, "error")
  }else if(newShelveFormState.isSuccess){
    setUpdateFields({
          "shelveName":"",
          "shelveDescription":""
        })
    setManualModalVisible(false)
    showNotification("Shelf Created Successfully", "success")
    dispatch(onSelectShelve(shelves[0]))
    history.push(AppRoutes.COLLECTION);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newShelveFormState])


  return (
    <Modal
      visible={isManualModalVisible}
      centered
      wrapClassName="autoShelvesModal"
      onCancel={closeModel}
    >
<SpinnerLoader loading={newShelveFormState?.loading || formState?.loading || customloading}>
        <div className="autoShelvesTitle">New Manual Shelf</div>
        
        <div className="mb-6 mt-6">
            <Row>
                <Col span={24}>
                    <Input className={`shelfTitle ${btnDisable ? 'manualShelveField' : ''}`} value={updateFields.shelveName} placeholder="Shelf name" name="shelveName" onChange={handleDetails} onPressEnter={handleSave} />
                     { btnDisable ? 
                        <div className="manualShelveError">Please enter a Shelf Name</div>
                        : null
                    }
                </Col>
            </Row>
        </div>

        <div className="mt-6 mb-40">
            <Row>
                <Col span={24}>
                    <TextArea 
                        className={`scrollStyle isbnSearchScroll shelfDescription ${
                          descriptionError ? "manualShelveField" : ""
                        }`}
                        maxLength={100}
                        placeholder="Description"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        name="shelveDescription"
                        onChange={handleDetails}
                        onPressEnter={handleSave}
                        value={updateFields.shelveDescription}
                    />
                    {descriptionError && (
                      <div className="manualShelveError">
                        Cannot enter more than 100 characters
                      </div>
                    )}
                </Col>
            </Row>
        </div>

        <div className={`${btnDisable || descriptionError ? 'manualShelfBtn' : ''}`}>
            <Button type="medium" onClick={handleSave}>Continue</Button>
        </div>
        </SpinnerLoader>
    </Modal>
  );
};
