import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import {
  fetchAudienceData,
  fetchContentProviders,
  fetchPublishers,
  fetchMediaTypes,
  saveSearch,
  fetchSearchResults,
  fetchLanguages,
  fetchCategoryOptions,
  fetchSearchList,
  deleteSearchById,
  fetchQuickSearch,
  getSearchString,
  getSaveSearchResult,
  onBackFillForm,
  onStringBackFillForm,
  fetchIsbnSearcNew,
  fetchSearchResultsNew,
  searchFilters,
  savedSearchFilters,
  fetchColecSearchResults,
  saveTargetAudience,
  cleraFitureList,
  deleteTargetAudience,
  retainDefailtValue,
  fetchMediaTypesError,
  getUnmatchValues,
  fetchQuickSearchNew,
  fetchQuickSearchIsbnNew,
  fetchppuBooksStatus,
  fetchContentProvidersNew,
  fetchPublisherWithRules
} from "../../actions/search/searchActions";
import { SearchState } from "../../states/search/searchState";
import { RootState } from "../../store/rootReducer";

const initialState: SearchState = {
  audienceData: [],
  providerData: [],
  providerDataNew: [],
  publishersDataNew: [],
  publishers:[],
  mediaTypes: [],
  languages:[],
  ppuBooksStatus:[],
  savedSearches: [],
  categoriesOptions: [],
  searchResults: [],
  searchSuccess: false,
  searchSaved: false,
  scrollHeight:0,
  quickSearchValue:"",
  searchDelete: false,
  formState: resetState(),
  ppuBooksSearchFormState:resetState(),
  quickSearchResults: [],
  quickSearchState: resetState(),
  selectedTab: 1,
  selectedSearch: null,
  selectedSearchAfterEdit: null,
  searchStrings:null,
  saveSearchResults:[],
  backFormFill:[],
  previousLocation:[],
  previousPayload:[],
  stringBackFormFill:[],
  totalResults:0,
  totalIsbnNumbers:[],
  totalIsbnprice:[],
  matchAndUnmatchData:[],
  selectedFilters: {},
  savedSelectedFilters: {},
  searchFormState: resetState(),
  collecSearchResults: [],
  totalCollecResults:0,
  searchCollectSuccess: false,
  searchCollecFormState: resetState(),
  targetAudFormState:resetState(),
  targetAudSav:false,
  resetSearchForm:false,
  clearTargetAud:[],
  retainValue:[],
  mediaTypesFormState: resetState(),
  getMediaData:[],
  audienceDataFormState: resetState(),
  contentProvidersFormState: resetState(),
  publishersFormState:resetState(),
  mediaTypeFormState: resetState(),
  languageSearchFormState: resetState(),
  categoryOptionsFormState: resetState(),
  RecordsNotMatched:[],
  NotMatchedData:[],
  MatchedPrice:[]
};

export const SearchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      state.formState = resetState();
      state.searchFormState = resetState();
      state.searchCollecFormState = resetState();
      state.targetAudFormState = resetState();
      state.audienceDataFormState = resetState();
      state.contentProvidersFormState = resetState();
      state.publishersFormState = resetState();
      state.mediaTypeFormState = resetState();
      state.languageSearchFormState = resetState();
      state.categoryOptionsFormState = resetState();
      return state;
    },
    clearResults: (state) => {
      state.searchResults = [];
      // state.collecSearchResults = [];
      state.totalResults = 0;
      state.totalIsbnNumbers = []
      state.totalCollecResults = 0;
      state.targetAudSav = false;
      return state
    },
    clearCollecResults: (state) => {
      state.collecSearchResults = [];
      return state
    },
    clearMatchIsbnData: (state) => {
      state.RecordsNotMatched=[];
      state.MatchedPrice=[];
      state.NotMatchedData=[];
      state.matchAndUnmatchData = [];
      state.totalIsbnprice = []
      return state
    },

    clearSuccess: (state) => {
      state.searchSuccess = false;
      state.searchCollectSuccess = false;
      state.targetAudSav = false;
      return state
    },
    clearQuicSearchNew: (state) => {
      state.quickSearchState = resetState();
      state.quickSearchResults = [];
      return state
    },
    clearDeleted: (state) => {
      state.searchDelete =false;
      return state
    },
    clearSaved: (state) => {
      state.searchSaved =false;
      return state
    },
    
    setSearchScroll: (state,{ payload}) => {
      state.scrollHeight = payload;
      return state;
    },
    setSearchTab: (state,{ payload}) => {
      state.selectedTab = payload;
      return state;
    },
    getPreviousLocaton: (state,{ payload}) => {
      state.previousLocation = payload;
      return state;
    },
    getPreviousPayload: (state,{ payload}) => {
      state.previousPayload = payload;
      return state;
    },
    clearString: (state) => {
      state.searchStrings = null;
      return state;
    },
    clearSaveSearchResult: (state) => {
      state.saveSearchResults = [];
      state.ppuBooksStatus = [];
      state.ppuBooksSearchFormState = resetState();
      return state;
    },
    clearAdvanceSearchData: (state) => {
      state.saveSearchResults = [];
      state.resetSearchForm = false;
      state.stringBackFormFill = []
      return state;
    },
    clearBackFormFill: (state) => {
      state.backFormFill = [];
      return state;
    },
    clearSetSearch: (state) => {
      state.selectedSearch = null
      return state;
    },
    clearQuickSearchResults: (state) => {
      state.quickSearchResults = []
      return state;
    },
    setSearch: (state,{ payload}) => {
      state.selectedSearch = payload;
      return state;
    },
    setSearchAfterEdit: (state,{ payload}) => {
      state.selectedSearchAfterEdit = payload;
      return state;
    },
    clearSearchAfterEdit: (state) => {
      state.selectedSearchAfterEdit = null;
      return state;
    },
    clearSearchFilter: (state) => {
      state.selectedFilters = {}
      return state;
    },
    clearRetainValue: (state) => {
      state.retainValue = []
      return state;
    },
    clearSavedSearchFilter: (state) => {
      state.savedSelectedFilters = {}
      return state;
    },
    clearMediaType: (state) => {
      state.mediaTypesFormState = resetState();
      state.getMediaData = []
      return state;
    },
    clearSearchScroll: (state) => {
      state.scrollHeight = 0;
      return state;
    },
    setQuickSearchValue:(state,{payload})=>{
      state.quickSearchValue = payload;
      return state;
    },
    clearQuickSearchValue:(state)=>{
      state.quickSearchValue = "";
      return state;
    },
    resetSearchForm: (state) => {
      state.resetSearchForm = true;
      return state;
    },
    pendingSearchFormState: (state) => {
      state.searchFormState = pendingState();
      return state;
    },
    resetSearchFormState: (state) => {
      state.searchFormState = resetState();
      return state;
    },
  },
  extraReducers: {
    [fetchAudienceData.fulfilled.toString()]: (state, { payload }) => {
      if(payload && payload.length){
        state.audienceData = payload
      };
      if (payload.errorMsg) {
        state.formState = errorState(payload.errorMsg);
        state.audienceDataFormState = errorState(payload.errorMsg)
      } else {
        state.formState = fulfilledState();
        state.audienceDataFormState = fulfilledState();
      }
      return state;
    },
    [fetchAudienceData.pending.toString()]: (state) => {
      state.formState = pendingState();
      state.audienceDataFormState = pendingState();
      return state;
    },
    [fetchAudienceData.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      state.audienceDataFormState = rejectedState(payload);
      return state;
    },
    [fetchContentProviders.fulfilled.toString()]: (state, { payload }) => {
        if(payload && payload.length){
          state.providerData = payload
        };
        if (payload.errorMsg) {
          state.formState = errorState(payload.errorMsg);
          state.contentProvidersFormState = errorState(payload.errorMsg);
        } else {
          state.formState = fulfilledState();
          state.contentProvidersFormState = fulfilledState();
        }
        return state;
      },
      [fetchContentProviders.pending.toString()]: (state) => {
        state.formState = pendingState();
        state.contentProvidersFormState = pendingState();
        return state;
      },
      [fetchContentProviders.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        state.contentProvidersFormState = rejectedState(payload)
        return state;
      },
      [fetchContentProvidersNew.fulfilled.toString()]: (state, { payload }) => {
        if(payload){
          state.providerDataNew = payload
        };
        if (payload.errorMsg) {
          state.contentProvidersFormState = errorState(payload.errorMsg);
        } else {
          state.contentProvidersFormState = fulfilledState();
        }
        return state;
      },
      [fetchContentProvidersNew.pending.toString()]: (state) => {
        state.contentProvidersFormState = pendingState();
        return state;
      },
      [fetchContentProvidersNew.rejected.toString()]: (state, { payload }) => {
        state.contentProvidersFormState = rejectedState(payload)
        return state;
      },
      [fetchPublisherWithRules.fulfilled.toString()]: (state, { payload }) => {
        if(payload){
          state.publishersDataNew = payload
        };
        if (payload.errorMsg) {
          state.publishersFormState = errorState(payload.errorMsg);
        } else {
          state.publishersFormState = fulfilledState();
        }
        return state;
      },
      [fetchPublisherWithRules.pending.toString()]: (state) => {
        state.publishersFormState = pendingState();
        return state;
      },
      [fetchPublisherWithRules.rejected.toString()]: (state, { payload }) => {
        state.publishersFormState = rejectedState(payload)
        return state;
      },
      [fetchPublishers.fulfilled.toString()]: (state, { payload }) => {
        if(payload && payload.length){
          state.publishers = payload
        };
        if (payload.errorMsg) {
          state.formState = errorState(payload.errorMsg);
          state.publishersFormState = errorState(payload.errorMsg)
        } else {
          state.formState = fulfilledState();
          state.publishersFormState = fulfilledState();
        }
        return state;
      },
      [fetchPublishers.pending.toString()]: (state) => {
        state.formState = pendingState();
        state.publishersFormState = pendingState();
        return state;
      },
      [fetchPublishers.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        state.publishersFormState = rejectedState(payload);
        return state;
      },
      [fetchMediaTypes.fulfilled.toString()]: (state, { payload }) => {
        if(payload && payload.length){
          state.mediaTypes = payload
        };
        if (payload.errorMsg) {
          state.formState = errorState(payload.errorMsg);
          state.mediaTypeFormState = errorState(payload.errorMsg);
        } else {
          state.formState = fulfilledState();
          state.mediaTypeFormState = fulfilledState();
        }
        return state;
      },
      [fetchMediaTypes.pending.toString()]: (state) => {
        state.formState = pendingState();
        state.mediaTypeFormState = pendingState();
        return state;
      },
      [fetchMediaTypes.rejected.toString()]: (state, { payload }) => {
        state.mediaTypeFormState = rejectedState(payload);
        return state;
      },
      [fetchMediaTypesError.fulfilled.toString()]: (state, { payload }) => {
        if (payload.error) {
          state.mediaTypesFormState = errorState(payload);
        } else {
          state.mediaTypesFormState = fulfilledState();
        }
        return state;
      },
      [fetchMediaTypesError.pending.toString()]: (state) => {
        state.mediaTypesFormState = pendingState();
        return state;
      },
      [fetchMediaTypesError.rejected.toString()]: (state, { payload }) => {
        state.mediaTypesFormState = rejectedState(payload);
        return state;
      },
      [fetchLanguages.pending.toString()]: (state) => {
        state.languageSearchFormState = pendingState();
        return state;
      },
      [fetchLanguages.fulfilled.toString()]: (state, { payload }) => {
        if(payload && payload.length){
          state.languages = payload
        };
        if (payload.errorMsg) {
          state.formState = errorState(payload.errorMsg);
          state.languageSearchFormState = errorState(payload.errorMsg)
        } else {
          state.formState = fulfilledState();
          state.languageSearchFormState = fulfilledState();
        }
        return state;
      },
      [fetchLanguages.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        state.languageSearchFormState = rejectedState(payload)
        return state;
      },
      [fetchCategoryOptions.pending.toString()]: (state) => {
        state.categoryOptionsFormState = pendingState();
        return state;
      },
      [fetchCategoryOptions.fulfilled.toString()]: (state, { payload }) => {
        if(payload && payload.length){
          state.categoriesOptions = payload
        };
        if (payload.errorMsg) {
          state.formState = errorState(payload.errorMsg);
          state.categoryOptionsFormState = errorState(payload.errorMsg)
        } else {
          state.formState = fulfilledState();
          state.categoryOptionsFormState = fulfilledState();
        }
        return state;
      },
      [fetchCategoryOptions.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        state.categoryOptionsFormState = rejectedState(payload);
        return state;
      },
      [saveSearch.fulfilled.toString()]: (state, { payload }) => {
        if (payload.errorMsg) {
          state.formState = errorState(payload.errorMsg);
        } else {
          state.searchSaved= true;
          state.formState = fulfilledState();
        }
        return state;
      },
      [saveSearch.pending.toString()]: (state) => {
        state.formState = pendingState();
        return state;
      },
      [saveSearch.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        return state;
      },
      [fetchSearchResults.fulfilled.toString()]: (state, { payload }) => {   
        if (payload.errorMsg) {
          state.searchFormState = errorState(payload.errorMsg);
        } else {
          state.searchResults = payload.data
          state.totalResults = payload.total
          state.searchFormState = fulfilledState();
          state.searchSuccess= true
        }
        return state;
      },
      [fetchSearchResults.pending.toString()]: (state) => {
        state.searchFormState = pendingState();
        return state;
      },
      [fetchSearchResults.rejected.toString()]: (state, { payload }) => {
        state.searchFormState = rejectedState(payload);
        return state;
      },

      //search with cancel feature
      [fetchSearchResultsNew.fulfilled.toString()]: (state, { payload }) => {   
        if (payload.errorMsg) {
          state.searchFormState = errorState(payload.errorMsg);
        } else {
          state.searchResults = payload.data
          state.totalResults = payload.total
          state.searchFormState = fulfilledState();
          state.searchSuccess= true
        }
        return state;
      },
      [fetchSearchResultsNew.pending.toString()]: (state) => {
        state.searchFormState = pendingState();
        return state;
      },
      [fetchSearchResultsNew.rejected.toString()]: (state, { payload }) => {
        state.searchFormState = rejectedState(payload);
        return state;
      },

      [fetchSearchList.fulfilled.toString()]: (state, { payload }) => {   
        if (payload.errorMsg) {
          state.formState = errorState(payload.errorMsg);
        } else {
          state.savedSearches = payload
          state.formState = fulfilledState();
        }
        return state;
      },
      [fetchSearchList.pending.toString()]: (state) => {
        state.formState = pendingState();
        return state;
      },
      [fetchSearchList.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        return state;
      },

      [fetchIsbnSearcNew.fulfilled.toString()]: (state, { payload }) => {   
        if (payload?.errorMsg) {
          state.searchFormState = errorState(payload?.errorMsg);
        } else {
          state.RecordsNotMatched = payload?.RecordsNotMatched
          state.NotMatchedData = payload?.totalUnmatchData
          state.MatchedPrice =payload?.MatchedPrice
          state.searchResults = payload?.data
          state.totalResults = payload?.total
          state.totalIsbnNumbers = payload?.totalIsbnNumbers
          state.totalIsbnprice = payload?.totalPrice
          state.searchFormState = fulfilledState();
          state.searchSuccess= true
          state.collecSearchResults = payload?.data
          state.totalCollecResults = payload.total
          state.searchCollecFormState = fulfilledState();
          state.searchCollectSuccess= true
        }
        return state;
      },
      [fetchIsbnSearcNew.pending.toString()]: (state) => {
        state.searchFormState = pendingState();
        state.searchCollecFormState = pendingState();
        return state;
      },
      [fetchIsbnSearcNew.rejected.toString()]: (state, { payload }) => {
        state.searchFormState = rejectedState(payload);
        state.searchCollecFormState = rejectedState(payload);
        return state;
      },

      [deleteSearchById.fulfilled.toString()]: (state, { payload }) => {   
        if (payload.errorMsg) {
          state.formState = errorState(payload.errorMsg);
        } else {
          state.formState = fulfilledState();
          state.searchDelete= true
        }
        return state;
      },
      [deleteSearchById.pending.toString()]: (state) => {
        state.formState = pendingState();
        return state;
      },
      [deleteSearchById.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        return state;
      },
      [fetchQuickSearch.fulfilled.toString()]: (state, { payload }) => {   
        if (payload.errorMsg) {
          state.quickSearchState = errorState(payload.errorMsg);
        } else {
          state.quickSearchState = fulfilledState();
          state.quickSearchResults = payload
          state.searchSuccess= true
        }
        return state;
      },
      [fetchQuickSearch.pending.toString()]: (state) => {
        state.quickSearchState = pendingState();
        return state;
      },
      [fetchQuickSearch.rejected.toString()]: (state, { payload }) => {
        state.quickSearchState = rejectedState(payload);
        return state;
      },
      [fetchQuickSearchNew.fulfilled.toString()]: (state, { payload }) => {   
        if (payload.errorMsg) {
          state.quickSearchState = errorState(payload.errorMsg);
        } else {
          state.quickSearchState = fulfilledState();
          state.quickSearchResults = payload
          state.searchSuccess= true
        }
        return state;
      },
      [fetchQuickSearchNew.pending.toString()]: (state) => {
        state.quickSearchState = pendingState();
        return state;
      },
      [fetchQuickSearchNew.rejected.toString()]: (state, { payload }) => {
        state.quickSearchState = rejectedState(payload);
        return state;
      },

      [fetchQuickSearchIsbnNew.fulfilled.toString()]: (state, { payload }) => {   
        if (payload?.errorMsg) {
          state.quickSearchState = errorState(payload?.errorMsg);
        } else {
          state.quickSearchState = fulfilledState();
          state.quickSearchResults = payload?.data
        }
        return state;
      },
      [fetchQuickSearchIsbnNew.pending.toString()]: (state) => {
        state.quickSearchState = pendingState();
        return state;
      },
      [fetchQuickSearchIsbnNew.rejected.toString()]: (state, { payload }) => {
        state.quickSearchState = rejectedState(payload);
        return state;
      },

      [getSearchString.fulfilled.toString()]: (state, { payload }) => {
        state.searchStrings = payload
        return state;
      },
      [getSearchString.pending.toString()]: (state) => {
        state.formState = pendingState();
        return state;
      },
      [getSearchString.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        return state;
      },
      [getSaveSearchResult.fulfilled.toString()]: (state, { payload }) => {
        state.saveSearchResults = payload
        state.formState = fulfilledState();
        return state;
      },
      [getSaveSearchResult.pending.toString()]: (state) => {
        state.formState = pendingState();
        return state;
      },
      [getSaveSearchResult.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        return state;
      },
      [onBackFillForm.fulfilled.toString()]: (state, { payload }) => {
        state.backFormFill = payload
        state.formState = fulfilledState();
        return state;
      },
      [onBackFillForm.pending.toString()]: (state) => {
        state.formState = pendingState();
        return state;
      },
      [onBackFillForm.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        return state;
      },
      [onStringBackFillForm.fulfilled.toString()]: (state, { payload }) => {
        state.stringBackFormFill = payload
        state.formState = fulfilledState();
        return state;
      },
      [onStringBackFillForm.pending.toString()]: (state) => {
        state.formState = pendingState();
        return state;
      },
      [onStringBackFillForm.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        return state;
      },
      [searchFilters.fulfilled.toString()]: (state, { payload }) => {
        state.selectedFilters = payload
        state.formState = fulfilledState();
        return state;
      },
      [searchFilters.pending.toString()]: (state) => {
        state.formState = pendingState();
        return state;
      },
      [searchFilters.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        return state;
      },
      [savedSearchFilters.fulfilled.toString()]: (state, { payload }) => {
        state.savedSelectedFilters = payload
        state.formState = fulfilledState();
        return state;
      },
      [savedSearchFilters.pending.toString()]: (state) => {
        state.formState = pendingState();
        return state;
      },
      [savedSearchFilters.rejected.toString()]: (state, { payload }) => {
        state.formState = rejectedState(payload);
        return state;
      },

       //search with my collection feature
       [fetchColecSearchResults.fulfilled.toString()]: (state, { payload }) => {   
        if (payload.errorMsg) {
          state.searchFormState = errorState(payload.errorMsg);
        } else {
          state.collecSearchResults = payload.data
          state.totalCollecResults = payload.total
          state.searchCollecFormState = fulfilledState();
          state.searchCollectSuccess= true
          state.targetAudSav= false;
        }
        return state;
      },
      [fetchColecSearchResults.pending.toString()]: (state) => {
        state.searchCollecFormState = pendingState();
        return state;
      },
      [fetchColecSearchResults.rejected.toString()]: (state, { payload }) => {
        state.searchCollecFormState = rejectedState(payload);
        return state;
      },
      [saveTargetAudience.fulfilled.toString()]: (state, { payload }) => {
        if (payload.errorMsg) {
          state.targetAudFormState = errorState(payload.errorMsg);
        } else {
          state.targetAudFormState = fulfilledState();
          state.targetAudSav= true;
        }
        return state;
      },
      [saveTargetAudience.pending.toString()]: (state) => {
        state.targetAudFormState = pendingState();
        return state;
      },
      [saveTargetAudience.rejected.toString()]: (state, { payload }) => {
        state.targetAudFormState = rejectedState();
        return state;
      },
      [cleraFitureList.fulfilled.toString()]: (state, { payload }) => {
        state.targetAudSav= payload;
        state.collecSearchResults = []
        state.totalCollecResults = 0
        return state;
      },
      [cleraFitureList.pending.toString()]: (state) => {
        state.targetAudFormState = pendingState();
        return state;
      },
      [cleraFitureList.rejected.toString()]: (state, { payload }) => {
        state.targetAudFormState = rejectedState();
        return state;
      },
      [deleteTargetAudience.fulfilled.toString()]: (state, { payload }) => {
        if (payload.errorMsg) {
          state.targetAudFormState = errorState(payload.errorMsg);
        } else {
          state.targetAudFormState = fulfilledState();
          state.targetAudSav= true;
        }
        return state;
      },
      [deleteTargetAudience.pending.toString()]: (state) => {
        state.targetAudFormState = pendingState();
        return state;
      },
      [deleteTargetAudience.rejected.toString()]: (state, { payload }) => {
        state.targetAudFormState = rejectedState();
        return state;
      },

      [retainDefailtValue.fulfilled.toString()]: (state, { payload }) => {
        state.retainValue= payload;
        return state;
      },
      [retainDefailtValue.pending.toString()]: (state) => {

        return state;
      },
      [retainDefailtValue.rejected.toString()]: (state, { payload }) => {
        state.targetAudFormState = rejectedState();
        return state;
      },
      [getUnmatchValues.fulfilled.toString()]: (state, { payload }) => {
        state.matchAndUnmatchData= payload;
        return state;
      },
      [fetchppuBooksStatus.pending.toString()]: (state) => {
        state.ppuBooksSearchFormState = pendingState();
        return state;
      },
      [fetchppuBooksStatus.fulfilled.toString()]: (state, { payload }) => {
        if(payload){
          state.ppuBooksStatus = payload
        };
        if (payload.errorMsg) {
          state.ppuBooksSearchFormState = errorState(payload.errorMsg)
        } else {
          state.ppuBooksSearchFormState = fulfilledState();
        }
        return state;
      },
      [fetchppuBooksStatus.rejected.toString()]: (state, { payload }) => {
        state.ppuBooksSearchFormState = rejectedState(payload)
        return state;
      },
  },
});

export const { clearState, clearResults, clearDeleted, clearSetSearch,clearQuickSearchResults,clearRetainValue ,clearSaved,setSearchScroll, clearSuccess, setSearchTab, setSearch,setSearchAfterEdit,clearSearchAfterEdit,clearString,clearSaveSearchResult,clearAdvanceSearchData,clearSearchFilter, clearSavedSearchFilter,getPreviousLocaton,getPreviousPayload,clearMediaType,clearSearchScroll,clearQuickSearchValue,setQuickSearchValue,clearMatchIsbnData,clearBackFormFill,resetSearchForm,clearQuicSearchNew,clearCollecResults,resetSearchFormState,pendingSearchFormState} = SearchSlice.actions;
export const SearchstateSelector = (state: RootState) => state.Search;
export default SearchSlice.reducer;