/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Card, Typography } from "antd";
import { useHistory,generatePath } from "react-router-dom";
import { Backgrounds } from "../../../constants/constants";
import "./categoryTiles.less";
import { AppRoutes } from "../../../router/appRoutes";
import dumyCovImg21 from "../../../../assets/images/notFound.png";
import { useSelector } from "react-redux";
import { CartStateSelector } from "../../../../redux/reducers/carts/cartsReducer";

export const CategoryTiles = (props: any) => {
  const { Paragraph } = Typography
  const { category,appUser } = props;
  const history = useHistory();
  const [showCardWithoutBg, setShowCardWithoutBg] = useState<boolean>(false)
  const {buyerImgUrl, buyerCart} = useSelector(CartStateSelector);

  const background =
  Backgrounds[Math.floor(Math.random() * Backgrounds.length)];
  const selectCategory = () => {
    const state: any = {
      category: category,
    };
    var id:any = state?.category?.FeatureListId?state?.category?.FeatureListId:""
    history.push(generatePath(AppRoutes.BOOKLIST, {id}), state);
  };

  const getImgUrl =(id:any,size:any)=>{
    if(appUser?.imageUrl !== "" || appUser?.imageUrl !== undefined){
      var url = `https://${appUser?.imageUrl}?type=DOCUMENTIMAGE&documentID=${id}&size=${size}&token=nobody`
      if(id !== undefined){
        return url
      }else{
        return dumyCovImg21
      }
    }
  }

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = dumyCovImg21;
    event.currentTarget.className = "errorInImg";
  };
  return (
    <>
    {showCardWithoutBg?
    <>
    <Card className="group-Tiles" bordered={false} onClick={selectCategory}>
      <div style={{ background: background}} className="group-cover">
        <Paragraph ellipsis={{ rows: 2, expandable: false, tooltip: `${category?.FeatureListName}` }}  className="title">{category?.FeatureListName}</Paragraph>
      </div>
      <span className="group-title">{category?.FeatureListName}</span>
    </Card>
    </>
    :
    <>
    <Card className="group-Tiles" bordered={false} onClick={selectCategory}>
          <div className="coverImgNew">
            <div className={`bookCoverImg1 bookCoverImgLast ${getImgUrl(category?.CoverArtDetails[2]?.ImageId,"SMALL") !== dumyCovImg21?'view_img':'errorInImg'}`}>
            <img alt={dumyCovImg21} src={getImgUrl(`${category?.CoverArtDetails[2]?.ImageId}`,"SMALL")} onError={imageOnErrorHandler} />      
            </div>
            <div className={`bookCoverImg2 bookCoverImgLast ${getImgUrl(category?.CoverArtDetails[1]?.ImageId,"SMALL") !== dumyCovImg21?'view_img':'errorInImg'}`}>
            <img alt={dumyCovImg21} src={getImgUrl(`${category?.CoverArtDetails[1]?.ImageId}`,"SMALL")} onError={imageOnErrorHandler} />    
            </div>
            <div className={`bookCoverImg3 bookCoverImgLast ${getImgUrl(category?.CoverArtDetails[0]?.ImageId,"MEDIUM") !== dumyCovImg21?'view_img':'errorInImg'}`}>
            <img alt={dumyCovImg21} src={getImgUrl(`${category?.CoverArtDetails[0]?.ImageId}`,"MEDIUM")} onError={imageOnErrorHandler} />
            </div>
          </div>
          <span className="group-title pl-7">{category?.FeatureListName}</span>
        </Card>
    </>
    }
    </>
  );
};
