import BaseService from "../../../core/services/baseService";
import { getAPIBaseUrl } from "../../../environment/api";
import { PortalModule } from "../../../environment/apiEnvironment";
import Shelf from "../../models/shelf/shelf";
import Error from "../../models/error/error";
import ShelvItem from "../../models/shelf/shelvItem";
import { showError } from "../../../utility/appUtil";

export class ShelfService extends BaseService {
  constructor() {
    super(getAPIBaseUrl(PortalModule.CARTS));
  }
  async fetchShelvesForLibrary(libraryId: number): Promise<Shelf[] | any> {
    const response = await this.get(`getShelfData?lib_id=${libraryId}`);
    if (response.data.success === 1) {
      var shelfData = this.setShelfData(response.data.data);
      return shelfData;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    };
  }

  async fetchShelveItems(payload: any): Promise<Shelf[] | any> {
    const response = await this.get(
      `getShelfItems?library_Id=${payload?.libraryId}&catShelf_Id=${payload?.catShelfId}&record_Count=${payload?.recordCount}&record_Start=${payload?.recordStart}`
    );
    if (response.data.success === 1) {
      var shelfData = this.setShelfItems(response?.data?.data);
      return shelfData;
    } else {
      showError(response?.data?.data)
      return null
    };
  }

  async fetchShelveDetails(payload: any): Promise<Shelf[] | any> {
    const response = await this.get(
      `getShelfItems?library_Id=${payload?.libraryId}&catShelf_Id=${payload?.catShelfId}&record_Count=${payload?.recordCount}&record_Start=${payload?.recordStart}`
    );
    if (response.data.success === 1) {
      var shelfData = this.setShelfDetails(response?.data?.data);
      return shelfData;
    } else {
      showError(response?.data?.data)
      return null
    };
  }

  async setShelfItemOrder(payload: any): Promise<any> {
    const response = await this.post(`setShelfOrder`, payload);
    if (response.data.success === 1) {
      return true;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    };
  }

  async preDeleteShelv (payload: any): Promise<any> {
    const response = await this.get(`onDeleteShelf?library_Id=${payload?.libraryId}&shelf_Id=${payload?.shelfId}`);
    if(response.data.success === 1){
      var preShelfData = this.setPreShelfData(response.data.data);
      return preShelfData;
    }
    else {
      showError(response?.data?.data)
      return false
    }
  }

  async deleteShelv (payload: any): Promise<any> {
    const response = await this.delete(`deleteShelf?library_Id=${payload?.libId}&shelf_Id=${payload?.shelfId}`);
    if(response.data.success === 1){
        return true
    }
    else {
      showError(response?.data?.data)
      return response.data.data
    }
  }

  async updateCartShelv(payload: any): Promise<any> {
    const response = await this.put(
      `updateShelf`,payload?.reqBody);
    if (response.data.success === 1) {
      return response.data.data;
    } else {
      showError(response?.data?.data)
      return this.setError([response?.data?.data])
    };
  }

  async fetchShelvByShelvId(payload: any): Promise<Shelf[] | any> {
    const response = await this.get(`getShelfById?library_Id=${payload?.libraryId}&catShelf_Id=${payload?.catShelfId}`);
    if (response.data.success === 1) {
      var shelfData = response.data.data;
      return shelfData;
    } else {
      showError(response?.data?.data)
      return null
    };
  }

  async createNewShelve (payload: any): Promise<any> {  
    const response = await this.post(`/createShelf`, payload);
    if(response.data.success === 1){
      var preShelfData = response.data.data;
      return preShelfData;
    } else if (response.data.success === 0){
        return response.data.data;
    }
    else {
      showError(response?.data?.data)
      return null
    }
  }

  async removeShelvItems(payload: any): Promise<any> {
    const response = await this.post(`removeShelfItems`, payload);
    if (response.data.success === 1) {
      return true;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    };
  }

  async fetchRefershShelvs(catShelfId: number): Promise<Shelf[] | any> {
    const response = await this.get(`refreshShelf?catShelf_Id=${catShelfId}`);
    if (response.data.success === 1) {
      return {data:true,shelv:{
        ShelfId:response?.data?.data?.ShelfId,
        CurrentStatus:response?.data?.data?.CurrentStatus,
        Items:response?.data?.data?.Items?.length
      }};
    } else {
      showError(response?.data?.data)
      return this.setError([response?.data?.data])
    };
  }

  async addShelvItems(payload: any): Promise<any> {
    const response = await this.post(`addShelfItems`, payload);
    if (response.data.success === 1) {
      return true;
    } else {
      showError(response?.data?.data)
      return response?.data?.data
    };
  }
  

  private setShelfData(data: any) {
    const shelves: Shelf[] = data.map((shelf: any,index:any) => {
      return {
        CatShelfAllItems: shelf.CatShelfAllItems,
        CatShelfCriteria: shelf.CatShelfCriteria,
        CreateDateTime: shelf.CreateDateTime,
        CreateUserId: shelf.CreateUserId,
        Description: shelf.Description,
        IsActive: shelf.IsActive,
        IsManual: shelf.IsManual,
        IsQuickConnect: shelf.IsQuickConnect,
        Items: shelf.Items,
        ItemsCount: shelf.ItemsCount,
        LibraryId: shelf.LibraryId,
        ReaktorShelfId: shelf.ReaktorShelfId,
        ShelfId: shelf.ShelfId,
        ShelfName: shelf.ShelfName,
        SortOrder: shelf.SortOrder,
        ChangeDateTime: shelf.ChangeDateTime,
        ChangeUserId: shelf.ChangeUserId,
        CurrentStatus: shelf.CurrentStatus,
        SortStrategy: shelf.SortStrategy,
        Indexid:index
      };
    });
    return shelves;
  }

  private setShelfItems(data: any) {
    const shelves: ShelvItem[] = data?.map((shelf: any) => {
      return {
        AddedToCatalogDate: shelf.AddedToCatalogDate,
        AudienceData: shelf.AudienceData,
        Authors: shelf.Authors,
        CatShelfItemId: shelf.CatShelfItemId,
        CatalogItemId: shelf.CatalogItemId,
        Categories: shelf.Categories,
        CategoryList: shelf.CategoryList,
        CheckOutLimit: shelf.CheckOutLimit,
        ContentProviderId: shelf.ContentProviderId,
        ContentProviderName: shelf.ContentProviderName,
        EISBN: shelf.EISBN,
        EditionType: shelf.EditionType,
        FileFormatName: shelf.FileFormatName,
        HoldRatio: shelf.HoldRatio,
        ImageId: shelf.ImageId,
        Imprint: shelf.Imprint,
        IsPPU: shelf.IsPPU,
        ItemState: shelf.ItemState,
        Language: shelf.Language,
        LastCheckoutDate: shelf.LastCheckoutDate,
        LastPurchasedDate: shelf.LastPurchasedDate,
        MediaTypeId: shelf.MediaTypeId,
        Narrator: shelf.Narrator,
        NumberInCarts: shelf.NumberInCarts,
        NumberInSeries: shelf.NumberInSeries,
        NumberOfCopies: shelf.NumberOfCopies,
        NumberOfCurrentLoans: shelf.NumberOfCurrentLoans,
        NumberOfHolds: shelf.NumberOfHolds,
        NumberOfLoans: shelf.NumberOfLoans,
        NumberOfShelves: shelf.NumberOfShelves,
        NumberOfWish: shelf.NumberOfWish,
        NumberOnOrder: shelf.NumberOnOrder,
        PPUPrice: shelf.PPUPrice,
        PublicationDate: shelf.PublicationDate,
        RetailPrice: shelf.RetailPrice,
        Series: shelf.Series,
        ShelfId: shelf.ShelfId,
        SortOrder: shelf.SortOrder,
        StreetDate: shelf.StreetDate,
        SubTitle: shelf.SubTitle,
        TimeSpanLimit: shelf.TimeSpanLimit,
        Title: shelf.Title,
        key: shelf.CatShelfItemId,
      };
    });
    return shelves;
  }

  private setShelfDetails(data: any) {
    const shelves: ShelvItem[] = data?.map((shelf: any) => {
      return {
        key: shelf.CatShelfItemId,
        AddedToCatalogDate:shelf?.AddedToCatalogDate,
        AudienceData:shelf?.AudienceData,
        Authors:shelf?.Authors,
        CatShelfItemId:shelf?.CatShelfItemId,
        CatalogItemId:shelf?.CatalogItemId,
        Categories:shelf?.Categories,
        CategoryList:shelf?.CategoryList,
        CheckOutLimit: shelf?.CheckOutLimit,
        ContentProviderId:shelf?.ContentProviderId,
        ContentProviderName:shelf?.ContentProviderName,
        EISBN:shelf?.EISBN,
        EditionType:shelf?.EditionType,
        FileFormatName:shelf?.FileFormatName,
        HoldRatio:shelf?.HoldRatio,
        ImageId:shelf?.ImageId,
        Imprint:shelf?.Imprint,
        IsPPU:shelf?.IsPPU,
        ItemState:shelf?.ItemState,
        Language:shelf?.Language,
        LastCheckoutDate:shelf?.LastCheckoutDate,
        LastPurchasedDate:shelf?.LastPurchasedDate,
        MediaTypeId: shelf?.MediaTypeId,
        Narrator:shelf?.Narrator,
        NumberInCarts:shelf?.NumberInCarts,
        NumberInSeries:shelf?.NumberInSeries,
        NumberOfCopies:shelf?.NumberOfCopies,
        NumberOfCurrentLoans:shelf?.NumberOfCurrentLoans,
        NumberOfHolds:shelf?.NumberOfHolds,
        NumberOfLoans:shelf?.NumberOfLoans,
        NumberOfShelves:shelf?.NumberOfShelves,
        NumberOfWish:shelf?.NumberOfWish,
        NumberOnOrder:shelf?.NumberOnOrder,
        PPUPrice:shelf?.PPUPrice,
        PublicationDate: shelf?.PublicationDate,
        RetailPrice: shelf?.RetailPrice,
        Series:shelf?.Series,
        ShelfId: shelf?.ShelfId,
        SortOrder:shelf?.SortOrder,
        StreetDate:shelf?.StreetDate,
        SubTitle:shelf?.SubTitle,
        TimeSpanLimit:shelf?.TimeSpanLimit,
        Title: shelf?.Title,
      };
    });
    return shelves;
  }

  private setPreShelfData(data: any) {
    const shelves: Shelf[] = data.map((shelf: any) => {
      return {
        AssociateShelfId : shelf.AssociateShelfId,
        CartId: shelf.CartId
      };
    });
    return shelves;
  }

  private setError(data: any) {
    const error: Error[] = data.map((err: any) => {
      return {
        ErrorCode : err?.ErrorCode,
        ErrorDetails: err?.ErrorDetails,
        ErrorMessage:err?.ErrorMessage
      };
    });
    return error;
  }

}