export const Messages = {
    ADD_TO_CART: "Adding to {0}.",
    ITEM_ADDED: "Added successfully.",
    CART_CREATED: "Cart created successfully.",
    CART_DELETED: "Cart deleted successfully.",
    RULES_UPDATED: "Rules saved successfully.",
    AUTO_CART_MESSAGE: `Let us do the work for you! Configure Auto Carts to save time managing your collection. Carts are automatically updated once a day.`,
    SAVE_SEARCH: "Saving your Search",
    SAVE_SEARCH_SUCCESS: "Search Saved Successfully",
    REMOVING_ITEM: "Removing selected items.",
    ITEM_REMOVED: "Removed successfully.",
    ITEM_UPDATE: "Items Updated successfully.",
    MOVE_ITEM: "Moving selected items.",
    MOVE_SUCCESS: "Moved successfully.",
    QUANTITY_UPDATED: "Updating Quantity of selected items.",

};        
