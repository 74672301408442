/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useRef } from "react";
import {
  Row,
  Col,
  Divider,
  Menu,
  Dropdown,
  InputNumber,
  Empty,
  message,
  Checkbox,
  Tooltip,
  Spin,
} from "antd";
import DropDown from "../../stateless/common/dropDown";
import Button from "../../stateless/common/button";
import PaginationTag from "../../stateless/common/pagination";
import TextEllipsis from "../../stateless/common/ellipsis";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import {
  CartStateSelector,
  clearAdded,
  setTab,
  clearErrorCart,
} from "../../../../redux/reducers/carts/cartsReducer";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { useEffect, useState } from "react";
import {
  ascIcon,
  descIcon,
  editIcon,
  downIcon,
  dropdownIcon,
  whiteArrow,
  list,
  asteriskIcon,
  userPrivate,
} from "../../../images";
import Cart from "../../../models/cart/cart";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../router/appRoutes";
import { filterOptions, Page } from "../../../constants/constants";
import moment from "moment-timezone";
import "../search/search.less";
import {
  SearchstateSelector,
  clearSuccess,
  clearState,
  clearString,
  clearResults,
  clearAdvanceSearchData,
  clearSearchScroll,
  clearQuickSearchValue,
} from "../../../../redux/reducers/search/searchReducer";
import {
  getallSearchStringRowA,
  getallSearchStringRowB,
} from "../search/searchString";
import { BookRow } from "../curatedList/bookList/bookRowItem";
import { addItemtoCart } from "../../../../redux/actions/carts/cartAction";
import { Book } from "../../../models/book/book";
import { useLocation } from "react-router-dom";
import {
  getSaveSearchResult,
  onStringBackFillForm,
  fetchIsbnSearcNew,
  searchFilters,
  fetchColecSearchResults,
  saveTargetAudience,
  cleraFitureList,
  deleteTargetAudience,
  getUnmatchValues,
  fetchColecSearchResultsExports,
  getSearchString,
  fetchIsbnSearchExport,
} from "../../../../redux/actions/search/searchActions";
import { NewCartModal } from "../carts/newCartModal";
import {
  BookStateSelector,
  clearCartsByBookId,
  clearSelectedChkBox,
  emtySelectedChkBox,
} from "../../../../redux/reducers/books/bookListReducer";
import { Messages } from "../../../constants/messages";
import { replaceAll } from "../../../../utility/appUtil";
import "./collection.less";
import Axios from "axios";
import ClearAudienceModel from "./clearAudienceModel";
import ExportIcon from "../../../../assets/dummy/ExportIcon.png";
import ChangeAudience from "../../../../assets/dummy/ChangeAudience.png";
import {
  ShelfstateSelector,
  clearAaddSingleShelv,
  clearAddItemtoShelf,
  clearShelvFormState,
} from "../../../../redux/reducers/shelves/shelfReducer";
import {
  onSelectShelve,
  fetchShelves,
  addItemtoShelve,
} from "../../../../redux/actions/shelves/shelfAction";
import {
  pageRememberBooklist,
  selectAllRemember,
  setCheckedValues,
  setSelectAllChkBox,
  setSelectedTitles,
  setGlobalCopy,
  isPageChange,
} from "../../../../redux/actions/books/bookActions";
import { LoadingOutlined } from "@ant-design/icons";
import { SideBarStateSelector, retainPageSize } from "../../../../redux/reducers/app/sideBarReducer";
import { switchSearchSideBar } from "../../../../redux/actions/app/appActions";
import SpinnerLoader from "../../stateless/common/spinnerLoader";
import { checkCollectPayloadNReForm } from "../../../../utility/searchUtil";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";

export const CollectionSearchResults = React.memo((props: any) => {
  const history = useHistory();
  const location = useLocation<any>();
  const dispatch = useDispatch();

  const {
    carts,
    addedItem,
    activeCartArray,
    addItemtoCartFormstate,
    ErrorCart,
  } = useSelector(CartStateSelector);
  const { showSearchSideBar, collapseSideBar } = useSelector(SideBarStateSelector);
  const {
    searchCollecFormState,
    totalCollecResults,
    backFormFill,
    selectedFilters,
    collecSearchResults,
    targetAudFormState,
    targetAudSav,
    scrollHeight,
    totalIsbnNumbers,
    totalIsbnprice,
    RecordsNotMatched,
  } = useSelector(SearchstateSelector);
  const { appUser } = useSelector(userStateSelector);
  const {
    booklistCurrentPage,
    checkedValues,
    selectAllChkBox,
    globalSelectAll,
    selectedTitles,
    bookGlobalValue,
    isPageChanges,
  } = useSelector(BookStateSelector);
  const {
    shelves,
    selectedShelve,
    addItemShelf,
    shelvFormState,
    addSingleItemToShelf,
    addItemShelfAlone,
    addItemShelfDone,
  } = useSelector(ShelfstateSelector);
  const { retainedPageSize } = useSelector(SideBarStateSelector);
  var permissions =
    appUser && appUser.libraryData
      ? appUser.libraryData[0].FeaturePermissions
      : "";
  var shelfAdmin = appUser ? appUser?.isShelfAdmin : "";
  // const myRef = useRef<any>(null);
  const [checked, setChecked] = useState<any>([]);
  const [checkedPayload, setCheckedPayload] = useState<any>([]);

  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [editSelected, setEditSelected] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [eptyCheckedArry, setEptyCheckedArry] = useState<boolean>(false);
  const [sort, setSort] = useState(
    selectedFilters.sort !== undefined ? selectedFilters.sort : "ASC"
  );
  const [sortIcon, setSortIcon] = useState(
    selectedFilters.sortIcon !== undefined ? selectedFilters.sortIcon : ascIcon
  );
  const [sortOrder, setSortOrder] = useState(
    selectedFilters.sortOrder !== undefined
      ? selectedFilters.sortOrder
      : "Default"
  );
  const csvLinkHit = useRef<any>(null);
  const [strinDetails, setStrinDetails] = useState<any>();
  const [bookList, setBookList] = useState<Book[]>([]);
  const [quickSearch, setQuickSearch] = useState<any>();
  const [copies, setCopies] = useState<any>(1);
  const [globalCopies, setGlobalCopies] = useState<any>(1);
  const [bookListCopies, setBookListCopies] =
    useState<any>(collecSearchResults);
  const [inputSearchStringA, setInputSearchStringA] = useState<any>(1);
  const [editModeAduience, setEditModeAduience] = useState<boolean>(false);
  const [editSelectAll, setEditSelectAll] = useState<boolean>(false);
  const [isbnInputVal, setIsbnInputVal] = useState<any>([]);
  const [inputSearchStringB, setInputSearchStringB] = useState<any>(1);
  const [showModal, setShowModal] = useState(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const [backString, setBackString] = useState<any>();
  const [searchDetails, setSearchDetails] = useState(
    location?.state?.searchDetails
  );
  const [isbnSearch, setIsbnSearch] = useState(location?.state?.isbnSearch);
  const [shelvesData, setShelvesData] = useState<any>(shelves);
  const [activeShelveName, setactiveShelveName] = useState<any>(
    selectedShelve !== undefined ? selectedShelve : ""
  );
  const [activeShelveCount, setActiveShelveCount] = useState<any>();
  const [addToCartNow, setAdToCartNow] = useState<boolean>(false);
  const [cartDescription, setCartDescription] = useState<any>([]);
  const [bulkAddShelf, setBulkAddShelf] = useState<any>([]);
  const [scrollValue, setScrollValue] = useState(0);
  var dateFormat = appUser?.libraryData
    ? appUser.libraryData[0]?.DateFormat.toUpperCase()
    : "";
  const backText = "< Back";
  var filteredCarts = permissions.includes("AudioBooks")
    ? carts.filter((data: Cart) => {
        return (
          data.CartOwner === appUser?.email || data.IsCommunityCart === true
        );
      })
    : carts.filter((data: Cart) => {
        return (
          (data.CartOwner === appUser?.email ||
            data.IsCommunityCart === true) &&
          !data.CartTypeCode?.includes("Audio")
        );
      });

  var lib_Id =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;

  const myRequest = new XMLHttpRequest();
  myRequest.open("POST", "getCollectionSearchResults");
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState<number>(
    booklistCurrentPage?.start !== undefined
      ? booklistCurrentPage?.start
      : Page.DEFAULT_START
  );

  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [selectedAud, setSelectedAud] = useState<any>([]);
  const [updatingData, setUpdatingData] = useState(false);
  const [unSelectAll, setUnSelectAll] = useState<boolean>(true);
  const [noData, setNoData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(
    booklistCurrentPage?.currentPage !== undefined
      ? booklistCurrentPage?.currentPage
      : 1
  );
  const [pageSize, setPageSize] = useState<number>(
    retainedPageSize ?? 100
  );
  const [csvData, setCsvData] = useState<any>([]);
  const [downloadingCsv, setDownloadingCsv] = useState<any>(false);
  const [exportIt, setExportIt] = useState<boolean>(false);
  const [lastCount, setLastCount] = useState(start + pageSize - 1);
  const [onpageLoad, setOnPageLoad] = useState<boolean>(false);
  const optionList = [10, 20, 50, 100, 250, 500];
  const [clearModal, setClearModal] = useState<boolean>(false);
  const [selectedChunkData, setSelectedChunkData] = useState<any>([]);
  const [confirmClearAudience, setConfirmClearAudience] =
    useState<boolean>(false);
  const [paginationHide, setPaginationHide] = useState(false);
  const [exportData, setExportData] = useState<any>([]);
  const [selectAlChecked, setSelectAlChecked] = useState<boolean>(true);
  const [cancelAll, setCancelAll] = useState(false);
  const [globalC, setGlobalC] = useState<number>(1);
  const [globalPrevious, setGlobalPrevious] = useState<any>();
  const [totalQuantiy, setTotalQuantiy] = useState<any>([]);
  const [screenSize, getDimension] = useState<any>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const camefromAdvancedSearch = location?.state?.fromAdvancedSearch;
  const headers = [
    { label: "ISBN", key: "Eisbn" },
    { label: "Title", key: "refineTitle" },
    { label: "Authors", key: "refineAuther" },
    { label: "Series", key: "Series" },
    { label: "Series Number", key: "NumberInSeries" },
    { label: "Narrator", key: "Narrator" },
    { label: "Publisher", key: "Publisher" },
    { label: "Content Provider", key: "Provider" },
    { label: "Format", key: "FileFormatName" },
    { label: "Publication Date", key: "PublicationDate" },
    { label: "Language", key: "Language" },
    { label: "Category", key: "category" },
    { label: "Sub Category", key: "subCategory" },
    { label: "Street Date", key: "StreetDate" },
    { label: "Price", key: "RetailPrice" },
    { label: "Added to CAT", key: "AddedToCatalogDate" },
    { label: "Owned", key: "NumberOfCopies" },
    { label: "Holds", key: "NumberOfHolds" },
    { label: "Loans", key: "NumberOfLoans" },
    { label: "In Circ", key: "NumberOfCurrentLoans" },
    { label: "Suggested", key: "NumberOfWish" },
    { label: "Holds Ratio", key: "HoldRatio" },
    { label: "Ordered", key: "NumberOnOrder" },
    { label: "Shelved", key: "NumberOfShelves" },
    { label: "In Cart", key: "NumberInCarts" },
    { label: "Last Purchased Date", key: "LastPurchasedDate" },
    { label: "Last Checked Out Date", key: "LastCheckoutDate" },
    { label: "Is PPU", key: "IsPPU" },
    { label: "PPU Price", key: "PPUPrice" },
    { label: "cloudLink Shareable Copies", key: "GroupSharableCopies" },
    { label: "cloudLink Current Holds", key: "GroupHoldCount" },
  ];

  const config: any = {
    key: "downloading",
    type: "loading",
    content: "Exporting CSV...",
    duration: 0,
    className: "custom-ant-message-container",
  };

  const hideLoading = () => message.destroy("downloading");
  const showLoading = () => {
    message.open(config);
  };

  const csvExportList = {
    filename: `collectionList.csv`,
    headers: headers,
    data: csvData,
  };

  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr).tz("Etc/GMT").format(dateFormat);
    return currentDate;
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    if (allSelected) {
      if (!selectAlChecked) {
        if (selectAllChkBox) {
          onConfirm();
        }
      } else {
        onConfirm();
        setChecked([]);
        setCheckedPayload([])
        setSelectAlChecked(false);
        dispatch(setSelectAllChkBox(false))      }
    }
    setBookListCopies(collecSearchResults);
  }, [collecSearchResults]);

  const onClickEditMode = () => {
    setEditModeAduience(true);
    setAllSelected(true);
    setEditSelected(true);
    setEditSelectAll(true);
    // onEditCancel();
  };

  useEffect(() => {
    if (editModeAduience && allSelected) {
      onEditCancel();
    }
  }, [editModeAduience, allSelected]);

  const onEditCancel = () => {
    setEditModeAduience(true);
    setAllSelected(true);
    setEditSelected(true);
    setEditSelectAll(true);
    setSelectedChunkData([]);
    setBulkAddShelf([]);
    setChecked([]);
    setCopies(1);
    setGlobalCopies(1);
    dispatch(setGlobalCopy(1));
    setCheckedPayload([]);
    setSelectAlChecked(false);
    dispatch(setSelectAllChkBox(false));
  };

  const functionForExport = (data: any) => {
    if (data?.error) {
      setDownloadingCsv(false);
      hideLoading();
      setTimeout(() => {
        showNotification(data.error, "error");
      }, 500);
      setExportIt(false);
    } else {
      setCsvData(data);
      setExportIt(true);
    }
  };

  useEffect(() => {
    if (exportIt && csvData) {
      hideLoading();
      setDownloadingCsv(false);
      setTimeout(() => {
        // showNotification("CSV Successfully Downloaded", "success");
        csvLinkHit?.current?.link?.click();
      }, 1000);
      setExportIt(false);
    }
  }, [exportIt, csvLinkHit, csvData]);

  const handleFetchExport = () => {
    let source = Axios.CancelToken.source();
    setDownloadingCsv(true);
    showLoading();
    if(location?.state?.cameFromIsbn){
      let sortOrderNew = sortOrder !== "Sort By" ? sortOrder : "";
      let isbnSearchNew = {
        ...isbnSearch,
        sortField: sortOrderNew && sortOrderNew !== "Default"?sortOrderNew:"",
        sortDirection: sort,
        recordCount: 1000 
      };
      dispatch(
        fetchIsbnSearchExport({
          payload: { ...isbnSearchNew, SearchType: "CollectionSearch" },
          signal: source,
          dateModify,
          functionForExport,
        })
      );
    }
    else{
      dispatch(
        fetchColecSearchResultsExports({
          payload: checkCollectPayloadNReForm({ ...searchDetails, recordCount: 1000,sortDirection: sort}),
          signal: source,
          dateModify,
          functionForExport,
        })
      );
    }
  };

  const pageOptions = React.useMemo(() => (
    <Menu className="header-dropdown">
      <Menu.Item key="page-size">
        <Row>
          <Col style={{ width: "20px" }}>
            <img style={{ width: "14px", height: "14px" }} src={list} alt=" " />
          </Col>
          <Col flex="auto" style={{ paddingLeft: "5px" }}>
            Page Size
          </Col>
        </Row>
      </Menu.Item>
      {optionList.map((data: number, index: number) => (
        <Menu.Item key={index} onClick={() => changePageSize(data)}>
          <Row>
            <Col style={{ width: "20px" }}>
              {pageSize === data ? (
                <img
                  style={{ width: "14px", height: "14px" }}
                  src={asteriskIcon}
                  alt="*"
                />
              ) : (
                " "
              )}
            </Col>
            <Col style={{ paddingLeft: "5px" }}>{data}</Col>
          </Row>
        </Menu.Item>
      ))}
      <Menu.Item>
        <Row className="coll-csv-export-row">
          <Col style={{ width: "20px" }}>
            <img
              style={{ width: "14px", height: "14px", marginTop: "-3px" }}
              src={ExportIcon}
              alt=" "
            />
          </Col>
          <Col>
            <div className="exportColListCsv export-list-search">
              <CSVLink
                ref={csvLinkHit}
                className="export-list-link hidden"
                {...csvExportList}
                style={{ visibility: "hidden" }}
              ></CSVLink>
              <button
                className={`collection-export-list-link ${
                  downloadingCsv ? "not-allowed" : "cursor-pointer"
                }`}
                onClick={handleFetchExport}
                disabled={downloadingCsv}
              >
                Export List 1000 results as CSV
              </button>
            </div>
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item key="Change-Audience" onClick={onClickEditMode}>
        <Row>
          <Col style={{ width: "20px", display: "contents" }}>
            <img
              style={{ width: "14px", height: "14px" }}
              src={ChangeAudience}
              alt=" "
            />
          </Col>
          <Col className="export-list-link change-audience-collection">
            Change Audience
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  ), [optionList, pageSize, csvLinkHit, csvExportList, downloadingCsv, handleFetchExport, onClickEditMode]);
  

  var currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";

      const userCarts = (
        <Menu className="header-dropdown">
          {filteredCarts.map((data: Cart, index: number) => {
            return (
              <Menu.Item key={index} onClick={() => onAddtoCart(data)}>
                <p className="dropDown-title">{data.CartName}</p>
                <span className="dropDown-content">
                  {currency}{" "}
                  {data.TotalCartAmount.toFixed(2)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  . {data.NumberOfTitles} Titles
                </span>
              </Menu.Item>
            );
          })}
          <Menu.Divider />
          <Menu.Item
            key="All-Carts-Link"
            className="dropDown-link"
            onClick={() => {
              history.push(AppRoutes.CART);
              dispatch(setTab("2"));
            }}
          >
            <p>All Carts</p>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="New-cart-Link"
            className="dropDown-link"
            onClick={() => setShowModal(true)}
          >
            <p>New Cart</p>
          </Menu.Item>
        </Menu>
      );

  const updateAndLoad = () => {
    setDataLoading(true);
    setUpdatingData(true);
  };

  useEffect(() => {
    let end = start + pageSize - 1;
    if (totalCollecResults < end) {
      setLastCount(totalCollecResults);
    } else {
      setLastCount(end);
    }
    // if (totalCollecResults && start) {
    //   if (
    //     totalCollecResults !== 0 &&
    //     start !== 1 &&
    //     totalCollecResults < start
    //   ) {
    //     setStart(1);
    //     setSearchDetails({ ...searchDetails, recordStart: 1 });
    //     setCurrentPage(1);
    //   }
    // }
  }, [start, pageSize, totalCollecResults]);

  useEffect(() => {
    if (ErrorCart !== "" && !loading) {
      dispatch(clearErrorCart());
      dispatch(clearAdded());
    } else {
      return undefined;
    }
  }, [ErrorCart, loading]);

  const handleCheckboxChange = (id: any, isChecked: any) => {
    const newCheckedValues = isChecked == true
    ? [...checkedValues, id]
    : checkedValues.filter((value: any) => value !== id);
    dispatch(setCheckedValues(newCheckedValues));
    let checkedArray: any = [];
    let checkedPayload: any = [];
    let checkedShelfPayload: any = [];
    bookList?.map((book: any) => {
      if (newCheckedValues?.includes(book?.CatalogItemId)) {
        checkedArray?.push(book.CatalogItemId);
        const index = bookListCopies?.find(
          (data: any) => book?.CatalogItemId === data?.CatalogItemId
        );
        const quant = index?.Quantity
          ? index.Quantity + (globalCopies - 1)
          : globalCopies;
        checkedPayload?.push({
          CatalogItemId: book.CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: quant,
          SaveAction: "S",
        });
        checkedShelfPayload?.push({
          CatalogItemId: book?.CatalogItemId,
          Title: book?.Title,
          SubTitle: book?.SubTitle,
          Authors: book?.Authors,
          EISBN: book?.Eisbn,
          Series: book?.Series,
          NumberInSeries: book?.NumberInSeries,
          Narrator: book?.Narrator,
          EditionType: book?.EditionType,
          Imprint: book?.Imprint,
          ContentProviderId: book?.ContentProviderId,
          ContentProviderName: book?.ContentProviderName,
          MediaTypeId: book?.MediaTypeId,
          FileFormatName: book?.FileFormatName,
          PublicationDate: book?.PublicationDate,
          Language: book?.Language,
          StreetDate: book?.StreetDate,
          ImageId: book?.ImageId,
          RetailPrice: book?.RetailPrice,
          AddedToCatalogDate: book?.AddedToCatalogDate,
          ItemState: book?.ItemState,
          NumberOfCopies: book?.NumberOfCopies,
          NumberOfHolds: book?.NumberOfHolds,
          NumberOfLoans: book?.NumberOfLoans,
          NumberOfCurrentLoans: book?.NumberOfCurrentLoans,
          NumberOfWish: book?.NumberOfWish,
          NumberInCarts: book?.NumberInCarts,
          NumberOnOrder: book?.NumberOnOrder,
          NumberOfShelves: book?.NumberOfShelves,
          LastPurchasedDate: book?.LastPurchasedDate,
          Categories: book?.Categories,
          CategoryList: book?.CategoryList,
          AudienceData: book?.AudienceData,
          IsPPU: book?.IsPPU,
          PPUPrice: book?.PPUPrice,
          CheckOutLimit: book?.CheckOutLimit,
          TimeSpanLimit: book?.TimeSpanLimit,
          HoldRatio: book?.HoldRatio,
          LastCheckoutDate: book?.LastCheckoutDate,
          Quantity: quant,
          Rank: 0,
          checked: true,
        });
      }
    });
    setChecked(newCheckedValues);
    setCheckedPayload(checkedShelfPayload);
    setBulkAddShelf(checkedShelfPayload);  
  };

  const handleSortFilter = (value: any) => {
    updateAndLoad();
    setSortOrder(value);
    if (searchDetails) {
      setSearchDetails({
        ...searchDetails,
        sortField: value && value === "Default" ? "":value,
        recordStart: start,
      });
    } else if (isbnSearch) {
      setIsbnSearch({
        ...isbnSearch,
        sortField:value && value === "Default" ? "":value,
        recordStart: start,
      });
    }
  };
  const onSortClick = () => {
    updateAndLoad();
    if (sort === "ASC") {
      setSort("DESC");
      if (searchDetails) {
        setSearchDetails({
          ...searchDetails,
          sortDirection: "DESC",
          recordStart: start,
        });
      } else if (isbnSearch) {
        setIsbnSearch({
          ...isbnSearch,
          sortDirection: "DESC",
          recordStart: start,
        });
      }
      setSortIcon(descIcon);
    } else {
      setSort("ASC");
      if (searchDetails) {
        setSearchDetails({
          ...searchDetails,
          sortDirection: "ASC",
          recordStart: start,
        });
      } else if (isbnSearch) {
        setIsbnSearch({
          ...isbnSearch,
          sortDirection: "ASC",
          recordStart: start,
        });
      }
      setSortIcon(ascIcon);
    }
  };
  const onValueChange = (value?: any) => {
    setGlobalPrevious(globalC);
    setCopies(value);
    setGlobalC(value);
    if (totalQuantiy?.length || checkedPayload.length) {
      var ttqs = {
        globalCopie: value,
        amtChangCopie: totalQuantiy.length != 0 ? totalQuantiy?.map((item: any) => {
          let ay: any;
          if (checkedValues?.includes(item?.CatalogItemId)) {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity + (value - copies),
            };
          } else {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity,
            };
          }
          return ay;
        }) : checkedPayload?.map((item: any) => {
          let ay: any;
          if (checkedValues?.includes(item?.CatalogItemId)) {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity + (value - copies),
            };
          } else {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity,
            };
          }
          return ay;
        }),
      };
      if (ttqs) {
        dispatch(setSelectedTitles(ttqs));
        setTotalQuantiy(ttqs?.amtChangCopie);
      }
    }

    setBulkAddShelf(
      bulkAddShelf?.map((book: any) => {
        return { ...book, Quantity: value };
      })
    );
  };

  useEffect(() => {
    setInputSearchStringA(getallSearchStringRowA(strinDetails));
    setInputSearchStringB(getallSearchStringRowB(strinDetails));
    setBackString(strinDetails);
  }, [strinDetails]);

  const goBack = () => {
    dispatch(pageRememberBooklist(location?.state?.gaString?.currentPage));
    dispatch(
      getSaveSearchResult({ searchParams: location?.state?.searchDetails })
    );
    dispatch(getSearchString(location?.state?.gaString?.gaData));
    history.goBack();
    dispatch(clearSearchScroll());
    onCancel();
    dispatch(onStringBackFillForm(backString));
    dispatch(clearQuickSearchValue());
    if (backFormFill?.isbn !== undefined) {
      dispatch(setTab(2));
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    setStrinDetails({ ...location?.state?.gaString?.gaData });
    setSearchDetails({ ...location?.state?.searchDetails });
    if (booklistCurrentPage?.currentPage) {
      setCurrentPage(booklistCurrentPage?.currentPage);
      setStart(booklistCurrentPage?.start);
    }
  }, [location]);

  const showNotification = (content: any, type: any) => {
    if (type === "loading") {
      setLoading(true);
      message
        .loading({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      type === "success"
        ? message.success({
            content: content,
            key: "notificationMessage",
          })
        : message.error({
            content: content,
            key: "error",
          });
    }
  };

  const onAddtoCart = (cart: Cart) => {
    setAdToCartNow(true);
    setCartDescription(cart);
  };

  useEffect(() => {
    if (addToCartNow && cartDescription && checkedPayload) {
      const booksMap: Map<number, Book> = new Map(
        selectedTitles?.amtChangCopie?.map((b: Book) => [b.CatalogItemId, b])
      );
      let checkedPayloads: any = [];
      if(checkedPayload.length > 0) {
      for (var i: any = 0; i < checkedPayload.length; i++) {
        var inputQuantity = booksMap.get(checkedPayload[i].CatalogItemId);
        checkedPayloads?.push({
          CatalogItemId: checkedPayload[i].CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: inputQuantity?.Quantity ?? globalC,
          SaveAction: "S",
        });
      }
    } else if(totalQuantiy.length > 0){
      for (var i: any = 0; i < totalQuantiy.length; i++) {
        var inputQuantity = booksMap.get(totalQuantiy[i].CatalogItemId);
        checkedPayloads?.push({
          CatalogItemId: totalQuantiy[i].CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: inputQuantity?.Quantity ?? globalC,
          SaveAction: "S",
        });
      }
    }

      var lib_Id =
        appUser && appUser?.libraryData
          ? appUser?.libraryData[0]?.LibraryId
          : 0;
      var payloadData = {
        IsCommunityCart: cartDescription?.IsCommunityCart,
        LibraryId: lib_Id,
        SaveAction: "S",
        CartDescription: cartDescription?.CartDescription,
        CartId: cartDescription?.CartId,
        CartName: cartDescription?.CartName,
        AssociateShelfId: cartDescription?.AssociateShelfId,
        CartDetail: checkedPayloads,
      };
      setTimeout(() => {
        if (checkedPayloads?.length > 0) {
          dispatch(addItemtoCart(payloadData));
          showNotification(
            `Adding items to ${cartDescription?.CartName}`,
            "loading"
          );
        } else {
          showNotification(`No items are selected`, "error");
        }
      }, 100);
      const msg = replaceAll(
        /\{0\}/gi,
        Messages.ADD_TO_CART,
        `${cartDescription?.CartName}`
      );
      showNotification(msg, "loading");
      setAdToCartNow(false);
      setCartDescription([]);
    }
  }, [checkedPayload, addToCartNow, cartDescription, appUser]);

  // const onEditModeConfirm = () => {
  //   setEditModeAduience(true);
  //   setAllSelected(true);
  //   setEditSelected(false);
  //   setChecked(
  //     bookList?.map((data: Book) => {
  //       return data.CatalogItemId;
  //     })
  //   );
  // };

  const onCancel = () => {
    setChecked([]);
    setCopies(1);
    setGlobalCopies(1);
    dispatch(setGlobalCopy(1));
    setCheckedPayload([]);
    setAllSelected(false);
    setSelectedChunkData([]);
    setBulkAddShelf([]);
    dispatch(clearSelectedChkBox());
   dispatch(selectAllRemember(false));

    setGlobalC(1);
  };
  const onSelectAlCheckBox = (e: any) => {
    if (e?.target?.checked) {
      setSelectAlChecked(true);
      dispatch(setSelectAllChkBox(true));
    } else {
      setSelectAlChecked(false);
      dispatch(setSelectAllChkBox(false));
    }
  };

  const onSelectAllCancel = () => {
    onCancel();
    dispatch(setSelectedTitles([]));
    setCancelAll(true);
    setGlobalPrevious(1);
  };

  const makePayload = (book: any) => {
    const booksMap: Map<number, Book> = new Map(
      selectedTitles?.amtChangCopie?.map((b: Book) => [b.CatalogItemId, b])
    );
    let checkedPayloads: any = [];
    for (var i: any = 0; i < checkedPayload.length; i++) {
      var inputQuantity = booksMap.get(checkedPayload[i].CatalogItemId);
      checkedPayloads?.push({
        CatalogItemId: checkedPayload[i].CatalogItemId,
        Quantity: inputQuantity?.Quantity ?? globalC,
      });
    }
    // eslint-disable-next-line eqeqeq
    const index = checkedPayloads.find(
      (data: any) => book?.CatalogItemId === data?.CatalogItemId
    );
    const quant = index?.Quantity;
    return {
      CatalogItemId: book?.CatalogItemId,
      Title: book?.Title,
      SubTitle: book?.SubTitle,
      Authors: book?.Authors,
      EISBN: book?.Eisbn,
      Series: book?.Series,
      NumberInSeries: book?.NumberInSeries,
      Narrator: book?.Narrator,
      EditionType: book?.EditionType,
      Imprint: book?.Imprint,
      ContentProviderId: book?.ContentProviderId,
      ContentProviderName: book?.ContentProviderName,
      MediaTypeId: book?.MediaTypeId,
      FileFormatName: book?.FileFormatName,
      PublicationDate: book?.PublicationDate,
      Language: book?.Language,
      StreetDate: book?.StreetDate,
      ImageId: book?.ImageId,
      RetailPrice: book?.RetailPrice,
      AddedToCatalogDate: book?.AddedToCatalogDate,
      ItemState: book?.ItemState,
      NumberOfCopies: book?.NumberOfCopies,
      NumberOfHolds: book?.NumberOfHolds,
      NumberOfLoans: book?.NumberOfLoans,
      NumberOfCurrentLoans: book?.NumberOfCurrentLoans,
      NumberOfWish: book?.NumberOfWish,
      NumberInCarts: book?.NumberInCarts,
      NumberOnOrder: book?.NumberOnOrder,
      NumberOfShelves: book?.NumberOfShelves,
      LastPurchasedDate: book?.LastPurchasedDate,
      Categories: book?.Categories,
      CategoryList: book?.CategoryList,
      AudienceData: book?.AudienceData,
      IsPPU: book?.IsPPU,
      PPUPrice: book?.PPUPrice,
      CheckOutLimit: book?.CheckOutLimit,
      TimeSpanLimit: book?.TimeSpanLimit,
      HoldRatio: book?.HoldRatio,
      LastCheckoutDate: book?.LastCheckoutDate,
      Quantity: quant,
      Rank: 0,
      checked: true,
    };
  };

  const onPageChange = (page: any, pageSize: any) => {
    setBookList([]);
    if (!searchCollecFormState.loading) {
      const pageStart = (page - 1) * pageSize + 1;
      setStart(pageStart);
      if (searchDetails) {
        setSearchDetails({
          ...searchDetails,
          recordStart: pageStart,
          recordCount: pageSize,
        });
      } else if (isbnSearch) {
        setIsbnSearch({
          ...isbnSearch,
          recordStart: pageStart,
          recordCount: pageSize,
        });
      }
      dispatch(
        isPageChange({
          isPageChanges: true,
          isSelectAll: false,
        })
      );
      setCurrentPage(page);
      setUnSelectAll(false);
      onSelectAllCancel();
      dispatch(clearSearchScroll());
    }
  };

  useEffect(() => {
    if (!selectAlChecked) {
      setChecked([]);
      setCheckedPayload([]);
      setBulkAddShelf([]);
    } else {
      let checkedArray: any = [];
      let checkedPayload: any = [];
      let checkedShelfPayload: any = [];
      collecSearchResults?.map((book: any) => {
        // if (checkedValues?.length > 0) {
        //   if (checkedValues?.includes(book?.CatalogItemId)) {
        //     checkedArray?.push(book.CatalogItemId);
        //     checkedPayload?.push({
        //       CatalogItemId: book.CatalogItemId,
        //       BuyerId: "",
        //       BuyerCartDetailId: 0,
        //       Quantity: copies,
        //       SaveAction: "S",
        //     });
        //     checkedShelfPayload?.push(makePayload(book));
        //   }
        // } else 
          checkedArray?.push(book.CatalogItemId);
          checkedPayload?.push({
            CatalogItemId: book.CatalogItemId,
            BuyerId: "",
            BuyerCartDetailId: 0,
            Quantity: copies,
            SaveAction: "S",
          });
          checkedShelfPayload?.push(makePayload(book));
        }
      );
      setChecked(checkedArray);
      setCheckedPayload(checkedPayload);
      setBulkAddShelf(checkedShelfPayload);
    }
  }, [selectAlChecked]);

  const onConfirm = () => {
    setSelectAlChecked(true);
    setCopies(1);
    setCancelAll(false);
    let checkedArray: any = [];
    let checkedPayload: any = [];
    let checkedShelfPayload: any = [];
    collecSearchResults?.map((book: any) => {
      if (checkedValues?.length > 0) {
        if (checkedValues?.includes(book?.CatalogItemId)) {
          const index = bookListCopies.find(
            (data: any) => book?.CatalogItemId === data?.CatalogItemId
          );
          checkedArray?.push(book.CatalogItemId);
          checkedPayload?.push({
            CatalogItemId: book.CatalogItemId,
            BuyerId: "",
            BuyerCartDetailId: 0,
            Quantity: index?.Quantity
              ? index?.Quantity + (globalCopies - 1)
              : globalCopies,
            SaveAction: "S",
          });
          checkedShelfPayload?.push(makePayload(book));
        }
      } else {
        checkedArray?.push(book.CatalogItemId);
        const index = bookListCopies.find(
          (data: any) => book?.CatalogItemId === data?.CatalogItemId
        );
        checkedPayload?.push({
          CatalogItemId: book.CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: index?.Quantity
            ? index?.Quantity + (globalCopies - 1)
            : globalCopies,
          SaveAction: "S",
        });
        checkedShelfPayload?.push(makePayload(book));
      }
    });
    if (eptyCheckedArry) {
      setChecked([]);
      setEptyCheckedArry(false);
    } else {
      setChecked(checkedArray);
    }
    setCheckedPayload(checkedPayload);
    setAllSelected(true);
    setBulkAddShelf(checkedShelfPayload);
    dispatch(selectAllRemember(true));
  };

  useEffect(() => {
    if (addItemtoCartFormstate?.isError && !loading) {
      dispatch(clearAdded());
    }
    if (addItemtoCartFormstate?.isSuccess && globalSelectAll) {
      setEptyCheckedArry(true);
      dispatch(emtySelectedChkBox());
      onConfirm();
    }
    if (globalSelectAll === true) {
      if (addItemtoCartFormstate?.isSuccess && globalSelectAll) {
        setEptyCheckedArry(true);
        dispatch(emtySelectedChkBox());
        onConfirm();
      } else {
        onConfirm();
      }
    }
    //for shelv
    if (addItemShelf?.isError && !loading) {
      dispatch(clearAddItemtoShelf());
    }
    if (addItemShelf?.isSuccess && globalSelectAll) {
      dispatch(fetchShelves(lib_Id));
      setEptyCheckedArry(true);
      dispatch(emtySelectedChkBox());
      onConfirm();
    }
    if (globalSelectAll === true) {
      if (addItemShelf?.isSuccess && globalSelectAll) {
        setEptyCheckedArry(true);
        dispatch(emtySelectedChkBox());
        onConfirm();
      } else {
        onConfirm();
      }
    }
  }, [addItemtoCartFormstate, globalSelectAll, addItemShelf]);

  useEffect(() => {
    if (addedItem && !loading) {
      showNotification(Messages.ITEM_ADDED, "success");
      setGlobalC(1);
      setTotalQuantiy([]);
      dispatch(setSelectedTitles([]));
      if (allSelected) {
        updateAndLoad();
        setAllSelected(false);
        if (searchDetails) {
          setSearchDetails({
            ...searchDetails,
            recordStart: Page.DEFAULT_START,
          });
        } else if (isbnSearch) {
          setIsbnSearch({ ...isbnSearch, recordStart: Page.DEFAULT_START });
        }
      }
      dispatch(clearAdded());
    }
    //for shelv
    if (addItemShelfDone && !loading) {
      showNotification(Messages.ITEM_ADDED, "success");
      setGlobalC(1);
      dispatch(setSelectedTitles([]));
      if (allSelected) {
        updateAndLoad();
        setAllSelected(false);
        if (searchDetails) {
          setSearchDetails({
            ...searchDetails,
            recordStart: Page.DEFAULT_START,
          });
        } else if (isbnSearch) {
          setIsbnSearch({ ...isbnSearch, recordStart: Page.DEFAULT_START });
        }
      }
      dispatch(clearAddItemtoShelf());
    }
  }, [addedItem, loading, addItemShelfDone]);

  useEffect(() => {
    if (addSingleItemToShelf) {
      showNotification(Messages.ITEM_ADDED, "success");
      dispatch(fetchShelves(lib_Id));
      dispatch(clearAaddSingleShelv());
    }
  }, [addSingleItemToShelf]);

  const onCustomSelect = (checkedValues: any) => {
    // let checkedArray: any = [];
    // let checkedPayload: any = [];
    // let checkedShelfPayload: any = [];
    // bookList?.map((book: any) => {
    //   if (checkedValues?.includes(book?.CatalogItemId)) {
    //     checkedArray?.push(book.CatalogItemId);
    //     const index = bookListCopies?.find(
    //       (data: any) => book?.CatalogItemId === data?.CatalogItemId
    //     );
    //     const quant = index?.Quantity
    //       ? index.Quantity + (globalCopies - 1)
    //       : globalCopies;
    //     checkedPayload?.push({
    //       CatalogItemId: book.CatalogItemId,
    //       BuyerId: "",
    //       BuyerCartDetailId: 0,
    //       Quantity: quant,
    //       SaveAction: "S",
    //     });
    //     checkedShelfPayload?.push({
    //       CatalogItemId: book?.CatalogItemId,
    //       Title: book?.Title,
    //       SubTitle: book?.SubTitle,
    //       Authors: book?.Authors,
    //       EISBN: book?.Eisbn,
    //       Series: book?.Series,
    //       NumberInSeries: book?.NumberInSeries,
    //       Narrator: book?.Narrator,
    //       EditionType: book?.EditionType,
    //       Imprint: book?.Imprint,
    //       ContentProviderId: book?.ContentProviderId,
    //       ContentProviderName: book?.ContentProviderName,
    //       MediaTypeId: book?.MediaTypeId,
    //       FileFormatName: book?.FileFormatName,
    //       PublicationDate: book?.PublicationDate,
    //       Language: book?.Language,
    //       StreetDate: book?.StreetDate,
    //       ImageId: book?.ImageId,
    //       RetailPrice: book?.RetailPrice,
    //       AddedToCatalogDate: book?.AddedToCatalogDate,
    //       ItemState: book?.ItemState,
    //       NumberOfCopies: book?.NumberOfCopies,
    //       NumberOfHolds: book?.NumberOfHolds,
    //       NumberOfLoans: book?.NumberOfLoans,
    //       NumberOfCurrentLoans: book?.NumberOfCurrentLoans,
    //       NumberOfWish: book?.NumberOfWish,
    //       NumberInCarts: book?.NumberInCarts,
    //       NumberOnOrder: book?.NumberOnOrder,
    //       NumberOfShelves: book?.NumberOfShelves,
    //       LastPurchasedDate: book?.LastPurchasedDate,
    //       Categories: book?.Categories,
    //       CategoryList: book?.CategoryList,
    //       AudienceData: book?.AudienceData,
    //       IsPPU: book?.IsPPU,
    //       PPUPrice: book?.PPUPrice,
    //       CheckOutLimit: book?.CheckOutLimit,
    //       TimeSpanLimit: book?.TimeSpanLimit,
    //       HoldRatio: book?.HoldRatio,
    //       LastCheckoutDate: book?.LastCheckoutDate,
    //       Quantity: quant,
    //       Rank: 0,
    //       checked: true,
    //     });
    //   }
    // });
    // setChecked(checkedValues);
    // setCheckedPayload(checkedShelfPayload);
    // setBulkAddShelf(checkedShelfPayload);
  };

  const handleDeselect = (e: any) => {};

  const handleAudienceDeselect = (e: any) => {};

  useEffect(() => {
    dispatch(switchSearchSideBar(true));
    let source = Axios.CancelToken.source();
    let pagination;
    let sortedField;
    let sortDirection;

    if (
      selectedFilters.sortOrder !== undefined &&
      selectedFilters.sortOrder !== "Sort By"
    ) {
      sortedField = selectedFilters.sortOrder !== "Default"?selectedFilters.sortOrder:"";;
    } else if (
      searchDetails?.QuickSearch?.[0] !== undefined &&
      searchDetails?.QuickSearch?.[0] !== ""
    ) {
      sortedField = "rank";
    } else {
      sortedField = "";
    }

    if (selectedFilters.sort !== undefined) {
      sortDirection = selectedFilters.sort;
    } else {
      sortDirection = "ASC";
    }

    if (selectedFilters.pageSize !== undefined) {
      pagination = selectedFilters.pageSize;
    }
    var initialSearchOnLoad = {
      ...searchDetails,
      sortField: sortedField && sortedField !== "Default"?sortedField:"",
      recordCount: pageSize,
      sortDirection: sortDirection,
      recordStart: start,
    };
    setSearchDetails({
      ...searchDetails,
      sortField: sortedField && sortedField !== "Default"?sortedField:"",
      recordCount: pagination,
      sortDirection: sortDirection,
    });
    if (searchDetails) {
      updateAndLoad();
      dispatch(
        fetchColecSearchResults({
          payload: checkCollectPayloadNReForm(initialSearchOnLoad),
          signal: source,
        })
      );
    } else if (isbnSearch) {
      updateAndLoad();
      let isbnSearchInitial = {
        ...isbnSearch,
        sortField: sortedField && sortedField !== "Default"?sortedField:"",
        sortDirection: sortDirection,
      };
      dispatch(
        fetchIsbnSearcNew({
          payload: {
            ...isbnSearchInitial,
            SearchType: "CollectionSearch",
            recordStart: start,
          },
          signal: source,
        })
      );
    }
    setOnPageLoad(true);
    return () => {
      source.cancel();
      hideLoading();
    };
  }, []);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    if (onpageLoad) {
      if (searchDetails) {
        updateAndLoad();
        let sortOrderNew = sortOrder !== "Sort By" ? sortOrder : "";
        let searchDetailsNew = {
          ...searchDetails,
          sortField: sortOrderNew && sortOrderNew !== "Default"?sortOrderNew:"",
          sortDirection: sort,
          recordStart: start,
          recordCount: pageSize,
        };
        dispatch(
          fetchColecSearchResults({ payload: checkCollectPayloadNReForm(searchDetailsNew), signal: source })
        );
      } else if (isbnSearch) {
        updateAndLoad();
        let sortOrderNew = sortOrder !== "Sort By" ? sortOrder : "";
        let isbnSearchNew = {
          ...isbnSearch,
          sortField:  sortOrderNew && sortOrderNew !== "Default"?sortOrderNew:"",
          sortDirection: sort,
        };
        dispatch(
          fetchIsbnSearcNew({
            payload: { ...isbnSearchNew, SearchType: "CollectionSearch" },
            signal: source,
          })
        );
      }
    }
    return () => {
      source.cancel();
    };
  }, [searchDetails, isbnSearch]);

  useEffect(() => {
    return () => {
      dispatch(clearSuccess());
      dispatch(clearState());
      dispatch(clearResults());
      dispatch(clearCartsByBookId());
      dispatch(clearString());
      dispatch(clearAdvanceSearchData());
      setBookList([]);
    };
  }, []);

  useEffect(() => {
    if (location?.state?.searchDetails) {
      setBookList([]);
    }
    setQuickSearch(location?.state?.searchDetails);
    setSearchDetails(location?.state?.searchDetails);
    if (location?.state?.isCameFromAdvanceSearch && globalSelectAll) {
      history.push(AppRoutes.COLLECTIONSEARCHRESULTS, {
        ...location?.state,
        isCameFromAdvanceSearch: false,
      });
      onSelectAllCancel();
    }
  }, [location?.state]);

  useEffect(() => {
    if (searchCollecFormState.isSuccess) {
      setUpdatingData(false);
      if (collecSearchResults?.length) {
        setDataLoading(true);
        setNoData(false);
      } else {
        setNoData(true);
        setDataLoading(false);
      }
      dispatch(clearState());
      if (isPageChanges?.isPageChanges && isPageChanges?.isSelectAll) {
        onConfirm();
        dispatch(
          isPageChange({
            isPageChanges: false,
            isSelectAll: false,
          })
        );
      }
    }
    if (searchCollecFormState.isError) {
      setNoData(true);
      setUpdatingData(false);
      setDataLoading(false);
      dispatch(clearState());
    }
  }, [searchCollecFormState, collecSearchResults, isPageChanges]);

  const changePageSize = (size: number) => {
    setBookList([]);
    updateAndLoad();
    setPageSize(size);
    dispatch(retainPageSize(size))
    if (searchDetails) {
      setBookList([]);
      setSearchDetails({ ...searchDetails, recordCount: size });
    } else if (isbnSearch) {
      setBookList([]);
      setIsbnSearch({ ...isbnSearch, recordCount: size });
    }
  };

  useEffect(() => {
    setBookList(collecSearchResults);
  }, [collecSearchResults]);

  // useEffect(() => {
  //   !updatingData && setDataLoading(false);
  // }, [bookList, updatingData]);

  useEffect(() => {
    setDataLoading(false);
  }, [noData]);

  useEffect(() => {
    if (totalCollecResults > lastCount - start + 1 &&
      bookList &&
      bookList?.length) {
      setPaginationHide(false);
    }else{
      setPaginationHide(true);
    }
  }, [totalCollecResults, lastCount,start,bookList]);

  useEffect(() => {
    const filtersApply = {
      sortOrder,
      pageSize,
      sortIcon,
      sort,
    };
    dispatch(searchFilters(filtersApply));
  }, [pageSize, sortOrder, sortIcon]);

  const onChangeAudi = (checkedValues: any) => {
    setSelectedAud(checkedValues);
  };

  const onCancelAudi = () => {
    setChecked([]);
    setCopies(1);
    setGlobalCopies(1);
    setCheckedPayload([]);
    setEditModeAduience(false);
    setEditSelected(false);
    setAllSelected(false);
    setSelectedAud([]);
    setEditSelectAll(false);
    setSelectedChunkData([]);
    setBulkAddShelf([]);
    dispatch(clearSelectedChkBox());
  };

  useEffect(() => {
    if (selectedAud?.length > 0 && checked?.length > 0) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [selectedAud, checked]);

  const onSaveAudience = () => {
    let source = Axios.CancelToken.source();
    var lib_Id =
      appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
    var payloadData = {
      LibraryId: lib_Id,
      CartDetail: checked,
      targetAudience: selectedAud,
    };
    if (canSave) {
      setAllSelected(false);
      setEditSelectAll(false);
      setEditSelected(false);
      setEditModeAduience(false);
      setSelectedAud([]);
      let sortOrderNew = sortOrder !== "Sort By" ? sortOrder : "";
      let searchDetailsAudi = {
        ...searchDetails,
        sortField:  sortOrderNew && sortOrderNew !== "Default"?sortOrderNew:"",
        sortDirection: sort,
      };
      dispatch(
        saveTargetAudience({
          payloadTarget: payloadData,
          payload: searchDetailsAudi,
          signal: source,
          isbnSearch: isbnSearch,
        })
      );
    }
  };

  const selectedChunk = () => {
    checked.reduce((resultArray: any[][], item: any, index: number): any => {
      const chunkIndex = Math?.floor(index / 20);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);
      setSelectedChunkData(resultArray);
      return resultArray;
    }, []);
  };

  useEffect(() => {
    if (clearModal) {
      selectedChunk();
    } else {
      setSelectedChunkData([]);
    }
  }, [clearModal]);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    if (confirmClearAudience) {
      setAllSelected(false);
      setEditSelectAll(false);
      setEditSelected(false);
      setEditModeAduience(false);
      setSelectedAud([]);
      selectedChunkData?.map((item: any) => {
        var lib_Id =
          appUser && appUser?.libraryData
            ? appUser.libraryData[0].LibraryId
            : 0;
        var payloadData = {
          LibraryId: lib_Id,
          CartDetail: item?.toString(),
        };
        return dispatch(
          deleteTargetAudience({
            payloadTarget: payloadData,
            payload: searchDetails,
            signal: source,
            isbnSearch: isbnSearch,
          })
        );
      });
      setConfirmClearAudience(false);
    }
  }, [confirmClearAudience]);

  useEffect(() => {
    if (targetAudSav) {
      setBookList([]);
      dispatch(cleraFitureList(false));
    }
  }, [targetAudSav]);

  const clearAudience = () => {
    if (checked.length > 0) {
      setClearModal(true);
    }
  };

  const clickArea = (id: any) => {};

  useEffect(() => {
    if (shelfAdmin) {
      const copyshelve = [...shelves];
      const filteredList = copyshelve?.filter(
        (item: any) => item.IsManual && item.IsQuickConnect === false
      );
      setShelvesData(filteredList);
      if (selectedShelve?.length < 1) {
        dispatch(onSelectShelve(filteredList[0]));
        setactiveShelveName(filteredList[0]);
        setActiveShelveCount(filteredList[0]?.ItemsCount);
      }
      if (shelves?.length < 1) {
        dispatch(fetchShelves(lib_Id));
      }
      if (selectedShelve !== undefined) {
        const a = shelves.filter(
          (d: any) => d.ShelfId === selectedShelve.ShelfId
        );
        setActiveShelveCount(a[0]?.ItemsCount);
      }
    }
  }, [shelves, selectedShelve]);

  const selectShelve = (data: any) => {
    setactiveShelveName(data);
    setActiveShelveCount(data?.ItemsCount);
    dispatch(onSelectShelve(data));
  };

  const shelveStatus = (status: any) => {
    if (status) {
      return <span className="shelves-live">LIVE</span>;
    } else {
      return <span className="shelves-draft">DRAFT</span>;
    }
  };

  const DropdownTitle = () => {
    return (
      <div style={{ display: "flex" }}>
        <div className="cart-dropdown shelf-dropdown">
          <Tooltip title={activeShelveName?.ShelfName} placement="bottom">
            <p className="dropDown-title shelf-dropdown-name">
              Shelf:
              <span className="shelves-title-ellipse">
                {" "}
                &nbsp;&nbsp;{activeShelveName?.ShelfName}
              </span>
              <span className="shelves-titles">{activeShelveCount} Titles</span>
              <span>{shelveStatus(activeShelveName?.IsActive)}</span>
            </p>
          </Tooltip>
        </div>
        <img src={downIcon} className="downIcon" alt="down" />
      </div>
    );
  };

  const shelvesList = (
    <Menu className="header-dropdown">
      {shelvesData.map((data: any, index: number) => {
        return (
          <Menu.Item key={index} onClick={() => selectShelve(data)}>
            <Tooltip title={data?.ShelfName} placement="bottom">
              <p className="dropDown-title dropdown-shelves-title  shelves-list-ellipse">
                <span>{data.ShelfName}</span>{" "}
                <span className="status-span">
                  {shelveStatus(data.IsActive)}
                </span>
              </p>
              <span className="dropDown-content">{data.ItemsCount} titles</span>
            </Tooltip>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const handleShelveClick = () => {
    history.push(AppRoutes.SHELVDETAILS, {
      shelv: activeShelveName,
      cammeFromAutoCreate: false,
    });
  };

  const addtoShelve = () => {
    let checkedShelfPayload: any = [];
    collecSearchResults?.map((book: any) => {
      if (checkedValues?.length > 0) {
        if (checkedValues?.includes(book?.CatalogItemId)) {
          checkedShelfPayload?.push(makePayload(book));
        }
      } else {
        checkedShelfPayload?.push(makePayload(book));
      }
    });
    const payLoadData = {
      ShelfId: activeShelveName?.ShelfId,
      LibraryId: activeShelveName?.LibraryId,
      ShelfName: activeShelveName?.ShelfName,
      Description: activeShelveName?.Description,
      ReaktorShelfId: activeShelveName?.ReaktorShelfId,
      SortOrder: activeShelveName?.SortOrder,
      IsActive: activeShelveName?.IsActive,
      IsManual: activeShelveName?.IsManual,
      Items: checkedShelfPayload,
      SortStrategy: activeShelveName?.SortStrategy,
      CatShelfAllItems: activeShelveName?.CatShelfAllItems,
      IsQuickConnect: activeShelveName?.IsQuickConnect,
      CatShelfCriteria: activeShelveName?.CatShelfCriteria,
      CurrentStatus: activeShelveName?.CurrentStatus,
      ItemsCount: activeShelveName?.ItemsCount,
      CreateDateTime: activeShelveName?.CreateDateTime,
      CreateUserId: activeShelveName?.CreateUserId,
      ChangeDateTime: activeShelveName?.ChangeDateTime,
      ChangeUserId: activeShelveName?.ChangeUserId,
    };
    dispatch(addItemtoShelve(payLoadData));
    const msg = replaceAll(
      /\{0\}/gi,
      Messages.ADD_TO_CART,
      `${activeShelveName?.ShelfName}`
    );
    showNotification(msg, "loading");
  };

  const addtoShelfForAll = (data: any) => {
    const payLoadData = {
      ShelfId: data?.ShelfId,
      LibraryId: data?.LibraryId,
      ShelfName: data?.ShelfName,
      Description: data?.Description,
      ReaktorShelfId: data?.ReaktorShelfId,
      SortOrder: data?.SortOrder,
      IsActive: data?.IsActive,
      IsManual: data?.IsManual,
      Items: bulkAddShelf,
      SortStrategy: data?.SortStrategy,
      CatShelfAllItems: data?.CatShelfAllItems,
      IsQuickConnect: data?.IsQuickConnect,
      CatShelfCriteria: data?.CatShelfCriteria,
      CurrentStatus: data?.CurrentStatus,
      ItemsCount: data?.ItemsCount,
      CreateDateTime: data?.CreateDateTime,
      CreateUserId: data?.CreateUserId,
      ChangeDateTime: data?.ChangeDateTime,
      ChangeUserId: data?.ChangeUserId,
    };
    dispatch(addItemtoShelve(payLoadData));
    const msg = replaceAll(
      /\{0\}/gi,
      Messages.ADD_TO_CART,
      `${data?.ShelfName}`
    );
    showNotification(msg, "loading");
  };

  const shelveListForAll = (
    <Menu className="header-dropdown">
      {shelvesData.map((data: any, index: number) => {
        return (
          <Menu.Item key={index} onClick={() => addtoShelfForAll(data)}>
            <Tooltip title={data?.ShelfName} placement="bottom">
              <p className="dropDown-title dropdown-shelves-title  shelves-list-ellipse">
                <span>{data.ShelfName}</span>{" "}
                <span className="status-span">
                  {shelveStatus(data.IsActive)}
                </span>
              </p>
              <span className="dropDown-content">{data.ItemsCount} titles</span>
            </Tooltip>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const containerRef = useRef<any>(null);
  const listRef = useRef<any>(null);
   // Cache to handle dynamic row heights
   const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 50, // Default height for rows until measured
    })
  ).current;


  const calculateDimensions = () => {
    if (containerRef.current) {
        // Clear cache and recompute row heights when necessary
        cache.clearAll();
        if (listRef.current) {
          listRef.current.recomputeRowHeights();
        }
    }
  };
  useEffect(() => {
    // Calculate dimensions on mount
    calculateDimensions();

    // Add event listener to recalculate on window resize
    window.addEventListener('resize', calculateDimensions);

    return () => {
      // Cleanup the event listener on unmount
      window.removeEventListener('resize', calculateDimensions);
    };
  }, [showSearchSideBar, collapseSideBar, cache, cancelAll]);

  const handleScroll = (event:any) => {
    if (listRef.current) {
      const scrollTop = event.target.scrollTop;
      listRef.current.scrollToPosition(scrollTop);
    }
  };

  useEffect(() => {
    if (bookList?.length > 1) {
      containerRef.current.scrollTo(0, scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookList]);

  useEffect(() => {
    if (strinDetails?.isbn !== undefined) {
      setIsbnInputVal(strinDetails?.isbn?.split(","));
    }
  }, [strinDetails]);

  useEffect(() => {
    var ArrayFileName = totalIsbnNumbers;
    var ArrayFileNameWExt = isbnInputVal;
    var FinalArray = ArrayFileNameWExt?.filter(
      (filenameWExt: string) =>
        !ArrayFileName?.includes(filenameWExt.split(".")[0])
    );
    dispatch(
      getUnmatchValues({
        totalVal: isbnInputVal,
        unmatchVal: FinalArray,
        totalIsbnPrice: totalIsbnprice,
      })
    );
  }, [isbnInputVal, totalIsbnNumbers, totalIsbnprice]);

  useEffect(() => {
    const currentPageState = {
      start,
      currentPage,
    };
    dispatch(pageRememberBooklist(currentPageState));
  }, [start, currentPage]);

  useEffect(() => {
    if (allSelected) {
      if (checked?.length === 0) {
        setUnSelectAll(false);
      } else if (checked?.length === pageSize||checked?.length > 0) {
        setUnSelectAll(true);
      }
    }
    dispatch(setCheckedValues(checked));
  }, [checked, allSelected, pageSize]);

  useEffect(() => {
    setCopies(bookGlobalValue);
    setGlobalCopies(bookGlobalValue);
  }, [bookGlobalValue]);

  const onItemValChange = (value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    var newEditCellRows: any = { newEditCellRows };
    let foundItem = totalQuantiy?.find(
      (item: { CatalogItemId: any }) =>
        item?.CatalogItemId === value?.CatalogItemId
    );
    if (foundItem) {
      var res = totalQuantiy.map(
        (obj: { CatalogItemId: number }) =>
          [value].find(
            (o: { CatalogItemId: number }) =>
              o.CatalogItemId === obj.CatalogItemId
          ) || obj
      );
      setTotalQuantiy(res);
    } else {
      var ttq = [...totalQuantiy, value].filter(
        (obj: any) => !(obj && Object.keys(obj)?.length === 0)
      );
      setTotalQuantiy(ttq);
    }

    if (allSelected) {
      if (!checked?.includes(value?.CatalogItemId)) {
        let chkVal: any = [...checked];
        chkVal.push(value?.CatalogItemId);
        setChecked(chkVal);
      }
    }
  };

  useEffect(() => {
    if (totalQuantiy?.length !== 0) {
      var ttqs = {
        globalCopie: copies,
        amtChangCopie: totalQuantiy,
      };
      dispatch(setSelectedTitles(ttqs));
    } else {
      return undefined;
    }
  }, [totalQuantiy]);

  useEffect(() => {
    if (
      selectedTitles?.globalCopie !== undefined &&
      selectedTitles?.globalCopie !== 1
    ) {
      setGlobalC(selectedTitles?.globalCopie);
    }
  }, [selectedTitles]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const handleSwitchSideBar = () => {
    if (showSearchSideBar) {
      dispatch(switchSearchSideBar(false));
    } else {
      dispatch(switchSearchSideBar(true));
    }
  };
  useEffect(() => {
    const syncScroll = () => {
      if (listRef.current && containerRef.current) {
        const listNode = listRef.current.Grid; // Access the internal Grid of List
        listNode.scrollTop = containerRef.current.scrollTop;
      }
    };

    const handleScroll = () => {
      syncScroll();
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="search-results">
      <button className="back-button" onClick={goBack}>
        {backText}
      </button>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <div className="results-text">
                <div className="label">
                  <div className="cursor-pointer" onClick={handleSwitchSideBar} style={{transition: 'width 0.3s',}}>
                    <img
                      src={userPrivate}
                      className="owned-icon"
                      alt="owned"
                    />
                    &nbsp;Refine your search
                    <br />
                  </div>
                  {(quickSearch?.QuickSearch &&
                    quickSearch?.QuickSearch[0] !== undefined) ||
                  !strinDetails?.isbn ? null : (
                    <>
                      <br />
                      Search Results:{" "}
                      <span className="total_not_matched">
                        Total not matched:{" "}
                        {searchCollecFormState.loading ? 0 : RecordsNotMatched}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {(quickSearch?.QuickSearch &&
            quickSearch?.QuickSearch[0] !== undefined) ||
          !strinDetails?.isbn ? null : (
            <>
              <Row>
                <Col span={24}>
                  <div className="results-text">
                    <div className="searchString">
                      <TextEllipsis
                        placement="bottomRight"
                        toolTipClass="StringtooltipStyle"
                        maxWidth={80}
                        text={inputSearchStringA}
                        textClassName="textStyle"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="results-text">
                    <div className="searchString">
                      <TextEllipsis
                        placement="bottomRight"
                        toolTipClass="StringtooltipStyle"
                        maxWidth={80}
                        text={inputSearchStringB}
                        textClassName="textStyle"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          <Row className="" justify="space-between">
            <Col xs={24} sm={24} md={24} lg={7} xl={10} xxl={14}>
              <p className="results-title">
                {/* {quickSearch?.QuickSearch[0] !== undefined
                  ? quickSearch?.QuickSearch[0]
                  : ""} */}
              </p>
            </Col>
            {shelfAdmin && !editModeAduience ? (
              <Col
                xs={24}
                sm={18}
                md={17}
                lg={11}
                xl={10}
                xxl={7}
                className="cart shelve-title-in-myCollection"
              >
                <Dropdown.Button
                  overlay={shelvesList}
                  trigger={["click"]}
                  icon={<DropdownTitle />}
                  className="cart-menu shelf-menu"
                  onClick={handleShelveClick}
                ></Dropdown.Button>
              </Col>
            ) : null}
            <Col xs={24} sm={6} md={7} lg={5} xl={4} xxl={3}>
              <div
                className="editor-Button collection-dropdown editorButton-List"
                style={{ marginRight: "40px" }}
              >
                <Dropdown
                  overlay={pageOptions}
                  trigger={["click"]}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  disabled={allSelected}
                >
                  <button className="button-type editor">
                    <img src={editIcon} className="edit-icon" alt="" /> Editor
                    &nbsp; &nbsp;
                    <img src={downIcon} className="down-icon" alt="" />
                  </button>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider className="books-divider" />
      <Row
        className="saved-search-header"
        justify="space-between"
        gutter={[20, 20]}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={allSelected ? 15 : 15}
          xl={allSelected ? 6 : 15}
        >
          <Row>
            <Col className="searchTitleCountStyle">
              <span className="title-count">
                {totalCollecResults > 0 ? start : "0"}-{lastCount} of{" "}
                {totalCollecResults} Titles
              </span>
            </Col>
            <Col
              md={6}
              lg={allSelected ? 8 : 6}
              xl={allSelected ? 8 : 6}
              className="filters-contatiner"
            >
              <DropDown
                defaultValue={
                  selectedFilters.sortOrder !== undefined
                    ? selectedFilters.sortOrder
                    : "Default"
                }
                value={sortOrder}
                bordered={false}
                className="filters"
                optionValue={filterOptions.sortersCollect}
                icon={dropdownIcon}
                onChange={handleSortFilter}
                showSort={true}
                sortIcon={sortIcon}
                onSortClick={onSortClick}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={allSelected ? 24 : 9}
          xl={allSelected ? 18 : 9}
        >
          {editModeAduience ? (
            <>
              <Row>
                {/* <Col span={24} className="results-text">
                  <div className="label audHed">Change Audience</div>
                </Col> */}
                <Col
                  xs={24}
                  sm={24}
                  md={21}
                  lg={14}
                  xl={15}
                  className="auidenceCheckBocGroup"
                >
                  <div className="audeinceCheckBoxes">
                    <Checkbox.Group
                      options={filterOptions.audienceChkBox}
                      onChange={onChangeAudi}
                    />
                  </div>
                  <div className="clearAudience">
                    <span onClick={clearAudience}>Clear Audience Data</span>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={3} lg={4} xl={4}>
                  <Button type="primary saveAud ml-15" onClick={onSaveAudience}>
                    Save
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={3} lg={4} xl={4} className="ml-15">
                  <Button type="secondary saveAud ml-15" onClick={onCancelAudi}>
                    Cancel
                  </Button>
                </Col>
              </Row>
              <ClearAudienceModel
                clearModal={clearModal}
                setClearModal={setClearModal}
                setConfirmClearAudience={setConfirmClearAudience}
                selectedCartItems={checked}
                setSelectedChunkData={setSelectedChunkData}
              />
            </>
          ) : null}
          {editSelectAll ? null : allSelected ? (
            <Row>
              <Col xs={13} sm={13} md={6} lg={6} xl={shelfAdmin ? 7 : 9}>
                <div
                  className={`add_toCart_button ${
                    checkedPayload.length > 0 || totalQuantiy.length > 0 ? "" : "disable-on-unselectall"
                  } `}
                >
                  {checkedValues?.length > 0 || totalQuantiy.length > 0 ? (
                    <Button
                      onClick={() => onAddtoCart(activeCartArray)}
                      type={`add-main-cart-button ${
                        checkedValues.length > 0 || totalQuantiy.length > 0 ? "" : "disable-on-unselectall"
                      }`}
                      disabled={false}
                    >
                      Add to Cart
                    </Button>
                  ) : (
                    <Button
                      type={`add-main-cart-button disable-on-unselectall`}
                      disabled={true}
                    >
                      Add to Cart
                    </Button>
                  )}
                  <Divider type="vertical" className="cart_button_divider" />
                  <Dropdown
                    overlay={userCarts}
                    className={`${
                      checkedPayload.length > 0 || totalQuantiy.length > 0 ? "" : "disable-on-unselectall"
                    }`}
                    trigger={["click"]}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    disabled={!(checkedPayload.length > 0 || totalQuantiy.length > 0)}
                    placement={"bottomRight"}
                  >
                    <Button
                      type={`cart-tomain-dropdown ${
                        checkedPayload.length > 0 || totalQuantiy.length > 0
                          ? ""
                          : "disable-on-unselectall"
                      }`}
                    >
                      <img
                        src={whiteArrow}
                        style={{ paddingLeft: "5px" }}
                        alt=""
                      />
                    </Button>
                  </Dropdown>
                </div>
              </Col>
              {shelfAdmin ? (
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={shelfAdmin ? 6 : 8}
                  className="addToMainShelfPadding"
                >
                  <div
                    className={`add_toCart_button add_toShelves_button ${
                      checkedPayload.length > 0 || totalQuantiy.length > 0 ? "" : "disable-on-unselectall"
                    }`}
                  >
                    {checkedValues?.length > 0  || totalQuantiy.length > 0? (
                      <Button
                        onClick={addtoShelve}
                        type={`add-main-cart-button ${
                          checkedValues.length > 0 || totalQuantiy.length > 0
                            ? ""
                            : "disable-on-unselectall"
                        }`}
                        disabled={false}
                      >
                        Add to Shelf
                      </Button>
                    ) : (
                      <Button
                        type={`add-main-cart-button disable-on-unselectall`}
                        disabled={true}
                      >
                        Add to Shelf
                      </Button>
                    )}
                    <Divider type="vertical" className="shelf_button_divider" />
                    <Dropdown
                      overlay={shelveListForAll}
                      className={`${
                        checkedPayload.length > 0 || totalQuantiy.length > 0
                          ? ""
                          : "disable-on-unselectall"
                      }`}
                      trigger={["click"]}
                      disabled={!(checkedPayload.length > 0 || totalQuantiy.length > 0)}
                      getPopupContainer={(trigger: any) => trigger.parentNode}
                      placement={"bottomRight"}
                    >
                      <Button
                        type={`cart-tomain-dropdown ${
                          checkedPayload.length > 0 || totalQuantiy.length > 0
                            ? ""
                            : "disable-on-unselectall"
                        }`}
                      >
                        <img
                          src={downIcon}
                          style={{ paddingLeft: " 3px" }}
                          alt=""
                        />
                      </Button>
                    </Dropdown>
                  </div>
                </Col>
              ) : null}
              <Col
                xs={8}
                sm={8}
                md={5}
                lg={5}
                xl={shelfAdmin ? 5 : 7}
                className="cancel"
              >
                <Button type="secondary" onClick={onSelectAllCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          ) : (
            <Row justify={!allSelected ? "end" : "start"}>
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={10}
                xl={10}
                className="select-all"
              >
                <Button
                  type="secondary"
                  disabled={!bookList.length}
                  onClick={onConfirm}
                >
                  Select All
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Divider
        className="books-divider"
        style={{ marginTop: `${editModeAduience ? "0px" : "14px"}` }}
      />
      <Spin
        spinning={
          // false
          targetAudFormState?.loading ||
          searchCollecFormState?.loading ||
          addItemShelfAlone?.loading
        }
        tip="Loading..."
        indicator={antIcon}
      >
        <div
          id="scrollableDiv"
          ref={containerRef}
          style={
            screenSize?.dynamicHeight
              ? { overflowY: 'auto', height: paginationHide?screenSize?.dynamicHeight - 280:screenSize?.dynamicHeight - 240 }
              : {}
          }
          className={`scrollContent scrollStyle scrollOnBooksScreen ${
            paginationHide ? "collectionPageScroll" : "collectionResultScroll"
          }`}
          onScroll={handleScroll}
        >
          {totalCollecResults > lastCount - start + 1 &&
          bookList &&
          bookList?.length ? (
            <>
              {allSelected ? (
                <>
                  <Row>
                    <Col span={8} className="selectAllColWrapper">
                      <div className="selectAllWrapper">
                        <Checkbox
                          className="unSelectAllCheckbox"
                          checked={selectAllChkBox}
                          onChange={onSelectAlCheckBox}
                        ></Checkbox>
                      </div>
                      {!editModeAduience ? (
                        <div className="selectAllWrapper">
                          {/* <span>Copies : </span> */}
                          <InputNumber
                            defaultValue={globalC}
                            min={1}
                            max={999}
                            value={globalC}
                            disabled={!unSelectAll}
                            onChange={onValueChange}
                          />
                        </div>
                      ) : null}
                    </Col>
                    <PaginationTag
                      total={totalCollecResults}
                      current={currentPage}
                      defaultPageSize={pageSize}
                      onChange={onPageChange}
                    />
                    <Divider className="search-divider" />
                  </Row>
                </>
              ) : (
                <>
                  <PaginationTag
                    total={totalCollecResults}
                    current={currentPage}
                    defaultPageSize={pageSize}
                    onChange={onPageChange}
                  />
                  <Divider className="search-divider" />
                </>
              )}
            </>
          ) : allSelected ? (
            <>
              <Col span={8} className="selectAllColWrapper">
                <div className="selectAllWrapper">
                  <Checkbox
                    className="unSelectAllCheckbox"
                    checked={selectAllChkBox}
                    onChange={onSelectAlCheckBox}
                  ></Checkbox>
                </div>
                {!editModeAduience ? (
                  <div className="selectAllWrapper">
                    <span>Copies : </span>
                    <InputNumber
                      defaultValue={1}
                      min={1}
                      max={999}
                      onChange={onValueChange}
                    />
                  </div>
                ) : null}
              </Col>
              <Divider className="search-divider" />
            </>
          ) : null}
          {bookList && bookList?.length ? (
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checkedValues}
              onChange={(checkedValues: any) => {
                onCustomSelect(checkedValues);
              }}
            >
             {/* Virtualized List */}
                {/* AutoSizer and List from react-virtualized */}
        <AutoSizer disableHeight>
          {({ width }: any) => (
      <List
        ref={listRef}
        height={containerRef.current ? containerRef.current.clientHeight : 0} // Match the height of the scrollable div
        rowCount={bookList.length} // Total number of rows
        rowHeight={cache.rowHeight} // Dynamic row height based on content
        width={width} // Full width of the container
        deferredMeasurementCache={cache} // Cache for measuring rows
        rowRenderer={({ index, key, parent, style }:any) => (
          <CellMeasurer
            key={key}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}
          >
            {({ measure }) => (
              <div style={style} onLoad={measure} 
              // onScroll={() => {
              //   cache.clear(index, 0);
              //   measure();
              // }}
              >
                <BookRow
                    book={bookList[index]}
                    isCart={false}
                    allSelected={allSelected}
                    editSelected={editSelected}
                    isAddTocartEnable={false}
                    filteredCarts={filteredCarts}
                    appUser={appUser}
                    showNotification={showNotification}
                    handleCheckbox={
                      editModeAduience ? handleAudienceDeselect : handleDeselect
                    }
                    searchStringParam={strinDetails}
                    isCollection={editModeAduience}
                    clickArea={clickArea}
                    addShelves={true}
                    scrollValue={scrollValue}
                    onSelectAll={onConfirm}
                    globalC={globalC}
                    selectAllCancel={cancelAll}
                    onCopie={onItemValChange}
                    globalCopie={copies}
                    globalPrevious={globalPrevious}
                    initialChecked={checkedValues.includes(bookList[index].CatalogItemId)}
                    onCheckboxChange={handleCheckboxChange}
                  />
              </div>
            )}
          </CellMeasurer>
        )} // Function to render each row
        overscanRowCount={10} // Render extra rows for smoother scrolling
        style={{
          overflow: 'hidden', // Explicitly hide overflow to prevent scrollbars
          // pointerEvents: 'none'
        }}
        autoHeight // Automatically adjusts the height based on the content
        // scrollTop={containerRef.current ? containerRef.current.scrollTop : 0} // Sync with outer div scroll
      />)}
      </AutoSizer>
            </Checkbox.Group>
          ) : (
            !searchCollecFormState?.loading &&
            !dataLoading &&
            !bookList.length &&
            noData && (
              <Empty
                style={{ marginTop: "20%" }}
                description="No results found."
              />
            )
          )}
          {totalCollecResults > lastCount - start + 1 &&
          bookList &&
          bookList?.length ? (
            <>
              <PaginationTag
                total={totalCollecResults}
                current={currentPage}
                defaultPageSize={pageSize}
                onChange={onPageChange}
              />
              <Col>
                <span className="searchTitleStyle">
                  {start}-{lastCount} of {totalCollecResults} Titles
                </span>
              </Col>
            </>
          ) : null}
          <div className="spinner-container">
            <SpinnerLoader
              // loading={searchCollecFormState?.loading || dataLoading}
              loading={
                // targetAudFormState?.loading ||
                // searchCollecFormState?.loading ||
                // addItemShelfAlone?.loading ||
                // !(bookList.length > 0 || noData)
                targetAudFormState?.loading ||
                searchCollecFormState?.loading ||
                addItemShelfAlone?.loading
                  ? false
                  : !(bookList.length > 0 || noData)
              }
            />
          </div>
        </div>
        <NewCartModal
          isModalVisible={showModal}
          setModalVisible={setShowModal}
          appUser={appUser}
          isNewCartDetailsNeed={true}
        />
      </Spin>
    </div>
  );
});
