/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef } from "react";
import {
  Row,
  Col,
  Divider,
  Menu,
  Dropdown,
  InputNumber,
  Empty,
  message,
  Checkbox,
} from "antd";
import DropDown from "../../stateless/common/dropDown";
import Button from "../../stateless/common/button";
import PaginationTag from "../../stateless/common/pagination";
import TextEllipsis from "../../stateless/common/ellipsis";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import {
  CartStateSelector,
  clearAdded,
  setTab,
  clearErrorCart,
} from "../../../../redux/reducers/carts/cartsReducer";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import { useEffect, useState } from "react";
import {
  ascIcon,
  descIcon,
  editIcon,
  downIcon,
  dropdownIcon,
  whiteArrow,
  list,
  asteriskIcon,
  userPrivate,
} from "../../../images";
import Cart from "../../../models/cart/cart";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../router/appRoutes";
import { filterOptions, Page } from "../../../constants/constants";
import {
  SearchstateSelector,
  clearSuccess,
  clearState,
  clearString,
  clearResults,
  clearSearchScroll,
  clearQuickSearchValue,
} from "../../../../redux/reducers/search/searchReducer";
import { getallSearchStringRowA, getallSearchStringRowB } from "./searchString";
import { BookRow } from "../curatedList/bookList/bookRowItem";
import { addItemtoCart, cartDetailsFilterRemember } from "../../../../redux/actions/carts/cartAction";
import { Book } from "../../../models/book/book";
import { useLocation } from "react-router-dom";
import {
  getSaveSearchResult,
  onStringBackFillForm,
  fetchIsbnSearcNew,
  fetchSearchResultsNew,
  searchFilters,
  getUnmatchValues,
  fetchSearchResultsExport,
  getSearchString,
  fetchIsbnSearchExport,
} from "../../../../redux/actions/search/searchActions";
import SpinnerLoader from "../../stateless/common/spinnerLoader";
import { NewCartModal } from "../carts/newCartModal";
import {
  BookStateSelector,
  clearCartsByBookId,
  clearSelectedChkBox,
  emtySelectedChkBox,
} from "../../../../redux/reducers/books/bookListReducer";
import { Messages } from "../../../constants/messages";
import { replaceAll } from "../../../../utility/appUtil";
import { CSVDownload, CSVLink } from "react-csv";
import ExportIcon from "../../../../assets/dummy/ExportIcon.png";
import { UseGAEventTracker } from "../../../../utility/useGAEventTracker";
import Axios from "axios";
import {
  pageRememberBooklist,
  selectAllRemember,
  setCheckedValues,
  setSelectAllChkBox,
  setSelectedTitles,
  setGlobalCopy,
} from "../../../../redux/actions/books/bookActions";
import { switchSearchSideBar } from "../../../../redux/actions/app/appActions";
import { SideBarStateSelector, retainPageSize } from "../../../../redux/reducers/app/sideBarReducer";
import { checkPayloadAndReform } from "../../../../utility/searchUtil";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";

export const SearchResults = React.memo((props: any) => {
  const history = useHistory();
  const location = useLocation<any>();
  const dispatch = useDispatch();
  const csvLinkHit = useRef<any>(null)
  const {
    carts,
    addedItem,
    activeCartArray,
    addItemtoCartFormstate,
    ErrorCart,
  } = useSelector(CartStateSelector);
  const {
    searchFormState,
    searchResults,
    totalResults,
    searchStrings,
    backFormFill,
    selectedFilters,
    scrollHeight,
    totalIsbnprice,
    RecordsNotMatched,
    NotMatchedData,
  } = useSelector(SearchstateSelector);
  const { showSearchSideBar, collapseSideBar } = useSelector(SideBarStateSelector);
  const {
    booklistCurrentPage,
    globalSelectAll,
    checkedValues,
    selectAllChkBox,
    selectedTitles,
    bookGlobalValue,
  } = useSelector(BookStateSelector);
  const { appUser } = useSelector(userStateSelector);
  const { retainedPageSize } = useSelector(SideBarStateSelector);
  var permissions =
    appUser && appUser.libraryData
      ? appUser.libraryData[0].FeaturePermissions
      : "";
  const [checked, setChecked] = useState<any>([]);
  const [checkedPayload, setCheckedPayload] = useState<any>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [eptyCheckedArry, setEptyCheckedArry] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sort, setSort] = useState(
    selectedFilters.sort !== undefined ? selectedFilters.sort : "ASC"
  );
  const [sortIcon, setSortIcon] = useState(
    selectedFilters.sortIcon !== undefined ? selectedFilters.sortIcon : ascIcon
  );
  const [sortOrder, setSortOrder] = useState(
    selectedFilters.sortOrder !== undefined
      ? selectedFilters.sortOrder
      : "Default"
  );
  const [strinDetails, setStrinDetails] = useState<any>();
  const [quickSearch, setQuickSearch] = useState<any>();
  const [copies, setCopies] = useState<any>(1);
  const [inputSearchStringA, setInputSearchStringA] = useState<any>(1);
  const [inputSearchStringB, setInputSearchStringB] = useState<any>(1);
  const [addToCartNow, setAdToCartNow] = useState<boolean>(false);
  const [cartDescription, setCartDescription] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [backString, setBackString] = useState<any>();
  const [isbnInputVal, setIsbnInputVal] = useState<any>([]);
  const [csvData, setCsvData] = useState<any>([]);
  const [downloadingCsv, setDownloadingCsv] = useState<any>(false);
  const [exportIt, setExportIt] = useState<boolean>(false);
  const [unSelectAll, setUnSelectAll] = useState<boolean>(true);
  const [selectAlChecked, setSelectAlChecked] = useState<boolean>(false);
  const [searchDetails, setSearchDetails] = useState(
    location?.state?.searchDetails
  );
  const gaString = location?.state?.gaString;

  const [isbnSearch, setIsbnSearch] = useState(location?.state?.isbnSearch);
  const [isbnQuickSearch, setIsbnQuickSearch] = useState({});
  const [exportData, setExportData] = useState<any>([]);
  const backText = "< Back";
  var filteredCarts = permissions.includes("AudioBooks")
    ? carts.filter((data: Cart) => {
      return (
        data.CartOwner === appUser?.email || data.IsCommunityCart === true
      );
    })
    : carts.filter((data: Cart) => {
      return (
        (data.CartOwner === appUser?.email ||
          data.IsCommunityCart === true) &&
        !data.CartTypeCode?.includes("Audio")
      );
    });

  const myRequest = new XMLHttpRequest();
  myRequest.open("POST", "getSearchResults");
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState<number>(
    booklistCurrentPage?.start !== undefined
      ? booklistCurrentPage?.start
      : Page.DEFAULT_START
  );

  const [selectedFormat, setSelectedFormat] = useState(
    selectedFilters.selectedFormat !== undefined
      ? selectedFilters.selectedFormat
      : "all"
  );
  const node = document.querySelector<HTMLElement>(".search-results-list");
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [bookList, setBookList] = useState<Book[]>([]);
  const [updatingData, setUpdatingData] = useState(false);
  const [noData, setNoData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(
    booklistCurrentPage?.currentPage !== undefined
      ? booklistCurrentPage?.currentPage
      : 1
  );

  const digits_only = (string: any) => [...string].every(c => '0123456789'.includes(c));
  const scrollRef = useRef<any>(null)
  const [pageSize, setPageSize] = useState<number>(
    retainedPageSize ?? 100
  );
  const [lastCount, setLastCount] = useState(start + pageSize - 1);
  const [onpageLoad, setOnPageLoad] = useState<boolean>(false);
  const [scrollValue, setScrollValue] = useState(0);
  const optionList = [10, 20, 50, 100, 250, 500];
  const [paginationHide, setPaginationHide] = useState(false);
  const [cancelAll, setCancelAll] = useState(false);
  const [totalQuantiy, setTotalQuantiy] = useState<any>([]);
  const [globalC, setGlobalC] = useState<number>(1);
  const [globalPrevious, setGlobalPrevious] = useState<any>();
  var dateFormat = appUser?.libraryData
    ? appUser.libraryData[0]?.DateFormat.toUpperCase()
    : "";
  const [screenSize, getDimension] = useState<any>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const config: any = {
    key: "downloading",
    type: "loading",
    content: "Exporting CSV...",
    duration: 0,
    className: "custom-ant-message-container"
  };

  const hideLoading = () => message.destroy("downloading");
  const showLoading = () => {
    message.open(config);
  };


  const camefromAdvancedSearch = location?.state?.fromAdvancedSearch;

  const headers = [
    { label: "ISBN", key: "Eisbn" },
    { label: "Title", key: "refineTitle" },
    { label: "Authors", key: "refineAuther" },
    { label: "Series", key: "Series" },
    { label: "Series Number", key: "NumberInSeries" },
    { label: "Narrator", key: "Narrator" },
    { label: "Publisher", key: "Publisher" },
    { label: "Content Provider", key: "Provider" },
    { label: "Format", key: "FileFormatName" },
    { label: "Publication Date", key: "PublicationDate" },
    { label: "Language", key: "Language" },
    { label: "Category", key: "category" },
    { label: "Sub Category", key: "subCategory" },
    { label: "Street Date", key: "StreetDate" },
    { label: "Price", key: "RetailPrice" },
    { label: "Added to CAT", key: "AddedToCatalogDate" },
    { label: "Owned", key: "NumberOfCopies" },
    { label: "Holds", key: "NumberOfHolds" },
    { label: "Loans", key: "NumberOfLoans" },
    { label: "In Circ", key: "NumberOfCurrentLoans" },
    { label: "Suggested", key: "NumberOfWish" },
    { label: "Holds Ratio", key: "HoldRatio" },
    { label: "Ordered", key: "NumberOnOrder" },
    { label: "Shelved", key: "NumberOfShelves" },
    { label: "in Cart", key: "NumberInCarts" },
    { label: "Last Purchased Date", key: "LastPurchasedDate" },
    { label: "Last Checked Out Date", key: "LastCheckoutDate" },
    { label: "Is PPU", key: "IsPPU" },
    { label: "PPU Price", key: "PPUPrice" },
    { label: "cloudLink Shareable Copies", key: "GroupSharableCopies" },
    { label: "cloudLink Current Holds", key: "GroupHoldCount" },
  ];

  const csvExportList = {
    filename: `searchList.csv`,
    headers: headers,
    data: csvData,
  };

  useEffect(() => {
    if (gaString?.byFrom === "onSearchbtn") {
      if (
        gaString?.gaData?.keyword !== "" &&
        gaString?.gaData?.keyword !== undefined
      ) {
        UseGAEventTracker(
          "Search category",
          "quick keyword Search",
          `quick Search keyword - ${gaString?.gaData?.keyword}`,
          NaN
        );
      } else {
        UseGAEventTracker(
          "Search category",
          gaString?.gaData?.author !== "" || gaString?.gaData?.title !== ""
            ? "Search title/author"
            : "Search by other criteria",
          `User search by other criteria - ${gaString?.gaData?.author !== "" || gaString?.gaData?.title !== ""
            ? `${gaString?.gaData?.author} ${gaString?.gaData?.title}`
            : ""
          }`,
          NaN
        );
      }
    }
    if (gaString?.byFrom === "onIsbnSearchbtn") {
      UseGAEventTracker(
        "Search category",
        "Search ISBN",
        `User search By ISBN ${gaString?.gaData?.isbn}`,
        NaN
      );
    }
    if (gaString?.byFrom === "byQuickSearch") {
      UseGAEventTracker(
        "Search category",
        "quick keyword Search",
        `'quick Search keyword - ${gaString?.gaData}`,
        NaN
      );
    }
  }, [gaString]);

  const dateModify = (date: any) => {
    const myStr = date.substr(0, 10);
    const currentDate = moment(myStr).tz("Etc/GMT").format(dateFormat);
    return currentDate;
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    if (allSelected) {
      if (!selectAlChecked) {
        if (selectAllChkBox) {
          onConfirm();
        }
      } else {
        onConfirm();
        setChecked([]);
        setCheckedPayload([])
        setSelectAlChecked(false);
        dispatch(setSelectAllChkBox(false))
      }
    }
    setBookList(searchResults)
  }, [searchResults]);

  useEffect(() => {
    if (allSelected) {
      if (checked?.length === 0) {
        setUnSelectAll(false);
      } else if (checked?.length === pageSize||checked?.length > 0) {
        setUnSelectAll(true);
      }
    }
    dispatch(setCheckedValues(checked));
  }, [checked, allSelected, pageSize]);

  const functionForExport = (data: any) => {
    if (data?.error) {
      setDownloadingCsv(false);
      hideLoading();
      setTimeout(() => {
        showNotification(data.error, "error");
      }, 500);
      setExportIt(false);
    } else {
      setCsvData(data);
      setExportIt(true);
    }
  };

  useEffect(() => {
    if (exportIt && csvData) {
      hideLoading();
      setDownloadingCsv(false);
      setTimeout(() => {
        // showNotification("CSV Successfully Downloaded", "success");
        csvLinkHit?.current?.link?.click();
      }, 1000);
      setExportIt(false);
    }
  }, [exportIt, csvLinkHit, csvData]);

  const handleFetchExport = () => {
    let source = Axios.CancelToken.source();
    setDownloadingCsv(true);
    showLoading();
    if (location?.state?.cameFromIsbn) {
      var sortFieldValue = sortOrder !== "Sort By" ? sortOrder : "";
      let isbnSearchNew = {
        ...isbnSearch,
        sortDirection: sort,
        sortField: sortFieldValue && sortFieldValue !== "Default" ? sortFieldValue : "",
        recordCount: 1000
      };
      dispatch(
        fetchIsbnSearchExport({
          payload: { ...isbnSearchNew },
          signal: source,
          dateModify,
          functionForExport,
        })
      );
    }
    else {
      dispatch(
        fetchSearchResultsExport({
          payload: checkPayloadAndReform({
            ...searchDetails,
            recordCount: 1000,
            sortDirection: sort,
          }),
          signal: source,
          dateModify,
          functionForExport
        })
      );
    }
  };
  const pageOptions = React.useMemo(() => (
    <Menu className="header-dropdown">
      <Menu.Item key="page-size">
        <Row>
          <Col style={{ width: "20px" }}>
            <img style={{ width: "14px", height: "14px" }} src={list} alt=" " />
          </Col>
          <Col flex="auto" style={{ paddingLeft: "5px" }}>
            Page Size
          </Col>
        </Row>
      </Menu.Item>
      {optionList.map((data: number, index: number) => (
        <Menu.Item key={index} onClick={() => changePageSize(data)}>
          <Row>
            <Col style={{ width: "20px" }}>
              {pageSize === data ? (
                <img
                  style={{ width: "14px", height: "14px" }}
                  src={asteriskIcon}
                  alt="*"
                />
              ) : (
                " "
              )}
            </Col>
            <Col style={{ paddingLeft: "5px" }}>{data}</Col>
          </Row>
        </Menu.Item>
      ))}
      <Menu.Item>
        <Row>
          <Col style={{ width: "20px" }}>
            <img
              style={{ width: "14px", height: "14px", marginTop: "8px" }}
              src={ExportIcon}
              alt=" "
            />
          </Col>
          <Col>
            <div className="exportColListCsv export-list-search">
              <CSVLink 
                ref={csvLinkHit} 
                className="export-list-link hidden" 
                {...csvExportList} 
                style={{ visibility: "hidden" }} 
              />
              <button 
                className={`search-export-list-link ${downloadingCsv ? "not-allowed" : "cursor-pointer"}`} 
                onClick={handleFetchExport} 
                disabled={downloadingCsv}
              >
                Export List 1000 results as CSV
              </button>
            </div>
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  ), [optionList, pageSize, csvLinkHit, csvExportList, downloadingCsv, handleFetchExport]);
  
  var currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";

      const userCarts = (
        <Menu className="header-dropdown">
          {filteredCarts.map((data: Cart, index: number) => {
            return (
              <Menu.Item key={index} onClick={() => onAddtoCart(data)}>
                <p className="dropDown-title">{data.CartName}</p>
                <span className="dropDown-content">
                  {currency}
                  {data.TotalCartAmount.toFixed(2)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  . {data.NumberOfTitles} Titles
                </span>
              </Menu.Item>
            );
          })}
          <Menu.Divider />
          <Menu.Item
            key="All-Carts-Link"
            className="dropDown-link"
            onClick={() => {
              history.push(AppRoutes.CART);
              dispatch(setTab("2"));
            }}
          >
            <p>All Carts</p>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="New-cart-Link"
            className="dropDown-link"
            onClick={() => setShowModal(true)}
          >
            <p>New Cart</p>
          </Menu.Item>
        </Menu>
      );

  const updateAndLoad = () => {
    setDataLoading(true);
    setUpdatingData(true);
  };
  const handleFormatFilter = (value: any) => {
    updateAndLoad();
    node?.scroll(0, 0);
    if (value === selectedFormat) {
      formatAll(selectedFormat);
    } else {
      setSelectedFormat(value);
    }
  };
  const filterFormat = () => {
    const data = searchResults?.filter((data: Book) => {
      return data.FileFormatName === selectedFormat;
    });

    data.length ? setNoData(false) : setNoData(true);
    return data;
  };
  const formatAll = (selectedFormat: string) => {
    setDataLoading(true);
    if (selectedFormat === "all") {
      setTimeout(() => {
        setUpdatingData(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setBookList(filterFormat());
        setUpdatingData(false);
      }, 1000);
    }
  };

  useEffect(() => {
    formatAll(selectedFormat);
  }, [selectedFormat]);

  useEffect(() => {
    setDataLoading(false);
  }, [noData]);

  useEffect(() => {
    let end = start + pageSize - 1;
    if (totalResults < end) {
      setLastCount(totalResults);
    } else {
      setLastCount(end);
    }
    // if (totalResults && start) {
    //   if (
    //     totalResults !== 0 &&
    //     start !== 1 &&
    //     totalResults < start
    //   ) {
    //     setStart(1);
    //     setSearchDetails({ ...searchDetails, recordStart: 1 });
    //     setCurrentPage(1);
    //   }
    // }
  }, [start, pageSize, totalResults]);

  useEffect(() => {
    if (ErrorCart !== "" && !loading) {
      dispatch(clearErrorCart());
      dispatch(clearAdded());
    } else {
      return undefined;
    }
  }, [ErrorCart, loading]);

  const handleSortFilter = (value: any) => {
    updateAndLoad();
    setSortOrder(value);
    if (searchDetails) {
      setSearchDetails({
        ...searchDetails,
        sortField: value && value === "Default" ? "" : value,
        recordStart: start,
      });
    } else if (isbnSearch) {
      setIsbnSearch({
        ...isbnSearch,
        sortField: value && value === "Default" ? "" : value,
      });
    } else if (isbnQuickSearch) {
      setIsbnQuickSearch({
        ...isbnQuickSearch,
        sortField: value && value === "Default" ? "" : value,
        recordStart: start,
      });
    }
  };
  const onSortClick = () => {
    updateAndLoad();
    if (sort === "ASC") {
      setSort("DESC");
      if (searchDetails) {
        setSearchDetails({
          ...searchDetails,
          sortDirection: "DESC",
          recordStart: start,
        });
      } else if (isbnSearch) {
        setIsbnSearch({
          ...isbnSearch,
          sortDirection: "DESC",
          recordStart: start,
        });
      } else if (isbnQuickSearch) {
        setIsbnQuickSearch({
          ...isbnQuickSearch,
          sortDirection: "DESC",
          recordStart: start,
        });
      }
      setSortIcon(descIcon);
    } else {
      setSort("ASC");
      if (searchDetails) {
        setSearchDetails({
          ...searchDetails,
          sortDirection: "ASC",
          recordStart: start,
        });
      } else if (isbnSearch) {
        setIsbnSearch({
          ...isbnSearch,
          sortDirection: "ASC",
          recordStart: start,
        });
      } else if (isbnQuickSearch) {
        setIsbnQuickSearch({
          ...isbnQuickSearch,
          sortDirection: "ASC",
          recordStart: start,
        });
      }
      setSortIcon(ascIcon);
    }
  };
  const onValueChange = (value?: any) => {
    setGlobalPrevious(copies);
    setCopies(value);
    setGlobalC(value);
    if (totalQuantiy?.length || checkedPayload.length) {
      var ttqs = {
        globalCopie: value,
        amtChangCopie: totalQuantiy.length != 0 ? totalQuantiy?.map((item: any) => {
          let ay: any;
          if (checkedValues?.includes(item?.CatalogItemId)) {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity + (value - copies),
            };
          } else {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity,
            };
          }
          return ay;
        }) : checkedPayload?.map((item: any) => {
          let ay: any;
          if (checkedValues?.includes(item?.CatalogItemId)) {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity + (value - copies),
            };
          } else {
            ay = {
              CatalogItemId: item?.CatalogItemId,
              Quantity: item?.Quantity,
            };
          }
          return ay;
        }),
      };
      if (ttqs) {
        dispatch(setSelectedTitles(ttqs));
        setTotalQuantiy(ttqs?.amtChangCopie);
      }
    }
  };

  useEffect(() => {
    //form string from object
    setInputSearchStringA(getallSearchStringRowA(strinDetails));
    setInputSearchStringB(getallSearchStringRowB(strinDetails));
    setBackString(strinDetails);
  }, [strinDetails]);

  const goBack = () => {
    dispatch(pageRememberBooklist(location?.state?.gaString?.currentPage));
    dispatch(
      getSaveSearchResult({ searchParams: location?.state?.searchDetails })
    );
    dispatch(getSearchString(location?.state?.gaString?.gaData));
    dispatch(cartDetailsFilterRemember(location?.state?.gaString?.gaData));
    history.goBack();
    dispatch(clearSearchScroll());
    onCancel();
    dispatch(onStringBackFillForm(backString));
    dispatch(clearQuickSearchValue());
    if (backFormFill?.isbn !== undefined) {
      dispatch(setTab(2));
    } else {
      return undefined;
    }
  };
  const showNotification = (content: any, type: any) => {
    if (type === "loading") {
      setLoading(true);
      message
        .loading({
          content: content,
          key: "notificationMessage",
          duration: 4,
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      type === "success"
        ? message.success({
          content: content,
          key: "notificationMessage",
        })
        : message.error({
          content: content,
          key: "error",
        });
    }
  };

  const googleAnalyticISBNLabel = bookList?.reduce((total: any, item: any) => {
    var CatalogItemId = item?.CatalogItemId;
    var found = checked?.some((elem: any) => elem === CatalogItemId);
    if (found) total = total + item?.RetailPrice;
    return total;
  }, 0);

  const onAddtoCart = (cart: Cart) => {
    setAdToCartNow(true);
    setCartDescription(cart);
  };

  useEffect(() => {
    if (addToCartNow && cartDescription && checkedPayload) {
      const booksMap: Map<number, Book> = new Map(
        selectedTitles?.amtChangCopie?.map((b: Book) => [b.CatalogItemId, b])
      );
      let checkedPayloads: any = [];
      if(checkedPayload.length > 0) {
      for (var i: any = 0; i < checkedPayload.length; i++) {
        var inputQuantity = booksMap.get(checkedPayload[i].CatalogItemId);
        checkedPayloads?.push({
          CatalogItemId: checkedPayload[i].CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: inputQuantity?.Quantity ?? globalC,
          SaveAction: "S",
        });
      }
    } else if(totalQuantiy.length > 0){
      for (var i: any = 0; i < totalQuantiy.length; i++) {
        var inputQuantity = booksMap.get(totalQuantiy[i].CatalogItemId);
        checkedPayloads?.push({
          CatalogItemId: totalQuantiy[i].CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: inputQuantity?.Quantity ?? globalC,
          SaveAction: "S",
        });
      }
    }
      var lib_Id =
        appUser && appUser?.libraryData
          ? appUser?.libraryData[0]?.LibraryId
          : 0;
      var payloadData = {
        IsCommunityCart: cartDescription?.IsCommunityCart,
        LibraryId: lib_Id,
        SaveAction: "S",
        CartDescription: cartDescription?.CartDescription,
        CartId: cartDescription?.CartId,
        CartName: cartDescription?.CartName,
        AssociateShelfId: cartDescription?.AssociateShelfId,
        CartDetail: checkedPayloads,
      };
      setTimeout(() => {
        dispatch(addItemtoCart(payloadData));
      }, 100);
      const msg = replaceAll(
        /\{0\}/gi,
        Messages.ADD_TO_CART,
        `${cartDescription?.CartName}`
      );
      showNotification(msg, "loading");
      UseGAEventTracker(
        "Cart category",
        "Add all to cart from ISBN search",
        `User add all to cart from ISBN search having total price (${currency}${googleAnalyticISBNLabel})`,
        checked?.length
      );
      setAdToCartNow(false);
      setCartDescription([]);
    }
  }, [checkedPayload, addToCartNow, cartDescription, appUser]);

  const onConfirm = () => {
    // setUnSelectAll(true);
    setSelectAlChecked(true)
    dispatch(setSelectAllChkBox(true))
    setCancelAll(false);
    let checkedArray: any = [];
    let checkedPayload: any = [];
    if (checkedValues?.length > 0) {
      checkedValues?.map((item: any) => {
        checkedArray?.push(item);
        checkedPayload?.push({
          CatalogItemId: item,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: copies,
          SaveAction: "S",
        });
      });
    } else {
      searchResults?.map((item: any) => {
        checkedArray?.push(item?.CatalogItemId);
        checkedPayload?.push({
          CatalogItemId: item?.CatalogItemId,
          BuyerId: "",
          BuyerCartDetailId: 0,
          Quantity: copies,
          SaveAction: "S",
        });
      });
    }
    setCopies(1);
    if (eptyCheckedArry) {
      setChecked([]);
      setEptyCheckedArry(false);
    } else {
      setChecked(checkedArray);
    }
    setCheckedPayload(checkedPayload);
    setAllSelected(true);
    // setSelectAlChecked(true);
    dispatch(selectAllRemember(true));
    dispatch(setSelectAllChkBox(true));
  };

  const onCustomSelect = () => {
    // setChecked(checkedValues);
    // setCheckedPayload(
    //   checkedValues?.map((item: any) => {
    //     return {
    //       CatalogItemId: item,
    //       BuyerId: "",
    //       BuyerCartDetailId: 0,
    //       Quantity: copies,
    //       SaveAction: "S",
    //     };
    //   })
    // );
  };

  const onCancel = () => {
    setChecked([]);
    setCopies(1);
    dispatch(setGlobalCopy(1));
    setCheckedPayload([]);
    setAllSelected(false);
    dispatch(clearSelectedChkBox());
    dispatch(selectAllRemember(false));
    setTotalQuantiy([]);
    setGlobalC(1);
    // setEditCellRows([]);
  };

  const onselectAllCancel = () => {
    onCancel();
    dispatch(setSelectedTitles([]));
    setCancelAll(true);
    setGlobalPrevious(1);
  };

  const onUncheckBox = (e:any) => {
    // setUnSelectAll(!unSelectAll);
    // dispatch(setSelectAllChkBox(!unSelectAll));
    if (e?.target?.checked) {
      setSelectAlChecked(true);
      dispatch(setSelectAllChkBox(true));
    }
     else {
      setSelectAlChecked(false);
      dispatch(setSelectAllChkBox(false));
    }
  };

  useEffect(() => {
    if (!selectAlChecked) {
      setChecked([]);
      setCheckedPayload([]);
    } else {
      let checkedArray: any = [];
      let checkedPayload: any = [];
      // if (checkedValues?.length > 0) {
      //   checkedValues?.map((item: any) => {
      //     checkedArray?.push(item);
      //     checkedPayload?.push({
      //       CatalogItemId: item,
      //       BuyerId: "",
      //       BuyerCartDetailId: 0,
      //       Quantity: copies,
      //       SaveAction: "S",
      //     });
      //   });
      // } else {
        searchResults?.map((item: any) => {
          checkedArray?.push(item?.CatalogItemId);
          checkedPayload?.push({
            CatalogItemId: item?.CatalogItemId,
            BuyerId: "",
            BuyerCartDetailId: 0,
            Quantity: copies,
            SaveAction: "S",
          });
        });
      // }
      setChecked(checkedArray);
      setCheckedPayload(checkedPayload);
    }
  }, [selectAlChecked]);

  const handleDeselect = (e: any) => { };

  useEffect(() => {
    dispatch(switchSearchSideBar(true));

    let source = Axios.CancelToken.source();
    let pagination;
    let sortedField;
    let sortDirection;
    if (
      selectedFilters.sortOrder !== undefined &&
      selectedFilters.sortOrder !== "Sort By" && selectedFilters.sortOrder
    ) {
      sortedField = selectedFilters.sortOrder !== "Default" ? selectedFilters.sortOrder : "";
    } else if (
      searchDetails?.QuickSearch?.[0] !== undefined &&
      searchDetails?.QuickSearch?.[0] !== ""
    ) {
      sortedField = "rank";
    } else {
      sortedField = "";
    }

    if (selectedFilters.sort !== undefined) {
      sortDirection = selectedFilters.sort;
    } else {
      sortDirection = "ASC";
    }

    if (selectedFilters.pageSize !== undefined) {
      pagination = selectedFilters.pageSize;
    }
    var initialSearchOnLoad = {
      ...searchDetails,
      sortField: sortedField && sortedField !== "Default" ? sortedField : "",
      recordCount: pageSize,
      sortDirection: sortDirection,
      recordStart: start,
    };
    setSearchDetails({
      ...searchDetails,
      sortField: sortedField && sortedField !== "Default" ? sortedField : "",
      recordCount: pagination,
      sortDirection: sortDirection,
    });
    if (searchDetails && !searchDetails?.EISBN) {
      updateAndLoad();
      dispatch(
        fetchSearchResultsNew({ payload: checkPayloadAndReform(initialSearchOnLoad), signal: source })
      );
    } else if (isbnSearch) {
      let sortFieldVlue = sortOrder !== "Sort By" ? sortOrder : "";
      var isbnInitalLoad = {
        ...isbnSearch,
        recordStart: start,
        sortField: sortFieldVlue && sortFieldVlue !== "Default" ? sortFieldVlue : "",
        sortDirection: sort,
      };
      updateAndLoad();
      dispatch(fetchIsbnSearcNew({ payload: isbnInitalLoad, signal: source }));
    }
    setOnPageLoad(true);
    return () => {
      source.cancel();
      hideLoading();
    };
  }, []);


  useEffect(() => {
    let source = Axios.CancelToken.source();
    var sortFieldValue = sortOrder !== "Sort By" ? sortOrder : "";
    if (onpageLoad) {
      if (searchDetails && !searchDetails?.EISBN) {
        updateAndLoad();
        setGlobalC(1);
        setTotalQuantiy([]);
        dispatch(setSelectedTitles([]));
        if(!searchDetails.fromCart) setAllSelected(false)
        let searchDetailsNew = {
          ...searchDetails,
          recordCount: pageSize,
          sortDirection: sort,
          sortField: sortFieldValue && sortFieldValue !== "Default" ? sortFieldValue : "",
          recordStart: start,
        };
        dispatch(
          fetchSearchResultsNew({ payload: checkPayloadAndReform(searchDetailsNew), signal: source })
        );
      } else if (isbnSearch) {
        updateAndLoad();
        let isbnSearchNew = {
          ...isbnSearch,
          sortDirection: sort,
          sortField: sortFieldValue && sortFieldValue !== "Default" ? sortFieldValue : "",
        };
        dispatch(fetchIsbnSearcNew({ payload: isbnSearchNew, signal: source }));
      }
    }
    return () => {
      source.cancel();
    };
  }, [searchDetails, isbnSearch]);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    setBookList([]);
    if (Object?.keys(isbnQuickSearch)?.length !== 0) {
      dispatch(fetchIsbnSearcNew({ payload: isbnQuickSearch, signal: source }));
    }
    return () => {
      source.cancel();
    };
  }, [isbnQuickSearch]);

  useEffect(() => {
    if (location?.state?.isbnQuickSearch) {
      setIsbnQuickSearch(location?.state?.isbnQuickSearch);
    } else {
      setIsbnQuickSearch({});
    }
  }, [location?.state?.isbnQuickSearch]);

  useEffect(() => {
    return () => {
      dispatch(clearSuccess());
      dispatch(clearState());
      dispatch(clearResults());
      dispatch(clearCartsByBookId());
      dispatch(clearString());
    };
  }, []);
  useEffect(() => {
    if (addedItem && !loading) {
      showNotification(Messages.ITEM_ADDED, "success");
      setGlobalC(1);
      setTotalQuantiy([]);
      dispatch(setSelectedTitles([]));
      if (allSelected) {
        updateAndLoad();
        // setAllSelected(false);
        if (searchDetails) {
          setSearchDetails({ ...searchDetails, fromCart: true, recordStart: start });
        } else if (isbnSearch) {
          setIsbnSearch({ ...isbnSearch, recordStart: start });
        } else if (isbnQuickSearch) {
          setIsbnQuickSearch({ ...isbnQuickSearch, recordStart: start });
        }
      }
      dispatch(clearAdded());
    }
  }, [addedItem, loading]);

  useEffect(() => {
    setQuickSearch(location?.state?.searchDetails);
    setSearchDetails(location?.state?.searchDetails);
  }, [location?.state?.searchDetails]);

  useEffect(() => {
    setStrinDetails(searchStrings);
  }, [searchStrings]);

  useEffect(() => {
    if (searchFormState?.isSuccess) {
      setUpdatingData(false);
      if (searchResults?.length) {
        setDataLoading(true);
        setNoData(false);
      } else {
        setNoData(true);
        setDataLoading(false);
      }
      dispatch(clearState());
    }
    if (searchFormState.isError) {
      setNoData(true);
      setUpdatingData(false);
      setDataLoading(false);
      dispatch(clearState());
    }
  }, [searchFormState, searchResults]);

  const changePageSize = (size: number) => {
    setBookList([]);
    updateAndLoad();
    setPageSize(size);
    dispatch(retainPageSize(size))
    if (searchDetails) {
      setSearchDetails({ ...searchDetails, recordCount: size });
    } else if (isbnSearch) {
      setIsbnSearch({ ...isbnSearch, recordCount: size });
    } else if (isbnQuickSearch) {
      setIsbnQuickSearch({ ...isbnQuickSearch, recordCount: size });
    }
  };
  const onPageChange = (page: any, pageSize: any) => {
    dispatch(setSelectAllChkBox(false));
    dispatch(clearSelectedChkBox());
    setBookList([]);
    setCopies(1);
    dispatch(setGlobalCopy(1));
    dispatch(setSelectedTitles([]));
    setGlobalC(1);
    if (!searchFormState.loading) {
      const pageStart = (page - 1) * pageSize + 1;
      setStart(pageStart);
      if (searchDetails) {
        setSearchDetails({ ...searchDetails, recordStart: pageStart });
      } else if (isbnSearch) {
        setIsbnSearch({ ...isbnSearch, recordStart: pageStart });
      } else if (isbnQuickSearch) {
        setIsbnQuickSearch({ ...isbnQuickSearch, recordStart: pageStart });
      }
      setCurrentPage(page);
      dispatch(clearSearchScroll());
    }
    setUnSelectAll(false);
    onCancel();
    setCancelAll(true);
    setChecked([]);
    setCheckedPayload([]);
    setAllSelected(false);
  };

  useEffect(() => {
    setDataLoading(true);
    if (selectedFormat === "all") {
      setBookList(searchResults);
    } else {
      setBookList(filterFormat());
    }
  }, [selectedFormat, searchResults]);

  useEffect(() => {
    !updatingData && setDataLoading(false);
  }, [bookList, updatingData]);

  useEffect(() => {
    if (totalResults > lastCount - start + 1 &&
      bookList &&
      bookList?.length) {
      setPaginationHide(false);
    } else {
      setPaginationHide(true);
    }
  }, [totalResults, lastCount, start, bookList]);

  useEffect(() => {
    const filtersApply = {
      selectedFormat,
      sortOrder,
      pageSize,
      sortIcon,
      sort,
    };
    dispatch(searchFilters(filtersApply));
  }, [pageSize, selectedFormat, sortOrder, sortIcon]);

  useEffect(() => {
    const currentPageState = {
      start,
      currentPage,
      pageSize,
      lastCount,
    };
    dispatch(pageRememberBooklist(currentPageState));
  }, [start, currentPage]);

  const clickArea = (id: any) => { };

  useEffect(() => {
    if (bookList?.length) {
      containerRef?.current.scrollTo(0, scrollHeight ?? scrollValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookList]);

  // const handleScroll = () => {
  //   if (bookList?.length) {
  //     const scrollTop = scrollRef?.current.scrollTop;
  //     scrollTop && setScrollValue(scrollTop);
  //   }
  // };

  useEffect(() => {
    if (strinDetails?.isbn !== undefined) {
      setIsbnInputVal(strinDetails?.isbn?.split(","));
    }
  }, [strinDetails]);

  useEffect(() => {
    dispatch(
      getUnmatchValues({
        totalVal: isbnInputVal,
        unmatchVal: NotMatchedData,
        totalIsbnPrice: totalIsbnprice,
      })
    );
  }, [isbnInputVal, totalIsbnprice, NotMatchedData]);

  useEffect(() => {
    if (addItemtoCartFormstate?.isError && !loading) {
      dispatch(clearAdded());
    }
    if (addItemtoCartFormstate?.isSuccess && globalSelectAll) {
      setTotalQuantiy([]);
      setEptyCheckedArry(true);
      dispatch(emtySelectedChkBox());
      onConfirm();
    }
    // if (globalSelectAll === true) {
    //   onConfirm();
    // }
  }, [addItemtoCartFormstate, globalSelectAll]);

  useEffect(() => {
    setCopies(bookGlobalValue);
  }, [bookGlobalValue]);

  const onItemValChange = (value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    var newEditCellRows: any = { newEditCellRows };
    let foundItem = totalQuantiy?.find(
      (item: { CatalogItemId: any }) =>
        item?.CatalogItemId === value?.CatalogItemId
    );
    if (foundItem) {
      var res = totalQuantiy.map(
        (obj: { CatalogItemId: number }) =>
          [value].find(
            (o: { CatalogItemId: number }) =>
              o.CatalogItemId === obj.CatalogItemId
          ) || obj
      );
      setTotalQuantiy(res);
    } else {
      var ttq = [...totalQuantiy, value].filter(
        (obj: any) => !(obj && Object.keys(obj)?.length === 0)
      );
      setTotalQuantiy(ttq);
    }

    if (allSelected) {
      if (!checked?.includes(value?.CatalogItemId)) {
        let chkVal: any = [...checked];
        chkVal.push(value?.CatalogItemId);
        setChecked(chkVal);
      }
    }
  };

  const containerRef = useRef<any>(null);
  const listRef = useRef<any>(null);
   // Cache to handle dynamic row heights
   const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 50, // Default height for rows until measured
    })
  ).current;


  const calculateDimensions = () => {
    if (containerRef.current) {
        // Clear cache and recompute row heights when necessary
        cache.clearAll();
        if (listRef.current) {
          listRef.current.recomputeRowHeights();
        }
    }
  };
  useEffect(() => {
    // Calculate dimensions on mount
    calculateDimensions();

    // Add event listener to recalculate on window resize
    window.addEventListener('resize', calculateDimensions);

    return () => {
      // Cleanup the event listener on unmount
      window.removeEventListener('resize', calculateDimensions);
    };
  }, [showSearchSideBar, collapseSideBar, cache, cancelAll]);

  const handleScroll = (event:any) => {
    if (listRef.current) {
      const scrollTop = event.target.scrollTop;
      listRef.current.scrollToPosition(scrollTop);
    }
  };

  useEffect(() => {
    if (bookList?.length > 1) {
      containerRef.current.scrollTo(0, scrollHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookList]);
  const handleCheckboxChange = (id: any, isChecked: any) => {
    const newCheckedValues = isChecked == true
    ? [...checkedValues, id]
    : checkedValues.filter((value: any) => value !== id);
    dispatch(setCheckedValues(newCheckedValues));
    const newCheckPayload =  newCheckedValues?.map((item: any) => {
          return {
            CatalogItemId: item,
            BuyerId: "",
            BuyerCartDetailId: 0,
            Quantity: copies,
            SaveAction: "S",
          };
        })
    setCheckedPayload(newCheckPayload); 
    setChecked(newCheckedValues);   
  };


  useEffect(() => {
    const syncScroll = () => {
      if (listRef.current && containerRef.current) {
        const listNode = listRef.current.Grid; // Access the internal Grid of List
        listNode.scrollTop = containerRef.current.scrollTop;
      }
    };

    const handleScroll = () => {
      syncScroll();
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    setStrinDetails({ ...location?.state?.gaString?.gaData });
    if (booklistCurrentPage?.currentPage) {
      setCurrentPage(booklistCurrentPage?.currentPage);
      setStart(booklistCurrentPage?.start);
    }
  }, [location])

  useEffect(() => {
    if (totalQuantiy?.length !== 0) {
      var ttqs = {
        globalCopie: copies,
        amtChangCopie: totalQuantiy,
      };
      dispatch(setSelectedTitles(ttqs));
    } else {
      return undefined;
    }
  }, [totalQuantiy]);

  useEffect(() => {
    if (
      selectedTitles?.globalCopie !== undefined &&
      selectedTitles?.globalCopie !== 1
    ) {
      setGlobalC(selectedTitles?.globalCopie);
    }
  }, [selectedTitles]);

  const handleSwitchSideBar = () => {
    if (showSearchSideBar) {
      dispatch(switchSearchSideBar(false));
    } else {
      dispatch(switchSearchSideBar(true));
    }
  };

  return (
    <div className="search-results">
      <button className="back-button" onClick={goBack}>
        {backText}
      </button>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <div className="results-text">
                <div className="label">
                  <div className="cursor-pointer" onClick={handleSwitchSideBar}>
                    <img
                      src={userPrivate}
                      className="owned-icon"
                      alt="owned"
                    />
                    &nbsp;Refine your search
                    <br />
                  </div>
                  {(quickSearch?.QuickSearch && quickSearch?.QuickSearch[0] !== undefined &&
                    digits_only(quickSearch?.QuickSearch[0]) &&
                    quickSearch?.QuickSearch[0] !== undefined &&
                    quickSearch?.QuickSearch[0]?.length > 12) || location?.state?.cameFromIsbn ? (
                    <>
                      <br />
                      Search Results:{" "}
                      <span className="total_not_matched">
                        Total not matched:{" "}
                        {searchFormState.loading ? 0 : RecordsNotMatched}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          {/* {quickSearch?.QuickSearch[0] !== undefined &&
          quickSearch?.QuickSearch[0] !== "" ? null : ( */}
          {location?.state?.cameFromIsbn && (
            <>
              <Row>
                <Col span={24}>
                  <div className="results-text">
                    <div className="searchString">
                      <TextEllipsis
                        placement="bottomRight"
                        toolTipClass="StringtooltipStyle"
                        maxWidth={80}
                        text={inputSearchStringA}
                        textClassName="textStyle"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="results-text">
                    <div className="searchString">
                      <TextEllipsis
                        placement="bottomRight"
                        toolTipClass="StringtooltipStyle"
                        maxWidth={80}
                        text={inputSearchStringB}
                        textClassName="textStyle"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          <Row className="" justify="space-between">
            <Col xs={18} sm={18} md={6} lg={6} xl={3}>
              <p className="results-title">
                {/* For quick search text */}
                {/* {quickSearch?.QuickSearch[0] !== undefined
                  ? quickSearch?.QuickSearch[0]
                  : ""} */}
                {quickSearch?.QuickSearch && quickSearch?.QuickSearch[0] !== undefined &&
                  digits_only(quickSearch?.QuickSearch[0]) &&
                  quickSearch?.QuickSearch[0] !== undefined &&
                  quickSearch?.QuickSearch[0]?.length > 12
                  ? quickSearch?.QuickSearch[0]
                  : null}
              </p>
            </Col>
            <Col
              xs={14}
              sm={8}
              md={12}
              lg={14}
              xl={15}
              style={{ marginRight: "40px" }}
            >
              <div className="editor-Button editorButton-List">
                <Dropdown
                  overlay={pageOptions}
                  trigger={["click"]}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  disabled={allSelected}
                >
                  <button className="button-type editor">
                    <img src={editIcon} className="edit-icon" alt="" /> Editor
                    &nbsp; &nbsp;
                    <img src={downIcon} className="down-icon" alt="" />
                  </button>
                </Dropdown>
              </div>
            </Col>
            {/* <Col
              xs={4}
              sm={4}
              md={3}
              lg={2}
              xl={1}
              style={{ marginRight: "40px" }}
            >
              <Button type="primary search-overlay">...</Button>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Divider className="books-divider" />
      <Row
        className="saved-search-header"
        justify="space-between"
        gutter={[20, 20]}
      >
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={allSelected ? 10 : 15}
          xl={allSelected ? 12 : 15}
        >
          <Row>
            <Col className="searchTitleCountStyle">
              {selectedFormat === "all" ? (
                <span className="title-count">
                  {totalResults > 0 ? (start! > lastCount ? "1" : start) : "0"}-
                  {lastCount} of {totalResults} Titles
                </span>
              ) : (
                <span className="title-count">
                  {bookList.length} in{" "}
                  {totalResults > 0 ? (start! > lastCount ? "1" : start) : "0"}-
                  {lastCount} of {totalResults} Titles
                </span>
              )}
            </Col>
            <Col span={6} className="filters-contatiner">
              <DropDown
                bordered={false}
                defaultValue={
                  selectedFilters.selectedFormat !== undefined
                    ? selectedFilters.selectedFormat
                    : "Format"
                }
                className="filters"
                optionValue={filterOptions.formats}
                icon={dropdownIcon}
                onChange={handleFormatFilter}
              />
            </Col>
            <Col span={allSelected ? 6 : 7} className="filters-contatiner">
              <DropDown
                defaultValue={
                  selectedFilters.sortOrder !== undefined
                    ? selectedFilters.sortOrder
                    : "Default"
                }
                value={sortOrder}
                bordered={false}
                className="filters"
                optionValue={filterOptions.sorters}
                icon={dropdownIcon}
                onChange={handleSortFilter}
                showSort={true}
                sortIcon={sortIcon}
                onSortClick={onSortClick}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={allSelected ? 14 : 9}
          xl={allSelected ? 12 : 9}
        >
          {allSelected ? (
            <Row>
              <Col xs={7} sm={7} md={8} lg={9} xl={8}>
                <div
                  className={`add_toCart_button ${checkedPayload.length > 0 || totalQuantiy.length > 0 ? "" : "disable-on-unselectall"
                    } `}
                >
                  <Button
                    onClick={() => onAddtoCart(activeCartArray)}
                    type={`add-main-cart-button ${checkedPayload.length > 0 || totalQuantiy.length > 0 ? "" : "disable-on-unselectall"
                      }`}
                      disabled={checkedPayload.length === 0 && totalQuantiy.length ===0}
                      >
                    Add to Cart
                  </Button>
                  <Divider type="vertical" className="cart_button_divider" />
                  <Dropdown
                    overlay={userCarts}
                    className={`${checkedPayload.length > 0 || totalQuantiy.length > 0 ? "" : "disable-on-unselectall"
                      }`}
                    trigger={["click"]}
                    disabled={!(checkedPayload.length > 0 || totalQuantiy.length > 0)}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    placement={"bottomRight"}
                  >
                    <Button
                      type={`cart-tomain-dropdown ${checkedPayload.length > 0 || totalQuantiy.length > 0
                          ? ""
                          : "disable-on-unselectall"
                        }`}
                    >
                      <img
                        src={whiteArrow}
                        style={{ paddingLeft: "5px" }}
                        alt=""
                      />
                    </Button>
                  </Dropdown>
                </div>
              </Col>
              <Col xs={7} sm={7} md={6} lg={6} xl={7} className="cancel">
                <Button type="secondary" onClick={onselectAllCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          ) : (
            <Row justify={!allSelected ? "end" : "start"}>
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={10}
                xl={10}
                className="select-all"
              >
                <Button
                  type="secondary"
                  disabled={!bookList?.length}
                  onClick={onConfirm}
                >
                  Select All
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Divider className="books-divider" />
      <div
        ref={containerRef}
        style={
          screenSize?.dynamicHeight
            ? { overflowY: 'auto', height: paginationHide?screenSize?.dynamicHeight - 280:screenSize?.dynamicHeight - 200 }
            : {}
        }
        id="scrollableDiv"
        className={`scrollContent scrollStyle scrollOnBooksScreen ${
          paginationHide ? "" : "searchResultScroll"
        }`}
        onScroll={handleScroll}
      >
        {totalResults > lastCount - start + 1 &&
        bookList &&
        bookList?.length ? (
          <>
            {allSelected ? (
              <Row>
                <Col span={8} className="selectAllColWrapper">
                  <div className="selectAllWrapper">
                    <Checkbox
                      className="unSelectAllCheckbox"
                      checked={selectAllChkBox}
                      onChange={onUncheckBox}
                    ></Checkbox>
                  </div>
                  <div className="selectAllWrapper">
                    <InputNumber
                      defaultValue={globalC}
                      value={globalC}
                      min={1}
                      max={999}
                      disabled={!unSelectAll}
                      onChange={onValueChange}
                    />
                  </div>
                </Col>
                {/* <Col> */}
                <PaginationTag
                  total={totalResults}
                  current={currentPage}
                  defaultPageSize={pageSize}
                  onChange={onPageChange}
                />
                {/* </Col> */}

                <Divider className="search-divider" />
              </Row>
            ) : (
              <>
                <PaginationTag
                  total={totalResults}
                  current={currentPage}
                  defaultPageSize={pageSize}
                  onChange={onPageChange}
                />
                <Divider className="search-divider" />
              </>
            )}
          </>
        ) : allSelected ? (
          <>
            <Col span={8} className="selectAllColWrapper">
              <div className="selectAllWrapper">
                <Checkbox
                  className="unSelectAllCheckbox"
                  checked={selectAllChkBox}
                  onChange={onUncheckBox}
                ></Checkbox>
              </div>
              <div className="selectAllWrapper">
                <span>Copies: </span>
                <InputNumber
                  defaultValue={1}
                  min={1}
                  max={999}
                  onChange={onValueChange}
                />
              </div>
            </Col>
            <Divider className="search-divider" />
          </>
        ) : null}
        {bookList && bookList.length ? (
          <Checkbox.Group
            style={{ width: "100%" }}
            value={checkedValues}
            onChange={(newCheckedValues: any) => onCustomSelect()}
          >
            <SpinnerLoader loading={searchFormState?.loading || dataLoading}>
            {/* Virtualized List */}
                {/* AutoSizer and List from react-virtualized */}
        <AutoSizer disableHeight>
          {({ width }: any) => (
      <List
        ref={listRef}
        height={containerRef.current ? containerRef.current.clientHeight : 0} // Match the height of the scrollable div
        rowCount={bookList.length} // Total number of rows
        rowHeight={cache.rowHeight} // Dynamic row height based on content
        width={width} // Full width of the container
        deferredMeasurementCache={cache} // Cache for measuring rows
        rowRenderer={({ index, key, parent, style }:any) => (
          <CellMeasurer
            key={key}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}
          >
            {({ measure }) => (
              <div style={style} onLoad={measure} 
              // onScroll={() => {
              //   cache.clear(index, 0);
              //   measure();
              // }}
              >
                <BookRow
                  book={bookList[index]}
                  key={bookList[index].CatalogItemId}
                  isCart={false}
                  allSelected={allSelected}
                  isAddTocartEnable={true}
                  editSelected={false}
                  filteredCarts={filteredCarts}
                  appUser={appUser}
                  showNotification={showNotification}
                  handleCheckbox={handleDeselect}
                  searchStringParam={strinDetails}
                  isCollection={false}
                  clickArea={clickArea}
                  scrollValue={scrollValue}
                  onSelectAll={onConfirm}
                  onCopie={onItemValChange}
                  globalCopie={copies}
                  globalPrevious={globalPrevious}
                  selectAllCancel={cancelAll}
                  globalC={globalC}
                  initialChecked={checkedValues.includes(bookList[index].CatalogItemId)}
                  onCheckboxChange={handleCheckboxChange}
                  // checkedPayload = {checkedPayload}
                />
              </div>
            )}
          </CellMeasurer>
        )} // Function to render each row
        overscanRowCount={10} // Render extra rows for smoother scrolling
        style={{
          overflow: 'hidden', // Explicitly hide overflow to prevent scrollbars
          // pointerEvents: 'none'
        }}
        autoHeight // Automatically adjusts the height based on the content
        // scrollTop={containerRef.current ? containerRef.current.scrollTop : 0} // Sync with outer div scroll
      />)}
      </AutoSizer>
            </SpinnerLoader>
          </Checkbox.Group>
        ) : (
          !searchFormState?.loading &&
          !dataLoading &&
          !bookList.length &&
          noData && (
            <Empty
              style={{ marginTop: "20%" }}
              description="No results found."
            />
          )
        )}
        {totalResults > lastCount - start + 1 &&
        bookList &&
        bookList?.length ? (
          <>
            <PaginationTag
              total={totalResults}
              current={currentPage}
              defaultPageSize={pageSize}
              onChange={onPageChange}
            />
            <Col>
              {selectedFormat === "all" ? (
                <span className="searchTitleStyle">
                  {totalResults > 0 ? (start! > lastCount ? "1" : start) : "0"}-
                  {lastCount} of {totalResults} Titles
                </span>
              ) : (
                <span className="searchTitleStyle">
                  {bookList.length} in{" "}
                  {totalResults > 0 ? (start! > lastCount ? "1" : start) : "0"}-
                  {lastCount} of {totalResults} Titles
                </span>
              )}
            </Col>
          </>
        ) : null}
        <div className="spinner-container">
          <SpinnerLoader
            loading={
              searchFormState?.loading ||
              dataLoading ||
              !(bookList.length > 0 || noData)
            }
          />
        </div>
      </div>
      <NewCartModal
        isModalVisible={showModal}
        setModalVisible={setShowModal}
        appUser={appUser}
        isNewCartDetailsNeed={true}
      />
    </div>
  );
});
