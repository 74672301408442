import { Popover } from "antd"
import React, { useEffect, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";

export const ShelvePopUp = (props: any) => {
    const {row,dragEndStatus,buttonClicked, isModalOpen,isEditModalOpen} = props
    const [visible, setVisible] = useState(false);
    const [rowValue,setRowValue] = useState(false)

    useEffect(()=>{
        if(row === true){
            setRowValue(true)
        }else{
            setRowValue(false)
        }
    },[row])
   
    useEffect(()=>{
        if(dragEndStatus || buttonClicked || isModalOpen || isEditModalOpen){
            setRowValue(false)
        }
    },[dragEndStatus,buttonClicked,isModalOpen,isEditModalOpen])

    return (
        <>
            <div className="shelvesPopover">
                <Popover
                    trigger={["click"]}
                    placement="right"              
                    content={`Click & drag to reorder.`}
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    visible={rowValue || visible}
                >
                    <MoreOutlined onMouseEnter={()=>{setVisible(true)}}  onMouseLeave={()=>{setVisible(false)}}/>
                </Popover>             
            </div>
        </>
    )
}