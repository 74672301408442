import { createSlice } from "@reduxjs/toolkit";
import {
  fulfilledState,
  rejectedState,
  pendingState,
  resetState,
  errorState,
} from "../../../utility/stateUtil";
import {
  recentSettingTab,
  fetchAbook,
  fetchEbook,
  updateAbook,
  updateEbook,
  fetchLibraryUsers,
  fetchLibrarySettings,
  updateLibrarySettings,
  fetchLibraryLogo,
  fetchLibraryLanguage,
  fetchHideCategory,
  fetchRestrictedCategory,
  restrictCategory,
  removeCategory,
  addNewUser,
  updateUser,
  saveRestrictCategoryIDs,
  removeLibraryLogo
} from "../../actions/settings/settings";
import { SettingsState } from "../../states/settings/settingsState";
import { RootState } from "../../store/rootReducer";

const initialState: SettingsState = {
  formState: resetState(),
  defaultSettingTab: "2",
  selectedTab:"1",
  patronData: [],
  eBookData: [],
  aBookData: [],
  SaveRestrictedIds:[],
  libUsersData: [],
  libSettingsData: [],
  patronHoldFormState: resetState(),
  patronReplaceFormState: resetState(),
  libUsersFormState: resetState(),
  libSettingsFormState: resetState(),
  removeFormState: resetState(),
  patronHoldData: [],
  hideCatgoryData:[],
  patronUpdated: false,
  postionUpdated: false,
  errMsg: [],
  holdMgtResizeState: [],
  errEbokMsg: [],
  categoryMsg: [],
  errLibStngkMsg: [],
  errAbokMsg: [],
  libSetngUpdated: false,
  ebokUpdated: false,
  abokUpdated: false,
  ppuEbookState: resetState(),
  ppuAbookState: resetState(),
  ppuEbookUpdateState: resetState(),
  ppuAbookUpdateState: resetState(),
  libLanguage:[],
  libLogo:[],
  restrictedCat:[],
  hideCatgoryFormState: resetState(),
  restrictCatgoryFormState: resetState(),
  addUser: resetState(),
  editUser: resetState(),
  addMsg:[],
  editmsg:[],
  userFilter:"1",
  roleFilter:"ScreenName",
  getLibraryFormState: resetState(),
  updateLibraryFormState: resetState()
};

export const SettingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    clearSettingsState: (state) => {
      state.formState = resetState();
      state.patronHoldFormState = resetState();
      state.patronReplaceFormState = resetState();
      state.defaultSettingTab = "2";
      state.patronData = [];
      state.SaveRestrictedIds = [];
      state.patronHoldData = [];
      state.libSettingsData = [];
      state.eBookData = [];
      state.aBookData = [];
      state.errMsg = [];
      state.errEbokMsg = [];
      state.categoryMsg = [];
      state.errAbokMsg = [];
      state.errAbokMsg = [];
      state.ebokUpdated = false;
      state.abokUpdated = false;
      state.ppuEbookState = resetState();
      state.ppuAbookState = resetState();
      state.ppuEbookUpdateState = resetState();
      state.ppuAbookUpdateState = resetState();
      state.libUsersData = [];
      state.hideCatgoryData = [];
      state.restrictedCat = [];
      state.libUsersFormState = resetState();
      state.restrictCatgoryFormState = resetState();
      state.libSettingsFormState = resetState();
      state.hideCatgoryFormState = resetState();
      state.addUser = resetState();
      state.editUser = resetState();
      state.removeFormState = resetState();
      state.addMsg = [];
      state.editmsg = [];
      state.getLibraryFormState = resetState();
      state.updateLibraryFormState = resetState();
      return state;
    },
    clearPatronUpdate: (state) => {
      state.patronUpdated = false;
      return state;
    },
    clearErrMsg: (state) => {
      state.errMsg = [];
      return state;
    },
    clearEMsg: (state) => {
      state.errEbokMsg = [];
      return state;
    },
    clearHideCatagoryData: (state) => {
      state.hideCatgoryData = [];
      return state;
    },
    clearAMsg: (state) => {
      state.errAbokMsg = [];
      return state;
    },
    clearLibUsers: (state) => {
      state.libUsersData = [];
      state.libUsersFormState = resetState();
      return state;
    },
    clearUserStatus:(state)=>{
      state.addMsg = [];
      state.editmsg = [];
    },
    setSettingTab: (state,{ payload}) => {
      state.selectedTab = payload;
      return state;
    },
    setUserFilter: (state,{ payload}) => {
      state.userFilter = payload;
      return state;
    },
    setRoleFilter: (state,{ payload}) => {
      state.roleFilter = payload;
      return state;
    },
    clearUserFormState:(state)=>{
      state.addUser = resetState();
      state.editUser = resetState();
    },
  },
  extraReducers: {
    [recentSettingTab.fulfilled.toString()]: (state, { payload }) => {
      state.defaultSettingTab = payload;
      state.formState = fulfilledState();
      return state;
    },
    [recentSettingTab.pending.toString()]: (state) => {
      state.formState = pendingState();
      return state;
    },
    [recentSettingTab.rejected.toString()]: (state, { payload }) => {
      state.formState = rejectedState(payload);
      return state;
    },

    [fetchEbook.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.ppuEbookState = errorState(payload.errorMsg);
      } else {
        state.ppuEbookState = fulfilledState();
        state.eBookData = payload;
      }
      return state;
    },
    [fetchEbook.pending.toString()]: (state) => {
      state.ppuEbookState = pendingState();
      return state;
    },
    [fetchEbook.rejected.toString()]: (state, { payload }) => {
      state.ppuEbookState = rejectedState();
      return state;
    },

    [fetchAbook.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.ppuAbookState = errorState(payload.errorMsg);
      } else {
        state.ppuAbookState = fulfilledState();
        state.aBookData = payload;
      }
      return state;
    },
    [fetchAbook.pending.toString()]: (state) => {
      state.ppuAbookState = pendingState();
      return state;
    },
    [fetchAbook.rejected.toString()]: (state, { payload }) => {
      state.ppuAbookState = rejectedState();
      return state;
    },

    [updateEbook.fulfilled.toString()]: (state, { payload }) => {
      if (payload?.ErrorMessage !== undefined && payload?.ErrorMessage !== "") {
        state.ppuEbookUpdateState = errorState(payload.ErrorMessage);
        state.errEbokMsg = payload?.ErrorDetails;
      } else {
        state.ppuEbookUpdateState = fulfilledState();
        state.ebokUpdated = true;
        state.errEbokMsg = [];
      }
      return state;
    },
    [updateEbook.pending.toString()]: (state) => {
      state.ppuEbookUpdateState = pendingState();
      return state;
    },
    [updateEbook.rejected.toString()]: (state, { payload }) => {
      state.ppuEbookUpdateState = rejectedState();
      return state;
    },

    [updateAbook.fulfilled.toString()]: (state, { payload }) => {
      if (payload?.ErrorMessage !== undefined && payload?.ErrorMessage !== "") {
        state.ppuAbookUpdateState = errorState(payload.ErrorMessage);
        state.errAbokMsg = payload?.ErrorDetails;
      } else {
        state.ppuAbookUpdateState = fulfilledState();
        state.abokUpdated = true;
        state.errAbokMsg = [];
      }
      return state;
    },
    [updateAbook.pending.toString()]: (state) => {
      state.ppuAbookUpdateState = pendingState();
      return state;
    },
    [updateAbook.rejected.toString()]: (state, { payload }) => {
      state.ppuAbookUpdateState = rejectedState();
      return state;
    },

    [fetchLibrarySettings.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.libSettingsFormState = errorState(payload.errorMsg);
        state.getLibraryFormState = errorState(payload.errorMsg);
      } else {
        state.libSettingsFormState = fulfilledState();
        state.getLibraryFormState = fulfilledState();
        state.libSettingsData = payload;
      }
      return state;
    },
    [fetchLibrarySettings.pending.toString()]: (state) => {
      state.libSettingsFormState = pendingState();
      state.getLibraryFormState = pendingState();
      return state;
    },
    [fetchLibrarySettings.rejected.toString()]: (state, { payload }) => {
      state.libSettingsFormState = rejectedState();
      state.getLibraryFormState = rejectedState();
      return state;
    },

    [fetchLibraryLogo.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
      } else {
        state.libLogo = payload;
      }
      return state;
    },
    [fetchLibraryLogo.pending.toString()]: (state) => {
      return state;
    },
    [fetchLibraryLogo.rejected.toString()]: (state, { payload }) => {
      return state;
    },

    [removeLibraryLogo.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.removeFormState = errorState(payload.errorMsg);
      } else {
        state.removeFormState = fulfilledState();
      }
      return state;
    },
    [removeLibraryLogo.pending.toString()]: (state) => {
      state.removeFormState = pendingState();
      return state;
    },
    [removeLibraryLogo.rejected.toString()]: (state, { payload }) => {
      state.removeFormState = rejectedState();
      return state;
    },

    [fetchLibraryLanguage.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
      } else {
        state.libLanguage = payload;
      }
      return state;
    },
    [fetchLibraryLanguage.pending.toString()]: (state) => {
      return state;
    },
    [fetchLibraryLanguage.rejected.toString()]: (state, { payload }) => {
      return state;
    },

    [fetchLibraryUsers.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.libUsersFormState = errorState(payload.errorMsg);
      } else {
        state.libUsersFormState = fulfilledState();
        state.libUsersData = payload;
      }
      return state;
    },
    [fetchLibraryUsers.pending.toString()]: (state) => {
      state.libUsersFormState = pendingState();
      return state;
    },
    [fetchLibraryUsers.rejected.toString()]: (state, { payload }) => {
      state.libUsersFormState = rejectedState();
      return state;
    },

    [updateLibrarySettings.fulfilled.toString()]: (state, { payload }) => {
      if (payload?.ErrorMessage !== undefined && payload?.ErrorMessage !== "") {
        state.libSettingsFormState = errorState(payload.ErrorMessage);
        state.updateLibraryFormState = errorState(payload.ErrorMessage);
        state.errEbokMsg = payload?.ErrorDetails;
      } else {
        state.libSettingsFormState = fulfilledState();
        state.updateLibraryFormState = fulfilledState();
        state.libSetngUpdated = true;
        state.errEbokMsg = [];
      }
      return state;
    },
    [updateLibrarySettings.pending.toString()]: (state) => {
      state.libSettingsFormState = pendingState();
      state.updateLibraryFormState = pendingState();
      return state;
    },
    [updateLibrarySettings.rejected.toString()]: (state, { payload }) => {
      state.libSettingsFormState = rejectedState();
      state.updateLibraryFormState = rejectedState();
      return state;
    },
    
    [fetchHideCategory.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.hideCatgoryFormState = errorState(payload.errorMsg);
      } else {
        state.hideCatgoryFormState = fulfilledState();
        state.hideCatgoryData = payload;
      }
      return state;
    },
    [fetchHideCategory.pending.toString()]: (state) => {
      state.hideCatgoryFormState = pendingState();
      return state;
    },
    [fetchHideCategory.rejected.toString()]: (state, { payload }) => {
      state.hideCatgoryFormState = rejectedState();
      return state;
    },

    [fetchRestrictedCategory.fulfilled.toString()]: (state, { payload }) => {
      if (payload.errorMsg) {
        state.restrictCatgoryFormState = errorState(payload.errorMsg);
      } else {
        state.restrictCatgoryFormState = fulfilledState();
        state.restrictedCat = payload;
      }
      return state;
    },
    [fetchRestrictedCategory.pending.toString()]: (state) => {
      state.restrictCatgoryFormState = pendingState();
      return state;
    },
    [fetchRestrictedCategory.rejected.toString()]: (state, { payload }) => {
      state.restrictCatgoryFormState = rejectedState();
      return state;
    },

    [restrictCategory.fulfilled.toString()]: (state, { payload }) => {
      if (payload?.ErrorMessage !== undefined && payload?.ErrorMessage !== "") {
        state.hideCatgoryFormState = errorState(payload.ErrorMessage);
        state.categoryMsg = payload?.ErrorDetails;
      } else {
        state.hideCatgoryFormState = fulfilledState();
        state.libSetngUpdated = true;
        state.categoryMsg = [];
      }
      return state;
    },
    [restrictCategory.pending.toString()]: (state) => {
      state.hideCatgoryFormState = pendingState();
      return state;
    },
    [restrictCategory.rejected.toString()]: (state, { payload }) => {
      state.hideCatgoryFormState = rejectedState();
      return state;
    },

    [saveRestrictCategoryIDs.fulfilled.toString()]: (state, { payload }) => {
      state.SaveRestrictedIds = payload;
      return state;
    },

    [removeCategory.fulfilled.toString()]: (state, { payload }) => {
      if (payload?.ErrorMessage !== undefined && payload?.ErrorMessage !== "") {
        state.restrictCatgoryFormState = errorState(payload.ErrorMessage);
        state.categoryMsg = payload?.ErrorDetails;
      } else {
        state.restrictCatgoryFormState = fulfilledState();
        state.libSetngUpdated = true;
        state.categoryMsg = [];
      }
      return state;
    },
    [removeCategory.pending.toString()]: (state) => {
      state.restrictCatgoryFormState = pendingState();
      return state;
    },
    [removeCategory.rejected.toString()]: (state, { payload }) => {
      state.restrictCatgoryFormState = rejectedState();
      return state;
    },

    [addNewUser.fulfilled.toString()]: (state, { payload }) => {
      if (payload.ErrorMessage) {
        state.addUser = errorState(payload.errorMsg);
        state.addMsg = payload;
      } else {
        state.addUser = fulfilledState();
        state.addMsg = payload;
      }
      return state;
    },
    [addNewUser.pending.toString()]: (state) => {
      state.addUser = pendingState();
      return state;
    },
    [addNewUser.rejected.toString()]: (state, { payload }) => {
      state.addUser = rejectedState();
      return state;
    },
    [updateUser.fulfilled.toString()]: (state, { payload }) => {
      if (payload.ErrorMessage) {
        state.editUser = errorState(payload.errorMsg);
        state.editmsg = payload;
      } else {
        state.editUser = fulfilledState();
        state.editmsg = payload;
      }
      return state;
    },
    [updateUser.pending.toString()]: (state) => {
      state.editUser = pendingState();
      return state;
    },
    [updateUser.rejected.toString()]: (state, { payload }) => {
      state.editUser = rejectedState();
      return state;
    },
  },
});

export const {
  clearSettingsState,
  clearPatronUpdate,
  clearErrMsg,
  clearEMsg,
  clearAMsg,
  clearLibUsers,
  clearUserStatus,
  setSettingTab,
  clearHideCatagoryData,
  setUserFilter,
  setRoleFilter,
  clearUserFormState
} = SettingsSlice.actions;
export const SettingStateSelector = (state: RootState) => state.settings;
export default SettingsSlice.reducer;
