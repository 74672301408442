import React, { useEffect, useRef, useState } from "react";
import { Row, Col, List, Empty, Tooltip, message, Checkbox } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import "./shelves.less";
import { deleteIcon, editIcon, notFound } from "../../../images";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import Button from "../../stateless/common/button";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../router/appRoutes";
import SpinnerLoader from "../../stateless/common/spinnerLoader";
import { SyncOutlined } from "@ant-design/icons";
import {
  clearShelvItems,
  ShelfstateSelector,
  // setShelvesFilter,
  clearShelvDetails,
  cleardeleteFormState,
  clearRefeshShelv,
  clearNewlyCrtedShelv
} from "../../../../redux/reducers/shelves/shelfReducer";
import {
  deleteShelve,
  fetchShelveItems,
  preDeleteShelv,
  refreshShelv,
  retainShelvState,
  retainShelvPostion,
  updateShelvAfterSync,
  setShelfItemOrder,
} from "../../../../redux/actions/shelves/shelfAction";
// import DropDown from "../../stateless/common/dropDown";
import RemoveConfirmModel from "../carts/cartDetails/remove/removeModal";
import { ShelvePopUp } from "./shelvePopUp";

const chkBoxOptions = [
  {
    label: "Automated",
    value: 3,
  },
  {
    label: "Manual",
    value: 4,
  },
  {
    label: "Live",
    value: 1,
  },
  {
    label: "Draft",
    value: 2,
  },
];

export const AllShelves = (props: any) => {
  const {
    libId,
    imageUrl,
    isModalOpen,
    dynamiCount,
    setShelvData,
    setShowEditMode,
    isEditModalOpen,
    unSelectRow,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    shelves,
    shelvSyncData,
    shelveItems,
    formState,
    updateShlvFormState,
    itemsFormState,
    preShelf,
    preDeleteFormState,
    refreshShelvFormState,
    deleteFormState,
    shelvListState,
    shelvListPostion,
    newlyCrtedShelv
  } = useSelector(ShelfstateSelector);
  const { appUser } = useSelector(userStateSelector);
  const myRef = useRef<any>(null);
  const [data, setData] = useState<any>([]);
  const [allShelves, setAllShelves] = useState<any>(shelves);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [showRemove, setShowRemove] = useState(false);
  const [showSyncPending, setShowSyncPending] = useState<boolean>(false);
  const [itemLoading, setItemLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<any>("0");
  const [deleteRowItem, setDeleteRowItem] = useState<any>([]);
  const [start, setStart] = useState<number>(0);
  const [showPatrons, setShowPatrons] = useState<number>(0);
  const [showSyncButton, setShowSyncButton] = useState<number>(0);
  const [dragEndStatus, setDragEndStatus] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [hidePatron, setHidePatron] = useState(false);
  const [chkBoxFiltr, setChkBoxFiltr] = useState(shelvListState);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isDraft, setIsDraft] = useState<boolean>(true);
  const [isAutomatic, setIsAutomatic] = useState<boolean>(true);
  const [isManual, setIsManual] = useState<boolean>(true);
  const [screenSize, getDimension] = useState<any>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  var currency =
    appUser && appUser.libraryData
      ? appUser.libraryData[0]?.CurrencySymbol
      : "";


  useEffect(()=>{
    setAllShelves(shelves)
  },[shelves])

  const handleListOrder = (shelfId: number, destination: any) => {
    var payload = {
      LibraryId: libId,
      ShelfId: shelfId,
      SortOrder: destination,
    };
    dispatch(setShelfItemOrder(payload));
  };

  useEffect(()=>{
      return ()=>{
        dispatch(clearNewlyCrtedShelv())
      }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };



  useEffect(() => {
    if (unSelectRow) {
      setSelectedRow([]);
    }
  }, [unSelectRow]);

  const filterVariableShelv =(checedVal:any)=>{
    if (checedVal.includes(1)) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    if (checedVal.includes(2)) {
      setIsDraft(true);
    } else {
      setIsDraft(false);
    }
    if (checedVal.includes(3)) {
      setIsAutomatic(true);
    } else {
      setIsAutomatic(false);
    }
    if (checedVal.includes(4)) {
      setIsManual(true);
    } else {
      setIsManual(false);
    }
  }

  useEffect(() => {
    setChkBoxFiltr(shelvListState);
    filterVariableShelv(shelvListState)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleHideItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamiCount]);

  useEffect(() => {
    if (isEditModalOpen) {
      setShowEditMode(true);
    } else {
      setShowEditMode(false);
      setShelvData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModalOpen]);

  const onChangeChkBox = (checkedValues: any) => {
    setChkBoxFiltr(checkedValues);
    filterVariableShelv(checkedValues)
    dispatch(retainShelvState(checkedValues))
  };

  useEffect(() => {
    setData(
      allShelves?.filter((item: any) => {
        if (isActive && isDraft && isAutomatic && isManual) {
          return (
            item?.IsQuickConnect === false && item?.CurrentStatus !== "Error"
          );
        } else if (isActive && isDraft) {
          if (isAutomatic && isManual) {
            return (
              item?.IsQuickConnect === false && item?.CurrentStatus !== "Error"
            );
          } else if (isAutomatic && !isManual) {
            return (
              item?.IsQuickConnect === false &&
              item?.IsManual === false &&
              item?.CurrentStatus !== "Error"
            );
          } else if (isManual && !isAutomatic) {
            return (
              item?.IsQuickConnect === false &&
              item?.IsManual === true &&
              item?.CurrentStatus !== "Error"
            );
          } else {
            return undefined;
          }
        } else if (isActive) {
          if (isAutomatic && isManual) {
            return (
              item?.IsActive === true &&
              item?.IsQuickConnect === false &&
              item?.CurrentStatus !== "Error"
            );
          } else if (isAutomatic && !isManual) {
            return (
              item?.IsActive === true &&
              item?.IsQuickConnect === false &&
              item?.IsManual === false &&
              item?.CurrentStatus !== "Error"
            );
          } else if (isManual && !isAutomatic) {
            return (
              item?.IsActive === true &&
              item?.IsQuickConnect === false &&
              item?.IsManual === true &&
              item?.CurrentStatus !== "Error"
            );
          } else {
            return undefined;
          }
        } else if (isDraft) {
          if (isAutomatic && isManual) {
            return (
              item?.IsActive === false &&
              item?.IsQuickConnect === false &&
              item?.CurrentStatus !== "Error"
            );
          } else if (isAutomatic && !isManual) {
            return (
              item?.IsActive === false &&
              item?.IsQuickConnect === false &&
              item?.IsManual === false &&
              item?.CurrentStatus !== "Error"
            );
          } else if (isManual && !isAutomatic) {
            return (
              item?.IsActive === false &&
              item?.IsQuickConnect === false &&
              item?.IsManual === true &&
              item?.CurrentStatus !== "Error"
            );
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isDraft, isAutomatic, isManual,allShelves]);

  useEffect(() => {
    setTitle(
      data?.filter((item: any) => {
        return item?.CurrentStatus !== "Error";
      }).length
    );
  }, [data]);

  const handleShowItems = (item: any) => {
    setStart(dynamiCount);
    setShowPatrons(item?.ShelfId);
    dispatch(
      fetchShelveItems({
        catShelfId: item?.ShelfId,
        recordCount: dynamiCount,
        recordStart: 0,
      })
    )
  };

  const handelRefresh = (item: any) => {
    dispatch(clearNewlyCrtedShelv())
    setButtonClicked(true);
    setShowSyncButton(item?.ShelfId);
    dispatch(
      refreshShelv({
        catShelfId: item?.ShelfId,
        fetchSelvItem: {
          libraryId:libId,
          catShelfId: item?.ShelfId,
          recordCount: 250,
          recordStart: 0,
        },
      })
    );
    setStart(dynamiCount);
    setShowPatrons(0);
  };

  const handleHideItems = () => {
    setHidePatron(true);
    setButtonClicked(false);
    setStart(dynamiCount);
    setShowPatrons(0);
    dispatch(clearShelvItems());
  };

  useEffect(() => {
    if (hidePatron) {
      setSelectedRow([]);
      setHidePatron(false);
    }
  }, [hidePatron]);
  useEffect(() => {
    if (isModalOpen === true) {
      setSelectedRow(false);
    }
  }, [isModalOpen]);

  const handleNextItems = (item: any) => {
    setStart(start + dynamiCount);
    var payload = {
      catShelfId: item?.ShelfId,
      recordCount: dynamiCount,
      recordStart: start,
    };
    dispatch(fetchShelveItems(payload));
  };

  const HandleResetStart = (item: any) => {
    setStart(dynamiCount);
    var payload = {
      catShelfId: item?.ShelfId,
      recordCount: dynamiCount,
      recordStart: 0,
    };
    dispatch(fetchShelveItems(payload));
  };

  const onSelecttitle = (row: any) => {
    history.push(AppRoutes.ITEMDETAILS, {
      book: row,
    });
  };

  const handleShowDetail = (data: any) => {
    dispatch(clearShelvDetails());
    history.push(AppRoutes.SHELVDETAILS, {
      shelv: data,
      cammeFromAutoCreate: false,
      shelvListPostion:shelvListPostion,
      shelvListState:shelvListState
    });
  };

  const handleEditDetail = (data: any) => {
    setShelvData(data);
    setShowEditMode(true);
  };

  const handleDeleteItem = (data: any) => {
    setDeleteRowItem(data);
    setShowRemove(true);
    dispatch(
      preDeleteShelv({
        libraryId: data?.LibraryId,
        shelfId: data?.ShelfId,
      })
    );
  };

  const handleShelvDelete = () => {
    dispatch(
      deleteShelve({
        libId: deleteRowItem?.LibraryId,
        shelfId: deleteRowItem?.ShelfId,
      })
    );
  };

  useEffect(() => {
    if (refreshShelvFormState?.loading) {
      setShowSyncPending(true);
      setItemLoading(true);
    }
    if (!refreshShelvFormState?.loading) {
      setShowSyncPending(false);
      setSelectedRow(false);
      setButtonClicked(false);
    }
    if (itemsFormState?.loading) {
      setItemLoading(false);
    }
    if (!refreshShelvFormState?.isSuccess) {
      setItemLoading(false);
    }
  }, [refreshShelvFormState, itemsFormState]);

  const showNotification = (content: any, type: any) => {
    if (type === "success") {
      message.success({
        content: content,
        key: "success",
        duration: 4,
      });
    } else if (type === "error") {
      message.error({
        content: content,
        key: "error",
        duration: 4,
      });
    }
    dispatch(cleardeleteFormState());
  };

  useEffect(() => {
    if (deleteFormState.isSuccess) {
      showNotification("Shelf removed successfully", "success");
    } else if (deleteFormState.isError) {
      showNotification("The request is invalid", "error");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFormState]);

    const hadelScroll =()=>{
      const scrollTop = myRef.current.scrollTop
      dispatch(retainShelvPostion(scrollTop))
    }

    useEffect(()=>{
      if(data?.length > 1){
        myRef.current.scrollTo(0, shelvListPostion);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data])

    useEffect(() => {
      if(shelvSyncData?.ShelfId !== undefined && shelvSyncData?.ShelfId !== null){
        dispatch(updateShelvAfterSync(shelves?.map((allShelves: { ShelfId: any; }) => {
          if (allShelves?.ShelfId === shelvSyncData?.ShelfId) {
            return {
              ...allShelves,
              ItemsCount: shelvSyncData?.Items
            }
          }
          return allShelves;
        })))
     dispatch(clearRefeshShelv())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shelvSyncData]);

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    border: isDragging && "2px solid",
    borderRadius: isDragging && "10px",
    background: isDragging && "white",
    ...draggableStyle,
  });


  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) return;

    const items = [...data];
    const item = items.splice(source.index, 1)[0];
    const datax = [...data];
    const itemOld = datax.slice(destination.index)[0];
    handleListOrder(item.ShelfId, itemOld?.SortOrder);

    items.splice(destination.index, 0, item);
    setData(items);
  };

  return (
    <>
      <SpinnerLoader
        loading={formState.loading || updateShlvFormState?.loading}
      >
        <Row
          justify="space-between"
          gutter={[20, 20]}
          onClick={() => {
            setDragEndStatus(true);
            setSelectedRow([]);
          }}
        >
          <Col xs={24} sm={24} md={24} lg={15} xl={15}>
            <Row>
              <Col span={3} className="title-adjustment">
                <span className="title-count">{title} Shelves</span>
              </Col>
              <Col span={20} className="filters-contatiner-byCheckbox">
                <Checkbox.Group
                  options={chkBoxOptions}
                  defaultValue={chkBoxFiltr}
                  onChange={onChangeChkBox}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div id="shelvScrollableDiv" ref={myRef} style={{maxHeight:screenSize?.dynamicHeight-153}} onScroll={hadelScroll} className="shelveScroll scrollContent scrollStyle">
              {allShelves && allShelves.length && data.length ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided: any) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <List
                          itemLayout="vertical"
                          size="large"
                          className={
                            chkBoxFiltr?.length > 3
                              ? "books-shelves"
                              : "manAutoShelve"
                          }
                          dataSource={[...data]}
                          renderItem={(item: any, index: number) => {
                            const draggble = item?.ShelfName !== "shelf Name.";
                            return (
                              <List.Item
                                key={item?.ShelfId}
                                onClick={() => {
                                  selectedRow?.ShelfId === item?.ShelfId
                                    ? setSelectedRow([])
                                    : setSelectedRow(item);
                                  setDragEndStatus(false);
                                }}
                                className={`${draggble ? "draggble" : ""}  ${
                                  selectedRow?.ShelfId === item?.ShelfId &&
                                  chkBoxFiltr?.length > 3
                                    ? "activeList"
                                    : "inactiveList"
                                }`}
                              >
                                <div>
                                  <Draggable
                                    key={item.ShelfId.toString()}
                                    draggableId={item.ShelfId.toString()}
                                    index={index}
                                  >
                                    {(provided: any, snapshot) => (
                                      <div
                                        className="card"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        {chkBoxFiltr?.length > 3 ? (
                                          <Row>
                                            <Col
                                              className="DragDroptoolTip"
                                              span={4}
                                            >
                                              {chkBoxFiltr?.length > 3 ? (
                                                <ShelvePopUp
                                                  row={
                                                    selectedRow?.ShelfId ===
                                                    item?.ShelfId
                                                  }
                                                  dragEndStatus={dragEndStatus}
                                                  buttonClicked={buttonClicked}
                                                  isModalOpen={isModalOpen}
                                                  isEditModalOpen={
                                                    isEditModalOpen
                                                  }
                                                />
                                              ) : (
                                                <p></p>
                                              )}
                                            </Col>
                                            <Col
                                              onClick={() =>
                                                handleShowDetail(item)
                                              }
                                              className="DragDroptoolTip"
                                              span={20}
                                            ></Col>
                                          </Row>
                                        ) : null}
                                        <div
                                          className={`List-Card-Content ${
                                            chkBoxFiltr?.length > 3
                                              ? ""
                                              : "List-Card-Content-noPopover"
                                          }`}
                                        >
                                          {" "}
                                          {/* new degine */}
                                          <div className="shelvsListnewStructure">
                                            <div
                                              onClick={() =>
                                                handleShowDetail(item)
                                              }
                                              className="newRowA"
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <div className="rowAContentSection">
                                                <Row>
                                                  <Col>
                                                    <div className="card-titel">
                                                      <span
                                                        onClick={() =>
                                                          handleShowDetail(item)
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {item?.ShelfName}
                                                      </span>
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col
                                                    sm={14}
                                                    md={13}
                                                    lg={12}
                                                    xl={8}
                                                  >
                                                    <div className="card-info card-info-padding">
                                                      <span className="card-name-slip">
                                                        Created by:
                                                      </span>
                                                      <span className="card-name-decrip">
                                                        {item.CreateUserId}
                                                      </span>
                                                    </div>
                                                    <div className="card-info">
                                                      <span className="card-name-slip">
                                                        Titles:
                                                      </span>
                                                      <span className="card-name-decrip">
                                                        {item.ItemsCount}
                                                      </span>
                                                    </div>
                                                  </Col>
                                                  <Col
                                                    sm={10}
                                                    md={11}
                                                    lg={12}
                                                    xl={14}
                                                  >
                                                    <div className="card-info card-info-padding">
                                                      <span className="card-name-slip">
                                                        Description:
                                                      </span>
                                                      <span className="card-name-decrip">
                                                        {item.Description}
                                                      </span>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </div>
                                            <div
                                              className="newRowB"
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <div className="rowBContentSection">
                                                <Row>
                                                  <Col span={24}>
                                                    <div className="card-header-button">
                                                      <Row>
                                                        <Col
                                                          className="card-btn-row"
                                                          span={24}
                                                        >
                                                          {!item?.IsManual ? (
                                                            <div className="refreshBtn">
                                                              <div className="syncMsgStyle">
                                                                {item?.CurrentStatus ===
                                                                "Ready"
                                                                  ? showSyncButton ===
                                                                    item?.ShelfId
                                                                    ? showSyncPending
                                                                      ? null
                                                                      : "Sync in progress"
                                                                    : "Sync in progress"
                                                                  : null}
                                                                {showSyncButton ===
                                                                item?.ShelfId
                                                                  ? showSyncPending
                                                                    ? "Sync in progress"
                                                                    : null
                                                                  : null}
                                                                {newlyCrtedShelv?.LibraryId &&
                                                                item?.ShelfId ===
                                                                  newlyCrtedShelv?.ShelfId ? (
                                                                  <div
                                                                    onClick={() =>
                                                                      handelRefresh(
                                                                        item
                                                                      )
                                                                    }
                                                                  >
                                                                    Sync in
                                                                    progress
                                                                  </div>
                                                                ) : null}
                                                              </div>
                                                              <SyncOutlined
                                                                onClick={() =>
                                                                  handelRefresh(
                                                                    item
                                                                  )
                                                                }
                                                                className="syncIcon"
                                                              />
                                                            </div>
                                                          ) : null}
                                                          {item?.IsManual ? (
                                                            <Button type="pink-btn btn-size-fixed">
                                                              MANUAL
                                                            </Button>
                                                          ) : (
                                                            <Button type="blue-btn btn-size-fixed">
                                                              AUTOMATED
                                                            </Button>
                                                          )}
                                                          {item?.IsActive ? (
                                                            <Button type="green-btn btn-size-fixed">
                                                              LIVE
                                                            </Button>
                                                          ) : (
                                                            <Button type="green-btn btn-size-fixed yellow-btn">
                                                              DRAFT
                                                            </Button>
                                                          )}
                                                          <div className="edit-delet-btns">
                                                            <div className="editBtn">
                                                              <img
                                                                src={editIcon}
                                                                alt="deleteIcon"
                                                                onClick={() =>
                                                                  handleEditDetail(
                                                                    item
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                            <div className="deleteBtn">
                                                              <img
                                                                src={deleteIcon}
                                                                alt="deleteIcon"
                                                                onClick={() => {
                                                                  handleDeleteItem(
                                                                    item
                                                                  );
                                                                  setButtonClicked(
                                                                    true
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col span={24}>
                                                    <div className="shelv-show-Btn">
                                                      <div className="shelv-rightBtn">
                                                        {showPatrons !==
                                                        item?.ShelfId ? (
                                                          <Button
                                                            type="primary new-cart show-btn"
                                                            onClick={() => {
                                                              handleShowItems(
                                                                item
                                                              );
                                                              setButtonClicked(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            Patron Preview
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                            type="primary new-cart show-btn"
                                                            onClick={
                                                              handleHideItems
                                                            }
                                                          >
                                                            Hide Preview
                                                          </Button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </div>
                                            <div className="newRowC">
                                              <Row>
                                                <Col span={24}>
                                                  <div className="card-books-container">
                                                    <Row>
                                                      {showPatrons ===
                                                      item?.ShelfId ? (
                                                        <>
                                                          <Col
                                                            className="card-books"
                                                            span={22}
                                                          >
                                                            {start >
                                                            dynamiCount ? (
                                                              <span
                                                                onClick={() =>
                                                                  HandleResetStart(
                                                                    item
                                                                  )
                                                                }
                                                                className="left_Arrow showArrow"
                                                              >
                                                                &#xab;
                                                              </span>
                                                            ) : null}

                                                            <SpinnerLoader
                                                              loading={
                                                                itemsFormState.loading ||
                                                                refreshShelvFormState?.loading ||
                                                                itemLoading
                                                              }
                                                            >
                                                              {shelveItems
                                                                ?.slice(
                                                                  0,
                                                                  dynamiCount
                                                                )
                                                                ?.map(
                                                                  (
                                                                    item: any
                                                                  ) => {
                                                                    return (
                                                                      <Tooltip
                                                                        title={
                                                                          item?.Title
                                                                        }
                                                                        key={
                                                                          item?.Title
                                                                        }
                                                                        placement="bottom"
                                                                      >
                                                                        <div className="shelves-img-container">
                                                                          <img
                                                                            onClick={() =>
                                                                              onSelecttitle(
                                                                                item
                                                                              )
                                                                            }
                                                                            src={`https://${imageUrl}?type=DOCUMENTIMAGE&documentID=${item?.ImageId}&size=LARGE&token=nobody`}
                                                                            alt="img"
                                                                            onError={(
                                                                              e: any
                                                                            ) => {
                                                                              e.target.src =
                                                                                notFound;
                                                                            }}
                                                                          />
                                                                          <div className="shelve-img-transbox">
                                                                            <p className="shelve-img-title">
                                                                              {
                                                                                item?.Title
                                                                              }
                                                                            </p>
                                                                            <p className="shelve-img-price">
                                                                              {
                                                                                currency
                                                                              }
                                                                              {item?.RetailPrice.toFixed(
                                                                                2
                                                                              )}
                                                                            </p>
                                                                          </div>
                                                                        </div>
                                                                      </Tooltip>
                                                                    );
                                                                  }
                                                                )}
                                                            </SpinnerLoader>
                                                          </Col>
                                                          {!itemsFormState.loading ? (
                                                            item?.ItemsCount -
                                                              start >
                                                            0 ? (
                                                              <span
                                                                className="text-count text-countClick"
                                                                onClick={() =>
                                                                  handleNextItems(
                                                                    item
                                                                  )
                                                                }
                                                              >
                                                                +
                                                                {item?.ItemsCount -
                                                                  start}
                                                              </span>
                                                            ) : (
                                                              <span className="text-count"></span>
                                                            )
                                                          ) : null}
                                                        </>
                                                      ) : null}
                                                    </Row>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                </div>
                              </List.Item>
                            );
                          }}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                !formState.loading && (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ marginTop: "20%" }}
                    description="No current published shelves"
                  />
                )
              )}
            </div>
          </Col>
        </Row>
      </SpinnerLoader>
      {preDeleteFormState?.isSuccess ? (
        <RemoveConfirmModel
          showRemove={showRemove}
          setButtonClicked={setButtonClicked}
          setSelectedRow={setSelectedRow}
          setShowRemove={setShowRemove}
          removeConfirm={handleShelvDelete}
          heading={"Remove Confirmation"}
          message={`Shelf ${deleteRowItem?.ShelfName} is used by ${preShelf.length} carts. Are you sure you want to delete?`}
          associateCardCount={preShelf.length}
          deleteRowItem={deleteRowItem}
        />
      ) : null}
    </>
  );
};
