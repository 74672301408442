import React from "react";
import { Tooltip } from "antd";
import "./ellipsis.less";

const TextEllipsis = (props: any) => {
  const {
    className,
    textClassName,
    text,
    maxWidth,
    toolTipClass,
    placement
  } = props;

  return (
    <>
      <div className={`ellipsis ${className}`}>
      <Tooltip title={text} placement={placement} overlayClassName={toolTipClass}>
      <div style={{maxWidth:`${maxWidth}%`}} className={`text-overflow ${textClassName}`}> {text} </div>
      </Tooltip>
      </div>
    </>
  );
};
export default TextEllipsis;
