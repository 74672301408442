import React from 'react';
import { Row, Modal } from "antd";
import Button from "../../stateless/common/button";

const ClearAudienceModel = (props:any) => {
    const { clearModal, setClearModal, setConfirmClearAudience, selectedCartItems, setSelectedChunkData } = props

    const onCancelModel=()=>{
        setClearModal(false)
        setConfirmClearAudience(false)
        setSelectedChunkData([])
    }

    const handleSubmit = () => {
        setClearModal(false)
        setConfirmClearAudience(true)
    }

  return (
<Modal
    wrapClassName="purchase-modal"
    visible={clearModal}
    onCancel={onCancelModel}
    footer={
          <Row justify="space-around">
          <Button
            type="primary confirm-button"
            onClick={handleSubmit}
          >
            Yes
          </Button>
          <Button
            type="smallRed confirm-button"
            onClick={onCancelModel}
          >
            No
          </Button>
          </Row>
      }
    >
        <div className="remove-content">
        <div className='purchaseNote'>
        This will clear all library-specific target audience data for the selected items. Target audience data for selected items will be returned to the default, publisher-provided state.
        <div className='purchaseNote'>
        Clear data for {selectedCartItems.length} book(s)?
        </div>
        </div>
        </div>

    </Modal>
  )

}

export default ClearAudienceModel