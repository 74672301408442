import React from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Divider,
  Radio,
  TreeSelect,
  Input,
  message,
  Empty,
} from "antd";
import "../search/advancedSearch/searchForm.less";
import DropDown from "../../stateless/common/dropDown";
import { filterOptions } from "../../../constants/constants";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { downIcon, dropdownIcon, searchIcon } from "../../../images";
import Button from "../../stateless/common/button";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchstateSelector } from "../../../../redux/reducers/search/searchReducer";
import Category from "../../../models/search/category";
import { useHistory, useLocation } from "react-router-dom";
import {
  clearNewShelv,
  clearState,
  clearUpdateError,
  getPreviousLocaton,
  ShelfstateSelector,
} from "../../../../redux/reducers/shelves/shelfReducer";
import { userStateSelector } from "../../../../redux/reducers/user/userReducer";
import {
  createNewAutoShelve,
  editAutoShelv,
  newlyCreatedShelv,
} from "../../../../redux/actions/shelves/shelfAction";
import { AppRoutes } from "../../../router/appRoutes";
import SpinnerLoader from "../../stateless/common/spinnerLoader";

export const CreateAutoShelfForm = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const { createAutoShelv } = location.state;
  const dispatch = useDispatch();
  const [radioValue, setRadioValue] = React.useState(1);
  const [selectedFormats, setSelectedFormats] = useState<any>([]);
  const [treeData, setTreeData] = useState<any>();
  const [navigation, setNavigation] = useState<boolean>(false);
  const [newCatObj, setNewCatObj] = useState<any>();
  const [treevalue, setTreeValue] = useState<any>();
  const { appUser } = useSelector(userStateSelector);
  var LibraryId =
    appUser && appUser.libraryData ? appUser.libraryData[0].LibraryId : 0;
  const {
    audienceData,
    publishers,
    mediaTypes,
    languages,
    categoriesOptions,
    audienceDataFormState,
    contentProvidersFormState,
    publishersFormState,
    mediaTypeFormState,
    languageSearchFormState,
    categoryOptionsFormState,
  } = useSelector(SearchstateSelector);
  const {
    shelves,
    newShelve,
    newAutoShelveFormState,
    formState,
    updateShelvError,
    updateShlvFormState,
  } = useSelector(ShelfstateSelector);

  let parentData = {
    ShelfId: 0,
    LibraryId: LibraryId,
    ShelfName: createAutoShelv?.shelveName,
    ReaktorShelfId: null,
    Description: createAutoShelv?.shelveDescription,
    IsActive: false,
    IsManual: false,
    SortOrder: 0,
    NextRefreshTime: null,
    SortStrategy: "Title",
    CatShelfCriteria: [],
    ShelfItems: [],
    CatShelfAllItems: [],
    Items: [],
    ItemsCount: 0,
  };

  let childData = {
    AudienceTypeId: null,
    Author: null,
    AuthorSearch: "",
    Category: null,
    itemformatid: { Ebooks: null, Audiobooks: null },
    ItemFormatIdSet: null,
    MediaTypeIdSet: [1, 2],
    Language: "",
    MaxBooks: 250,
    MostPopularDays: "0",
    Publisher: null,
    PublishedWithinDays: "0",
    PurchasedWithinDays: "0",
    SortStrategy: createAutoShelv?.SortStrategy,
    TargetAudienceIds: null,
    TreatNewCopiesAsNewBooks: "F",
    MediaTypeIdSetAsString: 1,
  };

  const [creatShelvData, setCreatShelvData] = useState<any>(
    createAutoShelv?.editCriteriaData !== undefined
      ? createAutoShelv?.editCriteriaData
      : parentData
  );
  const [creatShelvItem, setCreatShelvItem] = useState<any>(
    createAutoShelv?.editCriteriaData?.CatShelfCriteria !== undefined
      ? createAutoShelv?.editCriteriaData?.CatShelfCriteria
      : childData
  );

  useEffect(() => {
    if (createAutoShelv?.editCriteriaData !== undefined) {
      setCreatShelvData(createAutoShelv?.editCriteriaData);
      setCreatShelvItem(createAutoShelv?.editCriteriaData?.CatShelfCriteria);
      if (
        createAutoShelv?.editCriteriaData?.CatShelfCriteria
          ?.TreatNewCopiesAsNewBooks === "T"
      ) {
        setRadioValue(2);
      } else {
        setRadioValue(1);
      }
    } else {
      setCreatShelvData({
        ...creatShelvData,
        ShelfName: createAutoShelv?.shelveName,
        Description: createAutoShelv?.shelveDescription,
        LibraryId: LibraryId,
      });
      setCreatShelvItem({
        ...creatShelvItem,
        SortStrategy: createAutoShelv?.SortStrategy,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAutoShelv, LibraryId]);

  const getPublisherOptions = () => {
    var publisherOptions = publishers?.map((data: any) => {
      return { text: data.PublisherName, value: data.PublisherId };
    });
    return publisherOptions;
  };

  const getAudienceOptions = () => {
    var audienceOptions = audienceData?.map((data: any) => {
      return { text: data.Name, value: data.AgeClassificationId };
    });
    return audienceOptions;
  };

  const getLanguageOptions = () => {
    var languageOptions = languages
    ?.filter((language: any) => !(language.LanguageName === "Not Specified" && language.LanguageCode !== "n/a"))
    ?.map((data: any) => {
      return { text: data.LanguageName, value: data.LanguageCode };
    });
    return languageOptions;
  };

  const getNewCatObject = (categoriesOptions: any) => {
    function onlyUnique(value: any, index: any, self: any) {
      return self?.indexOf(value) === index;
    }
    var totalCatGroup = categoriesOptions?.map((data: any) => {
      var group = data?.CategoryGroup;
      return group;
    }, []);
    var totalUniqCatG = totalCatGroup?.filter(onlyUnique);

    function pushNewArray(groupN: string) {
      var filterData = categoriesOptions?.filter((data: any) => {
        return data?.CategoryGroup === groupN;
      });
      return filterData;
    }
    var newFilterObject = totalUniqCatG.map((item: any) => {
      return {
        CategoryGroup: item,
        category: pushNewArray(item),
      };
    });
    var finalNewData = newFilterObject?.map((item: any) => {
      if (item?.category?.length > 1) {
        return {
          CategoryGroup: item?.CategoryGroup,
          category: item?.category,
        };
      } else {
        return {
          CategoryGroup: item?.CategoryGroup,
          category: item?.category[0]?.SubCategory,
        };
      }
    });
    return finalNewData;
  };

  const getTreeDropOptions = (newCatObj: any) => {
    var newData = newCatObj?.map((data: any) => {
      return {
        title: data?.CategoryGroup,
        value: `${data?.category[0]?.ParentCategoryId}`,
        key: `${data?.category[0]?.ParentCategoryId}`,
        parentVal: `${data?.category[0]?.ParentCategoryId}`,
        children: data?.category?.map((data: Category) => {
          return {
            title: data?.CategoryName,
            value: `${data?.CategoryId}`,
            key: `${data?.CategoryId}`,
            parentVal: `${data?.CategoryId}`,
            children:
              data?.SubCategory?.length === 0
                ? []
                : data?.SubCategory?.map((child: any) => {
                    return {
                      title: child?.CategoryName,
                      value: `${child?.CategoryId}`,
                      parentVal: `${child?.CategoryId}`,
                      childVal: `${child?.CategoryId}`,
                      key: `${child?.CategoryId}`,
                      children:
                        child?.SubCategory?.length === 0
                          ? []
                          : child?.SubCategory?.map((childB: any) => {
                              return {
                                title: childB?.CategoryName,
                                value: `${childB?.CategoryId}`,
                                parentVal: `${childB?.CategoryId}`,
                                key: `${childB?.CategoryId}`,
                                childVal: `${childB?.CategoryId}`,
                              };
                            }),
                    };
                  }),
          };
        }),
      };
    });
    return newData;
  };

  useEffect(() => {
    setNewCatObj(getNewCatObject(categoriesOptions));
  }, [categoriesOptions]);

  useEffect(() => {
    if (newCatObj) {
      var A: any = newCatObj,
        x = 0,
        y = 1;
      A[x] = A?.splice(y, 1, A[x])[0];
      if (A?.length > 1) {
        setTreeData(getTreeDropOptions(newCatObj));
      }
    }
  }, [newCatObj]);

  const handleCancel = () => {
    history.goBack();
  };
  const regexForStringReplace = /,/gi;
  const handleAuthorChange = (e: any) => {
    var temp: any = e.target.value
      ?.replace(regexForStringReplace, ",")
      .split(",");
    setCreatShelvItem({ ...creatShelvItem, AuthorSearch: temp.toString() });
  };

  const handleMostPopular = (value: any) => {
    setCreatShelvItem({ ...creatShelvItem, MostPopularDays: value });
  };

  const handlePublisherChange = (value: any, defaultValue: any) => {
    var stringVal = value?.toString();
    var finalVal = stringVal?.split(",").join("|");
    setCreatShelvItem({ ...creatShelvItem, PublisherSearch: finalVal });
  };
  const handleAudienceChange = (value: any, defaultValue: any) => {
    setCreatShelvItem({ ...creatShelvItem, TargetAudienceIds: value });
  };

  const onChangeTreeSelect = (value: any, label: any, extra: any) => {
    setTreeValue(value);
    var parentId = treeData?.map((item: any) => {
      return item?.value;
    });
    var reuduceGroupParentId = value?.filter((item: any) => {
      return !parentId?.includes(item);
    });
    var stringVal = reuduceGroupParentId?.toString();
    var finalVal = stringVal?.split(",").join("|");
    setCreatShelvItem({ ...creatShelvItem, CategorySearch: finalVal });
  };

  const handleFormatChange = (e: any) => {
    e.target.checked
      ? setSelectedFormats(selectedFormats.concat(e.target.name))
      : setSelectedFormats(
          selectedFormats.filter((value: any) => {
            return value !== e.target.name;
          })
        );
  };
  useEffect(() => {
    let eBook: any = [];
    let aBook: any = [];
    var media = selectedFormats.map((i: any) => Number(i));
    selectedFormats.map((item: any) => {
      if (item === "1") {
        return eBook?.push({ Ebooks: null });
      } else if (item === "2") {
        return aBook?.push({ Audiobooks: null });
      } else {
        return [];
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    });
    var finalFormat = { ...eBook[0], ...aBook[0] };
    setCreatShelvItem({
      ...creatShelvItem,
      itemformatid: finalFormat,
      MediaTypeIdSet: media,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormats]);

  const handleRecentDateRangeSelect = (value: number) => {
    setCreatShelvItem({ ...creatShelvItem, PublishedWithinDays: value });
  };

  const handleDateRangeSelect = (value: number) => {
    setCreatShelvItem({ ...creatShelvItem, PurchasedWithinDays: value });
  };

  const handleLanguageChange = (value: number) => {
    setCreatShelvItem({ ...creatShelvItem, Language: value });
  };

  const handleChange = (e: any) => {
    var value = e.target.value;
    if (value === 1) {
      setRadioValue(1);
      setCreatShelvItem({ ...creatShelvItem, TreatNewCopiesAsNewBooks: "F" });
    } else if (value === 2) {
      setRadioValue(2);
      setCreatShelvItem({ ...creatShelvItem, TreatNewCopiesAsNewBooks: "T" });
    }
  };

  const handleFilterChange = (e: any) => {
    if (e.target.checked === true) {
      setCreatShelvItem({ ...creatShelvItem, PPUCriteria: 1 });
    } else if (e.target.checked === false) {
      setCreatShelvItem({ ...creatShelvItem, PPUCriteria: 0 });
    }
  };

  const handleCreate = () => {
    var payload = { ...creatShelvData, CatShelfCriteria: creatShelvItem };
    dispatch(createNewAutoShelve(payload));
  };

  const handleEditShelv = () => {
    var payload = {
      reqBody: { ...creatShelvData, CatShelfCriteria: creatShelvItem },
      getShelv: {
        libraryId: creatShelvData?.LibraryId,
        catShelfId: creatShelvData?.ShelfId,
        recordCount: 500,
        recordStart: 0,
      },
    };
    dispatch(editAutoShelv(payload));
  };

  const showNotification = (msg: any, type: any) => {
    if (type === "success") {
      message.success({
        content: msg,
        key: "success",
      });
    } else {
      const text = msg.split(",");
      message.error({
        content: text[1],
        key: "error",
      });
      dispatch(clearNewShelv());
    }
  };

  useEffect(() => {
    const mediaTypeIdSet =
      createAutoShelv?.editCriteriaData?.CatShelfCriteria?.MediaTypeIdSet;
    if (mediaTypeIdSet) {
      setSelectedFormats(mediaTypeIdSet.map(String));
    } else if (mediaTypeIdSet === null || mediaTypeIdSet === undefined) {
      setSelectedFormats(["1", "2"]); // Default state when mediaTypeIdSet is null or undefined
    } else {
      setSelectedFormats(["1", "2"]); // Default state for any other unexpected cases
    }
  }, [createAutoShelv]);

  useEffect(() => {
    if (newAutoShelveFormState.isError) {
      showNotification(newAutoShelveFormState?.errorMessage, "error");
    } else if (newAutoShelveFormState.isSuccess) {
      setNavigation(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAutoShelveFormState]);

  useEffect(() => {
    if (updateShlvFormState.isError) {
      if (updateShelvError?.length > 0) {
        message.error({
          content: updateShelvError[0]?.ErrorMessage,
          key: "notificationMessage",
        });
        dispatch(clearUpdateError());
      }
    } else if (updateShlvFormState.isSuccess) {
      showNotification("Shelf Updated Successfully", "success");
      setNavigation(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShelvError, updateShlvFormState]);

  useEffect(() => {
    if (shelves.length > 0 && navigation !== false) {
      dispatch(getPreviousLocaton(window.location.pathname));
      history.push(AppRoutes.SHELVES);
      dispatch(newlyCreatedShelv(newShelve));
      showNotification("Shelf Created Successfully", "success");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shelves, navigation, newShelve]);

  useEffect(() => {
    return () => {
      setNavigation(false);
      dispatch(clearState());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tProps = {
    treeData,
    value: treevalue,
    onChange: onChangeTreeSelect,
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_ALL,
    placeholder: "Category and Subjects",
    style: {
      width: "100%",
    },
  };

  return (
    <>
      <SpinnerLoader
        loading={
          newAutoShelveFormState?.loading ||
          formState?.loading ||
          updateShlvFormState?.loading ||
          audienceDataFormState?.loading ||
          contentProvidersFormState?.loading ||
          publishersFormState?.loading ||
          mediaTypeFormState?.loading ||
          languageSearchFormState?.loading ||
          categoryOptionsFormState?.loading
        }
      >
        <div className="shelvDetails">
          <Row className="shelfHeadWrapper">
            <Col xl={12} lg={12} md={12} sm={8}>
              <p className="page-title">{createAutoShelv?.shelveName}</p>
            </Col>
            <Col xl={6} lg={7} md={9} sm={12} className="createShelfHeadCol">
              <Button type="primary shelveDetailBtn blue-tag">AUTOMATED</Button>
              {creatShelvData?.IsActive ? (
                <Button type="primary shelveDetailBtn green-tag shelfDetailsBtn">
                  LIVE
                </Button>
              ) : (
                <Button type="primary shelveDetailBtn yellow-tag shelfDetailsBtn">
                  DRAFT
                </Button>
              )}
            </Col>
          </Row>
          <Row className="shelvesDiscription-row">
            <p className="title-count">{createAutoShelv?.shelveDescription}</p>
          </Row>
          <Divider className="autoShelves-divider" />
          <div className="scrollContent scrollStyle searchContainer">
            <Form>
              <Row className="form-container" gutter={[20, 20]}>
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <Card className="searchForm-card">
                        <p className="card-title">Search Detail</p>
                        <div className="card-dropdown-withLabel card-author-label">
                          <label>Author</label>
                          <Input
                            name="AuthorSearch"
                            placeholder="Author(s)"
                            className="card-input"
                            bordered={false}
                            onChange={handleAuthorChange}
                            defaultValue={creatShelvItem?.AuthorSearch}
                          />
                        </div>
                        <div className="card-dropdown-withLabel">
                          <label>Most Popular in Library</label>
                          <DropDown
                            showSearch={true}
                            className="card-dropdown with-search"
                            bordered={false}
                            optionValue={filterOptions.PopularLibrary}
                            icon={dropdownIcon}
                            onChange={handleMostPopular}
                            defaultValue={parseInt(
                              creatShelvItem?.MostPopularDays
                            )}
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Card className="searchForm-card">
                        <p className="card-title">Providers</p>
                        <DropDown
                          showSearch
                          placeholder="Publisher List"
                          className="card-dropdown with-search"
                          bordered={false}
                          optionValue={getPublisherOptions()}
                          icon={searchIcon}
                          mode="multiple"
                          onChange={handlePublisherChange}
                          defaultValue={
                            creatShelvItem?.PublisherSearch === ""
                              ? []
                              : creatShelvItem?.PublisherSearch?.split("|").map(
                                  (i: any) => Number(i)
                                )
                          }
                        />
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Card className="searchForm-card">
                        <p className="card-title">Format</p>
                        {mediaTypes?.map((data: any) => {
                          return (
                            <Checkbox
                              className="cards-checkbox"
                              name={`${data.MediaTypeId}`}
                              key={data?.MediaTypeId}
                              defaultChecked={creatShelvItem?.MediaTypeIdSet?.includes(
                                data?.MediaTypeId
                              )}
                              onChange={handleFormatChange}
                            >
                              {" "}
                              {data.MediaType}
                            </Checkbox>
                          );
                        })}
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Card className="searchForm-card">
                        <p className="card-title">Audience</p>
                        <DropDown
                          placeholder="All Audiences"
                          showSearch={true}
                          className="card-dropdown with-search"
                          bordered={false}
                          optionValue={getAudienceOptions()}
                          icon={dropdownIcon}
                          mode="multiple"
                          onChange={handleAudienceChange}
                          defaultValue={
                            creatShelvItem?.TargetAudienceIds === null
                              ? []
                              : creatShelvItem?.TargetAudienceIds
                          }
                        />
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <Card className="searchForm-card">
                        <p className="card-title">Category and Subjects</p>
                        <TreeSelect
                          showSearch
                          maxTagCount={2}
                          showArrow={true}
                          suffixIcon={<img src={searchIcon} alt="" />}
                          notFoundContent={
                            <Empty description="No results found." />
                          }
                          defaultValue={
                            creatShelvItem?.CategorySearch === ""
                              ? []
                              : creatShelvItem?.CategorySearch?.split("|")
                          }
                          className="treeSelectDropdown with-search"
                          {...tProps}
                          allowClear
                          multiple
                          getPopupContainer={(trigger) => trigger.parentNode}
                          filterTreeNode={(search: any, item: any) => {
                            return (
                              item.title
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) >= 0
                            );
                          }}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Card className="searchForm-card">
                        <p className="card-title">Dates and Language</p>
                        <div className="searchbyDateSelect">
                          <DropDown
                            className="card-dropdown"
                            placeholder="Recently Published"
                            bordered={false}
                            optionValue={filterOptions.createShelfDate}
                            icon={downIcon}
                            onChange={handleRecentDateRangeSelect}
                            TitleDaysOld
                            defaultValue={parseInt(
                              creatShelvItem?.PublishedWithinDays
                            )}
                          />
                        </div>
                        <div className="searchbyDateSelect">
                          <DropDown
                            className="card-dropdown"
                            placeholder="Published Within"
                            bordered={false}
                            optionValue={filterOptions.createShelfDateB}
                            icon={downIcon}
                            onChange={handleDateRangeSelect}
                            TitleDaysOld
                            defaultValue={parseInt(
                              creatShelvItem?.PurchasedWithinDays
                            )}
                          />
                        </div>
                        <DropDown
                          showSearch
                          className="card-dropdown with-search"
                          placeholder="Language(s)"
                          bordered={false}
                          optionValue={getLanguageOptions()}
                          icon={searchIcon}
                          onChange={handleLanguageChange}
                          defaultValue={
                            creatShelvItem?.Language === ""
                              ? []
                              : creatShelvItem?.Language
                          }
                        />
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Card className="searchForm-card">
                        <p className="card-title">Filter</p>
                        <Row>
                          <Checkbox
                            className="cards-checkbox"
                            name="ExcludeSelfPublished"
                            onChange={handleFilterChange}
                            defaultChecked={
                              creatShelvItem?.PPUCriteria === 1 ? true : false
                            }
                          >
                            {" "}
                            Include Pay Per Use
                          </Checkbox>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Card className="searchForm-card">
                        <p className="card-title">
                          New Copies Treated as New Titles
                        </p>
                        <Radio.Group
                          name="AutoBuy"
                          value={radioValue}
                          onChange={handleChange}
                        >
                          <Row>
                            <Col span={12}>
                              <Radio value={1}>
                                <span className="radio-span">No</span>
                              </Radio>
                            </Col>
                            <Col span={12}>
                              <Radio value={2}>
                                <span className="radio-span">Yes</span>
                              </Radio>
                            </Col>
                          </Row>
                        </Radio.Group>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row justify="end" className="search-footer">
                <Col xl={3} lg={3} md={4} sm={6} className="action-button">
                  <Button type="shelvesCancel" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Col>
                {createAutoShelv?.editCriteriaData !== undefined ? (
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={8}
                    className="action-button"
                    onClick={handleEditShelv}
                  >
                    <Button type="primary">Update</Button>
                  </Col>
                ) : (
                  <Col
                    xl={4}
                    lg={4}
                    md={6}
                    sm={8}
                    className="action-button"
                    onClick={handleCreate}
                  >
                    <Button type="primary">Create Shelf</Button>
                  </Col>
                )}
              </Row>
            </Form>
          </div>
        </div>
      </SpinnerLoader>
    </>
  );
};
