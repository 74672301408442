import React from 'react';
import { Row, Modal, } from "antd";
import Button from "../../../stateless/common/button";
import { useSelector } from "react-redux";
import SpinnerLoader from "../../../stateless/common/spinnerLoader";
import { PurchaseStateSelector } from '../../../../../redux/reducers/purchases/purchases';


const IsUnsaveDataModel = ( props: any) => {
    const {IsUnsaveDataShow, setDeclineUnsaveData, handleStayYes,message } = props;
    const {preSaleFormState} = useSelector(PurchaseStateSelector);

    const handleYesSubmit =() =>{
      handleStayYes()
      setDeclineUnsaveData(false)
    }

    const onCancelModel=()=>{
      setDeclineUnsaveData(false)
    }

    const handleNoSubmit = () => {
      setDeclineUnsaveData(false);
    }

  return (
    <Modal
    wrapClassName="purchase-modal"
    visible={IsUnsaveDataShow}
    onCancel={onCancelModel}
    footer={
          <Row justify="space-around">
          <Button
            type="primary confirm-button"
            onClick={handleYesSubmit}
          >
            Yes
          </Button>
          <Button
            type="smallRed confirm-button"
            onClick={handleNoSubmit}
          >
            No
          </Button>
          </Row>
      }
    >
        <SpinnerLoader loading={preSaleFormState.loading}>
        <p className="purchase-title">Unsaved data will be lost</p>
        <div className="remove-content">
        <p className='purchaseNote'>
          {message}
        </p>
        </div>
        </SpinnerLoader>

    </Modal>
  )
};

export default IsUnsaveDataModel;
