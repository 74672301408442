import React, { useEffect, useState } from "react";
import { Row, Col, Drawer, Input, Checkbox, Popover, Radio, Spin } from "antd";
import Button from "../../../stateless/common/button";
import { userDataKeys, userRoolesChkBox } from "../settingUtility";
import { infoIcon } from "../../../../images";
import DeActivateUserModal from "../../purchases/stayModel/deactivateUserModal";

const LibraryDrawer = (props: any) => {
  const {
    drawerOpen,
    setDrawerOpen,
    title,
    editData,
    editable,
    hadleSave,
    appUser,
    edituser,
    adduser
  } = props;
  const [roleCheckbox, setRoleCheckbox] = useState<any>(userRoolesChkBox([]));
  const [userData, setUserData] = useState<any>(userDataKeys);
  const [popoverState,setPopoverState] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false)
  const [firstnameError, setFirstNameError] = useState<boolean>(false)
  const [lastnameError, setLastNameError] = useState<boolean>(false)
  const [roleError, setRoleError] = useState<boolean>(false)
  const [rolesChecked, setRolesChecked] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (editable) {
      setUserData({
        ...userData,
        EmailAddress: editData?.EmailAddress,
        FirstName: editData?.FirstName,
        IsActive: editData?.IsActive,
        IsDownloadStationUser: editData?.IsDownloadStationUser,
        IsFeaturedContentManager: editData?.IsFeaturedContentManager,
        IsLibraryAdmin: editData?.IsLibraryAdmin,
        IsLibraryBuyer: editData?.IsLibraryBuyer,
        IsLibraryUser: editData?.IsLibraryUser,
        IsPatronCommunicator: editData?.IsPatronCommunicator,
        IsPatronManager: editData?.IsPatronManager,
        IsShelfAdmin: editData?.IsShelfAdmin,
        LastName: editData?.LastName,
        Password: editData?.Password,
        ScreenName: editData?.ScreenName,
        UserId: editData?.UserId,
        UserLanguage: editData?.UserLanguage,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, editable]);

  useEffect(() => {
    setRoleCheckbox(userRoolesChkBox(userData));
    if(userData.IsLibraryAdmin && userData.IsLibraryBuyer && userData.IsPatronCommunicator && userData.IsPatronManager && userData.IsShelfAdmin && userData.IsLibraryUser ){
      setRolesChecked(true)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const onHandleInput = (e: any) => {
    setUserData({ ...userData, [`${e?.target?.name}`]: e?.target?.value });
  };

  const handelCheckboxes = (e: any) => {
    if (e.target.id === "all"){
      setRolesChecked(e?.target?.checked)
      setUserData({ ...userData,
      IsLibraryAdmin: e?.target?.checked,
      IsLibraryBuyer: e?.target?.checked,
      IsPatronCommunicator: e?.target?.checked,
      IsPatronManager: e?.target?.checked,
      IsShelfAdmin: e?.target?.checked,
      IsLibraryUser: e?.target?.checked
    });
  } else if(e.target.id === "IsLibraryBuyer"){
      setUserData({ ...userData, [`${e?.target?.id}`]: e?.target?.checked,IsShelfAdmin:e?.target?.checked ,IsLibraryUser:e?.target?.checked});
      if(rolesChecked && e?.target?.checked === false ){
        setRolesChecked(e?.target?.checked)
      }
    }else if (e.target.id === "IsShelfAdmin") {
      if (userData.IsLibraryBuyer === true) {
        setUserData({ ...userData, [`${e?.target?.id}`]: true })
      } else {
        setUserData({ ...userData, [`${e?.target?.id}`]: e?.target?.checked })
      }
    } else if (e.target.id === "IsLibraryUser") {
      if (userData.IsLibraryBuyer === true) {
        setUserData({ ...userData, [`${e?.target?.id}`]: true })
      } else {
        setUserData({ ...userData, [`${e?.target?.id}`]: e?.target?.checked })
      }
    }
    else{
      setUserData({ ...userData, [`${e?.target?.id}`]: e?.target?.checked });
      if(rolesChecked && e?.target?.checked === false ){
        setRolesChecked(e?.target?.checked)
      }
    }
    };
    
  const hidePopover = () => {
    setPopoverState(!popoverState)
  }

  const validate = () => {
    // const validateEmail = userData?.EmailAddress !== null ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/.test(userData?.EmailAddress) : false
    const validateEmail = userData?.EmailAddress !== null ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$/.test(userData?.EmailAddress) : false;
    const FirstName = (userData?.FirstName !== null) ? (userData?.FirstName.length) ? true : false  : false
    const LastName = (userData?.LastName !== null) ?  (userData?.LastName.length) ? true : false :false
    const rolesValues = roleCheckbox.filter((data:any) => data.value === true)
    const validateRoles = (rolesValues.length > 0) ? true : false
    setEmailError(!validateEmail)
    setFirstNameError(!FirstName)
    setLastNameError(!LastName)
    setRoleError(!validateRoles)
    if (validateEmail && FirstName && LastName && validateRoles) {
      return true
    } else {
      return false
    }
  }

  const handleUpdate = () => {
    if (validate()) {
      hadleSave({
        update: true,
        user: {
          ...userData,
          Library: appUser?.libraryData,
          ScreenName: `${userData?.FirstName} ${userData?.LastName}`,
          UserId: userData?.EmailAddress,
        },
      });
      setShowModal(false);
      setConfirmed(false);
      setDrawerOpen(false);
    }
  };

  const handleUpdateClick = () =>{
    userData?.IsActive ? handleUpdate() : setShowModal(true)
  }
  
  useEffect(()=>{
    if(confirmed){
      handleUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[confirmed])

  const handleAdd = () => {
    if (validate()) { 
      hadleSave({
        update: false,
        user: {
          ...userData,
          Library: appUser?.libraryData,
          ScreenName: `${userData?.FirstName} ${userData?.LastName}`,
          UserId: userData?.EmailAddress,
          IsActive: userData?.IsActive,
        },
      });
    }
  };

  useEffect(() => {
    if(!drawerOpen){
      setUserData(userDataKeys)
      setEmailError(false)
      setFirstNameError(false)
      setLastNameError(false)
      setRoleError(false)
    }
  }, [drawerOpen])
const handelRadioBtn = (e:any) =>{
if(e.target.value === 1){
  setUserData({ ...userData, IsActive: true });
}else{
  setUserData({ ...userData, IsActive: false });
}
}

  return (
    <>
    <Drawer
      placement="right"
      width="428"
      onClose={() => {
        setDrawerOpen(false);
      }}
      visible={drawerOpen}
      className="libraryDrawer"
    >
      <Spin spinning={edituser || adduser}>
      <div className="drawerWrapper">
        <div>
          <h3 className="drawer-title">{title}</h3>
          <div className="drawerUserInfo">
            <div className="library-sub-heading">User Information</div>
            <Input
              name="EmailAddress"
              placeholder="Email"
              className={`${ emailError ? "input-when-error" : "card-input disabled notAllowed"}`}
              bordered={false}
              disabled={title === "Edit User" ? true : false}
              onChange={onHandleInput}
              value={
                userData?.EmailAddress !== undefined
                  ? userData?.EmailAddress
                  : undefined
              }
            />
            {
              emailError ? <p className="libraryDrawerError">Please enter a valid e-mail address</p> : null
            }
            <Input
              name="FirstName"
              placeholder="First Name"
              className={`${ firstnameError ? "input-when-error" : "card-input"}`} 
              bordered={false}
              onChange={onHandleInput}
              value={
                userData?.EmailAddress !== undefined
                  ? userData?.FirstName
                  : undefined
              }
            />
            {
              firstnameError ? <p className="libraryDrawerError">First name is required</p> : null
            }
            <Input
              name="LastName"
              placeholder="Last Name"
              className={`${ lastnameError ? "input-when-error" : "card-input"}`}
              bordered={false}
              onChange={onHandleInput}
              value={
                userData?.EmailAddress !== undefined
                  ? userData?.LastName
                  : undefined
              }
            />
            {
              lastnameError ? <p className="libraryDrawerError">Last name is required</p> : null
            }
          </div>

          <div className="drawerUserInfo drawerrole">
            <div className="library-sub-heading">
            <Row>
                <Col span={20}>
              Roles
              </Col>
              <Col span={4} className="drawerCheckbox">
              <Checkbox
              onChange={handelCheckboxes}
              id="all"
              checked={rolesChecked}
              >
              </Checkbox>
              </Col>
              </Row>
            </div>
            {roleCheckbox?.map((item: any) => {
              return (
                <Row key={item.name}>
                  <Col span={20} className="LibraryStaffPopover">{item.label} <Popover
                  onVisibleChange={hidePopover}
                trigger={["click"]}
                placement="bottomLeft"
                content={item.contentIniIcon}
                getPopupContainer={(trigger: any) => trigger.parentNode}
            >
                <img className="info-icon" src={infoIcon} alt="info" />
            </Popover></Col>
                  <Col span={4} className="drawerCheckbox">
                    <Checkbox
                      id={item?.name}
                      onChange={handelCheckboxes}
                      defaultChecked={item?.value}
                      checked={item?.value}
                    ></Checkbox>
                  </Col>
                </Row>
              );
            })}
            {
              roleError ? <p className="libraryDrawerError libraryRolesError">At least one role is required</p> : null
            }
          </div>
          <div className="drawerUserInfo drawerrole drawerRadioDiv">
            <div className="library-sub-heading">User Status</div>
            <Radio.Group  value={userData.IsActive === true ? 1 : 2} onChange={handelRadioBtn}>
              <Radio value={1}>Active</Radio>
              <Radio value={2}>Inactive</Radio>
            </Radio.Group>
          </div>
        </div>
        <div>
          {title === "Edit User" ? (
            <div className="drawerAdd">
              <Button type="primary confirm-button" onClick={handleUpdateClick}>
                Update
              </Button>
              {/* {
                userData?.IsActive ?
                  <Button type="smallRed confirm-button" onClick={handelDectivate}>Deactivate User</Button>
                  :
                  <Button type="primary confirm-button" onClick={handelActivate}>Activate User</Button>
              } */}

            </div>
          ) : (
            <Row>
              <Col span={24} className="drawerAdd">
                <Button type="primary confirm-button" onClick={handleAdd}>
                  Add User
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </div>
      </Spin>
    </Drawer>
    <DeActivateUserModal showModal={showModal} setShowModal={setShowModal} setConfirmed={setConfirmed}  message={"libraryDr"}/>
    </>
  );
};

export default LibraryDrawer;
