import React from "react"
import { Pagination } from "antd"
import "./pagination.less"
const PaginationTag = (props: any) => {
    return (
        <>
            <Pagination
                total={props.total}
                current={props.current}
                defaultPageSize={props.defaultPageSize}
                itemRender={props.itemRender}
                onChange={props.onChange}
                className="pageStyle"
            />
        </>
    )
}

export default PaginationTag;