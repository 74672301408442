import { ValidateEmail } from "../components/containers/user/auth/validateEmail";
import { ForgotPwd } from "../components/containers/user/auth/validateEmail/forgotPwd";
import { ResetPassword } from "../components/stateless/forgetPassword/resetPwd";
import { InitialPassword } from "../components/stateless/forgetPassword/initialPwd";
import { BookList } from "../components/containers/curatedList/bookList/Index";
import { Groups } from "../components/containers/curatedList/groups";
import { Spotlight } from "../components/containers/spotlight/index";
import { CategoryList } from "../components/containers/curatedList/subGroups";
import { BookDetails } from "../components/containers/curatedList/bookDetails";
import { Carts } from "../components/containers/carts";
import { CartDetails } from "../components/containers/carts/cartDetails";
import { Search } from "../components/containers/search";
import { SearchResults } from "../components/containers/search/searchResults";
import { Shelves } from "../components/containers/shelves/index";
import { CollectionSearchResults } from "../components/containers/collection/searchResults";
import { PurchasesMain } from "../components/containers/purchases/purchasesMain";
import { ValidateLibrarianEmail } from "../components/containers/librarianLogin/auth/validateLibrarianEmail";
import PurchaseDetails from "../components/containers/purchases/purchaseDetails"
import { CollectionSearch } from "../components/containers/collection/myCollectionMain"
import { MessagesMain } from "../components/containers/messages/messagesMain";
import Settings from "../components/containers/settings/settings"
import { PatronsManagement } from "../components/containers/patron/patronMain";
import { ShelvesDetails } from "../components/containers/shelves/ShelvesDetails/shelvesDetails";
import { CreateAutoShelfForm } from "../components/containers/shelves/createAutoShelfForm";
import { Support } from "../components/containers/support/index";
import {LibraryReports} from "../components/containers/insights/index";

export enum AppRoutes {
  LOGIN = "/login",
  FORGOTPWD = "/forgotPassword",
  PASSWORDRESET = "/resetPassword",
  INITIALPWD = "/setInitialPassword",
  LIBRARIANLOGIN = "/impersonatelibrarian",
  LANDING = "/cloudLibrary/curatedLists",
  SPOTLIGHTLANDING = "/cloudLibrary/landing",
  SEARCH = "/cloudLibrary/search",
  CART = "/cloudLibrary/cart",
  CATEGORIES = "/cloudLibrary/featuredlist",
  BOOKLIST = "/cloudLibrary/featuredlist/titles/featureListId=:id",
  COLLECTION = "/libraryManagement/collections",
  SHELVES = "/libraryManagement/shelves",
  PATRONS = "/libraryManagement/patrons",
  MESSAGES = "/libraryManagement/messages",
  REPORTS = "/libraryManagement/reports",
  PURCHASES = "/libraryManagement/purchases",
  PURCHASEDETAILS = "/libraryManagement/purchaseDetails",
  ITEMDETAILS = "/cloudLibrary/featuredlist/itemDetails",
  CARTDETAILS = "/cloudLibrary/cart/cartDetails",
  SEARCHRESULTS =  "/cloudLibrary/search/results",
  COLLECTIONSEARCHRESULTS =  "/cloudLibrary/search/myCollectionResults",
  SETTINGS =  "/cloudLibrary/settings",
  SHELVDETAILS =  "/libraryManagement/shelves/shelvDetails",
  AUTOMATEDSHELF = "/libraryManagement/shelves/automatedShelf",
  SUPPORT = "/cloudLibrary/support",
  INSIGHT = "/libraryManagement/LibraryReports"
}
export const PublicRoutes = [
    {
      path: AppRoutes.LOGIN,
      component: ValidateEmail,
    },
    {
      path: AppRoutes.LIBRARIANLOGIN,
      component: ValidateLibrarianEmail,
    },
    {
      path: AppRoutes.FORGOTPWD,
      component: ForgotPwd,
    },
    {
      path: AppRoutes.PASSWORDRESET,
      component: ResetPassword,
    },
    {
      path: AppRoutes.INITIALPWD,
      component: InitialPassword,
    },
  ];
  
export const PrivateRoutes = [
    {
      routePath: "/cloudLibrary/:path?",
      routes: [
        {
          path: AppRoutes.SPOTLIGHTLANDING,
          component: Spotlight,
        },
        {
          path: AppRoutes.LANDING,
          component: Groups,
        },
        {
          path: AppRoutes.SEARCH,
          component: Search,
        },
        {
          path: AppRoutes.CART,
          component: Carts,
        },
        {
          path: AppRoutes.CATEGORIES,
          component: CategoryList,
        },
        {
          path: AppRoutes.BOOKLIST,
          component: BookList,
        },
        {
          path: AppRoutes.ITEMDETAILS,
          component: BookDetails,
        },
        {
          path: AppRoutes.CARTDETAILS,
          component: CartDetails,
        },
        {
          path: AppRoutes.SEARCHRESULTS,
          component: SearchResults
        },
        {
          path: AppRoutes.COLLECTIONSEARCHRESULTS,
          component: CollectionSearchResults
        },
        {
          path: AppRoutes.SETTINGS,
          component: Settings,
        },
        {
          path: AppRoutes.SUPPORT,
          component: Support,
        },
      ],
    },
    {
      routePath: "/libraryManagement/:path?",
      routes: [
        {
          path: AppRoutes.COLLECTION,
          component: CollectionSearch,
        },
        {
          path: AppRoutes.SHELVES,
          component: Shelves,
        },
        {
          path: AppRoutes.SHELVDETAILS,
          component: ShelvesDetails,
        },
        {
          path: AppRoutes.AUTOMATEDSHELF,
          component: CreateAutoShelfForm,
        },
        {
          path: AppRoutes.PATRONS,
          component: PatronsManagement,
        },
        {
          path: AppRoutes.MESSAGES,
          component: MessagesMain,
        },
        {
          path: AppRoutes.PURCHASES,
          component: PurchasesMain,
        },
        {
          path: AppRoutes.PURCHASEDETAILS,
          component: PurchaseDetails,
        },
        {
          path: AppRoutes.INSIGHT,
          component: LibraryReports,
        },
      ],
    },
  ];
  