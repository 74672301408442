import styled from "styled-components";
import { Layout, Row } from 'antd';
const { Content, Sider } = Layout;

export const StyledLayout = styled(Layout)`
   width: 100%;
`;

export const StyledSider = styled(Sider)`
    background: #F3F3F3;
    height: 100% !important;
`;

export const StyledContent = styled(Content)`
    height: 92vh !important;
    padding-left: 1.5vw;
    padding-top: 10px;  
`;

export const SiderRow = styled(Row)`
    width: 100%;
`;